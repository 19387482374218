<template>
  <iframe
    :src="'https://api.cmspricer.com/cmsp1'"
    id="iframe"
    @load="onLoad"
    name="my-frame"
    width="100%"
    height="100%"
  />
</template>
<script>
export default {
  name: "CMSPricer",
  data: () => ({
    CMSPricer: null,
    myIframe: null,
  }),
  methods: {
    onLoad(frame) {
      this.myIframe = frame.contentWindow;
    },
  },
};
</script>
