<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Sent Correspondence
          </v-card-title>
        <v-card-text>
            <v-row>
              <v-col class="px-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="reportData"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.filledPdf="{ item }">
                <v-icon @click="openPDFViewer(item)">mdi-file</v-icon>
              </template>

              <template slot="footer">
                <ExportCSV :data="this.csvData" :name="'correspondence.csv'">
                  <v-btn color="accent" text class="export-button">
                    Export to CSV
                  </v-btn>
                </ExportCSV>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-dialog v-model="pdfViewerDialog" max-width="70%">
          <v-toolbar dark color="primary">
            <v-toolbar-title>View PDF</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click.stop="pdfViewerDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card tile>
            <v-card-text class="py-3">
              <iframe
                title="frame"
                width="100%"
                height="1000px"
                :src="this.pdfFileToView"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import ExportCSV from "vue-json-csv";

export default {
  name: "CorrespondenceSent",
  components: {
    ExportCSV
  },
  data() {
    return {
      user: "",
      search: "",
      load: false,
      headers: [
        { text: "User ID", value: "userID" },
        { text: "Recipient Name", value: "name" },
        { text: "Recipient Email", value: "email" },
        { text: "Correspondence", value: "pdfType" },
        { text: "Date Sent", value: "dateSent" },
        { text: "PDF File", value: "filledPdf" }
      ],
      reportData: [],
      csvData: [],
      pdfFileToView: "",
      pdfViewerDialog: false
    };
  },
  methods: {
    getUser() {
      axios.get("/api/user").then(response => {
        this.user = response.data[0];
        // check role and reroute if unauthorized
        if (this.user.role === "agent" || this.user.role === "fmo") {
          this.$router.push("/");
        } else {
          this.getCorrespondence();
        }
      }).catch(() => {
        this.$router.push("/login");
      });
    },
    getCorrespondence() {
      this.load = true;
      this.reportData = [];

      axios.get("/api/correspondence/sent").then(response => {
        this.load = false;
        this.reportData = response.data;
        this.csvData = JSON.parse(JSON.stringify(this.reportData))
        this.csvData.forEach((correspondence) => {
          delete correspondence._id
          delete correspondence.filledPdf
        })
      });
    },
    openPDFViewer(item) {
      this.pdfFileToView =
        "data:application/pdf;base64," + encodeURI(item.filledPdf); // creates link to encoded pdf file
      this.pdfViewerDialog = true; // open dialog
    }
  },
  mounted() {
    this.getUser();
  },
  computed: {},
  watch: {}
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
