<template crossorigin="use-credentials">
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        <v-icon color="accent">mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-toolbar dark color="primary" class="mx-auto">
      <v-btn icon dark @click="checkChanges">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <ExportCSV :data="[csvData]" :name="enrollment_details.name + '.csv'">
        <v-btn class="mr-3" outlined dark>
          <v-icon>mdi-microsoft-excel</v-icon>
          <span class="pl-3">Export to CSV</span>
        </v-btn>
      </ExportCSV>
      <v-btn class="mr-5" outlined dark @click="generatePDF()">
        <v-icon>mdi-file-image-outline</v-icon>
        <span class="pl-3">Export PDF</span>
      </v-btn>
      <v-btn
        class="mr-5"
        color="success"
        outlined
        dark
        @click="saveDialog = true"
        :disabled="limitedAccess"
      >
        <v-icon></v-icon>
        <span>Save Changes</span>
      </v-btn>
    </v-toolbar>

    <v-card tile id="capture" flat>
      <v-skeleton-loader
        :loading="loading"
        height="81"
        width="500"
        type="list-item-two-line"
      >
        <v-card-title class="text-h3 display-2">
          <h1 class="display-2">
            {{ enrollment_details.lastName }}, {{ enrollment_details.firstName
            }}<span v-if="enrollment_details.middleInitial">
              {{ enrollment_details.middleInitial }}</span
            >
          </h1>
        </v-card-title>
        <v-col lg="4" md="6" sm="8" class="pt-0">
          <div id="sonderIdDiv">
            <v-card-text class="text-subtitle-1 py-0" v-if="edit === false">
              Sonder ID:
              <span class="primary--text">{{
                enrollment_details.memberID
              }}</span>
              <v-icon
                small
                class="ml-2"
                @click="editSonderId"
                :disabled="limitedAccess"
                >mdi-pencil</v-icon
              >
            </v-card-text>
            <v-card-text class="py-0" v-else>
              <v-text-field
                outlined
                dense
                v-model="newMemberId"
                label="Enter New Sonder ID"
                :placeholder="enrollment_details.memberID"
                :readonly="limitedAccess"
              >
                <template slot="append-outer">
                  <v-icon
                    @click="saveSonderId(enrollment_details._id, newMemberId)"
                    color="success"
                    :disabled="limitedAccess"
                    >mdi-check-bold</v-icon
                  >
                  <v-icon
                    @click="editSonderId"
                    color="error"
                    :disabled="limitedAccess"
                    >mdi-close-thick</v-icon
                  >
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-text class="text-subtitle-1 py-0">
              Agent:
              <span class="primary--text">{{
                enrollment_details.agentName
              }}</span>
            </v-card-text>
          </div>
        </v-col>
        <v-tabs v-model="tab" grow>
          <v-tab v-for="item in modalTabs" :key="item">
            <v-badge
              icon="mdi-exclamation"
              color="warning"
              v-if="item === 'Enrollment History' && duplicates.length"
            >
              {{ item }}
            </v-badge>
            <template v-else>
              {{ item }}
            </template>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in modalTabs" :key="item">
            <template v-if="item === 'Summary'">
              <v-form
                ref="patientForm"
                v-model="summaryChanges"
                lazy-validation
              >
                <v-row class="pa-2">
                  <v-col lg="6" md="6" sm="12">
                    <v-card outlined height="100%" elevation="2">
                      <v-card-title class="font-weight-bold"
                        >Patient Summary</v-card-title
                      >
                      <v-row class="pa-1">
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.lastName"
                            label="Last Name"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.lastName != '' ||
                                'Please enter the patient\'s Last Name.',
                            ]"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.firstName"
                            label="First Name"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.firstName != '' ||
                                'Please enter the patient\'s First Name.',
                            ]"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.middleInitial"
                            label="Middle Inital"
                            outlined
                            dense
                            :readonly="limitedAccess"
                            @change="summaryFieldChanged = true"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :return-value.sync="enrollment_details.birthDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                            :readonly="limitedAccess"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="enrollment_details.birthDate"
                                label="Birth Date"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                :rules="[
                                  enrollment_details.birthDate != '' ||
                                    'Please enter the patient\'s date of birth',
                                ]"
                                v-bind="attrs"
                                v-on="on"
                                :readonly="limitedAccess"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="enrollment_details.birthDate"
                              @change="summaryFieldChanged = true"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1900-01-01"
                              :disabled="limitedAccess"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="menu1 = false"
                                :disabled="limitedAccess"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                :disabled="limitedAccess"
                                @click="
                                  $refs.menu1[0].save(
                                    enrollment_details.birthDate
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.email"
                            label="email"
                            outlined
                            dense
                            :readonly="limitedAccess"
                            @change="summaryFieldChanged = true"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.address"
                            label="Address"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.address != '' ||
                                'Please enter the patient\'s address.',
                            ]"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.city"
                            label="City"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.city != '' ||
                                'Please enter the patient\'s city.',
                            ]"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.county"
                            label="County"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.county != '' ||
                                'Please enter the patient\'s county.',
                            ]"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.state"
                            label="State"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.state != '' ||
                                'Please enter the patient\'s state.',
                            ]"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.zip"
                            label="Zip"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.zip != '' ||
                                'Please enter the patient\'s zip.',
                            ]"
                          />
                        </v-col>
                        <v-col lg="6" sm="12">
                          <v-text-field
                            v-model="enrollment_details.phoneNumber"
                            label="Phone"
                            outlined
                            dense
                            @change="summaryFieldChanged = true"
                            @input="formatMemberPhoneNum()"
                            required
                            :readonly="limitedAccess"
                            :rules="[
                              enrollment_details.phoneNumber != '' ||
                                'Please enter the patient\'s phone number.',
                              /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/.test(
                                enrollment_details.phoneNumber
                              ) || 'Please enter an actual phone number',
                            ]"
                          />
                        </v-col>
                        <!--mailing -->
                        <v-col cols="12" lg="3" class="pt-1">
                          <v-checkbox
                            v-model="enrollment_details.mailingAddress"
                            label="Mailing address same as residential address"
                            @change="summaryFieldChanged = true"
                            dense
                            required
                          ></v-checkbox>
                        </v-col>
                        <v-row
                          class="pa-3"
                          v-if="enrollment_details.mailingAddress !== true"
                        >
                          <v-col lg="6" sm="12">
                            <v-text-field
                              v-model="enrollment_details.mailAddress"
                              label="Mailing Address"
                              outlined
                              dense
                              @change="summaryFieldChanged = true"
                              required
                              :rules="[
                                enrollment_details.mailAddress != '' ||
                                  'Please enter the patient\'s mailing address.',
                              ]"
                            />
                          </v-col>
                          <v-col lg="6" sm="12">
                            <v-text-field
                              v-model="enrollment_details.mailCity"
                              label="Mailing City"
                              outlined
                              dense
                              @change="summaryFieldChanged = true"
                              required
                              :rules="[
                                enrollment_details.mailCity != '' ||
                                  'Please enter the patient\'s mailing city.',
                              ]"
                            />
                          </v-col>
                          <v-col lg="6" sm="12">
                            <v-text-field
                              v-model="enrollment_details.mailCounty"
                              label="Mailing County"
                              outlined
                              dense
                              @change="summaryFieldChanged = true"
                              required
                              :rules="[
                                enrollment_details.mailCounty != '' ||
                                  'Please enter the patient\'s mailing county.',
                              ]"
                            />
                          </v-col>
                          <v-col lg="6" sm="12">
                            <v-text-field
                              v-model="enrollment_details.mailState"
                              label="Mailing State"
                              outlined
                              dense
                              @change="summaryFieldChanged = true"
                              required
                              :rules="[
                                enrollment_details.mailState != '' ||
                                  'Please enter the patient\'s mailing state.',
                              ]"
                            />
                          </v-col>
                          <v-col lg="6" sm="12">
                            <v-text-field
                              v-model="enrollment_details.mailZip"
                              label="Mailing Zip"
                              outlined
                              dense
                              @change="summaryFieldChanged = true"
                              required
                              :rules="[
                                enrollment_details.mailZip != '' ||
                                  'Please enter the patient\'s mailing zip.',
                              ]"
                            />
                          </v-col>
                        </v-row>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-text-field
                            label="RxBIN"
                            v-model="enrollment_details.rxBin"
                            @change="summaryFieldChanged = true"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-text-field
                            label="RxPCN"
                            v-model="enrollment_details.rxPcn"
                            @change="summaryFieldChanged = true"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-text-field
                            label="RxGroup"
                            v-model="enrollment_details.rxGroup"
                            @change="summaryFieldChanged = true"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-text-field
                            label="PCP Copay"
                            v-model="enrollment_details.pcpCopay"
                            @change="summaryFieldChanged = true"
                            prefix="$"
                            outlined
                            dense
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-text-field
                            label="Specialist Copay"
                            v-model="enrollment_details.specialistCopay"
                            @change="summaryFieldChanged = true"
                            prefix="$"
                            outlined
                            dense
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-text-field
                            label="Emergency Copay"
                            v-model="enrollment_details.emergencyCopay"
                            @change="summaryFieldChanged = true"
                            prefix="$"
                            outlined
                            dense
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-text-field
                            label="Urgent Copay"
                            v-model="enrollment_details.urgentCopay"
                            @change="summaryFieldChanged = true"
                            prefix="$"
                            outlined
                            dense
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-menu
                            ref="letterMenu"
                            v-model="letterMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="enrollment_details.disenrollmentLetter"
                                label="Disenrollment Letter"
                                append-icon="mdi-calendar"
                                :return-value.sync="
                                  enrollment_details.disenrollmentLetter
                                "
                                clearable
                                outlined
                                dense
                                readyonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              label="Disenrollment Letter"
                              v-model="enrollment_details.disenrollmentLetter"
                              @change="summaryFieldChanged = true"
                              outlined
                              dense
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="letterMenu = false"
                                :disabled="limitedAccess"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                :disabled="limitedAccess"
                                @click="
                                  $refs.letterMenu[0].save(
                                    enrollment_details.disenrollmentLetter
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-menu
                            ref="memberIdCreatedMenu"
                            v-model="memberIdCreatedMenu"
                            :close-on-content-click="false"
                            :return-value.sync="
                              enrollment_details.memberIdCreated
                            "
                            offset-y
                            min-width="290px"
                            max-width="290px"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="enrollment_details.memberIdCreated"
                                label="Member ID Created"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readyonly
                                clearable
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              label="Member ID Created"
                              v-model="enrollment_details.memberIdCreated"
                              @change="summaryFieldChanged = true"
                              outlined
                              dense
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="memberIdCreatedMenu = false"
                                :disabled="limitedAccess"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                :disabled="limitedAccess"
                                @click="
                                  $refs.memberIdCreatedMenu[0].save(
                                    enrollment_details.memberIdCreated
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-select
                            label="Type of Practice"
                            v-model="enrollment_details.typeOfPractice"
                            @change="summaryFieldChanged = true"
                            :items="practiceTypes"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-1">
                          <v-menu
                            ref="ackOfRecieptMenu"
                            v-model="ackOfRecieptMenu"
                            :close-on-content-click="false"
                            :return-value.sync="enrollment_details.ackOfReciept"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="enrollment_details.ackOfReciept"
                                label="Ack of Reciept"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                clearable
                                readyonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              label="Ack of Receipt"
                              v-model="enrollment_details.ackOfReciept"
                              @change="summaryFieldChanged = true"
                              outlined
                              dense
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="ackOfRecieptMenu = false"
                                :disabled="limitedAccess"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                :disabled="limitedAccess"
                                @click="
                                  $refs.ackOfRecieptMenu[0].save(
                                    enrollment_details.ackOfReciept
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col lg="6" md="6" sm="12">
                    <v-card outlined height="100%" elevation="2">
                      <v-row>
                        <v-col lg="6" sm="12">
                          <v-card-title class="font-weight-bold"
                            >Medicare Details</v-card-title
                          >
                          <v-col cols="12">
                            <v-text-field
                              v-model="enrollment_details.medicareNumber"
                              label="Medicare Number"
                              outlined
                              dense
                              readonly
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="enrollment_details.medicaidNumber"
                              label="Medicaid Number"
                              @change="summaryFieldChanged = true"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              :return-value.sync="enrollment_details.partA"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                              :readonly="limitedAccess"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="enrollment_details.partA"
                                  label="Effective Date (A)"
                                  append-icon="mdi-calendar"
                                  clearable
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  :readonly="limitedAccess"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="enrollment_details.partA"
                                :allowed-dates="allowedEffectiveDates"
                                @change="summaryFieldChanged = true"
                                min="1900-01-01"
                                :disabled="limitedAccess"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="menu2 = false"
                                  :disabled="limitedAccess"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.menu2[0].save(
                                      enrollment_details.partA
                                    )
                                  "
                                  :disabled="limitedAccess"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12">
                            <v-menu
                              ref="menu3"
                              v-model="menu3"
                              :close-on-content-click="false"
                              :return-value.sync="enrollment_details.partB"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                              :readonly="limitedAccess"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="enrollment_details.partB"
                                  label="Effective Date (B)"
                                  append-icon="mdi-calendar"
                                  clearable
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  :readonly="limitedAccess"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="enrollment_details.partB"
                                :allowed-dates="allowedEffectiveDates"
                                @change="summaryFieldChanged = true"
                                min="1900-01-01"
                                :disabled="limitedAccess"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="menu3 = false"
                                  :disabled="limitedAccess"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.menu3[0].save(
                                      enrollment_details.partB
                                    )
                                  "
                                  :disabled="limitedAccess"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-card-title class="font-weight-bold">
                            PCP</v-card-title
                          >
                          <v-row justify="center" class="pl-3 pr-3 mb-3">
                            <v-col cols="6"
                              ><v-btn
                                color="primary"
                                block
                                @click="allowPCPEdits = true"
                                >Edit PCP</v-btn
                              ></v-col
                            >
                            <v-col cols="6"
                              ><v-btn
                                color="success"
                                block
                                @click="changePCP"
                                :disabled="!enrollment_details.pcpCareEnd"
                                >Change PCP</v-btn
                              ></v-col
                            >
                          </v-row>
                          <v-col cols="12">
                            <v-combobox
                              v-model="enrollment_details.selectedPCP"
                              label="PCP Name"
                              :items="pcpList"
                              item-text="RenderingProviderFullName"
                              hint="Search or select PCP from dropdown"
                              outlined
                              dense
                              @change="
                                summaryFieldChanged = true;
                                editPCP();
                                setPCP();
                              "
                              required
                              :rules="[
                                enrollment_details.selectedPCP != '' ||
                                  'Please enter the patient\'s PCP name.',
                              ]"
                              :disabled="!allowPCPEdits"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="enrollment_details.pcpNotShown"
                              label="PCP Not Shown"
                              @change="summaryFieldChanged = true"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-combobox
                              v-model="enrollment_details.pcpID"
                              label="PCP ID"
                              :items="pcpList"
                              item-text="RenderingNPI"
                              outlined
                              dense
                              @change="
                                summaryFieldChanged = true;
                                editPCP();
                                setPCPbyID();
                              "
                              :disabled="!allowPCPEdits"
                              :readonly="limitedAccess"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-menu
                              ref="pcpStartMenu"
                              v-model="pcpStartMenu"
                              :close-on-content-click="false"
                              :return-value.sync="
                                enrollment_details.pcpCareStart
                              "
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                              :readonly="limitedAccess"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="enrollment_details.pcpCareStart"
                                  label="PCP Care Start Date"
                                  append-icon="mdi-calendar"
                                  clearable
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  @change="
                                    summaryFieldChanged = true;
                                    editPCP();
                                  "
                                  :readonly="limitedAccess"
                                  :disabled="!allowPCPEdits"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="enrollment_details.pcpCareStart"
                                min="1900-01-01"
                                :disabled="limitedAccess && allowPCPEdits"
                                @change="
                                  summaryFieldChanged = true;
                                  editPCP();
                                "
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="pcpStartMenu = false"
                                  :disabled="limitedAccess"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.pcpStartMenu[0].save(
                                      enrollment_details.pcpCareStart
                                    )
                                  "
                                  :disabled="limitedAccess"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12">
                            <v-menu
                              ref="pcpEndMenu"
                              v-model="pcpEndMenu"
                              :close-on-content-click="false"
                              :return-value.sync="enrollment_details.pcpCareEnd"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                              :readonly="limitedAccess"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="enrollment_details.pcpCareEnd"
                                  label="PCP Care End Date"
                                  append-icon="mdi-calendar"
                                  clearable
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  @change="
                                    summaryFieldChanged = true;
                                    editPCP();
                                  "
                                  :disabled="!allowPCPEdits"
                                  :readonly="limitedAccess"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="enrollment_details.pcpCareEnd"
                                min="1900-01-01"
                                :disabled="limitedAccess && !allowPCPEdits"
                                @change="
                                  summaryFieldChanged = true;
                                  editPCP();
                                "
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="pcpEndMenu = false"
                                  :disabled="limitedAccess"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.pcpEndMenu[0].save(
                                      enrollment_details.pcpCareEnd
                                    )
                                  "
                                  :disabled="limitedAccess"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="
                                enrollment_details.MedicalGroupAffiliation
                              "
                              :items="medicalAffiliates"
                              label="Medical Group Affiliation"
                              dense
                              outlined
                              @change="summaryFieldChanged = true"
                            />
                          </v-col>
                          <v-col lg="12" sm="12">
                            <v-combobox
                              :items="agentIDs"
                              label="Agent ID"
                              v-model="enrollment_details.agentNumberAck"
                              hint="Enter or select agent from dropdown"
                              @change="
                                summaryFieldChanged = true;
                                getAgentName();
                              "
                              outlined
                              dense
                              :return-object="false"
                              :readonly="limitedAccess"
                            ></v-combobox>
                            <v-text-field
                              v-model="enrollment_details.agentName"
                              label="Agent Name"
                              outlined
                              @change="summaryFieldChanged = true"
                              :key="key"
                              disabled
                              dense
                              :readonly="limitedAccess"
                            ></v-text-field>
                          </v-col>
                        </v-col>
                        <v-col lg="6" sm="12" class="border-1">
                          <v-card-title class="font-weight-bold"
                            >Plan Details</v-card-title
                          >
                          <v-col lg="12" sm="12">
                            <v-select
                              v-model="enrollment_details.selectedPlan"
                              :items="patientPlans"
                              label="Plan"
                              outlined
                              dense
                              @change="summaryFieldChanged = true"
                              required
                              :rules="[
                                enrollment_details.selectedPlan != '' ||
                                  'Please choose the patient\'s current plan.',
                              ]"
                              :readonly="limitedAccess"
                            />
                          </v-col>
                          <v-card-title class="font-weight-bold"
                            >Enrollment Details
                            <!-- <v-btn
                              icon
                              small
                              @click="reEnrollDialog = true"
                              :disabled="limitedAccess"
                              ><v-icon small>mdi-pencil-outline</v-icon></v-btn
                            > -->
                          </v-card-title>
                          <v-row justify="center" class="pl-3 pr-3 mb-3">
                            <v-col cols="6"
                              ><v-btn
                                color="primary"
                                block
                                @click="editEnrollment = true"
                                >Edit</v-btn
                              ></v-col
                            >
                            <v-col cols="6"
                              ><v-btn
                                color="success"
                                block
                                @click="reEnrollDialog = true"
                                :disabled="
                                  !enrollment_details.disenrollmentDate
                                "
                                >Re-enroll</v-btn
                              ></v-col
                            >
                          </v-row>
                          <!-- Eff Date -->
                          <v-col cols="12">
                            <v-menu
                              ref="menu4"
                              v-model="menu4"
                              :close-on-content-click="false"
                              :return-value.sync="
                                enrollment_details.effectiveDateOfCoverage
                              "
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                              :readonly="limitedAccess"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    enrollment_details.effectiveDateOfCoverage
                                  "
                                  label="Effective Date of Coverage"
                                  append-icon="mdi-calendar"
                                  clearable
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  @change="summaryFieldChanged = true"
                                  :readonly="limitedAccess"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="
                                  enrollment_details.effectiveDateOfCoverage
                                "
                                :max="new Date().toISOString().substr(0, 10)"
                                min="1900-01-01"
                                @change="summaryFieldChanged = true"
                                :disabled="limitedAccess"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="menu4 = false"
                                  :disabled="limitedAccess"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.menu4[0].save(
                                      enrollment_details.effectiveDateOfCoverage
                                    )
                                  "
                                  :disabled="limitedAccess"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <!-- Disenroll Date -->
                          <v-col cols="12">
                            <v-menu
                              ref="menu5"
                              v-model="menu5"
                              :close-on-content-click="false"
                              :return-value.sync="
                                enrollment_details.disenrollmentDate
                              "
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                              :readonly="limitedAccess"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="enrollment_details.disenrollmentDate"
                                  label="Disenrollment Date"
                                  append-icon="mdi-calendar"
                                  clearable
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  @change="
                                    summaryFieldChanged = true;
                                    enrollmentEdited();
                                  "
                                  :readonly="limitedAccess"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="enrollment_details.disenrollmentDate"
                                min="1900-01-01"
                                :disabled="limitedAccess"
                                @change="
                                  summaryFieldChanged = true;
                                  enrollmentEdited();
                                "
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="menu5 = false"
                                  :disabled="limitedAccess"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.menu5[0].save(
                                      enrollment_details.disenrollmentDate
                                    )
                                  "
                                  :disabled="limitedAccess"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>

                <v-dialog v-model="reEnrollDialog" max-width="600px" persistent>
                  <v-card class="p-3">
                    <v-card-title>Re-Enrollment</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <em
                            >Previous enrollment dates will be added to
                            Enrollment History.</em
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <!-- <div @click="reEnrollDialog = true"> -->
                          <v-menu
                            ref="menuReEnroll1"
                            v-model="menuReEnroll1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                            :readonly="limitedAccess"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="
                                  reEnrollmentInfo.effectiveDateOfCoverage
                                "
                                label="Enrollment Date"
                                append-icon="mdi-calendar"
                                clearable
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :readonly="limitedAccess"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="reEnrollmentInfo.effectiveDateOfCoverage"
                              min="1900-01-01"
                              :disabled="limitedAccess"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="menuReEnroll1 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                @click="
                                  $refs.menuReEnroll1[0].save(
                                    reEnrollmentInfo.enrollmentDate
                                  )
                                "
                                :disabled="limitedAccess"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <v-menu
                            ref="menuReEnroll2"
                            v-model="menuReEnroll2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                            :readonly="limitedAccess"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="reEnrollmentInfo.disenrollmentDate"
                                label="Disenrollment Date"
                                append-icon="mdi-calendar"
                                clearable
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :readonly="limitedAccess"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="reEnrollmentInfo.disenrollmentDate"
                              min="1900-01-01"
                              :disabled="limitedAccess"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="menuReEnroll2 = false"
                                :disabled="limitedAccess"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                @click="
                                  $refs.menuReEnroll2[0].save(
                                    reEnrollmentInfo.disenrollmentDate
                                  )
                                "
                                :disabled="limitedAccess"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="reEnrollmentInfo.selectedPCP"
                            label="PCP Name"
                            outlined
                            dense
                            required
                            :rules="[
                              reEnrollmentInfo.selectedPCP != '' ||
                                'Please enter the patient\'s PCP name.',
                            ]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="reEnrollmentInfo.pcpNotShown"
                            label="PCP Not Shown"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="reEnrollmentInfo.pcpID"
                            label="PCP ID"
                            outlined
                            dense
                            :readonly="limitedAccess"
                          />
                        </v-col>
                        <v-col lg="12" sm="12">
                          <v-select
                            v-model="reEnrollmentInfo.selectedPlan"
                            :items="patientPlans"
                            label="Plan"
                            outlined
                            dense
                            required
                            :rules="[
                              reEnrollmentInfo.selectedPlan != '' ||
                                'Please choose the patient\'s current plan.',
                            ]"
                            :readonly="limitedAccess"
                          />
                        </v-col>
                        <v-col lg="12" sm="12">
                          <v-combobox
                            :items="agentIDs"
                            label="Agent ID"
                            v-model="reEnrollmentInfo.agentNumberAck"
                            hint="Enter or select agent from dropdown"
                            @change="
                              summaryFieldChanged = true;
                              getAgentNameReenroll();
                            "
                            outlined
                            dense
                            :return-object="false"
                            :readonly="limitedAccess"
                          ></v-combobox>
                          <v-text-field
                            v-model="reEnrollmentInfo.agentName"
                            label="Agent Name"
                            outlined
                            @change="summaryFieldChanged = true"
                            disabled
                            dense
                            :readonly="limitedAccess"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        @click="reEnrollPatient()"
                        color="primary"
                        text
                        dense
                        >Yes</v-btn
                      >
                      <v-spacer />
                      <v-btn @click="reEnrollDialog = false" text dense
                        >Cancel</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-form>
            </template>
            <template v-if="item === 'HRA'">
              <v-card v-if="!loading">
                <v-data-iterator
                  :items="patientHRAForms"
                  :items-per-page.sync="itemsPerPage"
                  :hide-default-footer="true"
                >
                  <template
                    v-slot:header="{ pagination, options, updateOptions }"
                  >
                    <v-data-footer
                      class="pt-3 justify-end"
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="rowsPerPageItems"
                    />
                  </template>
                  <template v-slot:default="props">
                    <div
                      id="capture"
                      v-for="formData in props.items"
                      :key="formData._id"
                    >
                      <v-form v-model="hraForm" ref="hraEdit" lazy-validation>
                        <v-row class="px-2">
                          <v-col cols="12">
                            <v-expansion-panels v-model="demographicPanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Demographic</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.memberID"
                                        @change="fieldChangedHRA = true"
                                        label="Member ID"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.memberID != '' ||
                                            'Please enter the patient\'s Member ID',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.medicareID"
                                        @change="fieldChangedHRA = true"
                                        label="Medicare/Medicaid ID"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.medicareID != '' ||
                                            'Please enter the patient\'s Medicare/Medicaid ID',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="5">
                                      <v-text-field
                                        v-model="formData.lastName"
                                        @change="fieldChangedHRA = true"
                                        label="Last Name"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.lastName != '' ||
                                            'Please enter the patient\'s last name',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="5">
                                      <v-text-field
                                        v-model="formData.firstName"
                                        @change="fieldChangedHRA = true"
                                        label="First Name"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.firstName != '' ||
                                            'Please enter the patient\'s first name',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2">
                                      <v-text-field
                                        v-model="formData.middleInitial"
                                        @change="fieldChangedHRA = true"
                                        label="Middle Initial"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.email"
                                        @change="fieldChangedHRA = true"
                                        label="Email"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.email != '' ||
                                            'Please enter the patient\'s email',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-menu
                                        ref="menu6"
                                        v-model="menu6"
                                        :close-on-content-click="false"
                                        :return-value.sync="formData.birthDate"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                        max-width="290px"
                                        :readonly="limitedAccess"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="formData.birthDate"
                                            @change="fieldChangedHRA = true"
                                            label="Birth Date"
                                            append-icon="mdi-calendar"
                                            :readonly="limitedAccess"
                                            outlined
                                            dense
                                            :rules="[
                                              formData.birthDate != '' ||
                                                'Please enter the patient\'s date of birth',
                                            ]"
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          ref="picker"
                                          v-model="formData.birthDate"
                                          @change="fieldChangedHRA = true"
                                          :max="
                                            new Date()
                                              .toISOString()
                                              .substr(0, 10)
                                          "
                                          min="1900-01-01"
                                          :disabled="limitedAccess"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            outlined
                                            color="primary"
                                            @click="menu6 = false"
                                            :disabled="limitedAccess"
                                          >
                                            Cancel
                                          </v-btn>
                                          <v-btn
                                            depressed
                                            color="primary"
                                            @click="
                                              $refs.menu6[0].save(
                                                formData.birthDate
                                              )
                                            "
                                            :disabled="limitedAccess"
                                          >
                                            OK
                                          </v-btn>
                                        </v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.phoneNumber"
                                        @change="fieldChangedHRA = true"
                                        label="Phone Number"
                                        outlined
                                        dense
                                        @input="formatMemberPhoneNum()"
                                        required
                                        :rules="[
                                          formData.phoneNumber != '' ||
                                            'Please enter the patient\'s phone number',
                                          /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/.test(
                                            formData.phoneNumber
                                          ) ||
                                            'Please enter an actual phone number',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="5">
                                      <v-text-field
                                        v-model="formData.address"
                                        @change="fieldChangedHRA = true"
                                        label="Street Address"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.address != '' ||
                                            'Please enter the patient\'s address',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2">
                                      <v-text-field
                                        v-model="formData.city"
                                        @change="fieldChangedHRA = true"
                                        label="City"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.city != '' ||
                                            'Please enter the patient\'s city',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2">
                                      <v-text-field
                                        v-model="formData.state"
                                        @change="fieldChangedHRA = true"
                                        label="State"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.state != '' ||
                                            'Please enter the patient\'s state',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2">
                                      <v-text-field
                                        v-model="formData.zip"
                                        @change="fieldChangedHRA = true"
                                        label="ZIP Code"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.zip != '' ||
                                            'Please enter the patient\'s ZIP code',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.language"
                                        @change="fieldChangedHRA = true"
                                        label="Primary Language(s)"
                                        outlined
                                        dense
                                        required
                                        hint="Enter the primary language of the patient"
                                        :rules="[
                                          formData.language != '' ||
                                            'Please enter the patient\'s primary language',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                      <v-text-field
                                        v-model="formData.height"
                                        @change="fieldChangedHRA = true"
                                        label="Height"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                      <v-text-field
                                        v-model="formData.weight"
                                        @change="fieldChangedHRA = true"
                                        label="Weight"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.race"
                                        @change="fieldChangedHRA = true"
                                        :items="races"
                                        label="Race/ethnicity (select all that are applicable)"
                                        multiple
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.race.length > 0 ||
                                            'Please select the patient\'s race',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.education"
                                        @change="fieldChangedHRA = true"
                                        :items="educationLevels"
                                        label="Highest Level of Education"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.education != null ||
                                            'Please select the highest education level',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels v-model="generalHealthPanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >General Health</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.healthRating"
                                        @change="fieldChangedHRA = true"
                                        :items="rateScaleAnswers"
                                        label="In general, how would you rate your health?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.healthRating !== '' ||
                                            'Please rate the patient\'s health',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.dentalHealth"
                                        @change="fieldChangedHRA = true"
                                        :items="rateScaleAnswers"
                                        label="In general, how would you rate your dental health?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.dentalHealth !== '' ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.erVisits"
                                        @change="fieldChangedHRA = true"
                                        :items="frequencyAnswers"
                                        label="ER visits past 3 months:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.erVisits !== '' ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="
                                          formData.overnightStayHospitals
                                        "
                                        @change="fieldChangedHRA = true"
                                        :items="frequencyAnswers"
                                        label="Overnight stays in hospital past 3 months:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.overnightStayHospitals !==
                                            '' || 'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.pcpCommunication"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No']"
                                        label="PCP Communication:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.pcpCommunication != null ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.pcpCommunicationFreq"
                                        @change="fieldChangedHRA = true"
                                        label="PCP Communication Frequency:"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.haveWill"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No', 'Not Sure']"
                                        label="Living Will or Advanced Directive:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.haveWill !== '' ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.pcpHaveWillCopy"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No']"
                                        label="PCP have Copy:"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.poaOrSurrogate"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No', 'Not Sure']"
                                        label="Power of Attorney or Healcare Surrogate:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.poaOrSurrogate != null ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.noCareCuzBeliefs"
                                        @change="fieldChangedHRA = true"
                                        :items="[
                                          'Yes',
                                          'No',
                                          'Prefer not to answer',
                                        ]"
                                        label="Ever choose not to seek medical attention because of religious or personal beliefs:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.noCareCuzBeliefs != null ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.needSpecialEquipment"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No']"
                                        label="Use or need special DME equipment:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.needSpecialEquipment !=
                                            null || 'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.specialEquipment"
                                        @change="fieldChangedHRA = true"
                                        label="List DME:"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.hasFallen"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No']"
                                        label="Fallen in past 6 months:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.hasFallen != null ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.fallenPCP"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No']"
                                        label="PCP know about any falls:"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="
                                          formData.balanceOrWalkingProblems
                                        "
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No']"
                                        label="Problems with walking or balance:"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.balanceOrWalkingProblems !=
                                            null || 'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.urineLeakage"
                                        @change="fieldChangedHRA = true"
                                        :items="['Yes', 'No']"
                                        label="Have you had problems with urine leakage?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.urineLeakage != null ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.painLevel"
                                        @change="fieldChangedHRA = true"
                                        :items="painLevels"
                                        label="Pain Levels:"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-card-text
                                        class="text-subtitle-1 black--text"
                                        :readonly="limitedAccess"
                                        >Living Situation:
                                        <span class="font-weight-light"
                                          >{{
                                            formData.liveAlone
                                              ? "Live Alone, "
                                              : ""
                                          }}
                                          {{
                                            formData.homebound
                                              ? "Homebound, "
                                              : ""
                                          }}
                                          {{
                                            formData.caregiver
                                              ? "Has a caregiver"
                                              : ""
                                          }}</span
                                        ></v-card-text
                                      >
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.question1"
                                        @change="fieldChangedHRA = true"
                                        label="My health is important to me:"
                                        :items="agreeAnswers"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.question2"
                                        @change="fieldChangedHRA = true"
                                        label="I am ultimately the one responsible for taking care of my health and wellness:"
                                        :items="agreeAnswers"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.question3"
                                        @change="fieldChangedHRA = true"
                                        label="I am confident I can prevent or reduce problems associated with my health:"
                                        :items="agreeAnswers"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.question4"
                                        @change="fieldChangedHRA = true"
                                        label="I am confident I can talk to my doctor about my health concerns, even when he/she does not ask:"
                                        :items="agreeAnswers"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.question5"
                                        @change="fieldChangedHRA = true"
                                        label="I am confident I can follow through on medical treatments or care that I may need
                                      to do at home:"
                                        :items="agreeAnswers"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>

                          <v-col cols="12">
                            <v-expansion-panels v-model="medicalHistoryPanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Medical History</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12" lg="8">
                                      <v-select
                                        v-model="formData.conditions"
                                        @change="fieldChangedHRA = true"
                                        :items="conditionArray"
                                        multiple
                                        outlined
                                        dense
                                        label="What Medical conditions do you have, or have you had in the
                                      past?"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                      <v-text-field
                                        v-model="formData.conditionOther"
                                        @change="fieldChangedHRA = true"
                                        label="If other, what type?"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                      <v-select
                                        v-model="formData.treatments"
                                        @change="fieldChangedHRA = true"
                                        :items="treamentArray"
                                        multiple
                                        outlined
                                        dense
                                        label="Which of the following conditions are you currently receiving
                                      treatment for?"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                      <v-text-field
                                        v-model="formData.treatmentOther"
                                        @change="fieldChangedHRA = true"
                                        label="If other, what type?"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels v-model="activitiesPanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Activities of Daily
                                  Living</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col class="pb-0">
                                      <v-card-subtitle
                                        class="text-subtitle-1 black--text"
                                      >
                                        Do you need help with doing the
                                        following daily activities?
                                      </v-card-subtitle>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" lg="6">
                                      <v-checkbox
                                        v-model="
                                          formData.standingUpFromSittingPosition
                                        "
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Standing up from a sitting position"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                      <v-checkbox
                                        v-model="formData.walkingInTheHouse"
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Walking in the house"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                      <v-checkbox
                                        v-model="
                                          formData.walkingOutsideOfTheHouse
                                        "
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Walking outside of the house"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                      <v-checkbox
                                        v-model="formData.preparingAMeal"
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Preparing a meal"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                      <v-checkbox
                                        v-model="formData.eatingAMeal"
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Eating a meal"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-checkbox
                                        v-model="formData.usingTheToilet"
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Using the toilet"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                      <v-checkbox
                                        v-model="formData.bathing"
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Bathing"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                      <v-checkbox
                                        v-model="formData.gettingDressed"
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Getting dressed"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                      <v-checkbox
                                        v-model="
                                          formData.drivingOrGettingPlaces
                                        "
                                        @change="fieldChangedHRA = true"
                                        hide-details
                                        label="Driving or getting to places"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-checkbox
                                        v-model="formData.someoneWhoCanHelp"
                                        @change="fieldChangedHRA = true"
                                        label="If you need help with any of these activities, do you have someone who can help you?"
                                        :disabled="limitedAccess"
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels v-model="medicationPanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Medication</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.medications"
                                        @change="fieldChangedHRA = true"
                                        :items="medicationItems"
                                        outlined
                                        dense
                                        label="Do you take medications; and if so, how often do you take
                                      medications?"
                                        required
                                        :rules="[
                                          formData.medications != '' ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="formData.medicationAmount"
                                        @change="fieldChangedHRA = true"
                                        :items="medicationAmountItems"
                                        outlined
                                        dense
                                        label="How many medications do you take?"
                                        required
                                        :rules="[
                                          formData.medicationAmount != '' ||
                                            'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field
                                        v-model="formData.pharmacyName"
                                        @change="fieldChangedHRA = true"
                                        label="Pharmacy Name"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.pharmacyName != '' ||
                                            'Please enter the pharmacy name',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.pharmacyAddress"
                                        @change="fieldChangedHRA = true"
                                        label="Pharmacy Address"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.pharmacyAddress != '' ||
                                            'Please enter the pharmacy address',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="formData.pharmacyPhoneNumber"
                                        @change="fieldChangedHRA = true"
                                        label="Phone Number"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        v-model="
                                          formData.groceriesOrMedications
                                        "
                                        @change="fieldChangedHRA = true"
                                        :items="yesNoNaArray"
                                        outlined
                                        dense
                                        required
                                        label="Do you sometimes find you have to choose between buying groceries
                                      or medications?"
                                        :rules="[
                                          formData.groceriesOrMedications !=
                                            '' || 'Please select an answer',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels v-model="preventivePanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Preventive Services</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12">
                                      <v-card-subtitle
                                        class="text-subtitle-1 black--text"
                                      >
                                        When was the last time you had each of
                                        the following? (Please select one for
                                        each type)
                                      </v-card-subtitle>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="yearsDoneArray"
                                        v-model="formData.pneumoniaVaccine"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        required
                                        label="Pneumonia Vaccine"
                                        multiple
                                        :rules="[
                                          (formData.pneumoniaVaccine &&
                                            formData.pneumoniaVaccine.length !==
                                              0) ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="yearsDoneArray"
                                        v-model="formData.fluVaccine"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        required
                                        multiple
                                        label="Flu Vaccine"
                                        :rules="[
                                          (formData.fluVaccine &&
                                            formData.fluVaccine.length !== 0) ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="yearsDoneArray"
                                        v-model="formData.covid19Vaccine"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        required
                                        label="COVID-19 Vaccine"
                                        multiple
                                        :rules="[
                                          (formData.covid19Vaccine &&
                                            formData.covid19Vaccine.length !==
                                              0) ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="yearsDoneArray"
                                        v-model="formData.breastScreening"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        required
                                        multiple
                                        label="Breast Screening (Mammogram)"
                                        :rules="[
                                          (formData.breastScreening &&
                                            formData.breastScreening.length !==
                                              0) ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="yearsDoneArray"
                                        v-model="formData.colorectalScreening"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        required
                                        multiple
                                        label="Colorectal Screening (Colonoscopy)"
                                        :rules="[
                                          (formData.colorectalScreening &&
                                            formData.colorectalScreening
                                              .length !== 0) ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="yearsDoneArray"
                                        v-model="formData.cervicalScreening"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        required
                                        multiple
                                        label="Cervical Screening (PAP Smear)"
                                        :rules="[
                                          (formData.cervicalScreening &&
                                            formData.cervicalScreening
                                              .length !== 0) ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="yearsDoneArray"
                                        v-model="formData.boneDensity"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        multiple
                                        label="Bone Density Screening"
                                        :rules="[
                                          (formData.boneDensity &&
                                            formData.boneDensity.length !==
                                              0) ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels v-model="lifestylePanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Life Style</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12">
                                      <v-select
                                        :items="exerciseArray"
                                        v-model="formData.dailyExercise"
                                        @change="fieldChangedHRA = true"
                                        label="How often do you exercise for at least 20 minutes in a day?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.dailyExercise != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="['Yes', 'No']"
                                        v-model="formData.sleepingPatterns"
                                        @change="fieldChangedHRA = true"
                                        label="In the past 2 weeks, have you had a change in your sleeping
                                        patterns, or are you having trouble obtaining restful
                                        sleep?"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          formData.sleepingPatterns != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="['Yes', 'No']"
                                        v-model="formData.specialDiet"
                                        @change="fieldChangedHRA = true"
                                        label="Are you on a special diet recommended by your doctor (low sodium, cholesterol, fat)?"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          item.specialDiet != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        label="If yes, what type of diet?"
                                        v-model="formData.specialDietOther"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="dietArray"
                                        v-model="
                                          formData.fruitVegitableConsumption
                                        "
                                        label="How often do you eat fresh fruits or vegetables?"
                                        @change="fieldChangedHRA = true"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          item.fruitVegitableConsumption !=
                                            '' || 'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="dietArray"
                                        label="How often do you eat fried or high fat foods (fried fish/checken, bacon, potato chips)?"
                                        v-model="formData.highFatFoods"
                                        @change="fieldChangedHRA = true"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          formData.highFatFoods !== '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="dietArray"
                                        v-model="formData.alcohol"
                                        @change="fieldChangedHRA = true"
                                        label="How often do you drink alcohol?"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          item.alcohol != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="yesNoNaArray"
                                        label="Have you ever felt you should reduce your alcohol
                                      consumption?"
                                        v-model="formData.reduceAlcohol"
                                        @change="fieldChangedHRA = true"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          formData.reduceAlcohol != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="smokingArray"
                                        v-model="formData.lastTimeSmoked"
                                        @change="fieldChangedHRA = true"
                                        label="When was the last time you smoked or used any tobacco product (cigarettes, chew, snuff, pipes, cigars, vapor cigarettes)?"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          formData.lastTimeSmoked != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="yesNoNaArray"
                                        label="Are you interested in quitting smoking?"
                                        v-model="
                                          formData.interestedInQuittingSmoking
                                        "
                                        @change="fieldChangedHRA = true"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          formData.interestedInQuittingSmoking !=
                                            '' || 'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="[
                                          '0',
                                          '1 time',
                                          '2 times',
                                          '3 or more times',
                                        ]"
                                        label="In the past year, how many times have you used an illegal drug or
                                      prescription medication for non-medical reasons?"
                                        v-model="formData.illegalDrugUse"
                                        @change="fieldChangedHRA = true"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          formData.illegalDrugUse != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="['Yes', 'No']"
                                        label="Do you have any hobbies or interests?"
                                        v-model="formData.hobbiesOrInterests"
                                        @change="fieldChangedHRA = true"
                                        required
                                        outlined
                                        dense
                                        :rules="[
                                          formData.hobbiesOrInterests != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        label="If yes, please list"
                                        outlined
                                        dense
                                        v-model="formData.hobbies"
                                        @change="fieldChangedHRA = true"
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels v-model="emotionalHealthPanel">
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Emotional Health</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12">
                                      <v-select
                                        :items="howOftenEmotionalArray"
                                        v-model="formData.nervousAnxious"
                                        @change="fieldChangedHRA = true"
                                        label="Over the past 2 weeks, how often have you felt nervous, anxious, or on edge?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.nervousAnxious != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="howOftenEmotionalArray"
                                        v-model="formData.nonStopWorrying"
                                        @change="fieldChangedHRA = true"
                                        label="Over the past 2 weeks, how often were you not able to stop worrying or control your worrying?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.nonStopWorrying != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="howOftenEmotionalArray"
                                        v-model="formData.downDepressedHopeless"
                                        @change="fieldChangedHRA = true"
                                        label="Over the past 2 weeks, how often have you felt down, depressed, or hopeless?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.downDepressedHopeless !=
                                            '' || 'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="howOftenEmotionalArray"
                                        v-model="formData.interestOrPleasure"
                                        @change="fieldChangedHRA = true"
                                        label="Over the past 2 weeks, how often have you felt little interest or pleasure in doing things?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.interestOrPleasure != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="howOftenArray"
                                        v-model="
                                          formData.socialAndEmotionalSupport
                                        "
                                        @change="fieldChangedHRA = true"
                                        label="How often do you get the social and emotional support you need?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.socialAndEmotionalSupport !=
                                            '' || 'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="howOftenArray"
                                        v-model="formData.stressHandlingLife"
                                        @change="fieldChangedHRA = true"
                                        label="How often do you get stressed handling your health, finances, relationship, or work?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.stressHandlingLife != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="['Yes', 'No']"
                                        v-model="formData.thoughtsOfSuicide"
                                        @change="fieldChangedHRA = true"
                                        label="Over the past 2 weeks, have you had thoughts of suicide?"
                                        outlined
                                        dense
                                        required
                                        :rules="[
                                          formData.thoughtsOfSuicide != '' ||
                                            'Please make a selection',
                                        ]"
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        :items="['Yes', 'No', 'N/A']"
                                        v-model="formData.suicidePlan"
                                        @change="fieldChangedHRA = true"
                                        label="If yes, do you have a plan?"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels
                              v-model="completionPanel"
                              class="mb-3"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="font-weight-bold"
                                  >Completion
                                  Information</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                  class="pa-1"
                                  elevation="2"
                                >
                                  <v-row>
                                    <v-col cols="12" lg="6">
                                      <v-select
                                        :items="roleArray"
                                        label="Who completed this HRA form?"
                                        v-model="formData.whoCompletedForm"
                                        @change="fieldChangedHRA = true"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-text-field
                                        v-model="item.nameOfIndividual"
                                        @change="fieldChangedHRA = true"
                                        label="Full Name Of Individual Filling Out This HRA Form"
                                        outlined
                                        dense
                                        required
                                        :readonly="limitedAccess"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                      <v-menu
                                        ref="menu7"
                                        v-model="menu7"
                                        :close-on-content-click="false"
                                        :return-value.sync="
                                          formData.completionDate
                                        "
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                        max-width="290px"
                                        :readonly="limitedAccess"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="formData.completionDate"
                                            @change="fieldChangedHRA = true"
                                            label="Completion Date"
                                            append-icon="mdi-calendar"
                                            readonly
                                            required
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          ref="picker"
                                          v-model="formData.completionDate"
                                          @change="fieldChangedHRA = true"
                                          :max="
                                            new Date()
                                              .toISOString()
                                              .substr(0, 10)
                                          "
                                          min="1900-01-01"
                                          :disabled="limitedAccess"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            outlined
                                            color="primary"
                                            @click="menu7 = false"
                                            :disabled="limitedAccess"
                                          >
                                            Cancel
                                          </v-btn>
                                          <v-btn
                                            depressed
                                            color="primary"
                                            @click="
                                              $refs.menu7[0].save(
                                                formData.completionDate
                                              )
                                            "
                                            :disabled="limitedAccess"
                                          >
                                            OK
                                          </v-btn>
                                        </v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="12" lg="9">
                                      <v-select
                                        :items="['Yes', 'No']"
                                        v-model="formData.memberPresent"
                                        @change="fieldChangedHRA = true"
                                        label="If not filled out by member, was the Member present while responding to and answering the HRA questions?"
                                        outlined
                                        dense
                                        :readonly="limitedAccess"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>
                      </v-form>
                    </div>
                  </template>
                </v-data-iterator>
              </v-card>

              <v-card v-else>
                <v-row style="height: 100vh">
                  <v-col align-self="center" class="text-center">
                    <v-progress-circular
                      :size="100"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <template v-if="item === 'Enrollment'">
              <!-- Medicare/PCP -->
              <v-row class="pa-2">
                <v-col cols="6">
                  <v-card outlined height="100%" elevation="2">
                    <v-card-title class="font-weight-bold"
                      >Medicare Information</v-card-title
                    >
                    <v-card-text class="text-subtitle-1 black--text"
                      >Medicare Number:
                      <span class="font-weight-light">{{
                        enrollment_details.medicareNumber
                      }}</span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-1 black--text"
                      >Effective Date (A):
                      <span class="font-weight-light">{{
                        formatDate(enrollment_details.partA)
                      }}</span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-1 black--text"
                      >Effective Date (B):
                      <span class="font-weight-light">{{
                        formatDate(enrollment_details.partB)
                      }}</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card outlined height="100%" elevation="2">
                    <v-card-title class="font-weight-bold">PCP</v-card-title>
                    <v-card-text class="text-subtitle-1 black--text"
                      >PCP Name:
                      <span class="font-weight-light">{{
                        enrollment_details.selectedPCP
                      }}</span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-1 black--text"
                      >PCP ID:
                      <span class="font-weight-light">{{
                        enrollment_details.pcpID
                      }}</span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-1 black--text">
                      Existing Patient:
                      <v-icon color="success" v-if="enrollment_details.pcpYes">
                        mdi-check
                      </v-icon>
                      <v-icon color="error" v-else>mdi-close</v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Patient Info/Questions -->
              <v-row class="pa-2">
                <v-col cols="6">
                  <v-card outlined height="100%" elevation="2">
                    <v-card-title class="font-weight-bold"
                      >Patient Info</v-card-title
                    >
                    <v-row>
                      <v-col lg="6" sm="12">
                        <v-card-text class="text-subtitle-1 black--text"
                          >Member ID:
                          <span class="font-weight-light">{{
                            enrollment_details.medicareNumber
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Last Name:
                          <span class="font-weight-light">{{
                            enrollment_details.lastName
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >First Name:
                          <span class="font-weight-light">{{
                            enrollment_details.firstName
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Middle Initial:
                          <span class="font-weight-light">{{
                            enrollment_details.middleInitial
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Date of Birth:
                          <span class="font-weight-light">{{
                            formatDate(enrollment_details.birthDate)
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Sex:
                          <span class="font-weight-light">{{
                            enrollment_details.gender
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Email:
                          <span class="font-weight-light">{{
                            enrollment_details.email
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Phone:
                          <span class="font-weight-light">{{
                            enrollment_details.phoneNumber
                          }}</span>
                        </v-card-text>
                      </v-col>
                      <v-col lg="6" sm="12">
                        <v-card-text class="text-subtitle-1 black--text"
                          >Address 1:
                          <span class="font-weight-light">{{
                            enrollment_details.address
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >City:
                          <span class="font-weight-light">{{
                            enrollment_details.city
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >County:
                          <span class="font-weight-light">{{
                            enrollment_details.county
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >State:
                          <span class="font-weight-light">{{
                            enrollment_details.state
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >ZIP:
                          <span class="font-weight-light">{{
                            enrollment_details.zip
                          }}</span>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card outlined height="100%" elevation="2">
                    <v-card-title class="font-weight-bold"
                      >Questionnaire</v-card-title
                    >
                    <v-card-text class="text-subtitle-1 black--text">
                      Once enrolled, will you have other Medical Health
                      Coverage?
                      <v-icon color="success" v-if="enrollment_details.coverage"
                        >mdi-check</v-icon
                      >
                      <v-icon color="error" v-else>mdi-close</v-icon>
                    </v-card-text>

                    <v-card-text class="text-subtitle-1 black--text">
                      Will you have other PRESCRIPTION drug coverage in addition
                      to Sonder Health Plans?
                      <span :class="getColor(enrollment_details.prescription)">
                        <v-icon
                          color="success"
                          v-if="enrollment_details.prescription"
                          >mdi-check</v-icon
                        >
                        <v-icon color="error" v-else>mdi-close</v-icon>
                      </span>
                    </v-card-text>

                    <v-card-text class="text-subtitle-1 black--text">
                      Are you a resident in a long-term care facility, such as a
                      nursing home?
                      <v-icon
                        color="success"
                        v-if="enrollment_details.longTermCare"
                        >mdi-check</v-icon
                      >
                      <v-icon color="error" v-else>mdi-close</v-icon>
                    </v-card-text>

                    <v-card-text class="text-subtitle-1 black--text">
                      Are you enrolled in your state Medicaid program?
                      <v-icon
                        color="success"
                        v-if="enrollment_details.stateMedicaid"
                        >mdi-check</v-icon
                      >
                      <v-icon color="error" v-else>mdi-close</v-icon>
                    </v-card-text>

                    <v-card-text class="text-subtitle-1 black--text">
                      If you are enrolling in a Complete Health HMO, Diabetic
                      Wellness HMO, Heart Healthy HMO, HMO C-SNP, have you been
                      diagnosed in are you currently being treated for Diabetes,
                      Cardiovascular Disorder, and/or Chronic Heart Failure?
                      <v-icon
                        color="success"
                        v-if="enrollment_details.chronicDisease"
                        >mdi-check</v-icon
                      >
                      <v-icon color="error" v-else>mdi-close</v-icon>
                    </v-card-text>

                    <v-card-text class="text-subtitle-1 black--text">
                      Do you or your spouse work?
                      <v-icon
                        color="success"
                        v-if="enrollment_details.currentlyWorking"
                        >mdi-check</v-icon
                      >
                      <v-icon color="error" v-else>mdi-close</v-icon>
                    </v-card-text>

                    <v-card-text class="text-subtitle-1 black--text"
                      >Please check one of the boxes below to select your
                      language preference:
                      <span class="font-weight-light">{{
                        arrayToString(enrollment_details.languagePref)
                      }}</span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-1 black--text"
                      >Please select one option if you need information in an
                      accessible format:
                      <span class="font-weight-light">{{
                        arrayToString(enrollment_details.accesibleFormat)
                      }}</span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-1 black--text"
                      >Are you Hispanic or Latino?:
                      <span class="font-weight-light">
                        {{
                          enrollment_details.hispanicOrLatino === true
                            ? "Yes"
                            : "No"
                        }}
                      </span>
                    </v-card-text>
                    <v-card-text class="text-subtitle-1 black--text"
                      >Regardless of your answer to the prior question please
                      indicate how you identify yourself (Select one or more):
                      <span class="font-weight-light">{{
                        arrayToString(enrollment_details.ethnicity)
                      }}</span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Agent needs assesment -->
              <v-row v-if="enrollment_details.needsAssessment" class="pa-2">
                <v-col cols="12">
                  <v-card outlined elevation="2">
                    <v-row>
                      <v-col cols="12">
                        <v-card-title class="font-weight-bold"
                          >Agent Needs Assessment</v-card-title
                        >
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-card-text>
                          Name: {{ enrollment_details.needsAssessment.name }}
                        </v-card-text>
                        <v-card-text>
                          DOB: {{ enrollment_details.needsAssessment.DOB }}
                        </v-card-text>
                        <v-card-text>
                          Sex: {{ enrollment_details.needsAssessment.sex }}
                        </v-card-text>
                        <v-card-text>
                          Height:
                          {{ enrollment_details.needsAssessment.height.feet }}'
                          {{
                            enrollment_details.needsAssessment.height.inches
                          }}"
                        </v-card-text>
                        <v-card-text>
                          Weight:
                          {{ enrollment_details.needsAssessment.weight }}lbs
                        </v-card-text>
                        <v-card-text>
                          Primary Language:
                          {{ enrollment_details.needsAssessment.language }}
                        </v-card-text>
                        <v-card-text>
                          Address:
                          {{ enrollment_details.needsAssessment.address }}
                        </v-card-text>
                        <v-card-text>
                          City: {{ enrollment_details.needsAssessment.city }}
                        </v-card-text>
                        <v-card-text>
                          State: {{ enrollment_details.needsAssessment.state }}
                        </v-card-text>
                        <v-card-text>
                          ZIP Code: {{ enrollment_details.needsAssessment.zip }}
                        </v-card-text>
                        <v-card-text>
                          Email: {{ enrollment_details.needsAssessment.email }}
                        </v-card-text>
                        <v-card-text>
                          Okay to contact via email:
                          <v-icon>{{ getIcon() }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Phone: {{ enrollment_details.needsAssessment.phone }}
                        </v-card-text>
                        <v-card-text>
                          Phone Type:
                          {{ enrollment_details.needsAssessment.phoneType }}
                        </v-card-text>
                        <v-card-text>
                          Okay to leave voicemail:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.voicemailOka
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          How would you rate your health?:
                          {{ enrollment_details.needsAssessment.healthRating }}
                        </v-card-text>
                        <v-card-text>
                          Lives Alone:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.liveAlone
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Homebound:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.homebound
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Has Caregiver:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.haveCaregiver
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Sees PCP Regularly:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.seePcpRegularly
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          PCP Name:
                          {{ enrollment_details.needsAssessment.pcpName }}
                        </v-card-text>
                        <v-card-text>
                          POA or Surrogate:
                          {{
                            enrollment_details.needsAssessment.poaOrSurrogate
                          }}
                        </v-card-text>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-card-text>
                          How would you rate your dental health:
                          {{
                            enrollment_details.needsAssessment.dentalHealhRating
                          }}
                        </v-card-text>
                        <v-card-text>
                          Dentist:
                          {{ enrollment_details.needsAssessment.dentist }}
                        </v-card-text>
                        <v-card-text>
                          Use DME Equipment:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.useDmeEquipment
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          DME Equipment Used:
                          {{
                            enrollment_details.needsAssessment.dmeEquipmentUsed
                          }}
                        </v-card-text>
                        <v-card-text>
                          Uses Alcohol or Drugs:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.alcoholDrug
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Auto Immune Disorder:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment
                                .autoimmuneDisorder
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Cancer:
                          <v-icon>{{
                            getIcon(enrollment_details.needsAssessment.cancer)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          CVD Disease:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.cvdDisease
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Chronic Heart Failure:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment
                                .chronicHeartFailure
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Dementia:
                          <v-icon>{{
                            getIcon(enrollment_details.needsAssessment.dementia)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Diabetes:
                          <v-icon>{{
                            getIcon(enrollment_details.needsAssessment.diabetes)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          ESLD:
                          <v-icon>{{
                            getIcon(enrollment_details.needsAssessment.esld)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          ESRD:
                          <v-icon>{{
                            getIcon(enrollment_details.needsAssessment.esrd)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Severe Hematologic Disorder:
                          <v-icon>
                            {{
                              getIcon(
                                enrollment_details.needsAssessment
                                  .severeHematologicDisorder
                              )
                            }}</v-icon
                          >
                        </v-card-text>
                        <v-card-text>
                          HIV/AIDS:
                          <v-icon>{{
                            getIcon(enrollment_details.needsAssessment.hivAids)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Chronic Lung Disorders:
                          <v-icon
                            >{{
                              getIcon(
                                enrollment_details.needsAssessment
                                  .chronicLungDisorders
                              )
                            }}
                          </v-icon>
                        </v-card-text>
                        <v-card-text>
                          Chronic Disabling Mental Health:
                          <v-icon>
                            {{
                              getIcon(
                                enrollment_details.needsAssessment
                                  .chronicDisablingMentalHealth
                              )
                            }}</v-icon
                          >
                        </v-card-text>
                        <v-card-text>
                          Neurologic Disorders:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment
                                .neurologicDisorders
                            )
                          }}</v-icon>
                        </v-card-text>
                        <v-card-text>
                          Stroke:
                          <v-icon>{{
                            getIcon(enrollment_details.needsAssessment.stroke)
                          }}</v-icon>
                        </v-card-text>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="
                          enrollment_details.needsAssessment
                            .medicationInformation.length > 0
                        "
                      >
                        <v-card-title> Medication Information </v-card-title>
                        <v-card-text
                          v-for="(med, index) in enrollment_details
                            .needsAssessment.medicationInformation"
                          :key="index"
                        >
                          <span>Medication Used: </span
                          >{{ med.medicationName }} {{ med.dosage }}
                          {{ med.frequency }}
                        </v-card-text>
                      </v-col>
                      <v-col cols="12">
                        <v-card-text>
                          Agent Name:
                          {{ enrollment_details.needsAssessment.agentName }}
                        </v-card-text>
                        <v-card-text>
                          Completed On:
                          {{ enrollment_details.needsAssessment.completedDate }}
                        </v-card-text>
                        <v-card-text>
                          Person Filling Out Form:
                          {{
                            enrollment_details.needsAssessment.personFillingForm
                          }}
                        </v-card-text>
                        <v-card-text>
                          Member Present:
                          <v-icon>{{
                            getIcon(
                              enrollment_details.needsAssessment.memberPresent
                            )
                          }}</v-icon>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Payment Options -->
              <v-row
                class="pa-2"
                v-if="user.role === 'superuser' || user.role === 'manager'"
              >
                <v-col cols="12">
                  <v-card outlined elevation="2">
                    <v-card-title class="font-weight-bold"
                      >Payment Options</v-card-title
                    >
                    <v-row>
                      <v-col cols="6">
                        <v-card-text class="text-subtitle-1 black--text">
                          Receive a Bill:
                          <v-icon color="success" v-if="enrollment_details.bill"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Automatic deduction (Social Security or RBB):
                          <v-icon
                            color="success"
                            v-if="enrollment_details.deduction"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Electronic Funds Transfer (EFT):
                          <v-icon color="success" v-if="enrollment_details.eft"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Checking
                          <v-icon
                            color="success"
                            v-if="enrollment_details.checking"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Savings
                          <v-icon
                            color="success"
                            v-if="enrollment_details.savings"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                      </v-col>
                      <v-col cols="6">
                        <v-card-text class="text-subtitle-1 black--text"
                          >Account Holder Name:
                          <span class="font-weight-light">{{
                            enrollment_details.acctHolderName
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Bank Name:
                          <span class="font-weight-light">{{
                            enrollment_details.depositoryBankName
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Routing Number:
                          <span class="font-weight-light">{{
                            enrollment_details.bankRouting
                          }}</span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Account Number:
                          <span class="font-weight-light">{{
                            enrollment_details.bankAcctNumber
                          }}</span>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <!-- CSNP Info -->
              <v-row
                class="pa-2"
                v-if="
                  (user.role === 'superuser' || user.role === 'manager') &&
                  enrollment_details.CSNPSignature
                "
              >
                <v-col cols="12">
                  <v-card outlined elevation="2">
                    <v-card-title class="font-weight-bold"
                      >CSNP Info</v-card-title
                    >
                    <v-row>
                      <v-col cols="6">
                        <v-card-text class="text-subtitle-1 black--text">
                          Diabetes:
                          <v-icon
                            color="success"
                            v-if="enrollment_details.diabetesStatus"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Cardiovascular Disease (CVD):
                          <v-icon
                            color="success"
                            v-if="
                              enrollment_details.cardioVascularDiseaseStatus
                            "
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Heart Attack/Blood Clot Risk:
                          <v-icon
                            color="success"
                            v-if="enrollment_details.atRiskHeartAttackBloodClot"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Heart Pain(angina) / Leg Pain(claudication):
                          <v-icon
                            color="success"
                            v-if="enrollment_details.legHeartActivePain"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text">
                          Uses Blood Sugar Medication:
                          <v-icon
                            color="success"
                            v-if="enrollment_details.useBloodSugarMedicine"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Uses Medication for Heart or Circulation:
                          <v-icon
                            color="success"
                            v-if="
                              enrollment_details.useHeartOrCirculationMedicine
                            "
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                      </v-col>
                      <v-col cols="6">
                        <v-card-text class="text-subtitle-1 black--text"
                          >Checks Blood Sugar at Home:
                          <v-icon
                            color="success"
                            v-if="enrollment_details.checkBloodSugarHome"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >High Blood Sugar:
                          <v-icon
                            color="success"
                            v-if="enrollment_details.hasHighBloodSugar"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Irregular Heartbeat:
                          <v-icon
                            color="success"
                            v-if="enrollment_details.hasIrregularHeartbeat"
                            >mdi-check</v-icon
                          >
                          <v-icon color="error" v-else>mdi-close</v-icon>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Provider Name:
                          <span>
                            {{ enrollment_details.ProviderLastName }},
                            {{ enrollment_details.ProviderFirstName }}
                          </span>
                        </v-card-text>
                        <v-card-text class="text-subtitle-1 black--text"
                          >Provider Phone:
                          <span>
                            {{ enrollment_details.ProviderPhone }}
                          </span>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Period Statements -->
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-card outlined elevation="2">
                    <v-card-title class="font-weight-bold"
                      >Period Statements</v-card-title
                    >
                    <v-card-text
                      v-if="enrollment_details.NEW"
                      class="text-subtitle-1 black--text"
                    >
                      NEW:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.LEC"
                      class="text-subtitle-1 black--text"
                    >
                      LEC:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.AEP"
                      class="text-subtitle-1 black--text"
                    >
                      AEP:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.CIE"
                      class="text-subtitle-1 black--text"
                    >
                      CIE:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.DST"
                      class="text-subtitle-1 black--text"
                    >
                      DST:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.EXC"
                      class="text-subtitle-1 black--text"
                    >
                      EXC:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.EXT"
                      class="text-subtitle-1 black--text"
                    >
                      EXT:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.INC"
                      class="text-subtitle-1 black--text"
                    >
                      INC:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.LAW"
                      class="text-subtitle-1 black--text"
                    >
                      LAW:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.LOC"
                      class="text-subtitle-1 black--text"
                    >
                      LOC:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.LTC"
                      class="text-subtitle-1 black--text"
                    >
                      LTC:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.MCC"
                      class="text-subtitle-1 black--text"
                    >
                      MCC:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.MCD"
                      class="text-subtitle-1 black--text"
                    >
                      MCD:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.MOV"
                      class="text-subtitle-1 black--text"
                    >
                      MOV:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.NON"
                      class="text-subtitle-1 black--text"
                    >
                      NON:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.OEP"
                      class="text-subtitle-1 black--text"
                    >
                      OEP:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.PAC"
                      class="text-subtitle-1 black--text"
                    >
                      PAC:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.RUS"
                      class="text-subtitle-1 black--text"
                    >
                      RUS:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.SNP"
                      class="text-subtitle-1 black--text"
                    >
                      SNP:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.SSEP"
                      class="text-subtitle-1 black--text"
                    >
                      S-SEP:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.SPA"
                      class="text-subtitle-1 black--text"
                    >
                      SPA:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.TER"
                      class="text-subtitle-1 black--text"
                    >
                      TER:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      v-if="enrollment_details.OTH"
                      class="text-subtitle-1 black--text"
                    >
                      OTH:
                      <v-icon color="success">mdi-check</v-icon>
                    </v-card-text>
                    <v-card-text
                      class="text-subtitle-1 black--text"
                      v-if="enrollment_details.EFF_DATE"
                      >Effective Date (EP):
                      <span class="font-weight-light">{{
                        formatDate(enrollment_details.EFF_DATE)
                      }}</span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="pa-2 mb-4">
                <v-col cols="12">
                  <v-card outlined elevation="2">
                    <v-card-title class="font-weight-bold"
                      >E-Signature</v-card-title
                    >
                    <img
                      style="width: 50%"
                      v-bind:src="enrollment_details.signature"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-if="item === 'Enrollment History'">
              <v-row class="pt-2 px-2" v-if="duplicates.length">
                <v-col cols="12">
                  <v-card outlined elevation="2" border="left" colored-border>
                    <!-- Duplicate Enrollment -->
                    <v-alert
                      type="warning"
                      border="left"
                      :icon="false"
                      colored-border
                      class="px-2 my-0 mr-2"
                    >
                      <v-list>
                        <v-list-item class="warning--text">
                          <v-icon color="warning" small
                            >mdi-exclamation-thick</v-icon
                          >
                          <em
                            >This application has been submitted more than
                            once.</em
                          >
                        </v-list-item>
                        <v-list-item
                          v-for="duplicate in duplicates"
                          :key="duplicate._id"
                        >
                          <span>
                            Application created on
                            <strong>{{ duplicate.created.slice(0, 10) }}</strong
                            ><span v-if="duplicate.agentName"
                              >, by
                              <strong>{{ duplicate.agentName }}</strong></span
                            >. Enrollment Status:
                            <strong>{{ duplicate.enrollmentStatus }}</strong>
                          </span>
                        </v-list-item>
                      </v-list>
                    </v-alert>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="pa-2" v-if="enrollment_details.enrollmentHistory">
                <v-col cols="12">
                  <v-card outlined elevation="2">
                    <v-card-title class="font-weight-bold">
                      Enrollment History
                    </v-card-title>
                    <v-timeline dense>
                      <v-timeline-item
                        v-for="(
                          enrollmentPeriod, i
                        ) in enrollment_details.enrollmentHistory"
                        :key="enrollmentPeriod._id"
                        small
                        :color="i % 2 === 0 ? 'primary' : 'secondary'"
                      >
                        <v-card elevation="0" outlined class="mr-5">
                          <v-card-text
                            class="text-subtitle-1 black--text pt-5 pb-2"
                          >
                            <p
                              v-if="
                                i ===
                                enrollment_details.enrollmentHistory.length - 1
                              "
                              class="primary--text"
                            >
                              <em>Current Enrollment Period</em>
                            </p>
                            <p>
                              Application Received:
                              <span class="font-weight-light">{{
                                new Date(
                                  enrollment_details.created
                                ).toDateString()
                              }}</span>
                            </p>
                            <p v-if="enrollmentPeriod.enrollmentDate">
                              Enrolled:
                              <span class="font-weight-light">{{
                                new Date(
                                  enrollmentPeriod.enrollmentDate
                                ).toDateString()
                              }}</span>
                            </p>
                            <p>
                              Disenrolled:
                              <span
                                v-if="enrollmentPeriod.disenrollmentDate"
                                class="font-weight-light"
                                >{{
                                  new Date(
                                    enrollmentPeriod.disenrollmentDate
                                  ).toDateString()
                                }}</span
                              >
                            </p>
                            <p>
                              PCP:
                              <span class="font-weight-light">{{
                                enrollmentPeriod.selectedPCP
                              }}</span>
                            </p>
                            <p>
                              PCP NPN:
                              <span class="font-weight-light">{{
                                enrollmentPeriod.pcpID
                              }}</span>
                            </p>
                            <p>
                              Agent:
                              <span class="font-weight-light">{{
                                enrollmentPeriod.agentName
                              }}</span>
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="px-2 pb-2">
                <v-col cols="12">
                  <v-card outlined elevation="2">
                    <v-card-title class="font-weight-bold"
                      >PCP Care History</v-card-title
                    >
                    <v-timeline dense>
                      <v-timeline-item
                        v-for="(
                          carePeriod, i
                        ) in enrollment_details.pcpCareHistory"
                        :key="carePeriod._id"
                        small
                        :color="i % 2 === 0 ? 'primary' : 'secondary'"
                      >
                        <v-card elevation="0" outlined class="mr-5">
                          <v-card-text
                            class="text-subtitle-1 black--text pt-5 pb-2"
                          >
                            <p
                              v-if="
                                i ===
                                enrollment_details.pcpCareHistory.length - 1
                              "
                              class="primary--text"
                            >
                              <em>Current</em>
                            </p>
                            <p>
                              PCP Name:
                              <span class="font-weight-light">{{
                                carePeriod.selectedPCP
                              }}</span>
                            </p>
                            <p>
                              PCP ID:
                              <span class="font-weight-light">{{
                                carePeriod.pcpID
                              }}</span>
                            </p>
                            <p>
                              PCP Care Start Date:
                              <span class="font-weight-light">{{
                                new Date(carePeriod.pcpCareStart).toDateString()
                              }}</span>
                            </p>
                            <p>
                              PCP Care End Date:
                              <span
                                v-if="carePeriod.pcpCareEnd"
                                class="font-weight-light"
                                >{{
                                  new Date(carePeriod.pcpCareEnd).toDateString()
                                }}</span
                              >
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-if="item === 'Tasks'">
              <patient-tasks
                :enrolleeID="enrollee_id"
                :user="user"
                :key="patientTasksKey"
              />
            </template>
            <template v-if="item === 'Communications'">
              <member-communications
                @addNote="addNewNote"
                @editNote="editNote"
                @deleteNote="deleteNote"
                @handleFilesChanged="uploadAttachments"
                @getDocuments="getDocuments"
                :notes="enrollment_details.notes"
                :user="user"
                :enrolleeID="enrollee_id"
              />
            </template>
            <template v-if="item === 'Documents'">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-card class="pa-2 mx-3 transparent-card" outlined>
                    <v-card-title class="text-h4" style="width: 100%">
                      <v-row class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">Documents</v-col>
                        <v-col class="pa-0 ma-0">
                          <file-selector
                            class="float-right"
                            accept-extensions=".jpg,.jpeg,.png,.pdf"
                            :multiple="true"
                            :max-file-size="5 * 1024 * 1024"
                            @changed="handleFilesChanged"
                            :disabled="limitedAccess"
                          >
                            <v-btn
                              color="accent"
                              depressed
                              :loading="fileUploading"
                              :disabled="fileUploading || limitedAccess"
                            >
                              <v-icon class="mr-2">mdi-file-upload</v-icon>
                              Upload File
                            </v-btn>
                          </file-selector>
                        </v-col>
                      </v-row>
                    </v-card-title>

                    <v-card-text>
                      <v-row v-if="documentLoading" justify="center">
                        <v-progress-circular
                          :loading="loading"
                          indeterminate
                          color="primary"
                          size="75"
                          width="5"
                        >
                        </v-progress-circular>
                      </v-row>

                      <div
                        v-if="
                          enrollee_id &&
                          !documentLoading &&
                          documentList.length == 0
                        "
                      >
                        No documents to display.
                      </div>

                      <v-row
                        v-if="
                          enrollee_id &&
                          !documentLoading &&
                          documentList.length > 0
                        "
                        class="align-content-center"
                      >
                        <v-col
                          v-for="(item, index) in documentList"
                          :key="index"
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                        >
                          <v-card height="100%">
                            <v-img
                              v-if="
                                item.date.substr(item.date.length - 3) !== 'pdf'
                              "
                              :src="item.url"
                              style="
                                display: inline-block;
                                height: 150px;
                                overflow-y: hidden;
                                box-shadow: inset 0 0 3px #000000;
                              "
                            ></v-img>

                            <pdf
                              v-if="
                                item.date.substr(item.date.length - 3) === 'pdf'
                              "
                              :src="item.url"
                              style="
                                display: inline-block;
                                height: 150px;
                                overflow-y: hidden;
                                box-shadow: inset 0 0 3px #000000;
                              "
                            ></pdf>

                            <v-card-title class="text-capitalize">
                              {{ item.name ? formatDocName(item) : "-" }}
                            </v-card-title>

                            <v-card-subtitle class="pb-0">
                              {{ item.date.substr(0, 10) }}
                            </v-card-subtitle>

                            <v-card-actions class="mr-0">
                              <v-spacer></v-spacer>

                              <v-hover v-slot:default="{ hover }">
                                <a
                                  :href="item.url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn
                                    :class="hover ? 'success--text' : ''"
                                    text
                                    small
                                  >
                                    <v-icon>mdi-download-outline</v-icon>
                                  </v-btn>
                                </a>
                              </v-hover>

                              <v-hover v-slot:default="{ hover }">
                                <v-btn
                                  @click="openDeleteConfirmation(item)"
                                  :class="hover ? 'error--text' : ''"
                                  text
                                  small
                                >
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </v-hover>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-dialog
                      v-model="deleteDocumentDialog"
                      max-width="450px"
                      persistent
                    >
                      <v-card>
                        <v-card-title
                          style="word-break: break-word"
                          class="error--text text-h5"
                        >
                          <v-icon class="error--text mr-2" large
                            >mdi-alert-outline</v-icon
                          >
                          Are you sure?
                        </v-card-title>

                        <v-card-text class="text-body-1">
                          Deleting a document is a permenant action.
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click="closeDeleteConfirmation"
                            color="accent"
                            text
                          >
                            Cancel
                          </v-btn>

                          <v-btn @click="deleteDocument" color="success" text>
                            Yes, Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-snackbar
                      v-model="documentSnackBar"
                      timeout="3000"
                      color="error"
                    >
                      {{ errorMessage }}
                    </v-snackbar>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-if="item === 'Single Case Agreements'">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-card class="pa-2 mx-3 transparent-card" outlined>
                    <v-card-title class="text-h4" style="width: 100%">
                      <v-row class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">Single Case Agreements</v-col>
                        <v-spacer></v-spacer>
                        <v-col class="pa-0 ma-0 text-right">
                          <v-btn
                            color="accent"
                            depressed
                            @click="openAddSCADialog"
                            class="mt-1"
                          >
                            <v-icon>mdi-plus</v-icon>
                            Add Single Case Agreement
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>

                    <v-card-text>
                      <v-row v-if="documentLoading" justify="center">
                        <v-progress-circular
                          :loading="loading"
                          indeterminate
                          color="primary"
                          size="75"
                          width="5"
                        ></v-progress-circular>
                      </v-row>

                      <div
                        v-if="
                          enrollee_id && !documentLoading && scaList.length == 0
                        "
                      >
                        No documents to display.
                      </div>
                      <v-row
                        v-if="
                          enrollee_id && !documentLoading && newList.length > 0
                        "
                        class="align-content-center"
                      >
                        <v-col
                          v-for="(item, index) in newList"
                          :key="index"
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                        >
                          <v-card>
                            <v-img
                              v-if="
                                item.pdf.date.substr(
                                  item.pdf.date.length - 3
                                ) !== 'pdf'
                              "
                              :src="item.pdf.url"
                              style="
                                display: inline-block;
                                height: 150px;
                                overflow-y: hidden;
                                box-shadow: inset 0 0 3px #000000;
                              "
                            ></v-img>

                            <pdf
                              v-if="
                                item.pdf.date.substr(
                                  item.pdf.date.length - 3
                                ) === 'pdf'
                              "
                              :src="item.pdf.url"
                              style="
                                display: inline-block;
                                height: 150px;
                                overflow-y: hidden;
                                box-shadow: inset 0 0 3px #000000;
                              "
                            ></pdf>
                            <v-card-title class="text-capitalize">
                              {{
                                item.pdf.name ? formatDocName(item.pdf) : "-"
                              }}
                            </v-card-title>

                            <v-card-subtitle class="pb-0 ma-0">
                              {{ item.pdf.date.substr(0, 10) }}
                            </v-card-subtitle>
                            <v-card-subtitle class="">
                              CPT Range: {{ item.details.cptMin }} -
                              {{ item.details.cptMax }}
                            </v-card-subtitle>

                            <v-card-actions class="mr-0">
                              <v-spacer></v-spacer>

                              <v-hover v-slot:default="{ hover }">
                                <a
                                  :href="item.pdf.url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn
                                    :class="hover ? 'success--text' : ''"
                                    text
                                    small
                                  >
                                    <v-icon>mdi-download-outline</v-icon>
                                  </v-btn>
                                </a>
                              </v-hover>

                              <v-hover v-slot:default="{ hover }">
                                <v-btn
                                  :class="hover ? 'error--text' : ''"
                                  @click="openDeleteSCADialog(item)"
                                  text
                                  small
                                >
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </v-hover>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-dialog
                      v-model="addSCADialog"
                      max-width="600px"
                      persistent
                    >
                      <v-card>
                        <v-toolbar dark color="primary" class="mx-auto">
                          <v-card-title>Add Single Case Agreement</v-card-title>
                          <v-spacer></v-spacer>
                          <v-btn icon dark @click="closeAddSCAConfirmation">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-card-text>
                          <v-row class="mt-3">
                            <v-col cols="6">
                              <v-text-field
                                v-model="scaForm.memberName"
                                label="Member Name"
                                dense
                                outlined
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="scaForm.memberID"
                                label="Member ID"
                                dense
                                outlined
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                v-model="scaForm.provName"
                                label="Provider Name"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="scaForm.provNPI"
                                label="Provider NPI"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <v-menu
                                ref="menu9"
                                v-model="menu9"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                max-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="scaForm.startDate"
                                    label="Agreement Effective Date"
                                    append-icon="mdi-calendar"
                                    clearable
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  ref="picker"
                                  v-model="scaForm.startDate"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    outlined
                                    color="primary"
                                    @click="menu9 = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    depressed
                                    color="primary"
                                    @click="
                                      $refs.menu9[0].save(scaForm.startDate)
                                    "
                                    :disabled="limitedAccess"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>

                            <v-col cols="6">
                              <v-menu
                                ref="menu10"
                                v-model="menu10"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                max-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="scaForm.endDate"
                                    label="Agreement End Date"
                                    append-icon="mdi-calendar"
                                    clearable
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  ref="picker"
                                  v-model="scaForm.endDate"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    outlined
                                    color="primary"
                                    @click="menu10 = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    depressed
                                    color="primary"
                                    @click="
                                      $refs.menu10[0].save(scaForm.endDate)
                                    "
                                    :disabled="limitedAccess"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                v-model="scaForm.cptMin"
                                label="Code Minimum Number"
                                dense
                                outlined
                                required
                                type="Number"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                v-model="scaForm.cptMax"
                                label="Code Maximum Number"
                                dense
                                outlined
                                required
                                type="Number"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <file-selector
                            class="float-right"
                            accept-extensions=".jpg,.jpeg,.png,.pdf"
                            :multiple="true"
                            :max-file-size="5 * 1024 * 1024"
                            @changed="handleSCAChanged"
                            :disabled="limitedAccess"
                          >
                            <v-btn
                              color="accent"
                              depressed
                              :loading="fileUploading"
                              :disabled="fileUploading || limitedAccess"
                              class="mb-3"
                            >
                              <v-icon class="mr-2">mdi-file-upload</v-icon>
                              Upload and Submit
                            </v-btn>
                          </file-selector>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="deleteSCADialog"
                      max-width="450px"
                      persistent
                    >
                      <v-card>
                        <v-card-title
                          style="word-break: break-word"
                          class="error--text text-h5"
                        >
                          <v-icon class="error--text mr-2" large
                            >mdi-alert-outline</v-icon
                          >
                          Are you sure?
                        </v-card-title>

                        <v-card-text class="text-body-1">
                          Deleting a document is a permenant action.
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click="closeDeleteSCAConfirmation"
                            color="accent"
                            text
                          >
                            Cancel
                          </v-btn>

                          <v-btn @click="deleteSCA" color="success" text>
                            Yes, Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-skeleton-loader>
    </v-card>

    <v-dialog v-model="closeDialog" max-width="500px">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>

        <v-card-text>
          Please confirm to exit without saving changes. Any unsaved changes
          will be lost.
        </v-card-text>

        <v-card-actions>
          <v-btn @click="closeDialog = false" text> Cancel </v-btn>

          <v-spacer></v-spacer>

          <v-btn @click="closeModal()" color="accent" text> Exit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="saveDialog" max-width="500px">
      <v-card>
        <v-card-title> Do you want to save changes? </v-card-title>

        <v-card-text>
          Please confirm changes to this document are accurate before saving.
        </v-card-text>

        <v-card-actions>
          <v-btn @click="saveDialog = false" text> Cancel </v-btn>

          <v-spacer></v-spacer>

          <v-btn @click="saveChanges()" color="success" text> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
import ExportCSV from "vue-json-csv";
import pdf from "vue-pdf";
import MemberCommunications from "./enrollment-components/MemberCommunications.vue";
import PatientTasks from "./enrollment-components/PatientTasks.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PatientModal",
  props: ["enrollee", "user"],
  components: {
    ExportCSV,
    pdf,
    MemberCommunications,
    PatientTasks,
  },
  data() {
    return {
      scaForm: {
        id: "",
        memberName: "",
        memberID: "",
        provName: "",
        provNPI: "",
        startDate: "",
        endDate: "",
        cptMin: "",
        cptMax: "",
      },
      menuReEnroll1: false,
      menuReEnroll2: false,
      pcpStartMenu: false,
      pcpEndMenu: false,
      pcpList: [],
      reEnrollmentInfo: {},
      hraForm: false,
      agentID: "",
      agentIDs: [],
      agentName: "",
      demographicPanel: 1,
      demographicForm: false,
      generalHealthPanel: 1,
      generalHealthForm: false,
      medicalHistoryPanel: 1,
      medicalHistoryForm: false,
      activitiesPanel: 1,
      activitiesForm: false,
      medicationPanel: 1,
      medicationForm: false,
      preventivePanel: 1,
      preventiveForm: false,
      lifestylePanel: 1,
      lifestyleForm: false,
      emotionalHealthPanel: 1,
      emotionalHealthForm: false,
      completionPanel: 1,
      completionForm: false,
      fieldChangedHRA: false,
      rowsPerPageItems: [],
      medicalAffiliates: [],
      dialog: false,
      key: 0,
      loading: false,
      enrollee_id: "",
      enrollment_details: {},
      enrollmentHistory: [],
      csvData: {},
      tab: null,
      sex: ["Male", "Female"],
      countyList: [
        "Cobb",
        "Dekalb",
        "Fulton",
        "Gwinnett",
        "Fayette",
        "Douglas",
        "Cherokee",
        "Henry",
        "Coweta",
        "Rockdale",
        "Clayton",
        "Paulding",
      ],
      modalTabs: [
        "Summary",
        "HRA",
        "Enrollment",
        "Enrollment History",
        "Tasks",
        "Communications",
        "Documents",
        "Single Case Agreements",
      ],
      races: [
        "African American or Black",
        "Asian",
        "White or Caucasian",
        "Hispanic",
        "Pacific Islander",
        "Native Alaskan",
        "Native American",
        "Native Hawaiian",
        "Indian",
        "Other",
        "Prefer not to answer",
      ],
      educationLevels: [
        "8th grade or less",
        "Some high school, but did not graduate",
        "High school or GED",
        "Technical School/Training",
        "Some college",
        "2 year college degree",
        "4 year college degree",
      ],
      rateScaleAnswers: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
      frequencyAnswers: ["0", "1 time", "2 times", "3 or more times"],
      painLevels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      agreeAnswers: [
        "Strongly Disagree",
        "Disagree",
        "Agree",
        "Strongly Agree",
      ],
      conditionArray: [
        "Anxiety",
        "Asthma",
        "Bi-polar Disorder",
        "Blood Disorder",
        "Cancer",
        "COPD/Emphysema",
        "Coronary Artery Disease",
        "Dementia/Alzheimer's",
        "Depression",
        "Diabetes (Type 1)",
        "Diabetes (Type 2)",
        "GERD/Stomach Issues",
        "Hearing Problems",
        "Heart Failure (CHF)",
        "Hypertension",
        "Organ Transplant",
        "Osteoarthritis(OA)",
        "Osteoporosis",
        "Peripheral Vascular Disease (PVD)",
        "Renal/Kiden Failure",
        "Rheumatoid Arthritis (RA)",
        "Seizures",
        "Schizophrenia",
        "Skin Issues/Wound",
        "Stroke",
        "Vision Problems",
        "Other",
      ],
      treamentArray: [
        "Anxiety/Depression",
        "Arthritis",
        "Asthma",
        "Bi-Polar/Schizophrenia",
        "Cancer",
        "COPD/Emphysema",
        "Coronary Artery Disease",
        "Dementia/Alzheimer's",
        "Diabetes",
        "Heart Failure (CHF)",
        "Hypertension",
        "Renal/Kiden Failure",
        "Stroke",
        "Other",
      ],
      yearsDoneArray: [
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "Within The Last 10 Years",
        "Never",
        "N/A",
      ],
      dietArray: ["Daily", "Often", "Less than 3 times a week", "Never"],
      yesNoNaArray: ["Yes", "No", "N/A"],
      medicationItems: [
        "No, never",
        "Yes, daily",
        "Yes, weekly",
        "Yes, as needed",
      ],
      medicationAmountItems: ["0", "1-3", "4-5", "6-7", "8+"],
      exerciseArray: [
        "Daily",
        "3 or more times a week",
        "Less than 3 times a week",
        "Never",
      ],
      smokingArray: [
        "Today",
        "Last week",
        "Last month",
        "3 months ago",
        "Last year",
        "1-5 years ago",
        "Longer than 5 years ago",
        "Never",
      ],
      howOftenEmotionalArray: [
        "Not at all",
        "Several days",
        "More days than not",
        "Nearly every day",
      ],
      howOftenArray: ["Always", "Often", "Sometimes", "Rarely", "Never"],
      roleArray: [
        "Member",
        "PCP",
        "Spouse",
        "Son/Daughter",
        "Caregiver",
        "Other",
      ],
      edit: false,
      editEnrollment: false,
      newMemberId: "",
      patientHRAForms: [],
      itemsPerPage: 1,
      documentSnackBar: false,
      documentLoading: false,
      fileUploading: false,
      documentList: [],
      scaList: [],
      snackbar: false,
      errorMessage: "",
      deleteDocumentDialog: false,
      deleteSCADialog: false,
      docToDelete: {},
      summaryChanges: false,
      summaryFieldChanged: false,
      patientPlans: [],
      selectedPlan: "",
      closeDialog: false,
      saveDialog: false,
      birthDate: null,
      effectiveDatePartA: null,
      effectiveDatePartB: null,
      effectiveDateOfCoverage: null,
      disenrollmentDate: null,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      menu10: false,
      limitedAccess: false,
      patientTasksKey: 0,
      storedFiles: undefined,
      isSCA: false,
      addSCADialog: false,
      newList: [],
      reEnrollDialog: false,
      reEnrolling: false,
      letterMenu: false,
      duplicates: [],
      memberIdCreatedMenu: false,
      ackOfRecieptMenu: false,
      practiceTypes: [
        "General Practice",
        "Family Medicine",
        "Internal Medicine",
        "Geriatrics",
      ],
      allowPCPEdits: false,
    };
  },
  methods: {
    async getMedicalAffiliates() {
      try {
        const data = await axios.get(`/api/affiliates/all/${false}/${true}`);
        this.medicalAffiliates = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    openAddSCADialog() {
      this.addSCADialog = true;
      this.scaForm.memberName = this.enrollment_details.name;
      this.scaForm.memberID = this.enrollment_details.memberID;
    },
    closeAddSCAConfirmation() {
      (this.addSCADialog = false),
        (this.scaForm = {
          id: "",
          memberName: "",
          memberID: "",
          provName: "",
          provNPI: "",
          startDate: "",
          endDate: "",
          cptMin: "",
          cptMax: "",
        });
    },
    async checkDuplicates() {
      // Make a function that checks on medicare id/num if there is more than 1
      const data = await axios.get(
        `api/modals/enrollment/duplicates/${this.enrollment_details.medicareNumber}`
      );
      this.duplicates = data.data;
    },
    async addNewNote(note) {
      this.enrollment_details.notes.push({
        ...note,
        date: new Date().toISOString(),
      });
      await axios.post(
        `api/modals/enrollment/update/note/${this.enrollment_details._id}`,
        this.enrollment_details
      );
    },
    async editNote(editedNote, oldNote) {
      this.enrollment_details.notes = this.enrollment_details.notes.map(
        (note) => {
          if (note.date === oldNote.date && note.text === oldNote.text) {
            return editedNote;
          } else {
            return note;
          }
        }
      );
      await axios.post(
        `api/modals/enrollment/update/note/${this.enrollment_details._id}`,
        this.enrollment_details
      );
    },
    deleteNote(note) {
      this.enrollment_details.notes = this.enrollment_details.notes.filter(
        (item) => item != note
      );
      this.summaryFieldChanged = true;
    },
    checkChanges() {
      if (this.summaryFieldChanged || this.fieldChangedHRA) {
        this.closeDialog = true;
      } else {
        this.closeModal();
      }
    },
    async getAgentNames() {
      this.loading = true;
      await axios.get(`api/modals/enrollment/agentComboBox`).then((res) => {
        //format fields for card
        let response = res.data;
        response.forEach((obj) => {
          this.agentIDs.push(obj.agentID);
        });
        try {
          if (
            this.enrollment_details.agentID &&
            !this.enrollment_details.agentName
          ) {
            this.getAgentName();
          }
        } catch (e) {
          console.log(e);
        }
      });
      await axios.get("api/modals/enrollment/userIdComboBox").then((res) => {
        let response = res.data;
        response.forEach((obj) => {
          this.agentIDs.push(obj.agentID);
        });
      });
      try {
        if (
          this.enrollment_details.agentNumberAck &&
          !this.enrollment_details.agentName
        ) {
          this.getAgentName();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getInfo() {
      this.loading = true;
      await axios
        .get(`api/modals/enrollment/patient/${this.enrollee._id}`)
        .then((res) => {
          //format fields for card
          this.enrollment_details = res.data[0];
          this.enrollmentHistory = res.data;
          this.memberID = this.enrollment_details.memberID;
          this.getHRAForms();
          this.getPlans();
          this.csvFormat();
          this.getAgentNames();
          this.formatCardFields();
          this.checkDuplicates();
          this.loading = false;
          if (this.user.role === "member services") {
            this.limitedAccess = true;
          }
          if (!this.enrollment_details.enrollmentHistory) {
            this.enrollment_details.enrollmentHistory = [];
            this.enrollment_details.enrollmentHistory.push({
              effectiveDateOfCoverage:
                this.enrollment_details.effectiveDateOfCoverage,
              disenrollmentDate: this.enrollment_details.disenrollmentDate,
              pcpID: this.enrollment_details.pcpID,
              selectedPCP: this.enrollment_details.selectedPCP,
              address: this.enrollment_details.address,
              city: this.enrollment_details.city,
              state: this.enrollment_details.state,
              zip: this.enrollment_details.zip,
              agentName: this.enrollment_details.agentName,
              agentNumberAck: this.enrollment_details.agentNumberAck,
              selectedPlan: this.enrollment_details.selectedPlan,
            });
            this.summaryFieldChanged = true;
          } else if (
            this.enrollment_details.enrollmentHistory.length < 1 ||
            !this.enrollment_details.enrollmentHistory
          ) {
            this.enrollment_details.enrollmentHistory.push({
              effectiveDateOfCoverage:
                this.enrollment_details.effectiveDateOfCoverage,
              disenrollmentDate: this.enrollment_details.disenrollmentDate,
              selectedPCP: this.enrollment_details.selectedPCP,
              pcpID: this.enrollment_details.pcpID,
              address: this.enrollment_details.address,
              city: this.enrollment_details.city,
              state: this.enrollment_details.state,
              zip: this.enrollment_details.zip,
              selectedPlan: this.enrollment_details.selectedPlan,
            });
            this.summaryFieldChanged = true;
          }
          // pcp care history
          if (
            !this.enrollment_details.pcpCareHistory ||
            this.enrollment_details.pcpCareHistory.length < 1
          ) {
            this.enrollment_details.pcpCareHistory = [
              {
                pcpCareStart: this.enrollment_details.effectiveDateOfCoverage,
                selectedPCP: this.enrollment_details.selectedPCP,
                pcpID: this.enrollment_details.pcpID,
              },
            ];
          }
          if (!this.enrollment_details.pcpCareStart) {
            this.enrollment_details.pcpCareStart =
              this.enrollment_details.effectiveDateOfCoverage;
          }
        });
    },
    getPCP() {
      axios.get("/api/enrollment/pcps").then((response) => {
        this.pcpList = response.data[0];
      });
    },
    setPCP() {
      // we should only do this if PCP exists on the list
      // thus the pcp not shown button :smile:
      if (!this.enrollment_details.pcpNotShown) {
        this.enrollment_details.pcpID =
          this.enrollment_details.selectedPCP.RenderingNPI;
        this.enrollment_details.selectedPCP =
          this.enrollment_details.selectedPCP.RenderingProviderFullName;
      }
    },
    setPCPbyID() {
      if (!this.enrollment_details.pcpNotShown) {
        this.enrollment_details.selectedPCP =
          this.enrollment_details.pcpID.RenderingProviderFullName;
        this.enrollment_details.pcpID =
          this.enrollment_details.pcpID.RenderingNPI;
      }
    },
    enrollmentEdited() {
      // if enrollment dates get edited because someone makes a mistake we need to make sure enrollment history is updated as well
      // latest entry in enrollment history will be the current
      const historyLength =
        this.enrollment_details.enrollmentHistory.length - 1;
      this.enrollment_details.enrollmentHistory[historyLength]
        .effectiveDateOfCoverage;
      this.enrollment_details.enrollmentHistory[historyLength]
        .disenrollmentDate;
    },
    csvFormat() {
      const newCsv = Object.keys(this.enrollment_details).reduce((obj, key) => {
        this.enrollment_details[key] !== false &&
          (obj[key] = this.enrollment_details[key]);

        return obj;
      }, {});
      delete newCsv.bill;
      delete newCsv.checking;
      delete newCsv.deduction;
      delete newCsv.acctHolderName;
      delete newCsv.depositoryBankName;
      delete newCsv.eft;
      delete newCsv.savings;
      delete newCsv.bankAcctNumber;
      delete newCsv.bankRouting;

      this.csvData = newCsv;
    },
    updateAfterChange() {
      this.key++;
    },
    getHRAForms() {
      this.loading = true;
      axios.get(`api/modals/hra/patient/${this.memberID}`).then((res) => {
        this.patientHRAForms = res.data;
        this.loading = false;
      });
    },
    editSonderId() {
      this.edit = !this.edit;
      this.newMemberId = "";
    },
    saveSonderId(id, newMemberId) {
      axios
        .post(`api/modals/enrollment/patient/${id}/${newMemberId}`)
        .then((res) => {
          console.log(res).catch((err) => {
            console.log(err);
          });
        });
      this.edit = !this.edit;
      this.enrollment_details.memberID = newMemberId;
      this.newMemberId = "";
    },
    getIcon(item) {
      if (item) {
        return "mdi-check";
      } else {
        return "mdi-close";
      }
    },
    getColor(val) {
      if (val === true) {
        return "green--text font-weight-bold text-h6";
      } else {
        return "red--text font-weight-bold text-h6";
      }
    },
    arrayToString(arr) {
      if (arr) {
        return arr.toString();
      }
    },
    allowedEffectiveDates(val) {
      return parseInt(val.split("-")[2], 10) === 1;
    },
    generatePDF() {
      let element = document.getElementById("capture");
      html2pdf(element, {
        margin: 1,
        filename: "enrollment.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
    formatDate(date) {
      return date ? date.substring(0, 10) : "";
    },
    formatDocName(item) {
      if (item.name.length > 15) {
        return item.name;
      } else {
        return item.name + "...";
      }
    },
    async getDocuments() {
      this.loading = true;
      if (this.enrollee_id) {
        await axios
          .get(`api/modals/enrollment/documents/${this.enrollee_id}`)
          .then((res) => {
            this.documentList = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.errorMessage =
              "Error loading documents. Please try again later.";
            this.snackbar = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async getSCAs() {
      this.newList = [];
      this.loading = true;
      if (this.enrollee_id) {
        await axios
          .get(`api/modals/enrollment/singleCaseAgreements/${this.enrollee_id}`)
          .then((res) => {
            this.scaList = res.data;
            this.enrollment_details.sca.forEach((sca) => {
              this.scaList.forEach((item) => {
                if (item.url.includes(sca.id)) {
                  this.newList.push({
                    details: sca,
                    pdf: item,
                  });
                }
              });
            });
          })
          .catch((err) => {
            console.log(err);
            this.errorMessage =
              "Error loading documents. Please try again later.";
            this.snackbar = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async handleSCAChanged(files) {
      this.fileUploading = true;
      let data = new FormData();
      this.isSCA = true;

      let uuid = uuidv4();

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        data.append("files[" + i + "]", file);
      }

      // send new document to API
      await axios
        .post(
          `api/modals/enrollment/documents/upload/${this.enrollee_id}/${this.isSCA}/${uuid}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.fileUploading = false;
          this.scaForm.id = uuid;
          axios.post(
            `api/modals/enrollment/addsca/${this.enrollee_id}`,
            this.scaForm
          );
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "Error uploading files. Please try again later.";
          this.snackbar = true;
        })
        .finally(() => {
          this.scaForm = {
            id: "",
            memberName: "",
            memberID: "",
            provName: "",
            provNPI: "",
            startDate: "",
            endDate: "",
            cptMin: "",
            cptMax: "",
          };
          this.fileUploading = false;
          this.addSCADialog = false;
          this.getInfo();
          this.getSCAs();
        });
    },
    async handleFilesChanged(files) {
      this.fileUploading = true;
      let data = new FormData();
      this.isSCA = false;
      let uuid = uuidv4();

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        data.append("files[" + i + "]", file);
      }

      // send new document to API
      await axios
        .post(
          `api/modals/enrollment/documents/upload/${this.enrollee_id}/${this.isSCA}/${uuid}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getDocuments();
          this.fileUploading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "Error uploading files. Please try again later.";
          this.snackbar = true;
        })
        .finally(() => {
          this.fileUploading = false;
        });
    },
    async uploadAttachments(files) {
      this.fileUploading = true;

      let data = new FormData();

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        data.append("files[" + i + "]", file);
      }

      // send new document to API
      await axios
        .post(
          `api/modals/enrollment/documents/upload/${this.enrollee_id}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.fileUploading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "Error uploading files. Please try again later.";
          this.snackbar = true;
        })
        .finally(() => {
          this.fileUploading = false;
        });
    },
    openDeleteConfirmation(item) {
      this.deleteDocumentDialog = true;
      this.docToDelete = item;
    },
    openDeleteSCADialog(item) {
      this.deleteSCADialog = true;
      this.docToDelete = item;
    },
    closeDeleteConfirmation() {
      this.deleteDocumentDialog = false;
    },
    closeDeleteSCAConfirmation() {
      this.deleteSCADialog = false;
    },
    async deleteDocument() {
      await axios
        .post(
          `api/modals/enrollment/documents/delete/${this.enrollee_id}`,
          this.docToDelete
        )
        .then((response) => {
          if (response.status === 200) {
            this.deleteDocumentDialog = false;
            this.docToDelete = {};
            this.getDocuments();
          }
        });
    },
    async deleteSCA() {
      await axios
        .post(
          `api/modals/enrollment/sca/delete/${this.enrollee_id}`,
          this.docToDelete
        )
        .then((response) => {
          if (response.status === 200) {
            axios.post(
              `api/modals/enrollment/deleteSCA/${this.enrollee_id}/${this.docToDelete.details.id}`
            );
            this.deleteSCADialog = false;
            this.docToDelete = {};
            this.getSCAs();
          }
        });
    },
    formatCardFields() {
      this.enrollment_details.birthDate = this.formatDate(
        this.enrollment_details.birthDate
      );
      this.enrollment_details.partA = this.formatDate(
        this.enrollment_details.partA
      );
      this.enrollment_details.partB = this.formatDate(
        this.enrollment_details.partB
      );
      this.enrollment_details.effectiveDateOfCoverage = this.formatDate(
        this.enrollment_details.effectiveDateOfCoverage
      );
      this.enrollment_details.enrollmentDate
        ? (this.enrollment_details.enrollmentDate = this.formatDate(
            this.enrollment_details.enrollmentDate
          ))
        : "";
      this.enrollment_details.disenrollmentDate
        ? (this.enrollment_details.disenrollmentDate = this.formatDate(
            this.enrollment_details.disenrollmentDate
          ))
        : "";
      this.enrollment_details.disenrollmentLetter
        ? (this.enrollment_details.disenrollmentLetter = this.formatDate(
            this.enrollment_details.disenrollmentLetter
          ))
        : "";
      this.enrollment_details.memberIdCreated
        ? (this.enrollment_details.memberIdCreated = this.formatDate(
            this.enrollment_details.memberIdCreated
          ))
        : "";
      this.enrollment_details.ackOfReciept
        ? (this.enrollment_details.ackOfReciept = this.formatDate(
            this.enrollment_details.ackOfReciept
          ))
        : "";
      this.enrollment_details.pcpCareStart
        ? (this.enrollment_details.pcpCareStart = this.formatDate(
            this.enrollment_details.pcpCareStart
          ))
        : "";
      this.enrollment_details.pcpCareEnd
        ? (this.enrollment_details.pcpCareEnd = this.formatDate(
            this.enrollment_details.pcpCareEnd
          ))
        : "";
    },
    formatMemberPhoneNum() {
      let x = this.enrollment_details.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.enrollment_details.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    closeModal() {
      this.tab = null;
      this.closeDialog = false;
      this.dialog = false;
      if (this.reEnrolling) this.reEnrolling = false;
    },
    saveChanges() {
      if (this.$refs.patientForm[0].validate()) {
        this.updatePatientInfo();
        this.closeModal();
        this.$emit("documentSaved");
      } else {
        this.saveDialog = false;
      }
    },
    async getPlans() {
      await axios.get(`api/modals/enrollment/plans`).then((response) => {
        this.patientPlans = response.data.data;
      });
    },
    async updatePatientInfo() {
      if (this.fieldChangedHRA === true) {
        if (this.$refs.hraEdit[0].validate()) {
          await axios.post(
            `/api/hra/edit/${this.patientHRAForms[0]._id}`,
            this.patientHRAForms[0]
          );
          this.closeModal();
        }
      }
      if (this.summaryFieldChanged === true) {
        await axios.post(
          `api/modals/enrollment/update/${this.enrollment_details._id}`,
          this.enrollment_details
        );
        this.closeModal();
      }
    },
    formatItems(index) {
      return (
        index !== "_id" &&
        index !== "mailingAddress" &&
        index !== "languagePref" &&
        index !== "accesibleFormat"
      );
    },
    async getAgentName() {
      try {
        await axios
          .get(
            `api/modals/enrollment/agentNameAutoFill/${this.enrollment_details.agentNumberAck}`
          )
          .then((response) => {
            this.enrollment_details.agentName = response.data[0].agentName;
            this.updateAfterChange();
          });
      } catch (e) {
        console.log(e);
      }
      if (!this.enrollment_details.agentName) {
        try {
          await axios
            .get(
              `api/modals/enrollment/userNameAutoFill/${this.enrollment_details.agentNumberAck}`
            )
            .then((response) => {
              this.enrollment_details.agentName = response.data[0].agentName;
              this.updateAfterChange();
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getAgentNameReenroll() {
      try {
        await axios
          .get(
            `api/modals/enrollment/agentNameAutoFill/${this.reEnrollmentInfo.agentNumberAck}`
          )
          .then((response) => {
            this.reEnrollmentInfo.agentName = response.data[0].agentName;
            this.updateAfterChange();
          });
      } catch (e) {
        console.log(e);
      }
      if (!this.reEnrollmentInfo.agentName) {
        try {
          await axios
            .get(
              `api/modals/enrollment/userNameAutoFill/${this.reEnrollmentInfo.agentNumberAck}`
            )
            .then((response) => {
              this.reEnrollmentInfo.agentName = response.data[0].agentName;
              this.updateAfterChange();
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    reEnrollPatient() {
      this.enrollment_details.effectiveDateOfCoverage =
        this.reEnrollmentInfo.effectiveDateOfCoverage;
      this.enrollment_details.disenrollmentDate =
        this.reEnrollmentInfo.disenrollmentDate;
      this.enrollment_details.selectedPCP = this.reEnrollmentInfo.selectedPCP;
      this.enrollment_details.pcpID = this.reEnrollmentInfo.pcpID;
      this.enrollment_details.selectedPlan = this.reEnrollmentInfo.selectedPlan;
      this.enrollment_details.agentName = this.reEnrollmentInfo.agentName;
      this.enrollment_details.agentNumberAck =
        this.reEnrollmentInfo.agentNumberAck;
      this.enrollment_details.enrollmentHistory.push({
        effectiveDateOfCoverage: this.reEnrollmentInfo.effectiveDateOfCoverage,
        disenrollmentDate: this.reEnrollmentInfo.disenrollmentDate,
        selectedPCP: this.reEnrollmentInfo.selectedPCP,
        pcpID: this.reEnrollmentInfo.pcpID,
        address: this.enrollment_details.address,
        city: this.enrollment_details.city,
        state: this.enrollment_details.state,
        zip: this.enrollment_details.zip,
        agentName: this.enrollment_details.agentName,
        agentNumberAck: this.enrollment_details.agentNumberAck,
        selectedPlan: this.reEnrollmentInfo.selectedPlan,
      });
      this.summaryFieldChanged = true;
      this.reEnrollDialog = false;
    },
    editPCP() {
      const historyLength = this.enrollment_details.pcpCareHistory.length - 1;

      this.enrollment_details.pcpCareHistory[historyLength].selectedPCP =
        this.enrollment_details.selectedPCP;
      this.enrollment_details.pcpCareHistory[historyLength].pcpID =
        this.enrollment_details.pcpID;
      this.enrollment_details.pcpCareHistory[historyLength].pcpCareStart =
        this.enrollment_details.pcpCareStart;
      this.enrollment_details.pcpCareHistory[historyLength].pcpCareEnd =
        this.enrollment_details.pcpCareEnd;
    },
    changePCP() {
      const historyLength = this.enrollment_details.pcpCareHistory.length - 1;

      if (
        !this.enrollment_details.pcpCareEnd ||
        !this.enrollment_details.pcpCareHistory[historyLength].pcpCareEnd
      ) {
        this.enrollment_details.pcpCareHistory[historyLength].pcpCareEnd =
          new Date();
      }
      this.enrollment_details.pcpCareHistory.push({});
      this.enrollment_details.pcpCareStart = "";
      this.enrollment_details.pcpCareEnd = "";
      this.enrollment_details.selectedPCP = "";
      this.enrollment_details.pcpID = "";

      this.allowPCPEdits = true;
    },
    currencyFormat(num) {
      return num.toFixed(2);
    },
  },
  watch: {
    async dialog() {
      if (this.dialog === true) {
        this.getPCP();
        this.enrollee_id = this.enrollee.PATIENT_KEY;
        this.summaryFieldChanged = false;
        this.fieldChangedHRA = false;
        this.getInfo();
        this.getDocuments();
        this.getMedicalAffiliates();
      }
    },
    "newLoc.BillingProviderTaxId": function () {
      let selected = this.providerTaxData.find(
        (item) => item.BillingProviderTaxId === this.newLoc.BillingProviderTaxId
      );
      if (selected) {
        this.newLoc.BillingProviderNPI = selected.BillingProviderNPI;
        this.newLoc.BillingProviderAddressType =
          selected.BillingProviderAddressType;
        this.newLoc.RemittoAddress1 = selected.RemittoAddress1;
        this.newLoc.RemittoAddress2 = selected.RemittoAddress2;
        this.newLoc.RemittoCity = selected.RemittoCity;
        this.newLoc.RemittoState = selected.RemittoState;
        this.newLoc.RemittoZip = selected.RemittoZip;
        this.newLoc.BillingPhone = selected.BillingPhone;
        this.newLoc.Billingfax = selected.Billingfax;
      }
    },
    tab() {
      if (this.tab === 4) {
        this.patientTasksKey += 1;
      }
    },
    enrollment_details() {
      if (this.enrollment_details !== {}) {
        this.getSCAs();
      }
    },
  },
};
</script>

<style scoped>
.v-dialog--fullscreen {
  overflow-x: hidden !important;
}

.currentEnrollment {
  background-color: #60777f64;
}
.stats-card {
  min-height: 285px;
}
.check-mark {
  font-weight: bold;
  color: green;
}
</style>
