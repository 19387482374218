<template>
  <div>
    <v-card flat outlined>
      <v-card-title>Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold">Claim ID: </span> {{ claim.claimID }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Patient ID: </span
            >{{ claim.patientID }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Claim Status: </span
            >{{ claim.claimStatus }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Gender: </span>{{ claim.gender }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold">DOB: </span> {{ claim.DOB }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Provider TIN (Tax ID): </span
            >{{ claim.taxID }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Medicare Number: </span
            >{{ claim.medicareNumber }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Provider NPI: </span
            >{{ claim.providerNPI }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold">Provider State: </span>
            {{ claim.providerState }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Provider Zip: </span
            >{{ claim.providerZip }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Remittance Address: </span
            >{{ claim.remittanceAddress }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Remittance City: </span
            >{{ claim.remittanceCity }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold">Remittance State: </span>
            {{ claim.remittanceState }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Remittance Zip: </span
            >{{ claim.remittanceZip }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Signature: </span>
            <span v-if="claim.signature">
              <v-icon color="success">mdi-check</v-icon>
            </span>
            <span v-else>
              <v-icon color="error">mdi-close</v-icon>
            </span>
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Email: </span>{{ claim.email }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold">Taxonomy: </span>
            {{ claim.taxonomy }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Ambulance Pick Up Zip: </span>
            {{ claim.ambulancePickUpZip }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Total Charge: </span>
            {{ claim.totalCharge }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Base Allowed: </span>
            {{ claim.baseAllowed }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold">Custom Rate: </span>
            {{ claim.customRate }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Patient Zip: </span>
            {{ claim.patientZip }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Authorization ID: </span>
            {{ claim.authorizationID }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Sequester Adjustment: </span>
            {{ claim.sequesterAdjustment }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold"
              >Readmission Reduction Program:
            </span>
            {{ claim.readmissionReductionProgram }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold"
              >Value Based Purchasing Program:
            </span>
            {{ claim.valueBasedPurchasingProgram }}
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold">Mods: </span> {{ claim.mods }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat outlined class="my-3">
      <v-card-title>Service(s)</v-card-title>
      <v-card
        flat
        class="ma-3"
        v-for="(service, index) in services"
        :key="index"
      >
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <span class="font-weight-bold">Service Procedure Code: </span>
              {{ service.serviceProcedureCode }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">POS: </span> {{ service.POS }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">TOS: </span> {{ service.TOS }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Diagnosis Code: </span>
              {{ service.diagnosisCode }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <span class="font-weight-bold">Service Mods: </span>
              {{ service.serviceMods }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Units: </span> {{ service.units }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Charges: </span>
              {{ service.charges }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Service Start Date: </span>
              {{ service.serviceStartDate }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <span class="font-weight-bold">Service End Date: </span>
              {{ service.serviceEndDate }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider v-if="index !== services.length - 1"></v-divider>
      </v-card>
    </v-card>
    <v-card flat outlined class="my-3">
      <v-card-title>Revenue(s)</v-card-title>
      <v-card
        flat
        class="ma-3"
        v-for="(revenue, index) in revenues"
        :key="index"
      >
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <span class="font-weight-bold">Revenue Service Start Date: </span>
              {{ revenue.revenueServiceStartDate }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Revenue Service End Date: </span>
              {{ revenue.revenueServiceEndDate }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Revenue Code: </span>
              {{ revenue.revenueCode }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">HCPCS: </span> {{ revenue.HCPCS }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <span class="font-weight-bold">Revenue Mods: </span>
              {{ revenue.revenueMods }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Revenue Units: </span>
              {{ revenue.revenueUnits }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Visits: </span>
              {{ revenue.visits }}
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold">Revenue Diagnosis Code: </span>
              {{ revenue.revenueDiagnosisCode }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <span class="font-weight-bold">POA: </span> {{ revenue.POA }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider v-if="index !== revenues.length - 1"></v-divider>
      </v-card>
    </v-card>
    <v-card flat outlined class="my-3">
      <v-card-title>Attached Authorization(s)</v-card-title>
      <v-row v-if="claim.authorizations && claim.authorizations.length > 0">
        <v-col cols="12">
          <v-data-table
            :headers="authDataHeaders"
            :items="claim.authorizations"
            :single-expand="singleExpand"
            show-expand
            item-key="_id"
            multi-sort
            class="pa-3"
          >
            <template v-slot:item.Services="{ item }">
              {{ item.Services.length }}
            </template>
            <template v-slot:item.ReceiveDate="{ item }">
              {{ item.ReceiveDate.slice(0, 10) }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-3">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Service Description</th>
                        <th class="text-left">CPT/HCPCS</th>
                        <th class="text-left">Diagnosis Code</th>
                        <th class="text-left">Visits</th>
                        <th class="text-left">Units</th>
                        <th class="text-left">Matches Claim Codes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(service, index) in item.Services"
                        :key="index"
                      >
                        <td>
                          {{ service.ServiceDesc }}
                        </td>
                        <td>
                          {{ service.CPT_HCPCS }}
                        </td>
                        <td>
                          {{ service.DIAG }}
                        </td>
                        <td>
                          {{ service.Visits }}
                        </td>
                        <td>
                          {{ service.Units }}
                        </td>
                        <td
                          v-if="
                            service.CPT_HCPCS === claim.HCPCS &&
                            service.DIAG === claim.diagnosisCode &&
                            service.Visits === claim.visits &&
                            service.Units === claim.units
                          "
                        >
                          <v-icon color="success">mdi-check</v-icon>
                        </td>
                        <td v-else>
                          <v-icon color="error">mdi-close</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn text color="accent lighten-1" class="mr-2" @click="backOne()">
          <v-icon>mdi-chevron-left</v-icon>
          Back
        </v-btn>

        <v-btn @click="submitClaim()" color="accent" depressed>
          Submit Claim
          <v-icon class="ml-2">mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'

export default {
  props: ["claim"],
  data() {
    return {
      // claimData: {},
      services: [],
      revenues: [],
      authDataHeaders: [
        { text: "Member Name", value: "MemberName" },
        { text: "Authorization ID", value: "AuthID" },
        { text: "Receive Date", value: "ReceiveDate" },
        { text: "Determination", value: "Determination" },
        { text: "Services", value: "Services" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    backOne() {
      this.$emit("backOne");
    },
    submitClaim() {
      this.$emit("submitClaim");
    },
  },
  watch: {
    claim() {
      // this.claimData = this.claim
      this.services = this.claim.services;
      this.revenues = this.claim.revenues;
    },
  },
};
</script>
