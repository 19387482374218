<template>
  <v-container fluid>
    <v-card-title class="justify-center">
      Network Participation
    </v-card-title>
    <v-row id="section3">
      <v-col cols="12" md="4">
        <v-menu
          ref="clinicOrigDatePicker"
          v-model="clinicOrigDatePickerFlag"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Load Date"
              append-icon="mdi-calendar"
              v-model="affiliateData.LoadDate"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker ref="picker" v-model="affiliateData.LoadDate">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              @click="clinicOrigDatePickerFlag = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="$refs.clinicOrigDatePicker.save(affiliateData.LoadDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-menu
          ref="clinicRecredDatePickerFlag"
          v-model="clinicRecredDatePickerFlag"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Recred Date"
              append-icon="mdi-calendar"
              v-model="affiliateData.ClinicReCredDate"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker ref="picker" v-model="affiliateData.ClinicReCredDate">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              @click="clinicRecredDatePickerFlag = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="
                $refs.clinicRecredDatePickerFlag.save(
                  affiliateData.ClinicReCredDate
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu
          ref="sonderCredDatePicker"
          v-model="affiliateData.sonderCredDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Sonder Cred Date"
              append-icon="mdi-calendar"
              v-model="affiliateData.SonderCredDate"
              :rules="[
                affiliateData.SonderCredDate != '' || 'Please enter a date',
              ]"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker ref="picker" v-model="affiliateData.SonderCredDate">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              @click="sonderCredDatePicker = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="
                $refs.sonderCredDatePicker.save(affiliateData.SonderCredDate)
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          label="Status"
          v-model="affiliateData.Status"
          :items="locationStatuses"
          required
          :rules="[
            affiliateData.Status != '' ||
              'Please make sure this field is not empty',
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          label="Accepting Patients"
          v-model="affiliateData.AcceptingNewPatients"
          :items="[
            'Not Accepting Patients',
            'Family of Patient',
            'Current Patients',
            'Accepting New Patients',
          ]"
          required
          :rules="[
            affiliateData.AcceptingNewPatients != '' ||
              'Please make sure this field is not empty',
          ]"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-checkbox
          label="Hospital Based"
          v-model="affiliateData.HospitalBased"
          required
          outlined
          dense
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="4">
        <v-checkbox
          label="Uses CMSMA"
          v-model="affiliateData.UsesCMSMA"
          outlined
          dense
        ></v-checkbox>
      </v-col>
    </v-row>

    <!-- Fee Schedule Information -->
    <v-card-title class="justify-center">
      <span>Fee Schedule Information</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            v-model="affiliateData.FeeScheduleType"
            label="Fee Schedule Type"
            :items="['Fee by Service', 'Capitation']"
            required
            :rules="[
              !!affiliateData.FeeScheduleType ? true : 'This field is required',
            ]"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            ref="feeScheduleEffectiveDatePickerFlag"
            v-model="feeScheduleEffectiveDatePickerFlag"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="affiliateData.ProviderEffectiveDatewithFeeSchedule"
                label="Fee Schedule Effective Date"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :rules="[
                  affiliateData.ProviderEffectiveDatewithFeeSchedule != '' ||
                    'Please enter a Date.',
                ]"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="affiliateData.ProviderEffectiveDatewithFeeSchedule"
            >
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="primary"
                @click="feeScheduleEffectiveDatePickerFlag = false"
              >
                Cancel
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="
                  $refs.feeScheduleEffectiveDatePickerFlag.save(
                    affiliateData.ProviderEffectiveDatewithFeeSchedule
                  )
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            ref="feeScheduleTerminationDatePickerFlag"
            v-model="feeScheduleTerminationDatePickerFlag"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="affiliateData.ProviderTerminationDatewithFeeSchedule"
                label="Fee Schedule Termination Date"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :rules="[
                  affiliateData.ProviderTerminationDatewithFeeSchedule != '' ||
                    'Please enter a Date.',
                ]"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="affiliateData.ProviderTerminationDatewithFeeSchedule"
            >
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="primary"
                @click="feeScheduleTerminationDatePickerFlag = false"
              >
                Cancel
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="
                  $refs.feeScheduleTerminationDatePickerFlag.save(
                    affiliateData.ProviderTerminationDatewithFeeSchedule
                  )
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <template v-if="affiliateData.FeeScheduleType === 'Fee by Service'">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="affiliateData.FeeSchedulePercent"
              suffix="%"
              label="Fee Schedule Percent"
              type="number"
              min="0"
              max="999"
              :rules="[
                (v) => v >= 0 || 'Cannot have negative percent',
                (v) => v < 1000 || 'Percent must be 3 digits',
              ]"
              outlined
              dense
              class="text-subtitle-1"
            ></v-text-field>
          </v-col>
        </template>
        <template v-if="affiliateData.FeeScheduleType === 'Capitation'">
          <v-col cols="12" md="3">
            <v-text-field
              v-model="affiliateData.MedicareCapitation"
              label="Medicare Members"
              type="number"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              outlined
              dense
              class="text-subtitle-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="affiliateData.MedicaidCapitation"
              label="Medicaid Members"
              type="number"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              outlined
              dense
              class="text-subtitle-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="affiliateData.maxDaysToSubmitInpatientClaim"
              label="Max Days To Submit Inpatient Claim"
              type="number"
              min="0"
              max="999"
              :rules="[(v) => v >= 0 || 'Cannot be negative']"
              outlined
              dense
              class="text-subtitle-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="affiliateData.maxDaysToSubmitOutpatientClaim"
              label="Max Days To Submit Outpatient Claim"
              type="number"
              min="0"
              max="999"
              :rules="[(v) => v >= 0 || 'Cannot be negative']"
              outlined
              dense
              class="text-subtitle-1"
            >
            </v-text-field>
          </v-col>
        </template>
      </v-row>
    </v-card-text>

    <!-- BILLING -->
    <v-card-title class="justify-center">
      <span>Billing</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            :items="primarySpecialty"
            label="Primary Specialty"
            v-model="affiliateData.PrimarySpecialty"
            :rules="[
              affiliateData.PrimarySpecialty != '' ||
                'Please make sure this field is not empty',
            ]"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Billing Provider Name"
            v-model="affiliateData.BillingProviderName"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Billing Provider Name as Appears on Check"
            v-model="affiliateData.BillingProviderNameAsAppearsonCheck"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Billing Provider Tax ID"
            v-model="affiliateData.BillingProviderTaxId"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Billing Provider NPI"
            v-model="affiliateData.BillingProviderNPI"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            label="Billing Provider Address Type"
            v-model="affiliateData.BillingProviderAddressType"
            :items="['Primary', 'Secondary']"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Remit to Address 1"
            v-model="affiliateData.RemittoAddress1"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Remit to Address 2"
            v-model="affiliateData.RemittoAddress2"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Remit to City"
            v-model="affiliateData.RemittoCity"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Remit to State"
            v-model="affiliateData.RemittoState"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Remit to ZIP"
            v-model="affiliateData.RemittoZip"
            :rules="[
              (v) =>
                v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                  ? 'This field cannot contain letters'
                  : true,
            ]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Billing County"
            v-model="affiliateData.BillingCounty"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Billing Phone"
            v-model="affiliateData.BillingPhone"
            @input="formatBillPhone"
            :rules="[
              (v) =>
                v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                  ? 'This field cannot contain letters'
                  : true,
            ]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Billing Fax"
            v-model="affiliateData.BillingFax"
            @input="formatBillFax"
            :rules="[
              (v) =>
                v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                  ? 'This field cannot contain letters'
                  : true,
            ]"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
export default {
  name: "LocationTab",
  props: ["affiliateData"],
  data() {
    return {
      Status: "",
      clinicOrigDatePickerFlag: false,
      clinicRecredDatePickerFlag: false,
      sonderCredDatePicker: false,
      feeScheduleEffectiveDatePickerFlag: false,
      feeScheduleTerminationDatePickerFlag: false,
      locationStatuses: [
        "Active",
        "Pending/ In-Process",
        "Ready For Review",
        "Ready ForCred Committee",
        "Pending CVO Check List",
        "Pending Updated Malpractice Insurance",
        "Inactive",
        "Termed",
        "Withdrawn",
        "Expired CAQH Attestation",
        "Listed on SAM GSA Report",
      ],
      primarySpecialty: [
        "Adult Intensivist",
        "Allergy",
        "Anesthesia",
        "Bariatric Medicine/Surgery",
        "Burn/Trauma",
        "Cardiac Catheterization",
        "Cardiology",
        "Cardiovascular Surgery",
        "Colorectal Surgery",
        "Dermatology",
        "Electrophysiology",
        "Emergency Medicine",
        "Endocrinology",
        "Family Practice",
        "Gastroenterology",
        "General Surgery",
        "Geriatrics",
        "Gynecologic Oncology",
        "Hematology/Oncology",
        "Hepatobiliary",
        "Hospitalist",
        "Infectious Disease",
        "Internal Medicine",
        "Interventional Radiology",
        "Medical Genetics",
        "Neonatology",
        "Nephrology",
        "Neuroradiology",
        "Neurology",
        "Neurosurgery",
        "Nuclear Medicine",
        "Obstetrics & Gynecology",
        "Occupational Medicine",
        "Ophthalmology",
        "Oral Surgery",
        "Orthopedics",
        "Otolaryngology / Head & Neck Surgery",
        "Pain Management",
        "Palliative Care",
        "Pain Management",
        "Palliative Care",
        "Pathology: Surgical & Anatomic",
        "Pediatric Intensivist",
        "Pediatrics",
        "Pediatric Surgery",
        "Physical Medicine",
        "Plastic & Reconstructive Surgery",
        "Podiatric Surgery",
        "Psychiatry",
        "Pulmonary Medicine",
        "Radiation Oncology",
        "Radiology",
        "Rheumatology",
        "Surgical Oncology",
        "Thoracic Surgery",
        "Transplant Surgery",
        "Urology",
        "Vascular Surgery",
        "Wound Care",
        "Bariatric Surgery",
        "ENT",
        "General Surgery",
        "Gastroenterology",
        "Neurosurgery",
        "Obstetrics & Gynecology",
        "Ophthalmology",
        "Oral/Dental Surgery",
        "Orthopedic Surgery",
        "Pain Management",
        "Pediatric Surgery",
        "Plastic Surgery",
        "Podiatric Surgery",
        "Urology",
        "Vascular Surgery",
      ],
      secondarySpecialty: [
        "Adult Intensivist",
        "Allergy",
        "Anesthesia",
        "Bariatric Medicine/Surgery",
        "Burn/Trauma",
        "Cardiac Catheterization",
        "Cardiology",
        "Cardiovascular Surgery",
        "Colorectal Surgery",
        "Dermatology",
        "Electrophysiology",
        "Emergency Medicine",
        "Endocrinology",
        "Family Practice",
        "Gastroenterology",
        "General Surgery",
        "Geriatrics",
        "Gynecologic Oncology",
        "Hematology/Oncology",
        "Hepatobiliary",
        "Hospitalist",
        "Infectious Disease",
        "Internal Medicine",
        "Interventional Radiology",
        "Medical Genetics",
        "Neonatology",
        "Nephrology",
        "Neuroradiology",
        "Neurology",
        "Neurosurgery",
        "Nuclear Medicine",
        "Obstetrics & Gynecology",
        "Occupational Medicine",
        "Ophthalmology",
        "Oral Surgery",
        "Orthopedics",
        "Otolaryngology / Head & Neck Surgery",
        "Pain Management",
        "Palliative Care",
        "Pain Management",
        "Palliative Care",
        "Pathology: Surgical & Anatomic",
        "Pediatric Intensivist",
        "Pediatrics",
        "Pediatric Surgery",
        "Physical Medicine",
        "Plastic & Reconstructive Surgery",
        "Podiatric Surgery",
        "Psychiatry",
        "Pulmonary Medicine",
        "Radiation Oncology",
        "Radiology",
        "Rheumatology",
        "Surgical Oncology",
        "Thoracic Surgery",
        "Transplant Surgery",
        "Urology",
        "Vascular Surgery",
        "Wound Care",
        "Bariatric Surgery",
        "ENT",
        "General Surgery",
        "Gastroenterology",
        "Neurosurgery",
        "Obstetrics & Gynecology",
        "Ophthalmology",
        "Oral/Dental Surgery",
        "Orthopedic Surgery",
        "Pain Management",
        "Pediatric Surgery",
        "Plastic Surgery",
        "Podiatric Surgery",
        "Urology",
        "Vascular Surgery",
      ],
    }
  },
  methods: {
    formatServicePhone() {
      let x = this.affiliateData.ServiceLocationPhone.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      )
      this.affiliateData.ServiceLocationPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    formatServiceFax() {
      let x = this.affiliateData.ServiceLocationFax.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      )
      this.affiliateData.ServiceLocationFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    formatBillPhone() {
      let x = this.affiliateData.BillingPhone.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      )
      this.affiliateData.BillingPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    formatBillFax() {
      let x = this.affiliateData.BillingFax.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      )
      this.affiliateData.BillingFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
  },
}
</script>
