<template>
  <v-dialog
    id="cred-modal"
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
        :style="activatorStyle"
      >
        <v-icon color="accent">mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-toolbar dark color="primary" class="mx-auto">
      <v-btn icon dark @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <ExportCSV
        v-if="user && user.role !== 'agent' && user.role !== 'fmo'"
        :data="[this.credData]"
        :name="credData.RenderingNPI + '.csv'"
      >
        <v-btn class="mr-3 mb-2" outlined dark>
          <v-icon>mdi-microsoft-excel</v-icon>
          <span class="pl-3">Export to CSV</span>
        </v-btn>
      </ExportCSV>
      <v-btn
        v-if="user && user.role !== 'agent' && user.role !== 'fmo'"
        class="mr-3 mb-2"
        outlined
        dark
        v-on:click="generateJpeg()"
      >
        <v-icon>mdi-file-image-outline</v-icon>
        <span class="pl-3">Export Image</span>
      </v-btn>
      <v-btn
        :disabled="!unsavedChanges"
        color="success"
        depressed
        dark
        v-on:click="saveRecord()"
        :loading="saving"
        class="mb-2"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="pl-3">Save Changes</span>
      </v-btn>
    </v-toolbar>

    <v-card tile id="capture" flat color="#fafafa">
      <v-row v-if="loading" justify="center" style="height: 100vh">
        <v-col align-self="center" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col cols="12" class="pt-0">
          <v-card id="modal-header" flat class="pb-4 mb-1">
            <v-card-title class="text-h3">
              {{
                isFacility
                  ? credData.FacilityName
                  : credData.RenderingProviderFullName
              }}
            </v-card-title>
            <v-card-subtitle class="pb-0 text-h5">
              <span class="accent--text">NPI:</span> {{ credData.RenderingNPI }}
            </v-card-subtitle>
          </v-card>

          <v-tabs fixed-tabs v-model="tab">
            <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
            <v-tabs-items v-model="tab">
              <!-- SUMMARY -->
              <v-tab-item>
                <SummaryTab
                  :credData="credData"
                  :isFacility="isFacility"
                  :user="user"
                  :startValidation="startValidation"
                  @summaryErrors="validateSummary"
                  :limitedAccess="limitedAccess"
                />
              </v-tab-item>
              <!-- LOCATIONS -->
              <v-tab-item>
                <Location
                  :credData="credData"
                  :isFacility="isFacility"
                  :startValidation="startValidation"
                  :limitedAccess="limitedAccess"
                  @locationIndex="locationIndex"
                  @newStatusObj="newStatusObj"
                  @locationErrors="validateLocations"
                />
              </v-tab-item>
              <!-- LICENSE & CERT -->
              <v-tab-item v-if="!limitedAccess">
                <Licenses
                  :credData="credData"
                  :isFacility="isFacility"
                  :user="user"
                  :startValidation="startValidation"
                  @licenseErrors="validateLicenseCertCredentialing"
                  @malpractiveSaved="updateMalpractices"
                />
              </v-tab-item>
              <!-- DOCUMENTS -->
              <v-tab-item v-if="!limitedAccess">
                <cred-documents
                  :npi="
                    credData.RenderingNPI
                      ? credData.RenderingNPI
                      : credData.FacilityNPI
                  "
                  :isCredModal="true"
                />
              </v-tab-item>
              <!-- NOTES -->
              <v-tab-item v-if="!limitedAccess">
                <notes-tab :credData="credData" />
              </v-tab-item>

              <!-- Tasks -->
              <v-tab-item v-if="!limitedAccess">
                <tasks-tab 
                  :credID="credData.RenderingNPI" 
                  :user="user"
                />
              </v-tab-item>
              <!-- CORRESPONDENCE -->
              <!-- <v-tab-item v-if="!limitedAccess">
                <correspondence-tab :npi="credData.RenderingNPI" />
              </v-tab-item> -->
              <!-- Share Group NPI -->
              <v-tab-item v-if="!limitedAccess">
                <shared-tab
                  :groupNPI="credData.groupNPI"
                  :isFacility="isFacility"
                  :npi="
                    isFacility ? credData.FacilityNPI : credData.RenderingNPI
                  "
                >
                </shared-tab>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>

          <v-snackbar v-model="snackError" :timeout="3000" color="error">
            Error saving changes!
            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
            </template>
          </v-snackbar>

          <v-snackbar v-model="validationError" :timeout="3000" color="error">
            Unable to save because of uncompleted fields in the following
            tab(s):
            <span v-if="!summaryValid">SUMMARY</span><br />
            <span v-if="!locationsValid">LOCATIONS</span><br />
            <span v-if="!licensesValid">LICENSES</span>
            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="validationError = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <v-dialog v-model="unsavedChangesDialog" max-width="900px">
            <v-card>
              <v-card-title>Are you sure you want to exit?</v-card-title>
              <v-card-text
                >You have unsaved changes to the record and exiting will cause
                you to lose these changes</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog = false"
                  >Yes</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="unsavedChangesDialog = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import CredDocuments from "./cred-modal-componenets/CredDocuments";
import axios from "axios";
import * as htmlToImage from "html-to-image";
import ExportCSV from "vue-json-csv";
import SummaryTab from "./cred-modal-componenets/SummaryTab";
import Location from "./cred-modal-componenets/Location";
import Licenses from "./cred-modal-componenets/LicenseTab";
import NotesTab from "./cred-modal-componenets/NotesTab.vue"
import TasksTab from "./cred-modal-componenets/TasksTab.vue";
import SharedTab from "./cred-modal-componenets/SharedTab.vue"

/* Removed for now */
// import CorrespondenceTab from "./cred-modal-componenets/CorrespondenceTab.vue"
// import Notifications from "../views/Notifications"

export default {
  name: "CredentialingModal",
  props: ["npiNumber", "isFacility", "user", "source"],
  components: {
    ExportCSV,
    CredDocuments,
    SummaryTab,
    Location,
    Licenses,
    NotesTab,
    SharedTab,
    TasksTab
  },
  data() {
    return {
      dialog: false,
      loading: true,
      saving: false,
      startValidation: false,
      tab: null,
      items: [
        "Summary",
        "Locations",
        "Licenses",
        "Documents",
        "Notes",
        "Tasks",
        "Shared",
      ],
      snackError: false,
      clinicInitDatePicker: false,
      clinicOrigDatePicker: false,
      clinicRecredDatePicker: false,
      medicareAwardDatePicker: false,
      medicareExpDatePicker: false,
      medicaidAwardDatePicker: false,
      medicaidExpDatePicker: false,
      credData: {},
      dataCopy: {},
      selectedLocation: 0,
      newLocationCreated: false,
      newLocationIndex: "",
      unsavedChanges: false,
      locationsValid: true,
      summaryValid: true,
      licensesValid: true,
      validationError: false,
      unsavedChangesDialog: false,
      statusUpdateObj: {},
      deleteDialog: false,
      toDelete: {},
      limitedAccess: false,
    };
  },
  computed: {
    activatorStyle() {
      if(this.source === "ProviderReports") {
        return {}
      }
      else return {
        position: 'absolute', right: '5px', top: '18px'
      }
    }
  },
  methods: {
    deletedLoc(loc) {
      let locIndex = this.credData.locations.indexOf(loc);
      this.dataCopy.locations.splice(locIndex, 1);
    },
    // if form invalid trigger modal to check correct tab
    async validateLocations(validationFailure) {
      if (validationFailure) {
        this.locationsValid = false;
      } else if (validationFailure === false) {
        this.locationsValid = true;
      }
    },
    async validateSummary(validationFailure) {
      if (validationFailure) {
        this.summaryValid = false;
      } else if (validationFailure === false) {
        this.summaryValid = true;
      }
    },
    async validateLicenseCertCredentialing(validationFailure) {
      if (validationFailure) {
        this.licensesValid = false;
      } else if (validationFailure === false) {
        this.licensesValid = true;
      }
    },
    newStatusObj(status) {
      this.statusUpdateObj = status;
    },
    locationIndex(index) {
      this.selectedLocation = index;
    },
    async getInfo() {
      this.loading = true;
      this.credData = {};
      this.newLocationCreated = false;
      this.newLocationIndex = "";
      this.selectedLocation = 0;

      await axios
        .get(
          `api/modals/credentialing/npi/${this.npiNumber}/${this.isFacility}`
        )
        .then((res) => {
          this.credData = res.data[0];

          //make sure all the notes exist, and exist as arrays
          if (!Array.isArray(this.credData.notes)) {
            this.credData.notes = [];
          }
          if (!Array.isArray(this.credData.withdrawNotes)) {
            this.credData.withdrawNotes = [];
          }
          if (!Array.isArray(this.credData.terminationNotes)) {
            this.credData.terminationNotes = [];
          }
          //Checks when the modal loads if the user is an agent or member services and only shows the available tabs
          //an agent should have access to.
          if (
            this.user.role === "agent" ||
            this.user.role === "fmo" ||
            this.user.role === "member services"
          ) {
            this.items = ["Summary", "Locations"];
            this.limitedAccess = true;
          }
          this.dataCopy = JSON.parse(JSON.stringify(this.credData));
          this.loading = false;
        });
      this.unsavedChanges = false;
    },
    getIcon(val) {
      if (val === true) {
        return "&#10003;";
      } else {
        return "&#9747;";
      }
    },
    getColor(val) {
      if (val === true) {
        return "green--text font-weight-bold text-h6";
      } else {
        return "red--text font-weight-bold text-h6";
      }
    },
    arrayToString(arr) {
      if (arr) {
        return arr.toString();
      }
    },
    async saveRecord() {
      this.startValidation = true; // passes to children to check if form is valid
      // wait for validation to complete
      await this.validateLocations();
      await this.validateSummary();
      await this.validateLicenseCertCredentialing();
      if (!this.locationsValid || !this.summaryValid || !this.licensesValid) {
        this.validationError = true;
      }

      // if all forms are valid run save query
      if (this.locationsValid && this.summaryValid && this.licensesValid) {
        try {
          this.saving = true;
          await axios
            .post("/api/modals/credentialing/save", {
              mode: this.isFacility ? "facility" : "provider",
              credData: this.credData,
            })
            .then(() => {
              // emit value to reload the patients if changes were made
              this.$emit("recordUpdated");
              this.dialog = false;
            })
            .catch((err) => {
              console.log(err);
              this.snackError = true;
              this.saving = false;
            });
          this.saving = false;
        } catch (error) {
          console.log(error);
        }
      }
      this.startValidation = false;
    },
    updateMalpractices(malpractice) {
      if (!this.credData.malpracticeInfo) {
        this.credData.malpracticeInfo = [];
      }
      if (!this.dataCopy.malpracticeInfo) {
        this.dataCopy.malpracticeInfo = [];
      }
      this.credData.malpracticeInfo.push(malpractice);
      this.dataCopy.malpracticeInfo.push(malpractice);
      this.getInfo();
    },
    closeDialog() {
      if (this.unsavedChanges) {
        this.unsavedChangesDialog = true;
      } else {
        this.dialog = false;
      }
    },
    generateJpeg() {
      htmlToImage
        .toJpeg(document.getElementById("capture"), {
          quality: 0.95,
          pixelRatio: 1,
        })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "Credentialing_Snapshot" + ".jpeg";
          link.href = dataUrl;
          link.click();
        });
    },

  },
  watch: {
    async dialog() {
      if (this.dialog === true) {
        if (this.npiNumber != null) {
          await this.getInfo();
        }
      }
    },
    credData: {
      handler: function () {
        if (!this.loading) {
          this.unsavedChanges = true;
          if (JSON.stringify(this.dataCopy) !== JSON.stringify(this.credData)) {
            this.unsavedChanges = true;
          } else {
            this.unsavedChanges = false;
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.v-messages__message {
  color: rgb(166, 50, 48) !important;
}
#modal-header {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 63%,
    rgba(21, 167, 184, 0.1) 100%
  );
}

.v-dialog--fullscreen {
  overflow-x: hidden !important;
}

.stats-card {
  min-height: 285px;
}
.check-mark {
  font-weight: bold;
  color: green;
}
</style>
