<template>
  <v-row class="pa-2">
    <v-col>
      <v-card class="px-2" outlined elevation="2">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-card-actions>
                  <v-select
                    v-model="selectedNote"
                    :items="noteItems"
                    label="Note Type"
                    class="ml-3 mt-1 pl-1"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-card-actions>
              </v-col>
              <v-col cols="6" align="right">
                <v-btn
                  @click="newNoteDialog = true"
                  class="mt-4 mr-4"
                  color="success"
                >
                  Create New Note
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-dialog
          v-model="newNoteDialog"
          persistent
          :max-width="noteDialogWidth"
        >
          <v-card>
            <v-toolbar dark flat color="primary">
              <v-card-title color="primary">Add New Note</v-card-title>
            </v-toolbar>
            <v-form ref="newNoteForm" v-model="newNoteValid" lazy-validation>
              <v-select
                v-model="newNote.type"
                :items="noteItems.filter((item) => item != 'All')"
                label="Note Type"
                class="mt-2 px-2"
                outlined
                required
                :rules="[newNote.type != '' || 'Please select note type']"
                dense
              ></v-select>
              <v-text-field
                v-model="newNote.title"
                label="Title"
                class="px-2"
                :counter="20"
                :rules="[(v) => v.length < 20 || 'Max 20 characters']"
                hide-details
                outlined
                dense
              />
              <v-textarea
                v-model="newNote.text"
                class="mt-2 px-2"
                placeholder="Please enter your note here..."
                :counter="300"
                :rules="[
                  newNote.text != '' || 'Note contents must not be empty',
                  (v) => v.length <= 300 || 'Max 300 characters',
                  newNote.type != '' || 'Please select note type',
                ]"
                required
                outlined
              />
              <v-file-input
                v-model="savedFiles"
                class="px-2"
                accept=".pdf, .jpg, .png"
                append-icon="mdi-file"
                prepend-icon=""
                multiple
                counter
                outlined
                dense
                truncate-length="10"
              ></v-file-input>
              <v-checkbox
                v-model="followUp"
                class="px-2"
                label="Set a Follow Up"
                outlined
                dense
              />
              <new-task
                v-if="followUp"
                :user="user"
                :enrolleeID="enrolleeID"
                :key="taskKey"
                @addNewNote="validateNewNote"
                @closeNewNote="closeNewNote"
              />
            </v-form>
            <v-card-actions v-if="!followUp">
              <v-btn color="accent" text @click="closeNewNote()">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="validateNewNote()">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- edit note dialog -->
        <v-dialog v-model="editNoteDialog" persistent max-width="450px">
          <v-card>
            <v-toolbar dark flat color="primary" class="mb-2">
              <v-card-title color="primary">Edit Note</v-card-title>
            </v-toolbar>
            <v-form ref="newNoteForm" lazy-validation>
              <v-text-field
                v-model="newNote.title"
                label="Title"
                class="px-2"
                :counter="20"
                :rules="[(v) => v.length < 20 || 'Max 20 characters']"
                hide-details
                outlined
                dense
              />
              <v-textarea
                v-model="newNote.text"
                class="mt-2 px-2"
                placeholder="Please enter your note here..."
                :counter="300"
                :rules="[
                  newNote.text != '' || 'Note contents must not be empty',
                  (v) => v.length <= 300 || 'Max 300 characters',
                ]"
                required
                outlined
              />
            </v-form>
            <v-card-actions>
              <v-btn
                color="accent"
                text
                @click="
                  editNoteDialog = false;
                  oldNote = {};
                "
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="editNote"> Edit </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- / edit note dialog -->
        <!-- delete prompt -->
        <v-dialog v-model="deleteDialog" max-width="900px">
          <v-card>
            <v-card-title
              >Are you sure you want to delete this note?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deleteNote()"
                >Yes</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteDialog = false"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- / delete prompt -->
        <v-row min-height="12rem">
          <v-col v-for="note in noteList" :key="note.id" cols="12" md="6">
            <v-card height="100%" width="100%">
              <v-row class="pb-2">
                <v-card-title class="pa-0 ml-5">
                  {{ note.title }}
                </v-card-title>
                <v-spacer></v-spacer>
                <div class="my-2">
                  <v-chip small outlined>
                    {{ note.type }}
                  </v-chip>
                  <v-btn
                    @click="
                      editNoteDialog = true;
                      newNote = { ...note };
                      oldNote = { ...note };
                    "
                    icon
                    small
                  >
                    <v-icon small>mdi-pencil-outline</v-icon></v-btn
                  >
                  <v-btn @click="promptDelete(note)" icon small class="mr-3"
                    ><v-icon small>mdi-close</v-icon></v-btn
                  >
                </div>
              </v-row>
              <v-divider></v-divider>
              <v-card-text style="height: 12rem">{{ note.text }}</v-card-text>
              <v-card-actions class="grey--text">
                <v-col cols="6" class="caption">
                  {{ note.date.slice(0, 10) }}
                </v-col>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import NewTask from "./NewTask.vue";

export default {
  props: ["notes", "user", "enrolleeID"],
  components: {
    NewTask,
  },
  data() {
    return {
      noteItems: ["All", "Email", "Phone", "Text"],
      editNoteDialog: false,
      newNoteDialog: false,
      deleteDialog: false,
      newNote: {
        title: "",
        text: "",
        type: "",
        author: "",
        date: null,
      },
      oldNote: {},
      toDelete: {},
      newNoteValid: false,
      selectedNote: "",
      followUp: false,
      taskKey: 0,
      filesUploaded: false,
      savedFiles: [],
    };
  },
  methods: {
    promptDelete(note) {
      this.deleteDialog = true;
      this.toDelete = note;
    },
    deleteNote() {
      this.deleteDialog = false;
      this.$emit("deleteNote", this.toDelete);
    },
    closeNewNote() {
      this.newNote.text = "";
      this.newNote.author = "";
      this.newNoteDialog = false;
      if (this.filesUploaded) {
        this.savedFiles = undefined
        this.filesUploaded = false;
      }
      this.followUp = false;
    },
    validateNewNote(newTask = undefined) {
      if (this.$refs.newNoteForm.validate()) {
        this.addNewNote(newTask);
      }
    },
    addNewNote(newTask = undefined) {
      this.newNoteDialog = false;
      if (newTask) {
        this.addTask(newTask);
        this.taskKey += 1;
      }

      this.$emit("addNote", { ...this.newNote });
      if (this.savedFiles) {
        this.uploadAttachments()
      }

      this.newNote = {
        title: "",
        text: "",
        type: "",
        author: "",
        date: null,
      };
    },
    editNote() {
      this.editNoteDialog = false;
      this.$emit("editNote", this.newNote, this.oldNote);
      this.newNote = {};
      this.oldNote = {};
    },
    async addTask(newTask) {
      await axios
        .post(`/api/tasks/new`, [newTask])
        .then((res) => {
          this.$emit("addTask", res.data.savedTask);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveAttachments(files) {
      // let data = new FormData();
      let formData = new FormData();

      // for (let i = 0; i < files.length; i++) {
      //   let file = files[i];
      //   data.append("files[" + i + "]", file);
      // }
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          let file = files[i];
          formData.append("file[" + i + "]", file);
        }

      this.savedFiles = formData
    },
    async uploadAttachments(){
      let formData = new FormData();
        for (let i = 0; i < this.savedFiles.length; i++) {
          let file = this.savedFiles[i];
          formData.append("file[" + i + "]", file);
        }
      await axios
        .post(
          `api/modals/enrollment/documents/upload/${this.enrolleeID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
           this.$emit("getDocuments");
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "Error uploading files. Please try again later.";
          this.snackbar = true;
        })
        .finally(() => {
          this.fileUploading = false;
        });
    }
  },
  computed: {
    noteList() {
      if (this.selectedNote === "Email") {
        return this.notes.filter((element) => element.type === "Email");
      } else if (this.selectedNote === "Phone") {
        return this.notes.filter((element) => element.type === "Phone");
      } else if (this.selectedNote === "Text") {
        return this.notes.filter((element) => element.type === "Text");
      } else {
        return this.notes;
      }
    },
    noteDialogWidth() {
      if (this.followUp) {
        return "800px";
      } else return "450px";
    },
  },
};
</script>