<template>
    <div>
        <v-row>
            <v-col cols="12" class="float-left">
                <v-btn color="success" @click="openSurgeryModal()" class="mb-3">
                    <v-icon small>mdi-plus-box</v-icon>
                    Add Surgical Procedure
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="surgeryModal" max-width="900px">
            <v-card>
                <v-toolbar dark color="primary" class="mx-auto">Add Surgical Procedure</v-toolbar>
                <v-card-text>
                    <v-form ref="surgeryForm" lazy-validation class="pa-3">
                        <v-text-field
                        v-model="surgeryType"
                        label="Enter surgical procedure"
                        outlined
                        dense
                        required
                        class="mt-3"
                        :rules="[
                            surgeryType != '' ||
                            'Please enter a surgical procedure',
                        ]"
                        ></v-text-field>
                        <v-menu
                            ref="surgeryDateMenu"
                            v-model="surgeryDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="surgeryDate"
                                    label="Date of Surgery"
                                    append-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[
                                    surgeryDate != '' || 'Please enter the date the surgery was conducted',
                                ]"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="surgeryDate"
                                min="1900-01-01"
                                max="2100-12-01"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    outlined
                                    color="primary"
                                    @click="notificationDateMenu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed
                                    color="primary"
                                    @click="
                                    $refs.surgeryDateMenu.save(surgeryDate)
                                    "
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-card-actions>
                            <v-btn
                                @click="addSurgery()"
                                color="success"
                                text
                            >
                                Add Procedure
                            </v-btn>
                            <v-btn
                                @click="surgeryModal = false"
                                color="accent"
                                text
                            >
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            surgeryModal: false,
            surgeryType: '',
            surgeryDateMenu: false,
            surgeryDate: '',
        }
    },
    methods: {
        openSurgeryModal() {
            this.surgeryModal = true;
        },
        addSurgery() {
            if (this.$refs.surgeryForm.validate()) {
                let newSurgObj = {
                    surgeryName: this.surgeryType,
                    date: this.surgeryDate
                }
                this.$emit('surgery', newSurgObj)
                this.surgeryModal = false;
                this.surgeryType = ''
                this.surgeryDate = ''
            }
        },
    }
}
</script>