<template>
  <v-dialog v-model="diagnosisDialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        color="success"
      >
        <v-icon>mdi-pencil-plus</v-icon>
        Add Diagnosis
      </v-btn>
    </template>

    <v-toolbar dark color="primary" class="mx-auto" dense>
      <v-toolbar-title>Add Diagnosis</v-toolbar-title>
    </v-toolbar>

    <v-card class="pa-3" tile>
      <v-form ref="form">
        <v-row>
          <v-card-text class="pb-0">
            Problem:
          </v-card-text>
          <v-col cols="12">
            <v-text-field
              label="Enter a Problem"
              v-model="problem"
              required
              :rules="[
                problem != '' ||
                  'Please make an entry',
              ]"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-card-text class="py-0">
            Diagnosis: 
          </v-card-text>
          <v-col cols="12">
            <v-text-field
              label="Enter a Diagnosis"
              v-model="diagnosis"
              required
              :rules="[
                diagnosis != '' ||
                  'Please make an entry',
              ]"
              outlined
              dense
            ></v-text-field>
          </v-col>
          
          <v-card-text class="py-0">
            Goals:
          </v-card-text>
          <v-col cols="12" md="6" v-for="(item, index) in goals" :key="index">
            <v-text-field
              label="Please enter a goal"
              v-model="item.goal"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-btn
              class="mx-2"
              @click="removeGoal"
              fab
              dark
              x-small
              color="grey"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>

            <v-btn
              class="mx-2"
              @click="addGoal"
              fab
              dark
              x-small
              color="primary"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="cancelDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="validate"
          color="success"
          text
        >
          Save Selected
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "memSearchDialog",
  props: [],
  components: {
  },
  data() {
    return {
      diagnosisDialog: false,
      problem: '',
      diagnosis: '',
      goals:[
        {
          identifier: 1,
          goal:''
        }
      ],
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.savePlanItem();
      }
    },
    savePlanItem() {
      // create and emit object to fill parent data table
      let planItem = {
        problem: this.problem,
        diagnosis: this.diagnosis,
        goals: this.goals
      }

      this.$emit('newPlanItem', planItem);
      this.diagnosisDialog = false;

      // reset dialog
      this.problem = '';
      this.diagnosis = '';
      this.goals = [
        {
          identifier: 1,
          goal:''
        }
     ];
    },
    addGoal() {
      this.goals.push({
        identifier: this.goals.length + 1,
        goal: ''
      });
    },
    removeGoal() {
      if(this.goals.length > 1) {
        this.goals.pop();
      }
    },
    cancelDialog() {
      this.diagnosisCode = '';
      this.serviceCode = '';
      this.serviceDescription = '';
      this.numOfVisits = 0;
      this.numOfUnits = 0;
      this.diagnosisDialog = false;
    }
  },
  computed: {
  },
  watch: {
    diagnosisDialog() {
      if(this.diagnosisDialog) {
        this.$refs.form.reset();
      }
    },
  }
};
</script>

<style scoped>

</style>
