<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-navigation-drawer
      v-model="drawer"
      color="primary"
      dense
      app
      bottom
      mobile-breakpoint="725"
      v-if="userAuthenticated && !hide"
    >
      <v-list dark>
        <v-list-item class="mb-2" h>
          <v-list-item-avatar width="100%" height="80px">
            <v-img :src="require('@/assets/vernierwhite.png')" contain></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <template v-for="item in items">
          <v-list-group
            v-if="item.subitems.length > 0"
            :key="item.title"
            :prepend-icon="item.icon"
            no-action
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in item.subitems"
              :key="subItem.title"
              :to="subItem.route"
              active-class="secondary--text"
            >
              <v-list-item-content>
                <v-list-item-title v-text="subItem.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="item.title"
            :to="item.route"
            active-class="secondary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
            href="https://qcdemo.quickcap.net/DEMO/general/index.php"
            target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Claim Processing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.role !== undefined && user.role !== 'member services'"
          href="https://enrollment.sonderhealthplans.com/presentation"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-school-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Training</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-btn
          class="transparent my-5 text-body-1 text-capitalize"
          depressed
          block
          dark
          @click="logout"
        >
          Logout
        </v-btn>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-app-bar flat color="transparent" v-if="userAuthenticated && !hide">
        <v-toolbar-title></v-toolbar-title>
        <v-btn
          class="nav-button-visibility"
          small
          elevation="0"
          outlined
          color="primary"
          @click.stop="drawer = !drawer"
        >
          <v-icon color="primary">mdi-menu</v-icon>
        </v-btn>
      </v-app-bar>

      <router-view></router-view>
    </v-main>

    <v-footer color="transparent">
      <v-card
        elevation="0"
        tile
        color="transparent"
        class="text-center"
        width="100%"
      >
        <v-card-text id="footer">
          © {{ new Date().getFullYear() }} —
          <strong>Vernier</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data: () => ({
    user: "",
    userAuthenticated: false,
    goDark: false,
    errorMessage: false,
    loading: false,
    menu: false,
    drawer: true,
    items: [],
  }),
  mounted() {
    if (this.$route.name !== "sign-soa-id") {
      this.getUser();
    }

    // load theme
    if (localStorage.darkMode) {
      this.goDark = JSON.parse(localStorage.getItem("darkMode" || "[]"));
    }

    // set menu size
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawer = false;
    } else {
      this.drawer = true;
    }
  },
  methods: {
    async getUser() {
      await axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          this.userAuthenticated = true;
          if (this.user.firstLogin === true) {
            this.$router.push("/pwchange");
          }
        })
        .then(() => {
          if (this.$route.path === "/pwchange") {
            this.drawer = false;
            this.showAppBar = false;
          }
        })
        .catch(() => {
          this.userAuthenticated = false;
          this.$router.push("/login");
        });
    },
    logout() {
      this.user = "";
      this.menu = false;
      axios.get("/api/logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  watch: {
    $route() {
      if (this.$route.path === "/pwchange") {
        this.drawer = false;
        this.showAppBar = false;
      }
      if (this.$route.path === "/") {
        this.drawer = true;
        this.showAppBar = true;
      }
    },
    hide() {
      this.getUser();
    },
    async goDark() {
      localStorage.setItem("darkMode", JSON.stringify(this.goDark));
      if (this.goDark) this.$vuetify.theme.dark = true;
      else this.$vuetify.theme.dark = false;
    },
    user() {
      if (this.user.role === "superuser" || this.user.role === "manager") {
        this.items = [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "/",
            subitems: [],
          },
          {
            title: "Agents",
            icon: "mdi-account-multiple",
            route: "/agents",
            subitems: [],
          },
          {
            title: "Members",
            icon: "mdi-account-check",
            subitems: [
              {
                title: "Enrollments",
                route: "/enrollments",
              },
              {
                title: "Disenrollments",
                route: "/disenrollments",
              },
              {
                title: "Pending",
                route: "/pending",
              },
              {
                title: "Not Eligible",
                route: "/noteligible",
              },
              {
                title: "New Enrollment",
                route: "/newenrollment",
              },
            ],
          },
          {
            title: "HRA",
            icon: "mdi-file-document-edit",
            subitems: [
              {
                title: "New Enrollment",
                route: "/hraenrollment",
              },
              {
                title: "Enrolled Patients",
                route: "/hrapatients",
              },
            ],
          },
          {
            title: "Credentialing",
            icon: "mdi-credit-card-clock",
            subitems: [
              {
                title: "Search",
                route: "/credentialing",
              },
              {
                title: "New",
                route: "/newprovider",
              },
              // hide for now but we need for future
              // {
              //   title: "Reports",
              //   route: "/credreports"
              // }
            ],
          },
          {
            title: "Correspondence",
            icon: "mdi-email",
            subitems: [
              {
                title: "New",
                route: "/newcorrespondence",
              },
              {
                title: "Sent",
                route: "/sentcorrespondence",
              },
            ],
          },
          {
            title: "Scope of Appt",
            icon: "mdi-clipboard-check",
            subitems: [
              {
                title: "New",
                route: "/NewSOA",
              },
              {
                title: "Sent",
                route: "/SOA",
              },
            ],
          },
          {
            title: "Reports",
            icon: "mdi-account-check",
            subitems: [
              {
                title: "Member Reports",
                route: "/reports",
              },
              {
                title: "Provider Reports",
                route: "/providerreports",
              },
              {
                title: "Capitation Reports",
                route: "/capitationreports",
              },
            ],
          },
          // {
          //   title: 'Care Plans',
          //   icon: 'mdi-notebook',
          //   route: '/careplans',
          //   subitems: [],
          // },
          // {
          //   title: "Claims",
          //   icon: "mdi-file-document-multiple",
          //   subitems: [
          //     {
          //       title: "Manual Claim",
          //       route: "/SubmitClaim",
          //     },
          //     {
          //       title: "Create Claim",
          //       route: "/CreateClaim",
          //     },
          //     {
          //       title: "Claim History",
          //       route: "/ClaimHistory",
          //     },
          //     {
          //       title: "Plan Settings",
          //       route: "/PlanSettings",
          //     },
          //   ],
          // },
          {
            title: "Authorizations",
            icon: "mdi-fax",
            route: "/authorizations",
            subitems: [],
          },
          {
            title: "Notifications",
            icon: "mdi-bell",
            route: "/notifications",
            subitems: [],
          },
          {
            title: "Tasks",
            icon: "mdi-notebook-check",
            route: "/tasks",
            subitems: [],
          },
          {
            title: "Administration",
            icon: "mdi-account-cog",
            route: "/admin",
            subitems: [],
          },
        ];
      } else if (this.user.role === "agent") {
        this.items = [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "/",
            subitems: [],
          },
          {
            title: "Credentialing",
            icon: "mdi-credit-card-clock",
            subitems: [
              {
                title: "Search",
                route: "/credentialing",
              },
              // hide for now but we need for future
              // {
              //   title: "Reports",
              //   route: "/credreports"
              // }
            ],
          },
          {
            title: "Members",
            icon: "mdi-account-check",
            subitems: [
              {
                title: "Enrollments",
                route: "/enrollments",
              },
              {
                title: "Disenrollments",
                route: "/disenrollments",
              },
              {
                title: "Pending",
                route: "/pending",
              },
              {
                title: "Not Eligible",
                route: "/noteligible",
              },
              {
                title: "New Enrollment",
                route: "/newenrollment",
              },
            ],
          },
          {
            title: "Scope of Appt",
            icon: "mdi-clipboard-check",
            subitems: [
              {
                title: "New",
                route: "/NewSOA",
              },
              {
                title: "Sent",
                route: "/SOA",
              },
            ],
          },
          {
            title: "Tasks",
            icon: "mdi-notebook-check",
            route: "/tasks",
            subitems: [],
          },
        ];
      } else if (this.user.role === "credentialing") {
        this.items = [
          {
            title: "Credentialing",
            icon: "mdi-credit-card-clock",
            subitems: [
              {
                title: "Search",
                route: "/credentialing",
              },
              // hide for now but we need for future
              // {
              //   title: "Reports",
              //   route: "/credreports"
              // }
            ],
          },
          {
            title: "Correspondence",
            icon: "mdi-email",
            subitems: [
              {
                title: "New",
                route: "/newcorrespondence",
              },
              {
                title: "Sent",
                route: "/sentcorrespondence",
              },
            ],
          },
          {
            title: "Notifications",
            icon: "mdi-bell",
            route: "/notifications",
            subitems: [],
          },
        ];
      } else if (this.user.role === "hra") {
        this.items = [
          {
            title: "HRA",
            icon: "mdi-file-document-edit",
            subitems: [
              {
                title: "New Enrollment",
                route: "/hraenrollment",
              },
              {
                title: "Enrolled Patients",
                route: "/hrapatients",
              },
            ],
          },
          {
            title: "Authorizations",
            icon: "mdi-fax",
            route: "/authorizations",
            subitems: [],
          },
          {
            title: "Care Plans",
            icon: "mdi-notebook",
            route: "/careplans",
            subitems: [],
          },
          {
            title: "Notifications",
            icon: "mdi-bell",
            route: "/notifications",
            subitems: [],
          },
        ];
      } else if (this.user.role === "fmo") {
        this.items = [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "/",
            subitems: [],
          },
          {
            title: "Agents",
            icon: "mdi-account-multiple",
            route: "/agents",
            subitems: [],
          },
          {
            title: "Members",
            icon: "mdi-account-check",
            subitems: [
              {
                title: "Enrollments",
                route: "/enrollments",
              },
              {
                title: "Disenrollments",
                route: "/disenrollments",
              },
              {
                title: "Pending",
                route: "/pending",
              },
              {
                title: "Not Eligible",
                route: "/noteligible",
              },
              {
                title: "New Enrollment",
                route: "/newenrollment",
              },
            ],
          },
          {
            title: "Credentialing",
            icon: "mdi-credit-card-clock",
            subitems: [
              {
                title: "Search",
                route: "/credentialing",
              },
              // hide for now but we need for future
              // {
              //   title: "Reports",
              //   route: "/credreports"
              // }
            ],
          },
          {
            title: "Scope of Appt",
            icon: "mdi-clipboard-check",
            subitems: [
              {
                title: "New",
                route: "/NewSOA",
              },
              {
                title: "Sent",
                route: "/SOA",
              },
            ],
          },
          {
            title: "Reports",
            icon: "mdi-account-check",
            subitems: [
              {
                title: "Member Reports",
                route: "/reports",
              },
              {
                title: "Provider Reports",
                route: "/providerreports",
              },
              {
                title: "Capitation Reports",
                route: "/capitationreports",
              },
            ],
          },
          {
            title: "Tasks",
            icon: "mdi-notebook-check",
            route: "/tasks",
            subitems: [],
          },
        ];
      } else if (this.user.role === "member services") {
        this.items = [
          {
            title: "Credentialing",
            icon: "mdi-credit-card-clock",
            subitems: [
              {
                title: "Search",
                route: "/credentialing",
              },
              // hide for now but we need for future
              // {
              //   title: "Reports",
              //   route: "/credreports"
              // }
            ],
          },
          {
            title: "Members",
            icon: "mdi-account-check",
            subitems: [
              {
                title: "Enrollments",
                route: "/enrollments",
              },
              {
                title: "Disenrollments",
                route: "/disenrollments",
              },
              {
                title: "Pending",
                route: "/pending",
              },
              {
                title: "Not Eligible",
                route: "/noteligible",
              },
            ],
          },
          {
            title: "Reports",
            icon: "mdi-account-check",
            subitems: [
              {
                title: "Member Reports",
                route: "/reports",
              },
            ],
          },
        ];
      }
    },
  },
  computed: {
    hide() {
      return this.$route.path === "/login";
    },
    setTheme() {
      if (this.goDark == true) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Poppins", sans-serif; /* font for entire app */
}

@media only screen and (min-width: 1904px) {
  .container {
    max-width: 1500px !important;
  }
}
.v-navigation-drawer__border {
  width: 0 !important;
}
@media only screen and (max-width: 725px) {
  .login-sidebar {
    width: 100% !important;
  }
}
@media only screen and (min-width: 725px) {
  .login-sidebar {
    width: 20% !important;
  }
}
@media only screen and (min-width: 1900px) {
  .login-sidebar {
    width: 13% !important;
  }
}

@media only screen and (min-width: 1900px) {
  .v-navigation-drawer__content {
    /* on mobile view, nav bar comes from bottom and is full width */
    width: 100% !important;
  }
}

@media only screen and (max-width: 725px) {
  .v-navigation-drawer__content {
    /* on mobile view, nav bar comes from bottom and is full width */
    width: 100%;
  }
}
@media only screen and (min-width: 725px) {
  .v-navigation-drawer__content {
    width: 100%;
    /* makes so works in nav list show fully (overflow not hidden)*/
  }
  .nav-button-visibility {
    /* removes nav bar at certain breakpoint */
    visibility: hidden;
  }
}

.dash-list-btn {
  text-transform: none;
  font-weight: 400;
  text-align: left;
}
.login-form {
  z-index: 69;
  margin: 0;
}
.logout-menu-option {
  position: fixed !important;
  bottom: 0;
  width: 100%;
}
/* Remove left margin on Menu Expand arrow to make all labels readable */
.v-list-group__header__append-icon {
  margin-left: 0 !important;
}

.v-menu__content {
  z-index: 10001 !important;
}

#footer {
  z-index: 1000 !important;
  background-color: white;
}
</style>
