<template>
  <v-container>
    <v-tabs
      fixed-tabs
      background-color="primary"
      v-model="tab"
    >
      <v-tab v-for="item in tabItems" :key="item">{{ item }}</v-tab>
      <v-tabs-items v-model="tab">
        <!-- USERS -->
        <v-tab-item>
          <user-admin />
        </v-tab-item>
        <!-- FMOs -->
        <v-tab-item>
          <fmo-admin />
        </v-tab-item>
        <!-- INTERNAL RATES -->
        <v-tab-item>
          <internal-rates-admin />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import axios from "axios";
// import ExportCSV from "vue-json-csv";
import UserAdmin from "../components/admin-components/UserAdmin";
import FmoAdmin from "../components/admin-components/FmoAdmin";
import InternalRatesAdmin from "../components/admin-components/InternalRatesAdmin";

export default {
  name: "Admin",
  components: {
    UserAdmin,
    FmoAdmin,
    InternalRatesAdmin
    // ExportCSV,
  },
  data() {
    return {
      tab: null,
      tabItems: [
        'Users',
        'FMO',
        'Internal Rates'
      ],
    };
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (
            this.user.role === "agent" ||
            this.user.role === "fmo"
          ) {
            this.$router.push("/");
          } else if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async getAllUsers() {
      this.userData = [];
      this.load = true;
      axios.get("api/users").then((res) => {
        this.userData = res.data;
        this.csvData = JSON.parse(JSON.stringify(this.userData))
        this.csvData.forEach((user) => {
          delete user._id
          delete user.filledPdf
        })
        this.userData.forEach((user) => {
          user.role =
            user.role === "hra"
              ? user.role.toUpperCase()
              : user.role.charAt(0).toUpperCase() + user.role.slice(1);
        });

        this.load = false;
      });
    },
    editUser(item) {
      this.editedUserIndex = this.userData.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.userDialog = true;
    },
    closeUserDialog() {
      this.userDialog = false;
      // reload users to reflect changes
      this.getAllUsers();

      setTimeout(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedUserIndex = -1;
      }, 300);
    },
    async validate() {
      // If New User this.editedUserIndex will equal -1, if so we need to check if email already exists. If we are editing an existing user we will go into the else and skip the email check.
      if (this.editedUserIndex === -1) {
        //Check if the email is already being used
        await axios
          .get(`api/users/emailcheck/${this.editedUser.email}`)
          .then((res) => {
            this.emailInUse = res.data;
          });
        if (this.$refs.form.validate() && this.emailInUse === false) {
          this.saveChanges();
        }
      } else {
        // Validate that data is correct before submitting
        if (this.$refs.form.validate()) {
          this.saveChanges();
        }
      }
    },
    saveChanges() {
      if (this.editedUserIndex > -1) {
        this.updateUser();
      } else {
        this.submitUser();
      }
      this.closeUserDialog();
    },
    updateUser() {
      const { _id } = this.editedUser;
      const { userID } = this.editedUser;
      const { firstname } = this.editedUser;
      const { lastname } = this.editedUser;
      const { clinicaltype } = this.editedUser;
      const { email } = this.editedUser;
      const { role } = this.editedUser;
      const { firstLogin } = this.editedUser;

      const data = {
        _id,
        userID,
        firstname,
        lastname,
        clinicaltype,
        email: email.toLowerCase(),
        role: role.toLowerCase(),
        firstLogin,
      };

      axios.post("/api/users/update", data).catch((err) => {
        console.log(err);
        console.log("Couldn't update user.");
      });
    },
    submitUser() {
      const { userID } = this.editedUser;
      const { firstname } = this.editedUser;
      const { lastname } = this.editedUser;
      const { clinicaltype } = this.editedUser;
      const { email } = this.editedUser;
      const { password } = this.editedUser;
      const { role } = this.editedUser;
      const { firstLogin } = this.editedUser;
      const data = {
        userID,
        firstname,
        lastname,
        clinicaltype,
        email: email.toLowerCase(),
        password,
        role: role.toLowerCase(),
        firstLogin
        };
      
      axios.post("/api/users/create", data).catch((err) => {
        console.log(err);
        console.log("Couldn't register user.");
      });
    },
    deleteUser(user) {
      const index = this.userData.indexOf(user);
      axios
        .post("/api/users/delete", user)
        .then(() => {
          this.userData.splice(index, 1);
          this.deleteDialog = false;
        })
        .catch((err) => {
          console.log(err);
          console.log("Couldn't delete user.");
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
</style>
