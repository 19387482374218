<template>
  <v-container class="mt-7">
    <v-row>
      <v-col cols="12">
        <span class="display-1">Credentialing Search</span>
        <span v-if="exportData.length === 0">
          <v-btn
            v-if="user && user.role !== 'agent' && user.role !== 'fmo' && !affiliateMode"
            color="accent"
            outlined
            small
            class="mb-2 ml-3"
            :disabled="csvLoading"
            @click="getDataForExport()"
          >
            <div v-if="csvLoading">
              <v-progress-circular
                :size="15"
                color="grey"
                indeterminate
              ></v-progress-circular>
              Building CSV File
            </div>
            <div v-else>
              Generate CSV data
            </div>
          </v-btn>
        </span>
        <span v-else>
          <ExportCSV
            :data="exportData"
            class="export-btn"
            :name="'credentialing.csv'"
            v-if="!affiliateMode"
          >
            <v-btn
              color="accent"
              outlined
              small
              :disabled="csvLoading"
              class="mb-2 ml-3"
            >
              Export to CSV
            </v-btn>
          </ExportCSV>
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="searchFilter !== 'Group NPI'" cols="12" md="3" class="pb-0">
        <v-text-field
          v-model="search"
          id="providerSearch"
          :label="searchFilter"
          @keydown.enter="submit"
          autofocus
          dense
          clearable
          outlined
        ></v-text-field>
      </v-col>
      <v-col v-else cols="12" md="3">
        <v-autocomplete
          v-model="search"
          :items="groupNPIs"
          outlined
          dense
          label="Group NPI"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          dense
          v-model="searchFilter"
          :items="searchOptions"
          outlined
          label="Search by"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          dense
          v-model="parStatus"
          :items="['all', 'par', 'nonPar']"
          outlined
          label="Par/non-par filter"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          dense
          v-model="credType"
          :items="['provider', 'facility', 'affiliate']"
          outlined
          label="Credential Type"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="MedicalGroupAffiliation"
          :items="medicalAffiliates"
          clearable
          outlined
          dense
          :disabled="credType === 'affiliate'"
          label="Medical Group Affiliation"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          label="Fee Schedule Type"
          v-model="feeScheduleType"
          :items="['Service', 'Capitation']"
          outlined
          dense
          clearable
          :disabled="credType === 'affiliate'"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn @click="submit" color="accent" block dense>
          Submit
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="!affiliateMode">
      <div v-if="!load">
        <v-data-iterator
          :items="filteredData"
          :items-per-page.sync="itemsPerPage"
          :page="page"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row v-if="filteredData.length > 0">
              <v-col
                cols="12"
                md="6"
                lg="4"
                v-for="item in props.items"
                :key="item.RenderingNPI"
              >
                <v-card id="cred-card" class="elevation-1" tile>
                  <v-card-actions id="cred-actions">
                    <credentialing-modal
                      :npiNumber="item.RenderingNPI"
                      :isFacility="credType === 'facility' ? true : false"
                      @recordUpdated="getData"
                      :user="user"
                    />
                  </v-card-actions>

                  <v-card-title id="cred-title">
                    {{
                      chosenCredtype === "provider"
                        ? item.RenderingProviderFullName
                        : item.FacilityName
                    }}
                    
                  </v-card-title>
                  <v-card-subtitle class="pb-1">
                    <span class="accent--text">NPI:</span> {{ item.RenderingNPI }}
                  </v-card-subtitle>
                  <v-card-text class="mt-0 pt-0 pb-1">
                    <span class="primary--text">Date Credentialed:</span>
                    {{
                      item.SonderCredDate
                        ? item.SonderCredDate.substr(0, 10)
                        : "No data"
                    }}
                    <p class="mt-1">
                      <v-icon :color="parHelper(item.Locations) === 'Par' ? 'success' : 'warning'">mdi-circle</v-icon>
                      {{ parHelper(item.Locations)}}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-else-if="filteredData.length === 0">
              <v-col class="pt-0">
                <v-alert width="100%" text type="error">
                  No matching results...
                </v-alert>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row class="px-7 pt-2 pb-4" align="center" justify="center">
              <span class="grey--text">Items per page</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn dark text color="primary" v-on="on">
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-spacer></v-spacer>

              <span class="mr-4 grey--text"
                >Page {{ page }} of {{ numberOfPages }}</span
              >
              <v-btn
                small
                fab
                dark
                color="primary"
                class="mr-1"
                @click="formerPage()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                small
                fab
                dark
                color="primary"
                class="ml-1"
                @click="nextPage()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </div>

      <div v-else>
        <v-row justify="center">
          <v-col align-self="center" class="text-center">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </div>
    </template>

    <template v-else>
      <div v-if="!load">
        <!-- {{medicalAffiliates}} -->
        <v-data-iterator
          :items="filteredData"
          :items-per-page.sync="itemsPerPage"
          :page="page"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item._id">
                <v-card id="cred-card" class="elevation-1" tile>
                  <v-card-actions id="cred-actions">
                    <affiliate-modal :affiliateName="item.tax_id" />
                  </v-card-actions>

                  <v-card-title id="cred-title">
                    {{item.affiliate_name}}
                  </v-card-title>
                  <v-card-subtitle class="pb-1">
                    <span class="accent--text">Tax ID:</span> {{ item.tax_id }}
                  </v-card-subtitle>
                  <v-card-text class="mt-0 pt-0 contact-text" v-if="item.contact_lname && item.contact_fname">
                    <span class="primary--text">
                      Contact: 
                    </span>
                    {{item.contact_lname}}, {{item.contact_fname}}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row class="px-7 pt-2 pb-4" align="center" justify="center">
              <span class="grey--text">Items per page</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn dark text color="primary" v-on="on">
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-spacer></v-spacer>

              <span class="mr-4 grey--text"
                >Page {{ page }} of {{ numberOfPages }}</span
              >
              <v-btn
                small
                fab
                dark
                color="primary"
                class="mr-1"
                @click="formerPage()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                small
                fab
                dark
                color="primary"
                class="ml-1"
                @click="nextPage()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </div>
    </template>

    <v-row>
      <v-snackbar
        v-model="showCaqhSuccessAlert"
        color="success"
        :timeout="3000"
      >
        Record saved successfully!
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackInfo = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-row>

    <v-snackbar v-model="snackSuccess" :timeout="3000" color="success">
      Record Saved Successfully!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackSuccess = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CredentialingModal from "../components/CredentialingModal";
import AffiliateModal from "../components/AffiliateModal.vue"
import ExportCSV from "vue-json-csv";
import axios from "axios";

export default {
  name: "Credentialing",
  components: {
    CredentialingModal,
    AffiliateModal,
    ExportCSV,
  },
  data() {
    return {
      filterBtnTTip: false,
      load: false,
      search: null,
      searchNPI: false,
      user: "",
      credData: [],
      medicalAffiliates: [],
      MedicalGroupAffiliation: null,
      filteredData: [],
      exportData: [],
      chosenCredtype: "provider",
      credType: "provider",
      showCaqhSuccessAlert: false,
      csvLoading: false,
      parStatus: "all",
      parData: [],
      nonParData: [],
      allData: [],
      statusFilter: "",
      statusItems: [
        "Pending",
        "Ready For Review",
        "Active",
        "Inactive",
        "Withdrawn",
        "Termed",
      ],
      feeScheduleType: null,
      snackSuccess: false,
      searchFilter: "Name",
      searchOptions: ["Name", "NPI", "Group NPI", "Tax ID"],
      groupNPIs: [],
      itemsPerPage: 21,
      itemsPerPageArray: [12, 20, 32, 40],
      page: 1,
      affiliateMode: false,
      affiliateDialog: false,
    };
  },
  mounted() {
    this.getUser();
    this.getMedicalAffiliates();
  },
  methods: {
    async getUser() {
      await axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else {
            this.getData();
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async getGroupNPIs() {
      await axios
        .get(`api/credentialing/groupnpis/${this.credType}`)
        .then((response) => {
          this.groupNPIs = response.data[0].NPIs;
        });
    },
    async getData() {
      this.allData = [];
      this.load = true;
      this.statusFilter = "";

      // Check for pending request and if so cancel old one for new incoming
      this.cancelSearch();
      // Set the CancelToken
      this.cancelSource = axios.CancelToken.source();

      let searchField = this.searchFilter.toLowerCase();
      let requestURL;
      if(this.credType != 'affiliate') {
        if (this.search !== null && this.search !== "") {
          requestURL = `api/credentialing/search/${this.parStatus}/${this.credType}/${searchField}/${this.search}/${this.feeScheduleType}/${this.MedicalGroupAffiliation}`;
        } else {
          requestURL = `api/credentialing/search/${this.parStatus}/${this.credType}/false/false/${this.feeScheduleType}/${this.MedicalGroupAffiliation}`;
        }

        await axios
          .get(requestURL, { cancelToken: this.cancelSource.token })
          .then((res) => {
            this.credData = res.data;
            this.filteredData = res.data;
            this.getDataForExport();
            this.cancelSource = null;
            this.load = false;
            // Once we have our response we can set csvLoading to false
            this.csvLoading = false;
          });
      } else {
        await axios.get(`/api/affiliates/search/${searchField}/${this.search}/`).then((res) => {
          this.filteredData = res.data
        })
      }
    },
    async getMedicalAffiliates() {
      await axios.get(`/api/affiliates/all/${true}`).then((res) => {
        this.medicalAffiliates = res.data;
      });
    },
    async getDataForExport() {
      let searchField = this.searchFilter.toLowerCase();
      let tempSearch = this.search

      if(!this.search) {
        tempSearch = 'false'
        searchField = 'false'
      }

      this.csvLoading = true;
      this.exportData = [];

        let requestURL = `api/credentialing/export/${this.parStatus}/${this.credType}/${searchField}/${tempSearch}/${this.feeScheduleType}/${this.MedicalGroupAffiliation}`;
        await axios.get(requestURL).then((res) => {
          this.exportData = res.data;
          this.exportData.forEach((obj) => {
            delete obj._id;
          });
          this.csvLoading = false;
        });
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    addedProvOrFacility() {
      this.snackSuccess = true;
    },
    getStatusColor(status) {
      if (status === "Active") {
        return "success";
      } else if (
        status === "Pending/ In-Process" ||
        status === "Ready For Review" ||
        status === "Ready ForCred Committee" ||
        status === "Pending CVO Check List" ||
        status === "Pending Updated Malpractice Insurance"
      ) {
        return "yellow";
      } else if (
        status === "Inactive" ||
        status === "Termed" ||
        status === "Withdrawn" ||
        status === "Expired CAQH Attestation" ||
        status === "Listed on SAM GSA Report"
      ) {
        return "red";
      } else {
        return "black";
      }
    },
    async submit() {
      // Everytime we hit the submit button we are going to clear the exportData and set csvLoading to true until we get our response from the backend
      this.csvLoading = true;
      this.exportData = [];
      this.load = true;
      this.chosenCredtype = this.credType;
      this.affiliateMode = this.credType === 'affiliate'
      await this.getData();
      this.load = false;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        return (this.page += 1);
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        return (this.page -= 1);
      }
    },
    parHelper(locationsArr) {
      const isActive = locationsArr.some(current => { return current.Status === "Active"})
      return isActive ? "Par" : "Non-Par"
    }
  },
  watch: {
    searchFilter() {
      this.search = null;
      if (this.searchFilter === "Group NPI") {
        this.getGroupNPIs();
      }
    },
    credType() {
      // this.search = null;
      if (this.searchFilter === "Group NPI") {
        this.getGroupNPIs();
      }
    },
    statusFilter() {
      let filterResults = this.credData;

      if (this.statusFilter) {
        filterResults = filterResults.filter((item) => {
          return item["Status(DD)"] === this.statusFilter;
        });
      }

      this.filteredData = filterResults;
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
  },
};
</script>

<style>
#cred-actions {
  padding: 0 !important;
}

#cred-title {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.export-btn {
  display: inline-block;
}

.contact-text {
  text-transform: capitalize;
}
</style>
