<template>
  <v-dialog v-model="newNotificationDialog" max-width="900px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        color="success"
        
      >
        <v-icon>mdi-bell-outline</v-icon>
        Schedule Notification
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">New Notification</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="assignmentType"
                :items="assignmentItems"
                @change="clearAssigned"
                :readonly="view === 'authorization'"
                label="Assignment"
                outlined
                dense
                required
                :rules="[
                  assignmentType != null ||
                    'Please select the assignment',
                ]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Search Users"
                v-model="assignedUser"
                :disabled="assignmentType === 'Unassigned'"
                :items="assignmentList"
                :loading="assignmentLoading"
                :search-input.sync="assignmentSearch"
                :readonly="view === 'authorization'"
                item-text="fullName"
                return-object
                :clearable="view !== 'authorization'"
                required
                :rules="[assignedUser != '' || 'Assignment is required']"
                outlined
                dense
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for users
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title> {{ item.fullName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.userID }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>    
          <v-row>
            <v-col cols="6" sm="6">
              <v-text-field
                label="Notification ID"
                v-model="notificationID"
                :rules="[notificationID != '' || 'Notification ID is required']"
                required
                :readonly="view === 'authorization'"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-select
                label="Notification Type"
                :items="['Auth', 'Credentialing', 'Something Else', 'Correspondence']"
                v-model="type"
                :rules="[type != '' || 'Payment Type is required']"
                outlined
                :readonly="view === 'authorization'"
                dense
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                ref="notificationDateMenu"
                v-model="notificationDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="deliveryDate"
                      label="Delivery Date"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="deliveryDate"
                  :min="new Date().toISOString().substr(0, 10)"
                  max="2100-12-01"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      outlined
                      color="primary"
                      @click="notificationDateMenu = false"
                  >
                      Cancel
                  </v-btn>
                  <v-btn
                      depressed
                      color="primary"
                      @click="
                      $refs.notificationDateMenu.save(deliveryDate)
                      "
                  >
                      OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <time-selector 
                v-model="deliveryTime" 
                view="notificationModal" 
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="message"
                :items="messageOptions"
                label="Message"
                outlined
                dense
                required
                :rules="[
                  message != null ||
                    'Please select a message',
                ]"
              ></v-select>
              <v-textarea
                label="Message"
                :counter=250
                v-model="messageText"
                :rules="[
                  message != '' || 'Message is required',
                  v => v.length <= 250 || 'Max 250 characters'
                ]"
                required
                outlined
                dense
                :disabled="message !== 'Custom'"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="cancelNotification()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          text
          @click="validate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import TimeSelector from './TimeSelector.vue';

export default {
  name: "NewNotification",
  components: { TimeSelector },
  props: [
    'view', 
    'auth',
  ],
  data() {
    return {
      newNotificationDialog: false,
      valid: true,
      notificationDateMenu: false,
      notificationID: '',
      deliveryDate: '',
      deliveryTime: '',
      type: '',
      assignmentSearch: null,
      assignmentLoading: false,
      assignmentItems: [
        "Unassigned", 
        "Nurse", 
        "MD"
      ],
      assignedUser: null,
      assignmentList: [{}],
      assignmentType: '',
      message: '',
      messageOptions: ['New Assignment', 'Authorization Service Expiration', 'Custom'],
      messageText: ''
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.saveNotification();
      }
    },
    async saveNotification() {
      let newNotif = {
        assignee: this.assignedUser,
        deliveryDate: this.deliveryDate,
        deliveryTime: this.deliveryTime,
        notificationID: this.notificationID,
        type: this.type,
        message: this.messageText,
        assignmentType: this.assignmentType
      }

      await axios
        .post(`/api/notifications/new/${this.notificationID}`, [newNotif])
        .then((res) => {
          this.$emit('notificationCreated', res.data);
          this.newNotificationDialog = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    cancelNotification() {
      this.assignedUser = null;
      this.deliveryDate = '';
      this.deliveryTime = '';
      this.notificationID = '';
      this.type = '';
      this.message = '';
      this.messageText = '';
      this.assignmentType = '';

      this.newNotificationDialog = false;
    },
    clearAssigned() {
      if(this.assignmentType !== undefined) {
        this.assignedUser = {
          fullName: '',
          userID: '',
          email: '',
        };
      }
    },
  },
  watch: {
    assignmentSearch() {
      if(this.assignmentSearch !== null && this.assignmentType !== 'Unassigned') {
        this.assignmentLoading = true;

        // Lazily load input items
        axios
          .get(
            `/api/search/users/${this.assignmentType}/${this.assignmentSearch[0]}`
          )
          .then((response) => {
            this.assignmentList = response.data;
            this.assignmentLoading = false;
          });
      }
    },
    message() {
      switch(this.message) {
        case 'New Assignment':
          this.messageText = `You have been assigned to a new authorization (${this.auth.AuthID}).`
          break;
        case 'Authorization Service Expiration':
          this.messageText = `Authorization services (${this.auth.AuthID}) are set to expire ${this.auth.EndDate}. Please review the authorization and take the necessary actions.`
          break;
        case 'Custom':
          this.messageText = '';
          break;
      }
    },
    newNotificationDialog() {
      if(this.view === 'authorization') {
        this.type = 'Auth';
        this.notificationID = this.auth.AuthID;
        this.assignmentType = this.auth.ReviewStatus;
        this.assignedUser = this.auth.AssignedUser;
        if(this.auth.AssignedUser !== null) {
          this.assignmentList.push(this.auth.AssignedUser);
        }
      }
    },
  },
};
</script>