<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Scope of Appointment
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="px-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="reportData"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.filledPDF="{ item }">
                <v-icon @click="openPDFViewer(item)">mdi-file</v-icon>
              </template>

              <template v-slot:item.signedByBeneficiary="{ item }">
                <v-icon :color="getIconColor(item)">{{
                  item.signedByBeneficiary ? "mdi-check" : "mdi-window-close"
                }}</v-icon>
              </template>

              <template slot="footer">
                <ExportCSV :data="this.csvData" :name="'agent-testing.csv'">
                  <v-btn color="accent" text class="export-button">
                    Export to CSV
                  </v-btn>
                </ExportCSV>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="pdfViewerDialog" max-width="70%">
      <v-toolbar dark color="primary">
        <v-toolbar-title>View PDF</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.stop="pdfViewerDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card tile>
        <v-card-text class="py-3">
          <iframe
            title="frame"
            width="100%"
            height="1000px"
            :src="this.pdfFileToView"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import ExportCSV from "vue-json-csv";

export default {
  name: "Agents",
  components: {
    ExportCSV
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      pdfViewerDialog: false,
      pdfFileToView: "",
      selectedAgentAnswers: [],
      headers: [
        { text: "User ID", value: "userID" },
        { text: "Agent Name", value: "agentName" },
        { text: "Agent NPN", value: "agentNPN" },
        { text: "Beneficiary Name", value: "beneficiaryName" },
        { text: "Beneficiary Phone", value: "beneficiaryPhone" },
        { text: "Beneficiary Email", value: "beneficiaryEmail" },
        { text: "PDF File", value: "filledPDF" },
        { text: "Signed by Beneficiary", value: "signedByBeneficiary" }
      ],
      reportData: [],
      csvData: []
    };
  },
  methods: {
    getUser() {
      axios.get("/api/user").then(response => {
        this.user = response.data[0];
        // check role and reroute if unauthorized
        if (this.user.role === "hra") {
          this.$router.push("/hraenrollment");
        } else if (this.user.role === "credentialing") {
          this.$router.push("/credentialing");
        } else {
          this.getSOA();
        }
      }).catch(() => {
        this.$router.push("/login");
      });
    },
    async getSOA() {
      this.reportData = [];
      this.load = true;

      let requestURL = "";

      if (this.user.role === "agent" || this.user.role === "fmo") {
        requestURL = `api/soa/filled/${this.user.userID}`;
      } else if (
        this.user.role === "superuser" ||
        this.user.role === "manager"
      ) {
        requestURL = `api/soa/filled/all`;
      }
      axios.get(requestURL).then(res => {
        this.reportData = res.data;
        this.csvData = JSON.parse(JSON.stringify(this.reportData))
        this.csvData.forEach((soa) => {
          delete soa._id
          delete soa.filledPDF
        })
        this.load = false;
      });
    },
    openPDFViewer(item) {
      this.pdfFileToView =
        "data:application/pdf;base64," + encodeURI(item.filledPDF); // creates link to encoded pdf file
      this.pdfViewerDialog = true; // open dialog
    },
    getIconColor(item) {
      if (item.signedByBeneficiary) return "success";
      else return "error";
    }
  },
  mounted() {
    this.getUser();
  }
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}

.v-card__title {
  word-break: break-word !important;
}

.right-answer {
  background-color: rgb(0, 200, 83, 0.5);
  border-radius: 5px;
}
.wrong-answer {
  background-color: rgba(255, 82, 82, 0.5);
  border-radius: 5px;
}
</style>
