<template>
  <v-container>
    <v-card class="pa-2 mx-3 transparent-card" outlined>
      <v-card-title>
        Notifications
        <!-- <v-spacer></v-spacer>
        <new-notification /> -->
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="9">
            <v-text-field
              class="py-0"
              v-model="search"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <div class="d-flex justify-end">
              <v-switch inset v-model="scheduled">
                <template slot="prepend">
                  <p class="pt-1 mr-2">
                    {{ scheduled ? "Scheduled" : "Sent" }}
                  </p>
                </template>
              </v-switch>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="load"
              :headers="getHeaders"
              :items="scheduled ? scheduledData : sentData"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt.slice(0, 10) }}
              </template>

              <template v-slot:item.sent="{ item }">
                <v-icon v-if="item.sent" color="success">mdi-check</v-icon>
                <v-icon v-if="!item.sent" color="error">mdi-close</v-icon>
              </template>

              <template v-slot:item.ACTIONS="{ item }">
                <v-icon small class="mr-2" @click="openEditDialog(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteNotification(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Edit Notification</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12" sm="6">
                <v-menu transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Date"
                      v-model="editDate"
                      required
                      :rules="[
                        editDate != '' || 'Notification Date is required',
                      ]"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editDate"
                    :min="new Date().toISOString().slice(0, 10)"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <time-selector v-model="editTime" view="notificationModal" />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  class="py-0"
                  v-model="editMessage"
                  label="Message"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn class="ma-3" @click="editDialog = false" text color="accent">
              Cancel
            </v-btn>
            <v-btn
              class="ma-3"
              @click="saveNotificationEdit()"
              text
              color="success"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteNotificationDialog" persistent max-width="600px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>
              Are you sure you want to delete this notification?
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="deleteNotificationDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="mt-3">
            The selected notification will be deleted and no email notification
            will be sent.
            <v-card-actions>
              <v-spacer />
              <v-btn @click="deleteSelectedNotification()" text color="Error">
                Delete
              </v-btn>

              <v-btn
                @click="deleteNotificationDialog = false"
                text
                color="primary"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import TimeSelector from "../components/TimeSelector.vue";
// import NewNotification from "../components/NewNotification.vue";

export default {
  components: {
    TimeSelector,
    //   NewNotification
  },
  props: ["npi", "isCredModal"],
  data() {
    return {
      load: false,
      headers: [],
      items: [],
      search: "",
      sentData: [],
      scheduledData: [],
      scheduled: true,
      editDialog: false,
      notificationToEdit: "",
      editDate: "",
      editTime: "",
      editMessage: "",
      editMessageText: "",
      messageList: ["New Assignment", "Upcoming Expiration"],
      deleteNotificationDialog: false,
      notificationToDelete: "",
      notificationSuccessMessage: false,
      fmoAgentIds: []
    };
  },
  computed: {
    getHeaders() {
      if (this.scheduled) {
        return [
          { text: "Sent", value: "sent" },
          { text: "Created", value: "createdAt" },
          { text: "Type", value: "type" },
          { text: "Message", value: "message" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Delivery Time", value: "deliveryTime" },
          { text: "Assignee", value: "assignee.fullName" },
          { text: "Actions", value: "ACTIONS" },
        ];
      } else {
        return [
          { text: "Sent", value: "sent" },
          { text: "Created", value: "createdAt" },
          { text: "Type", value: "type" },
          { text: "Message", value: "message" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Delivery Time", value: "deliveryTime" },
          { text: "Assignee", value: "assignee.fullName" },
        ];
      }
    },
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          this.getReportData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getReportData() {
      let userRole = this.user.role;
      let isCredModal = this.isCredModal;

      let requestUrl = '';

      if(isCredModal === true){
        requestUrl = `/api/notifications/cred/${userRole}/${this.user.userID}/${this.npi}`;
      }
      else if(userRole === 'fmo') {
        this.fmoAgentIds = []
        // get notifications from agents inside fmo
        await axios.get(`api/fmo/agents/${this.user.fmoNpn}`).then(res => {
          this.fmoAgentIds = res.data;
        })
      }
      else {
        requestUrl = `/api/notifications/all/${userRole}/${this.user.userID}`;
      }
      if(!this.user.role === 'fmo') {
        axios
        .get(requestUrl)
        .then((response) => {
          this.scheduledData = [];
          this.sentData = [];

          response.data.forEach((notification) => {
            if (notification.sent === true) {
              this.sentData.push(notification);
            } else {
              this.scheduledData.push(notification);
            }
          });
        });
      } else {
          this.scheduledData = [];
          this.sentData = [];

          this.fmoAgentIds.forEach(agentID => {
            axios.get(`/api/notifications/all/agent/${agentID}`).then((response) => {
              response.data.forEach((notification) => {
                if (notification.sent === true) {
                  this.sentData.push(notification);
                } else {
                  this.scheduledData.push(notification);
                }
              });
            })
          })
        // get notification collection
      }
    },
    openEditDialog(item) {
      this.editDialog = true;
      this.notificationToEdit = item.notificationReference;
      this.editDate = item.deliveryDate;
      this.editTime = item.deliveryTime;
      this.editMessage = item.message;
    },
    saveNotificationEdit() {
      let notificationEdits = {
        notificationReference: this.notificationToEdit,
        deliveryDate: this.editDate,
        deliveryTime: this.editTime,
        message: this.editMessage,
      };

      axios.post(`/api/notifications/update`, notificationEdits).then(() => {
        this.editDialog = false;
        this.getReportData();
      });
    },
    deleteNotification(notification) {
      this.notificationToDelete = notification;
      this.deleteNotificationDialog = true;
    },
    deleteSelectedNotification() {
      axios
        .post(
          `/api/notifications/delete/${this.notificationToDelete.notificationReference}`
        )
        .then(() => {
          if (this.scheduled) {
            let deletedItem = this.scheduledData.indexOf(
              this.notificationToDelete
            );

            this.scheduledData.splice(deletedItem, 1);
            this.$nextTick(() => {
              this.deletedItem = -1;
            });
          } else {
            let deletedItem = this.sentData.indexOf(this.notificationToDelete);

            this.sentData.splice(deletedItem, 1);
            this.$nextTick(() => {
              this.deletedItem = -1;
            });
          }
          this.deleteNotificationDialog = false;
          this.notificationSuccessMessage = true;
        });
    },
    notificationSuccess(item) {
      this.scheduledData.push(item);
      this.notificationSuccessMessage = true;
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
</style>