<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card flat>
                    <v-card-title class="mb-3">Plan Settings</v-card-title>
                    <v-card-text>
                        <add-plan @planAdded="planAdded"></add-plan>
                        <v-data-table 
                            class="ma-3"
                            :loading="load"
                            :headers="headers"
                            :items="planData"
                            :items-per-page="25"
                            multi-sort
                            loading-text="Loading data..."
                        >
                            <template v-slot:item.action="{ item }">
                                <edit-plan :plan="item"></edit-plan>
                                <v-chip @click="deletePlanDialog(item)"><v-icon>mdi-delete</v-icon></v-chip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="deleteDialog" max-width="900px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title> Remove Plan </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="deleteDialog = false">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">Are you sure you want to delete {{planToBeDeleted.planName}}?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteDialog = false" text>Cancel</v-btn>
                    <v-btn
                        color="accent"
                        text
                        @click="deletePlan(planToBeDeleted)"
                    >
                        Delete Plan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AddPlan from '../components/claim-processing-components/AddPlan.vue';
import EditPlan from '../components/claim-processing-components/EditPlan.vue';
import axios from "axios";
export default {
    components: {
        AddPlan,
        EditPlan
    },
    data() {
        return {
            planData: [],
            load: false,
            headers: [
                { text: "Plan Name", value: "planName"},
                { text: "Choice Of Providers", value: "choiceOfProviders"},
                { text: "Deductible", value: "deductible"},
                { text: "ER Copay", value: "emergencyRoomCopay"},
                { text: "Max Out Of Pocket", value: "maxOutOfPocket"},
                { text: "Actions", value: "action"}
            ],
            deleteDialog: false,
            planToBeDeleted: {},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            axios.get('api/plans/allPlans')
                .then((response) => {
                    this.planData = response.data
                })
        },
        planAdded() {
            this.getData()
        },
        deletePlan(item) {
            console.log(item)
            axios.post('api/plans/delete', item)
                .then(() => {
                    this.deleteDialog = false
                    this.getData()
                })
        },
        deletePlanDialog(item) {
            this.deleteDialog = true
            this.planToBeDeleted = item
        }
    }
}
</script>

<style scoped>

</style>