<template>
  <div>
    <v-form ref="form" v-model="valid">
      <!-- actions and navigation -->
      <v-card
        id="location-nav"
        class="transparent-card my-3 mx-1 rounded-t-0"
        outlined
      >
        <v-row>
          <v-col cols="12" sm="7">
            <v-dialog
              v-model="deleteLocationDialog"
              persistent
              max-width="450px"
              v-if="!limitedAccess"
            >
              <v-card tile>
                <v-card-title class="text-5 mb-2"> Are you sure? </v-card-title>

                <v-card-subtitle>
                  Deleting a location is a permenant action. Please confirm your
                  decision to delete this location.
                </v-card-subtitle>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="deleteLocationDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn color="error" text @click="deleteLocation()">
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="5" v-if="!isFacility">
            <div
              :class="$vuetify.breakpoint.smAndUp ? 'float-right mr-2' : 'mx-2'"
            >
              <v-btn
                @click="addLocation"
                class="ml-4"
                color="primary"
                icon
                x-large
              >
                <v-icon elevation="2">mdi-plus-circle</v-icon>
              </v-btn>
              <div class="d-inline overline">
                Location {{ selectedLocation + 1 }} of
                {{ credData.Locations.length }}
              </div>

              <v-btn
                icon
                large
                color="primary"
                class="mx-2"
                :disabled="selectedLocation == 0"
                @click="prevLoc()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

              <v-btn
                icon
                large
                color="primary"
                :disabled="selectedLocation == credData.Locations.length - 1"
                @click="nextLoc()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- Network Participation -->
      <v-card class="transparent-card pa-2 ma-3" height="100%" outlined>
        <v-card-title class="text-h4" style="word-break: break-word">
          Network Participation
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                v-model="
                  credData.Locations[selectedLocation].MedicalGroupAffiliation
                "
                :items="medicalAffiliates"
                @change="setMedGroup"
                outlined
                dense
                label="Medical Group Affiliation"
                clearable
                class="text-subtitle-1"
                :readonly="limitedAccess"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                label="Status"
                v-model="credData.Locations[selectedLocation].Status"
                :items="locationStatuses"
                dense
                outlined
                :readonly="limitedAccess"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                credData.Locations[selectedLocation].Status === 'Termed' ||
                  credData.Locations[selectedLocation].TermedAsOfDate
              "
            >
              <v-menu
                ref="termedDatePicker"
                v-model="termedDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Termed As Of"
                    append-icon="mdi-calendar"
                    value="hi"
                    v-model="
                      credData.Locations[selectedLocation].TermedAsOfDate
                    "
                    hint="Please note: Setting a future date will retain previous status until that date"
                    persistent-hint
                    clearable
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="credData.Locations[selectedLocation].TermedAsOfDate"
                  :disabled="limitedAccess"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="termedDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.termedDatePicker.save(
                        credData.Locations[selectedLocation].TermedAsOfDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                label="Accepting Patients"
                v-model="
                  credData.Locations[selectedLocation].AcceptingNewPatients
                "
                :items="['New', 'Family of Patient', 'Current Patients', 'No']"
                required
                clearable
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="clinicOrigDatePicker"
                v-model="clinicOrigDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Load Date"
                    append-icon="mdi-calendar"
                    v-model="credData.Locations[selectedLocation].LoadDate"
                    clearable
                    :disabled="affiliated"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="credData.Locations[selectedLocation].LoadDate"
                  :disabled="limitedAccess"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="clinicOrigDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.clinicOrigDatePicker.save(
                        credData.Locations[selectedLocation]
                          .ClinicOriginalCredDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="clinicRecredDatePicker"
                v-model="clinicRecredDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
                :readonly="limitedAccess"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Recred Date"
                    append-icon="mdi-calendar"
                    v-model="
                      credData.Locations[selectedLocation].ClinicReCredDate
                    "
                    clearable
                    :disabled="affiliated"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="
                    credData.Locations[selectedLocation].ClinicReCredDate
                  "
                  :disabled="limitedAccess"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="clinicRecredDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.clinicRecredDatePicker.save(
                        credData.Locations[selectedLocation].ClinicReCredDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="sonderCredDatePicker"
                v-model="sonderCredDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
                :readonly="limitedAccess"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Sonder Cred Date"
                    append-icon="mdi-calendar"
                    v-model="
                      credData.Locations[selectedLocation].SonderCredDate
                    "
                    required
                    :disabled="affiliated"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="credData.Locations[selectedLocation].SonderCredDate"
                  required
                  :disabled="limitedAccess"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="sonderCredDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.sonderCredDatePicker.save(
                        credData.Locations[selectedLocation].SonderCredDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" md="3">
              <v-checkbox
                label="Hospital Based"
                v-model="credData.Locations[selectedLocation].HospitalBased"
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              />
            </v-col>
            <v-col cols="4" md="3">
              <v-checkbox
                label="Uses CMSMA"
                v-model="credData.Locations[selectedLocation].UsesCMSMA"
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              />
            </v-col>
            <v-col cols="4" md="3">
              <v-checkbox
                label="Include In Directory"
                v-model="credData.Locations[selectedLocation].IncludeInDirectory"
                outlined
                :readonly="limitedAccess"
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Service Location Info -->
      <v-card class="transparent-card pa-2 ma-3" height="100%" outlined>
        <v-card-title class="text-h4" style="word-break: break-word">
          Service Location Info
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                label="Service Location Name"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationName
                "
                required
                :readonly="limitedAccess"
                :rules="[
                  credData.Locations[selectedLocation].ServiceLocationName !=
                    '' || 'Please make sure this field is not empty',
                ]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="!isFacility" cols="12" md="3">
              <v-checkbox
                label="Default Address"
                v-model="credData.Locations[selectedLocation].DefaultAddress"
                required
                outlined
                :readonly="limitedAccess"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Service Location Address 1"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationAddress1
                "
                required
                :rules="[
                  credData.Locations[selectedLocation]
                    .ServiceLocationAddress1 != '' ||
                    'Please make sure this field is not empty',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Service Location Address 2"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationAddress2
                "
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="City"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationCity
                "
                required
                :rules="[
                  credData.Locations[selectedLocation].ServiceLocationCity !=
                    '' || 'Please make sure this field is not empty',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="State"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationState
                "
                required
                :rules="[
                  credData.Locations[selectedLocation].ServiceLocationState !=
                    '' || 'Please make sure this field is not empty',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Zip"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationZip
                "
                required
                :rules="[
                  credData.Locations[selectedLocation].ServiceLocationZip !=
                    '' || 'Please make sure this field is not empty',
                  /^[^a-zA-Z]+$/.test(
                    credData.Locations[selectedLocation].ServiceLocationZip
                  ) || 'This field cannot contain letters',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="County"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationCounty
                "
                required
                :rules="[
                  credData.Locations[selectedLocation].ServiceLocationCounty !=
                    '' || 'Please make sure this field is not empty',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Phone"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationPhone
                "
                @input="formatServicePhone"
                required
                :rules="[
                  credData.Locations[selectedLocation].ServiceLocationPhone !=
                    '' || 'This field cannot be empty or contain letters',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Fax"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationFax
                "
                @input="formatServiceFax"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Email"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationEmail
                "
                required
                :rules="[
                  credData.Locations[selectedLocation].ServiceLocationEmail !=
                    '' || 'Please enter a valid email',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Tax ID"
                v-model="
                  credData.Locations[selectedLocation].ServiceLocationTaxId
                "
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Claims - Formerly Fee Schedule Info -->
      <v-card outlined class="transparent-card pa-2 mx-3" height="100%">
        <v-card-title class="text-h4" style="word-break: break-word"
          >Claims</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="credData.Locations[selectedLocation].FeeScheduleType"
                :disabled="affiliated"
                label="Fee Schedule Type"
                :items="['Fee by Service', 'Capitation']"
                :readonly="
                  !!credData.Locations[selectedLocation].MedicalGroupAffiliation
                "
                required
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="feeScheduleEffectiveDatePicker"
                v-model="feeScheduleEffectiveDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                readonly
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="
                      credData.Locations[selectedLocation]
                        .ProviderEffectiveDatewithFeeSchedule
                    "
                    :disabled="affiliated"
                    :label="
                      credData.Locations[selectedLocation].FeeScheduleType ===
                      'Fee by Service'
                        ? 'Fee Schedule Effective Date'
                        : 'Capitation Effective Date'
                    "
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="
                    credData.Locations[selectedLocation]
                      .ProviderEffectiveDatewithFeeSchedule
                  "
                  :disabled="limitedAccess === true"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="feeScheduleEffectiveDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.feeScheduleEffectiveDatePicker.save(
                        credData.ProviderEffectiveDatewithFeeSchedule
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="feeScheduleTerminationDatePicker"
                v-model="feeScheduleTerminationDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                readonly
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="
                      credData.Locations[selectedLocation]
                        .ProviderTerminationDatewithFeeSchedule
                    "
                    :disabled="affiliated"
                    :label="
                      credData.Locations[selectedLocation].FeeScheduleType ===
                      'Fee by Service'
                        ? 'Fee Schedule Termination Date'
                        : 'Capitation Termination Date'
                    "
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="
                    credData.Locations[selectedLocation]
                      .ProviderTerminationDatewithFeeSchedule
                  "
                  :disabled="limitedAccess === true"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="feeScheduleTerminationDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.feeScheduleTerminationDatePicker.save(
                        credData.ProviderTerminationDatewithFeeSchedule
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <template
              v-if="
                credData.Locations[selectedLocation].FeeScheduleType ===
                  'Fee by Service'
              "
            >
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="
                    credData.Locations[selectedLocation].FeeSchedulePercent
                  "
                  :disabled="affiliated"
                  suffix="%"
                  label="Fee Schedule Percent"
                  min="0"
                  max="999"
                  :rules="[
                    (v) => v >= 0 || 'Must be positive percent',
                    (v) => (v && v < 1000) || 'Percent must be 3 digits',
                  ]"
                  outlined
                  dense
                  class="text-subtitle-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="
                    credData.Locations[selectedLocation]
                      .maxDaysToSubmitInpatientClaim
                  "
                  :disabled="affiliated"
                  type="number"
                  label="Max Days To Submit Inpatient Claim"
                  min="0"
                  max="999"
                  :rules="[(v) => v >= 0 || 'Must be positive']"
                  outlined
                  dense
                  class="text-subtitle-1"
                  :readonly="limitedAccess === true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="
                    credData.Locations[selectedLocation]
                      .maxDaysToSubmitOutpatientClaim
                  "
                  :disabled="affiliated"
                  type="number"
                  label="Max Days To Submit Outpatient Claim"
                  min="0"
                  max="999"
                  :rules="[(v) => v >= 0 || 'Must be positive']"
                  outlined
                  dense
                  class="text-subtitle-1"
                  :readonly="limitedAccess === true"
                ></v-text-field>
              </v-col>
            </template>
            <template
              v-if="
                credData.Locations[selectedLocation].FeeScheduleType ===
                  'Capitation'
              "
            >
              <v-col cols="12" md="3">
                <v-text-field
                  label="Medicare Members"
                  v-model="credData.Locations[selectedLocation].MedicareCapitation"
                  type="number"
                  placeholder="0.00"
                  persistent-placeholder 
                  prefix="$"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Medicaid Members"
                  v-model="credData.Locations[selectedLocation].MedicaidCapitation"
                  type="number"
                  placeholder="0.00"
                  persistent-placeholder 
                  prefix="$"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="
                    credData.Locations[selectedLocation]
                      .maxDaysToSubmitInpatientClaim
                  "
                  :disabled="affiliated"
                  type="number"
                  label="Max Days To Submit Inpatient Claim"
                  min="0"
                  max="999"
                  :rules="[(v) => v >= 0 || 'Must be positive']"
                  outlined
                  dense
                  class="text-subtitle-1"
                  :readonly="limitedAccess === true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="
                    credData.Locations[selectedLocation]
                      .maxDaysToSubmitOutpatientClaim
                  "
                  :disabled="affiliated"
                  type="number"
                  label="Max Days To Submit Outpatient Claim"
                  min="0"
                  max="999"
                  :rules="[(v) => v >= 0 || 'Must be positive']"
                  outlined
                  dense
                  class="text-subtitle-1"
                  :readonly="limitedAccess === true"
                ></v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Billing -->
      <v-card
        outlined
        class="transparent-card pa-2 ma-3"
        height="100%"
        v-if="!limitedAccess"
      >
        <v-card-title class="text-h4" style="word-break: break-word">
          Billing
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-switch
                v-model="useHsd"
                label="Use USD Instead of Specialty Code"
                color="primary"
                flat
                inset
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                label="Medicare Specialty Code"
                v-model="
                  credData.Locations[selectedLocation].MedicareSpecialtyCode
                "
                v-if="!useHsd"
                :items="specialtyCodes"
                :rules="[
                  credData.Locations[selectedLocation].MedicareSpecialtyCode !=
                    '' || 'Please make sure this field is not empty',
                ]"
                outlined
                :readonly="limitedAccess"
                @change="updateSpecialty"
                dense
              />
              <v-autocomplete
                label="HSD Code"
                v-model="credData.Locations[selectedLocation].hsdCode"
                :items="hsdCodes"
                @change="hsdSpecialty"
                v-else
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Primary Specialty"
                v-model="credData.Locations[selectedLocation].PrimarySpecialty"
                disabled
                required
                :rules="[
                  credData.Locations[selectedLocation].PrimarySpecialty != '' ||
                    'Please make sure this field is not empty',
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Billing Provider Name"
                v-model="
                  credData.Locations[selectedLocation].BillingProviderName
                "
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Billing Provider Name as Appears on Check"
                v-model="
                  credData.Locations[selectedLocation]
                    .BillingProviderNameasAppearsonCheck
                "
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Billing Provider Tax ID"
                v-model="
                  credData.Locations[selectedLocation].BillingProviderTaxId
                "
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Billing Provider NPI"
                v-model="
                  credData.Locations[selectedLocation].BillingProviderNPI
                "
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                label="Billing Provider Address Type"
                v-model="
                  credData.Locations[selectedLocation]
                    .BillingProviderAddressType
                "
                :disabled="affiliated"
                :items="['Primary', 'Secondary']"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Remit to Address 1"
                v-model="credData.Locations[selectedLocation].RemittoAddress1"
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Remit to Address 2"
                v-model="credData.Locations[selectedLocation].RemittoAddress2"
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Remit to City"
                v-model="credData.Locations[selectedLocation].RemittoCity"
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Remit to State"
                v-model="credData.Locations[selectedLocation].RemittoState"
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Remit to ZIP"
                v-model="credData.Locations[selectedLocation].RemittoZip"
                :disabled="affiliated"
                :rules="[
                  (v) =>
                    v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                      ? 'This field cannot contain letters'
                      : true,
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Billing County"
                v-model="credData.Locations[selectedLocation].BillingCounty"
                :disabled="affiliated"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Billing Phone"
                v-model="credData.Locations[selectedLocation].BillingPhone"
                :disabled="affiliated"
                @input="formatBillPhone"
                :rules="[
                  (v) =>
                    v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                      ? 'This field cannot contain letters'
                      : true,
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Billing Fax"
                v-model="credData.Locations[selectedLocation].Billingfax"
                :disabled="affiliated"
                @input="formatBillFax"
                :rules="[
                  (v) =>
                    v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                      ? 'This field cannot contain letters'
                      : true,
                ]"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Other Info -->
      <v-card
        class="transparent-card pa-2 ma-3"
        height="100%"
        outlined
        v-if="!limitedAccess"
      >
        <v-card-title class="text-h4" style="word-break">
          Other Info
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Contact Phone"
                v-model="credData.Locations[selectedLocation].contact_phone"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Contact Email"
                v-model="credData.Locations[selectedLocation].contact_email"
                outlined
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="riskManagementEffDatePicker"
                v-model="riskManagementEffDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                readonly
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="
                      credData.Locations[selectedLocation].riskManagementEffDate
                    "
                    label="Risk Management Effective Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="
                    credData.Locations[selectedLocation].riskManagementEffDate
                  "
                  :disabled="limitedAccess === true"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="riskManagementEffDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.riskManagementEffDatePicker.save(
                        credData.Locations[selectedLocation]
                          .riskManagementEffDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="riskManagementExpDatePicker"
                v-model="riskManagementExpDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                readonly
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="
                      credData.Locations[selectedLocation].riskManagementExpDate
                    "
                    label="Risk Management Expiration Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="
                    credData.Locations[selectedLocation].riskManagementExpDate
                  "
                  :disabled="limitedAccess === true"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="riskManagementExpDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.riskManagementExpDatePicker.save(
                        credData.Locations[selectedLocation]
                          .riskManagementExpDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Taxonomy Code"
                v-model="credData.Locations[selectedLocation].TaxonomyCode"
                outlined
                :rules="[
                  credData.Locations[selectedLocation].TaxonomyCode != '' ||
                    'Please make sure this field is not empty',
                ]"
                required
                :readonly="limitedAccess"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Office Hours Info -->
      <v-card
        class="transparent-card pa-2 ma-3"
        height="100%"
        outlined
        v-if="!limitedAccess"
      >
        <v-card-title class="text-h4" style="word-break: break-word">
          Office Hours
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Sunday Open'"
                v-model="credData.Locations[selectedLocation].SundayOpen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Sunday Close'"
                v-model="credData.Locations[selectedLocation].SundayClose"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Monday Open'"
                v-model="credData.Locations[selectedLocation].MondayOpen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Monday Close'"
                v-model="credData.Locations[selectedLocation].MondayClose"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Tuesday Open'"
                v-model="credData.Locations[selectedLocation].TuesdayOpen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Tuesday Close'"
                v-model="credData.Locations[selectedLocation].TuesdayClose"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Wednesday Open'"
                v-model="credData.Locations[selectedLocation].WednesdayOpen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Wednesday Close'"
                v-model="credData.Locations[selectedLocation].WednesdayClose"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Thursday Open'"
                v-model="credData.Locations[selectedLocation].ThursdayOpen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Thursday Close'"
                v-model="credData.Locations[selectedLocation].ThursdayClose"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Friday Open'"
                v-model="credData.Locations[selectedLocation].FridayOpen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Friday Close'"
                v-model="credData.Locations[selectedLocation].FridayClose"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Saturday Open'"
                v-model="credData.Locations[selectedLocation].SaturdayOpen"
              />
            </v-col>
            <v-col cols="12" md="6">
              <Time-Selector
                :label="'Saturday Close'"
                v-model="credData.Locations[selectedLocation].SaturdayClose"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- Auto Note dialog -->
    <v-dialog
      v-model="newNoteDialog"
      persistent
      max-width="450px"
      v-if="!limitedAccess"
    >
      <v-card>
        <v-toolbar dark flat :color="noteHeaderColor">
          <v-card-title color="primary"
            >{{ noteDialogFlavor }} this provider?</v-card-title
          >
        </v-toolbar>
        <v-form lazy-validation>
          <!-- <v-text-field 
              v-model="newNoteTitle"
              label="Title"
              class="px-2"
              :counter="15"
              :rules="[
                v => v.length < 15 || 'Max 15 characters'
              ]"
              hide-details
              outlined
              dense
            /> -->
          <v-textarea
            v-model="newNoteMessage"
            class="mt-2 px-2"
            placeholder="Please enter your note here..."
            :counter="600"
            :rules="[
              newNoteMessage != '' || 'Note contents must not be empty',
              (v) => v.length <= 600 || 'Max 600 characters',
            ]"
            required
            outlined
          />
        </v-form>
        <v-card-actions>
          <v-btn color="accent" text @click="closeNewNote()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="addNewNote()"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Auto Note dialog -->

    <v-snackbar v-model="newLocationSaved" :timeout="4000" color="success">
      New location successfully saved.
    </v-snackbar>

    <v-snackbar v-model="locationDeleted" :timeout="4000" color="success">
      Location succesfully deleted
    </v-snackbar>

    <v-snackbar v-model="locError" :timeout="3000" color="error">
      There is an uncompleted field for this location, please complete the field
      below
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="locError = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios"

import TimeSelector from "../TimeSelector.vue"
export default {
  name: "LocationsTab",
  props: ["credData", "isFacility", "startValidation", "user", "limitedAccess"],
  components: {
    // NewLocation,
    TimeSelector,
  },
  data() {
    return {
      valid: false,
      selectedLocation: 0,
      useHsd: false,
      specialtyCodes: [],
      termedDatePicker: false,
      locationCategories: [
        "Delegated (Employed)",
        "Delegated (Non-Employed)",
        "Non-Delegated (Facility)",
        "Non-Delegated (Ancillary)",
        "Non-Delegated (Group)",
        "Non-Delegated (Individual Provider)",
      ],
      locationStatuses: [
        "Active",
        "Pending/ In-Process",
        "Ready For Review",
        "Ready ForCred Committee",
        "Pending CVO Check List",
        "Pending Updated Malpractice Insurance",
        "Inactive",
        "Termed",
        "Withdrawn",
        "Expired CAQH Attestation",
        "Listed on SAM GSA Report",
      ],
      primarySpecialty: [
        "Adult Intensivist",
        "Allergy",
        "Anesthesia",
        "Bariatric Medicine/Surgery",
        "Burn/Trauma",
        "Cardiac Catheterization",
        "Cardiology",
        "Cardiovascular Surgery",
        "Colorectal Surgery",
        "Dermatology",
        "Electrophysiology",
        "Emergency Medicine",
        "Endocrinology",
        "Family Practice",
        "Gastroenterology",
        "General Surgery",
        "Geriatrics",
        "Gynecologic Oncology",
        "Hematology/Oncology",
        "Hepatobiliary",
        "Hospitalist",
        "Infectious Disease",
        "Internal Medicine",
        "Interventional Radiology",
        "Medical Genetics",
        "Neonatology",
        "Nephrology",
        "Neuroradiology",
        "Neurology",
        "Neurosurgery",
        "Nuclear Medicine",
        "Obstetrics & Gynecology",
        "Occupational Medicine",
        "Ophthalmology",
        "Oral Surgery",
        "Orthopedics",
        "Otolaryngology / Head & Neck Surgery",
        "Pain Management",
        "Palliative Care",
        "Pain Management",
        "Palliative Care",
        "Pathology: Surgical & Anatomic",
        "Pediatric Intensivist",
        "Pediatrics",
        "Pediatric Surgery",
        "Physical Medicine",
        "Plastic & Reconstructive Surgery",
        "Podiatric Surgery",
        "Psychiatry",
        "Pulmonary Medicine",
        "Radiation Oncology",
        "Radiology",
        "Rheumatology",
        "Surgical Oncology",
        "Thoracic Surgery",
        "Transplant Surgery",
        "Urology",
        "Vascular Surgery",
        "Wound Care",
        "Bariatric Surgery",
        "ENT",
        "General Surgery",
        "Gastroenterology",
        "Neurosurgery",
        "Obstetrics & Gynecology",
        "Ophthalmology",
        "Oral/Dental Surgery",
        "Orthopedic Surgery",
        "Pain Management",
        "Pediatric Surgery",
        "Plastic Surgery",
        "Podiatric Surgery",
        "Urology",
        "Vascular Surgery",
      ],
      secondarySpecialty: [
        "Adult Intensivist",
        "Allergy",
        "Anesthesia",
        "Bariatric Medicine/Surgery",
        "Burn/Trauma",
        "Cardiac Catheterization",
        "Cardiology",
        "Cardiovascular Surgery",
        "Colorectal Surgery",
        "Dermatology",
        "Electrophysiology",
        "Emergency Medicine",
        "Endocrinology",
        "Family Practice",
        "Gastroenterology",
        "General Surgery",
        "Geriatrics",
        "Gynecologic Oncology",
        "Hematology/Oncology",
        "Hepatobiliary",
        "Hospitalist",
        "Infectious Disease",
        "Internal Medicine",
        "Interventional Radiology",
        "Medical Genetics",
        "Neonatology",
        "Nephrology",
        "Neuroradiology",
        "Neurology",
        "Neurosurgery",
        "Nuclear Medicine",
        "Obstetrics & Gynecology",
        "Occupational Medicine",
        "Ophthalmology",
        "Oral Surgery",
        "Orthopedics",
        "Otolaryngology / Head & Neck Surgery",
        "Pain Management",
        "Palliative Care",
        "Pain Management",
        "Palliative Care",
        "Pathology: Surgical & Anatomic",
        "Pediatric Intensivist",
        "Pediatrics",
        "Pediatric Surgery",
        "Physical Medicine",
        "Plastic & Reconstructive Surgery",
        "Podiatric Surgery",
        "Psychiatry",
        "Pulmonary Medicine",
        "Radiation Oncology",
        "Radiology",
        "Rheumatology",
        "Surgical Oncology",
        "Thoracic Surgery",
        "Transplant Surgery",
        "Urology",
        "Vascular Surgery",
        "Wound Care",
        "Bariatric Surgery",
        "ENT",
        "General Surgery",
        "Gastroenterology",
        "Neurosurgery",
        "Obstetrics & Gynecology",
        "Ophthalmology",
        "Oral/Dental Surgery",
        "Orthopedic Surgery",
        "Pain Management",
        "Pediatric Surgery",
        "Plastic Surgery",
        "Podiatric Surgery",
        "Urology",
        "Vascular Surgery",
      ],
      hsdCodes: [
        "001 - General Practice",
        "002 - Family Practice",
        "003 - Internal Medicine",
        "004 - Geriatrics",
        "005 - Primary Care - Physician Assistants",
        "006 - Primary Care - Nurse Practitioners",
        "007 - Allergy and Immunology",
        "008 - Cardiology",
        "010 - Chiropractor",
        "011 - Dermatology",
        "012 - Endocrinology",
        "013 - ENT/Otolaryngology",
        "014 - Gastroenterology",
        "015 - General Surgery",
        "016 - Gynecology, OB/GYN",
        "017 - Infectious Diseases",
        "018 - Nephrology",
        "019 - Neurology",
        "020 - Neurosurgery",
        "021 - Oncology - Medical, Surgical",
        "022 - Oncology - Radiation/Radiation Oncology",
        "023 - Ophthalmology",
        "025 - Orthopedic Surgery",
        "026 - Physiatry, Rehabilitative Medicine",
        "027 - Plastic Surgery",
        "028 - Podiatry",
        "029 - Psychiatry",
        "030 - Pulmonology",
        "031 - Rheumatology",
        "033 - Urology",
        "034 - Vascular Surgery",
        "035 - Cardiothoracic Surgery",
        "040 - Acute Inpatient Hospitals",
        "041 - Cardiac Surgery Program",
        "042 - Cardiac Catheterization Services",
        "043 - Critical Care Services - Intensive Care Units (ICU)",
        "044 - Outpatient Dialysis",
        "045 - Surgical Services (Outpatient or ASC)",
        "046 - Skilled Nursing Facilities",
        "047 - Diagnostic Radiology",
        "048 - Mammography",
        "049 - Physical Therapy",
        "050 - Occupational Therapy",
        "051 - Speech Therapy",
        "052 - Inpatient Psychiatric Facility Services",
        "057 - Outpatient Infusion/Chemotherapy",
      ],
      medicalAffiliates: [],
      clinicInitDatePicker: false,
      clinicOrigDatePicker: false,
      clinicRecredDatePicker: false,
      sonderCredDatePicker: false,
      feeScheduleEffectiveDatePicker: false,
      feeScheduleTerminationDatePicker: false,
      riskManagementEffDatePicker: false,
      riskManagementExpDatePicker: false,
      MocAttestationDatePicker: false,

      newLocationSaved: false,
      deleteLocationDialog: false,
      locationDeleted: false,
      locError: false,
      newStatus: "",
      oldStatus: "",
      newStatusObj: {},
      hasAddedNote: false,
      newNoteDialog: false,
      newNoteMessage: "",
      noteDialogFlavor: "",
      originalStatus: "",
      incomingNoteType: "",
    }
  },
  mounted() {
    this.getMedicalAffiliates()
    this.getSpecialtyCodes()
    this.oldStatus = this.credData.Locations[this.selectedLocation].Status
    this.codeChoiceSetup()
    this.formatDates()
  },
  methods: {
    formatDates() {
      if(this.credData.Locations[this.selectedLocation].TermedAsOfDate) {
        this.credData.Locations[this.selectedLocation].TermedAsOfDate = this.credData.Locations[this.selectedLocation].TermedAsOfDate.toString().slice(0,10)
      }
    },
    hsdSpecialty(hsdCode) {
      this.credData.Locations[this.selectedLocation].PrimarySpecialty = hsdCode.slice(5)
      if(this.credData.Locations[this.selectedLocation].MedicareSpecialtyCode) {
        this.credData.Locations[this.selectedLocation].MedicareSpecialtyCode = ""
      }
    },
    codeChoiceSetup () {
      if(this.credData.Locations[this.selectedLocation].hsdCode) {
        this.useHsd = true
      }
      else this.useHsd = false
    },
    async getSpecialtyCodes() {
      await axios
        .get("/api/modals/credentialing/specialtyCodes")
        .then((res) => {
          this.specialtyCodes = res.data
          this.specialtyCodes = this.specialtyCodes.map((index) => {
            return index.specialtyCodes
          })
        })
    },
    async updateSpecialty() {
      // TODO: Update schema to remove rest of fields with conflicting symbols () etc
      const MedicareCode = this.credData.Locations[this.selectedLocation]
        .MedicareSpecialtyCode
      await axios
        .get(`/api/modals/credentialing/primarySpecialties/${MedicareCode}`)
        .then((res) => {
          this.credData.Locations[this.selectedLocation].PrimarySpecialty =
            res.data[0].primarySpecialty
          if(this.credData.Locations[this.selectedLocation].hsdCode) {
            this.credData.Locations[this.selectedLocation].hsdCode = ""
          }
        })
    },
    nextLoc() {
      // if (this.$refs.form.validate()) {
      //   this.oldStatus = this.credData.locations[this.selectedLocation][
      //     "Status(DD)"
      //   ]
      //   this.selectedLocation++
      // } else {
      //   this.locError = true
      // }
      this.selectedLocation++
      // these functions dont need to exist i dont think - nextloc & prevLoc
    },
    prevLoc() {
      // if (this.$refs.form.validate()) {
      //   this.oldStatus = this.credData.locations[this.selectedLocation][
      //     "Status(DD)"
      //   ]
      //   this.selectedLocation--
      // } else {
      //   this.locError = true
      // }
      this.selectedLocation--
    },
    updateLocs(newLoc) {
      // receive updated loc info and pass to parent to add to credData
      // alert user that save was successful
      this.selectedLocation = this.credData.Locations.length
      this.newLocationSaved = true
      this.$emit("setUpdatedLocs", newLoc)
    },
    async deleteLocation() {
      axios
        .post("/api/modals/credentialing/delete", {
          mode: this.isFacility ? "facility" : "provider",
          NPI: this.isFacility
            ? this.credData.FacilityNPI
            : this.credData.RenderingNPI,
          location: this.credData.Locations[this.selectedLocation],
        })
        .then(() => {
          this.$emit(
            "deletedLocation",
            this.credData.locations[this.selectedLocation]
          )
          this.credData.Locations.splice(this.selectedLocation, 1)
          this.selectedLocation =
            this.selectedLocation < this.credData.Locations.length
              ? this.selectedLocation
              : this.selectedLocation - 1

          this.deleteLocationDialog = false
          this.locationDeleted = true
        })
        .catch(() => {
          console.log("Error deleting location.")
        })
    },
    async getMedicalAffiliates() {
      await axios.get(`/api/affiliates/all/${true}`).then((res) => {
        this.medicalAffiliates = res.data
      })
    },
    formatServicePhone() {
      let x = this.credData.Locations[
        this.selectedLocation
      ].ServiceLocationPhone.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      )
      this.credData.Locations[
        this.selectedLocation
      ].ServiceLocationPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    formatServiceFax() {
      let x = this.credData.Locations[
        this.selectedLocation
      ].ServiceLocationFax.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      )
      this.credData.Locations[this.selectedLocation].ServiceLocationFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    formatBillPhone() {
      let x = this.credData.Locations[
        this.selectedLocation
      ].BillingPhone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.credData.Locations[this.selectedLocation].BillingPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    formatBillFax() {
      let x = this.credData.Locations[this.selectedLocation].Billingfax.replace(
        /\D/g,
        ""
      ).match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.credData.Locations[this.selectedLocation].Billingfax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    addNewNote() {
      // build the note for the emit
      const formattedType =
        this.incomingNoteType == "terminationNotes"
          ? "Termination Notes"
          : "Withdraw Notes"
      const note = {
        message: this.newNoteMessage,
        type: this.incomingNoteType,
        formattedType,
      }
      this.$emit("newNote", note)
      this.hasAddedNote = true
      this.newNoteDialog = false
    },

    // When the MedGroup is set almost all Location Fields are filled and locked. computed property affiliated returns true
    async setMedGroup() {
      if (this.credData.Locations[this.selectedLocation].MedicalGroupAffiliation) {
        let medAffiliate

        await axios
          .get(
            `/api/affiliates/get/${
              this.credData.Locations[this.selectedLocation]
                .MedicalGroupAffiliation
            }`
          )
          .then((res) => {
            medAffiliate = res.data[0]
          })
          .finally(() => {
            this.setFields(medAffiliate)
          })
      }
    },
    // TODO: Add a dialog if user wants to create a new location
    addLocation() {
      this.credData.Locations.push({
        // Fee Schedule
        FeeScheduleType: "Fee by Service",
        ProviderEffectiveDatewithFeeSchedule: "",
        ProviderTerminationDatewithFeeSchedule: "",
        MedicareFeeScheduleName: "",
        MedicaidFeeScheduleName: "",
        MedicareMemberPerMonth: "",
        MedicaidMemberPerMonth: "",
        FeeScheduleName: "",
        FeeSchedulePercent: "",

        // 'Other' Info
        riskManagementEffDate: "",
        riskManagementExpDate: "",
        MocAttestationDate: "",
        MocExpDate: this.mocExp,
        TaxonomyCode: "",
        contact_phone: "",
        contact_email: "",

        // Service Location Info
        ServiceLocationName: "",
        DefaultAddress: false,
        ServiceLocationAddress1: "",
        ServiceLocationAddress2: "",
        ServiceLocationCity: "",
        ServiceLocationState: "",
        ServiceLocationZip: "",
        ServiceLocationCounty: "",
        ServiceLocationPhone: "",
        ServiceLocationFax: "",

        // Network Participation
        MedicalGroupAffiliation: "",
        CredDate: "",
        RecredDate: "",
        ClinicReCredDate: "",
        Status: "",
        AcceptingNewPatients: "",
        HospitalBased: false,
        UsesCMSMA: false,

        // Billing
        PrimarySpecialty: "",
        SecondarySpecialty: "",
        BillingProviderName: "",
        BillingProviderNameasAppearsonCheck: "",
        BillingProviderTaxId: "",
        BillingProviderNPI: "",
        BillingProviderAddressType: "",
        RemittoAddress1: "",
        RemittoAddress2: "",
        RemittoCity: "",
        RemittoState: "",
        RemittoZip: "",
        BillingCounty: "",
        BillingPhone: "",
        BillingFax: "",
      })
    },
    setFields(medAffiliate) {
      /*
        Alright, I know what you're thinking, 'Wow Gare, this is really dirty'. And you're right.
        But to do this in a cool ES6 way (spread operator), we would have to change the UI of the Create Locations panel,
        because the v-model'd fields will not update otherwise (the property's change but the user will not see that). Trust me, I've tried.
        ~TODO~
      */

      this.clearFields()
      this.credData.Locations[this.selectedLocation].Status =
        medAffiliate.status
      this.credData.Locations[this.selectedLocation].BillingProviderTaxId =
        medAffiliate.tax_id
      this.credData.Locations[this.selectedLocation].AcceptingNewPatients =
        medAffiliate.AcceptingNewPatients
      this.credData.Locations[this.selectedLocation].contact_phone =
        medAffiliate.contact_phone
      this.credData.Locations[this.selectedLocation].contact_email =
        medAffiliate.contact_email
      this.credData.Locations[this.selectedLocation].contact_fname =
        medAffiliate.contact_fname
      this.credData.Locations[this.selectedLocation].contact_lname =
        medAffiliate.contact_lname
      this.credData.Locations[this.selectedLocation].FeeScheduleType =
        medAffiliate.FeeScheduleType
      this.credData.Locations[this.selectedLocation].FeeSchedulePercent =
        medAffiliate.FeeSchedulePercent
      this.credData.Locations[this.selectedLocation].CapitationAmount =
        medAffiliate.CapitationAmount
      this.credData.Locations[
        this.selectedLocation
      ].maxDaysToSubmitInpatientClaim =
        medAffiliate.maxDaysToSubmitInpatientClaim
      this.credData.Locations[
        this.selectedLocation
      ].maxDaysToSubmitOutpatientClaim =
        medAffiliate.maxDaysToSubmitOutpatientClaim

      // some of these fields are null due to affiliate not being filled out completely / OLD affiliates. slice was causing a big problem
      // the if checks should fix it but putting inside a try catch just in case there are still errors
      try {
        if (medAffiliate.LoadDate) {
          this.credData.Locations[
            this.selectedLocation
          ].LoadDate = medAffiliate.LoadDate.slice(0, 10)
        }

        if (medAffiliate.ClinicReCredDate) {
          this.credData.Locations[
            this.selectedLocation
          ].ClinicReCredDate = medAffiliate.ClinicReCredDate.slice(0, 10)
        }

        if (medAffiliate.ProviderEffectiveDatewithFeeSchedule) {
          this.credData.Locations[
            this.selectedLocation
          ].ProviderEffectiveDatewithFeeSchedule = medAffiliate.ProviderEffectiveDatewithFeeSchedule.slice(
            0,
            10
          )
        }

        if (medAffiliate.ProviderTerminationDatewithFeeSchedule) {
          this.credData.Locations[
            this.selectedLocation
          ].ProviderTerminationDatewithFeeSchedule = medAffiliate.ProviderTerminationDatewithFeeSchedule.slice(
            0,
            10
          )
        }
      } catch (error) {
        console.log(error)
      }
      this.credData.Locations[this.selectedLocation].PrimarySpecialty =
        medAffiliate.PrimarySpecialty
      this.credData.Locations[this.selectedLocation].SecondarySpecialty =
        medAffiliate.SecondarySpecialty
      this.credData.Locations[this.selectedLocation].BillingProviderName =
        medAffiliate.BillingProviderName

      this.credData.Locations[
        this.selectedLocation
      ].BillingProviderNameAsAppearsonCheck =
        medAffiliate.BillingProviderNameAsAppearsonCheck
      this.credData.Locations[this.selectedLocation].BillingProviderNPI =
        medAffiliate.BillingProviderNPI
      this.credData.Locations[
        this.selectedLocation
      ].BillingProviderAddressType = medAffiliate.BillingProviderAddressType
      this.credData.Locations[this.selectedLocation].BillingCounty =
        medAffiliate.BillingCounty
      this.credData.Locations[this.selectedLocation].BillingPhone =
        medAffiliate.BillingPhone
      this.credData.Locations[this.selectedLocation].BillingFax =
        medAffiliate.BillingFax
      this.credData.Locations[this.selectedLocation].RemittoAddress1 =
        medAffiliate.RemittoAddress1
      this.credData.Locations[this.selectedLocation].RemittoAddress2 =
        medAffiliate.RemittoAddress2
      this.credData.Locations[this.selectedLocation].RemittoCity =
        medAffiliate.RemittoCity
      this.credData.Locations[this.selectedLocation].RemittoState =
        medAffiliate.RemittoState
      this.credData.Locations[this.selectedLocation].RemittoZip =
        medAffiliate.RemittoZip
      this.credData.Locations[this.selectedLocation].HospitalBased =
        medAffiliate.HospitalBased
      this.credData.Locations[this.selectedLocation].UsesCMSMS =
        medAffiliate.UsesCMSMA // todo
    },
    clearFields() {
      // Fields should be reset before they are filled because they are read-only once a Med Affiliate is chosen
      this.credData.Locations[this.selectedLocation].tax_id = ""
      this.credData.Locations[this.selectedLocation].contact_phone = ""
      this.credData.Locations[this.selectedLocation].contact_email = ""
      this.credData.Locations[this.selectedLocation].contact_fname = ""
      this.credData.Locations[this.selectedLocation].contact_lname = ""
      this.credData.Locations[this.selectedLocation].Status = ""

      this.credData.Locations[this.selectedLocation].FeeScheduleType = ""
      this.credData.Locations[this.selectedLocation].FeeSchedulePercent = 0
      this.credData.Locations[
        this.selectedLocation
      ].ProviderEffectiveDatewithFeeSchedule = ""
      this.credData.Locations[
        this.selectedLocation
      ].ProviderTerminationDatewithFeeSchedule = ""
      this.credData.Locations[this.selectedLocation].CapitationAmount = 0
      this.credData.Locations[
        this.selectedLocation
      ].maxDaysToSubmitInpatientClaim = 0
      this.credData.Locations[
        this.selectedLocation
      ].maxDaysToSubmitOutpatientClaim = 0
      this.credData.Locations[this.selectedLocation].LoadDate = ""
      this.credData.Locations[this.selectedLocation].ClinicReCredDate = ""

      this.credData.Locations[this.selectedLocation].PrimarySpecialty = ""
      this.credData.Locations[this.selectedLocation].SecondarySpecialty = ""
      this.credData.Locations[this.selectedLocation].BillingProviderName = ""
      this.credData.Locations[
        this.selectedLocation
      ].BillingProviderNameAsAppearsonCheck = ""
      this.credData.Locations[this.selectedLocation].BillingProviderTaxId = ""
      this.credData.Locations[this.selectedLocation].BillingProviderNPI = ""
      this.credData.Locations[
        this.selectedLocation
      ].BillingProviderAddressType = ""
      this.credData.Locations[this.selectedLocation].BillingCounty = ""
      this.credData.Locations[this.selectedLocation].BillingPhone = ""
      this.credData.Locations[this.selectedLocation].RemittoAddress1 = ""
      this.credData.Locations[this.selectedLocation].RemittoAddress2 = ""
      this.credData.Locations[this.selectedLocation].RemittoCity = ""
      this.credData.Locations[this.selectedLocation].RemittoState = ""
      this.credData.Locations[this.selectedLocation].RemittoZip = ""
    },
  },
  computed: {
    noteHeaderColor() {
      if (this.incomingNoteType == "terminationNotes") {
        return "accent"
      } else return "warning"
    },
    affiliated() {
      return !!this.credData.Locations[this.selectedLocation]
        .MedicalGroupAffiliation
    },
  },
  watch: {
    // emit to parent if form is invalid
    startValidation() {
      if (
        (this.startValidation && !this.$refs.form.validate()) ||
        (!this.credData.Locations[this.selectedLocation].TermedAsOfDate &&
          this.credData.Locations[this.selectedLocation].Status === "Termed")
      ) {
        this.$emit("locationErrors", true)
      } else if (this.startValidation && this.$refs.form.validate()) {
        //emit the newStatus object if one exists
        if (
          new Date(
            this.credData.Locations[this.selectedLocation].TermedAsOfDate
          ) >= new Date() &&
          this.oldStatus != "Termed"
        ) {
          this.credData.Locations[this.selectedLocation].Status = this.oldStatus
        }
        if (Object.keys(this.newStatusObj).length !== 0) {
          this.$emit("newStatusObj", this.newStatusObj)
        }
        this.$emit("locationErrors", false)
        this.$emit("locationIndex", this.selectedLocation)
      }
    },
    newStatus() {
      // prompt auto note if status changes to termed or withdrawn
      if (this.newStatus == "Termed" && !this.hasAddedNote) {
        this.noteDialogFlavor = "Terminate"
        this.newNoteMessage =
          "Terminated on: " + new Date().toString().slice(0, 10)
        this.newNoteDialog = true
        this.incomingNoteType = "terminationNotes"
      } else if (this.newStatus == "Withdrawn") {
        this.noteDialogFlavor = "Withdraw"
        this.newNoteMessage =
          "Withdrawn on: " + new Date().toString().slice(0, 10)
        this.newNoteDialog = true
        this.incomingNoteType = "withdrawNotes"
      }
      this.newStatusObj = {
        date: new Date().toISOString().substr(0, 10),
        statusChangeTo: this.newStatus,
        statusChangeFrom: this.oldStatus,
      }
    },
  },
}
</script>

<style scoped>

#location-nav {
  border-top: 0px;
  border-radius: 1em;
  box-shadow: 1px 20px 19px -31px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 1px 20px 19px -31px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 20px 19px -31px rgba(0, 0, 0, 0.5);

  background: rgb(237, 237, 237);
  background: linear-gradient(
    0deg,
    rgba(237, 237, 237, 0.6) 0%,
    rgba(250, 250, 250, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>
