<template>
  <v-dialog v-model="editDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        @click="$emit('setEdit', true);"
        fab
        text
        dense 
        small
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Edit Payment</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Patient Name"
                v-model="modifiedPayment.patientName"
                :rules="[modifiedPayment.patientName != '' || 'Patient Name is required']"
                required
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Patient ID"
                v-model="modifiedPayment.patientID"
                :rules="[modifiedPayment.patientID != '' || 'Patient ID is required']"
                required
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="modifiedPayment.paymentDate"
                    label="Payment Date"
                    append-icon="mdi-calendar"
                    outlined
                    dense
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="modifiedPayment.paymentDate"
                  show-current="false"
                  :hide-details="false"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Payment Type"
                :items="['New Enrollment', 'Renewal', 'Disenrollment', 'Reversal']"
                v-model="modifiedPayment.paymentType"
                :rules="[modifiedPayment.paymentType != '' || 'Payment Type is required']"
                outlined
                dense
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Amount"
                v-model.number="modifiedPayment.amountPaid"
                :rules="[modifiedPayment.amountPaid > 0 || 'Amount Paid is required']"
                required
                placeholder="0"
                type="number"
                prefix="$"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="FMO Payment"
                v-model="modifiedPayment.fmoPayment"
                type="number"
                prefix="$"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox
                class="mt-0"
                v-model="modifiedPayment.paid"
                hide-details
                label="Paid"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="closeEditDialog()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          text
          @click="validate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: "EditPayment",
  props: ['payment'],
  data() {
    return {
      editDialog: false,
      valid: true,
      payDate: '',
      menu1: '',
      modifiedPayment: {
        agentID: '',
        patientName: '',
        patientID: '',
        amountPaid: 0,
        paymentType: '',
        paid: false
      }
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.savePaymentEdit(this.modifiedPayment);
      }
    },
    savePaymentEdit(item) {
      const paymentInfo = {
        paymentID: item.paymentID,
        patientName: item.patientName,
        patientID: item.patientID,
        paymentDate: item.paymentDate,
        paymentType: item.paymentType,
        amountPaid: item.amountPaid,
        fmoPayment: item.fmoPayment,
        paid: item.paid
      }

      axios.post("/api/payments/edit", paymentInfo).then(response => {
        if(response.status === 200) {
          this.editDialog = false;
          this.$emit('editSaved', paymentInfo);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    closeEditDialog() {
      this.modifiedPayment = {
        paymentID: '',
        patientName: '',
        patientID: '',
        paymentDate: null,
        paymentType: '',
        amountPaid: 0,
        fmoPayment: 0,
        paid: false
      }
      this.editDialog = false;
    },
  },
  watch: {
    async editDialog() {
      if(this.editDialog === true) {
        this.modifiedPayment = {
          paymentID: this.payment._id,
          patientName: this.payment.patientName,
          patientID: this.payment.patientID,
          paymentDate: this.payment.created.substr(0, 10),
          paymentType: this.payment.paymentType,
          amountPaid: this.payment.amtPaid,
          fmoPayment: this.payment.fmoPayment,
          paid: this.payment.paid
        }
      }
    },
  },
};
</script>