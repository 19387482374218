<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            outlined
            dense
            label="Over the past 2 weeks, how often have you felt nervous, anxious,
            or on edge?"
            v-model="nervousAnxious"
            required
            :rules="[this.nervousAnxious != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            outlined
            dense
            label="Over the past 2 weeks, how often were you not able to stop
            worrying or control your worrying?"
            v-model="nonStopWorrying"
            required
            :rules="[this.nonStopWorrying != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            outlined
            dense
            label="Over the past 2 weeks, how often have you felt down, depressed,
            or hopeless?"
            v-model="downDepressedHopeless"
            required
            :rules="[
              this.downDepressedHopeless != '' || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            outlined
            dense
            label="Over the past 2 weeks, how often have you felt little interest or
            pleasure in doing things?"
            v-model="interestOrPleasure"
            required
            :rules="[
              this.interestOrPleasure != '' || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="howOftenArray"
            outlined
            dense
            label="How often do you get the social and emotional support you
            need?"
            v-model="socialAndEmotionalSupport"
            required
            :rules="[
              this.socialAndEmotionalSupport != '' ||
                'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="howOftenArray"
            outlined
            dense
            label="How often do you get stressed handling your health, finances,
            relationship, or work?"
            v-model="stressHandlingLife"
            required
            :rules="[
              this.stressHandlingLife != '' || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            :items="['Yes', 'No']"
            outlined
            dense
            v-model="thoughtsOfSuicide"
            label="Over the past 2 weeks, have you had thoughts of suicide?"
            required
            :rules="[
              this.thoughtsOfSuicide != '' || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            :items="['Yes', 'No', 'N/A']"
            outlined
            dense
            label="If yes, do you have a plan?"
            v-model="suicidePlan"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    answerArray: [
      "Not at all",
      "Several days",
      "More days than not",
      "Nearly every day"
    ],
    howOftenArray: ["Always", "Often", "Sometimes", "Rarely", "Never"],
    nervousAnxious: "",
    nonStopWorrying: "",
    downDepressedHopeless: "",
    interestOrPleasure: "",
    socialAndEmotionalSupport: "",
    stressHandlingLife: "",
    thoughtsOfSuicide: "",
    suicidePlan: ""
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          nervousAnxious: this.nervousAnxious,
          nonStopWorrying: this.nonStopWorrying,
          downDepressedHopeless: this.downDepressedHopeless,
          interestOrPleasure: this.interestOrPleasure,
          socialAndEmotionalSupport: this.socialAndEmotionalSupport,
          stressHandlingLife: this.stressHandlingLife,
          thoughtsOfSuicide: this.thoughtsOfSuicide,
          suicidePlan: this.suicidePlan
        });

        this.$emit('selectedTab', 8)
      }
    }
  }
};
</script>
