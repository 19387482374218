<template>
  <v-row justify="start">
    <v-col cols="12">
      <v-form ref="form">
        <v-text-field
          outlined
          dense
          clearable
          v-model="memberName"
          type="text"
          :rules="sigRules"
          label="Please type your first and last name"
        />
      </v-form>
      <div ref="printMe" overflow="visible">
        <input
          overflow="visible"
          type="text"
          :style="signaturePreview"
          outlined
          v-model="memberName"
          disabled
        />
      </div>
    </v-col>
    <!-- <v-col>    
        <img :src="output" />
    </v-col> -->
  </v-row>
</template>

<script>
import html2canvas from "html2canvas-render-offscreen";
export default {
  props: {
    signatureSize: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    memberName: "",
    show: false,
    output: null,
    sigRules: [(v) => !!v || "Name is required"],
  }),
  methods: {
    async submit() {
      if (this.memberName.length > 0) {
        const el = this.$refs.printMe;

        const base64Image = await html2canvas(el, {
          width: 700,
          scale: 1,
          useCORS: true,
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
        }).then((canvas) => {
          return canvas.toDataURL("image/png");
        });
        return base64Image;

        // const base64Image = await this.$html2canvas(el, {
        //   type: "dataURL",
        //   useCORS: true,
        //   logging: false,
        //   width: 800,
        // });
        // return base64Image;
      } else {
        this.memberName = "";
      }
    },
  },
  computed: {
    signaturePreview() {
      return {
        fontFamily: "otoo",
        fontSize: `${this.signatureSize}rem`,
        width: "100%",
      };
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "arty";
  src: url("~@/assets/fonts/arty.otf") format("otf");
}
@font-face {
  font-family: "otoo";
  src: url("~@/assets/fonts/otoo.ttf");
}

/* .sigContainer {
  overflow: visible !important;
} */
/* .signaturePreview {
  font-family: "otoo" !important;
  font-size: 5rem !important;
} */
</style>
