<template>
  <v-container fluid>
    <v-menu
      v-if="
        user.role === 'superuser' ||
          user.role === 'manager' ||
          user.role === 'fmo'
      "
      v-model="menu"
      :close-on-content-click="false"
      offset-x
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          right
          top
          fixed
          class="show-filters py-5"
          v-on="on"
        >
          <v-icon>mdi-filter-menu</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0 pt-2" max-width="350px" min-width="350px">
        <v-list-item>
          <v-switch v-model="useDateRange" :label="useDateRange ? 'Within Date Range' : 'All Time'" class="switch-label" />
        </v-list-item>
        <v-list-item>
          <v-select label="Plan Type" v-model="filters.plan" :items="planList" dense outlined></v-select>
        </v-list-item>
        <v-list-item>
          <v-select label="County" v-model="filters.county" :items="countyList" dense outlined></v-select>
          <!-- <v-btn dense color="primary" @click="getDashboard">Submit</v-btn> -->
        </v-list-item>
        <template v-if="useDateRange">
          <v-list-item>
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="startDate" label="Start Date" show-current="2019-12" append-icon="mdi-calendar"
                  outlined dense class="mt-5" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="startDate" :close-on-content-click="true" show-current="false" type="month"
                @input="menu1 = false" no-title scrollable></v-date-picker>
            </v-menu>
          </v-list-item>
          <v-list-item>
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="endDate" label="End Date" append-icon="mdi-calendar" outlined dense readonly v-on="on">
                </v-text-field>
              </template>
              <v-date-picker v-model="endDate" show-current="false" :close-on-content-click="true" type="month"
                @input="menu2 = false" no-title scrollable></v-date-picker>
            </v-menu>
          </v-list-item>
        </template>
        <v-list-item v-if="startDate > endDate">
          <v-alert class="error--text pa-0">Please use a valid date range</v-alert>
        </v-list-item>
        <v-list-item>
          <v-row justify="space-between">
            <v-btn text @click="close" color="error">Cancel</v-btn>
            <v-btn :disabled="startDate > endDate ? true : false" @click="getDashboard" text color="success">Save</v-btn>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-card
      v-if="
        user.role === 'superuser' ||
          user.role === 'manager' ||
          user.role === 'fmo'
      "
      flat
      id="dashboard"
    >

      <v-row align-content="center" justify="center" v-if="loading">
        <v-col class="text-center">
          <v-progress-circular
            color="primary"
            :size="100"
            :width="5"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-card-text v-if="!loading">
        <v-row>
          <v-col class="text-center pb-6">
            <v-chip class="px-5 text-h7" x-large outlined color="primary">
              <span>{{ enrollmentCount }}</span>
              <v-icon class="pl-2">mdi-account-plus</v-icon>
            </v-chip>
            <div class="pt-2 text-h7">Total Members</div>
          </v-col>

          <v-col class="text-center">
            <v-chip class="px-5 text-h7" x-large outlined color="primary">
              <span>{{ disenrollmentCount }}</span>
              <v-icon class="pl-2">mdi-account-minus</v-icon>
            </v-chip>
            <div class="pt-2 text-h7">Disenrollments</div>
          </v-col>

          <v-col class="text-center">
            <v-chip class="px-5 text-h7" x-large outlined color="primary">
              <span>{{ pendingCount }}</span>
              <v-icon class="pl-2">mdi-account-minus</v-icon>
            </v-chip>
            <div class="pt-2 text-h7">Pending</div>
          </v-col>

          <v-col class="text-center">
            <v-chip class="px-5 text-h7" x-large outlined color="primary">
              <span>{{ notEligibleCount }}</span>
              <v-icon class="pl-2">mdi-account-check</v-icon>
            </v-chip>
            <div class="pt-2 text-h7">Not Eligible</div>
          </v-col>

          <!-- <v-col class="text-center">
            <v-chip class="px-5 text-h7" large outlined color="primary">
              <span>{{ renewalCount }}</span>
              <v-icon class="pl-2">mdi-account-check</v-icon>
            </v-chip>
            <div class="pt-2 text-h7">Renewals</div>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-card elevation="3" class="rounded-xl">
              <v-card-title> Members Enrolled </v-card-title>
              <v-card-text>
                <bar-chart
                  :chartData="enrollmentChart"
                  :chartOptions="options"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card elevation="3" class="rounded-xl">
              <v-card-title> Renewals </v-card-title>
              <v-card-text>
                <bar-chart :chartData="renewalChart" :chartOptions="options" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card elevation="3" class="rounded-xl">
              <v-card-title> Applications </v-card-title>
              <v-card-text>
                <bar-chart
                  :chartData="applicationChart"
                  :chartOptions="options"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card elevation="3" class="rounded-xl">
              <v-card-title> Total Members </v-card-title>
              <v-card-text>
                <bar-chart :chartData="totalChart" :chartOptions="options" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="user.role === 'agent'" class="pa-2 my-1" outlined>
      <v-row align-content="center" justify="center" v-if="loading">
        <v-col class="text-center">
          <v-progress-circular
            color="primary"
            :size="100"
            :width="5"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-tabs v-if="!loading" v-model="tab" slider-size="3" grow>
        <v-tab v-for="item in tabOptions" :key="item">{{ item }}</v-tab>

        <v-tabs-items v-model="tab">
          <!-- roster tab -->
          <v-tab-item>
            <v-row>
              <v-col cols="12" class="pa-6">
                <v-text-field
                  class="py-0"
                  v-model="searchRoster"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card outlined class="transparent pa-2 mx-3" height="100%">
                  <v-data-table
                    :loading="loading"
                    :headers="rosterHeaders"
                    :items="rosterData"
                    :search="searchRoster"
                    multi-sort
                    loading-text="Loading data..."
                    class="elevation-0 col-md-12 m-auto"
                  ></v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- payments -->
          <v-tab-item>
            <v-row>
              <v-col cols="12" class="pa-6">
                <v-text-field
                  class="py-0"
                  v-model="searchPayments"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card outlined class="transparent pa-2 mx-3" height="100%">
                  <v-data-table
                    :loading="loading"
                    :headers="paymentsHeaders"
                    :items="paymentsData"
                    :search="searchPayments"
                    multi-sort
                    loading-text="Loading data..."
                    class="elevation-0 col-md-12 m-auto"
                  >
                    <template v-slot:item.amtPaid="{ item }">
                      {{ formatCurrency(item.amtPaid) }}
                    </template>

                    <template v-slot:item.paid="{ item }">
                      <v-simple-checkbox
                        v-model="item.paid"
                        disabled
                        :ripple="false"
                      ></v-simple-checkbox>
                    </template>

                    <template v-slot:item.fmoPayment="{ item }">
                      {{ formatCurrency(item.fmoPayment) }}
                    </template>

                    <template v-slot:item.created="{ item }">
                      {{ item.created.substr(0, 10) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import BarChart from "../components/BarChart";
import moment from "moment";

export default {
  name: "Dashboard",
  components: { BarChart },
  data() {
    return {
      loading: false,
      user: "",
      useDateRange: false,
      enrollmentChart: {
        labels: [],
        datasets: [
          {
            label: "Enrollments",
            borderWidth: 2,
            borderColor: "rgba(200, 90, 97, 0.9)",
            backgroundColor: "rgba(200, 90, 97, 0.3)",
            data: [],
          },
        ],
      },
      renewalChart: {
        labels: [],
        datasets: [
          {
            label: "Renewals",
            borderWidth: 2,
            borderColor: "rgba(100, 111, 200, 0.9)",
            backgroundColor: "rgba(100, 111, 200, 0.3)",
            data: [],
          },
        ],
      },
      applicationChart: {
        labels: [],
        datasets: [
          {
            label: "Applications",
            borderWidth: 2,
            borderColor: "rgba(155, 68, 150, 0.9)",
            backgroundColor: "rgba(155, 68, 150, 0.3)",
            data: [],
          },
        ],
      },
      totalChart: {
        labels: [],
        datasets: [
          {
            label: "Total Members",
            borderWidth: 2,
            borderColor: "rgba(153, 200, 145, 0.9)",
            backgroundColor: "rgba(153, 200, 145, 0.3)",
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: "single",
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              offset: true,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
          yAxes: [
            {
              offset: true,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
        },
      },
      enrollmentCount: 0,
      disenrollmentCount: 0,
      pendingCount: 0,
      notEligibleCount: 0,
      renewalCount: 0,
      unpaidCount: 0,
      menu: false,
      menu1: false,
      menu2: false,
      startDate: moment()
        .subtract(1, "quarter")
        .startOf("quarter")
        .toISOString()
        .substr(0, 7),
      endDate: moment()
        .subtract(1, "quarter")
        .endOf("quarter")
        .subtract(1, "ms")
        .toISOString()
        .substr(0, 7),
      tab: null,
      tabOptions: ["Roster", "Payments"],
      searchRoster: "",
      rosterHeaders: [
        { text: "Name", value: "Full_Name" },
        { text: "Plan", value: "selectedPlan" },
        { text: "PCP", value: "selectedPCP" },
        { text: "Enrollment Status", value: "enrollmentStatus" },
        { text: "Agent ID", value: "agentID" },
        { text: "New Payment", value: "newPayment" },
      ],
      rosterData: [],
      searchPayments: "",
      paymentsHeaders: [
        { text: "Patient ID", value: "patientID" },
        { text: "Patient Name", value: "patientName" },
        { text: "Amount", value: "amtPaid" },
        { text: "Paid", value: "paid" },
        { text: "FMO Payment", value: "fmoPayment" },
        { text: "Payment Type", value: "paymentType" },
        { text: "Payment Date", value: "created" },
        { text: "Actions", value: "ACTIONS" },
      ],
      paymentsData: [],
      fmoAgentIds: [],
      planList: [
        "All",
        "H1748-001 Complete",
        "H1748-003 Diabetes",
        "H1748-004 Heart",
        "H1748-005 Dual",
      ],
      countyList: [
        "All",
        "Cobb",
        "Dekalb",
        "Fulton",
        "Gwinnett",
        "Fayette",
        "Douglas",
        "Cherokee",
        "Henry",
        "Coweta",
        "Rockdale",
        "Clayton",
        "Paulding",
      ],
      filters: {
        plan: "",
        county: "",
      }
    };
  },
  methods: {
    async getUser() {
      await axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // zendesk user name and email display
          localStorage.setItem(
            "name",
            this.user.firstname + " " + this.user.lastname
          );
          localStorage.setItem("email", this.user.email);
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else if (this.user.role === "agent") {
            this.getAgentDashboard();
          } else {
            this.getDashboard();
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async getDashboard() {
      this.close();
      this.loading = true;

      this.enrollmentChart.datasets[0].data = [];
      this.enrollmentChart.labels = [];
      this.renewalChart.datasets[0].data = [];
      this.renewalChart.labels = [];
      this.applicationChart.datasets[0].data = [];
      this.applicationChart.labels = [];
      this.totalChart.datasets[0].data = [];
      this.totalChart.labels = [];

      let agentIds;

      if (this.user.role === "fmo") {
        await this.getFmoAgents();
        agentIds = this.fmoAgentIds;
      } else {
        agentIds = "all";
      }

      let summaryData = await axios.get(
        `api/dashboard/summary/${agentIds}/${this.startDate}/${this.endDate}/${this.useDateRange}/${JSON.stringify(this.filters)}`
      );
      let chartData = await axios.get(
        `api/dashboard/charts/${agentIds}/${this.startDate}/${this.endDate}/${this.useDateRange}/${JSON.stringify(this.filters)}`
      );

      this.enrollmentCount = summaryData.data["Enrollment"];
      this.disenrollmentCount = summaryData.data["Disenrollment"];
      this.pendingCount = summaryData.data["Pending"];
      this.notEligibleCount = summaryData.data["Not Eligible"];
      this.unpaidCount = summaryData.data["Unpaid"];
      this.renewalCount = summaryData.data["Renewals"];

      chartData.data.enrollmentData.forEach((item) => {
        this.enrollmentChart.labels.push(item._id);
        this.enrollmentChart.datasets[0].data.push(item.count);
      });

      chartData.data.renewalData.forEach((item) => {
        this.renewalChart.labels.push(item._id);
        this.renewalChart.datasets[0].data.push(item.RENEWALS);
      });

      chartData.data.applicationsData.forEach((item) => {
        this.applicationChart.labels.push(item._id);
        this.applicationChart.datasets[0].data.push(item.count);
      });
      chartData.data.totalData.forEach((item) => {
        this.totalChart.labels.push(item._id);
        this.totalChart.datasets[0].data.push(item.count);
      });

      this.loading = false;
    },
    async getAgentDashboard() {
      this.loading = true;

      this.paymentsData = [];
      this.rosterData = [];

      let rosterQuery = await axios.get(
        `api/modals/agent/roster/${this.user.userID}`
      );
      let paymentsQuery = await axios.get(
        `api/modals/agent/payments/${this.user.userID}`
      );

      this.rosterData = rosterQuery.data;
      this.paymentsData = paymentsQuery.data;

      this.loading = false;
    },
    async getFmoAgents() {
      this.fmoAgentIds = [];

      await axios.get(`api/fmo/agents/${this.user.fmoNpn}`).then((res) => {
        this.fmoAgentIds = res.data;
      });
    },
    close() {
      this.menu = false;
    },
    formatCurrency(num) {
      let parsed = parseFloat(num);
      if (parsed === 0) {
        return "$0.00";
      } else if (parsed != undefined && parsed != "") {
        return "$" + parsed.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
#dashboard {
  background-color: #fafafa;
}
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
.show-filters {
  border-radius: 25% 0% 0% 25%;
  position: fixed;
  right: 0;
  z-index: 1;
  /* margin-top: 5.5rem; */
}
</style>
