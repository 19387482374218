<template>
  <v-card class="pa-2 mx-3 transparent-card" outlined>
    <v-card-title class="text-h4" style="width: 100%">
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0">Documents</v-col>
        <v-col class="pa-0 ma-0">
          <file-selector
            class="float-right"
            accept-extensions=".jpg,.png,.pdf"
            :multiple="true"
            :max-file-size="5 * 1024 * 1024"
            @changed="handleFilesChanged"
          >
            <v-btn
              color="accent"
              depressed
              :loading="fileUploading"
              :disabled="fileUploading"
            >
              <v-icon class="mr-2">mdi-file-upload</v-icon>
              Upload File
            </v-btn>
          </file-selector>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row v-if="loading" justify="center">
        <v-progress-circular
          :loading="loading"
          indeterminate
          color="primary"
          size="75"
          width="5"
        ></v-progress-circular>
      </v-row>

      <div v-if="!loading && documentList.length == 0">
        No documents to display.
      </div>

      <v-row v-if="!loading && documentList.length > 0" class="align-content-center">
        <v-col
          v-for="(item, index) in documentList"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card height="100%">
            <v-img
              v-if="item.date.substr(item.date.length - 3) !== 'pdf'"
              :src="item.url"
              style="
                display:inline-block; 
                height:150px; 
                overflow-y:hidden;
                box-shadow: inset 0 0 3px #000000;
              "
            ></v-img>

            <pdf
              v-if="item.date.substr(item.date.length - 3) === 'pdf'"
              :src="item.url"
              style="
                display:inline-block; 
                height:150px; 
                overflow-y:hidden;
                box-shadow: inset 0 0 3px #000000;
              "
            ></pdf>
            
            <v-card-title class="text-capitalize">
              {{ item.name.length > 15 ? `${item.name.substr(0, 12)}...` : item.name }}
            </v-card-title>

            <v-card-subtitle class="pb-0">
              {{ item.date.substr(0, 10) }}
            </v-card-subtitle>

            <v-card-actions class="mr-0">
              <v-spacer></v-spacer>

              <v-hover v-slot:default="{ hover }">
                <a :href="item.url" target="_blank" style="text-decoration:none;">
                  <v-btn 
                    :class="hover ? 'success--text' : ''"
                    text 
                    small 
                  >
                    <v-icon>mdi-download-outline</v-icon>
                  </v-btn>
                </a>
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn 
                  @click="openDeleteConfirmation(item)"
                  :class="hover ? 'error--text' : ''"
                  text 
                  small 
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row> 
    </v-card-text>

    <v-dialog
      v-model="deleteDocumentDialog"
      max-width="450px"
      persistent
    >
      <v-card>
        <v-card-title 
          style="word-break: break-word"
          class="error--text text-h5"
        >
          <v-icon class="error--text mr-2" large>mdi-alert-outline</v-icon>
          Are you sure?
        </v-card-title>

        <v-card-text class="text-body-1">
          Deleting a document is a permenant action. 
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDeleteConfirmation"
            color="accent"
            text
          >
            Cancel
          </v-btn>

          <v-btn
            @click="deleteDocument"
            color="success"
            text
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" color="error">
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  name: "CredDocuments",
  props: ["npi", "isFacility"],
  data() {
    return {
      loading: false,
      fileUploading: false,
      documentList: [],
      snackbar: false,
      errorMessage: "",
      deleteDocumentDialog: false,
      docToDelete: {}
    };
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments() {
      this.loading = true;

      axios
        .get(`api/credentialing/documents/${this.npi}`)
        .then((res) => {
          this.documentList = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage =
            "Error loading documents. Please try again later.";
          this.snackbar = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleFilesChanged(files) {
      this.fileUploading = true;

      let data = new FormData();

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        data.append("files[" + i + "]", file);
      }

      // send new document to API
      await axios
        .post(
          `api/credentialing/documents/upload/${this.npi}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getDocuments();
          this.fileUploading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "Error uploading files. Please try again later.";
          this.snackbar = true;
        })
        .finally(() => {
          this.fileUploading = false;
        });
    },
    openDeleteConfirmation(item) {
      this.deleteDocumentDialog = true;
      this.docToDelete = item;
    },
    closeDeleteConfirmation() {
      this.deleteDocumentDialog = false;
    },
    async deleteDocument() {
      await axios
        .post(
          `api/credentialing/documents/delete/${this.npi}`,
          this.docToDelete
        ).then(response => {
          if(response.status === 200) {
            this.deleteDocumentDialog = false;
            this.docToDelete= {};
            this.getDocuments();
          }
        })
    }
  },
};
</script>