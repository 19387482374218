<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="healthRating"
            :items="rateScaleAnswers"
            label="In general, how would you rate your health?"
            outlined
            dense
            required
            :rules="[this.healthRating !== '' || 'Please rate the patient\'s health']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2" class="pt-0">
          <v-checkbox
            class="mt-0"
            v-model="liveAlone"
            hide-details
            label="Live alone"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" class="pt-0">
          <v-checkbox
            class="mt-0"
            v-model="homebound"
            hide-details
            label="Homebound"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" class="pt-0">
          <v-checkbox
            class="mt-0"
            v-model="caregiver"
            hide-details
            label="Have a caregiver"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="erVisits"
            :items="frequencyAnswers"
            label="In the past 3 months, how many times did you go to the Emergency Room (ER)?"
            outlined
            dense
            required
            :rules="[this.erVisits !== '' || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-model="overnightStayHospitals"
            :items="frequencyAnswers"
            label="In the past 3 months, how many times have you had an overnight stay in a hospital (as a patient)?"
            outlined
            dense
            required
            :rules="[this.overnightStayHospitals !== '' || 'Please select the patient\'s sex']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-select
            v-model="dentalHealth"
            :items="rateScaleAnswers"
            label="In general, how would you rate your dental health?"
            outlined
            dense
            required
            :rules="[this.dentalHealth !== '' || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <v-select
            v-model="pcpCommunication"
            :items="['Yes', 'No']"
            label="Do you visit or communicate with your PCP regularly?"
            outlined
            dense
            required
            :rules="[this.pcpCommunication != null || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="pcpCommunicationFreq"
            label="If yes, how often?"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="haveWill"
            :items="['Yes', 'No', 'Not Sure']"
            label="Do you have a living will or advanced directive?"
            outlined
            dense
            required
            :rules="[this.haveWill !== '' || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-model="pcpHaveWillCopy"
            :items="['Yes', 'No']"
            label="If yes, does your PCP have a copy?"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="poaOrSurrogate"
            :items="['Yes', 'No', 'Not Sure']"
            label="Do you have a Power of Attorney (POA) or healthcare surrogate?"
            outlined
            dense
            required
            :rules="[this.poaOrSurrogate != null || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-model="noCareCuzBeliefs"
            :items="['Yes', 'No', 'Prefer not to answer']"
            label="Do you ever choose to not seek medical care because of religious or personal beliefs?"
            outlined
            dense
            required
            :rules="[this.poaOrSurrogate != null || 'Please select an answer']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="needSpecialEquipment"
            :items="['Yes', 'No']"
            label="Do you use or need any special DME equipment/supplies (walkers, trach supplies, ostomy, oxygen)?"
            outlined
            dense
            required
            :rules="[this.needSpecialEquipment != null || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="specialEquipment"
            label="List the DME, if applicable"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="hasFallen"
            :items="['Yes', 'No']"
            label="In the past 6 months, have you fallen?"
            outlined
            dense
            required
            :rules="[this.hasFallen != null || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-model="fallenPCP"
            :items="['Yes', 'No']"
            label="If yes, does your PCP know about it?"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="balanceOrWalkingProblems"
            :items="['Yes', 'No']"
            label="Have you had any problems with balance or walking?"
            outlined
            dense
            required
            :rules="[this.balanceOrWalkingProblems != null || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-model="urineLeakage"
            :items="['Yes', 'No']"
            label="Have you had problems with urine leakage?"
            outlined
            dense
            required
            :rules="[this.urineLeakage != null || 'Please select an answer']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            On a scale of 0-10, what is your normal pain level? (0 = No Pain, 10 = Worst Pain Imaginable)
          </div>
          <v-slider
            v-model="painLevel"
            :tick-labels="painLevels"
            :max="10"
            step="1"
            ticks="always"
            tick-size="3"
            thumb-size="60"
            thumb-label
            class="mt-10"
          >
            <template v-slot:thumb-label="{ value }" class="ta-c">
              {{ getPainLevelDescription(value) }}
            </template>

            <template v-slot:prepend>
              <v-icon>
                mdi-emoticon-happy-outline
              </v-icon>
            </template>

            <template v-slot:append>
              <v-icon>
                mdi-emoticon-sad-outline
              </v-icon>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div>Rate how you feel about each of the following statements:</div>
        </v-col>
        <v-col cols="12" lg="4">
          <div>My health is important to me.</div>
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="question1"
            :items="agreeAnswers"
            outlined
            dense
            required
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <div>I am ultimately the one responsible for taking care of my health and wellness.</div>
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="question2"
            :items="agreeAnswers"
            outlined
            dense
            required
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <div>I am confident I can prevent or reduce problems associated with my health.</div>
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="question3"
            :items="agreeAnswers"
            outlined
            dense
            required
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <div>I am confident I know when I need to seek medical care, and when I am able to take care of myself.</div>
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="question4"
            :items="agreeAnswers"
            outlined
            dense
            required
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <div>I am confident I can talk to my doctor about my health concerns, even when he/she does not ask.</div>
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="question5"
            :items="agreeAnswers"
            outlined
            dense
            required
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <div>I am confident I can follow through on medical treatments or care that I may need to do at home.</div>
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="question6"
            :items="agreeAnswers"
            outlined
            dense
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
export default {
  props: ["recordID"],
  data: () => ({
    valid: true,
    rateScaleAnswers: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
    frequencyAnswers: ["0", "1 time", "2 times", "3 or more times"],
    painLevels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    agreeAnswers: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
    healthRating: "",
    liveAlone: null,
    homebound: null,
    caregiver: null,
    erVisits: "",
    overnightStayHospitals: "",
    dentalHealth: "",
    pcpCommunication: null,
    pcpCommunicationFreq: "",
    haveWill: null,
    pcpHaveWillCopy: null,
    poaOrSurrogate: null,
    noCareCuzBeliefs: null,
    balanceOrWalkingProblems: null, 
    needSpecialEquipment: null,
    specialEquipment: "",
    hasFallen: null,
    fallenPCP: null,
    urineLeakage: null,
    painLevel: null,
    painLocation: "",
    shortTermMemoryProblems: null,
    longTermMemoryProblems: null,
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    menu1: false,
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          healthRating: this.healthRating,
          liveAlone: this.liveAlone,
          homebound: this.homebound,
          caregiver: this.caregiver,
          erVisits: this.erVisits,
          overnightStayHospitals: this.overnightStayHospitals,
          dentalHealth: this.dentalHealth,
          pcpCommunication: this.pcpCommunication,
          pcpCommunicationFreq: this.pcpCommunicationFreq,
          haveWill: this.haveWill,
          pcpHaveWillCopy: this.pcpHaveWillCopy,
          poaOrSurrogate: this.poaOrSurrogate,
          noCareCuzBeliefs: this.noCareCuzBeliefs,
          balanceOrWalkingProblems: this.balanceOrWalkingProblems,
          needSpecialEquipment: this.needSpecialEquipment,
          specialEquipment: this.specialEquipment,
          hasFallen: this.hasFallen,
          fallenPCP: this.fallenPCP,
          urineLeakage: this.urineLeakage,
          painLevel: this.painLevel,
          painLocation: this.painLocation,
          shortTermMemoryProblems: this.shortTermMemoryProblems,
          longTermMemoryProblems: this.longTermMemoryProblems,
          question1: this.question1,
          question2: this.question2,
          question3: this.question3,
          question4: this.question4,
          question5: this.question5,
          question6: this.question6,
        });

        this.$emit('selectedTab', 2)
      }
    },
    acceptNumber() {
      var x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    getPainLevelDescription(value) {
      let description = '';

      switch(value) {
        case 0:
          description = "No Pain";
          break;
        case 1: case 2: case 3:
          description = "Mild";
          break;
        case 4: case 5: case 6:
          description = "Moderate";
          break;
        case 7: case 8: case 9: case 10:
          description = "Severe";
          break;
      }

      return description;
    }
  },
  watch: {
    menu1 (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
};
</script>
