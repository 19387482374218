<template>
    <v-container fluid class="mt-3">
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field label="Affiliate Name" v-model="affiliateData.affiliate_name" required outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
                <v-text-field label="Tax ID" v-model="affiliateData.tax_id" disabled required outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
                <v-checkbox
                    label="Show to Enrollments"
                    v-model="affiliateData.showToEnrollments"
                    class="my-1 py-0"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-text-field label="Contact First Name" v-model="affiliateData.contact_fname" required outlined dense></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Contact Last Name" v-model="affiliateData.contact_lname" required outlined dense></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-text-field label="Contact Phone" v-model="affiliateData.contact_phone" required outlined dense></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Contact Email" v-model="affiliateData.contact_email" required outlined dense></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10">
                <v-text-field label="Taxonomy Code" v-model="affiliateData.TaxonomyCode" required outlined dense></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "GeneralInformationTab",
    props: ['affiliateData']
}
</script>