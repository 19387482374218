<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Care Plans
            <v-spacer></v-spacer>
            <new-careplan :user="user" @newCareplan="addNewCareplan" />
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col class="pa-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :loading="load"
              :headers="headers"
              :items="reportData"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.DETAILS="{ item }">
                <careplan-modal :careplanID="item.careplanID" :user="user" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="newPlanSaved"
      color="success"
      :timeout="5000"
    >
      New care plan has saved successfully. 
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackInfo = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import NewCareplan from "../components/NewCareplan.vue";
import CareplanModal from "../components/CareplanModal.vue"

export default {
  name: "Careplans",
  components: {
    NewCareplan,
    CareplanModal
  },
  data() {
    return {
      user: "",
      load: false,
      search: "",
      reportData: [],
      headers: [
        { text: "Care Plan ID", value: "careplanID" },
        { text: "Member ID", value: "memberID" },
        { text: "Member Name", value: "memberName" },
        { text: "Date Created", value: "dateCreated" },
        { text: "Details", value: "DETAILS" },
      ],
      newPlanSaved: false,
    };
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          this.clinicalType = this.user.clinicaltype
          // check role and reroute if unauthorized
          if (this.user.role === "agent" || this.user.role === "fmo") {
            this.$router.push("/");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else {
            this.getAllCareplans();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/login");
        });
    },
    async getAllCareplans() {
      this.load = true;

      let careplans = await axios.get('/api/careplans/all')

      this.reportData = careplans.data;
      this.load = false;
    },
    addNewCareplan(plan) {
      this.newPlanSaved = true;
      this.reportData.push(plan)
    }
  },
  mounted() {
    this.getUser();
  },
  watch: {
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
