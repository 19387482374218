<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Authorizations
            <v-spacer></v-spacer>
            <new-authorization :user="user"  @newAuth="addAuth"/>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="4" md="6" lg="2" class="py-0">
                <v-select :items="AuthItems" v-model="completed"/>
              </v-col>
            </v-row>
            
            <div>
              <v-tabs
                fixed-tabs
                background-color="primary"
                v-model="tab"
                class="mt-5"
              >
                <v-tab v-for="item in tabs" :key="item">{{ item }}</v-tab>
                <v-tabs-items v-model="tab">
                  <!-- Unassigned -->
                  <v-tab-item>
                    <v-row>
                      <v-col class="pa-6">
                        <v-text-field
                          class="py-0"
                          v-model="search"
                          label="Search"
                          prepend-inner-icon="mdi-magnify"
                          single-line
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-data-table
                      :loading="load"
                      :headers="headers"
                      :items="
                        completed !== 'All' ? unassignedFilteredData : unassignedData
                      "
                      :search="search"
                      :footer-props="{'items-per-page-options':[15, -1]}"
                      sort-by="CreatedDT"
                      sort-desc
                      multi-sort
                      loading-text="Loading data..."
                      class="elevation-0 col-md-12 m-auto"
                    >
                      <template v-slot:item.ACTIONS="{ item }">
                        <v-btn text>
                          <v-icon  @click="openDeleteDialog(item)">
                            mdi-delete
                          </v-icon>
                        </v-btn>

                        <auth-modal :authID="item.AuthID" :user="user"></auth-modal>
                      </template>

                      <template v-slot:item.Services="{ item }">
                        <auth-services-modal
                          :services="item.Services"
                          v-model="servicesModal"
                        ></auth-services-modal>
                      </template>
                    </v-data-table>
                  </v-tab-item>

                  <!-- Nurse -->
                  <v-tab-item>
                    <v-row>
                      <v-col class="pa-6">
                        <v-text-field
                          class="py-0"
                          v-model="search"
                          label="Search"
                          prepend-inner-icon="mdi-magnify"
                          single-line
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-data-table
                      :loading="load"
                      :headers="headers"
                      :items="completed !== 'All' ? nurseFilteredData : nurseData"
                      :search="search"
                      :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
                      sort-by="CreatedDT"
                      sort-desc
                      multi-sort
                      loading-text="Loading data..."
                      class="elevation-0 col-md-12 m-auto"
                    >
                      <template v-slot:item.ACTIONS="{ item }">
                        <v-btn text>
                          <v-icon  @click="openDeleteDialog(item)">
                            mdi-delete
                          </v-icon>
                        </v-btn>

                        <auth-modal :authID="item.AuthID" :user="user"></auth-modal>
                      </template>

                      <template v-slot:item.Services="{ item }">
                        <auth-services-modal
                          :services="item.Services"
                          v-model="servicesModal"
                        ></auth-services-modal>
                      </template>
                    </v-data-table>
                  </v-tab-item>

                  <!-- MD -->
                  <v-tab-item>
                    <v-row>
                      <v-col class="pa-6">
                        <v-text-field
                          class="py-0"
                          v-model="search"
                          label="Search"
                          prepend-inner-icon="mdi-magnify"
                          single-line
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-data-table
                      :loading="load"
                      :headers="headers"
                      :items="completed !== 'All' ? mdFilteredData : mdData"
                      :search="search"
                      :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
                      sort-by="CreatedDT"
                      sort-desc
                      multi-sort
                      loading-text="Loading data..."
                      class="elevation-0 col-md-12 m-auto"
                    >
                      <template v-slot:item.ACTIONS="{ item }">
                        <v-btn text>
                          <v-icon @click="openDeleteDialog(item)">
                            mdi-delete
                          </v-icon>
                        </v-btn>

                        <auth-modal :authID="item.AuthID" :user="user"></auth-modal>
                      </template>

                      <template v-slot:item.Services="{ item }">
                        <auth-services-modal
                          :services="item.Services"
                          v-model="servicesModal"
                        ></auth-services-modal>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </div>
          </v-card-text>
        </v-card>

        <v-dialog v-model="deleteAuthDialog" persistent max-width="600px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>
                Are you sure you want to delete this Authorization?
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="deleteAuthDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="mt-3">
              Deleting an Authorization is a permenant action.
              Please confirm that you wish to remove this notification from the system.
              <v-card-actions>
                <v-spacer />
                <v-btn @click="deleteAuth()" text color="Error">
                  Delete
                </v-btn>

                <v-btn
                  @click="deleteAuthDialog = false"
                  text
                  color="primary"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="deleteSuccess"
          color="success"
          :timeout="2000"
        >
          Authorization successfully deleted
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackInfo = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import NewAuthorization from "../components/NewAuthorization";
import AuthModal from "../components/AuthModal";
import AuthServicesModal from "../components/AuthServicesModal";

export default {
  name: "Authorizations",
  components: {
    NewAuthorization,
    AuthModal,
    AuthServicesModal,
  },
  data() {
    return {
      user: "",
      setServicesData: [],
      servicesModal: false,
      completed: 'All',
      tab: null,
      tabs: ["Unassigned", "Nurse", "MD"],
      load: false,
      search: "",
      reportData: [],
      unassignedData: [],
      unassignedCompletedData: [],
      mdData: [],
      mdCompletedData: [],
      nurseData: [],
      nurseCompletedData: [],
      AuthItems:['All', 'Completed', 'Uncompleted'],
      headers: [
        { text: "Date Created", value: "CreatedDT" },
        { text: "Authorization ID", value: "AuthID" },
        { text: "Patient Member ID", value: "SonderID" },
        { text: "Member Name", value: "MemberName" },
        { text: "Determintation", value: "Determination" },
        { text: "Services", value: "Services" },
        { text: "Requesting Provider Name", value: "ReqProviderName" },
        { text: "Assigned To", value: "AssignedUser.fullName" },
        { text: "Actions", value: "ACTIONS" },
      ],
      deleteAuthDialog: false,
      authToDelete: {},
      deleteSuccess: false,
    };
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "agent" || this.user.role === "fmo") {
            this.$router.push("/");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else {
            this.getReportData();
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$router.push("/login");
        });
    },
    async getReportData() {
      await axios.get(`/api/authorizations/getAllAuthorizations`).then((res) => {
        let allData = res.data
        this.unassignedData = []
        this.nurseData = []
        this.mdData = []

        allData.forEach(authObj => {
          if (authObj.ReviewStatus === "Unassigned") {
            this.unassignedData.push(authObj)
          } else if (authObj.ReviewStatus === "Nurse") {
            this.nurseData.push(authObj)
          } else if (authObj.ReviewStatus === "MD") {
            this.mdData.push(authObj)
          }
        });
      })
    },
    openServicesModal() {
      this.servicesModal = true;
    },
    addAuth(e){
      if (e.ReviewStatus === "Unassigned") {
        this.unassignedData.push(e)
      } else if (e.ReviewStatus === "Nurse") {
        this.nurseData.push(e)
      } else if (e.ReviewStatus === "MD") {
        this.mdData.push(e)
      }
    },
    openDeleteDialog(auth) {
      this.authToDelete = auth;
      this.deleteAuthDialog = true;
    },
    async deleteAuth() {
      await axios.post(`/api/authorizations/delete/${this.authToDelete.AuthID}`)
        .then(() => {
          // Remove item from table in correct tab
          if (this.tab === 0) {
            let deletedItem = this.unassignedData.indexOf(this.authToDelete);

            this.unassignedData.splice(deletedItem, 1);
            this.$nextTick(() => {
              this.deletedItem = -1;
            });
          } else if (this.tab === 1) {
            let deletedItem = this.nurseData.indexOf(this.authToDelete);

            this.nurseData.splice(deletedItem, 1);
            this.$nextTick(() => {
              this.deletedItem = -1;
            });
          } else if ( this.tab === 2) {
            let deletedItem = this.mdData.indexOf(this.authToDelete);

            this.mdData.splice(deletedItem, 1);
            this.$nextTick(() => {
              this.deletedItem = -1;
            });
          }

          this.deleteAuthDialog = false;
          this.deleteSuccess = true;
        });
    }
  },
  mounted() {
    this.getUser();
  },
  watch: {
    completed() {
      this.unassignedFilteredData = [];
      this.nurseFilteredData = [];
      this.mdFilteredData = [];
      if (this.completed === "Completed") {
        this.unassignedData.forEach((obj) => {
          if (obj.Determination !== "Pending") {
            this.unassignedFilteredData.push(obj);
          }
        });
        this.nurseData.forEach((obj) => {
          if (obj.Determination !== "Pending") {
            this.nurseFilteredData.push(obj);
          }
        });
        this.mdData.forEach((obj) => {
          if (obj.Determination !== "Pending") {
            this.mdFilteredData.push(obj);
          }
        });
      }
      else if(this.completed === 'Uncompleted') {
        this.unassignedData.forEach((obj) => {
          if (obj.Determination === "Pending") {
            this.unassignedFilteredData.push(obj);
          }
        });
        this.nurseData.forEach((obj) => {
          if (obj.Determination === "Pending") {
            this.nurseFilteredData.push(obj);
          }
        });
        this.mdData.forEach((obj) => {
          if (obj.Determination === "Pending") {
            this.mdFilteredData.push(obj);
          }
        }); 
      }
    },
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
