<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <span class="display-1">Member Reports</span>
      </v-col>
      <v-col>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="selectedReport"
                :items="reports"
                label="Select Report"
                required
                :rules="[
                  selectedReport.length !== 0 || 'Report choice is required',
                ]"
                flat
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchParams.date"
                    :disabled="selectedReport === 'Not Paid'"
                    :label="
                      selectedReport === 'Enrollment Reports'
                        ? 'Up to Month'
                        : 'Month'
                    "
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    flat
                    outlined
                    dense
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.date"
                  show-current="false"
                  type="month"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn outlined color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="$refs.menu.save(searchParams.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="searchParams.status"
                label="Current Enrollment Status"
                multiple
                :items="statusTypes"
                persistent-hint
                hint="Filters through patient's current status"
                flat
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="searchParams.county"
                :items="counties"
                label="County"
                flat
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                label="Plans"
                v-model="searchParams.selectedPlan"
                :items="planOptions"
                outlined
                dense
                flat
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Agent ID"
                v-model="searchParams.agentId"
                persistent-hint
                hint="Multiple Agent ID's can be selected"
                multiple
                clearable
                small-chips
                flat
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-btn @click="validate" color="accent" depressed>
                Run Report
                <v-icon>mdi-menu-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-alert v-if="!showReport" type="info" color="primary" class="mt-2">
          Please select a report to continue
        </v-alert>

        <v-col
          v-else-if="showReport && changeDetected && reportData.length > 0"
          cols="12"
        >
          <v-alert :value="true" type="warning">
            Change dectected: Run the report to see updated data
          </v-alert>
        </v-col>

        <v-container v-show="showReport" flat>
          <v-row class="my-2" justify="space-around">
            <v-col cols="12" md="4">
              <h4>{{ this.selectedReport }}</h4>
            </v-col>
            <v-col cols="4" md="4">
              <v-chip class="small" outlined color="primary">
                <span>Total Members: {{ totalMembers }}</span>
                <v-icon class="pl-2">mdi-account-check</v-icon>
              </v-chip>
              <!-- <div class="pt-2">Total Members</div> -->
            </v-col>
          </v-row>
          <!-- <v-col cols="12">
              <v-text-field
                class="py-0"
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->

          <v-data-table
            :loading="load"
            :headers="headers"
            :items="filteredMembers"
            :search="search"
            multi-sort
            loading-text="Loading data..."
            class="elevation-0 col-md-12 m-auto"
          >
            <template v-slot:item._id="{ item }">
              {{
                new Date(item._id).toLocaleString("default", {
                  year: "numeric",
                  month: "long",
                })
              }}
            </template>

            <template v-slot:item.DETAILS="{ item }">
              <enrollment-modal
                :enrollee="item"
                :user="user"
              ></enrollment-modal>
            </template>

            <template v-slot:item.result="{ item }">
              <v-icon :color="colorIcon(item.result)">{{
                item.result === "Pass" ? "mdi-check" : "mdi-close"
              }}</v-icon>
            </template>

            <template v-slot:item.paid="props">
              <v-simple-checkbox
                v-model="props.item.paid"
                :ripple="false"
                :disabled="true"
              ></v-simple-checkbox>
            </template>

            <template slot="footer">
              <ExportCSV :data="this.reportData" :name="'agent-testing.csv'">
                <v-btn color="accent" text class="export-button">
                  Export to CSV
                </v-btn>
              </ExportCSV>
            </template>
          </v-data-table>
        </v-container>
        <v-divider />
        <v-alert :value="true" type="info">
          Current enrollment status filters members' CURRENT enrollment status
          from the selected report
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import EnrollmentModal from "../components/EnrollmentModal";
import ExportCSV from "vue-json-csv";

export default {
  name: "Agents",
  components: {
    EnrollmentModal,
    ExportCSV,
  },
  data() {
    return {
      user: "",
      load: false,
      search: "",
      datePicker: false,
      reportMonth: new Date().toISOString().substr(0, 7),
      reports: [
        "Enrollments by Month",
        "Disenrollments by Month",
        "Enrollment Reports",
        // "Renewals by Month", ** Bring these reports back in after
        // "Not Paid" ************ reviewing and redesigning to work properly
      ],
      selectedReport: "",
      headers: [],
      reportData: [],
      valid: true,
      showReport: false,
      changeDetected: false,
      planOptions: [
        "All",
        "H1748-001 Complete",
        "H1748-003 Diabetes",
        "H1748-004 Heart",
        "H1748-005 Dual",
      ],
      statusTypes: ["Enrollment", "Disenrollment", "Pending", "Not Eligible"],
      counties: [
        "All",
        "Cobb",
        "Dekalb",
        "Fulton",
        "Gwinnett",
        "Fayette",
        "Douglas",
        "Cherokee",
        "Henry",
        "Coweta",
        "Rockdale",
        "Clayton",
        "Paulding",
      ],
      filteredMembers: [],
      fmoAgentIds: [],
      searchParams: {
        county: "All",
        status: [],
        agentId: [],
        date: new Date().toISOString().substr(0, 7),
        selectedPlan: "All",
      },
      totalMembers: 0,
    };
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    getHeaders() {
      if (this.selectedReport === "Enrollments by Month") {
        if (this.user.role === "superuser" || this.user.role === "manager") {
          this.headers = [
            { text: "Name", value: "Full_Name" },
            { text: "Plan", value: "selectedPlan" },
            { text: "PCP", value: "selectedPCP" },
            { text: "Birth Date", value: "birthDate" },
            { text: "Phone Number", value: "phoneNumber" },
            { text: "Enrollment Month", value: "month" },
            { text: "Enrolling Agent ID", value: "agentNumberAck" },
            { text: "Paid", value: "paid" },
            { text: "Enrollment Details", value: "DETAILS" },
          ];
        } else if (this.user.role === "agent" || this.user.role === "fmo") {
          this.headers = [
            { text: "Name", value: "Full_Name" },
            { text: "Plan", value: "selectedPlan" },
            { text: "PCP", value: "selectedPCP" },
            { text: "Birth Date", value: "birthDate" },
            { text: "Phone Number", value: "phoneNumber" },
            { text: "Enrollment Month", value: "month" },
            { text: "Enrolling Agent ID", value: "agentNumberAck" },
            { text: "Paid", value: "paid" },
          ];
        }
      } else if (this.selectedReport === "Disenrollments by Month") {
        if (this.user.role === "superuser" || this.user.role === "manager") {
          this.headers = [
            { text: "Name", value: "Full_Name" },
            { text: "Plan", value: "selectedPlan" },
            { text: "PCP", value: "selectedPCP" },
            { text: "Birth Date", value: "birthDate" },
            { text: "Phone Number", value: "phoneNumber" },
            { text: "Enrollment Date", value: "month" },
            { text: "Disnrollment Date", value: "disenrollmentDate" },
            { text: "Enrolling Agent ID", value: "agentNumberAck" },
            { text: "Paid", value: "paid" },
            { text: "Enrollment Details", value: "DETAILS" },
          ];
        } else if (this.user.role === "agent" || this.user.role === "fmo") {
          this.headers = [
            { text: "Name", value: "Full_Name" },
            { text: "Plan", value: "selectedPlan" },
            { text: "PCP", value: "selectedPCP" },
            { text: "Birth Date", value: "birthDate" },
            { text: "Phone Number", value: "phoneNumber" },
            { text: "Enrollment Month", value: "month" },
            { text: "Disnrollment Date", value: "disenrollmentDate" },
            { text: "Enrolling Agent ID", value: "agentNumberAck" },
            { text: "Paid", value: "paid" },
          ];
        }
      } else if (this.selectedReport === "Enrollment Reports") {
        this.headers = [
          { text: "Month", value: "_id" },
          { text: "Enrollments by Month", value: "enrollmentsByMonth" },
        ];
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.getHeaders();
        this.getReport();
      }
    },
    async getReport() {
      this.reportData = [];
      this.load = true;
      this.changeDetected = false;
      this.showReport = true;

      // set url based on role
      let requestURL;
      if (this.selectedReport != "Enrollment Reports") {
        if (
          this.user.role === "superuser" ||
          this.user.role === "manager" ||
          this.user.role === "member services"
        ) {
          requestURL = `api/memberReports/report/all/${this.selectedReport}/${
            this.reportMonth
          }/${JSON.stringify(this.searchParams)}`;
        } else if (this.user.role === "fmo") {
          await this.getFmoAgents();
          requestURL = `api/memberReports/report/all/${this.selectedReport}/${
            this.reportMonth
          }/${JSON.stringify(this.searchParams)}`;
        } else {
          requestURL = `api/memberReports/report/all/${this.selectedReport}/${
            this.reportMonth
          }/${JSON.stringify(this.searchParams)}`;
        }

        axios.get(requestURL).then((res) => {
          this.reportData = res.data;
          this.filteredMembers = this.reportData;
          this.load = false;
        });
      } else {
        try {
          let result = await axios.get(
            `api/memberReports/monthlyMembers/${JSON.stringify(
              this.searchParams
            )}`
          );
          this.reportData = result.data;
          this.filteredMembers = this.reportData;
          this.getHeaders();
          this.load = false;
        } catch (e) {
          console.log(e);
        }
      }
      try {
        this.totalMembers = await axios.get(
          `api/memberReports/totalMembers/${JSON.stringify(this.searchParams)}`
        );
        this.totalMembers = this.totalMembers.data[0].count;
      } catch (e) {
        console.log(e);
      }
    },
    async getFmoAgents() {
      this.fmoAgentIds = [];

      await axios.get(`api/fmo/agents/${this.user.fmoNpn}`).then((res) => {
        this.fmoAgentIds = res.data;
      });
    },
    colorIcon(result) {
      if (result === "Pass") {
        return "success";
      } else {
        return "error";
      }
    },
  },
  mounted() {
    this.getUser();
  },
  watch: {
    selectedReport() {
      if (this.showReport) {
        this.changeDetected = true;
      }
    },
    reportMonth() {
      if (this.showReport) {
        this.changeDetected = true;
      }
    },
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}

.v-data-table {
  word-break: initial;
}
</style>
