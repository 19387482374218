<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid">
      <!-- Medicare/aid Info -->
      <v-row>
        <v-col cols="12">
          <v-card outlined class="transparent-card pa-2 mx-3" height="100%">
            <v-card-title class="text-h4">
              Medicare/Medicaid Info
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="credData.MedicareID"
                    label="Medicare ID"
                    outlined
                    dense
                    class="text-subtitle-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="medicareAwardDatePicker"
                    v-model="medicareAwardDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.McareAwardDate"
                        label="Medicare Award Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="credData.McareAwardDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="medicareAwardDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.medicareAwardDatePicker.save(
                            credData.McareAwardDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="medicareExpDatePicker"
                    v-model="medicareExpDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.McareExpDate"
                        label="Medicare Expiration Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="credData.McareExpDate">
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="medicareExpDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.medicareExpDatePicker.save(
                            credData.McareExpDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="credData.MedicaidID"
                    label="Medicaid ID"
                    outlined
                    dense
                    class="text-subtitle-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="medicaidAwardDatePicker"
                    v-model="medicaidAwardDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.McaidAwardDate"
                        label="Medicaid Award Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="credData.McaidAwardDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="medicaidAwardDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.medicaidAwardDatePicker.save(
                            credData.McaidAwardDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="medicaidExpDatePicker"
                    v-model="medicaidExpDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.McaidExpDate"
                        label="Medicaid Expiration Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="credData.McaidExpDate">
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="medicaidExpDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.medicaidExpDatePicker.save(
                            credData.McaidExpDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Licenses -->
      <v-row v-if="isFacility === false">
        <v-col cols="12">
          <v-card outlined class="transparent-card pa-2 mx-3" height="100%">
            <v-card-title class="text-h4"> Licenses </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="credData.StateProfesionalLicenseNumber"
                    label="State License Number"
                    outlined
                    dense
                    class="text-subtitle-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="stateProfesionalLicenseNumber"
                    v-model="stateProfesionalLicenseNumber"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.stateLicenseAwardDate"
                        label="State Award date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="credData.stateLicenseAwardDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="stateProfesionalLicenseNumber = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.stateProfesionalLicenseNumber.save(
                            credData.stateLicenseAwardDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="stateExpDatePicker"
                    v-model="stateExpDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.stateLicenseExpirationDate"
                        label="State Expiration Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="credData.stateLicenseExpirationDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="stateExpDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.stateExpDatePicker.save(
                            credData.stateLicenseExpirationDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="credData.DEALicenseNumber"
                    label="DEA License Number"
                    outlined
                    dense
                    class="text-subtitle-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="deaAwardDatePicker"
                    v-model="deaAwardDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.DEAAwardDate"
                        label="DEA Award Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="credData.DEAAwardDate">
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="deaAwardDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.deaAwardDatePicker.save(credData.DEAAwardDate)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="deaExpDatePicker"
                    v-model="deaExpDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.DEAExpDate"
                        label="DEA Expiration Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="credData.DEAExpDate">
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="deaExpDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.deaExpDatePicker.save(credData.DEAExpDate)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Professional Malpractice Liability -->
      <v-row>
        <v-col cols="12">
          <v-card outlined class="transparent-card pa-2 mx-3" height="100%">
            <v-card-title class="text-h4">
              Professional & General Malpractice Liability
              <v-spacer></v-spacer>
              <v-btn @click="newMalPracDialog = true" color="success">
                Add Malpractice
              </v-btn>
            </v-card-title>

            <v-card-text>
              <div
                v-if="
                  credData.malpracticeInfo &&
                    credData.malpracticeInfo.length > 0
                "
              >
                <v-row
                  v-for="item in credData.malpracticeInfo"
                  :key="item.malPracPolicyNumber"
                >
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="item.malPracPolicyName"
                      label="Policy Name"
                      outlined
                      dense
                      readonly
                      class="text-subtitle-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="item.malPracPolicyNumber"
                      label="Policy Number"
                      outlined
                      dense
                      readonly
                      class="text-subtitle-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="item.malPracIssueDate"
                      label="Issue Date"
                      readonly
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="item.malPracExpDate"
                      label="Expiration Date"
                      readonly
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.malPracDetails"
                      label="Limitations & Coverage Amount"
                      outlined
                      dense
                      readonly
                      class="text-subtitle-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-right pt-0">
                    <v-btn
                      color="accent"
                      small
                      @click="openEditModal(item, item.malPracPolicyNumber)"
                    >
                      <v-icon small>mdi-pencil</v-icon> Edit
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-row v-else>
                <v-col cols="12">
                  There is no current malpractice liability information
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Board Certification Info -->
      <v-row v-if="!isFacility">
        <v-col cols="12">
          <v-card outlined class="transparent-card pa-2 mx-3" height="100%">
            <v-card-title class="text-h4">
              Board Certification Info
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <v-checkbox
                    v-model="credData.BoardCertified1"
                    label="Board Certified 1"
                    outlined
                    dense
                    class="text-subtitle-1"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="credData.BoardCertified1Specialty"
                    label="Board Certified Specialty"
                    outlined
                    dense
                    :disabled="!credData.BoardCertified1"
                    class="text-subtitle-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="boardCert1EffectiveDatePicker"
                    v-model="boardCert1EffectiveDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.BoardCertified1EffectiveDate"
                        label="Certification Effective Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        :disabled="!credData.BoardCertified1"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="credData.BoardCertified1EffectiveDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="boardCert1EffectiveDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.boardCert1EffectiveDatePicker.save(
                            credData.BoardCertified1EffectiveDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="boardCert1ExpDatePicker"
                    v-model="boardCert1ExpDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="credData.BoardCertified1ExpDate"
                        label="Certification Expiration Date"
                        append-icon="mdi-calendar"
                        clearable
                        outlined
                        dense
                        :disabled="!credData.BoardCertified1"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="credData.BoardCertified1ExpDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="primary"
                        @click="boardCert1ExpDatePicker = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="
                          $refs.boardCert1ExpDatePicker.save(
                            credData.BoardCertified1ExpDate
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Board Certified 2 -->
                <template v-if="credData.BoardCertified1">
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="credData.BoardCertified2"
                      label="Board Certified 2"
                      outlined
                      dense
                      class="text-subtitle-1"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="credData.BoardCertified2Specialty"
                      label="Board Certified Specialty"
                      outlined
                      dense
                      :disabled="!credData.BoardCertified2"
                      class="text-subtitle-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="boardCert2EffectiveDatePicker"
                      v-model="boardCert2EffectiveDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="credData.BoardCertified2EffectiveDate"
                          label="Certification Effective Date"
                          append-icon="mdi-calendar"
                          clearable
                          outlined
                          dense
                          :disabled="!credData.BoardCertified2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="credData.BoardCertified2EffectiveDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="boardCert2EffectiveDatePicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.boardCert2EffectiveDatePicker.save(
                              credData.BoardCertified2EffectiveDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="boardCert2ExpDatePicker"
                      v-model="boardCert2ExpDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="credData.BoardCertified2ExpDate"
                          label="Certification Expiration Date"
                          append-icon="mdi-calendar"
                          clearable
                          outlined
                          dense
                          :disabled="!credData.BoardCertified2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="credData.BoardCertified2ExpDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="boardCert2ExpDatePicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.boardCert2ExpDatePicker.save(
                              credData.BoardCertified1ExpDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </template>
                <!-- Board Certified 3 -->
                <template
                  v-if="credData.BoardCertified1 && credData.BoardCertified1"
                >
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="credData.BoardCertified3"
                      label="Board Certified 3"
                      outlined
                      dense
                      class="text-subtitle-1"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="credData.BoardCertified3Specialty"
                      label="Board Certified Specialty"
                      outlined
                      dense
                      :disabled="!credData.BoardCertified3"
                      class="text-subtitle-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="boardCert3EffectiveDatePicker"
                      v-model="boardCert3EffectiveDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="credData.BoardCertified3EffectiveDate"
                          label="Certification Effective Date"
                          append-icon="mdi-calendar"
                          clearable
                          outlined
                          dense
                          :disabled="!credData.BoardCertified3"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="credData.BoardCertified3EffectiveDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="boardCert3EffectiveDatePicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.boardCert3EffectiveDatePicker.save(
                              credData.BoardCertified3EffectiveDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="boardCert3ExpDatePicker"
                      v-model="boardCert3ExpDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="credData.BoardCertified3ExpDate"
                          label="Certification Expiration Date"
                          append-icon="mdi-calendar"
                          clearable
                          outlined
                          dense
                          :disabled="!credData.BoardCertified3"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="credData.BoardCertified3ExpDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="boardCert3ExpDatePicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.boardCert3ExpDatePicker.save(
                              credData.BoardCertified3ExpDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <!-- New Malpractice dialog -->
    <v-dialog v-model="newMalPracDialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          Add New Malpractice Information
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newMalpracticeName"
                label="Policy Name"
                outlined
                dense
                class="text-subtitle-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newMalpracticeNumber"
                label="Policy Number"
                outlined
                dense
                class="text-subtitle-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="newMalpracticeIssueDatePicker"
                v-model="newMalpracticeIssueDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newMalpracticeIssueDate"
                    label="Issue Date"
                    append-icon="mdi-calendar"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker ref="picker" v-model="newMalpracticeIssueDate">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="newMalpracticeIssueDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.newMalpracticeIssueDatePicker.save(
                        newMalpracticeIssueDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="newMalpracticeExpDatePicker"
                v-model="newMalpracticeExpDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newMalpracticeExpDate"
                    label="Expiration Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker ref="picker" v-model="newMalpracticeExpDate">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="newMalpracticeExpDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.newMalpracticeExpDatePicker.save(
                        newMalpracticeExpDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newMalpracticeLimitAmount"
                label="Limitations & Coverage Amount"
                outlined
                dense
                class="text-subtitle-1"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="clearNewMalPrac">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveMalpractice()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-snackbar v-model="malpracticeSnackbar" :timeout="4000" color="success">
        New malpractice has been saved
      </v-snackbar>
    </v-dialog>

    <!-- Edit Malpractice Dialog -->
    <v-dialog v-model="editMalPracDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>Edit Selected Malpractice</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newMalpracticeName"
                label="Policy Name"
                outlined
                dense
                class="text-subtitle-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newMalpracticeNumber"
                label="Policy Number"
                outlined
                dense
                class="text-subtitle-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="updatedMalpracticeIssueDatePicker"
                v-model="updatedMalpracticeIssueDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newMalpracticeIssueDate"
                    label="Issue Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker ref="picker" v-model="newMalpracticeIssueDate">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="updatedMalpracticeIssueDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.updatedMalpracticeIssueDatePicker.save(
                        newMalpracticeIssueDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="updatedMalpracticeExpDatePicker"
                v-model="updatedMalpracticeExpDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newMalpracticeExpDate"
                    label="Expiration Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker ref="picker" v-model="newMalpracticeExpDate">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    @click="updatedMalpracticeExpDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="
                      $refs.updatedMalpracticeExpDatePicker.save(
                        newMalpracticeExpDate
                      )
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newMalpracticeLimitAmount"
                label="Limitations & Coverage Amount"
                outlined
                dense
                class="text-subtitle-1"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="accent" @click="clearNewMalPrac()">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="updateMalPractice()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios"

export default {
  props: ["credData", "isFacility", "startValidation", "user"],
  data() {
    return {
      valid: false,
      deaAwardDatePicker: false,
      deaExpDatePicker: false,
      medicareAwardDatePicker: false,
      medicaidExpDatePicker: false,
      medicaidAwardDatePicker: false,
      medicareExpDatePicker: false,
      stateProfesionalLicenseNumber: false,
      malpracticeExpDatePicker: false,
      malPracticeIssueDatePicker: false,
      boardCert1EffectiveDatePicker: false,
      boardCert1ExpDatePicker: false,
      boardCert2EffectiveDatePicker: false,
      boardCert2ExpDatePicker: false,
      boardCert3EffectiveDatePicker: false,
      boardCert3ExpDatePicker: false,
      sonderCredDatePicker: false,
      stateExpDatePicker: false,
      BoardCertified2: false,
      "BoardCertified3(YN)": "N",
      newMalPracDialog: false,
      newMalpracticeName: "",
      newMalpracticeNumber: "",
      newMalpracticeIssueDatePicker: false,
      newMalpracticeIssueDate: "",
      newMalpracticeExpDatePicker: false,
      newMalpracticeExpDate: "",
      malpracticeSnackbar: false,
      newMalpracticeLimitAmount: "",
      editMalPracDialog: false,
      malpracticeUpdateKey: "",
      updatedMalpracticeExpDatePicker: false,
      updatedMalpracticeIssueDatePicker: false,
    }
  },
  methods: {
    async saveMalpractice() {
      let newMalpractice = {
        malPracPolicyName: this.newMalpracticeName,
        malPracPolicyNumber: this.newMalpracticeNumber,
        malPracIssueDate: this.newMalpracticeIssueDate,
        malPracExpDate: this.newMalpracticeExpDate,
        malPracDetails: this.newMalpracticeLimitAmount,
      }

      axios
        .post("/api/modals/credentialing/newmalpractice", {
          newMalpractice: newMalpractice,
          isFacility: this.isFacility,
          npiNumber: this.isFacility
            ? this.credData.FacilityNPI
            : this.credData.RenderingNPI,
        })
        .then((response) => {
          // if successfully saved display snackbar notification
          if (response.status === 200) {
            // send new data to parent to update view
            this.$emit("malpractiveSaved", newMalpractice)
            this.newMalPracDialog = false
            this.malpracticeSnackbar = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async updateMalPractice() {
      try {
        this.credData.malpracticeInfo.forEach((malpractice) => {
          if (malpractice.malPracPolicyNumber === this.malpracticeUpdateKey) {
            malpractice.malPracPolicyName = this.newMalpracticeName
            malpractice.malPracPolicyNumber = this.newMalpracticeNumber
            malpractice.malPracIssueDate = this.newMalpracticeIssueDate
            malpractice.malPracExpDate = this.newMalpracticeExpDate
            malpractice.malPracDetails = this.newMalpracticeLimitAmount
            axios.post("/api/modals/credentialing/updatemalpractice", {
              practiceToUpdate: this.credData.malpracticeInfo,
              isFacility: this.isFacility,
              policyKey: this.malpracticeUpdateKey,
              npi: this.isFacility
                ? this.credData.FacilityNPI
                : this.credData.RenderingNPI,
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
      this.clearNewMalPrac()
    },
    clearNewMalPrac() {
      //Clears both selected modals when closed
      this.newMalpracticeName = ""
      this.newMalpracticeNumber = ""
      this.newMalpracticeIssueDate = ""
      this.newMalpracticeExpDate = ""
      this.newMalpracticeLimitAmount = ""
      this.newMalPracDialog = false
      this.editMalPracDialog = false
      this.malpracticeUpdateKey = ""
    },
    openEditModal(malpractice, policyNum) {
      //Sets all of the necessary fields for editing the existing, selected malpractice
      this.editMalPracDialog = true
      this.newMalpracticeName = malpractice.malPracPolicyName
      this.newMalpracticeNumber = malpractice.malPracPolicyNumber
      this.newMalpracticeIssueDate = malpractice.malPracIssueDate
      this.newMalpracticeExpDate = malpractice.malPracExpDate
      this.newMalpracticeLimitAmount = malpractice.malPracDetails
      this.malpracticeUpdateKey = policyNum
    },
  },
  watch: {
    startValidation() {
      if (this.startValidation && !this.$refs.form.validate()) {
        this.$emit("licenseErrors", true)
      } else if (this.startValidation && this.$refs.form.validate()) {
        this.$emit("licenseErrors", false)
      }
    },
  },
}
</script>
