<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-row >
            <v-col>
              <v-card-title>
                Not Eligible Patients
                <template v-if="user.role === 'fmo'">
                  <v-spacer />
                  <v-switch v-model="showFmoMembers" label="Show FMO Agents Members" />
                </template>
              </v-card-title>
            </v-col>
            <v-col class="px-7" cols="12" lg="5">
              <v-select :items="planOptions" v-model="planFilter" @change="filterNonEligiblePatients()"/>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col class="px-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="filteredMembers"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              
              <template v-slot:item.enrollmentStatus="{ item }">
                <enrollment-status-modal :view="'Not Eligible'" :patient="item" @closeEnrollmentStatusModal="closeEnrollmentStatusModal"></enrollment-status-modal>
              </template>

              <template v-slot:item.agent="{ item }">
                <span v-if="item.agent">{{item.agent}}</span>
                <span v-else>{{item.tempAgent}}</span>
              </template>

              <template v-slot:item.DETAILS="{ item }">
                <enrollment-modal :enrollee="item" :user="user" @documentSaved="getUser"></enrollment-modal>
              </template>

              <template slot="footer">
                <ExportCSV
                  :data="this.notEligibleData"
                  :name="'enrollments.csv'"
                >
                  <v-btn color="accent" text class="export-button">
                    Export to CSV
                  </v-btn>
                </ExportCSV>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackInfo" :timeout="3000" color="success">
      Patient successfully updated!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import EnrollmentModal from "../components/EnrollmentModal";
import EnrollmentStatusModal from "../components/EnrollmentStatusModal";
import ExportCSV from "vue-json-csv";
import axios from "axios";

export default {
  name: "noteligible",
  components: {
    EnrollmentModal,
    ExportCSV,
    EnrollmentStatusModal
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      headers: [
        { text: "Name", value: "Full_Name" },
        { text: "Plan", value: "selectedPlan" },
        { text: "PCP", value: "selectedPCP" },
        { text: "Enrollment Status", value: "enrollmentStatus" },
        { text: "Agent ID", value: "agentID" },
        { text: "Agent Name", value: "agent" },
        { text: "Enrollment Details", value: "DETAILS" },
      ],
      notEligibleData: [],
      paymentDialog: false,
      snackInfo: false,
      rules: {
        memberID: [(val) => (val || "").length > 0 || "This field is required"],
      },
      enrollmentStatus: "",
      patient: "",
      memberID: "",
         planOptions:[
        'All',
        'H1748-001 Complete',
        'H1748-003 Diabetes',
        'H1748-004 Heart',
        'H1748-005 Dual'
      ],
      planFilter:"All",
      filteredMembers:[],
      fmoAgents: [],
      showFmoMembers: false 
    };
  },

  mounted() {
    this.getUser();
  },
  methods: {
    closeEnrollmentStatusModal(value) {
      if (value) {
        this.getNotEligiblePatients();
      } 
    },
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else {
            if (this.user.role === "agent" || this.user.role === "fmo") {
              this.headers = [
                { text: "Name", value: "Full_Name" },
                { text: "Plan", value: "selectedPlan" },
                { text: "PCP", value: "selectedPCP" },
                { text: "Enrollment Status", value: "enrollmentStatus" },
                { text: "Agent ID", value: "agentID" },
                { text: "Enrollment Details", value: "DETAILS" },
              ];
            } else if (this.user.role === "member services") {
              this.headers = [
                { text: "Name", value: "Full_Name" },
                { text: "Plan", value: "selectedPlan" },
                { text: "PCP", value: "selectedPCP" },
                { text: "Agent ID", value: "agentID" },
                { text: "Enrollment Details", value: "DETAILS" },
              ];
            }

            this.getNotEligiblePatients();
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async getNotEligiblePatients() {
      this.notEligibleData = [];
      this.load = true;

      let requestURL;
      if (this.user.role === "agent" || (this.user.role === "fmo" && !this.showFmoMembers)) {
        requestURL = `api/noteligible/${this.user.userID}`;
      }
      else if (this.user.role === "fmo" && this.showFmoMembers) {
          this.fmoAgents = []
          await axios
            .get(`api/fmo/agents/${this.user.fmoNpn}`).then(res => {
              this.fmoAgents = res.data;
            })
            .then(() => {
              this.fmoAgents.forEach(agentID => {
                axios.get(`api/noteligible/${agentID}`).then(res => {
                  if(res.data[0]) {
                    this.notEligibleData.push(res.data[0])
                  }
                })
              })
            })
            .finally(() => {
              this.filterNonEligiblePatients();
              this.csvData = JSON.parse(JSON.stringify(this.notEligibleData));
              this.csvData.forEach((user) => {
                delete user._id;
              });
              this.load = false;
            })
      }
      else if (
        this.user.role === "superuser" ||
        this.user.role === "manager"   ||
        this.user.role === "member services"
      ) {
        requestURL = `api/noteligible/all`;
      }

    if(!this.showFmoMembers) {
      await axios.get(requestURL).then((res) => {
        this.notEligibleData = res.data;
        // Filter data
        this.filterNonEligiblePatients();
        this.load = false;
      });
    }
    },
    filterNonEligiblePatients() {
      if(this.planFilter === 'All'){
        this.filteredMembers = this.notEligibleData;
      }
      else{
        this.filteredMembers = []
        this.notEligibleData.forEach(notEligible => {
          if(notEligible.selectedPlan && notEligible.selectedPlan.includes(this.planFilter.substr(0,9))){
            this.filteredMembers.push(notEligible)
          }
        })
      }
    }
  },
  watch: {
    showFmoMembers() {
      this.getNotEligiblePatients()
    },
    user() {
      if(this.user.role === "fmo") {
        this.showFmoMembers = true
      }
    }
  }
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
