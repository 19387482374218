<template>
  <v-app class="login-img">
    <v-navigation-drawer
      color="sidebar"
      class="login-sidebar pa-7"
      permanent
      floating
      :width="300"
    >
      <v-img
        :src="require('@/assets/vernierwhite.png')"
        max-width="250"
        class="mx-auto"
      ></v-img>
      <div class="text-subtitile-1 text-center my-4">
        Please enter in a new password and confirm below
      </div>
      <v-alert type="error" class="mt-5" v-if="errorMessage === true">
        Invalid credentials, please try again.
      </v-alert>
      <v-form ref="form" class="mt-5">
        <v-text-field
          v-model="password"
          label="New Password"
          required
          outlined
          prepend-inner-icon="mdi-lock"
          :rules="passwordRules"
          :type="false ? 'text' : 'password'"
        ></v-text-field>
        <v-text-field
          v-model="confirmPassword"
          label="Confirm New Password"
          required
          outlined
          prepend-inner-icon="mdi-lock-check"
          :rules="passwordConfirm"
          :type="false ? 'text' : 'password'"
          @keydown.enter="validate"
        ></v-text-field>
      </v-form>
      <v-btn
        :disabled="!valid"
        depressed
        color="primary"
        class="px-5"
        @click="validate"
      >
        Sign in
      </v-btn>

      <v-list>
        <v-list-item class="sidebar-bottom-text">
          <v-btn
            text
            color="primary"
            href="https://halossystems.com"
            target="_blank"
            small
          >
            Powered by Halos Systems
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="welcome-text d-none d-lg-block">
      <div class="display-3">Change Password.</div>
      <div class="overline">
        Welcome to your new account. Please create a new password.
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: true,
      password: "",
      user: "",
      confirmPassword: "",
      errorMessage: false,
      passwordRules: [
        (v) => !!v || "Password required",
        (v) => (v && v.length >= 5) || "Password must have 5+ characters",
        (v) => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have one number",
      ],
      passwordConfirm: [
        (v) => !!v || "Confirm password",
        (v) => v === this.password || "Passwords do not match",
      ],
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      await axios.get("/api/user").then((response) => {
        console.log(response);
        this.user = response.data[0];
      });
    },
    validate(e) {
      if (this.$refs.form.validate()) {
        this.updateUser(e);
      }
    },
    updateUser(e) {
      e.preventDefault();

      const { email } = this.user;
      const { password } = this;
      const update = () => {
        const data = {
          email,
          password,
        };
        axios
          .post("/api/user/pwchange", data)
          .then(() => {
            console.log("Logged in");
            this.$router.push("/");
          })
          .catch((errors) => {
            console.log(errors);
            this.errorMessage = true;
            console.log("Cannot login");
          });
      };
      update();
    },
  },
};
</script>
<style scoped>
.sidebar-bottom-text {
  position: fixed !important;
  bottom: 10px;
  left: 0;
  width: 100%;
}

.login-img {
  background-image: url(../assets/loginbg.jpg);
  background-size: cover;
}

.welcome-text {
  position: absolute;
  top: 45%;
  left: 25%;
}
</style>
