<template>
  <v-dialog v-model="dialog" id="cred-modal" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
        style="position: absolute; right: 5px; top: 18px"
        @click="getData"
      >
        <v-icon color="accent">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-toolbar dark color="primary" class="mx-auto">
      <v-btn icon dark @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn color="success" depressed dark @click="saveData()" class="mb-2">
        <v-icon>mdi-content-save</v-icon>
        <span class="pl-3">Save Changes</span>
      </v-btn>
    </v-toolbar>

    <v-card tile flat color="#fafafa">
        <template v-if="!loading">
      <v-card id="modal-header" flat class="pb-4 mb-1">
        <v-card-title class="text-h3">
          {{ affiliateData.affiliate_name }}
        </v-card-title>
        <v-card-subtitle class="pb-0 text-h5">
          <span class="accent--text">Tax ID:</span> {{ affiliateData.tax_id }}
          <!-- todo-->
        </v-card-subtitle>
      </v-card>

      <v-tabs fixed-tabs v-model="tab">
        <v-tab v-for="item in tabItems" :key="item">{{ item }}</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <general-information-tab :affiliateData="affiliateData" />
          </v-tab-item>
          <v-tab-item>
            <location-tab :affiliateData="affiliateData" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </template>
    </v-card>
  </v-dialog>
</template>

<script>
import GeneralInformationTab from "./affiliate-modal-components/GeneralInformationTab.vue"
import LocationTab from "./affiliate-modal-components/LocationTab.vue"
import axios from "axios"

export default {
  name: "AffiliateModal",
  props: ["affiliateName"],
  components: { GeneralInformationTab, LocationTab },
  data() {
    return {
      dialog: false,
      tab: null,
      affiliateData: null,
      tabItems: ["General Information", "Location"],
      loading: true,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.loading = true
    },
    async getData() {
      await axios
        .get(`/api/affiliates/get/${this.affiliateName}`)
        .then((res) => {
          this.affiliateData = res.data[0]
          this.loading = false
        })
    },
    async saveData() {
      await axios.post(`/api/affiliates/edit`, this.affiliateData).then(() => {
        this.closeDialog()
      })
    },
  },
}
</script>

<style>
.v-dialog--fullscreen {
  overflow-x: hidden !important;
}

#modal-header {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 63%,
    rgba(21, 167, 184, 0.1) 100%
  );
}
</style>
