<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        <v-icon color="accent"> mdi-dots-horizontal </v-icon>
      </v-btn>
    </template>

    <v-toolbar dark color="primary" class="mx-auto">
      <v-btn icon dark @click="closeAuthModal()">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        @click="openSaveChanges()"
        class="mb-3"
        color="success"
        depressed
        :disabled="disableSaveButton"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="pl-3">Save Changes</span>
      </v-btn>
    </v-toolbar>

    <v-card tile color="#fafafa">
      <v-row v-if="loading" justify="center" style="height: 100vh">
        <v-col align-self="center" class="text-center">
          <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-if="!loading && encountersData !== undefined">
        <v-col cols="12">
          <v-card flat>
            <v-card-title class="display-2">
              <h1 class="display-2">{{ encountersData.MemberName }}</h1>
            </v-card-title>

            <v-card-subtitle class="py-0 text-subtitle-1">
              <v-col>
                <div>
                  Sonder ID: <span class="primary--text">{{ encountersData.SonderID }}</span>
                </div>
                <div>
                  DOB:
                  <span class="primary--text"
                    >{{ formatDate(encountersData.DOB) }} (Age:{{
                      getAge(encountersData.DOB).toFixed(0)
                    }})</span
                  >
                </div>
                <div>
                  Auth Received:
                  <span class="primary--text"
                    >{{ formatDate(encountersData.ReceiveDate) }},
                    {{ formatAuthTime(encountersData.ReceivedTime) }}</span
                  >
                </div>
              </v-col>
            </v-card-subtitle>
          </v-card>

          <v-tabs fixed-tabs background-color="primary" v-model="tab" class="mt-5">
            <v-tab v-for="item in modalTabs" :key="item">{{ item }}</v-tab>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in modalTabs" :key="item">
                <template v-if="item === 'Summary'">
                  <v-form ref="authForm" v-model="authValid" lazy-validation class="pa-3">
                    <!-- Approval and Assignment Info -->
                    <v-card outlined class="px-3 ma-2">
                      <v-card-title class="px-0"> Approval Status and Assignment </v-card-title>

                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select
                            v-model="encountersData.ReviewStatus"
                            :items="assignmentItems"
                            @change="clearAssigned"
                            label="Assignment"
                            outlined
                            dense
                            required
                            :rules="[
                              encountersData.ReviewStatus != null || 'Please select the assignment',
                            ]"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            label="Search Users"
                            :disabled="encountersData.ReviewStatus === 'Unassigned'"
                            v-model="encountersData.AssignedUser"
                            :items="assignmentList"
                            :loading="assignmentLoading"
                            :search-input.sync="assignmentSearch"
                            item-text="fullName"
                            return-object
                            clearable
                            required
                            :rules="[encountersData.AssignedUser != '' || 'Assignment is required']"
                            outlined
                            dense
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title> Search for users </v-list-item-title>
                              </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title> {{ item.fullName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.userID }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                            v-model="encountersData.Determination"
                            :items="determinationItems"
                            label="Determination"
                            outlined
                            dense
                            required
                            :rules="[
                              encountersData.Determination != null ||
                                'Please select the determination',
                            ]"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>

                    <!-- Requesting Provider -->
                    <v-card outlined class="px-3 ma-2">
                      <v-row>
                        <v-col>
                          <v-card-title class="px-0"> Requesting Provider </v-card-title>
                        </v-col>
                        <v-col cols="12" md="3" class="mt-3">
                          <provider-search @providerSelected="loadRequestingProv"></provider-search>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="encountersData.ReqProviderName"
                            outlined
                            dense
                            clearable
                            :disabled="disableRequestingProv"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="encountersData.ReqProviderNPI"
                            label="Provider NPI"
                            outlined
                            dense
                            required
                            :disabled="disableRequestingProv"
                            :rules="[
                              encountersData.ReqProviderNPI != '' ||
                                'Please enter the requesting provider NPI',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="encountersData.ReqProvTIN"
                            label="Provider TIN"
                            outlined
                            dense
                            required
                            :disabled="disableRequestingProv"
                            :rules="[
                              encountersData.ReqProvTIN != '' ||
                                'Please enter the requesting provider TIN',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.ReqContactName"
                            label="Contact Name"
                            outlined
                            dense
                            required
                            :disabled="disableRequestingProv"
                            :rules="[
                              encountersData.ReqContactName != '' ||
                                'Please enter the requesting contact name',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.ReqContactTitle"
                            label="Contact Title"
                            outlined
                            dense
                            :disabled="disableRequestingProv"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.ReqProvPhone"
                            label="Phone"
                            @input="formatRequestingPhone"
                            outlined
                            dense
                            required
                            :disabled="disableRequestingProv"
                            :rules="[
                              encountersData.ReqProvPhone != '' ||
                                'Please enter valid phone number',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.ReqProvFax"
                            label="Fax"
                            @input="formatRequestingFax"
                            outlined
                            dense
                            required
                            :disabled="disableRequestingProv"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          Please select on of the following:
                          <v-radio-group
                            v-model="encountersData.ReqProvType"
                            required
                            :rules="[
                              encountersData.ReqProvType != null ||
                                'Please select the requesting type',
                            ]"
                            row
                          >
                            <v-radio label="PCP" value="PCP"></v-radio>
                            <v-radio label="Specialist" value="Specialist"></v-radio>
                            <v-radio label="Hospital/Facility" value="Hospital/Facility"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>

                    <!-- Rendering Services -->
                    <v-card outlined class="px-3 ma-2">
                      <v-row>
                        <v-col>
                          <v-card-title class="px-0"> Provider Rendering Services </v-card-title>
                        </v-col>
                        <v-col cols="12" md="3" class="mt-3">
                          <provider-search @providerSelected="loadRendingProv"></provider-search>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="encountersData.RendProvGroup"
                            label="Group/Facility Name"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[
                              encountersData.RendProvGroup != '' ||
                                'Please enter the group/facility name',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="encountersData.RendProvGroupNPI"
                            label="Group NPI"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[
                              encountersData.RendProvGroupNPI != '' || 'Please enter the group NPI',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="encountersData.RendBillingTIN"
                            label="Billing TIN"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[
                              encountersData.RendBillingTIN != '' || 'Please enter the billing TIN',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="encountersData.RendPhysName"
                            label="Physician Name"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[
                              encountersData.RendPhysName != '' ||
                                'Please enter the physician name',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="encountersData.RendPhysNPI"
                            label="Physician NPI"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[
                              encountersData.RendPhysNPI != '' || 'Please enter the physician NPI',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="encountersData.RendPhysAddress"
                            label="Address"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[
                              encountersData.RendPhysAddress != '' || 'Please enter address',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.RendPhysCity"
                            label="City"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[encountersData.RendPhysCity != '' || 'Please enter city']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            label="State"
                            v-model="encountersData.RendPhysState"
                            :items="stateList"
                            required
                            :disabled="disableRendingProv"
                            :rules="[encountersData.RendPhysState != '' || 'Please enter state']"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.RendPhysZip"
                            label="Zip"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[encountersData.RendPhysZip != '' || 'Please enter zip']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.RendPhysPhone"
                            label="Phone"
                            @input="formatServicePhone"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                            :rules="[
                              encountersData.RendPhysPhone != '' ||
                                'Please enter a valid phone number',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="encountersData.RendPhysFax"
                            label="Fax"
                            @input="formatServiceFax"
                            outlined
                            dense
                            required
                            :disabled="disableRendingProv"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          Please select a provider type:
                          <v-radio-group
                            v-model="encountersData.RendPhysNetType"
                            required
                            :rules="[(v) => !!v || 'Selection is required']"
                            row
                          >
                            <v-radio label="Par" value="Par"></v-radio>
                            <v-radio label="Non-Par" value="Non-Par"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>

                    <!-- Service Details -->
                    <v-card outlined class="px-3 ma-2">
                      <v-card-title class="px-0"> Service Details </v-card-title>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            label="Service Type"
                            v-model="encountersData.ServiceType"
                            :items="serviceType"
                            required
                            :rules="[
                              encountersData.ServiceType != null ||
                                'Please select the service type',
                            ]"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            label="POS Code"
                            v-model="encountersData.POS"
                            :items="posCodeList"
                            required
                            :rules="[encountersData.POS != null || 'Please select the POS codes']"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu
                            ref="serviceStartMenu"
                            v-model="serviceStartMenu"
                            :close-on-content-click="false"
                            :return-value.sync="encountersData.StartDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="encountersData.StartDate"
                                label="Start Date"
                                append-icon="mdi-calendar"
                                readonly
                                required
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="encountersData.StartDate"
                              min="1900-01-01"
                            >
                              <v-spacer></v-spacer>
                              <v-btn outlined color="primary" @click="serviceStartMenu = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                @click="$refs.serviceStartMenu[0].save(encountersData.StartDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                          <time-selector
                            class="float-left"
                            v-model="encountersData.StartTime"
                          ></time-selector>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu
                            ref="serviceEndMenu"
                            v-model="serviceEndMenu"
                            :close-on-content-click="false"
                            :return-value.sync="encountersData.EndDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="encountersData.EndDate"
                                label="End Date"
                                append-icon="mdi-calendar"
                                readonly
                                required
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="encountersData.EndDate"
                              :min="encountersData.StartDate"
                            >
                              <v-spacer></v-spacer>
                              <v-btn outlined color="primary" @click="serviceEndMenu = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                @click="$refs.serviceEndMenu[0].save(encountersData.EndDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                          <time-selector
                            class="float-left"
                            v-model="encountersData.EndTime"
                          ></time-selector>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-menu
                            ref="serviceApptMenu"
                            v-model="serviceApptMenu"
                            :close-on-content-click="false"
                            :return-value.sync="encountersData.ApptDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="encountersData.ApptDate"
                                label="Appointment Date"
                                append-icon="mdi-calendar"
                                clearable
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="encountersData.ApptDate"
                              min="1900-01-01"
                            >
                              <v-spacer></v-spacer>
                              <v-btn outlined color="primary" @click="serviceApptMenu = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                @click="$refs.serviceApptMenu[0].save(encountersData.ApptDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card-title>Service Codes</v-card-title>

                          <v-row>
                            <v-col cols="12" sm="9" class="pa-6">
                              <v-text-field
                                class="py-0"
                                v-model="serviceDetailsSearch"
                                label="Search"
                                dense
                                prepend-inner-icon="mdi-magnify"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" class="mt-2">
                              <service-details-dialog
                                @newServiceDetail="addServiceDetails"
                              ></service-details-dialog>
                            </v-col>
                          </v-row>

                          <v-data-table
                            :items="encountersData.Services"
                            :headers="servicesHeaders"
                            :hide-default-footer="true"
                          >
                            <template v-slot:item.Visits="{ item }">
                              <v-text-field
                                v-model="item.Visits"
                                outlined
                                dense
                                class="mt-6 mr-6"
                              ></v-text-field>
                            </template>

                            <template v-slot:item.Units="{ item }">
                              <v-text-field
                                v-model="item.Units"
                                outlined
                                dense
                                class="mt-6 mr-6"
                              ></v-text-field>
                            </template>

                            <template v-slot:item.actions="{ item }">
                              <v-btn @click="openDeleteService(item)" color="error" text>
                                <v-icon> mdi-delete </v-icon>
                              </v-btn>

                              <v-dialog v-model="removeServiceDialog" max-width="450px">
                                <v-card>
                                  <v-toolbar dark color="primary" class="mx-auto mb-3">
                                    <v-card-title color="primary"> Confirm Deletion </v-card-title>

                                    <v-spacer></v-spacer>

                                    <v-btn icon dark>
                                      <v-icon @click="removeServiceDialog = false">
                                        mdi-close
                                      </v-icon>
                                    </v-btn>
                                  </v-toolbar>

                                  <v-card-text>
                                    Are you sure you want to delete this service?
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="accent" text @click="removeServiceDialog = false">
                                      Cancel
                                    </v-btn>

                                    <v-btn color="success" text @click="deleteService()">
                                      Yes, Delete
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                </template>

                <template v-if="item === 'Updates'">
                  <v-row class="ma-2">
                    <v-col cols="4">
                      <v-card outlined class="pa-2 my-2">
                        <h4>Authorization Updates</h4>
                        <v-divider></v-divider>
                        <div v-if="authorizationUpdates.length !== 0">
                          <v-card
                            v-for="(update, index) in authorizationUpdates"
                            :key="index"
                            class="pa-2 my-2"
                            elevation="0"
                          >
                            <v-card-text class="text-subtitle-1 black--text">
                              Date:
                              <span class="font-weight-light">{{
                                formatDate(update.updateDate)
                              }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Reason:
                              <span class="font-weight-light">{{ update.reason }}</span>
                            </v-card-text>
                            <v-divider v-if="index !== authorizationUpdates.length - 1"></v-divider>
                          </v-card>
                        </div>
                        <div v-else>
                          <v-card class="pa-2 my-2" elevation="0">No changes made</v-card>
                        </div>
                      </v-card>
                    </v-col>

                    <v-col cols="4">
                      <v-card outlined class="pa-2 my-2">
                        <h4>Assignment Updates</h4>
                        <v-divider></v-divider>
                        <div v-if="assignmentUpdates.length !== 0">
                          <v-card
                            v-for="(update, index) in assignmentUpdates"
                            :key="index"
                            class="pa-2 my-2"
                            elevation="0"
                          >
                            <v-card-text class="text-subtitle-1 black--text">
                              Date:
                              <span class="font-weight-light">{{
                                formatDate(update.updateDate)
                              }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Change from:
                              <span class="font-weight-light">{{ update.from }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Change to:
                              <span class="font-weight-light">{{ update.to }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Reason:
                              <span class="font-weight-light">{{ update.reason }}</span>
                            </v-card-text>
                            <v-divider v-if="index !== assignmentUpdates.length - 1"></v-divider>
                          </v-card>
                        </div>
                        <div v-else>
                          <v-card class="pa-2 my-2" elevation="0">No changes made</v-card>
                        </div>
                      </v-card>
                    </v-col>

                    <v-col cols="4">
                      <v-card outlined class="pa-2 my-2">
                        <h4>Determination Updates</h4>
                        <v-divider></v-divider>
                        <div v-if="determinationUpdates.length !== 0">
                          <v-card
                            v-for="(update, index) in determinationUpdates"
                            :key="index"
                            class="pa-2 my-2"
                            elevation="0"
                          >
                            <v-card-text class="text-subtitle-1 black--text">
                              Date:
                              <span class="font-weight-light">{{
                                formatDate(update.updateDate)
                              }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Time:
                              <span class="font-weight-light">{{
                                authTime(update.updateDate)
                              }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Change from:
                              <span class="font-weight-light">{{ update.from }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Change to:
                              <span class="font-weight-light">{{ update.to }}</span>
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 black--text">
                              Reason:
                              <span class="font-weight-light">{{ update.reason }}</span>
                            </v-card-text>
                            <v-divider v-if="index !== determinationUpdates.length - 1"></v-divider>
                          </v-card>
                        </div>
                        <div v-else>
                          <v-card class="pa-2 my-2" elevation="0">No changes made</v-card>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>

                <template v-if="item === 'Documents'">
                  <v-card>
                    <auth-documents :authID="authID" />
                  </v-card>
                </template>

                <template v-if="item === 'Tasks'">
                  <v-card>
                    <auth-tasks :auth="encountersData" :user="user" />
                  </v-card>
                </template>

                <template v-if="item === 'Notifications'">
                  <v-card>
                    <auth-notifications :auth="encountersData" />
                  </v-card>
                </template>

                <template v-if="item === 'Notes'">
                  <v-card>
                    <auth-notes :authID="authID" :user="user" />
                  </v-card>
                </template>
                <template v-if="item === 'Additional Questions'">
                  <v-card outlined class="pa-2 my-2 ma-2">
                    <v-card-title class="px-0"> Additional Questions </v-card-title>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-select
                          :items="booleanSelect"
                          v-model="encountersData.TimeframeExtension"
                          dense
                          outlined
                          label="Was a timeframe extension taken?"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-menu
                          v-model="WrittenNotificationDateMenu"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Date Written Notification provided to enrollee"
                              v-model="encountersData.WrittenNotificationDate"
                              prepend-inner-icon="mdi-calendar"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="encountersData.WrittenNotificationDate"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <time-selector
                          v-model="encountersData.WrittenNotificationTime"
                          class="float-left"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-menu
                          v-model="OralNotificationDateMenu"
                          :return-value.sync="encountersData.OralNotifcationTimeMinute"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Date Oral Notification provided to enrollee"
                              v-model="encountersData.OralNotificationDate"
                              prepend-inner-icon="mdi-calendar"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="encountersData.OralNotificationDate"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <time-selector
                          v-model="encountersData.OralNotificationTime"
                          class="float-left"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-select
                          :items="booleanSelect"
                          v-model="encountersData.LackMedicalNecessity"
                          dense
                          outlined
                          label="Was the request denied for lack of medical necessity?"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="exitConfirmationDialog" max-width="450px" persistent>
      <v-card>
        <v-toolbar dark color="primary" class="mx-auto mb-3">
          <v-card-title color="primary"> Are you sure you want to exit? </v-card-title>

          <v-spacer></v-spacer>

          <v-btn icon dark>
            <v-icon @click="exitConfirmationDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="heading">
          You have made changes without saving the record. Do you want to save before exiting?
        </v-card-text>

        <v-card-actions>
          <v-btn color="accent" text @click="dialog = false"> Exit without saving </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="openSaveChanges()"> Save & Exit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="saveChangesDialog" max-width="900px" persistent>
      <v-card>
        <v-toolbar dark color="primary" class="mx-auto mb-3">
          <v-card-title color="primary"> Reason(s) for Changes to Authorization </v-card-title>

          <v-spacer></v-spacer>

          <v-btn icon dark>
            <v-icon @click="closeSaveChanges">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-form ref="reasonsForm" v-model="reasonsValid" lazy-validation>
            <v-row>
              <v-col cols="12" md="4" v-if="authorizationChange">
                <div>Authorization Reason:</div>
                <v-textarea
                  v-model="authorizationReason"
                  label="Please enter a reason"
                  dense
                  outlined
                  class="mt-1"
                  :rules="[authorizationReason != '' || 'Authorization reason is required']"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" md="4" v-if="determinationChange">
                <div>Determination Reason:</div>
                <v-textarea
                  v-model="determinationReason"
                  label="Please enter a reason"
                  dense
                  outlined
                  class="mt-1"
                  :rules="[determinationReason != '' || 'Determination reason is required']"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" md="4" v-if="assignmentChange">
                <div>Assignment Reason:</div>
                <v-textarea
                  v-model="assignmentReason"
                  label="Please enter a reason"
                  dense
                  outlined
                  class="mt-1"
                  :rules="[assignmentReason != '' || 'Assignment reason is required']"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                Would you like to send a document alongside these updates?
                <v-radio-group
                  v-model="deliveryMethod"
                  required
                  :rules="[deliveryMethod != '' || 'Please make a selection']"
                >
                  <v-radio label="Email" value="Email"></v-radio>
                  <v-radio label="Fax" value="Fax"></v-radio>
                  <v-radio label="None" value="None"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeSaveChanges" color="accent" text> Cancel </v-btn>

          <v-btn @click="checkDeliveryMethod" color="success" text> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deliveryInfoDialog" max-width="500px" persistent>
      <v-card>
        <v-toolbar dark color="primary" class="mx-auto mb-3">
          <v-card-title color="primary"> Save and Send </v-card-title>

          <v-spacer></v-spacer>

          <v-btn icon dark>
            <v-icon @click="closeDeliveryInfo">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-form ref="deliveryForm" v-model="deliveryValid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <div class="mb-2">Select a template to send:</div>
                <v-select
                  label="Choose a template"
                  v-model="selectedTemplate"
                  required
                  :rules="[selectedTemplate != '' || 'Please select the service type']"
                  :items="templateItems"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pb-0"
                v-if="
                  selectedTemplate === 'Reduction' ||
                  selectedTemplate === 'Stopped' ||
                  selectedTemplate === 'Suspension'
                "
              >
                <div class="mb-2">Select an effective date:</div>
                <v-menu
                  v-model="documentEffectiveDateMenu"
                  transition="scale-transition"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Received Date"
                      v-model="documentEffectiveDate"
                      required
                      :rules="[documentEffectiveDate != '' || 'Received Date is required']"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="documentEffectiveDate" no-title></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" v-if="deliveryMethod === 'Fax'" class="py-0">
                <div>Destination(s):</div>
                <div class="text-caption">(at least one selection required)</div>
                <v-checkbox
                  label="Requesting Provider"
                  v-model="sendToRequesting"
                  dense
                ></v-checkbox>
                <v-checkbox label="Rendering Provider" v-model="sendToRendering" dense></v-checkbox>
              </v-col>
              <v-col cols="12" v-if="deliveryMethod === 'Email'" class="py-0">
                <div class="mb-2">Destination:</div>
                <v-text-field
                  label="Recipient Email"
                  v-model="sendToEmail"
                  :required="deliveryMethod !== 'Email'"
                  :rules="[
                    sendToEmail != '' || 'Email is required',
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(sendToEmail) ||
                      'Please enter a valid email address',
                  ]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="closeDeliveryInfo"> Cancel </v-btn>

          <v-btn color="success" text @click="saveDeliveryInfo"> Save and Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="missingRequiredError" :timeout="3000" color="error">
      There are empty fields that need to be completed before saving!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="missingRequiredError = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="saveSuccess" :timeout="3000" color="success">
      Your changes have been saved successfully!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="saveSuccess = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import ServiceDetailsDialog from './auth-components/ServiceDetailsDialog';
import AuthDocuments from './auth-components/AuthDocuments';
import AuthNotifications from './auth-components/AuthNotifications';
import AuthTasks from './auth-components/AuthTasks';
import AuthNotes from './auth-components/AuthNotes';
import ProviderSearch from './auth-components/ProviderSearch';
import TimeSelector from './TimeSelector.vue';

export default {
  name: 'NewAuthorization',
  props: ['authID', 'user'],
  components: {
    ServiceDetailsDialog,
    AuthDocuments,
    AuthTasks,
    AuthNotifications,
    AuthNotes,
    ProviderSearch,
    TimeSelector,
  },
  data() {
    return {
      dialog: false,
      ProviderSearchdisableSaveButton: false,
      loading: false,
      tab: null,
      modalTabs: [
        'Summary',
        'Updates',
        'Documents',
        'Tasks',
        'Notifications',
        'Notes',
        'Additional Questions',
      ],
      encountersData: {},
      originalRecord: {},
      authValid: false,
      reasonsValid: false,
      deliveryValid: false,
      saveChangesDialog: false,
      deliveryInfoDialog: false,
      removeServiceDialog: false,
      exitConfirmationDialog: false,
      assignmentChange: false,
      assignmentUpdates: [],
      assignmentReason: '',
      authorizationChange: false,
      authorizationUpdates: [],
      authorizationReason: '',
      determinationChange: false,
      determinationUpdates: [],
      determinationReason: '',
      assignmentItems: ['Unassigned', 'Nurse', 'MD'],
      assignmentList: [],
      assignmentLoading: false,
      assignmentSearch: null,
      determinationItems: [
        'Pending',
        'Approved',
        'Partial Approval',
        'Not Approved',
        'Withdrawn',
        'Denial',
        'Admin Denial',
      ],
      stateList: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
      serviceType: [
        'Office/Diag Ctr',
        'DME',
        'Home Health',
        'OP Hospital',
        'IP Hospital',
        'Ambulatory Surgery Center',
        'Physical Therapy',
        'Occupational Therapy',
        'Speech Therapy',
        'Part B Drug Request',
      ],
      posCodeList: [],
      serviceStartMenu: false,
      serviceEndMenu: false,
      serviceApptMenu: false,
      serviceDetailsSearch: '',
      servicesHeaders: [
        { text: 'Dx Codes', value: 'DIAG' },
        { text: 'CPT-4/HCPCS Codes', value: 'CPT_HCPCS' },
        { text: 'Service Description', value: 'ServiceDesc' },
        { text: 'Visits', value: 'Visits' },
        { text: 'Units', value: 'Units' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      selectedCode: {},
      selectedTemplate: '',
      documentEffectiveDateMenu: false,
      documentEffectiveDate: '',
      templateItems: [
        'Denial',
        'Partial Approval',
        'Payment',
        'Reduction',
        'Stopped',
        'Suspension',
        'Approval',
      ],
      deliveryMethod: '',
      sendToRequesting: false,
      sendToRendering: false,
      sendToEmail: '',
      missingRequiredError: false,
      saveSuccess: false,
      reqProvStatus: null,
      disableSaveButton: false,
      rendingProv: {},
      requestingProv: {},
      disableRequestingProv: false,
      disableRendingProv: false,
      oralAmPm: 0,
      writtenAmPm: 0,
      WrittenNotificationDateMenu: false,
      OralNotificationDateMenu: false,
      booleanSelect: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
    };
  },
  methods: {
    closeAuthModal() {
      if (this.disableSaveButton === false) {
        this.exitConfirmationDialog = true;
      } else {
        this.dialog = false;
      }
    },
    async getAuthData() {
      // clear and existing updates
      this.authorizationUpdates = [];
      this.determinationUpdates = [];
      this.assignmentUpdates = [];

      await axios.get(`/api/authorizations/encounters/${this.authID}`).then((res) => {
        console.log(res.data[0]);
        this.encountersData = res.data[0];
        // make sure no changes are detected on initial data load
        this.assignmentChange = false;
        this.determinationChange = false;
        // populate user list to prevent errors
        if (this.encountersData.AssignedUser !== null) {
          this.assignmentList.push(this.encountersData.AssignedUser);
        }

        //Create copy of original record to compare for changes
        this.originalRecord = JSON.parse(JSON.stringify(this.encountersData));

        // fill updates based on updateType
        this.encountersData.Updates.forEach((update) => {
          if (update.updateType === 'Auth') {
            this.authorizationUpdates.push(update);
          } else if (update.updateType === 'Det') {
            this.determinationUpdates.push(update);
          } else if (update.updateType === 'Assign') {
            this.assignmentUpdates.push(update);
          }
        });
        this.getPosCodes();
      });
    },
    getPosCodes() {
      axios.get('/api/authorizations/pos/getAll').then((response) => {
        this.posCodeList = response.data[0].POS;
        this.posCodeList.sort();
      });
    },
    async saveChanges() {
      let updateReasons = [];

      // check all possible reason to create fill all reasons for this update
      if (this.authorizationReason !== '') {
        let authReason = {
          updateDate: moment().tz('America/New_York').format('YYYY-MM-DD'),
          updateType: 'Auth',
          reason: this.authorizationReason,
        };
        updateReasons.push(authReason);
      }
      if (this.determinationReason !== '') {
        this.encountersData.DetReason = this.determinationReason;
        let detReason = {
          updateDate: moment().tz('America/New_York').format('YYYY-MM-DD'),
          updateType: 'Det',
          reason: this.determinationReason,
          from: this.originalRecord.Determination,
          to: this.encountersData.Determination,
        };
        updateReasons.push(detReason);
      }
      if (this.assignmentReason !== '') {
        let assignReason = {
          updateDate: moment().tz('America/New_York').format('YYYY-MM-DD'),
          updateType: 'Assign',
          reason: this.assignmentReason,
          from: this.originalRecord.ReviewStatus,
          to: this.encountersData.ReviewStatus,
        };
        updateReasons.push(assignReason);
      }

      // add reasons to master record for updating reasons

      updateReasons.forEach((obj) => {
        this.encountersData.Updates.push(obj);
      });

      // grab the right recipient faxes or emails in order to send
      let allRecipients = [];
      if (this.deliveryMethod === 'Fax') {
        if (this.sendToRequesting) {
          allRecipients.push(this.encountersData.ReqProvFax);
        }
        if (this.sendToRendering) {
          allRecipients.push(this.encountersData.RendPhysFax);
        }
      } else if (this.deliveryMethod === 'Email') {
        allRecipients.push(this.sendToEmail);
      }

      let postData = {
        authData: this.encountersData,
        newReasons: updateReasons,
        recipients: allRecipients,
        template: this.selectedTemplate,
        deliveryMethod: this.deliveryMethod,
      };

      await axios
        .post(
          `/api/authorizations/update/${this.encountersData._id}/${this.documentEffectiveDate}`,
          postData,
        )
        .then((response) => {
          if (response.status === 200) {
            // clear changes and reset original record to compare for changes
            this.originalRecord = JSON.parse(JSON.stringify(this.encountersData));
            this.determinationChange = false;
            this.assignmentChange = false;
            this.authorizationChange = false;

            // push new updates into updates tab
            updateReasons.forEach((update) => {
              if (update.updateType === 'Auth') {
                this.authorizationUpdates.push(update);
              } else if (update.updateType === 'Det') {
                this.determinationUpdates.push(update);
              } else if (update.updateType === 'Assign') {
                this.assignmentUpdates.push(update);
              }
            });
            // trigger snackbar success message
            this.saveSuccess = true;
            // clear reasons dialog
            this.saveChangesDialog = false;
            this.authorizationReason = '';
            this.determinationReason = '';
            this.assignmentReason = '';
            this.deliveryMethod = '';
            // clear delivery dialog
            this.deliveryInfoDialog = false;
            this.selectedTemplate = '';
            this.sendToRequesting = '';
            this.sendToRendering = '';
            //re-disable button
            this.disableSaveButton = true;
            this.exitConfirmationDialog = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openSaveChanges() {
      if (this.$refs.authForm[0].validate()) {
        this.saveChangesDialog = true;
        // Map keys to check for changes and show correct reason boxes
        Object.keys(this.encountersData).forEach((key) => {
          // check for changes in updates/services arrays
          // need to stringify nested objects/arrays before comparing
          if (key === 'Updates' || key === 'Services') {
            if (
              JSON.stringify(this.encountersData[key]) !== JSON.stringify(this.originalRecord[key])
            ) {
              this.authorizationChange = true;
            }
          } else if (key === 'AssignedUser') {
            if (
              JSON.stringify(this.encountersData[key]) !== JSON.stringify(this.originalRecord[key])
            ) {
              this.assignmentChange = true;
            }
          } else if (this.encountersData[key] !== this.originalRecord[key]) {
            // check for types of changes depending on field names
            if (key === 'Determination') {
              this.determinationChange = true;
            } else if (key === 'ReviewStatus') {
              this.assignmentChange = true;
            } else {
              this.authorizationChange = true;
            }
          }
        });
      } else {
        this.missingRequiredError = true;
      }
    },
    closeSaveChanges() {
      this.saveChangesDialog = false;
      this.authorizationReason = '';
      this.determinationReason = '';
      this.assignmentReason = '';
      this.deliveryMethod = '';
    },
    checkDeliveryMethod() {
      if (
        this.$refs.reasonsForm.validate() &&
        (this.deliveryMethod === 'Email' || this.deliveryMethod === 'Fax')
      ) {
        this.saveChangesDialog = false;
        this.deliveryInfoDialog = true;
      } else if (this.$refs.reasonsForm.validate() && this.deliveryMethod === 'None') {
        this.saveChanges();
      }
    },
    closeDeliveryInfo() {
      this.deliveryInfoDialog = false;
      this.saveChangesDialog = true;

      this.selectedTemplate = '';
      this.sendToRequesting = '';
      this.sendToRendering = '';
    },
    saveDeliveryInfo() {
      if (
        this.deliveryMethod === 'Fax' &&
        this.$refs.deliveryForm.validate() &&
        (this.sendToRendering || this.sendToRequesting)
      ) {
        this.saveChanges();
      } else if (this.deliveryMethod === 'Email' && this.$refs.deliveryForm.validate()) {
        this.saveChanges();
      }
    },
    addServiceDetails(value) {
      this.encountersData.Services.push(value);
    },
    openDeleteService(item) {
      this.selectedCode = item;
      this.removeServiceDialog = true;
    },
    deleteService() {
      let itemIndex = this.encountersData.Services.indexOf(this.selectedCode);
      this.encountersData.Services.splice(itemIndex, 1);
      this.removeServiceDialog = false;
    },
    formatDate(date) {
      //added if check to remove console error
      if (typeof date === 'string') {
        return date.substring(0, 10);
      }
    },
    getAge(dateString) {
      let age = moment().diff(dateString, 'years');
      return age;
    },
    authTime(date) {
      //added if check to remove console error
      let authTime = moment(date).format('h:mm a');
      return authTime;
    },
    formatAuthTime(time) {
      let timeConvert = moment(time, 'HH:mm A').format('h:mm a');
      return timeConvert;
    },
    formatRequestingPhone() {
      let x = this.encountersData.ReqProvPhone.replace(/\D/g, '').match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/,
      );
      this.encountersData.ReqProvPhone = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    formatServicePhone() {
      let x = this.encountersData.RendPhysPhone.replace(/\D/g, '').match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/,
      );
      this.encountersData.RendPhysPhone = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    formatRequestingFax() {
      let x = this.encountersData.ReqProvFax.replace(/\D/g, '').match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/,
      );
      this.encountersData.ReqProvFax = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    formatServiceFax() {
      let x = this.encountersData.RendPhysFax.replace(/\D/g, '').match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/,
      );
      this.encountersData.RendPhysFax = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    clearAssigned() {
      if (this.encountersData.ReviewStatus !== undefined) {
        this.encountersData.AssignedUser = {
          fullName: '',
          userID: '',
          email: '',
        };
      }
    },
    loadRendingProv(provider) {
      //sets all field in the rending provider after a user selects a provider to use
      //will also disable fields so the user cannot change info on an existing provider....
      this.encountersData.RendPhysName = provider.FullName;
      this.encountersData.RendProvGroup = provider.group;
      this.encountersData.RendProvGroupNPI = provider.rendGroupNPI;
      this.encountersData.RendPhysFax = provider.Fax;
      this.encountersData.RendPhysPhone = provider.Phone;
      this.encountersData.RendBillingTIN = provider.BillingProviderTaxID;
      this.encountersData.RendPhysNPI = provider.NPI;
      this.encountersData.RendPhysCity = provider.city;
      this.encountersData.RendPhysState = provider.state;
      this.encountersData.RendPhysZip = provider.zip;
      this.encountersData.RendPhysAddress = provider.address;
      this.disableRendingProv = true;
    },
    loadRequestingProv(provider) {
      //sets all fields for requesting provider after selection,
      //will also disable fields so the user cannot change info on an existing provider....
      this.encountersData.ReqProviderName = provider.FullName;
      this.encountersData.ReqProvFax = provider.Fax;
      this.encountersData.ReqProvPhone = provider.Phone;
      this.encountersData.ReqProviderNPI = provider.NPI;
      this.encountersData.ReqContactTitle = provider.Degree;
      this.encountersData.ReqContactName = provider.NAME;
      this.encountersData.ReqProvTIN = provider.BillingProviderTaxID;
      this.disableRequestingProv = true;
    },
  },
  watch: {
    dialog() {
      if (this.dialog === true) {
        this.getAuthData();
      }
    },
    assignmentSearch() {
      // Lazily load input items
      this.assignmentLoading = true;

      // serach at [0] to get list -> let autocomplete handle filtering as you type
      axios
        .get(`/api/search/users/${this.encountersData.ReviewStatus}/${this.assignmentSearch[0]}`)
        .then((response) => {
          this.assignmentList = response.data;
          this.assignmentLoading = false;
        });
    },
    reqProvSearch() {
      this.getReqProviderList();
    },
    encountersData: {
      handler() {
        if (JSON.stringify(this.encountersData) === JSON.stringify(this.originalRecord)) {
          this.disableSaveButton = true;
        } else {
          this.disableSaveButton = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
