<template>
  <v-container>
    <span class="display-1">Provider Reports</span>
    <v-row class="mt-3">
      <!-- <v-col cols="3">
        <v-select
          v-model="reportParams.Status"
          label="Status"
          :items="statusList"
          dense
          outlined
          clearable
        />
      </v-col> -->
      <v-col cols="4">
        <v-autocomplete
          v-model="reportParams.state"
          label="State"
          :items="stateList"
          @change="getCountyList(); reportParams.county = ''"
          dense
          outlined
        />
      </v-col>
      <v-fade-transition>
        <v-col cols="4">
          <v-autocomplete
            v-model="reportParams.county"
            label="County"
            :items="countyList"
            dense
            outlined
            clearable
          />
        </v-col>
      </v-fade-transition>
      <v-col cols="3">
        <v-btn
          @click="
            getReport()
          "
          color="primary"
        >
          Submit
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row v-show="tableData.length" class="my-1 justify-center">
        <v-col cols="4" class="text-center">
          <v-chip
            class="px-5 text-h7 large"
            outlined
            color="primary"
          >
            <span>{{ displayState }}</span>
            <v-icon class="pl-2">mdi-archive-marker-outline</v-icon>
          </v-chip>
          <div class="pt-2 text-h7">State</div>
        </v-col>
        <v-slide-y-transition>
        <v-col cols="4" v-if="showProviders" class="text-center">
          <v-chip
            class="px-5 text-h7 large"
            outlined
            color="primary"
          >
            <span>{{ displayCounty }}</span>
            <v-icon class="pl-2">mdi-map-marker-outline</v-icon>
          </v-chip>
          <div class="pt-2 text-h7">County</div>
        </v-col>
      </v-slide-y-transition>
        <v-col cols="4" class="text-center">
          <v-chip
            class="px-5 text-h7 large"
            outlined
            color="primary"
          >
            <span>{{ totalProviders }}</span>
            <v-icon class="pl-2">mdi-heart-settings-outline</v-icon>
          </v-chip>
          <div class="pt-2 text-h7">Total Providers</div>
        </v-col>
        <!-- <template v-if="parProviders">
          <v-col class="text-center">
            <v-chip class="px-5 text-h7" outlined color="primary">
              <span>{{ parProviders }}</span>
              <v-icon class="pl-2">mdi-heart-plus-outline</v-icon>
            </v-chip>
            <div class="pt-2 text-h7">Par Providers</div>
          </v-col>
          <v-col class="text-center">
            <v-chip class="px-5 text-h7" outlined color="primary">
              <span>{{ nonParProviders }}</span>
              <v-icon class="pl-2">mdi-heart-remove-outline</v-icon>
            </v-chip>
            <div class="pt-2 text-h7">Non-Par Providers</div>
          </v-col>
        </template> -->
      </v-row>
    </v-expand-transition>
    <v-data-table
      :items="tableData"
      :loading="loading"
      :headers="headers"
      v-if="tableData"
      multi-sort
      loading-text="Loading data..."
      class="elevation-0 col-md-12 m-auto"
    >
      <template v-slot:item.view="{ item }">
        <v-btn
          v-model="item.view"
          @click="
            reportParams.county = item._id
            showProviders = true
            getReport()
          "
          color="primary"
          icon
        >
          <v-icon>mdi-table-eye</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.cred="{ item }">
        <credentialing-modal
          :npiNumber="item.RenderingNPI"
          :isFacility="false"
          @recordUpdated="getReport"
          :user="user"
          source="ProviderReports"
        />
      </template>
      <template v-if="!loading && showProviders" v-slot:item.Status="{ item }">
        {{ parHelper(item.Locations) }}
      </template>
    </v-data-table>
    <p class="subtitle-2 font-weight-thin ml-2">
          Please select Submit to see report.
    </p>
  </v-container>
</template>

<script>
import axios from "axios"
import CredentialingModal from "../components/CredentialingModal"

// The general breakdown will be this:
// Filter by state and then show all counties in that state and the provider count per county in the table.
// If I then filter by County then I should see a list of all the providers in that county with an address, center name, phone, etc.
// I feel like this would be the most useful for them since they can then search the table or export it if they need to.

export default {
  name: "ProviderReports",
  components: { CredentialingModal },
  data() {
    return {
      loading: false,
      showProviders: false,
      user: "",
      totalProviders: 0,
      parProviders: 0,
      nonParProviders: 0,
      tableData: [],
      countyList: [],
      displayState: "",
      displayCounty: "",
      stateList: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      statusList: [
        { text: "Par", value: "Active" },
        { text: "Non-Par", value: "Inactive" },
      ],
      reportParams: {
        state: "GA",
        Status: "Active",
      },
    }
  },
  computed: {
    headers() {
      if (this.showProviders) {
        return [
          { text: "Provider Name", value: "RenderingProviderFullName" },
          { text: "NPI", value: "RenderingNPI" },
          { text: "Status", value: "Status"},
          { text: "View Provider", value: "cred" },
        ]
      } else {
        return [
          { text: "County", value: "_id" },
          { text: "Total Providers", value: "count" },
          { text: "View Providers", value: "view" },
        ]
      }
    },
  },
  methods: {
    async getUser() {
      await axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0]
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment")
          }
        })
        .catch(() => {
          this.$router.push("/login")
        })
    },
    async getReport() {
      this.loading = true
      this.reportParams.county
        ? (this.showProviders = true)
        : (this.showProviders = false)
      this.displayState = this.reportParams.state
      this.displayCounty = this.reportParams.county
      if (this.showProviders) {
        await axios
          .get(
            `api/credentialing/providerReports/${JSON.stringify(
              this.reportParams
            )}`
          )
          .then((res) => {
            this.tableData = res.data
            this.loading = false
          })
      } else {
        await axios
          .get(`api/credentialing/reports/${JSON.stringify(this.reportParams)}`)
          .then((res) => {
            this.tableData = res.data.countyData
            this.totalProviders = res.data.totalData
            this.parProviders = res.data.parData
            this.nonParProviders = res.data.nonParData

            // Preserve Current County List, if searching for
            // if (res.data.countyList.length > 1) {
            //   this.countyList = res.data.countyList
            // }

            this.loading = false
          }).catch((error) => {
            console.log(error)
          })
      }
    },
    async getCountyList() {
      let test = await axios.get(`api/credentialing/countyList/${this.reportParams.state}`)
      this.countyList = test.data.countyList
    },
    parHelper(locationsArr) {
      const isActive = locationsArr.some((current) => {
        return current.Status === "Active"
      })
      return isActive ? "Par" : "Non-Par"
    },
  },
  mounted() {
    this.getUser()
    this.getCountyList()
  },
}
</script>
