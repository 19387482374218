<template>
    <v-dialog v-model="addRevenueDialog" max-width="900px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" class="mb-5"><v-icon>mdi-plus</v-icon>Add Revenue</v-btn>
        </template>
        <v-card>
            <v-card-title>Add Revenue</v-card-title>
            <v-form ref="revenueForm" class="pa-3" lazy-validation>
                <v-row>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.revenueCode"
                            label="Revenue Code"
                            outlined
                            dense
                            required
                            :rules="[formData.revenueCode != '' || 'Please enter Revenue Code']"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            label="Diagnosis Code"
                            v-model="formData.revenueDiagnosisCode"
                            :items="diagnosisItems"
                            :loading="diagnosisLoading"
                            :search-input.sync="diagnosisSearch"
                            item-text="CODE"
                            clearable
                            required
                            :rules="[formData.revenueDiagnosisCode != '' || 'Diagnosis Code is required']"
                            outlined
                            dense
                        >
                        <template v-slot:no-data>
                            <v-list-item>
                            <v-list-item-title>
                                Search for a diagnosis code
                            </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-content>
                            <v-list-item-title v-text="item.CODE"></v-list-item-title>
                            <v-list-item-subtitle v-text="item.DESCRIPTION"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            label="HCPCS/HIPPS"
                            v-model="formData.HCPCS"
                            :items="hcpcsItems"
                            :loading="hcpcsLoading"
                            :search-input.sync="hcpcsSearch"
                            item-text="CODE"
                            clearable
                            required
                            :rules="[formData.HCPCS != '' || 'HCPCS/HIPPS Code is required']"
                            outlined
                            dense
                        >
                        <template v-slot:no-data>
                            <v-list-item>
                            <v-list-item-title>
                                Search for a HCPCS/HIPPS code
                            </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-content>
                            <v-list-item-title v-text="item.CODE"></v-list-item-title>
                            <v-list-item-subtitle v-text="item.DESCRIPTION"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.visits"
                            label="Visits"
                            outlined
                            dense
                            required
                            :rules="[formData.visits != '' || 'Please enter Visits']"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.revenueUnits"
                            label="Units"
                            outlined
                            dense
                            required
                            :rules="[formData.revenueUnits != '' || 'Please enter Units']"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.revenueMods"
                            label="Mods"
                            outlined
                            dense
                            required
                            :rules="[formData.revenueMods != '' || 'Please enter Mods']"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox v-model="formData.POA" label="Present on Admission"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formData.revenueServiceStartDate"
                                    label="Service Start Date"
                                    append-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    :rules="[formData.revenueServiceStartDate != '' || 'Please enter the Service Start Date']"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="formData.revenueServiceStartDate"
                                min="1900-01-01"
                            >
                                <v-spacer></v-spacer>
                                <v-btn outlined color="primary" @click="menu1 = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed
                                    color="primary"
                                    @click="$refs.menu1.save(formData.revenueServiceStartDate)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formData.revenueServiceEndDate"
                                    label="Service End Date"
                                    append-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    :rules="[formData.revenueServiceEndDate != '' || 'Please enter the Service End Date']"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="formData.revenueServiceEndDate"
                                min="1900-01-01"
                            >
                                <v-spacer></v-spacer>
                                <v-btn outlined color="primary" @click="menu2 = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed
                                    color="primary"
                                    @click="$refs.menu2.save(formData.revenueServiceEndDate)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="addRevenueDialog = false" text>Cancel</v-btn>
                <v-btn
                    @click="validateRevenue"
                    color="accent"
                    text
                >
                    Add
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            addRevenueDialog: false,
            formData: {
                revenueServiceStartDate: "",
                revenueServiceEndDate: "",
                revenueCode: "",
                HCPCS: "",
                revenueMods: "",
                revenueUnits: "",
                visits: "",
                revenueDiagnosisCode: "",
                POA: false,
            },
            menu1: false,
            menu2: false,
            revenueValid: false,
            diagnosisItems: [],
            diagnosisLoading: false,
            diagnosisSearch: null,
            hcpcsItems: [],
            hcpcsLoading: false,
            hcpcsSearch: null,
        }
    },
    methods: {
        validateRevenue() {
            if (this.$refs.revenueForm.validate()) {
                this.$emit('revenue', this.formData)
                this.formData = {
                    revenueServiceStartDate: "",
                    revenueServiceEndDate: "",
                    revenueCode: "",
                    HCPCS: "",
                    revenueMods: "",
                    revenueUnits: "",
                    visits: "",
                    revenueDiagnosisCode: "",
                    POA: false,
                }
                this.addRevenueDialog = false
            }
        },
    },
    watch: {
        diagnosisSearch() {
            this.diagnosisLoading = true;
            axios
                .get(`/api/search/codes/diagnosis/${this.diagnosisSearch}`)
                .then(response => {
                this.diagnosisItems = response.data;
                this.diagnosisLoading = false;
                })
        },
        hcpcsSearch() {
            this.hcpcsLoading = true;
            axios
                .get(`/api/search/codes/service/${this.hcpcsSearch}`)
                .then(response => {
                this.hcpcsItems = response.data;
                this.hcpcsLoading = false;
                })
        },
    }
}
</script>

<style scoped>

</style>