var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",staticClass:"pa-3",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.exerciseArray,"label":"How often do you exercise for at least 20 minutes in a day?","required":"","outlined":"","dense":"","rules":[this.dailyExercise != '' || 'Please make a selection']},model:{value:(_vm.dailyExercise),callback:function ($$v) {_vm.dailyExercise=$$v},expression:"dailyExercise"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":['Yes', 'No'],"label":"In the past 2 weeks, have you had a change in your sleeping\n          patterns, or are you having trouble obtaining restful\n          sleep?","required":"","outlined":"","dense":"","rules":[
            this.sleepingPatterns != '' || 'Please make a selection',
          ]},model:{value:(_vm.sleepingPatterns),callback:function ($$v) {_vm.sleepingPatterns=$$v},expression:"sleepingPatterns"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"items":['Yes', 'No'],"label":"Are you on a special diet recommended by your doctor (low sodium, cholesterol, fat)?","required":"","outlined":"","dense":"","rules":[this.specialDiet != '' || 'Please make a selection']},model:{value:(_vm.specialDiet),callback:function ($$v) {_vm.specialDiet=$$v},expression:"specialDiet"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"If yes, what type of diet?","outlined":"","dense":""},model:{value:(_vm.specialDietOther),callback:function ($$v) {_vm.specialDietOther=$$v},expression:"specialDietOther"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.dietArray,"label":"How often do you eat fresh fruits or vegetables?","required":"","outlined":"","dense":"","rules":[
            this.fruitVegitableConsumption != '' ||
              'Please make a selection',
          ]},model:{value:(_vm.fruitVegitableConsumption),callback:function ($$v) {_vm.fruitVegitableConsumption=$$v},expression:"fruitVegitableConsumption"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.dietArray,"label":"How often do you eat fried or high fat foods (fried fish/checken, bacon, potato chips)?","required":"","outlined":"","dense":"","rules":[this.highFatFoods !== '' || 'Please make a selection']},model:{value:(_vm.highFatFoods),callback:function ($$v) {_vm.highFatFoods=$$v},expression:"highFatFoods"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.dietArray,"label":"How often do you drink alcohol?","required":"","outlined":"","dense":"","rules":[this.alcohol != '' || 'Please make a selection']},model:{value:(_vm.alcohol),callback:function ($$v) {_vm.alcohol=$$v},expression:"alcohol"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.yesNoNaArray,"label":"Have you ever felt you should reduce your alcohol\n          consumption?","required":"","outlined":"","dense":"","rules":[this.reduceAlcohol != '' || 'Please make a selection']},model:{value:(_vm.reduceAlcohol),callback:function ($$v) {_vm.reduceAlcohol=$$v},expression:"reduceAlcohol"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.smokingArray,"label":"When was the last time you smoked or used any tobacco product (cigarettes, chew, snuff, pipes, cigars, vapor cigarettes)?","required":"","outlined":"","dense":"","rules":[this.lastTimeSmoked != '' || 'Please make a selection']},model:{value:(_vm.lastTimeSmoked),callback:function ($$v) {_vm.lastTimeSmoked=$$v},expression:"lastTimeSmoked"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.yesNoNaArray,"label":"Are you interested in quitting smoking?","required":"","outlined":"","dense":"","rules":[
            this.interestedInQuittingSmoking != '' ||
              'Please make a selection',
          ]},model:{value:(_vm.interestedInQuittingSmoking),callback:function ($$v) {_vm.interestedInQuittingSmoking=$$v},expression:"interestedInQuittingSmoking"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":['0', '1 time', '2 times', '3 or more times'],"label":"In the past year, how many times have you used an illegal drug or\n          prescription medication for non-medical reasons?","required":"","outlined":"","dense":"","rules":[this.illegalDrugUse != '' || 'Please make a selection']},model:{value:(_vm.illegalDrugUse),callback:function ($$v) {_vm.illegalDrugUse=$$v},expression:"illegalDrugUse"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"items":['Yes', 'No'],"label":"Do you have any hobbies or interests?","required":"","outlined":"","dense":"","rules":[
            this.hobbiesOrInterests != '' || 'Please make a selection',
          ]},model:{value:(_vm.hobbiesOrInterests),callback:function ($$v) {_vm.hobbiesOrInterests=$$v},expression:"hobbiesOrInterests"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"If yes, please list","outlined":"","dense":""},model:{value:(_vm.hobbies),callback:function ($$v) {_vm.hobbies=$$v},expression:"hobbies"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right pb-0"},[_c('v-btn',{attrs:{"text":"","disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.validate}},[_vm._v(" Next "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }