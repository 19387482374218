var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-folder-plus")]),_vm._v(" Create Task ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5 mb-2"},[_vm._v("New Task")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Task Type","items":_vm.typeList,"required":"","rules":[
                _vm.taskType != '' || 'Type is required'
              ],"outlined":"","dense":""},model:{value:(_vm.taskType),callback:function ($$v) {_vm.taskType=$$v},expression:"taskType"}})],1),(_vm.showTaskAuthId)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"readonly":_vm.section === 'authorization',"label":"Task Auth ID","outlined":"","dense":""},model:{value:(_vm.taskAuthID),callback:function ($$v) {_vm.taskAuthID=$$v},expression:"taskAuthID"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('auth-search',{attrs:{"buttonDisabled":_vm.section == 'authorization'},on:{"authSelected":_vm.setAuthID}})],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Assign User","items":_vm.assignmentList,"loading":_vm.assignmentLoading,"search-input":_vm.assignmentSearch,"readonly":_vm.section === 'authorization',"item-text":"fullName","return-object":"","clearable":"","required":"","rules":[
                _vm.assignedUser != null || 'Assignment is required'
              ],"outlined":"","dense":""},on:{"update:searchInput":function($event){_vm.assignmentSearch=$event},"update:search-input":function($event){_vm.assignmentSearch=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for users ")])],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.fullName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.userID))])],1)]}}]),model:{value:(_vm.assignedUser),callback:function ($$v) {_vm.assignedUser=$$v},expression:"assignedUser"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Task Priority "),_c('v-radio-group',{attrs:{"required":"","rules":[
                _vm.taskPriority != '' ||
                  'Priority is required',
              ],"row":""},model:{value:(_vm.taskPriority),callback:function ($$v) {_vm.taskPriority=$$v},expression:"taskPriority"}},[_c('v-radio',{attrs:{"label":"Low","value":"Low"}}),_c('v-radio',{attrs:{"label":"Medium","value":"Medium"}}),_c('v-radio',{attrs:{"label":"High","value":"High"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Please enter the task objective","counter":255,"dense":"","outlined":"","rules":[
                _vm.taskObjective != '' || 'Objective is required',
                v => v.length <= 255|| 'Max 255 characters'
              ]},model:{value:(_vm.taskObjective),callback:function ($$v) {_vm.taskObjective=$$v},expression:"taskObjective"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.clearTask()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }