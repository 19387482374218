<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-row>
            <v-col>
              <v-card-title>Submit Claim</v-card-title>
            </v-col>
          </v-row>
          <v-row justify="center" class="pt-5">
            <v-col cols="12" md="10">
              <v-stepper v-model="step">
                <v-stepper-header>
                  <v-stepper-step :complete="step > 1" step="1">
                    Services/Revenue
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 2" step="2">
                    Claim Details
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 3" step="3">
                    Review and Submit
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-col cols="12">
                      <v-item-group
                        v-model="claimToggle"
                        active-class="primary secondary--text"
                      >
                        <v-row justify="center">
                          <v-col md="3">
                            <v-item
                              v-slot="{ active, toggle }"
                              :value="'Institutional'"
                            >
                              <v-card
                                outlined
                                :class="active ? 'accent white--text' : ''"
                                class="d-flex align-center"
                                height="100"
                                width="200"
                                @click="toggle"
                              >
                                <div class="text-h6 flex-grow-1 text-center">
                                  <v-icon
                                    large
                                    class="pa-3"
                                    :color="active ? 'white' : ''"
                                  >
                                    mdi-hospital-building
                                  </v-icon>
                                  <v-spacer></v-spacer>
                                  Institutional Claim
                                </div>
                              </v-card>
                            </v-item>
                          </v-col>
                          <v-col md="3">
                            <v-item
                              v-slot="{ active, toggle }"
                              :value="'Professional'"
                            >
                              <v-card
                                outlined
                                :class="active ? 'accent white--text' : ''"
                                class="d-flex align-center"
                                height="100"
                                width="200"
                                @click="toggle"
                              >
                                <div class="text-h6 flex-grow-1 text-center">
                                  <v-icon
                                    large
                                    class="pa-3"
                                    :color="active ? 'white' : ''"
                                  >
                                    mdi-doctor
                                  </v-icon>
                                  <v-spacer></v-spacer>
                                  Professional Claim
                                </div>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </v-col>
                    <v-card-title>Services</v-card-title>
                    <add-service @service="addedService" />
                    <v-row v-if="services && services.length > 0">
                      <v-col
                        cols="6"
                        v-for="(service, index) in services"
                        :key="index"
                      >
                        <v-card flat outlined class="ma-3">
                          <div class="ma-4">
                            <v-row>
                              <v-col cols="6">
                                <span class="font-weight-bold"
                                  >Service Procedure Code:
                                </span>
                                {{ service.serviceProcedureCode }}
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold"
                                  >Diagnosis Code:
                                </span>
                                {{ service.diagnosisCode }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <span class="font-weight-bold">POS: </span>
                                {{ service.POS }}
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold">TOS: </span>
                                {{ service.TOS }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <span class="font-weight-bold">Charges: </span>
                                {{ service.charges }}
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold">Units: </span>
                                {{ service.units }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <span class="font-weight-bold"
                                  >Service Start Date:
                                </span>
                                {{ service.serviceStartDate }}
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold"
                                  >Service End Date:
                                </span>
                                {{ service.serviceEndDate }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <span class="font-weight-bold"
                                  >Service Mods:
                                </span>
                                {{ service.serviceMods }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    <div v-if="claimToggle === 'Institutional'">
                      <v-divider></v-divider>
                      <v-card-title>Revenues</v-card-title>
                      <add-revenue @revenue="addedRevenue" />
                      <v-row v-if="revenues && revenues.length > 0">
                        <v-col
                          cols="6"
                          v-for="(revenue, index) in revenues"
                          :key="index"
                        >
                          <v-card flat outlined class="ma-3">
                            <div class="ma-4">
                              <v-row>
                                <v-col cols="6">
                                  <span class="font-weight-bold"
                                    >Revenue Code:
                                  </span>
                                  {{ revenue.revenueCode }}
                                </v-col>
                                <v-col cols="6">
                                  <span class="font-weight-bold"
                                    >Revenue Diagnosis Code:
                                  </span>
                                  {{ revenue.revenueDiagnosisCode }}
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <span class="font-weight-bold">HCPCS: </span>
                                  {{ revenue.HCPCS }}
                                </v-col>
                                <v-col cols="6">
                                  <span class="font-weight-bold">POA: </span>
                                  <span v-if="revenue.POA">
                                    <v-icon class="pb-1" color="success"
                                      >mdi-check</v-icon
                                    >
                                  </span>
                                  <span v-else>
                                    <v-icon class="pb-1" color="error"
                                      >mdi-close</v-icon
                                    >
                                  </span>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <span class="font-weight-bold">Visits: </span>
                                  {{ revenue.visits }}
                                </v-col>
                                <v-col cols="6">
                                  <span class="font-weight-bold">Units: </span>
                                  {{ revenue.revenueUnits }}
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <span class="font-weight-bold"
                                    >Revenue Start Date:
                                  </span>
                                  {{ revenue.revenueServiceStartDate }}
                                </v-col>
                                <v-col cols="6">
                                  <span class="font-weight-bold"
                                    >Revenue End Date:
                                  </span>
                                  {{ revenue.revenueServiceEndDate }}
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <span class="font-weight-bold"
                                    >Revenue Mods:
                                  </span>
                                  {{ revenue.revenueMods }}
                                </v-col>
                              </v-row>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="validateStepOne"
                          color="accent"
                          depressed
                        >
                          Continue
                          <v-icon class="ml-2">mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-card-title>Claim Info</v-card-title>
                    <claim-details
                      @details="addedDetails"
                      @backOne="backOne"
                    ></claim-details>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-card-title>Review Claim</v-card-title>
                    <review-claim
                      :claim="claim"
                      @backOne="backOne"
                      @submitClaim="submitClaim"
                    ></review-claim>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
// import Signature from '../components/new-enrollment-components/Signature.vue';
import AddService from "../components/claim-processing-components/AddService.vue";
import AddRevenue from "../components/claim-processing-components/AddRevenue.vue";
import ClaimDetails from "../components/claim-processing-components/ClaimDetails.vue";
import ReviewClaim from "../components/claim-processing-components/ReviewClaim.vue";
export default {
  components: {
    // Signature,
    AddService,
    AddRevenue,
    ClaimDetails,
    ReviewClaim,
  },
  data() {
    return {
      claimToggle: "Institutional",
      singleExpand: false,
      stepOneValid: false,
      stepTwoValid: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      step: 1,
      services: [],
      revenues: [],
      claim: {},
      formData: {
        claimID: "",
        claimStatus: "",
        patientID: "",
        gender: "",
        DOB: "",
        taxID: "",
        medicareNumber: "",
        providerNPI: "",
        providerState: "",
        providerZip: "",
        remittanceAddress: "",
        remittanceCity: "",
        remittanceState: "",
        remittanceZip: "",
        signature: "",
        email: "",
        taxonomy: "",
        ambulancePickUpZip: "",
        totalCharge: "",
        baseAllowed: "",
        customRate: "",
        patientZip: "",
        authorizationID: "",
        sequesterAdjustment: "",
        readmissionReductionProgram: "",
        valueBasedPurchasingProgram: "",
        mods: "",
        serviceProcedureCode: "",
        POS: "",
        TOS: "",
        diagnosisCode: "",
        serviceMods: "",
        units: "",
        charges: "",
        serviceStartDate: "",
        serviceEndDate: "",
        revenueServiceStartDate: "",
        revenueServiceEndDate: "",
        revenueCode: "",
        HCPCS: "",
        revenueMods: "",
        revenueUnits: "",
        visits: "",
        revenueDiagnosisCode: "",
        POA: false,
        authorizations: [],
      },
      statuses: [
        "New",
        "Ready To Price",
        "Priced",
        "Waiting on Funding",
        "Need Authorization",
        "Paid",
        "Reprice Needed",
        "Disputed Claim",
      ],
      genders: ["Male", "Female"],
      serviceTypes: [
        "Office/Diag Ctr",
        "DME",
        "Home Health",
        "OP Hospital",
        "IP Hospital",
        "Ambulatory Surgery Center",
        "Physical Therapy",
        "Occupational Therapy",
        "Speech Therapy",
        "Part B Drug Request",
      ],
      posTypes: [
        { text: "00 - Not Supplied", value: "00" },
        { text: "01 - Pharmacy", value: "01" },
        {
          text: "02 - Telehealth Provided Other than in Patient’s Home",
          value: "02",
        },
        { text: "03 - School", value: "03" },
        { text: "04 - Homeless Shelter", value: "04" },
        {
          text: "05 - Indian Health Services Free-Standing Facility",
          value: "05",
        },
        {
          text: "06 - Indian Health Service Provider-based Facility",
          value: "06",
        },
        { text: "07 - Tribal 638 Free-standing Facility", value: "07" },
        { text: "08 - Tribal 638 Provider-based Facility", value: "08" },
        { text: "09 - Prison/Correctional Facility", value: "09" },
        { text: "10 - Telehealth Provided in Patient’s Home", value: "10" },
        { text: "11 - Office", value: "11" },
        { text: "12 - Home", value: "21" },
        { text: "13 - Assisted Living Facility", value: "13" },
        { text: "14 - Group Home", value: "14" },
        { text: "15 - Mobile Unit", value: "15" },
        { text: "16 - Temporary Lodging", value: "16" },
        { text: "17 - Walk-in Retail Health Clinic", value: "17" },
        { text: "18 - Place of Employment Worksite", value: "18" },
        { text: "19 - Off Campus Outpatient Hospital", value: "19" },
        { text: "20 - Urgent Care Facility", value: "20" },
        { text: "21 - Inpatient Hospital", value: "21" },
        { text: "22 - On Campus Outpatient Hospital", value: "22" },
        { text: "23 - Emergency Room Hospital", value: "23" },
        { text: "24 - Abulatory Surgical Center", value: "24" },
        { text: "25 - Birthing Center", value: "25" },
        { text: "26 - Military Treatment Facility", value: "26" },
        { text: "31 - Skilled Nursing Facility", value: "31" },
        { text: "32 - Nursing Facility", value: "32" },
        { text: "33 - Custodial Care Facility", value: "33" },
        { text: "34 - Hospice", value: "34" },
        { text: "41 - Ambulance - Land", value: "41" },
        { text: "42 - Ambulance - Air or Water", value: "42" },
        { text: "49 - Independent Clinic", value: "49" },
        { text: "50 - Federally Qualified Health Center", value: "50" },
        { text: "51 - Inpatient Psychiatric Facility", value: "51" },
        {
          text: "52 - Psychiatric Facility - Partial Hospitalization",
          value: "52",
        },
        { text: "53 - Community Mental Health Center", value: "53" },
        {
          text: "54 - Intermediate Care Facility/Individuals with Intellectual Disabilities",
          value: "54",
        },
        {
          text: "55 - Residential Substance Abuse Treatment Facility",
          value: "55",
        },
        { text: "56 - Psychiatric Residential Treatment Center", value: "56" },
        {
          text: "57 - Non-residential Substance Abuse Treatment Facility",
          value: "57",
        },
        { text: "58 - Non-residential Opiod Treatment Facility", value: "58" },
        { text: "60 - Mass Immunization Center", value: "60" },
        {
          text: "61 - Comprehensive Inpatient Rehabilitation Facility",
          value: "61",
        },
        {
          text: "62 - Comprehensive Outpatient Rehabilitation Facility",
          value: "62",
        },
        {
          text: "65 - End Stage Renal Disease Treatment Facility",
          value: "65",
        },
        { text: "71 - Public Health Clinic", value: "71" },
        { text: "72 - Rural Health Clinic", value: "72" },
        { text: "81 - Independent Laboratory", value: "81" },
        { text: "99 - Other Place of Service", value: "99" },
      ],
      authModal: false,
      searchAuthLoad: false,
      memberIDSearch: "",
      authData: [],
      selectedAuths: [],
      authDataHeaders: [
        { text: "Member Name", value: "MemberName" },
        { text: "Authorization ID", value: "AuthID" },
        { text: "Receive Date", value: "ReceiveDate" },
        { text: "Determination", value: "Determination" },
        { text: "Services", value: "Services" },
        { text: "", value: "data-table-expand" },
      ],
      alert: false,
    };
  },
  methods: {
    backOne() {
      this.step--;
    },
    validateStepOne() {
      // if (this.$refs.formStepOne.validate()) {
      //     this.step = 2
      // }

      this.step = 2;
    },
    async validateStepTwo() {
      // if (this.$refs.formStepTwo.validate()) {
      //     this.formData.signature = await this.$refs.Signature.submit();
      //     if (this.formData.signature !== undefined) {
      //         this.step = 3
      //     } else {
      //         this.alert = true;
      //     }
      // }
      this.step = 3;
    },
    validateStepThree() {
      // if (this.$refs.formStepThree.validate()) {
      //     this.submitClaim()
      // }
      this.submitClaim();
    },
    submitClaim() {},
    searchAuthModal() {
      this.authModal = true;
    },
    async searchAuths() {
      this.searchAuthLoad = true;
      axios
        .get(
          `/api/authorizations/getMemberAuthorizations/${this.memberIDSearch}`
        )
        .then((response) => {
          console.log(response);
          this.authData = response.data;
          this.searchAuthLoad = false;
        });
    },
    saveSelectedAuth() {
      this.formData.authorizations = this.selectedAuths;
      // Checking to see if any of the services in the selected auths match up with the codes input on the claim, if so we auto fill the information we can

      this.selectedAuths.forEach((auth) => {
        auth.Services.forEach((service) => {
          if (
            service.CPT_HCPCS === this.formData.HCPCS &&
            service.DIAG === this.formData.diagnosisCode &&
            service.Visits === this.formData.visits &&
            service.Units === this.formData.units
          ) {
            this.formData.patientID = this.formData.authorizations[0].SonderID;
            this.formData.authorizationID =
              this.formData.authorizations[0].AuthID;
            this.formData.DOB = this.formData.authorizations[0].DOB.slice(
              0,
              10
            );
            this.formData.taxID =
              this.formData.authorizations[0].RendBillingTIN;
            this.formData.providerNPI =
              this.formData.authorizations[0].RendPhysNPI;
            this.formData.remittanceAddress =
              this.formData.authorizations[0].RendPhysAddress;
            this.formData.remittanceCity =
              this.formData.authorizations[0].RendPhysCity;
            this.formData.remittanceState =
              this.formData.authorizations[0].RendPhysState;
            this.formData.remittanceZip =
              this.formData.authorizations[0].RendPhysZip;
          }
        });
      });

      this.authModal = false;
    },
    addedService(service) {
      this.services.unshift(service);
    },
    addedRevenue(revenue) {
      this.revenues.unshift(revenue);
    },
    addedDetails(details) {
      this.claim = details;
      // create services and revenues arrays
      this.claim.services = this.services;
      this.claim.revenues = this.revenues;
      this.step = 3;
    },
  },
};
</script>

<style scoped></style>
