<template>
  <div>
    <v-container>
      <v-form ref="form" lazy-validation v-model="valid">
        <v-row class="d-flex mb-6 justify-center mt-5">
          <v-btn large color="warning" @click="skip()">Skip</v-btn>
        </v-row>
        <v-row>
          <h3 class="justify-start ma-3 fon-weight-black">
            CHRONIC SPECIAL NEEDS PLAN (CSNP) PRE-QUALIFICATION FORM
          </h3>
        </v-row>

        <v-row class="mb-2">
          <v-col>
            Sonder Health Plans offers Special Needs Plans (SNPs) designed for
            people with certain chronic or disabling conditions, Diabetes and
            Cardiovascular Disease (CVD). You may be eligible to join one of our
            chronic-care SNPs if you can answer YES to any of the questions
            below. We will verify the presence of the chronic condition with
            your health care provider within 30 days of enrollment. We are
            required to disenroll you from the special needs plan if we are
            unable to verify your chronic condition. It is very important,
            therefore, that you let your doctor know that we will require their
            verification of the information below and that you provide us with
            accurate contact information for your doctor or other health care
            provider on this form.
          </v-col>
        </v-row>
        <v-row>
          <h3 class="justify-start ma-3 fon-weight-black">
            DO YOU HAVE CARDIOVASCULAR DISEASE (CVD) OR DIABETES?
          </h3>
        </v-row>
        <v-row>
          <v-col
            >Has your doctor or other licensed health-care professional
            diagnosed you with any of the following medical conditions?</v-col
          >
        </v-row>
        <v-row>
          <v-col>
            <v-row class="my-2"> Cardiovascular Disease (CVD): </v-row>
            <v-radio-group
              required
              v-model="cvdStatus"
              :rules="[
                this.cvdStatus != null || 'Please select your CVD status',
              ]"
            >
              <v-radio label="Yes" :value="true" />
              <v-radio label="No" :value="false" />
            </v-radio-group>
          </v-col>
          <v-col>
            <v-row class="my-2"> Diabetes: </v-row>
            <v-radio-group
              required
              v-model="diabetesStatus"
              :rules="[
                this.diabetesStatus != null ||
                  'Please select your diabetes status',
              ]"
            >
              <v-radio label="Yes" :value="true" />
              <v-radio label="No" :value="false" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="my-3">
          <v-col>
            <h3>CARDIOVASCULAR DISEASE (CVD)</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Have you had a heart attack or blood clot or been told by your
            doctor you are at risk of having one?</v-col
          >
          <v-spacer />
          <v-radio-group
            required
            v-model="atRisk"
            :rules="[this.atRisk != null || 'Please select an answer']"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col>
            Do you have heart pain (angina) or leg pain (claudication) brought
            on when you are active?</v-col
          >
          <v-spacer />
          <v-radio-group
            required
            v-model="legHeartActivePain"
            :rules="[
              this.legHeartActivePain != null || 'Please select an answer',
            ]"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col>Do you take medicine to control your blood sugar?</v-col>
          <v-spacer />
          <v-radio-group
            required
            v-model="bloodSugarMedicine"
            :rules="[
              this.bloodSugarMedicine != null || 'Please select an answer',
            ]"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col>Do you take medicine for your heart or circulation?</v-col>
          <v-spacer />
          <v-radio-group
            required
            v-model="heartCirculationMedicine"
            :rules="[
              this.heartCirculationMedicine != null ||
                'Please select an answer',
            ]"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-row>
        <v-row class="my-3">
          <v-col>
            <h3>DIABETES</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>Do you check your blood sugar at home?</v-col>
          <v-spacer />
          <v-radio-group
            required
            v-model="checkBloodSugarHome"
            :rules="[
              this.checkBloodSugarHome != null || 'Please select an answer',
            ]"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col>Do you have high blood sugar</v-col>
          <v-spacer />
          <v-radio-group
            required
            v-model="hasHighBloodSugar"
            :rules="[
              this.hasHighBloodSugar != null || 'Please select an answer',
            ]"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col
            >Have you been told by your doctor that you have an irregular
            heartbeat (cardiac arrhythmia)?</v-col
          >
          <v-spacer />
          <v-radio-group
            required
            v-model="hasIrregularHeartbeat"
            :rules="[
              this.hasIrregularHeartbeat != null || 'Please select an answer',
            ]"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-row>
        <v-row class="my-3">
          <v-col>
            <h3>HEALTH CARE PROVIDER CONTACT INFORMATION</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              required
              v-model="provLastName"
              label="Provider Last Name"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              required
              v-model="provFirstName"
              label=" Provider First Name"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              required
              v-model="provPhone"
              @input="acceptNumber('phone')"
              label="Provider Phone Number"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              required
              v-model="provFax"
              @input="acceptNumber('fax')"
              label="Provider Fax Number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :rules="[!!date ? true : 'Please enter a name']"
                  label="Date of Signature"
                  prepend-icon="mdi-calendar"
                  readonly
                  class="pt-0 mt-0"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="date" @input="menu = false" />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            By filling this in box you acknowledge you are electronically
            signing this enrollment form and consent to it being submitted to
            CMS</v-col
          ></v-row
        >
        <v-row class="my-2">
          <v-col cols="12">
            <v-alert dense outlined type="error" v-model="alert">
              Please sign your name below to continue or press skip to be sent a
              paper form to sign.
            </v-alert>
            <Signature ref="Signature" />
          </v-col>
        </v-row>
        <v-row class="d-flex mb-6 justify-end">
          <v-btn class="mr-5" color="warning" @click="skip()">Skip</v-btn>
          <v-btn color="success" @click="validate()">Save</v-btn>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Signature from "./Signature.vue";
import axios from "axios";

export default {
  props: ["recordID"],
  components: {
    Signature,
  },
  data: () => ({
    cvdStatus: null,
    diabetesStatus: null,
    atRisk: null,
    legHeartActivePain: null,
    bloodSugarMedicine: null,
    heartCirculationMedicine: null,
    checkBloodSugarHome: null,
    hasHighBloodSugar: null,
    hasIrregularHeartbeat: null,
    provLastName: "",
    provFirstName: "",
    provPhone: "",
    provFax: "",
    signature: "",
    menu: false,
    date: "",
    valid: false,
    alert: false,
  }),
  methods: {
    async save() {
      this.signature = await this.$refs.Signature.submit();
      if (this.signature !== undefined) {
        this.saveData();
      } else {
        this.alert = true;
      }
    },
    acceptNumber(mode) {
      let x;
      switch (mode) {
        case "phone":
          x = this.provPhone
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          this.provPhone = !x[2]
            ? x[1]
            : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
          break;
        case "fax":
          x = this.provFax
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          this.provFax = !x[2]
            ? x[1]
            : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
          break;
      }
    },
    storeData() {
      localStorage.setItem(
        "data",
        JSON.stringify({
          _id: this.recordID,
          cardioVascularDiseaseStatus: this.cvdStatus,
          diabetesStatus: this.diabetesStatus,
          atRiskHeartAttackBloodClot: this.atRisk,
          legHeartActivePain: this.legHeartActivePain,
          useBloodSugarMedicine: this.bloodSugarMedicine,
          checkBloodSugarHome: this.checkBloodSugarHome,
          hasHighBloodSugar: this.hasHighBloodSugar,
          useHeartOrCirculationMedicine: this.heartCirculationMedicine,
          hasIrregularHeartbeat: this.hasIrregularHeartbeat,
          providerFax: this.providerFax,
          ProviderFirstName: this.provFirstName,
          ProviderLastName: this.provLastName,
          ProviderPhone: this.provPhone,
          CSNPSignature: this.signature,
          CSNPSignatureDate: this.date,
        })
      );
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    skip() {
      this.$emit("selectedTab", 9);
    },
    saveData() {
      this.storeData();
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          cardioVascularDiseaseStatus: this.cvdStatus,
          diabetesStatus: this.diabetesStatus,
          atRiskHeartAttackBloodClot: this.atRisk,
          legHeartActivePain: this.legHeartActivePain,
          useBloodSugarMedicine: this.bloodSugarMedicine,
          checkBloodSugarHome: this.checkBloodSugarHome,
          hasHighBloodSugar: this.hasHighBloodSugar,
          useHeartOrCirculationMedicine: this.heartCirculationMedicine,
          hasIrregularHeartbeat: this.hasIrregularHeartbeat,
          providerFax: this.providerFax,
          ProviderFirstName: this.provFirstName,
          ProviderLastName: this.provLastName,
          ProviderPhone: this.provPhone,
          CSNPSignature: this.signature,
          CSNPSignatureDate: this.date,
        })
        .then(() => {
          this.$emit("selectedTab", 8);
        })
        .catch((err) => {
          console.log(err);
          console.log("Error saving form data.");
        });
    },
  },
};
</script>
