<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="success"> New Authorization </v-btn>
    </template>

    <v-card tile id="capture" flat color="#fafafa">
      <v-toolbar dark color="primary">
        <v-toolbar-title> Create New Authorization </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closeDialog = true;">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-row v-if="loading" justify="center" style="height: 100vh">
        <v-col align-self="center" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-else justify="center" class="pt-5">
        <v-col cols="12" md="10">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step
                :complete="step > 1"
                step="1"
              >
                Pre-Authorization
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="step > 2"
                step="2"
              >
                Provider Details
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                Service Details
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form 
                  ref="stepOne"
                  v-model="stepOneValid" 
                  lazy-validation
                >
                  <v-row >
                    <v-col cols="12" md="6">
                      <v-card-text>
                        <div class="text-body-1 mb-3">
                          Authorization Priority:

                          <v-radio-group 
                            v-model="authPriority" 
                            required
                            :rules="[
                              authPriority != '' || 'Authorization Priority is required'
                            ]"
                            row
                          >
                            <v-radio
                              label="Standard"
                              value="Standard"
                            ></v-radio>
                            <v-radio
                              label="Expidited"
                              value="Expidited"
                            ></v-radio>
                          </v-radio-group>
                        </div>

                        <div class="text-body-1 mb-3">
                          Initial Authorization Method Received:
                          <v-select
                            v-model="authReceivedMethod"
                            :items="['Email', 'Fax', 'Phone']"
                            required
                            :rules="[
                              authReceivedMethod != '' || 'Recieved Method is required'
                            ]"
                            label="Method of Authorization"
                            outlined
                            dense
                          ></v-select>
                        </div>
                        

                        <div class="text-body-1 mb-3">
                          <v-row>
                            <v-col cols=8>
                              Initial Authorization Date:
                              <v-menu
                                v-model="authReceivedDateMenu"
                                transition="scale-transition"
                                min-width="auto"
                                offset-y
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    label="Received Date"
                                    v-model="authReceivedDate"
                                    required
                                    :rules="[
                                      authReceivedDate != '' || 'Received Date is required'
                                    ]"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="authReceivedDate"
                                  no-title
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="4">
                              <time-selector 
                              class="mt-6" 
                              v-model="authReceivedTime"
                              ></time-selector>
                            </v-col>
                          </v-row>
                        </div>

                        <div class="text-body-1 mb-3">
                          Upload documentation
                          <span class="text-caption mb-2">(if applicable)</span>
                          <v-file-input
                            v-model="uploadedFiles"
                            accept=".pdf, .jpg, .png"
                            prepend-icon="mdi-file"
                            multiple
                            counter
                            outlined
                            dense
                            truncate-length="10"
                          ></v-file-input>
                        </div>
                        
                      </v-card-text>
                    </v-col>
                    
                    <v-col cols="12" md="6">
                      <v-card-text>
                        <member-search-dialog @memberSelected="saveSelectedMember"></member-search-dialog>

                        <v-card
                          v-if="selectedMember != ''"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content>
                              <div class="text-caption">
                                ID: {{ selectedMember.memberID}}
                              </div>
                              <v-list-item-title class="mb-1">
                                {{ selectedMember.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ selectedMember.birthDate}}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar
                              tile
                              size="70"
                            >
                              <v-icon v-if="selectedMember.gender === 'Male'" x-large>
                                mdi-face-man
                              </v-icon>
                              <v-icon v-if="selectedMember.gender === 'Female'" x-large>
                                mdi-face-woman
                              </v-icon>
                            </v-list-item-avatar>
                          </v-list-item>
                        </v-card>

                        <div v-if="!selectedMember" class=" px-1 text-subtitle-2">
                          Please select a member to continue
                        </div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-form>

                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      @click="validateStepOne"
                      :disabled="!stepOneValid"
                      color="accent" 
                      depressed 
                    >
                      Continue
                      <v-icon class="ml-2">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form 
                  ref="stepTwo"
                  v-model="stepTwoValid" 
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12">
                     <v-row>
                       <v-col cols="12" md="4">
                        <v-card-title class="text-h5">
                          Requesting Provider           
                        </v-card-title>
                       </v-col>
                       <v-spacer></v-spacer>
                       <v-col class="my-auto" cols="12" md="3">
                        <provider-search-dialog 
                        @providerSelected="setSelectedReqProvider"
                        >
                        </provider-search-dialog>
                       </v-col>   
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                     <v-text-field
                      v-model="authForm.requestingName"
                      label="Provider Name"
                      outlined
                      :disabled="!enableRequestingProvEdit"
                      dense
                      :rules="[authForm.requestingName != '' || 'Provider Name is required']"
                     /> 
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Provider NPI"
                        v-model="authForm.requestingNPI"
                        :readonly="existingProvider"
                        required
                        :disabled="!enableRequestingProvEdit"
                        :rules="[(v) => !!v || 'Provider NPI is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Provider Tax ID"
                        v-model="authForm.requestingTaxID"
                        :disabled="!enableRequestingProvEdit"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Contact Name"
                        v-model="authForm.requestingContactName"
                        required
                        :disabled="!enableRequestingProvEdit"
                        :rules="[(v) => !!v || 'Contact Name is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Contact Title"
                        v-model="authForm.requestingContactTitle"
                        :disabled="!enableRequestingProvEdit"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Contact Phone"
                        v-model="authForm.requestingPhone"
                        @input="formatRequestingPhone"
                        :disabled="!enableRequestingProvEdit"
                        required
                        :rules="[
                          authForm.requestingPhone != '' ||
                          'Must be a valid phone number'
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Contact Fax"
                        v-model="authForm.requestingFax"
                        @input="formatRequestingFax"
                        :disabled="!enableRequestingProvEdit"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      Please select one of the following:
                      <v-radio-group 
                        v-model="authForm.requestingType" 
                        required 
                        :rules="[(v) => !!v || 'Selection is required']"
                        row
                      >
                        <v-radio
                          label="PCP"
                          value="PCP"
                        ></v-radio>
                        <v-radio
                          label="Specialist"
                          value="Specialist"
                        ></v-radio>
                        <v-radio
                          label="Hospital/Facility"
                          value="Hospital/Facility"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        class="my-0 py-0"
                        v-model="renderingProvSame"
                        label="Is servicing provider the same as requesting provider?"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col>
                         <div class="text-h5">Rendering Provider</div>
                        </v-col>
                        <v-col cols="12" md="3">
                          <provider-search-dialog  
                          @providerSelected="setSelectedRendingProvider"
                          ></provider-search-dialog>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Group Name"
                        v-model="authForm.groupName"
                        :disabled="!enableRendingEdit"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Group NPI"
                        v-model="authForm.groupNPI"
                        outlined
                        :disabled="!enableRendingEdit"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Tax ID"
                        v-model="authForm.billingTaxID"
                        :disabled="!enableRendingEdit"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Physician Name"
                        v-model="authForm.physicianName"
                        :readonly="renderingProvSame"
                        required
                        :disabled="!enableRendingEdit"
                        :rules="[(v) => !!v || 'Physician Name is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Physician NPI"
                        v-model="authForm.physicianNPI"
                        :readonly="renderingProvSame"
                        :disabled="!enableRendingEdit"
                        required
                        :rules="[(v) => !!v || 'Physician NPI is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Service Provider Address"
                        v-model="authForm.serviceProviderAddress"
                        required
                        :disabled="!enableRendingEdit"
                        :rules="[(v) => !!v || 'Service Provider Address is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="City"
                        v-model="authForm.serviceProviderCity"
                        required
                        :disabled="!enableRendingEdit"
                        :rules="[(v) => !!v || 'City is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-autocomplete
                        label="State"
                        v-model="authForm.serviceProviderState"
                        :items="stateList"
                        required
                        :disabled="!enableRendingEdit"
                        :rules="[(v) => !!v || 'State is required']"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="ZIP"
                        v-model="authForm.serviceProviderZIP"
                        required
                        :disabled="!enableRendingEdit"
                        :rules="[
                          authForm.serviceProviderZIP != '' ||
                          'ZIP is required',
                          /^[^a-zA-Z]+$/.test(authForm.serviceProviderZIP) ||
                          'This field cannot contain letters'
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Phone"
                        v-model="authForm.serviceProviderPhone"
                        @input="formatServicePhone"
                        required
                        :disabled="!enableRendingEdit"
                        :rules="[
                          authForm.serviceProviderPhone != '' ||
                          'Must be a valid phone number'
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Fax"
                        v-model="authForm.serviceProviderFax"
                        @input="formatServiceFax"
                        required
                        :disabled="!enableRendingEdit"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      Please select a provider type:
                      <v-radio-group 
                        v-model="authForm.serviceProviderType"
                        required 
                        :rules="[(v) => !!v || 'Selection is required']"
                        row
                      >
                        <v-radio
                          label="Par"
                          value="Par"
                        ></v-radio>
                        <v-radio
                          label="Non-Par"
                          value="Non-Par"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-form>

                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      text
                      color="accent lighten-1"
                      class="mr-2"
                      @click="step--"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                      Back
                    </v-btn>

                    <v-btn 
                      @click="validateStepTwo"
                      :disabled="!stepTwoValid"
                      color="accent" 
                      depressed 
                    >
                      Continue
                      <v-icon class="ml-2">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card flat class="pa-3 mb-2">
                  <v-form 
                    ref="stepThree"
                    v-model="stepThreeValid" 
                    lazy-validation
                  >
                    <v-row><v-col cols="12"><div class="text-h5">Service Details</div></v-col></v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          label="Service Type"
                          v-model="authForm.serviceType"
                          :items="serviceTypeChoices"
                          required
                          :rules="[(v) => !!v || 'Service Type is required']"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          label="POS Code"
                          v-model="authForm.posCode"
                          :items="posCodeList"
                          required
                          :rules="[(v) => !!v || 'POS Code is required']"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="serviceStartDateMenu"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Service Start Date"
                              v-model="authForm.serviceStartDate"
                              required
                              :rules="[
                                authForm.serviceStartDate != '' ||
                                  'Service Start Date is required',
                              ]"
                              prepend-inner-icon="mdi-calendar"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="authForm.serviceStartDate"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <time-selector class="float-left" v-model="authForm.serviceStartTime"></time-selector>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="serviceEndDateMenu"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Service End Date"
                              v-model="authForm.serviceEndDate"
                              required
                              :rules="[
                                authForm.serviceEndDate != '' ||
                                  'Service End Date is required',
                              ]"
                              prepend-inner-icon="mdi-calendar"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="authForm.serviceEndDate"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <time-selector class="float-left" v-model="authForm.serviceEndTime"></time-selector>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="appointmentDateMenu"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Appointment Date"
                              v-model="authForm.appointmentDate"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              clearable
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="authForm.appointmentDate"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-card outlined class="pa-3" height="100%">
                          <v-row>
                            <v-col cols="12" sm="9" class="pa-6">
                              <v-text-field
                                class="py-0"
                                v-model="serviceDetailsSearch"
                                label="Search"
                                dense
                                prepend-inner-icon="mdi-magnify"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <service-details-dialog
                                resetService="resetService"
                                @newServiceDetail="addServiceDetails"
                              ></service-details-dialog>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-data-table
                              :headers="serviceDetailsHeaders"
                              :items="authForm.serviceDetails"
                              :search="serviceDetailsSearch"
                              multi-sort
                              class="elevation-0 col-md-12 m-auto"
                            >
                              <template v-slot:item.ACTIONS="{ item }">
                                <v-icon @click="deleteCode(item)"
                                  >mdi-delete</v-icon
                                >
                              </template>
                            </v-data-table>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <div class="text-h5">Review Assignment</div>
                      </v-col>
                      <v-col cols="12" md="6">
                        Select Review Assignment:
                        <v-radio-group 
                          v-model="assignmentType"
                          required
                          :rules="[(v) => !!v || 'Assignment is required']"
                          row
                        >
                          <v-radio
                            label="Unassigned"
                            value="Unassigned"
                          ></v-radio>
                          <v-radio
                            label="Nurse"
                            value="Nurse"
                          ></v-radio>
                          <v-radio
                            label="MD"
                            value="MD"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-autocomplete
                          label="Search Users"
                          :disabled="assignmentType === 'Unassigned' || assignmentType === ''"
                          v-model="assignedUser"
                          :items="assignmentList"
                          :loading="assignmentLoading"
                          :search-input.sync="assignmentSearch"
                          item-text="fullName"
                          return-object
                          clearable
                          required
                          :rules="[
                            assignedUser != '' || 'Assignment is required'
                          ]"
                          outlined
                          dense
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title>
                                Search for users
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title> {{ item.fullName }}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.userID }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    
                    <v-row v-if="assignedUser != null">
                      <v-col cols="12" md="9">
                        <div class="text-h5">Tasks</div>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-dialog v-model="taskDialog" max-width="500px" persistent>
                          <template v-slot:activator="{ on }">
                            <v-btn 
                              v-on="on"
                              color="success"
                              block
                            >
                              <v-icon class="mr-1">mdi-folder-plus-outline</v-icon>
                              Create Task
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title>
                              <span class="text-h5 mb-2">New Task</span>
                            </v-card-title>

                            <v-card-text>
                              <v-form ref="taskForm" v-model="taskValid" lazy-validation>
                                <v-row>
                                  <v-col cols="12">
                                    <v-select
                                      v-model="taskType"
                                      label="Task Type"
                                      :items="taskTypeList"
                                      required
                                      :rules="[
                                        this.taskType != '' ||
                                          'Type is required',
                                      ]"
                                      row
                                      outlined
                                      dense
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12">
                                    Task Priority
                                    <v-radio-group
                                      v-model="taskPriority"
                                      required
                                      :rules="[
                                        taskPriority != '' ||
                                          'Priority is required',
                                      ]"
                                      row
                                    >
                                      <v-radio 
                                        label="Low" 
                                        value="Low"
                                      ></v-radio>
                                      <v-radio
                                        label="Medium"
                                        value="Medium"
                                      ></v-radio>
                                      <v-radio
                                        label="High"
                                        value="High"
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-textarea
                                      v-model="taskObjective"
                                      label="Please enter the task objective"
                                      :counter="255"
                                      dense
                                      outlined
                                      :rules="[
                                        taskObjective != '' || 'Objective is required',
                                        v => v.length <= 255|| 'Max 255 characters'
                                      ]"
                                    >
                                    </v-textarea>
                                  </v-col>
                                </v-row>    
                              </v-form>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="accent"
                                text
                                @click="cancelTask()"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="success"
                                text
                                @click="addTask()"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12">
                        <v-card v-if="taskList.length === 0" transparent flat>
                          <v-card-text class=" text-subtitle-1 pl-0">
                            No tasks have been added to this authorization.
                          </v-card-text> 
                        </v-card>

                        <v-card v-else outlined>
                          <v-list three-line>
                            <template v-for="(t, index) in taskList">
                              <v-list-item :key="t.TaskObjective">
                                <template>
                                  <v-list-item-action>
                                    <v-btn
                                      @click="removeTask" 
                                      text
                                      fab
                                    >
                                      <v-icon color="grey lighten-1">
                                        mdi-close-circle-outline
                                      </v-icon>
                                    </v-btn>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Objective: {{ t.TaskObjective }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                      Type: {{ t.TaskType }}
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle>
                                      Priority: {{ t.TaskPriority }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>

                              <v-divider
                                v-if="index < taskList.length - 1"
                                :key="index"
                                inset
                              ></v-divider>
                            </template>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>

                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      text
                      color="accent lighten-1"
                      class="mr-2"
                      @click="step--"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                      Back
                    </v-btn>

                    <v-btn 
                      @click="validateStepThree"
                      :disabled="!stepThreeValid"
                      color="accent"
                      depressed
                    >
                      Save
                      <v-icon class="ml-2">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>

      <v-snackbar
        :timeout="5000"
        v-model="snackbar"
        color="error"
      >
        Please input all required information before you can proceed.
      </v-snackbar>
      
      <v-snackbar
        :timeout="5000"
        v-model="taskError"
        color="error"
      >
        When a user is assigned you must add at least one task.
      </v-snackbar>

      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title class="text-h5">
            Confirmation
          </v-card-title>
          <v-card-text>
            Are you sure you want to create a new authorization? 
            Please confirm that all the information is accurate before saving. 
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="confirmDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              text
              @click="saveNewAuth"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="closeDialog"
        persistent
        max-width="400" 
      >
      <v-card >
        <v-card-title  class="text-h5">
          Are you sure you want to exit?
        </v-card-title>
        <v-card-text>
          Any unsaved progress will be lost...
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
        <v-btn class="ma-3" @click="closeDialog = false" text color="accent">Cancel</v-btn>
        <v-btn class="ma-3" @click="modalReset()" text color="success">Yes, Exit</v-btn>
        </v-card-actions>
      </v-card>

      </v-dialog>
    </v-card>
      
  </v-dialog>
</template>

<script>
import axios from "axios";
import MemberSearchDialog from "./auth-components/MemberSearchDialog";
import ServiceDetailsDialog from "./auth-components/ServiceDetailsDialog";
import TimeSelector from './TimeSelector.vue'
import ProviderSearchDialog from './auth-components/ProviderSearch';

export default {
  name: "NewAuthorization",
  props: ["user"],
  components: {
    MemberSearchDialog,
    ServiceDetailsDialog,
    TimeSelector,
    ProviderSearchDialog,
  },
  data() {
    return {
      snackbar: false,
      dialog: false,
      existingProvider: false,
      confirmDialog: false,
      loading: false,
      step: 1,
      stepOneValid: false,
      stepTwoValid: false,
      stepThreeValid: false,
      reqProvItems: [],
      reqProvLoading: false,
      requestingName: {},
      requestingNPI: '',
      renderingProvSame: false,
      authPriority: '',
      selectedMember: '',
      authReceivedDateMenu: false,
      authReceivedDate: '',
      authReceivedTime: '',
      authReceivedMethod: '',
      uploadedFiles: [],
      authForm: {
        requestingTaxID: '',
        requestingContactName: '',
        requestingContactTitle: '',
        requestingPhone: '',
        requestingFax: '',
        requestingType: '',
        groupName: '',
        groupNPI: '',
        billingTaxID: '',
        physicianName: '',
        physicianNPI: '',
        serviceProviderAddress: '',
        serviceProviderCity: '',
        serviceProviderState: '',
        serviceProviderZIP: '',
        serviceProviderPhone: '',
        serviceProviderFax: '',
        serviceProviderType: '',
        serviceType: '',
        posCode: '',
        serviceStartDate: '',
        serviceStartTime: '',
        serviceEndDate: '',
        serviceEndTime: '',
        appointmentDate: '',
        serviceDetails: [],
      },
      stateList: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      serviceTypeChoices: [
        "Office/Diag Ctr",
        "DME",
        "Home Health",
        "OP Hospital",
        "IP Hospital",
        "Ambulatory Surgery Center",
        "Physical Therapy",
        "Occupational Therapy",
        "Speech Therapy",
        "Part B Drug Request",
      ],
      posCodeList: [],
      serviceStartDateMenu: false,
      serviceEndDateMenu: false,
      appointmentDateMenu: false,
      serviceDetailsSearch: "",
      serviceDetailsHeaders: [
        { text: "Diagnosis", value: "DIAG" },
        { text: "CPT4/HCPCS", value: "CPT_HCPCS" },
        { text: "Description", value: "ServiceDesc" },
        { text: "Visits", value: "Visits" },
        { text: "Units", value: "Units" },
        { text: "Actions", value: "ACTIONS" },
      ],
      selectedTemplate: '',
      determination: '',
      determinationReason: '',
      deliveryMethod: '',
      assignmentType: '',
      assignedUser: null,
      assignmentList: [],
      assignmentLoading: false,
      assignmentSearch: null,
      taskDialog: false,
      taskValid: false,
      taskPriority: '',
      taskObjective: '',
      taskType: '',
      taskTypeList: ['Authorization Alert'],
      taskList: [],
      taskError: false,
      closeDialog:false,
      resetService: false,
      searchProvDialog:false,
      selectedProvider:{},
      selectedRendingProvider:{},
      enableRendingEdit: true,
      enableRequestingProvEdit: true 
    };
  },
  mounted() {
    this.getPosCodes();
  },
  methods: {
    saveSelectedMember(value) {
      this.selectedMember = value;
    },
    validateStepOne() {
      if(this.$refs.stepOne.validate() && this.selectedMember === '') {
        this.snackbar = true;
      } else if(this.$refs.stepOne.validate() && this.selectedMember != '') {
        this.step = 2;
      }
    },
    validateStepTwo() {
      if(this.$refs.stepTwo.validate()) {
        this.step = 3;
      }
    },
    validateStepThree() {
      if(this.$refs.stepThree.validate() && this.authForm.serviceDetails.length === 0) {
        this.snackbar = true;
      } else if(this.$refs.stepThree.validate() && this.authForm.serviceDetails.length > 0) {
        this.confirmDialog = true;
      }
    },
    async saveNewAuth() {
      let newAuth = {
        SonderID: this.selectedMember.memberID,
        MemberName: this.selectedMember.name,
        DOB: this.selectedMember.birthDate,
        AuthPrio: this.authPriority,
        CreatedBY:this.user.userID,
        ReceiveType: this.authReceivedMethod,
        ReceiveDate: this.authReceivedDate,
        ReceivedTime: this.authReceivedTime,
        ReviewStatus: this.assignmentType,
        AssignedUser: this.assignedUser,
        Updates: [],
        ReqProviderName: this.authForm.requestingName,
        ReqProviderNPI: this.authForm.requestingNPI,
        ReqProvTIN: this.authForm.requestingTaxID,
        ReqContactName: this.authForm.requestingContactName,
        ReqContactTitle: this.authForm.requestingContactTitle,
        ReqProvPhone: this.authForm.requestingPhone,
        ReqProvFax: this.authForm.requestingFax,
        ReqProvType: this.authForm.requestingType,
        RendProvGroup: this.authForm.groupName,
        RendProvGroupNPI: this.authForm.groupNPI,
        RendBillingTIN: this.authForm.billingTaxID,
        RendPhysName: this.authForm.physicianName,
        RendPhysNPI: this.authForm.physicianNPI,
        RendPhysNetType: this.authForm.serviceProviderType,
        RendPhysAddress: this.authForm.serviceProviderAddress,
        RendPhysCity: this.authForm.serviceProviderCity,
        RendPhysState: this.authForm.serviceProviderState,
        RendPhysZip: this.authForm.serviceProviderZIP,
        RendPhysPhone: this.authForm.serviceProviderPhone,
        RendPhysFax: this.authForm.serviceProviderFax,
        ServiceType: this.authForm.serviceType,
        POS: this.authForm.posCode,
        StartDate: this.authForm.serviceStartDate,
        StartTime: this.authForm.serviceStartTime,
        EndDate: this.authForm.serviceEndDate,
        EndTime: this.authForm.serviceEndTime,
        ApptDate: this.authForm.appointmentDate,
        Services: this.authForm.serviceDetails,
      }

      if(
        this.assignedUser && 
        this.taskList.length === 0
      ) {
        this.taskError = true;
        this.confirmDialog = false;
      } else {
        let createdAuth = await axios.post(`api/authorizations/new`, newAuth);
        let createdAuthID = createdAuth.data;
  
        // create form data to pass allow passing files to backend
        let formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          let file = this.uploadedFiles[i];
          formData.append("file[" + i + "]", file);
        }
        await axios.post(
          `api/authorizations/documents/upload/${createdAuthID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        ).then(
          this.$emit('newAuth', {
            AssignedUser: this.assignedUser,
            AuthID: createdAuthID,
            Determination: this.assignmentType,
            MemberName: this.selectedMember.name,
            ReqProviderName : this.requestingName.NAME,
            ReviewStatus:this.assignmentType,
            Services:this.authForm.serviceDetails,
            SonderID: this.selectedMember.memberID
          })
        )
        
        if(this.taskList.length > 0) {
          this.taskList.forEach(task => {
            task.TaskAuthID = createdAuthID;
          })
          await axios.post(`/api/tasks/new`, this.taskList);
        }
       
        this.confirmDialog = false;
        this.modalReset();
      }
    },
    addServiceDetails(service) {
      this.authForm.serviceDetails.push(service);
    },
    getPosCodes() {
      axios.get("/api/authorizations/pos/getAll").then((response) => {
        this.posCodeList = response.data[0].POS;
        this.posCodeList.sort();
      });
    },
    deleteCode(item) {
      let deletedItem = this.authForm.serviceDetails.indexOf(item);
      this.authForm.serviceDetails.splice(deletedItem, 1);
    },
    addTask() {
      if(this.$refs.taskForm.validate()) {
        let task = {
          TaskPriority: this.taskPriority,
          TaskObjective: this.taskObjective,
          TaskType: this.taskType,
          AssignedBy: {
            email: this.user.email,
            fullName: `${this.user.lastname}, ${this.user.firstname}`,
            userID: this.user.userID
          },
          AssignedTo: {
            email: this.assignedUser.email,
            fullName: this.assignedUser.fullName,
            userID: this.user.userID
          },
        }

        this.taskList.push(task);
        this.$refs.taskForm.reset();
        this.taskPriority = '';
        this.taskObjective = '';
        this.taskType = '';
        this.taskDialog = false;
      }
    },
    cancelTask() {
      this.taskDialog = false;
      this.$refs.taskForm.reset();
      this.taskPriority = '';
      this.taskObjective = '';
      this.taskType = '';
    },
    removeTask(item) {
      let index = this.taskList.indexOf(item);
      this.taskList.splice(index, 1);
    },
    formatRequestingPhone() {
      let x = this.authForm.requestingPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.authForm.requestingPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    formatServicePhone() {
      let x = this.authForm.serviceProviderPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.authForm.serviceProviderPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    formatRequestingFax() {
      let x = this.authForm.requestingFax
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.authForm.requestingFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    formatServiceFax() {
      let x = this.authForm.serviceProviderFax
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.authForm.serviceProviderFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    modalReset() {
      this.step = 1;
      this.authPriority = '';
      this.authReceivedMethod = '';
      this.authReceivedDate = '';
      this.authCreatedTime = '';
      this.uploadedFiles = [];
      this.selectedMember = '';
      this.existingProvider = false;
      this.authForm.NPI = '';
      this.authForm.NAME = '';
      this.authForm.requestingName = '';
      this.authForm.requestingTaxID = '';
      this.authForm.requestingContactName = '';
      this.authForm.requestingContactTitle = '';
      this.authForm.requestingPhone = '';
      this.authForm.requestingFax = '';
      this.authForm.requestingType = '';
      this.renderingProvSame = false;
      this.authForm.groupName = '';
      this.authForm.groupName = '';
      this.authForm.groupNPI = '';
      this.authForm.billingTaxID = '';
      this.authForm.physicianName = '';
      this.authForm.physicianNPI = '';
      this.authForm.serviceProviderAddress = '';
      this.authForm.serviceProviderCity = '';
      this.authForm.serviceProviderState = '';
      this.authForm.serviceProviderZIP = '';
      this.authForm.serviceProviderPhone = '';
      this.authForm.serviceProviderFax = '';
      this.authForm.serviceProviderType = '';
      this.authForm.serviceType = '';
      this.authForm.posCode = ''
      this.authForm.serviceStartDate ='';
      this.authForm.serviceEndDate ='';
      this.authForm.appointmentDate ='';
      this.authForm.serviceDetails = [];
      this.assignmentType = '';
      this.assignedUser = null;
      this.assignmentLoading = false;
      this.assignmentSearch = null;
      this.existingProvider = '';
      this.requestingNPI = '';
      this.dialog = false;
      this.resetService = true;
    },
    setSelectedReqProvider(provider){
        this.reqProvItems[0] = provider;
        this.authForm.requestingName = provider.FullName
        this.requestingName = provider.FullName
        this.enableRequestingProvEdit = false;
        if(this.renderingProvSame){
          this.setSelectedRendingProvider(provider)
        }
    },
    setSelectedRendingProvider(provider){
      this.selectedRendingProvider = provider;
      this.enableRendingEdit = false;
      this.authForm.physicianName = provider.NAME;
      this.authForm.physicianNPI = provider.NPI;
      this.authForm.billingTaxID = provider.BillingProviderTaxID
      this.authForm.groupName = provider.group
      this.authForm.groupNPI = provider.rendGroupNPI
      this.authForm.serviceProviderAddress = provider.address;
      this.authForm.serviceProviderCity = provider.city;
      this.authForm.serviceProviderState = provider.state;
      this.authForm.serviceProviderZIP = provider.zip;
      this.authForm.serviceProviderPhone = provider.Phone;
      this.authForm.serviceProviderFax = provider.Fax;
      this.authForm.serviceProviderFax = provider.Fax;
    }
    
  },
  watch: {
    assignmentSearch() {
      // Lazily load input items
      this.assignmentLoading = true;
      axios
        .get(
          `/api/search/users/${this.assignmentType}/${this.assignmentSearch}`
        )
        .then((response) => {
          this.assignmentList = response.data;
          this.assignmentLoading = false;
        });
    },
    requestingName() {
      if( this.reqProvItems.length > 0) { 
        this.authForm.requestingName = this.reqProvItems[0].NAME
        this.authForm.requestingNPI = this.reqProvItems[0].NPI;
        this.authForm.requestingTaxID = this.reqProvItems[0].BillingProviderTaxID;
        this.authForm.requestingContactName = this.reqProvItems[0].FullName;
        this.authForm.requestingContactTitle = this.reqProvItems[0].Degree;
        this.authForm.requestingPhone = this.reqProvItems[0].Phone;
        this.authForm.requestingFax = this.reqProvItems[0].Fax;
      }
      else { 
        this.requestingNPI = '';
        this.authForm.requestingTaxID = '';
        this.authForm.requestingContactName = '';
        this.authForm.requestingContactTitle = '';
        this.authForm.requestingPhone = '';
        this.authForm.requestingFax = '';
      }
      
    },
    renderingProvSame() {
      if(this.renderingProvSame) {
        this.enableRendingEdit = false;
        this.authForm.physicianName = this.reqProvItems[0].NAME;
        this.authForm.physicianNPI = this.reqProvItems[0].NPI;
        this.authForm.billingTaxID = this.reqProvItems[0].BillingProviderTaxID
        this.authForm.groupName =  this.reqProvItems[0].group
        this.authForm.groupNPI = this.reqProvItems[0].rendGroupNPI
        this.authForm.serviceProviderAddress = this.reqProvItems[0].address;
        this.authForm.serviceProviderCity = this.reqProvItems[0].city;
        this.authForm.serviceProviderState = this.reqProvItems[0].state;
        this.authForm.serviceProviderZIP = this.reqProvItems[0].zip;
        this.authForm.serviceProviderPhone =  this.reqProvItems[0].Phone;
        this.authForm.serviceProviderFax = this.reqProvItems[0].Fax;
      } else {
        this.authForm.physicianName = '';
        this.authForm.physicianNPI = '';
        this.authForm.billingTaxID = '';
        this.authForm.groupName = '';
        this.authForm.groupNPI = '';
        this.authForm.serviceProviderAddress = '';
        this.authForm.serviceProviderCity = '';
        this.authForm.serviceProviderState = '';
        this.authForm.serviceProviderZIP = '';
        this.authForm.serviceProviderPhone = '';
        this.authForm.serviceProviderFax = '';
        this.enableRendingEdit = true
      }
    },
    assignmentType() {
      // reset user and task list if switching to unassigned
      if(this.assignmentType === 'Unassigned') {
        this.assignedUser = null;
        this.taskList = [];
      }
    }
  }
};
</script>

<style scoped>
.switch-label {
  min-width: 100px;
  text-align: right;
}
</style>
