<template>
  <v-dialog v-model="providerSearchDialog" max-width="750px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        color="accent"
        class="mb-2"
        block
        @click="openProvSearchModal"
      >
        <v-icon>mdi-magnify</v-icon>
        Search Providers
      </v-btn>
    </template>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Provider Select</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="resetModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card class="pa-5">
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-text-field
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-switch
              v-model="parStatus"
              class="mt-0 float-right"
              inset
            >
              <template slot="prepend">
                <p class="pa-1 switch-label">
                  {{ parStatus ? "Par" : "Non-Par" }}
                </p>
              </template>
            </v-switch>
          </v-col>
        </v-row>

        <v-data-table
          v-model="provSearchSelected"
          :items="provSearchResults"
          :headers="provSearchHeaders"
          item-key="NPI"
          :loading="provSearchLoad"
          :search="search"
          single-select
          show-select
          multi-sort
          return-object
          loading-text="Loading data..."
        ></v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="accent" @click="resetModal">Cancel</v-btn>
        <v-spacer> </v-spacer>
        <v-btn text color="success" @click="saveSelectedProvider">Save Selected</v-btn>      
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "provSearchDialog",
  components: {
  },
  data() {
    return {
      providerSearchDialog: false,
      provSearchLoad: false,
      provSearchId: '',
      provSearchName: '',
      provSearchDob: '',
      provSearchDobMenu: false,
      provSearchHeaders: [
        { text: "NPI", value: "NPI" },
        { text: "Name", value: "NAME" },
        { text: "Status", value: "status" },
        { text: "Tax ID", value: "BillingProviderTaxID" },
      ],
      provSearchResults: [],
      provSearchSelected:[],
      search:'',
      parStatus: true,
    };
  },
  methods: {
    openProvSearchModal() { 
      //When attempting to open the modal it will check to see if the user had provided characters
      // and will display an error if nothing has entered before the search..
      this.provSearchName = this.provName ? this.provName : '';
      this.providerSearchDialog = true;
      this.getReqProviderList();
    },
    async getReqProviderList() {
      //Produces a list of providers dependent on if they are Par or not par
      //As it stands in the database we currently have the status for Par vs Non-par as 'Active' for par and NULL otherwise...
      this.provSearchLoad = true
      await axios
        .get(`/api/search/par/${this.parStatus}/${this.provSearchName ? this.provSearchName: 'null'}`)
        .then(response => {
          this.provSearchResults = response.data;
          this.provSearchLoad = false;
        })
    },
    resetModal(){
      //closes the modal and clears all values searched and presented...
      this.provSearchLoad = false
      this.searchMethod = ''
      this.provSearchId = ''
      this.provSearchName = ''
      this.provSearchDob = ''
      this.provSearchDobMenu = false
      this.provSearchHeaders = [
        { text: "NPI", value: "NPI" },
        { text: "Name", value: "NAME" },
        { text: "Status", value: "status" },
        { text: "Tax ID", value: "BillingProviderTaxID" },
      ]
      this.provSearchResults = []
      this.provSearchSelected = []
      this.providerSearchDialog = false
    },
    unwind(obj) {
      const newObj = obj
      for(let i = 0; i < Object.values(newObj).length; i++) {
        if(Array.isArray(newObj[Object.keys(newObj)[i]])) {
          newObj[Object.keys(newObj)[i]] = newObj[Object.keys(newObj)[i]][0]
        }
      }
      return newObj
    },
    saveSelectedProvider() {
     //Emit the current provider, populating the rendering provider should also be possible through this modal
     this.$emit('providerSelected', this.unwind(this.provSearchSelected[0]));
     this.resetModal()
    },
  },
  watch:{
    providerSearchDialog(){
      if(this.provSearchSelected){
        this.getReqProviderList()
      }
    },
    parStatus() {
      this.getReqProviderList();
    }
  }
};
</script>


