<template>
    <v-dialog v-model="editPlanDialog" max-width="1200px">
        <template v-slot:activator="{ on }">
            <v-chip v-on="on"><v-icon>mdi-pencil</v-icon></v-chip>
        </template>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title> Edit Plan </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon dark @click="editPlanDialog = false">
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="editPlanForm" lazy-validation class="pa-3 ma-3">
                <v-row>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.planName"
                            label="Plan Name"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.premium"
                            label="Premium"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.deductible"
                            label="Deductible"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="formData.maxOutOfPocket"
                            label="Max Out Of Pocket"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="formData.emergencyRoomCopay"
                            label="Emergency Room Copay"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="formData.urgentCareCopay"
                            label="Urgent Care Copay"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="formData.choiceOfProviders"
                            label="Choice of Providers"
                            :items="['In Network Only', 'In and Out of Network']"
                            required
                            dense
                            outlined
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.optionalSupplementalBenefits"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Optional Supplemental Benefits</v-card-text>
                    </v-col>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.rxDrugCoverage"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Prescription Drug Coverage</v-card-text>
                    </v-col>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.otherPlanDeductibles"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Other Plan Deductibles</v-card-text>
                    </v-col>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.additionalBenefits"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Additional Benefits</v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="3" class="mt-5">
                        <add-c-p-t @newCode="newCode"></add-c-p-t>
                    </v-col>
                    <v-col cols="12" lg="9" class="align-left">
                        <v-text-field
                            v-model="searchTerm"
                            label="Search for a specific CPT Code"
                            class="ma-3"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <div v-if="sortedArray && sortedArray.length > 0">
                <v-data-iterator
                    :items="sortedArray"
                    :items-per-page.sync="itemsPerPage"
                >
                    <template v-slot:default="props">
                        <v-card flat outlined class="ma-3" v-for="(code, index) in props.items" :key="index">
                            <div>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" lg="3" v-if="code.code.min !== code.code.max">
                                            CPT Code Min: <span class="font-weight-bold">{{code.code.min}}</span>
                                        </v-col>
                                        <v-col cols="12" lg="3" v-if="code.code.min !== code.code.max">
                                            CPT Code Max: <span class="font-weight-bold">{{code.code.max}}</span>
                                        </v-col>
                                        <v-col cols="12" lg="3" v-if="code.code.min === code.code.max">
                                            CPT Code: <span class="font-weight-bold">{{code.code.max}}</span>
                                        </v-col>
                                        <v-col cols="12" lg="3">
                                            Description: <span class="font-weight-bold">{{code.desc}}</span>
                                        </v-col>
                                        <v-col cols="12" lg="2" v-if="code.days.start && code.days.end">
                                            Days: <span class="font-weight-bold">{{code.days.start}} - {{code.days.end}}</span>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-chip class="mb-5 mr-2 mt-2" color="accent" @click="deleteCode(index)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-chip>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" lg="3">
                                            Referral Needed: <span class="font-weight-bold">{{code.refRequired}}</span>
                                        </v-col>
                                        <v-col cols="12" lg="3">
                                            Authorization Needed: <span class="font-weight-bold">{{code.authRequired}}</span>
                                        </v-col>
                                        <v-col cols="12" lg="3">
                                            Copay: <span class="font-weight-bold">{{code.copay}}</span>
                                        </v-col>
                                        <v-col cols="12" lg="3">
                                            Max Benefit: <span class="font-weight-bold">{{code.maxBenefit}}</span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </div>
                        </v-card>
                    </template>
                </v-data-iterator>
            </div>
            <v-card-actions class="pb-6">
              <v-row>
                  <v-col cols="12" align="right">
                    <v-btn
                        outlined
                        @click="editPlanDialog = false"
                        class="mr-3"
                        color="accent"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="success"
                        outlined
                        @click="savePlan()"
                    >
                        Save
                    </v-btn>
                  </v-col>
              </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import AddCPT from "./AddCPT.vue"
export default {
    props: ["plan"],
    components: {
        AddCPT
    },
    data() {
        return {
            editPlanDialog: false,
            formData: {},
            searchTerm: null,
            sortedArray: [],
            itemsPerPage: 5,
            editIndex: null,
        }
    },
    methods: {
        savePlan() {
            axios
                .post('/api/plans/edit', this.formData)
                .then(() => {
                    this.editPlanDialog = false
                })
                .catch((err) => {
                    console.log(err);
                    console.log("Couldn't add plan.");
                });
        },
        newCode(code) {
            this.formData.cptCodes.unshift(code)
        },
        deleteCode(index) {
            this.formData.cptCodes.splice(index, 1)
        }
    },
    watch: {
        editPlanDialog() {
            if (this.editPlanDialog) {
                this.formData = this.plan
                this.sortedArray = this.plan.cptCodes
            } else {
                this.formData = {}
                this.sortedArray = []
            }
        },
        searchTerm() {
            //This watcher sorts the cpt codes by the entered number
            // Might need to use a try catch for the first else if in case of letters
            this.sortedArray = []
            this.formData.cptCodes.forEach((rule) => {
                if (rule.code.max === this.searchTerm || rule.code.min === this.searchTerm) {
                    this.sortedArray = [rule]
                } else if (parseInt(this.searchTerm) > parseInt(rule.code.min) && parseInt(this.searchTerm) < parseInt(rule.code.max)) {
                    this.sortedArray.unshift(rule)
                } else if (rule.code.max.includes(this.searchTerm) || rule.code.min.includes(this.searchTerm)) {
                    this.sortedArray.unshift(rule)
                }
            })
        }
    }
}
</script>

<style scoped>

</style>