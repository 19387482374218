<template>
  <div>
    <v-container fluid>
      <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
        <v-row>
          <span class="font-weight-black justify-start ma-3">
            Please provide your Medicare insurance information
          </span>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="memberID"
              label="Member ID"
              placeholder="Only for current or past Sonder members"
              outlined
              dense
              hint="To be filled only by current or past Sonder members"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            <v-text-field
              v-model="lastName"
              label="Last Name"
              outlined
              required
              dense
              :rules="[
                this.lastName != '' ||
                  'Field cannot be blank',
                /\S/.test(
                  this.lastName
                ) || 'Field cannot be blank',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="5">
            <v-text-field
              v-model="firstName"
              label="First Name"
              outlined
              dense
              required
              :rules="[
                this.firstName != '' ||
                  'Field cannot be blank',
                /\S/.test(
                  this.firstName
                ) || 'Field cannot be blank',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              v-model="middleInitial"
              label="Middle Initial"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthDate"
                  label="Birth Date"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  :rules="[
                    birthDate != '' || 'Please enter your date of birth',
                  ]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="birthDate"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                :active-picker.sync="activePickerValue"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu1.save(birthDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" lg="2">
            <v-select
              v-model="gender"
              :items="sex"
              label="Sex"
              outlined
              dense
              required
              :rules="[this.gender != null || 'Please select your sex']"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="5">
            <v-text-field
              v-model="email"
              label="Email"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="phoneNumber"
              label="Phone Number"
              outlined
              dense
              @input="acceptNumber()"
              required
              :rules="[
                this.phoneNumber != '' ||
                  'Field cannot be blank',
                /\S/.test(
                  this.phoneNumber
                ) || 'Field cannot be blank',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="2" class="pt-4">
            <v-checkbox
              class="pt-0"
              v-model="phoneHome"
              label="Home"
              dense
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" lg="2" class="pt-4">
            <v-checkbox
              class="pt-0"
              v-model="phoneCell"
              label="Cell"
              dense
              required
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="address"
              label="Street Address"
              outlined
              dense
              required
              :rules="[
                this.address != '' ||
                  'Field cannot be blank',
                /\S/.test(
                  this.address
                ) || 'Field cannot be blank',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" lg="3">
            <v-text-field
              v-model="city"
              label="City"
              outlined
              dense
              required
              :rules="[
                this.city != '' ||
                  'Field cannot be blank',
                /\S/.test(
                  this.city
                ) || 'Field cannot be blank',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" lg="3">
            <v-select
              v-model="county"
              :items="countyList"
              label="County"
              outlined
              dense
              required
              :rules="[this.county != '' || 'Please enter your county']"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" lg="2">
            <v-text-field
              v-model="state"
              label="State"
              disabled
              outlined
              dense
              required
              :rules="[this.state != '' || 'Please enter your state']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="zip"
              label="ZIP Code"
              outlined
              dense
              required
              :rules="[
                this.lastName != '' ||
                  'Field cannot be blank',
                /\S/.test(
                  this.lastName
                ) || 'Field cannot be blank',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" class="pt-1">
            <v-checkbox
              v-model="mailingAddress"
              label="Mailing address same as residence address"
              dense
              required
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <span
            class="font-weight-black justify-start ma-3"
            v-if="mailingAddress == false"
            >Mailing Address</span
          >
        </v-row>
        <v-row v-if="mailingAddress === false">
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="mailAddress"
              label="Street Address"
              outlined
              dense
              required
              :rules="[
                (this.mailingAddress === false && this.mailAddress != '') ||
                  'Please enter your mailing address',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" lg="3">
            <v-text-field
              v-model="mailCity"
              label="City"
              outlined
              dense
              required
              :rules="[
                (this.mailingAddress === false && this.mailCity != '') ||
                  'Please enter your city',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" lg="3">
            <v-text-field
              v-model="mailCounty"
              label="County"
              outlined
              dense
              required
              :rules="[
                (this.mailingAddress === false && this.mailCounty != '') ||
                  'Please enter your county',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="mailingAddress === false">
          <v-col cols="12" sm="3" lg="2">
            <v-text-field
              v-model="mailState"
              label="State"
              outlined
              dense
              required
              :rules="[
                (this.mailingAddress === false && this.mailAddress != '') ||
                  'Please enter your state',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="mailZip"
              label="ZIP Code"
              outlined
              dense
              required
              :rules="[
                (this.mailingAddress === false && this.mailZip != '') ||
                  'Please enter your mailing address',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" class="pt-1">
            <v-text-field
              label="RxBIN"
              v-model="rxBin"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-text-field
              label="RxPCN"
              v-model="rxPcn"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-text-field
              label="RxGroup"
              v-model="rxGroup"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-text-field
              label="PCP Copay"
              v-model="pcpCopay"
              prefix="$"
              outlined
              dense
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-text-field
              label="Specialist Copay"
              v-model="specialistCopay"
              prefix="$"
              outlined
              dense
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-text-field
              label="Emergency Copay"
              v-model="emergencyCopay"
              prefix="$"
              outlined
              dense
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-text-field
              label="Urgent Copay"
              v-model="urgentCopay"
              prefix="$"
              outlined
              dense
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-menu
              ref="letterMenu"
              v-model="letterMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="disenrollmentLetter"
                  label="Disenrollment Letter"
                  append-icon="mdi-calendar"
                  :return-value.sync="disenrollmentLetter"
                  clearable
                  outlined
                  dense
                  readyonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                label="Disenrollment Letter"
                v-model="disenrollmentLetter"
                outlined
                dense
              >
                <v-spacer></v-spacer>
                <v-btn outlined color="primary" @click="letterMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="$refs.letterMenu.save(disenrollmentLetter)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-menu
              ref="memberIdCreatedMenu"
              v-model="memberIdCreatedMenu"
              :close-on-content-click="false"
              :return-value.sync="memberIdCreated"
              offset-y
              min-width="290px"
              max-width="290px"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="memberIdCreated"
                  label="Member ID Created"
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  readyonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                label="Member ID Created"
                v-model="memberIdCreated"
                outlined
                dense
              >
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  @click="memberIdCreatedMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="$refs.memberIdCreatedMenu.save(memberIdCreated)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-select
              label="Type of Practice"
              v-model="typeOfPractice"
              :items="practiceTypes"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="pt-1">
            <v-menu
              ref="ackOfRecieptMenu"
              v-model="ackOfRecieptMenu"
              :close-on-content-click="false"
              :return-value.sync="ackOfReciept"
              offset-y
              min-width="290px"
              max-width="290px"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="ackOfReciept"
                  label="Ack of Reciept"
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  clearable
                  readyonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                label="Ack of Receipt"
                v-model="ackOfReciept"
                outlined
                dense
              >
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  @click="ackOfRecieptMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="$refs.ackOfRecieptMenu.save(ackOfReciept)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <v-btn text :disabled="!valid" color="primary" @click="previous()">
              <v-icon small> mdi-chevron-left </v-icon>
              Previous
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right pb-0">
            <v-btn text :disabled="!valid" color="primary" @click="validate">
              Next
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import axios from "axios"

export default {
  props: ["recordID"],
  data: () => ({
    valid: true,
    sex: ["Male", "Female"],
    countyList: [
      "Cobb",
      "Dekalb",
      "Fulton",
      "Gwinnett",
      "Fayette",
      "Douglas",
      "Cherokee",
      "Henry",
      "Coweta",
      "Rockdale",
      "Clayton",
      "Paulding",
    ],
    mailingAddress: true,
    memberID: "",
    lastName: "",
    firstName: "",
    middleInitial: "",
    birthDate: "",
    gender: null,
    email: "",
    phoneNumber: "",
    phoneHome: false,
    phoneCell: false,
    address: "",
    city: "",
    county: "",
    state: "GA",
    zip: "",
    mailAddress: "",
    mailCity: "",
    mailCounty: "",
    mailState: "",
    mailZip: "",
    menu1: false,
    activePickerValue: null,
    rxBin: "",
    rxPcn: "",
    rxGroup: "",
    pcpCopay: 0,
    specialistCopay: 0,
    emergencyCopay: 0,
    urgentCopay: 0,
    disenrollmentLetter: null,
    memberIdCreated: null,
    typeOfPractice: "",
    ackOfReciept: null,
    letterMenu: false,
    memberIdCreatedMenu: false,
    ackOfRecieptMenu: false,
    practiceTypes: [
      "General Practice",
      "Family Medicine",
      "Internal Medicine",
      "Geriatrics",
    ],
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData()
      }
    },
    previous() {
      this.$emit("selectedTab", 1)
    },
    acceptNumber() {
      var x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    saveData() {
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          memberID: this.memberID,
          lastName: this.lastName,
          firstName: this.firstName,
          middleInitial: this.middleInitial,
          birthDate: this.birthDate,
          gender: this.gender,
          email: this.email,
          phoneNumber: this.phoneNumber,
          address: this.address,
          city: this.city,
          county: this.county,
          state: this.state,
          zip: this.zip,
          mailAddress: this.mailAddress,
          mailCity: this.mailCity,
          mailCounty: this.mailCounty,
          mailState: this.mailState,
          mailZip: this.mailZip,
          rxBin: this.rxBin,
          rxPcn: this.rxPcn,
          rxGroup: this.rxGroup,
          pcpCopay: this.pcpCopay,
          specialistCopay: this.specialistCopay,
          emergencyCopay: this.emergencyCopay,
          urgentCopay: this.urgentCopay,
          disenrollmentLetter: this.disenrollmentLetter,
          memberIdCreated: this.memberIdCreated,
          typeOfPractice: this.typeOfPractice,
          ackOfReciept: this.ackOfReciept,
        })
        .then(() => {
          this.$emit("selectedTab", 3)
        })
        .catch((err) => {
          console.log(err)
          console.log("Error saving form data.")
        })
    },
  },
  watch: {
    menu1(val) {
      val && setTimeout(() => (this.activePickerValue = "YEAR"))
    },
  },
}
</script>
