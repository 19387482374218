<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-row >
            <v-col>
              <v-card-title>
                Disenrollments
                <template v-if="user.role === 'fmo'">
                  <v-spacer />
                  <v-switch v-model="showFmoMembers" label="Show FMO Agents Members" />
                </template>
              </v-card-title>
            </v-col>
            <v-col class="px-7" cols="12" lg="5">
              <v-select :items="planOptions" v-model="planFilter"/>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col class="px-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="filteredMembers"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.DETAILS="{ item }">
                <enrollment-modal :enrollee="item" :user="user" @documentSaved="getUser"></enrollment-modal>
              </template>

              <template v-slot:item.enrollmentStatus="{ item }">
                <enrollment-status-modal :view="'Disenrollment'" :patient="item" @closeEnrollmentStatusModal="closeEnrollmentStatusModal"></enrollment-status-modal>
              </template>

              <template v-slot:item.agent="{ item }">
                <span v-if="item.agent">{{item.agent}}</span>
                <span v-else>{{item.tempAgent}}</span>
              </template>

              <template v-slot:item.disenrollmentDate="props">
                {{ props.item.disenrollmentDate ? props.item.disenrollmentDate.substr(0, 7) : '' }}
              </template>

              <template slot="footer">
                <ExportCSV
                  :data="this.disenrollmentData"
                  :name="'Disenrollments.csv'"
                >
                  <v-btn color="accent" text class="export-button"
                    >Export to CSV</v-btn
                  >
                </ExportCSV>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackInfo" :timeout="3000" color="success">
      Changes saved!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import EnrollmentModal from "../components/EnrollmentModal";
import ExportCSV from "vue-json-csv";
import EnrollmentStatusModal from "../components/EnrollmentStatusModal";
import axios from "axios";

export default {
  name: "Disenrollments",
  components: {
    EnrollmentModal,
    ExportCSV,
    EnrollmentStatusModal
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      headers: [
        { text: "Name", value: "Full_Name" },
        { text: "Plan", value: "selectedPlan" },
        { text: "PCP", value: "selectedPCP" },
        {text:"Agent Name", value: "agent"},
        { text: "Birth Date", value: "birthDate" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Enrollment Status", value: "enrollmentStatus" },
        { text: "Disenrollment Date", value: "disenrollmentDate" },
        { text: "Enrollment Details", value: "DETAILS" }
      ],
      disenrollmentData: [],
      snackInfo: false,
       planOptions:[
        'All',
        'H1748-001 Complete',
        'H1748-003 Diabetes',
        'H1748-004 Heart',
        'H1748-005 Dual'
      ],
      planFilter:"All",
      filteredMembers:[],
      limitedAccess:false,
      fmoAgents: [],
      showFmoMembers: false 
    };
  },
  methods: {
    closeEnrollmentStatusModal(value) {
      if (value) {
        this.getDisenrolledPatients();
      } 
    },
    getUser() {
      axios.get("/api/user").then(response => {
        this.user = response.data[0];
        // check role and reroute if unauthorized
        if (this.user.role === "hra") {
          this.$router.push("/hraenrollment");
        } else if (this.user.role === "credentialing") {
          this.$router.push("/credentialing");
        } else {
          this.getDisenrolledPatients();
        }
      }).catch(() => {
        this.$router.push("/login");
      });
    },
    async getDisenrolledPatients() {
      this.disenrollmentData = [];
      this.load = true;

      let requestURL;
      if (this.user.role === "agent" || (this.user.role === "fmo" && !this.showFmoMembers)) {
        requestURL = `api/disenrollments/${this.user.userID}`;
      } else if (
        this.user.role === "superuser" ||
        this.user.role === "manager"   || 
        this.user.role === 'member services'
      ) {
        this.setHeaders()
        requestURL = `api/disenrollments/all`;
      } else if (this.user.role === "fmo" && this.showFmoMembers) {
          this.fmoAgents = []
          await axios
            .get(`api/fmo/agents/${this.user.fmoNpn}`).then(res => {
              this.fmoAgents = res.data;
            })
            .then(() => {
              this.fmoAgents.forEach(agentID => {
                axios.get(`api/disenrollments/${agentID}`).then(res => {
                  if(res.data.length > 0) {
                    this.disenrollmentData.push(res.data[0])
                  }
                })
              })
            })
            .finally(() => {
              this.filteredMembers = this.disenrollmentData
              this.load = false;
            })
      }

      if(!this.showFmoMembers) {
        axios.get(requestURL).then(res => {
          if (res.data.length > 0) {
            res.data.forEach(
              p => (p.amtPaidFormatted = this.formatCurrency(p.amtPaid))
            );
          }

          this.disenrollmentData = res.data;
          this.filteredMembers = this.disenrollmentData
          this.load = false;
        });
      }
    },
    saveEnrollment(item) {
      const paymentInfo = {
        patientID: item.medicareNumber,
        enrollmentStatus: item.enrollmentStatus,
        disenrollmentDate:
          item.enrollmentStatus === "Enrollment" ||
          item.enrollmentStatus === "Renewal"
            ? null
            : item.disenrollmentDate
      };

      axios.post("/api/enrollments/type/save", paymentInfo).then(response => {
        if(response) {
          let indexToUpdate = this.disenrollmentData.findIndex(
            p => p.medicareNumber === item.medicareNumber
          );

          // if changed status to Enrollment or Renewal, remove from array
          if (
            item.enrollmentStatus === "Enrollment" ||
            item.enrollmentStatus === "Renewal"
          ) {
            this.disenrollmentData.splice(indexToUpdate, 1);
          }

          // display snackbar notification
          this.snackInfo = true;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    formatCurrency(c) {
      if (c != undefined && c != "")
        return "$" + c.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    setHeaders(){
      if(this.user.role === "member services"){
        this.headers = [
        { text: "Name", value: "Full_Name" },
        { text: "Plan", value: "selectedPlan" },
        { text: "PCP", value: "selectedPCP" },
        {text:"Agent Name", value: "agent"},
        { text: "Birth Date", value: "birthDate" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Disenrollment Date", value: "disenrollmentDate" },
        { text: "Enrollment Details", value: "DETAILS" }
        ]
      }
      else{
        this.headers = [
        { text: "Name", value: "Full_Name" },
        { text: "Plan", value: "selectedPlan" },
        { text: "PCP", value: "selectedPCP" },
        {text:"Agent Name", value: "agent"},
        { text: "Birth Date", value: "birthDate" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Enrollment Status", value: "enrollmentStatus" },
        { text: "Disenrollment Date", value: "disenrollmentDate" },
        { text: "Enrollment Details", value: "DETAILS" }
        ]
      }
    }
  },
  mounted() {
    this.getUser();
  },
  watch: {
    planFilter() {
      if(this.planFilter === 'All'){
        this.filteredMembers = this.disenrollmentData;
      }
      else{
        this.filteredMembers = []
        this.disenrollmentData.forEach(disenrollment => {
          if(disenrollment.selectedPlan && disenrollment.selectedPlan.includes(this.planFilter.substr(0,9))){
            this.filteredMembers.push(disenrollment)
          }
        })
      }
    },
    showFmoMembers() {
      this.getDisenrolledPatients()
    },
    user() {
      if(this.user.role === "fmo") {
        this.showFmoMembers = true
      }
    }
  }
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
