<template>
  <v-container>
    <v-card class="pa-2 mx-3 transparent-card" outlined>
      <v-card-title>
        Tasks
        <v-spacer></v-spacer>
        <new-task 
          :user="user" 
          @addTask="taskSaved"
        />
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="py-0"
              v-model="search"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="tasks"
              :search="search"
              multi-sort
              loading-text="Loading data..."
            >
              <template v-slot:item.Completed="{ item }">
                <v-simple-checkbox
                  v-model="item.Completed"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>

              <template v-slot:item.CompletedDate="{ item }">
                {{ item.CompletedDate ? item.CompletedDate.substring(0, 10) : '-' }}
              </template>

              <template v-slot:item.TaskPriority="{ item }">
                <span :class="getPriorityColor(item.TaskPriority)">
                  {{ item.TaskPriority }}
                </span>
              </template>

              <template v-slot:item.TaskObjective="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>{{ item.TaskObjective }}</span>
                </v-tooltip>
              </template>
              
              <template v-slot:item.Created="{ item }">
                {{ item.Created.substring(0, 10) }}
              </template>

              <template v-slot:item.ACTIONS="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="sendReminder(item)"
                      text
                    >
                      <v-icon color="grey darken-1">mdi-email-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Send Reminder</span>
                </v-tooltip>

                <task-modal 
                  :user="user" 
                  :taskID="item.TaskID"
                  @completionChanged="updateCompleted"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-snackbar
      v-model="saveSuccess"
      color="success"
      :timeout="2000"
    >
      Changes have successfully been saved
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="saveSuccess = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    
    <v-snackbar
      v-model="reminderSent"
      color="success"
      :timeout="2000"
    >
      Your reminder has successfully sent
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="reminderSent = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import TaskModal from "../components/task-components/TaskModal";
import NewTask from "../components/task-components/NewTask";

export default {
  components: {
    TaskModal,
    NewTask,
  },
  props: [],
  data() {
    return {
      load: false,
      user: '',
      headers: [
        { text: "Completed", value: "Completed" },
        { text: "Completed Date", value: "CompletedDate" },
        { text: 'Priority', value: 'TaskPriority' },
        { text: 'Objective', value: 'TaskObjective' },
        { text: "Type", value: "TaskType" },
        { text: "Assigned To", value: "AssignedTo.Name" },
        { text: "Created", value: "Created" },
        { text: "Actions", value: "ACTIONS" },
      ],
      tasks: [],
      search: "",
      saveSuccess: false,
      reminderSent: false,
    };
  },
  computed: {
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          this.getTaskData();
        })
        .catch((err) => {
          console.log(err);
          // this.$router.push("/login");
        });
    },
    async getTaskData() {
      this.load = true;
      this.tasks = [];
      let userID = this.user.role === 'superuser' || this.user.role === 'manager' ? 'all' : this.user.userID

      await axios.get(`/api/tasks/all/${userID}`).then((response) => {
        this.tasks = response.data;
        this.load = false;
      });
    },
    taskSaved(item) {
      this.tasks.push(item);
      this.saveSuccess = true;
    },
    getPriorityColor(item) {
      if(item === 'High') {
        return 'error--text';
      } else if(item === 'Medium') {
        return 'warning--text';
      } else if(item === 'Low') {
        return 'success--text';
      } else {
        return '';
      }
    },
    updateCompleted(value) {
      let index = this.tasks.findIndex(task => task.TaskID === value.TaskID);
      this.tasks[index].Completed = value.Completed;
      this.tasks[index].CompletedDate = value.CompletedDate;
    },
    sendReminder(task) {
      axios.post('/api/tasks/reminder', task).then(res => {
        if(res.data === 'sent') {
          this.reminderSent = true;
        }
      })
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
</style>