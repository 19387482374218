<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" >
    <template v-slot:activator="{ on }">
      <v-chip text v-on="on">{{ agent.agentID }}</v-chip>
    </template>

    <v-toolbar dark color="primary" class="mx-auto">
      <v-btn icon dark @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card tile id="capture" flat>
      <v-row justify="center">
        <v-col cols="12" lg="10">
          <v-card outlined class="pa-2 my-1">
            <v-card-title class="text-h3">{{ agent.lname }}, {{ agent.fname }}</v-card-title>
            <v-card-subtitle class="text-h4">{{ agent.agentID }}</v-card-subtitle>
            
            <v-tabs 
              v-model="tab"
              slider-size="3"
              grow 
            >
              <v-tab v-for="item in tabOptions" :key="item">{{ item }}</v-tab>

              <v-tabs-items v-model="tab">
                <!-- roster tab -->
                <v-tab-item>
                  <v-row>
                    <v-col cols="12" class="pa-6">
                      <v-text-field
                        class="py-0"
                        v-model="searchRoster"
                        label="Search"
                        prepend-inner-icon="mdi-magnify"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined class="transparent pa-2 mx-3" height="100%">
                        <v-data-table
                          :loading="loading"
                          :headers="rosterHeaders"
                          :items="rosterData"
                          :search="searchRoster"
                          multi-sort
                          loading-text="Loading data..."
                          class="elevation-0 col-md-12 m-auto"
                        >
                          <template v-slot:item.newPayment="{ item }">
                            <new-payment-modal
                              @paymentCreated="newPaymentSuccess"
                              :agentInfo="item"
                            ></new-payment-modal>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <!-- payments -->
                <v-tab-item>
                  <v-row>
                    <v-col cols="12" class="pa-6">
                      <v-text-field
                        class="py-0"
                        v-model="searchPayments"
                        label="Search"
                        prepend-inner-icon="mdi-magnify"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined class="transparent pa-2 mx-3" height="100%">
                        <v-data-table
                          :loading="loading"
                          :headers="paymentsHeaders"
                          :items="paymentsData"
                          :search="searchPayments"
                          multi-sort
                          loading-text="Loading data..."
                          class="elevation-0 col-md-12 m-auto"
                        >
                          <template v-slot:item.amtPaid="{ item }">
                            {{ formatCurrency(item.amtPaid) }}
                          </template>

                          <template v-slot:item.paid="{ item }">
                            <v-simple-checkbox
                              v-model="item.paid"
                              disabled
                              :ripple="false"
                            ></v-simple-checkbox>
                          </template>

                          <template v-slot:item.fmoPayment="{ item }">
                            {{ formatCurrency(item.fmoPayment) }}
                          </template>

                          <template v-slot:item.created="{ item }">
                            {{ item.created.substr(0, 10) }}
                          </template>
                          
                          <template v-slot:item.ACTIONS="{ item }">
                            <edit-payment 
                              :payment="item"
                              @editSaved="editSuccess"
                              @setEdit="setEditItem(item)"
                            ></edit-payment>
                            <v-dialog
                              v-model="deletePaymentDialog"
                              persistent
                              max-width="400"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  text
                                  dense 
                                  small
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  Are you sure?
                                </v-card-title>
                                <v-card-text>
                                  Deleting a payment is an irreversible action. 
                                  Please confirm whether or not you want to delete 
                                  this payment.
                                </v-card-text>
                                <v-card-actions>
                                  <v-btn
                                    color="error"
                                    text
                                    @click="deletePaymentDialog = false"
                                  >
                                    No, Cancel
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="success"
                                    text
                                    @click="deletePayment(item)"
                                  >
                                    Yes, Delete Payment
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar
      v-model="snackInfo"
      color="success"
      :timeout="2000"
    >
      Changes have successfully been saved
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackInfo = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import NewPaymentModal from './NewPaymentModal';
import EditPayment from './EditPayment';

export default {
  name: "AgentModal",
  props: ["agent"],
  components: {
    NewPaymentModal,
    EditPayment
  },
  data() {
    return {
      dialog: false,
      tab: null,
      snackInfo: false,
      tabOptions: [
        'Roster',
        'Payments'
      ],
      searchRoster: "",
      rosterHeaders: [
        { text: "Name", value: "Full_Name" },
        { text: "Plan", value: "selectedPlan" },
        { text: "PCP", value: "selectedPCP" },
        { text: "Enrollment Status", value: "enrollmentStatus" },
        { text: "Agent ID", value: "agentID" },
        { text: "New Payment", value: "newPayment" },
      ],
      rosterData: [],
      searchPayments: "",
      paymentsHeaders: [
        { text: "Patient ID", value: "patientID" },
        { text: "Patient Name", value: "patientName" },
        { text: "Amount", value: "amtPaid" },
        { text: "Paid", value: "paid" },
        { text: "FMO Payment", value: "fmoPayment" },
        { text: "Payment Type", value: "paymentType" },
        { text: "Payment Date", value: "created" },
        { text: "Actions", value: "ACTIONS" },
      ],
      paymentsData: [],
      deletePaymentDialog: false,
      editedIndex: -1,
      loading: false,
    };
  },
  methods: {
    async getData() {
      this.rosterData = [];
      this.paymentsData = [];
      this.loading = true;

      let rosterQuery = await axios.get(`api/modals/agent/roster/${this.agent.agentID}`);
      let paymentsQuery = await axios.get(`api/modals/agent/payments/${this.agent.agentID}`);
        
      this.rosterData = rosterQuery.data;
      this.paymentsData = paymentsQuery.data;
      this.loading = false;
    },
    newPaymentSuccess(item) {
      this.paymentsData.push(item);
      this.snackInfo = true;
    },
    setEditItem(item) {
      this.editedIndex = this.paymentsData.indexOf(item);
    },
    editSuccess(item) {
      // set editable field values 
      this.paymentsData[this.editedIndex].created = item.paymentDate;
      this.paymentsData[this.editedIndex].paymentType = item.paymentType;
      this.paymentsData[this.editedIndex].amtPaid = item.amountPaid;
      this.paymentsData[this.editedIndex].fmoPayment = item.fmoPayment;
      this.paymentsData[this.editedIndex].paid = item.paid;
      this.$nextTick(() => {
        this.editedIndex = -1
      })
      this.snackInfo = true;
    },
    deletePayment(payment) {
      axios.post("/api/payments/delete", payment).then(response => {
        if(response.status === 200) {
          // find and remove deleted payment
          let deletedItem = this.paymentsData.indexOf(payment);
          this.paymentsData.splice(deletedItem, 1);
          this.$nextTick(() => {
            this.deletedItem = -1
          })

          this.deletePaymentDialog = false;
          this.snackInfo = true;
        }
      })
      .catch(error => {
        console.log(error);
      });
      this.deletePaymentDialog = false
    },
    formatCurrency(num) {
      let parsed = parseFloat(num)
      if(parsed === 0) {
        return '$0.00'
      } else if (parsed != undefined && parsed != "") {
        return "$" + parsed.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
    }
  },
  watch: {
    async dialog() {
      if(this.dialog === true) {
        this.getData();
      }
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  overflow-x: hidden !important;
}

.stats-card {
  min-height: 285px;
}

.check-mark {
  font-weight: bold;
  color: green;
}
</style>
