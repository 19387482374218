<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="9">
          <v-text-field
            class="py-0"
            v-model="search"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            single-line
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-dialog v-model="fmoDialog" max-width="750px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" block dark color="accent" class="mb-16">
                <v-icon>mdi-account-plus</v-icon>
                Add FMO
              </v-btn>
            </template>
            <template>
              <v-form ref="fmoForm" v-model="fmoValid" lazy-validation>
                <v-card>
                  <v-card-title>Add FMO</v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newFmo.agencyNpn"
                          :rules="[(v) => !!v || 'Agency NPN is required']"
                          label="Agency NPN"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newFmo.agencyName"
                          :rules="[(v) => !!v || 'Agency Name is required']"
                          label="Agency Name"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="newFmo.contactName"
                          :rules="[(v) => !!v || 'Name is required']"
                          label="Contact Name"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Contact Phone"
                          v-model="newFmo.contactPhone"
                          @input="formatContactPhone"
                          required
                          :rules="[
                            newFmo.contactPhone != '' ||
                            'Must be a valid phone number'
                          ]"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="newFmo.contactEmail"
                          :rules="[
                            (v) => !!v || 'E-mail is required',
                            (v) =>
                              /.+@.+\..+/.test(v) || 'E-mail must be valid',
                            (v) =>
                              !/\s/.test(v) ||
                              'Please remove spaces from email',
                          ]"
                          label="Contact Email"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newFmo.address1"
                          :rules="[(v) => !!v || 'Address is required']"
                          label="Address 1"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newFmo.address2"
                          label="Address 2"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="newFmo.city"
                          :rules="[(v) => !!v || 'City is required']"
                          label="City"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          label="State"
                          v-model="newFmo.state"
                          :items="stateList"
                          required
                          :rules="[(v) => !!v || 'State is required']"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="ZIP"
                          v-model="newFmo.zip"
                          required
                          :rules="[
                            newFmo.zip != '' ||
                            'ZIP is required',
                            /^[^a-zA-Z]+$/.test(newFmo.zip) ||
                            'This field cannot contain letters'
                          ]"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="FMO Commission Rate"
                          v-model="newFmo.commissionRate"
                          type="number"
                          prefix="$"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="New Enrollment Commission Rate"
                          v-model="newFmo.newEnrollmentRate"
                          type="number"
                          prefix="$"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Renewal Commission Rate"
                          v-model="newFmo.renewalRate"
                          type="number"
                          prefix="$"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Plan To Plan Commission Rate"
                          v-model="newFmo.planToPlanRate"
                          type="number"
                          prefix="$"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                      @click="cancelNewFmo"
                      color="error" 
                      text
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      @click="validateNewFmo"
                      :disabled="!fmoValid"
                      color="success"
                      text
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </template>
          </v-dialog>
        </v-col>
        <v-checkbox v-model="showCommissionRates" class="hidden-md-and-up ml-3" label="Show Commission Rates"></v-checkbox>
      </v-row>

      <v-data-table
        :loading="load"
        :headers="headers"
        :items="fmoData"
        :search="search"
        multi-sort
        loading-text="Loading data..."
        class="elevation-0 col-md-12 m-auto"
      >
        <template v-slot:item.agentCommission="{ item }">
          $ {{ item.agentCommission }}
        </template>
        
        <template v-slot:item.commissionRate="{ item }">
          $ {{ item.commissionRate }}
        </template>
        
        <template v-slot:item.ACTIONS="{ item }">
          <v-icon small class="mr-2" @click="openEditDialog(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="openDeleteDialog(item)">
            mdi-delete
          </v-icon>
        </template>

        <template slot="footer">
          <v-checkbox class="hidden-sm-and-down" v-model="showCommissionRates" label="Show Commission Rates"></v-checkbox>
          <ExportCSV :data="csvData" :name="'agent-testing.csv'">
            <v-btn color="accent" text class="export-button">
              Export to CSV
            </v-btn>
          </ExportCSV>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="editDialog" max-width="750px">
      <template>
        <v-form ref="editForm" v-model="editValid" lazy-validation>
          <v-card>
            <v-card-title>Edit {{ itemToEdit.agencyName }}</v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemToEdit.agencyName"
                    :rules="[(v) => !!v || 'Agency Name is required']"
                    label="Agency Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemToEdit.contactName"
                    :rules="[(v) => !!v || 'Name is required']"
                    label="Contact Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Contact Phone"
                    v-model="itemToEdit.contactPhone"
                    @input="formatContactPhone"
                    required
                    :rules="[
                      itemToEdit.contactPhone != '' ||
                      'Must be a valid phone number'
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemToEdit.contactEmail"
                    :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) =>
                        /.+@.+\..+/.test(v) || 'E-mail must be valid',
                      (v) =>
                        !/\s/.test(v) ||
                        'Please remove spaces from email',
                    ]"
                    label="Contact Email"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemToEdit.address1"
                    :rules="[(v) => !!v || 'Address is required']"
                    label="Address 1"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemToEdit.address2"
                    label="Address 2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="itemToEdit.city"
                    :rules="[(v) => !!v || 'City is required']"
                    label="City"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    label="State"
                    v-model="itemToEdit.state"
                    :items="stateList"
                    required
                    :rules="[(v) => !!v || 'State is required']"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="ZIP"
                    v-model="itemToEdit.zip"
                    required
                    :rules="[
                      itemToEdit.zip != '' ||
                      'ZIP is required',
                      /^[^a-zA-Z]+$/.test(itemToEdit.zip) ||
                      'This field cannot contain letters'
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="FMO Commission Rate"
                    v-model="itemToEdit.commissionRate"
                    type="number"
                    prefix="$"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="New Enrollment Commission Rate"
                    v-model="itemToEdit.newEnrollmentRate"
                    type="number"
                    prefix="$"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Renewal Commission Rate"
                    v-model="itemToEdit.renewalRate"
                    type="number"
                    prefix="$"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Plan To Plan Commission Rate"
                    v-model="itemToEdit.planToPlanRate"
                    type="number"
                    prefix="$"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                @click="cancelEdit"
                color="error" 
                text
              >
                Cancel
              </v-btn>
              <v-btn
                @click="validateEdit"
                :disabled="!editValid"
                color="success"
                text
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500px" :retain-focus="false">
      <template>
        <v-card>
          <v-card-title>
            Are you sure?
          </v-card-title>

          <v-card-text>
            Deleting an FMO is an irreversible action. 
            Are you sure you wish to delete this record?
          </v-card-text>

          <v-card-actions>
            <v-btn @click="deleteFmo()" color="primary" text>
              Yes
            </v-btn>

            <v-btn @click="deleteDialog = false" text>No</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-snackbar
      v-model="existingSnackbar"
      color="error"
      :timeout="5000"
    >
      An FMO already exists with the same Agency NPN.
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="existingSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import ExportCSV from "vue-json-csv";

export default {
  name: "FmoAdmin",
  components: {
    ExportCSV,
  },
  data() {
    return {
      search: "",
      load: false,
      fmoData: [],
      csvData: [],
      showCommissionRates: false,
      fmoDialog: false,
      fmoValid: false,
      newFmo: {
        agencyNpn: '',
        agencyName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        // agentCommission: '0.00', // removing this for now
        commissionRate: '0.00',
        newEnrollmentRate: '0.00',
        renewalRate: '0.00',
        planToPlanRate: '0.00'
      },
      stateList: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      editDialog: false,
      editValid: false,
      itemToEdit: {},
      editedIndex: -1,
      deleteDialog: false,
      itemToDelete: {},
      existingSnackbar: false,
    };
  },
  methods: {
    async getAllFmo() {
      this.load = true;

      await axios.get("/api/fmo/all").then(res => {
        this.fmoData = res.data;
        this.csvData = res.data;
        this.load = false;
      })
    },
    validateNewFmo() {
      if(this.$refs.fmoForm.validate()) {
        this.saveFmo();
      }
    },
    async saveFmo() {
      let fmo = await axios.post("/api/fmo/new", this.newFmo)
      
      if(fmo.data === 'existingNpn') {
        this.existingSnackbar = true;
      } else {
        this.fmoData.push(fmo.data);
        this.cancelNewFmo();
      }
    },
    cancelNewFmo() {
      this.newFmo = {
        agencyNpn: '',
        agencyName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        agentCommission: '0.00',
        commissionRate: '0.00',
        newEnrollmentRate: '0.00',
        renewalRate: '0.00',
        planToPlanRate: '0.00',
      }
      this.fmoDialog = false;
      this.$refs.fmoForm.reset();
    },
    openEditDialog(item) {
      this.editedIndex = this.fmoData.indexOf(item);
      this.itemToEdit = Object.assign({}, item);
      this.editDialog = true;
    },
    validateEdit() {
      if(this.$refs.editForm.validate()) {
        this.saveEdit();
      }
    },
    async saveEdit() {
      await axios
        .post("/api/fmo/update", this.itemToEdit)
        .then(res => {
          // replace existing item in fmoData to reflect changes
          this.fmoData.splice(this.editedIndex, 1, res.data);
          this.cancelEdit();
        })
        .catch((err) => {
          console.log(err);
        });
      
    },
    cancelEdit() {
      this.itemToEdit = {};
      this.editedIndex = -1;
      this.editDialog = false;
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.itemToDelete = item;
    },
    deleteFmo() {
      const index = this.fmoData.indexOf(this.itemToDelete);

      axios
        .post("/api/fmo/delete", this.itemToDelete)
        .then(() => {
          this.fmoData.splice(index, 1);
          this.itemToDelete = {};
          this.deleteDialog = false;
        })
        .catch((err) => {
          console.log(err);
          console.log("Error deleting FMO.");
        });
    },
    formatContactPhone() {
      // Added the if to remove an error in console 
      if (this.newFmo.contactPhone) {
        let x = this.newFmo.contactPhone
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.newFmo.contactPhone = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    },
  },
  computed: {
    headers() {
      if(this.showCommissionRates) {
        return [ 
          { text: "Actions", value: "ACTIONS", sortable: false },
          { text: "Agency NPN", value: "agencyNpn" },
          { text: "Agency Name", value: "agencyName" },
          { text: "Contact Name", value: "contactName"},
          { text: "Contact Phone", value: "contactPhone", width: '125px' },
          { text: "Contact Email", value: "contactEmail"},
          { text: "Plan to Plan Commission", value: "planToPlanRate" },
          { text: "Renewal Commission", value: "renewalRate" },
          { text: "New Enrollment Commission", value: "newEnrollmentRate" },
          { text: "Commission Rate", value: "commissionRate" },
          { text: "Address 1", value: "address1", width: '200px' },
          { text: "Address 2", value: "address2", width: '125px' },
          { text: "City", value: "city" },
          { text: "State", value: "state" },
          { text: "ZIP", value: "zip" },
        ]
      }
      else {
        return [ 
          { text: "Actions", value: "ACTIONS", sortable: false },
          { text: "Agency NPN", value: "agencyNpn" },
          { text: "Agency Name", value: "agencyName" },
          { text: "Contact Name", value: "contactName"},
          { text: "Contact Phone", value: "contactPhone", width: '125px' },
          { text: "Contact Email", value: "contactEmail"},
          { text: "Address 1", value: "address1", width: '200px' },
          { text: "Address 2", value: "address2", width: '125px' },
          { text: "City", value: "city" },
          { text: "State", value: "state" },
          { text: "ZIP", value: "zip" },
        ]
      }
    }
  },
  mounted() {
    this.getAllFmo();
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}

.v-card__title {
  word-break: break-word !important;
}
</style>
