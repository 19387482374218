<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12" lg="6">
          <v-combobox
            :items="memberIDs"
            label="Member ID"
            v-model="memberID"
            hint="Enter or select member ID from dropdown"
            @change="fieldChanged()"
            outlined
            :key="key"
            clearable
            @click="getInfo"
            dense            
          ></v-combobox>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="medicareID"
            label="Medicare/Medicaid ID"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.medicareID != '' || 'Please enter the patient\'s Medicare/Medicaid ID' , (!/[^a-zA-Z0-9]/.test(this.medicareID)) || 'Invalid entry, no special characters']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="5">
          <v-text-field
            v-model="lastName"
            label="Last Name"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.lastName != '' || 'Please enter the patient\'s last name', (!/[^-a-zA-Z]/.test(this.lastName)) || 'Invalid entry, no special characters or numbers']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="5">
          <v-text-field
            v-model="firstName"
            label="First Name"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.firstName != '' || 'Please enter the patient\'s first name' , (!/[^a-zA-Z]/.test(this.firstName)) || 'Invalid entry, no special characters or numbers']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-text-field
            v-model="middleInitial"
            label="Middle Initial"
            outlined
            dense
             :rules="[(!/[^a-zA-Z]/.test(this.middleInitial)) || 'Invalid entry, no special characters or numbers']"
            @change="fieldChanged()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="birthDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                label="Birth Date"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                @change="fieldChanged()"
                :rules="[
                  birthDate != '' || 'Please enter the patient\'s date of birth',
                ]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker 
              ref="picker"
              v-model="birthDate"  
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
            >
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="$refs.menu1.save(birthDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="3">
          <v-select
            v-model="gender"
            :items="sex"
            label="Sex"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.gender != null || 'Please select the patient\'s sex']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model="height"
            label="Height"
            outlined
            dense
            @change="fieldChanged()"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model="weight"
            label="Weight"
            outlined
            dense
            @change="fieldChanged()"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="email"
            label="Email"
            outlined
            dense
            @change="fieldChanged()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="phoneNumber"
            label="Phone Number"
            outlined
            dense
            @change="fieldChanged()"
            @input="acceptNumber()"
            required
            :rules="[
              this.phoneNumber != '' || 'Please enter the patient\'s phone number',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="5">
          <v-text-field
            v-model="address"
            label="Street Address"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.address != '' || 'Please enter the patient\'s address']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model="city"
            label="City"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.city != '' || 'Please enter the patient\'s city']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-text-field
            v-model="state"
            label="State"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.state != '' || 'Please enter the patient\'s state']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-text-field
            v-model="zip"
            label="ZIP Code"
            outlined
            dense
            type="number"
            @change="fieldChanged()"
            required
            :rules="[this.zip != '' || 'Please enter the patient\'s ZIP code', (!/[^-0-9]/.test(this.zip)) || 'Invalid entry, no non-numeric characters']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="language"
            label="Primary Language(s)"
            outlined
            dense
            @change="fieldChanged()"
            required
            hint="Enter the primary language of the patient"
            :rules="[this.language != '' || 'Please enter the patient\'s primary language', (!/[^a-zA-Z]/.test(this.language)) || 'Invalid entry, no special characters or numbers']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-model="education"
            :items="educationLevels"
            label="Highest Level of Education"
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.education != null || 'Please select the highest education level']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="race"
            :items="races"
            label="Race/ethnicity (select all that are applicable)"
            multiple
            outlined
            dense
            @change="fieldChanged()"
            required
            :rules="[this.race.length > 0 || 'Please select the patient\'s race']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: ["recordID"],
  data: () => ({
    valid: true,
    member_details: {},
    sex: ["Male", "Female"],
    countyList: ["Cobb", "Dekalb", "Fulton", "Gwinnett"],
    educationLevels: ["8th grade or less", "Some high school, but did not graduate", "High school or GED", "Technical School/Training", "Some college", "2 year college degree", "4 year college degree"],
    races: ["African American or Black", "Asian", "White or Caucasian", "Hispanic", "Pacific Islander", "Native Alaskan", "Native American", "Native Hawaiian", "Indian", "Other", "Prefer not to answer"],
    key: 1,
    memberIDs: [],
    memberID: "",
    medicareID: "",
    lastName: "",
    firstName: "",
    middleInitial: "",
    birthDate: new Date().toISOString().substr(0, 10),
    gender: null,
    height: '',
    weight: '',
    email: "",
    emailVerificationExpression: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    language: "", 
    education: null,
    race: [],
    menu1: false,
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          memberID: this.memberID,
          lastName: this.lastName,
          firstName: this.firstName,
          middleInitial: this.middleInitial,
          medicareID: this.medicareID,
          birthDate: this.birthDate,
          gender: this.gender,
          height: this.height,
          weight: this.weight,
          email: this.email,
          phoneNumber: this.phoneNumber,
          address: this.address,
          city: this.city,
          state: this.state,
          zip: this.zip,
          language: this.language,
          education: this.education,
          race: this.race
        });
        
      this.$emit('selectedTab', 1);
      this.$emit('unsavedChanges', false);
      
      }
    },
    acceptNumber() {
      var x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    fieldChanged() {
      this.$emit('unsavedChanges', true);
    },
    updateAfterChange() {
      this.key++;
    },
    getInfo() {
      axios
        .get(`api/modals/hra/memberIDcombobox`)
        .then((res) => {
          let response  = res.data;
          response.forEach((obj)=>{
            this.memberIDs.push(obj.memberID) 
          })
        });
    }
  },
  watch: {
    menu1 (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }, 
    'memberID': async function() {
      await axios
        .get(`api/modals/hra/memberinfoautofill/${this.memberID}`)
        .then((response) =>{
          this.memberDetails = response.data[0]
          this.lastName = this.memberDetails.lastName,
          this.firstName = this.memberDetails.firstName,
          this.memberID = this.memberDetails.memberID,
          this.middleInitial = this.memberDetails.middleInitial,
          this.medicareID = this.memberDetails.medicareID,
          this.birthDate = this.memberDetails.birthDate,
          this.gender = this.memberDetails.gender,
          this.height = this.memberDetails.height,
          this.weight = this.memberDetails.weight,
          this.email = this.memberDetails.email,
          this.phoneNumber = this.memberDetails.phoneNumber,
          this.address = this.memberDetails.address,
          this.city = this.memberDetails.city,
          this.state = this.memberDetails.state,
          this.zip = this.memberDetails.zip,
          this.language = this.memberDetails.language,
          this.education = this.memberDetails.education,
          this.race = this.memberDetails.race,
          this.updateAfterChange();                 
      })
    },  
  },
};
</script>
