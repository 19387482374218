<template>
  <v-container>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"
          ><span class="white--text">Cred Type</span></v-stepper-step
        >
        <v-stepper-step :complete="e1 > 2" step="2"
          ><span class="white--text">General Information</span></v-stepper-step
        >
        <v-stepper-step :complete="e1 > 3" step="3"
          ><span class="white--text">Location</span></v-stepper-step
        >
        <v-stepper-step
          :complete="e1 > 4"
          step="4"
          v-if="credType != 'Medical Group Affiliation'"
          ><span class="white--text">Licenses</span></v-stepper-step
        >
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="credType"
                :items="credTypeOptions"
                label="Please select type"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right pb-3">
              <v-btn text color="primary" :disabled="!credType" @click="e1++">
                Next
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <template v-if="credType != 'Medical Group Affiliation'">
          <v-stepper-content step="2">
            <v-form ref="form1" v-model="formData.valid" class="mx-auto">
              <v-row>
                <v-card-title class="justify-center">
                  <span>General Information</span>
                </v-card-title>

                <v-card-text>
                  <v-card outlined class="pa-2 mx-3">
                    <v-row id="section1">
                      <v-col cols="12" md="3" v-if="credType == 'Facility'">
                        <v-text-field
                          v-model="formData.FacilityName"
                          label="Facility Name"
                          outlined
                          required
                          :rules="[
                            formData.FacilityName !== ''
                              ? true
                              : 'Facility Name is required',
                          ]"
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="credType !== 'Facility'">
                        <v-text-field
                          v-model="formData.RenderingProviderLastName"
                          :rules="[
                            formData.RenderingProviderLastName.length > 0
                              ? true
                              : 'This field is required',
                          ]"
                          label="Provider Last Name"
                          required
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="credType !== 'Facility'">
                        <v-text-field
                          v-model="formData.RenderingProviderFirstName"
                          label="Provider First Name"
                          :rules="[
                            formData.RenderingProviderFirstName.length > 0
                              ? true
                              : 'This field is required',
                          ]"
                          required
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="credType !== 'Facility'">
                        <v-text-field
                          v-model="formData.RenderingProviderMiddleName"
                          label="Provider Middle Name"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="credType !== 'Facility'">
                        <v-select
                          v-model="formData.Gender"
                          :items="['Male', 'Female']"
                          :rules="[
                            formData.Gender.length > 0
                              ? true
                              : 'This field is required',
                          ]"
                          label="Gender"
                          required
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="formData.Company"
                          label="Company"
                          :rules="[
                            formData.Company.length > 0
                              ? true
                              : 'This field is required',
                          ]"
                          required
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="formData.RenderingNPI"
                          label="Provider NPI"
                          :rules="[
                            formData.RenderingNPI.length > 0
                              ? true
                              : 'Individual NPI is required',
                          ]"
                          required
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-select
                          v-model="formData.ProviderType"
                          label="Provider Type"
                          :items="providerTypes"
                          required
                          :rules="[
                            formData.ProviderType.length > 0
                              ? true
                              : 'This field is required',
                          ]"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" v-if="credType !== 'Facility'">
                        <v-text-field
                          v-model="formData.Degree"
                          label="Provider Degree"
                          :rules="[
                            formData.Degree.length > 0
                              ? true
                              : 'This field is required',
                          ]"
                          required
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" v-if="credType !== 'Facility'">
                        <v-menu
                          ref="dobPicker"
                          v-model="formData.dobPicker"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                          max-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formData.DOB"
                              label="Birth Date"
                              append-icon="mdi-calendar"
                              readonly
                              :rules="[
                                formData.DOB.length > 0
                                  ? true
                                  : 'This field is required',
                              ]"
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker ref="picker" v-model="formData.DOB">
                            <v-spacer></v-spacer>
                            <v-btn
                              outlined
                              color="primary"
                              @click="dobPicker = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              depressed
                              color="primary"
                              @click="$refs.dobPicker.save(formData.DOB)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4" v-if="credType !== 'Facility'">
                        <v-text-field
                          v-model="formData.SSN"
                          label="SSN"
                          outlined
                          dense
                          :rules="[
                            (v) =>
                              v && !/^[^a-zA-Z]+$/.test(v)
                                ? 'This field cannot contain letters'
                                : true,
                          ]"
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="formData.ContactPhone"
                          label="Contact Phone Number"
                          required
                          :rules="[
                            formData.ContactPhone.length > 6
                              ? true
                              : 'This field is required',
                          ]"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="text-right pb-3">
                <v-btn
                  text
                  color="primary"
                  :disabled="!credType"
                  @click="validateNext(1)"
                >
                  Next
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form ref="form2" v-model="formData.valid" class="mx-auto">
              <!-- Network Participation -->
              <v-card flat class="pa-2 mx-3">
                <v-card-title class="justify-center">
                  Network Participation
                </v-card-title>
                <v-row id="section3">
                  <v-col cols="12" md="4">
                    <v-menu
                      ref="clinicOrigDatePicker"
                      v-model="clinicOrigDatePickerFlag"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Load Date"
                          append-icon="mdi-calendar"
                          v-model="formData.Locations[0].LoadDate"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="formData.Locations[0].LoadDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="clinicOrigDatePickerFlag = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.clinicOrigDatePicker.save(
                              formData.Locations[0].LoadDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- <v-col cols="12" md="4">
                    <v-menu
                      ref="clinicInitDatePicker"
                      v-model="clinicInitDatePickerFlag"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Date assigned"
                          append-icon="mdi-calendar"
                          v-model="formData.Locations[0].ClinicInitalCredDate"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="formData.Locations[0].ClinicInitalCredDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="clinicInitDatePickerFlag = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.clinicInitDatePicker.save(
                              formData.Locations[0].ClinicInitalCredDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col> -->
                  <v-col cols="12" md="4">
                    <v-menu
                      ref="clinicRecredDatePickerFlag"
                      v-model="clinicRecredDatePickerFlag"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Recred Date"
                          append-icon="mdi-calendar"
                          v-model="formData.Locations[0].ClinicReCredDate"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="formData.Locations[0].ClinicReCredDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="clinicRecredDatePickerFlag = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.clinicRecredDatePickerFlag.save(
                              formData.Locations[0].ClinicReCredDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="sonderCredDatePicker"
                      v-model="sonderCredDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Sonder Cred Date"
                          append-icon="mdi-calendar"
                          v-model="
                            formData.Locations[0].SonderCredDate
                          "
                          :rules="[
                            formData.Locations[0].SonderCredDate !=
                              '' || 'Please enter a date',
                          ]"
                          :disabled="affiliated"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="
                          formData.Locations[0].SonderCredDate
                        "
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="sonderCredDatePicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.sonderCredDatePicker.save(
                              formData.Locations[0].SonderCredDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="formData.Locations[0].MedicalGroupAffiliation"
                      :items="medicalAffiliates"
                      @change="setMedGroup"
                      outlined
                      dense
                      clearable
                      label="Medical Group Affiliation"
                      class="text-subtitle-1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="Status"
                      v-model="formData.Locations[0].Status"
                      :items="locationStatuses"
                      required
                      :rules="[
                        formData.Locations[0].Status != '' ||
                          'Please make sure this field is not empty',
                      ]"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="Accepting Patients"
                      v-model="formData.Locations[0].AcceptingNewPatients"
                      :items="[
                        'Not Accepting Patients',
                        'Family of Patient',
                        'Current Patients',
                        'Accepting New Patients',
                      ]"
                      required
                      :rules="[
                        formData.Locations[0].AcceptingNewPatients != '' ||
                          'Please make sure this field is not empty',
                      ]"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox
                      label="Hospital Based"
                      v-model="formData.Locations[0].HospitalBased"
                      required
                      outlined
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox
                      label="Uses CMSMA"
                      v-model="formData.Locations[0].UsesCMSMA"
                      outlined
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Fee Schedule Information -->
              <v-card flat class="pa-2 mx-3">
                <v-card-title class="justify-center">
                  <span>Fee Schedule Information</span>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="formData.Locations[0].FeeScheduleType"
                        label="Fee Schedule Type"
                        :items="['Fee by Service', 'Capitation']"
                        required
                        :rules="[
                          formData.Locations[0].FeeScheduleType.length > 6
                            ? true
                            : 'This field is required',
                        ]"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="feeScheduleEffectiveDatePickerFlag"
                        v-model="feeScheduleEffectiveDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              formData.Locations[0]
                                .ProviderEffectiveDatewithFeeSchedule
                            "
                            label="Fee Schedule Effective Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="
                            formData.Locations[0]
                              .ProviderEffectiveDatewithFeeSchedule
                          "
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="feeScheduleEffectiveDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.feeScheduleEffectiveDatePickerFlag.save(
                                formData.ProviderEffectiveDatewithFeeSchedule
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="feeScheduleTerminationDatePickerFlag"
                        v-model="feeScheduleTerminationDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              formData.Locations[0]
                                .ProviderTerminationDatewithFeeSchedule
                            "
                            label="Fee Schedule Termination Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="
                            formData.Locations[0]
                              .ProviderTerminationDatewithFeeSchedule
                          "
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="
                              feeScheduleTerminationDatePickerFlag = false
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.feeScheduleTerminationDatePickerFlag.save(
                                formData.ProviderTerminationDatewithFeeSchedule
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <template
                      v-if="
                        formData.Locations[0].FeeScheduleType ===
                        'Fee by Service'
                      "
                    >
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.lazy="
                            formData.Locations[0].FeeSchedulePercent
                          "
                          suffix="%"
                          label="Fee Schedule Percent"
                          type="number"
                          min="0"
                          max="999"
                          :rules="[
                            (v) => v >= 0 || 'Cannot have negative percent',
                            (v) => v < 1000 || 'Percent must be 3 digits',
                          ]"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <template
                      v-if="
                        formData.Locations[0].FeeScheduleType === 'Capitation'
                      "
                    >
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="formData.Locations[0].CapitationAmount"
                          label="Capitation Amount"
                          outlined
                          dense
                          class="text-subtitle-1"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="
                            formData.Locations[0].maxDaysToSubmitInpatientClaim
                          "
                          label="Max Days To Submit Inpatient Claim"
                          type="number"
                          min="0"
                          max="999"
                          :rules="[(v) => v >= 0 || 'Cannot be negative']"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="
                            formData.Locations[0].maxDaysToSubmitOutpatientClaim
                          "
                          label="Max Days To Submit Outpatient Claim"
                          type="number"
                          min="0"
                          max="999"
                          :rules="[(v) => v >= 0 || 'Cannot be negative']"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Other Info -->
              <v-card flat class="pa-2 mx-3">
                <v-card-title class="justify-center">
                  <span>Other Info</span>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Contact Phone"
                        v-model="formData.Locations[0].contact_phone"
                        :rules="[
                          formData.Locations[0].contact_phone.length > 0
                            ? true
                            : 'This field is required',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="formData.Locations[0].contact_email"
                        label="Contact Email"
                        required
                        outlined
                        dense
                        :rules="[
                          formData.Locations[0].contact_email.length > 0
                            ? true
                            : 'This field is required',
                        ]"
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="riskManagementEffDatePickerFlag"
                        v-model="riskManagementEffDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              formData.Locations[0].riskManagementEffDate
                            "
                            label="Risk Management Effective Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.Locations[0].riskManagementEffDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="riskManagementEffDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.riskManagementEffDatePickerFlag.save(
                                formData.Locations[0].riskManagementEffDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="riskManagementExpDatePickerFlag"
                        v-model="riskManagementExpDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              formData.Locations[0].riskManagementExpDate
                            "
                            label="Risk Management Expiration Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.Locations[0].riskManagementExpDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="riskManagementExpDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.riskManagementExpDatePickerFlag.save(
                                formData.Locations[0].riskManagementExpDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="mocAttestationDatePickerFlag"
                        v-model="mocAttestationDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.Locations[0].MocAttestationDate"
                            label="Moc Attestation Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.Locations[0].MocAttestationDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="mocAttestationDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.mocAttestationDatePickerFlag.save(
                                formData.Locations[0].MocAttestationDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" v-if="credType === 'Facility'">
                      <v-text-field
                        v-model="formData.Locations[0].MedicareCertifiedBeds"
                        label="Medicare Certified Beds"
                        outlined
                        dense
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="formData.Locations[0].TaxonomyCode"
                        label="Taxonomy Code"
                        outlined
                        dense
                        class="text-subtitle-1"
                        :rules="[
                          formData.Locations[0].TaxonomyCode.length === 10 ||
                            'Please enter a valid 10 character',
                        ]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Service Location Info  -->
              <v-card
                flat
                class="pa-2 mx-3"
              >
                <v-card-title class="justify-center">
                  <span>Service Location Info</span>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Service Location Name"
                        v-model="formData.Locations[0].ServiceLocationName"
                        :rules="[
                          formData.Locations[0].ServiceLocationName != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        :readonly="affiliated"
                        :required="!affiliated"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="credType === 'Provider'" cols="12" md="3">
                      <v-checkbox
                        label="Default Address"
                        v-model="formData.Locations[0].DefaultAddress"
                        :readonly="affiliated"
                        :required="!affiliated"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Service Location Address"
                        v-model="formData.Locations[0].ServiceLocationAddress"
                        :readonly="affiliated"
                        :required="!affiliated"
                        :rules="[
                          formData.Locations[0].ServiceLocationAddress != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="City"
                        v-model="formData.Locations[0].ServiceLocationCity"
                        :readonly="affiliated"
                        :required="!affiliated"
                        :rules="[
                          formData.Locations[0].ServiceLocationCity != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="State"
                        v-model="formData.Locations[0].ServiceLocationState"
                        :readonly="affiliated"
                        :required="!affiliated"
                        :rules="[
                          formData.Locations[0].ServiceLocationState != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Zip"
                        v-model="formData.Locations[0].ServiceLocationZip"
                        :readonly="affiliated"
                        :required="!affiliated"
                        :rules="[
                          formData.Locations[0].ServiceLocationZip != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="County"
                        v-model="formData.Locations[0].ServiceLocationCounty"
                        :readonly="affiliated"
                        :required="!affiliated"
                        :rules="[
                          formData.Locations[0].ServiceLocationCounty != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Phone"
                        v-model="formData.Locations[0].ServiceLocationPhone"
                        @input="formatServicePhone"
                        :rules="[
                          formData.Locations[0].ServiceLocationPhone != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Fax"
                        v-model="formData.Locations[0].ServiceLocationFax"
                        @input="formatServiceFax"
                        :readonly="affiliated"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Billing  -->
              <v-card
                flat
                class="pa-2 mx-3"
              >
                <v-card-title class="justify-center">
                  <span>Billing</span>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="primarySpecialty"
                        label="Primary Specialty"
                        v-model="formData.Locations[0].PrimarySpecialty"
                        disabled
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider Name"
                        v-model="formData.Locations[0].BillingProviderName"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider Name as Appears on Check"
                        v-model="
                          formData.Locations[0]
                            .BillingProviderNameAsAppearsonCheck
                        "
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider Tax ID"
                        v-model="formData.Locations[0].BillingProviderTaxId"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider NPI"
                        v-model="formData.Locations[0].BillingProviderNPI"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        label="Billing Provider Address Type"
                        v-model="
                          formData.Locations[0].BillingProviderAddressType
                        "
                        disabled
                        :items="['Primary', 'Secondary']"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to Address 1"
                        v-model="formData.Locations[0].RemittoAddress1"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to Address 2"
                        v-model="formData.Locations[0].RemittoAddress2"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to City"
                        v-model="formData.Locations[0].RemittoCity"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to State"
                        v-model="formData.Locations[0].RemittoState"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to ZIP"
                        v-model="formData.Locations[0].RemittoZip"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing County"
                        v-model="formData.Locations[0].BillingCounty"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Phone"
                        v-model="formData.Locations[0].BillingPhone"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Fax"
                        v-model="formData.Locations[0].BillingFax"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
            <v-row>
              <v-col class="text-right pb-3">
                <v-btn
                  text
                  color="primary"
                  :disabled="!credType"
                  @click="validateNext(2)"
                >
                  Next
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-form ref="form3" v-model="formData.valid" class="mx-auto">
              <!-- Medicaid Info -->
              <v-card flat class="pa-2 mx-3">
                <v-card-title class="justify-center">
                  Medicare/Medicaid Info
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="formData.MedicareID"
                        label="Medicare ID"
                        outlined
                        dense
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="medicareAwardDatePickerFlag"
                        v-model="medicareAwardDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.McareAwardDate"
                            label="Medicare Award Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.McareAwardDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="medicareAwardDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.medicareAwardDatePickerFlag.save(
                                formData.McareAwardDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="medicareExpDatePickerFlag"
                        v-model="medicareExpDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.McareExpDate"
                            label="Medicare Expiration Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.McareExpDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="medicareExpDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.medicareExpDatePickerFlag.save(
                                formData.McareExpDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="formData.MedicaidID"
                        label="Medicaid ID"
                        outlined
                        dense
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="medicaidAwardDatePickerFlag"
                        v-model="medicaidAwardDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.McaidAwardDate"
                            label="Medicaid Award Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.McaidAwardDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="medicaidAwardDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.medicaidAwardDatePickerFlag.save(
                                formData.McaidAwardDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="medicaidExpDatePickerFlag"
                        v-model="medicaidExpDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.McaidExpDate"
                            label="Medicaid Expiration Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.McaidExpDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="medicaidExpDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.medicaidExpDatePickerFlag.save(
                                formData.McaidExpDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Licenses -->
              <v-card flat class="pa-2 mx-3" v-if="credType === 'Provider'">
                <v-card-title class="justify-center"> Licenses </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="formData.StateProfesionalLicenseNumber"
                        label="State License Number"
                        outlined
                        dense
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="stateProfesionalLicenseNumberFlag"
                        v-model="stateProfesionalLicenseNumberFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.stateLicenseAwardDate"
                            label="State Award date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.stateLicenseAwardDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="stateProfesionalLicenseNumberFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.stateProfesionalLicenseNumberFlag.save(
                                formData.stateLicenseAwardDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="stateExpDatePickerFlag"
                        v-model="stateExpDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.stateLicenseExpirationDate"
                            label="State Expiration Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.stateLicenseExpirationDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="stateExpDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.stateExpDatePickerFlag.save(
                                formData.stateLicenseExpirationDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="formData.DEALicenseNumber"
                        label="DEA License Number"
                        outlined
                        dense
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="deaAwardDatePickerFlag"
                        v-model="deaAwardDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.DEAAwardDate"
                            label="DEA Award Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.DEAAwardDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="deaAwardDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.deaAwardDatePickerFlag.save(
                                formData.DEAAwardDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        ref="deaExpDatePickerFlag"
                        v-model="deaExpDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.DEAExpDate"
                            label="DEA Expiration Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.DEAExpDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="deaExpDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.deaExpDatePickerFlag.save(
                                formData.DEAExpDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Professional & General Malpractice Liability -->
              <v-card flat class="pa-2 mx-3">
                <v-card-title class="justify-center">
                  Professional & General Malpractice Liability
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="formData.malPracPolicyName"
                        label="Policy Name"
                        outlined
                        dense
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="formData.malPracPolicyNumber"
                        label="Policy Number"
                        outlined
                        dense
                        class="text-subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="malPracticeIssueDatePickerFlag"
                        v-model="malPracticeIssueDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.malPracIssueDate"
                            label="Issue Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.malPracIssueDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="malPracticeIssueDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.malPracticeIssueDatePickerFlag.save(
                                formData.malPracIssueDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="malpracticeExpDatePickerFlag"
                        v-model="malpracticeExpDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formData.malPracExpDate"
                            label="Expiration Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="formData.malPracExpDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="malpracticeExpDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.malpracticeExpDatePickerFlag.save(
                                formData.MalPractice_ExpDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.malPracDetails"
                        label="Limitations & Coverage Amount"
                        outlined
                        dense
                        class="text-subtitle-1"
                        type="number"
                        prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Board Certification Info -->
              <v-row v-if="credType === 'Provider'">
                <v-col cols="12">
                  <v-card flat class="pa-2 mx-3" height="100%">
                    <v-card-title class="justify-center">
                      Board Certification Info
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="2">
                          <v-checkbox
                            v-model="formData.BoardCertified1"
                            label="Board Certified 1"
                            outlined
                            dense
                            class="text-subtitle-1"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="formData.BoardCertified1Specialty"
                            label="Board Certified Specialty"
                            outlined
                            dense
                            :disabled="!formData.BoardCertified1"
                            class="text-subtitle-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-menu
                            ref="boardCert1EffectiveDatePickerFlag"
                            v-model="boardCert1EffectiveDatePickerFlag"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formData.BoardCertified1EffectiveDate"
                                label="Certification Effective Date"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                :disabled="!formData.BoardCertified1"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="formData.BoardCertified1EffectiveDate"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="
                                  boardCert1EffectiveDatePickerFlag = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                @click="
                                  $refs.boardCert1EffectiveDatePickerFlag.save(
                                    formData.BoardCertified1EffectiveDate
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-menu
                            ref="boardCert1ExpDatePickerFlag"
                            v-model="boardCert1ExpDatePickerFlag"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formData.BoardCertified1ExpDate"
                                label="Certification Expiration Date"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                :disabled="
                                  formData['BoardCertified1(YN)'] === 'N'
                                "
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="formData.BoardCertified1ExpDate"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="primary"
                                @click="boardCert1ExpDatePickerFlag = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                depressed
                                color="primary"
                                @click="
                                  $refs.boardCert1ExpDatePickerFlag.save(
                                    formData.BoardCertified1ExpDate
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <template v-if="formData.BoardCertified1">
                          <v-col cols="12" md="2">
                            <v-checkbox
                              v-model="formData.BoardCertified2"
                              label="Board Certified 2"
                              outlined
                              dense
                              class="text-subtitle-1"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="formData.BoardCertified2Specialty"
                              label="Board Certified Specialty"
                              outlined
                              dense
                              :disabled="!formData.BoardCertified2"
                              class="text-subtitle-1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="boardCert2EffectiveDatePickerFlag"
                              v-model="boardCert2EffectiveDatePickerFlag"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    formData.BoardCertified2EffectiveDate
                                  "
                                  label="Certification Effective Date"
                                  append-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  dense
                                  :disabled="!formData.BoardCertified2"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="formData.BoardCertified2EffectiveDate"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="
                                    boardCert2EffectiveDatePickerFlag = false
                                  "
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.boardCert2EffectiveDatePickerFlag.save(
                                      formData.BoardCertified2EffectiveDate
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="boardCert2ExpDatePickerFlag"
                              v-model="boardCert2ExpDatePickerFlag"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formData.BoardCertified2ExpDate"
                                  label="Certification Expiration Date"
                                  append-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  dense
                                  :disabled="!formData.BoardCertified2"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="formData.BoardCertified2ExpDate"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="boardCert2ExpDatePickerFlag = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.boardCert2ExpDatePickerFlag.save(
                                      formData.BoardCertified1ExpDate
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </template>

                        <template
                          v-if="
                            formData.BoardCertified1 && formData.BoardCertified2
                          "
                        >
                          <v-col cols="12" md="2">
                            <v-checkbox
                              :v-model="formData.BoardCertified3"
                              label="Board Certified 3"
                              outlined
                              dense
                              class="text-subtitle-1"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="formData.BoardCertified3Specialty"
                              label="Board Certified Specialty"
                              outlined
                              dense
                              :disabled="!formData.BoardCertified3"
                              class="text-subtitle-1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="boardCert3EffectiveDatePickerFlag"
                              v-model="boardCert3EffectiveDatePickerFlag"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    formData.BoardCertified3EffectiveDate
                                  "
                                  label="Certification Effective Date"
                                  append-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  dense
                                  :disabled="!formData.BoardCertified3"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="formData.BoardCertified3EffectiveDate"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="
                                    boardCert3EffectiveDatePickerFlag = false
                                  "
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.boardCert3EffectiveDatePickerFlag.save(
                                      formData.BoardCertified3EffectiveDate
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="boardCert3ExpDatePickerFlag"
                              v-model="boardCert3ExpDatePickerFlag"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              max-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formData.BoardCertified3ExpDate"
                                  label="Certification Expiration Date"
                                  append-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  dense
                                  :disabled="!formData.BoardCertified3"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="formData.BoardCertified3ExpDate"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="boardCert3ExpDatePickerFlag = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="
                                    $refs.boardCert3ExpDatePickerFlag.save(
                                      formData.BoardCertified3ExpDate
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </template>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="text-right pb-3">
                <v-btn
                  text
                  color="primary"
                  :disabled="!credType"
                  @click="validateNext(3)"
                >
                  Next
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </template>
        <template v-else>
          <v-stepper-content
            step="2"
            v-if="credType === 'Medical Group Affiliation'"
          >
            <v-form ref="medForm1" v-model="medGroupFormData.valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="medGroupFormData.affiliate_name"
                    required
                    :rules="[
                      medGroupFormData.affiliate_name.length > 0 ||
                        'Must not be blank',
                    ]"
                    outlined
                    label="Affiliate Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="medGroupFormData.tax_id"
                    required
                    :rules="[!!medGroupFormData.tax_id || 'Must not be blank']"
                    outlined
                    label="Tax ID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="medGroupFormData.contact_fname"
                    required
                    :rules="[
                      medGroupFormData.contact_fname.length > 0 ||
                        'Must not be blank',
                    ]"
                    outlined
                    label="Contact First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="medGroupFormData.contact_lname"
                    required
                    :rules="[
                      medGroupFormData.contact_lname.length > 0 ||
                        'Must not be blank',
                    ]"
                    outlined
                    label="Contact Last Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox
                    label="Show to Enrollments"
                    v-model="medGroupFormData.showToEnrollments"
                    class="my-1 py-0"
                />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="medGroupFormData.contact_phone"
                    required
                    :rules="[
                      !!medGroupFormData.contact_phone || 'Must not be blank',
                    ]"
                    outlined
                    label="Contact Phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="medGroupFormData.contact_email"
                    required
                    :rules="[
                      medGroupFormData.contact_email.length > 0 ||
                        'Must not be blank',
                    ]"
                    outlined
                    label="Contact Email"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    v-model="medGroupFormData.TaxonomyCode"
                    label="Taxonomy Code"
                    outlined
                    dense
                    class="text-subtitle-1"
                    :rules="[
                      medGroupFormData.TaxonomyCode.length === 10 ||
                        'Please enter a valid 10 character',
                    ]"
                  >
                  </v-text-field>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col class="text-right pb-3">
                  <v-btn
                    text
                    color="primary"
                    :disabled="!credType"
                    @click="validateNext(1, true)"
                  >
                    Next
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form ref="medForm2">
              <v-card flat class="pa-2 mx-3 mt-1">
                <v-card-title class="justify-center">
                  Network Participation
                </v-card-title>
                <v-row id="section3">
                  <v-col cols="12" md="4">
                    <v-menu
                      ref="clinicOrigDatePicker"
                      v-model="clinicOrigDatePickerFlag"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Load Date"
                          append-icon="mdi-calendar"
                          v-model="medGroupFormData.LoadDate"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="medGroupFormData.LoadDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="clinicOrigDatePickerFlag = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.clinicOrigDatePicker.save(
                              medGroupFormData.LoadDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-menu
                      ref="clinicRecredDatePickerFlag"
                      v-model="clinicRecredDatePickerFlag"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Recred Date"
                          append-icon="mdi-calendar"
                          v-model="medGroupFormData.ClinicReCredDate"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="medGroupFormData.ClinicReCredDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="clinicRecredDatePickerFlag = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.clinicRecredDatePickerFlag.save(
                              medGroupFormData.ClinicReCredDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="sonderCredDatePicker"
                      v-model="sonderCredDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Sonder Cred Date"
                          append-icon="mdi-calendar"
                          v-model="
                            medGroupFormData.SonderCredDate
                          "
                          :rules="[
                            medGroupFormData.SonderCredDate !=
                              '' || 'Please enter a date',
                          ]"
                          :disabled="affiliated"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="
                          medGroupFormData.SonderCredDate
                        "
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="sonderCredDatePicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="
                            $refs.sonderCredDatePicker.save(
                              medGroupFormData.SonderCredDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="Status"
                      v-model="medGroupFormData.Status"
                      :items="locationStatuses"
                      required
                      :rules="[
                        medGroupFormData.Status != '' ||
                          'Please make sure this field is not empty',
                      ]"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="Accepting Patients"
                      v-model="medGroupFormData.AcceptingNewPatients"
                      :items="[
                        'Not Accepting Patients',
                        'Family of Patient',
                        'Current Patients',
                        'Accepting New Patients',
                      ]"
                      required
                      :rules="[
                        medGroupFormData.AcceptingNewPatients != '' ||
                          'Please make sure this field is not empty',
                      ]"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox
                      label="Hospital Based"
                      v-model="medGroupFormData.HospitalBased"
                      required
                      outlined
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox
                      label="Uses CMSMA"
                      v-model="medGroupFormData.UsesCMSMA"
                      outlined
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Fee Schedule Information -->
              <v-card flat class="pa-2 mx-3">
                <v-card-title class="justify-center">
                  <span>Fee Schedule Information</span>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="medGroupFormData.FeeScheduleType"
                        label="Fee Schedule Type"
                        :items="['Fee by Service', 'Capitation']"
                        required
                        :rules="[
                          medGroupFormData.FeeScheduleType.length > 6
                            ? true
                            : 'This field is required',
                        ]"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="feeScheduleEffectiveDatePickerFlag"
                        v-model="feeScheduleEffectiveDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              medGroupFormData.ProviderEffectiveDatewithFeeSchedule
                            "
                            label="Fee Schedule Effective Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            :rules="[
                              medGroupFormData.ProviderEffectiveDatewithFeeSchedule != '' || 'Please enter a Date.'
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="
                            medGroupFormData.ProviderEffectiveDatewithFeeSchedule
                          "
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="feeScheduleEffectiveDatePickerFlag = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.feeScheduleEffectiveDatePickerFlag.save(
                                formData.ProviderEffectiveDatewithFeeSchedule
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="feeScheduleTerminationDatePickerFlag"
                        v-model="feeScheduleTerminationDatePickerFlag"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              medGroupFormData.ProviderTerminationDatewithFeeSchedule
                            "
                            label="Fee Schedule Termination Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            :rules="[
                              medGroupFormData.ProviderTerminationDatewithFeeSchedule != '' || 'Please enter a Date.'
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="
                            medGroupFormData.ProviderTerminationDatewithFeeSchedule
                          "
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="
                              feeScheduleTerminationDatePickerFlag = false
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="
                              $refs.feeScheduleTerminationDatePickerFlag.save(
                                formData.ProviderTerminationDatewithFeeSchedule
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <template
                      v-if="
                        medGroupFormData.FeeScheduleType === 'Fee by Service'
                      "
                    >
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="medGroupFormData.FeeSchedulePercent"
                          suffix="%"
                          label="Fee Schedule Percent"
                          type="number"
                          min="0"
                          max="999"
                          :rules="[
                            (v) => v >= 0 || 'Cannot have negative percent',
                            (v) => v < 1000 || 'Percent must be 3 digits',
                          ]"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <template
                      v-if="medGroupFormData.FeeScheduleType === 'Capitation'"
                    >
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="medGroupFormData.MedicareCapitation"
                          label="Medicare Members"
                          type="number"
                          placeholder="0.00"
                          persistent-placeholder 
                          prefix="$"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="medGroupFormData.MedicaidCapitation"
                          label="Medicaid Members"
                          type="number"
                          placeholder="0.00"
                          persistent-placeholder 
                          prefix="$"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="
                            medGroupFormData.maxDaysToSubmitInpatientClaim
                          "
                          label="Max Days To Submit Inpatient Claim"
                          type="number"
                          min="0"
                          max="999"
                          :rules="[(v) => v >= 0 || 'Cannot be negative']"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="
                            medGroupFormData.maxDaysToSubmitOutpatientClaim
                          "
                          label="Max Days To Submit Outpatient Claim"
                          type="number"
                          min="0"
                          max="999"
                          :rules="[(v) => v >= 0 || 'Cannot be negative']"
                          outlined
                          dense
                          class="text-subtitle-1"
                        ></v-text-field>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- BILLING -->
              <v-card flat class="pa-2 mx-3">
                <v-card-title class="justify-center">
                  <span>Billing</span>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <!-- <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="primarySpecialty"
                        label="Primary Specialty"
                        v-model="medGroupFormData.PrimarySpecialty"
                        :rules="[
                          medGroupFormData.PrimarySpecialty != '' ||
                            'Please make sure this field is not empty',
                        ]"
                        outlined
                        dense
                      />
                    </v-col> -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider Name"
                        v-model="medGroupFormData.BillingProviderName"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider Name as Appears on Check"
                        v-model="
                          medGroupFormData.BillingProviderNameAsAppearsonCheck
                        "
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider Tax ID"
                        v-model="medGroupFormData.BillingProviderTaxId"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Provider NPI"
                        v-model="medGroupFormData.BillingProviderNPI"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        label="Billing Provider Address Type"
                        v-model="medGroupFormData.BillingProviderAddressType"
                        :items="['Primary', 'Secondary']"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to Address 1"
                        v-model="medGroupFormData.RemittoAddress1"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to Address 2"
                        v-model="medGroupFormData.RemittoAddress2"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to City"
                        v-model="medGroupFormData.RemittoCity"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to State"
                        v-model="medGroupFormData.RemittoState"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Remit to ZIP"
                        v-model="medGroupFormData.RemittoZip"
                        :rules="[
                          (v) =>
                            v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                              ? 'This field cannot contain letters'
                              : true,
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing County"
                        v-model="medGroupFormData.BillingCounty"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Phone"
                        v-model="medGroupFormData.BillingPhone"
                        @input="formatBillPhone"
                        :rules="[
                          (v) =>
                            v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                              ? 'This field cannot contain letters'
                              : true,
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Billing Fax"
                        v-model="medGroupFormData.BillingFax"
                        @input="formatBillFax"
                        :rules="[
                          (v) =>
                            v && !/^[^a-zA-Z]+$/.test(v)
                              ? 'This field cannot contain letters'
                              : true,
                        ]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row>
                <v-col class="text-right pb-3">
                  <v-btn text color="primary" @click="validateNext(3)">
                    <!---->
                    Next
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "CreateProvider",
  data() {
    return {
      credType: "",
      credTypeOptions: ["Provider", "Facility", "Medical Group Affiliation"],
      e1: 1,
      valid: false,
      ClinicInitalCredDateFlag: false,
      clinicOrigDatePickerFlag: false,
      feeScheduleEffectiveDatePickerFlag: false,
      feeScheduleTerminationDatePickerFlag: false,
      clinicRecredDatePickerFlag: false,
      clinicInitDatePickerFlag: false,
      riskManagementEffDatePickerFlag: false,
      riskManagementExpDatePickerFlag: false,
      MocAttestationDatePickerFlag: false,
      mocExpDatePickerFlag: false,
      medicareAwardDatePickerFlag: false,
      medicareExpDatePickerFlag: false,
      medicaidAwardDatePickerFlag: false,
      medicaidExpDatePickerFlag: false,
      stateLicenseAwardDateFlag: false,
      stateExpDatePickerFlag: false,
      deaExpDatePickerFlag: false,
      deaAwardDatePickerFlag: false,
      malpracticeExpDatePickerFlag: false,
      malPracPolicyNumberFlag: false,
      boardCert1EffectiveDatePickerFalse: false,
      boardCert1ExpDatePickerFlag: false,
      boardCert2EffectiveDatePickerFlag: false,
      boardCert2ExpDatePickerFlag: false,
      boardCert3EffectiveDatePickerFlag: false,
      boardCert3ExpDatePickerFlag: false,
      malPracticeIssueDatePickerFlag: false,
      boardCert1EffectiveDatePickerFlag: false,
      mocAttestationDatePickerFlag: false,
      dobPicker: false,
      sonderCredDatePicker: false,
      locationCategories: [
        "Delegated (Employed)",
        "Delegated (Non-Employed)",
        "Non-Delegated (Facility)",
        "Non-Delegated (Ancillary)",
        "Non-Delegated (Group)",
        "Non-Delegated (Individual Provider)",
      ],
      stateProfesionalLicenseNumberFlag: false,
      locationStatuses: [
        "Active",
        "Pending/ In-Process",
        "Ready For Review",
        "Ready ForCred Committee",
        "Pending CVO Check List",
        "Pending Updated Malpractice Insurance",
        "Inactive",
        "Termed",
        "Withdrawn",
        "Expired CAQH Attestation",
        "Listed on SAM GSA Report",
      ],
      providerTypes: [
        "Primary Care",
        "Specialist",
        "Ancillary",
        "Hospital Physician Group",
      ],
      formData: {
        FacilityName: "",
        Language: "",
        Language5: "",
        UniqueLocationIdentifier: uuidv4(),
        Language4: "",
        DOB: "",
        Language3: "",
        Language2: "",
        ContactPhone: "",
        SSN: "",
        Degree: "",
        ProviderType: "",
        FacilityNPI: "", // we should just have one NPI - no reason to seperate
        RenderingNPI: "",
        groupNPI: "",
        Company: "",
        Gender: "",
        RenderingProviderMiddleName: "",
        RenderingProviderFirstName: "",
        RenderingProviderLastName: "",
        Locations: [
          {
            // Fee Schedule
            FeeScheduleType: "Fee by Service",
            ProviderEffectiveDatewithFeeSchedule: "",
            ProviderTerminationDatewithFeeSchedule: "",
            MedicareFeeScheduleName: "",
            MedicaidFeeScheduleName: "",
            MedicareMemberPerMonth: "",
            MedicaidMemberPerMonth: "",
            FeeScheduleName: "",
            FeeSchedulePercent: "",

            // 'Other' Info
            riskManagementEffDate: "",
            riskManagementExpDate: "",
            MocAttestationDate: "",
            MocExpDate: this.mocExp,
            TaxonomyCode: "",
            contact_phone: "",
            contact_email: "",

            // Service Location Info
            ServiceLocationName: "",
            DefaultAddress: false,
            ServiceLocationAddress1: "",
            ServiceLocationAddress2: "",
            ServiceLocationCity: "",
            ServiceLocationState: "",
            ServiceLocationZip: "",
            ServiceLocationCounty: "",
            ServiceLocationPhone: "",
            ServiceLocationFax: "",

            // Network Participation
            MedicalGroupAffiliation: "",
            CredDate: "",
            RecredDate: "",
            ClinicReCredDate: "",
            Status: "",
            AcceptingNewPatients: "",
            HospitalBased: false,
            UsesCMSMA: false,

            // Billing
            PrimarySpecialty: "",
            SecondarySpecialty: "",
            BillingProviderName: "",
            BillingProviderNameAsAppearsonCheck: "",
            BillingProviderTaxId: "",
            BillingProviderNPI: "",
            BillingProviderAddressType: "",
            RemittoAddress1: "",
            RemittoAddress2: "",
            RemittoCity: "",
            RemittoState: "",
            RemittoZip: "",
            BillingCounty: "",
            BillingPhone: "",
            BillingFax: "",
          },
        ],
        riskManagementEffDate: "",
        riskManagementExpDate: "",
        MocAttestationDate: "",
        MocExpDate: this.mocExp,
        // "PCP(YesNo)": "",
        // "Category(DD)": "",
        ContractType: "",
        ContractTypeDCorDS: "",
        MedicareCertifiedBeds: "",
        "MedicareSpecialtyCode(HSD)": "",
        PrimarySpecialty: "",
        SecondarySpecialty: "",
        MedicareID: "",
        McareAwardDate: "",
        McareExpDate: "",
        MedicaidID: "",
        McaidAwardDate: "",
        McaidExpDate: "",
        StateProfesionalLicenseNumber: "",
        stateLicenseAwardDate: "",
        stateLicenseExpirationDate: "",
        DEALicenseNumber: "",
        DEAAwardDate: "",
        DEAExpDate: "",
        // malPracPolicyName: "",
        // malPracPolicyNumber: "",
        // malPracIssueDate: "",
        // malPracExpDate: "",
        // malPracDetails: "",
        BoardCertified1: false,
        BoardCertified1Specialty: "",
        BoardCertified1EffectiveDate: "",
        BoardCertified1ExpDate: "",
        BoardCertified2: false,
        BoardCertified2Specialty: "",
        BoardCertified2EffectiveDate: "",
        BoardCertified2ExpDate: "",
        BoardCertified3: false,
        BoardCertified3Specialty: "",
        BoardCertified3EffectiveDate: "",
        BoardCertified3ExpDate: "",
        maxDaysToSubmitInpatientClaim: "",
        maxDaysToSubmitOutpatientClaim: "",
      },
      medGroupFormData: {
        affiliate_name: "",
        contact_phone: "",
        contact_email: "",
        contact_fname: "",
        contact_lname: "",
        Status: "",
        // TaxonomyCode: "",
        showToEnrollments: false,
        FeeScheduleType: "Fee by Service",
        FeeSchedulePercent: "",
        ProviderEffectiveDatewithFeeSchedule: "",
        ProviderTerminationDatewithFeeSchedule: "",
        MedicareCapitation: 0,
        MedicaidCapitation: 0,
        maxDaysToSubmitInpatientClaim: null,
        maxDaysToSubmitOutpatientClaim: null,

        // Network Participation
        LoadDate: "",
        CredDate: "",
        RecredDate: "",
        ClinicReCredDate: "",
        AcceptingNewPatients: "",
        HospitalBased: false,
        UsesCMSMA: false,

        // Billing
        // PrimarySpecialty: "",
        // SecondarySpecialty: "",
        BillingProviderName: "",
        BillingProviderNameAsAppearsonCheck: "",
        BillingProviderTaxId: "",
        BillingProviderNPI: "",
        BillingProviderAddressType: "",
        RemittoAddress1: "",
        RemittoAddress2: "",
        RemittoCity: "",
        RemittoState: "",
        RemittoZip: "",
        BillingCounty: "",
        BillingPhone: "",
        BillingFax: "",
      },
      primarySpecialty: [
        "Adult Intensivist",
        "Allergy",
        "Anesthesia",
        "Bariatric Medicine/Surgery",
        "Burn/Trauma",
        "Cardiac Catheterization",
        "Cardiology",
        "Cardiovascular Surgery",
        "Colorectal Surgery",
        "Dermatology",
        "Electrophysiology",
        "Emergency Medicine",
        "Endocrinology",
        "Family Practice",
        "Gastroenterology",
        "General Surgery",
        "Geriatrics",
        "Gynecologic Oncology",
        "Hematology/Oncology",
        "Hepatobiliary",
        "Hospitalist",
        "Infectious Disease",
        "Internal Medicine",
        "Interventional Radiology",
        "Medical Genetics",
        "Neonatology",
        "Nephrology",
        "Neuroradiology",
        "Neurology",
        "Neurosurgery",
        "Nuclear Medicine",
        "Obstetrics & Gynecology",
        "Occupational Medicine",
        "Ophthalmology",
        "Oral Surgery",
        "Orthopedics",
        "Otolaryngology / Head & Neck Surgery",
        "Pain Management",
        "Palliative Care",
        "Pain Management",
        "Palliative Care",
        "Pathology: Surgical & Anatomic",
        "Pediatric Intensivist",
        "Pediatrics",
        "Pediatric Surgery",
        "Physical Medicine",
        "Plastic & Reconstructive Surgery",
        "Podiatric Surgery",
        "Psychiatry",
        "Pulmonary Medicine",
        "Radiation Oncology",
        "Radiology",
        "Rheumatology",
        "Surgical Oncology",
        "Thoracic Surgery",
        "Transplant Surgery",
        "Urology",
        "Vascular Surgery",
        "Wound Care",
        "Bariatric Surgery",
        "ENT",
        "General Surgery",
        "Gastroenterology",
        "Neurosurgery",
        "Obstetrics & Gynecology",
        "Ophthalmology",
        "Oral/Dental Surgery",
        "Orthopedic Surgery",
        "Pain Management",
        "Pediatric Surgery",
        "Plastic Surgery",
        "Podiatric Surgery",
        "Urology",
        "Vascular Surgery",
      ],
      medicalAffiliates: [],
    };
  },
  mounted() {
    this.getMedicalAffiliates();
  },
  methods: {
    async saveRecord() {
      await axios
        .post(
          `/api/modals/credentialing/newprovorfacility/${
            this.credType === "Facility"
          }`,
          {
            formData: this.formData,
            isFacility: this.credType === "Facility",
          }
        )
        .then((response) => {
          if (response.status === 201) {
            // Route to credentialing
            this.$router.push("credentialing");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveAffiliate() {
      await axios
        .post("/api/affiliates/create", this.medGroupFormData)
        .then((response) => {
          if (response.status === 201) {
            // Route to credentialing
            this.$router.push("credentialing");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setMedGroup() {
      if (this.formData.Locations[0].MedicalGroupAffiliation) {
        let medAffiliate;

        await axios
          .get(
            `/api/affiliates/get/${this.formData.Locations[0].MedicalGroupAffiliation}`
          )
          .then((res) => {
            medAffiliate = res.data[0];
          })
          .finally(() => {
            this.setFields(medAffiliate)
          })
      }
    },
    setFields(medAffiliate) {
      /*
        Alright, I know what you're thinking, 'Wow Gare, this is really dirty'. And you're right.
        But to do this in a cool ES6 way (spread operator), we would have to change the UI of the Create Locations panel,
        because the v-model'd fields will not update otherwise (the property's change but the user will not see that). Trust me, I've tried.
        ~TODO~
      */
      this.clearFields();
      this.formData.Locations[0].tax_id = medAffiliate.tax_id;
      this.formData.Locations[0].AcceptingNewPatients =
        medAffiliate.AcceptingNewPatients;
      this.formData.Locations[0].contact_phone = medAffiliate.contact_phone;
      this.formData.Locations[0].contact_email = medAffiliate.contact_email;
      this.formData.Locations[0].contact_fname = medAffiliate.contact_fname;
      this.formData.Locations[0].contact_lname = medAffiliate.contact_lname;
      this.formData.Locations[0].Status = medAffiliate.Status;
      this.formData.Locations[0].FeeScheduleType = medAffiliate.FeeScheduleType;
      this.formData.Locations[0].FeeSchedulePercent =
        medAffiliate.FeeSchedulePercent;
      this.formData.Locations[0].ProviderEffectiveDatewithFeeSchedule =
        medAffiliate.ProviderEffectiveDatewithFeeSchedule.slice(0, 10);
      this.formData.Locations[0].ProviderTerminationDatewithFeeSchedule =
        medAffiliate.ProviderTerminationDatewithFeeSchedule.slice(0, 10);
      this.formData.Locations[0].CapitationAmount =
        medAffiliate.CapitationAmount;
      this.formData.Locations[0].maxDaysToSubmitInpatientClaim =
        medAffiliate.maxDaysToSubmitInpatientClaim;
      this.formData.Locations[0].maxDaysToSubmitOutpatientClaim =
        medAffiliate.maxDaysToSubmitOutpatientClaim;
      this.formData.Locations[0].LoadDate = medAffiliate.LoadDate.slice(0, 10);
      this.formData.Locations[0].ClinicReCredDate =
        medAffiliate.ClinicReCredDate.slice(0, 10);
      // this.formData.Locations[0].PrimarySpecialty =
      //   medAffiliate.PrimarySpecialty;
      // this.formData.Locations[0].SecondarySpecialty =
      //   medAffiliate.SecondarySpecialty;
      this.formData.Locations[0].BillingProviderName =
        medAffiliate.BillingProviderName;
      this.formData.Locations[0].BillingProviderNameAsAppearsonCheck =
        medAffiliate.BillingProviderNameAsAppearsonCheck;
      this.formData.Locations[0].BillingProviderTaxId =
        medAffiliate.BillingProviderTaxId;
      this.formData.Locations[0].BillingProviderNPI =
        medAffiliate.BillingProviderNPI;
      this.formData.Locations[0].BillingProviderAddressType =
        medAffiliate.BillingProviderAddressType;
      this.formData.Locations[0].BillingCounty = medAffiliate.BillingCounty;
      this.formData.Locations[0].BillingPhone = medAffiliate.BillingPhone;
      this.formData.Locations[0].BillingFax = medAffiliate.BillingFax;
      this.formData.Locations[0].RemittoAddress1 = medAffiliate.RemittoAddress1;
      this.formData.Locations[0].RemittoAddress2 = medAffiliate.RemittoAddress2;
      this.formData.Locations[0].RemittoCity = medAffiliate.RemittoCity;
      this.formData.Locations[0].RemittoState = medAffiliate.RemittoState;
      this.formData.Locations[0].RemittoZip = medAffiliate.RemittoZip;
      this.formData.Locations[0].HospitalBased = medAffiliate.HospitalBased;
      this.formData.Locations[0].UsesCMSMA = medAffiliate.UsesCMSMA;
      this.formData.Locations[0].SonderCredDate = medAffiliate.SonderCredDate;
    },
    clearFields() {
      // Fields should be reset before they are filled because they are read-only once a Med Affiliate is chosen
      this.formData.Locations[0].tax_id = "";
      this.formData.Locations[0].contact_phone = "";
      this.formData.Locations[0].contact_email = "";
      this.formData.Locations[0].contact_fname = "";
      this.formData.Locations[0].contact_lname = "";
      this.formData.Locations[0].Status = "";

      this.formData.Locations[0].FeeScheduleType = "";
      this.formData.Locations[0].FeeSchedulePercent = 0;
      this.formData.Locations[0].ProviderEffectiveDatewithFeeSchedule = "";
      this.formData.Locations[0].ProviderTerminationDatewithFeeSchedule = "";
      this.formData.Locations[0].CapitationAmount = 0;
      this.formData.Locations[0].maxDaysToSubmitInpatientClaim = 0;
      this.formData.Locations[0].maxDaysToSubmitOutpatientClaim = 0;
      this.formData.Locations[0].LoadDate = "";
      this.formData.Locations[0].ClinicReCredDate = "";

      // this.formData.Locations[0].PrimarySpecialty = "";
      // this.formData.Locations[0].SecondarySpecialty = "";
      this.formData.Locations[0].BillingProviderName = "";
      this.formData.Locations[0].BillingProviderNameAsAppearsonCheck = "";
      this.formData.Locations[0].BillingProviderTaxId = "";
      this.formData.Locations[0].BillingProviderNPI = "";
      this.formData.Locations[0].BillingProviderAddressType = "";
      this.formData.Locations[0].BillingCounty = "";
      this.formData.Locations[0].BillingPhone = "";
      this.formData.Locations[0].RemittoAddress1 = "";
      this.formData.Locations[0].RemittoAddress2 = "";
      this.formData.Locations[0].RemittoCity = "";
      this.formData.Locations[0].RemittoState = "";
      this.formData.Locations[0].RemittoZip = "";

      this.formData.Locations[0].HospitalBased = false;
      this.formData.Locations[0].UsesCMSMA = false;

      //👇 these get zeroed out because they only come into play if no Med Group Affiliation
      // This isn't true anymore ☝️
      // this.formData.Locations[0].ServiceLocationName = "";
      // this.formData.Locations[0].DefaultAddress = false;
      // this.formData.Locations[0].ServiceLocationAddress1 = "";
      // this.formData.Locations[0].ServiceLocationAddress2 = "";
      // this.formData.Locations[0].ServiceLocationCity = "";
      // this.formData.Locations[0].ServiceLocationState = "";
      // this.formData.Locations[0].ServiceLocationZip = "";
      // this.formData.Locations[0].ServiceLocationCounty = "";
      // this.formData.Locations[0].ServiceLocationPhone = "";
      // this.formData.Locations[0].ServiceLocationFax = "";
      //☝️
    },
    formatServicePhone() {
      let x = this.medGroupFormData.ServiceLocationPhone.replace(
        /\D/g,
        ""
      ).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.medGroupFormData.ServiceLocationPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    formatServiceFax() {
      let x = this.medGroupFormData.ServiceLocationFax.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.medGroupFormData.ServiceLocationFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    formatBillPhone() {
      let x = this.medGroupFormData.BillingPhone.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.medGroupFormData.BillingPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    formatBillFax() {
      let x = this.medGroupFormData.BillingFax.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.medGroupFormData.BillingFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    closeDialog() {
      this.$emit("closeDialog", true);
    },
    clearFormData() {
      Object.keys(this.formData).forEach(
        (field) => (this.formData[field] = "")
      );
    },
    validateNext(formNum, medForm = false) {
      if (!medForm) {
        if (formNum === 1) {
          if (this.$refs.form1.validate()) {
            this.e1++
          }
        } else if (formNum === 2) {
          if (this.$refs.form2.validate()) {
            this.e1++;
          }
        } else if (formNum === 3) {
          if (this.credType === "Medical Group Affiliation") {
            if (this.$refs.medForm2.validate()) {
              this.saveAffiliate();
            }
          } else {
            if (this.$refs.form3.validate()) {
              this.saveRecord();
            }
          }
        }
      } else {
        //  med form stuff
        if (formNum === 1) {
          //validate
          if (this.$refs.medForm1.validate()) {
            this.e1++;
          }
        }
      }
    },
    async getMedicalAffiliates() {
      await axios.get(`/api/affiliates/all/${true}`).then((res) => {
        this.medicalAffiliates = res.data;
      });
    },
  },
  computed: {
    affiliated() {
      return !!this.formData.MedicalGroupAffiliation;
    },
    mocExp() {
      if (this.formData.Locations[0].MocAttestationDate) {
        let computedDate =
          this.formData.Locations[0].MocAttestationDate.split("-");
        computedDate[0] = parseInt(computedDate[0]) + 1;
        return computedDate.join("-");
      } else return "";
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  background-color: #15a7b8;
  color: white;
}
</style>
