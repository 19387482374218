<template>
  <div>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
        <v-row>
          <v-col>
            <span class="font-weight-black justify-start">
              Please read and answer these important questions
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6">
            Once enrolled, will you have other Medical Health Coverage?
          </v-col>
          <v-radio-group row v-model="coverage">
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row v-if="coverage">
          <v-col cols="12">
            <h4>If yes, complete the following:</h4>
          </v-col>
        </v-row>
        <v-row v-if="coverage">
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="carrierName"
              label="Carrier"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="carrierAddress"
              label="Carrier Address"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="coverage">
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="carrierCity"
              label="City"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="carrierState"
              label="State"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="carrierZip"
              label="Zip"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="coverage">
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="carrierGroup"
              label="Group Number"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="carrierID"
              label="ID Number"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="coverage">
          <v-col cols="12" lg="6">
            Are you the primary policy holder?
          </v-col>
          <v-radio-group row v-model="primaryPolicy">
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row v-if="coverage">
          <v-col cols="6" lg="3" align="start">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="effectiveDateOfCoverage"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="effectiveDateOfCoverage"
                  label="Effective date of coverage"
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="effectiveDateOfCoverage"
                type="month"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu1.save(effectiveDateOfCoverage)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" lg="4">
            <v-text-field
              v-model="carrierPhone"
              label="Phone Number"
              @input="acceptNumber()"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Question 2 -->
        <v-row>
          <v-col cols="12">
            <h4>
              Some individuals may have other drug coverage, including other
              private insurance, TRICARE, Federal employee health benefits
              coverage, VA benefits, or State pharmaceutical assistance
              programs.
            </h4>
          </v-col>
          <v-col cols="12" lg="6">
            Will you have other PRESCRIPTION drug coverage in addition to Sonder
            Health Plans?
          </v-col>
          <v-radio-group row v-model="prescription">
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row v-if="prescription">
          <v-col>
            <h4>
              If yes, please list your other coverage and your identification
              numbers for this coverage:
            </h4>
          </v-col>
        </v-row>
        <v-row v-if="prescription">
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="coverageName"
              label="Name of other coverage"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="coveragePhone"
              label="Phone"
              @input="acceptCoveragePhone()"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="coverageID"
              label="ID number for this coverage"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="coverageGroup"
              label="Group number for this coverage"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Question 3 -->
        <v-row>
          <v-col cols="12" lg="6">
            Are you a resident in a long-term care facility, such as a nursing
            home? (if you are a resident in an assisted living facility, answer
            “No” to this question.)
          </v-col>
          <v-radio-group row v-model="longTermCare">
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row v-if="longTermCare">
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="longTermCareName"
              label="Name of Institution"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="longTermCarePhone"
              label="Institution Phone Number"
              @input="acceptLongTermCarePhone()"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="5">
            <v-text-field
              v-model="longTermCareAddress"
              label="Address of Institution"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Question 4 -->
        <v-row>
          <v-col cols="12" lg="6">
            Are you enrolled in your state Medicaid program?
          </v-col>
          <v-radio-group row v-model="stateMedicaid">
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row v-if="stateMedicaid">
          <v-col cols="6" class="ml-4">
            <v-text-field
              v-model="stateMedicaidNumber"
              label="Medicaid Number"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Question 5 -->
        <v-row>
          <v-col cols="12" lg="6">
            If you are enrolling in a Complete Health HMO, Diabetic Wellness
            HMO, Heart Healthy HMO, HMO C-SNP, have you been diagnosed in are
            you currently being treated for Diabetes, Cardiovascular Disorder,
            and/or Chronic Heart Failure?
          </v-col>
          <v-radio-group row v-model="chronicDisease">
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-row>
        <!-- Question 6 -->
        <v-row>
          <v-col cols="12" lg="6">
            Do you or your spouse work?
          </v-col>
          <v-radio-group row v-model="currentlyWorking">
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-row>
        <!-- Question 7 -->
        <v-row>
          <v-col cols="12" lg="5">
            Please check one of the boxes below to select your language
            preference:
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="languagePref"
              label="English"
              value="English"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="languagePref"
              label="Spanish"
              value="Spanish"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-text-field
              class="pt-0 mt-0"
              label="Other"
              v-model="otherLanguage"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            Are you Hispanic or Latino?
          </v-col>
          <v-col>
            <v-select
              class="mt-0 pt-0"
              label="Choose Yes or No"
              dense
              :items="hispanicLatinoItems"
              v-model="hispanicOrLatino"
              item-value="value"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            Regardless of your answer to the prior question please indicate how you identify yourself (Select one or more)
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="ethnicity"
              label="American Indian or Alaska Native"
              value="American Indian or Alaska Native"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="ethnicity"
              label="Asian"
              value="Asian"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="ethnicity"
              label="Black or African American"
              value="Black or African American"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="ethnicity"
              label="Native Hawaiian or Other Pacific Islander"
              value="Native Hawaiian or Other Pacific Islander"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="ethnicity"
              label="White"
              value="White"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- Question 8 -->
        <v-row>
          <v-col cols="12" lg="5">
            Please select one option if you need information in an accessible
            format:
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="accesibleFormat"
              label="Audio"
              value="Audio"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="accesibleFormat"
              label="Large Print"
              value="Large Print"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="accesibleFormat"
              label="Screen Reader PDF"
              value="Screen Reader PDF"
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              class="mt-0 pt-0"
              v-model="accesibleFormat"
              label="Over the Phone"
              value="Over the Phone"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col>
            <h4>
              Please contact member services at (insert member services number)
              if you need information in an accessible format or language other
              than what is listed above.
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <v-btn text :disabled="!valid" color="primary" @click="previous()">
                <v-icon small> mdi-chevron-left </v-icon>
                Previous
            </v-btn>
          </v-col> 
          <v-spacer></v-spacer>
          <v-col class="text-right pb-0">
            <v-btn
              text
              :disabled="!valid"
              color="primary"
              @click="validate"
            >
              Next
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["recordID", "effDate"],
  data: () => ({
    valid: true,
    coverage: false,
    carrierName: "",
    carrierAddress: "",
    carrierCity: "",
    carrierState: "",
    carrierZip: "",
    carrierGroup: "",
    carrierID: "",
    primaryPolicy: false,
    effectiveDateOfCoverage: "",
    menu1: false,
    carrierPhone: "",
    prescription: false,
    coverageName: "",
    coveragePhone: "",
    coverageGroup: "",
    coverageID: "",
    longTermCare: false,
    longTermCarePhone: "",
    longTermCareName: "",
    longTermCareAddress: "",
    stateMedicaid: false,
    stateMedicaidNumber: "",
    chronicDisease: false,
    currentlyWorking: false,
    languagePref: [],
    hispanicOrLatino: null,
    otherLanguage: "",
    accesibleFormat: [],
    ethnicity: [],
    hispanicLatinoItems: [
      { text: "Yes", value: true },
      { text: "No", value: false }
    ]
  }),

  methods: {
    previous() {
        this.$emit("selectedTab", 4);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData();
      }
    },
    saveData() {
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          coverage: this.coverage,
          carrierName: this.carrierName,
          carrierAddress: this.carrierAddress,
          carrierCity: this.carrierCity,
          carrierState: this.carrierState,
          carrierZip: this.carrierZip,
          carrierGroup: this.carrierGroup,
          carrierID: this.carrierID,
          primaryPolicy: this.primaryPolicy,
          effectiveDateOfCoverage: this.effectiveDateOfCoverage,
          carrierPhone: this.carrierPhone,
          prescription: this.prescription,
          coverageName: this.coverageName,
          coveragePhone: this.coveragePhone,
          coverageGroup: this.coverageGroup,
          coverageID: this.coverageID,
          longTermCare: this.longTermCare,
          longTermCarePhone: this.longTermCarePhone,
          longTermCareAddress: this.longTermCareAddress,
          stateMedicaid: this.stateMedicaid,
          chronicDisease: this.chronicDisease,
          currentlyWorking: this.currentlyWorking,
          languagePref: this.languagePref,
          otherLanguage: this.otherLanguage,
          accesibleFormat: this.accesibleFormat,
          hispanicOrLatino: this.hispanicOrLatino,
          ethnicity: this.ethnicity
        })
        .then(() => {
          this.$emit("selectedTab", 6);
        })
        .catch((err) => {
          console.log(err);
          console.log("Error saving form data.");
        });
    },
    acceptNumber() {
      var x = this.carrierPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.carrierPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    acceptLongTermCarePhone() {
      var x = this.longTermCarePhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.longTermCarePhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    acceptCoveragePhone() {
      var x = this.coveragePhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.coveragePhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
  mounted() {
    this.effectiveDateOfCoverage = this.effDate
  }
};
</script>
