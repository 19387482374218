<template>
  <v-container mt-16>
    <v-row>
      <v-col>
        <v-card v-if="formError" class="pa-5 text-center" flat>
          <v-icon color="error" class="display-2"> mdi-cancel </v-icon>
          <v-card-text class="headline">
            This link is no longer valid or it has already been used.
          </v-card-text>
        </v-card>

        <v-card v-else-if="!formComplete" flat>
          <v-card-title> Please Sign Your Scope of Appointment </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="formData.agentName"
                    label="Agent Name"
                    outlined
                    disabled
                    dense
                    required
                    :rules="[
                      this.formData.agentName != '' || 'Please enter your name',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="formData.agentPhone"
                    label="Agent Phone"
                    outlined
                    disabled
                    dense
                    required
                    :rules="[
                      this.formData.agentPhone != '' ||
                        'Please enter your phone number',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.beneficiaryName"
                    label="Beneficiary Name"
                    outlined
                    disabled
                    required
                    dense
                    :rules="[
                      this.formData.beneficiaryName != '' ||
                        'Please enter your phone number',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.ssn"
                    label="Beneficiary Last 4 of SSN"
                    outlined
                    disabled
                    required
                    dense
                    :rules="[formData.ssn != '' || 'Last 4 of SSN Required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.beneficiaryPhone"
                    label="Beneficiary Phone (optional)"
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.beneficiaryAddress"
                    label="Beneficiary Address (optional)"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- Added the v-if below to get ridf of console eror -->
              <v-row v-if="formData.agentPlans">
                <v-col cols="12">
                  <div class="subtitle-1">
                    Check the product type(s) you want the agent to discuss
                    (required):
                  </div>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[0]"
                    label="Medicare Advantage MAP D Plan (HMO)"
                    disabled
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[1]"
                    label="Chronic Special Needs Program - Diabetic"
                    disabled
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[2]"
                    label="Chronic Special Needs Program - Cardiovascular"
                    disabled
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[3]"
                    label="Dual Special Needs Plan (D-SNP)"
                    disabled
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" lg="4">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.dateSigned"
                        label="Date Signed"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        @change="fieldChanged()"
                        :rules="[
                          formData.DateSigned != '' || 'Please enter the date',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="formData.DateSigned"
                      min="1900-01-01"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="$refs.menu1.save(formData.DateSigned)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12">
                  <div class="subtitle-1">Please Sign in the Box Below</div>
                </v-col>
                <v-col cols="12">
                  <v-alert dense outlined type="error" v-model="alert">
                    Please sign your name below to continue
                  </v-alert>
                  <Signature ref="Signature" />
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12">
                  <div class="subtitle-1">
                    If you are the authorized representative, please sign above
                    and print clearly and legibly below:
                  </div>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="membData.repName"
                    label="Name (First and Last)"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="membData.repRelationship"
                    label="Relationship"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row> -->

              <v-row class="mt-5" justify="end">
                <v-col cols="2">
                  <v-btn
                    depressed
                    x-large
                    block
                    :disabled="!valid"
                    color="primary"
                    @click="validate"
                    class="py-7"
                    :loading="load"
                  >
                    Sign Form
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card v-else class="pa-5 text-center glassCard" flat>
          <v-icon color="success" class="display-2"> mdi-check </v-icon>
          <v-card-text class="headline">
            Form signed successfully!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Signature from "../components/new-enrollment-components/Signature.vue";

export default {
  name: "NewSOA",
  components: {
    Signature,
  },
  data() {
    return {
      load: false,
      search: "",
      formError: false,
      valid: false,
      signature: "",
      alert: false,
      formComplete: false,
      options: {
        penColor: "#343434",
        minWidth: 2.5,
        maxWidth: 4.5,
      },
      formData: {},
      membData: {
        memberSignature: "",
        repName: "",
        repRelationship: "",
      },
    };
  },
  mounted() {
    this.validateLink(this.$route.params.id);
  },
  methods: {
    validateLink(uniqueID) {
      axios
        .get(`/api/soa/getFormById/${uniqueID}`)
        .then((response) => {
          this.formData = response.data;
          // }
        })
        .catch(() => {
          this.formError = true;
        });
    },
    async validate() {
      this.membData.memberSignature = await this.$refs.Signature.submit();
      if (this.signature !== undefined) {
        this.signPdf();
      } else {
        this.alert = true;
      }
    },
    signPdf() {
      this.load = true;

      axios
        .post("/api/soa/updateSignature", {
          id: this.$route.params.id,
          formData: this.membData,
        })
        .then(() => {
          this.formComplete = true;
        })
        .catch((error) => {
          this.load = false;
          console.log(error);
        });
    },
  },
};
</script>
<style>
#signature {
  border: 1px solid #888;
  border-radius: 5px;
}
</style>
