<template>
  <v-dialog v-model="dialog" max-width="750px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        color="accent"
        :disabled="buttonDisabled"
        rounded
        block
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>

    <v-toolbar dark color="primary">
      <v-toolbar-title>Search</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="cancelSelection">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card class="pa-5">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" sm="8">
            <v-text-field
              required
              :rules="[searchName != '' || 'This field is required']"
              v-model="searchName"
              label="Member Name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn 
              @click="validate"
              color="accent" 
              block
            >
              <v-icon class="mr-2">mdi-magnify</v-icon>
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-divider></v-divider>

      <div v-if="authList.length >= 1">
        <v-row>
          <v-col cols="12">
            <v-data-table
              v-model="selectedAuth"
              :headers="headers"
              :items="authList"
              item-key="_id"
              :loading="searchLoading"
              single-select
              show-select
              multi-sort
              loading-text="Loading data..."
            ></v-data-table>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="cancelSelection"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="saveSelection"
            color="success"
            text
          >
            Save Selected
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "authSearchDialog",
  props: [
    'buttonDisabled'
  ],
  components: {
  },
  data() {
    return {
      dialog: false,
      searchLoading: false,
      searchName: '',
      headers: [
        { text: "Auth ID", value: "AuthID" },
        { text: "Name", value: "MemberName" },
        { text: "Assigned User", value: "AssignedUser" },
        { text: "Service Type", value: "ServiceType" },
      ],
      authList: [],
      selectedAuth: [],
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.searchAuths();
      }
    },
    async searchAuths() {
      this.searchLoading = true;
      this.authList = [];

      await axios
        .get(`/api/search/authorizations/${this.searchName}`)
        .then(response => {
          this.authList = response.data;
          this.searchLoading = false;
        })
    },
    saveSelection() {
      // send member to parent to save
      this.$emit('authSelected', this.selectedAuth[0]);
      this.searchName = ''
      this.authList = [];
      this.selectedAuth = []
      this.dialog = false;
    },
    cancelSelection() {
      this.searchName = ''
      this.authList = [];
      this.selectedAuth = []
      this.dialog = false;
    }
  },
  computed: {
  },
  watch: {
  }
};
</script>

<style scoped>

</style>
