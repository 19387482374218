<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        <v-icon color="accent">mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-card v-if="!loading">
      <v-data-iterator
        :items="patientProfile"
        :items-per-page.sync="itemsPerPage"
        :hide-default-footer="true"
      >
        <template v-slot:header="{ pagination, options, updateOptions }">
          <v-toolbar
            style="position: fixed; z-index: 69"
            dark
            color="primary"
            class="mx-auto"
            width="100%"
          >
            <v-btn icon dark @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <!-- options is an object from the v-slot that contains the current page -->
            <ExportCSV
              :data="patientProfile"
              :name="
                patientProfile[options.page - 1].firstName +
                ' ' +
                patientProfile[options.page - 1].lastName +
                '.csv'
              "
            >
              <v-btn class="mr-3" outlined dark>
                <v-icon>mdi-microsoft-excel</v-icon>
                <span class="pl-3">Export to CSV</span>
              </v-btn>
            </ExportCSV>

            <v-btn class="mr-3" outlined dark v-on:click="generateJpeg()">
              <v-icon>mdi-file-image-outline</v-icon>
              <span class="pl-3">Export Image</span>
            </v-btn>

            <v-btn
              color="success"
              depressed
              :disabled="disableSaveButton"
              @click="
                updateRecord(
                  patientProfile[options.page - 1]._id,
                  options.page - 1
                )
              "
            >
              <v-icon>mdi-content-save</v-icon>
              <span class="pl-3">Save Changes</span>
            </v-btn>
          </v-toolbar>

          <v-data-footer
            class="pt-16 justify-end"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :items-per-page-options="rowsPerPageItems"
          />
        </template>
        
        <template v-slot:default="props">
          <v-card flat id="capture">
            <div v-for="item in props.items" :key="item._id">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pa-3"
              >
                <!-- Demographics -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>Demographic</v-card-title>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.memberID"
                        label="Member ID"
                        readonly
                        outlined
                        dense
                        required
                        :rules="[
                          item.memberID != '' ||
                            'Please enter the patient\'s Member ID',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.medicareID"
                        label="Medicare/Medicaid ID"
                        outlined
                        dense
                        required
                        :rules="[
                          item.memberID != '' ||
                            'Please enter the patient\'s Medicare/Medicaid ID',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="5">
                      <v-text-field
                        v-model="item.lastName"
                        label="Last Name"
                        outlined
                        dense
                        required
                        :rules="[
                          item.lastName != '' ||
                            'Please enter the patient\'s last name',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="5">
                      <v-text-field
                        v-model="item.firstName"
                        label="First Name"
                        outlined
                        dense
                        required
                        :rules="[
                          item.firstName != '' ||
                            'Please enter the patient\'s first name',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-text-field
                        v-model="item.middleInitial"
                        label="Middle Initial"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="3">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="item.birthDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.birthDate"
                            label="Birth Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            :rules="[
                              item.birthDate != '' ||
                                'Please enter the patient\'s date of birth',
                            ]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="item.birthDate"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1900-01-01"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="menu1 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="$refs.menu1[0].save(item.birthDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-select
                        v-model="item.gender"
                        :items="sex"
                        label="Sex"
                        outlined
                        dense
                        required
                        :rules="[
                          item.gender != null ||
                            'Please select the patient\'s sex',
                        ]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-text-field
                        v-model="item.height"
                        label="Height"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-text-field
                        v-model="item.weight"
                        label="Weight"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.email"
                        label="Email"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.phoneNumber"
                        label="Phone Number"
                        @input="acceptNumber()"
                        outlined
                        dense
                        required
                        :rules="[
                          item.phoneNumber != '' ||
                            'Please enter the patient\'s phone number',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="5">
                      <v-text-field
                        v-model="item.address"
                        label="Street Address"
                        outlined
                        dense
                        required
                        :rules="[
                          item.address != '' ||
                            'Please enter the patient\'s address',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-text-field
                        v-model="item.city"
                        label="City"
                        outlined
                        dense
                        required
                        :rules="[
                          item.city != '' ||
                            'Please enter the patient\'s city',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-text-field
                        v-model="item.state"
                        label="State"
                        outlined
                        dense
                        required
                        :rules="[
                          item.state != '' ||
                            'Please enter the patient\'s state',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-text-field
                        v-model="item.zip"
                        label="ZIP Code"
                        outlined
                        dense
                        required
                        :rules="[
                          item.zip != '' ||
                            'Please enter the patient\'s ZIP code',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.language"
                        label="Primary Language(s)"
                        outlined
                        dense
                        required
                        hint="Enter the primary language of the patient"
                        :rules="[
                          item.language != '' ||
                            'Please enter the patient\'s primary language',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.education"
                        :items="educationLevels"
                        label="Highest Level of Education"
                        outlined
                        dense
                        required
                        :rules="[
                          item.education != null ||
                            'Please select the highest education level',
                        ]"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.race"
                        :items="races"
                        label="Race/ethnicity (select all that are applicable)"
                        multiple
                        outlined
                        dense
                        required
                        :rules="[
                        //changed from length > 0 to fix display not rendering
                          item.race.length > 0 ||
                            'Please select the patient\'s race',
                        ]"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- General Health -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>General Health</v-card-title>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.healthRating"
                        :items="rateScaleAnswers"
                        label="In general, how would you rate your health?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="2" class="pt-0">
                      <v-checkbox
                        class="mt-0"
                        v-model="item.liveAlone"
                        hide-details
                        label="Live alone"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="2" class="pt-0">
                      <v-checkbox
                        class="mt-0"
                        v-model="item.homebound"
                        hide-details
                        label="Homebound"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="2" class="pt-0">
                      <v-checkbox
                        class="mt-0"
                        v-model="item.caregiver"
                        hide-details
                        label="Have a caregiver"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.erVisits"
                        :items="frequencyAnswers"
                        label="In the past 3 months, how many times did you go to the Emergency Room (ER)?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.overnightStayHospitals"
                        :items="frequencyAnswers"
                        label="In the past 3 months, how many times have you had an overnight stay in a hospital (as a patient)?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="4">
                      <v-select
                        v-model="item.dentalHealth"
                        :items="rateScaleAnswers"
                        label="In general, how would you rate your dental health?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-select
                        v-model="item.pcpCommunication"
                        :items="['Yes', 'No']"
                        label="Do you visit or communicate with your PCP regularly?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        v-model="item.pcpCommunicationFreq"
                        label="If yes, how often?"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.haveWill"
                        :items="['Yes', 'No', 'Not Sure']"
                        label="Do you have a living will or advanced directive?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.pcpHaveWillCopy"
                        :items="['Yes', 'No']"
                        label="If yes, does your PCP have a copy?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.poaOrSurrogate"
                        :items="['Yes', 'No', 'Not Sure']"
                        label="Do you have a Power of Attorney (POA) or healthcare surrogate?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.noCareCuzBeliefs"
                        :items="['Yes', 'No', 'Prefer not to answer']"
                        label="Do you ever choose to not seek medical care because of religious or personal beliefs?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.needSpecialEquipment"
                        :items="['Yes', 'No']"
                        label="Do you use or need any special DME equipment/supplies (walkers, trach supplies, ostomy, oxygen)?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.specialEquipment"
                        label="List the DME, if applicable"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.hasFallen"
                        :items="['Yes', 'No']"
                        label="In the past 6 months, have you fallen?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.fallenPCP"
                        :items="['Yes', 'No']"
                        label="If yes, does your PCP know about it?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.balanceOrWalkingProblems"
                        :items="['Yes', 'No']"
                        label="Have you had any problems with balance or walking?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.urineLeakage"
                        :items="['Yes', 'No']"
                        label="Have you had problems with urine leakage?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div>
                        On a scale of 0-10, what is your normal pain level?
                        (0 = No Pain, 10 = Worst Pain Imaginable)
                      </div>
                      <v-slider
                        v-model="item.painLevel"
                        :tick-labels="painLevels"
                        :max="10"
                        step="1"
                        ticks="always"
                        tick-size="3"
                        thumb-size="60"
                        thumb-label
                        class="mt-10"
                      >
                        <template
                          v-slot:thumb-label="{ value }"
                          class="ta-c"
                        >
                          {{ getPainLevelDescription(value) }}
                        </template>

                        <template v-slot:prepend>
                          <v-icon> mdi-emoticon-happy-outline </v-icon>
                        </template>

                      <template v-slot:append>
                        <v-icon> mdi-emoticon-sad-outline </v-icon>
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div>
                      Rate how you feel about each of the following
                      statements:
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div>My health is important to me.</div>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-select
                      v-model="item.question1"
                      :items="agreeAnswers"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div>
                      I am ultimately the one responsible for taking care
                      of my health and wellness.
                    </div>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-select
                      v-model="item.question2"
                      :items="agreeAnswers"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div>
                      I am confident I can prevent or reduce problems
                      associated with my health.
                    </div>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-select
                      v-model="item.question3"
                      :items="agreeAnswers"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div>
                      I am confident I know when I need to seek medical
                      care, and when I am able to take care of myself.
                    </div>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-select
                      v-model="item.question4"
                      :items="agreeAnswers"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div>
                      I am confident I can talk to my doctor about my
                      health concerns, even when he/she does not ask.
                    </div>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-select
                      v-model="item.question5"
                      :items="agreeAnswers"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div>
                      I am confident I can follow through on medical
                      treatments or care that I may need to do at home.
                    </div>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-select
                      v-model="item.question6"
                      :items="agreeAnswers"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card>
              
              <!-- Medical History -->
              <v-card outlined class="mb-3 px-2">
                <v-row>
                  <v-col cols="6">
                    <v-card-title>Medical History</v-card-title>
                    <v-select
                      v-model="item.conditions"
                      :items="conditionArray"
                      multiple
                      outlined
                      dense
                      label="What Medical conditions do you have, or have you had in the
                    past?"
                    ></v-select>
                    <v-text-field
                      v-model="item.conditionOther"
                      label="If other, what type?"
                      outlined
                      dense
                    ></v-text-field>
                    <v-select
                      v-model="item.treatments"
                      :items="treamentArray"
                      multiple
                      outlined
                      dense
                      label="Which of the following conditions are you currently receiving
                    treatment for?"
                    ></v-select>
                    <v-text-field
                      v-model="item.treatmentOther"
                      label="If other, what type?"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title>Surgical History</v-card-title>
                    <surgical-history-button @surgery="addSurgery"></surgical-history-button>
                    <v-simple-table class="mb-6">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Surgery</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(surg, index) in item.surgicalHistory" :key="index">
                            <td>{{ surg.surgeryName }}</td>
                            <td>{{ surg.date }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card>

                <!-- Activities of Daily Living -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>Activities of Daily Living</v-card-title>
                  <v-row>
                    <v-col class="pb-0">
                      <div>
                        Do you need help with doing the following daily
                        activities?
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-checkbox
                        v-model="item.standingUpFromSittingPosition"
                        hide-details
                        label="Standing up from a sitting position"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.walkingInTheHouse"
                        hide-details
                        label="Walking in the house"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.walkingOutsideOfTheHouse"
                        hide-details
                        label="Walking outside of the house"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.preparingAMeal"
                        hide-details
                        label="Preparing a meal"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.eatingAMeal"
                        hide-details
                        label="Eating a meal"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-checkbox
                        v-model="item.usingTheToilet"
                        hide-details
                        label="Using the toilet"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.bathing"
                        hide-details
                        label="Bathing"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.gettingDressed"
                        hide-details
                        label="Getting dressed"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.drivingOrGettingPlaces"
                        hide-details
                        label="Driving or getting to places"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="item.someoneWhoCanHelp"
                        label="If you need help with any of these activities, do you have someone who can help you?"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- Medication -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>Medication</v-card-title>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.medications"
                        :items="medicationItems"
                        outlined
                        dense
                        label="
                          Do you take medications; and if so, 
                          how often do you take
                          medications?
                        "
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.medicationAmount"
                        :items="medicationAmountItems"
                        outlined
                        dense
                        label="How many medications do you take?"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.pharmacyName"
                        label="Pharmacy Name"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.pharmacyAddress"
                        label="Pharmacy Address"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.pharmacyPhoneNumber"
                        label="Phone Number"
                        outlined
                        dense
                        @input="acceptNumber()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="item.groceriesOrMedications"
                        :items="yesNoNaArray"
                        outlined
                        dense
                        label="
                          Do you sometimes find you have to choose 
                          between buying groceries
                          or medications?
                        "
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- Preventive Services -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>Preventive Services</v-card-title>
                  <v-row>
                    <v-col cols="12">
                      <div>
                        When was the last time you had each of the following?
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="yearsDoneArray"
                        v-model="item.pneumoniaVaccine"
                        outlined
                        dense
                        label="Pneumonia Vaccine"
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="yearsDoneArray"
                        v-model="item.fluVaccine"
                        outlined
                        dense
                        label="Flu Vaccine"
                        multiple
                      ></v-select>
                    </v-col> 
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        :items="yearsDoneArray"
                        v-model="item.covid19Vaccine"
                        outlined
                        dense
                        label="COVID-19 Vaccine"
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="yearsDoneArray"
                        v-model="item.breastScreening"
                        outlined
                        dense
                        label="Breast Screening (Mammogram)"
                        multiple
                      ></v-select>
                    </v-col> 
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        :items="yearsDoneArray"
                        v-model="item.colorectalScreening"
                        outlined
                        dense
                        label="Colorectal Screening (Colonoscopy)"
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="yearsDoneArray"
                        v-model="item.cervicalScreening"
                        outlined
                        dense
                        label="Cervical Screening (PAP Smear)"
                        multiple
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="yearsDoneArray"
                        v-model="item.boneDensity"
                        outlined
                        dense
                        label="Bone Density Screening"
                        multiple
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- Lifestyle -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>Lifestyle</v-card-title>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="exerciseArray"
                        v-model="item.dailyExercise"
                        label="How often do you exercise for at least 20 minutes in a day?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="['Yes', 'No']"
                        v-model="item.sleepingPatterns"
                        label="
                          In the past 2 weeks, have you had a change in your sleeping
                          patterns, or are you having trouble obtaining restful
                          sleep?
                        "
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        :items="['Yes', 'No']"
                        v-model="item.specialDiet"
                        label="Are you on a special diet recommended by your doctor (low sodium, cholesterol, fat)?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="If yes, what type of diet?"
                        v-model="item.specialDietOther"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="dietArray"
                        v-model="item.fruitVegitableConsumption"
                        label="How often do you eat fresh fruits or vegetables?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="dietArray"
                        label="How often do you eat fried or high fat foods (fried fish/checken, bacon, potato chips)?"
                        v-model="item.highFatFoods"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="dietArray"
                        v-model="item.alcohol"
                        label="How often do you drink alcohol?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="yesNoNaArray"
                        label="Have you ever felt you should reduce your alcohol
                      consumption?"
                        v-model="item.reduceAlcohol"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="smokingArray"
                        v-model="item.lastTimeSmoked"
                        label="When was the last time you smoked or used any tobacco product (cigarettes, chew, snuff, pipes, cigars, vapor cigarettes)?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="yesNoNaArray"
                        label="Are you interested in quitting smoking?"
                        v-model="item.interestedInQuittingSmoking"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="[
                          '0',
                          '1 time',
                          '2 times',
                          '3 or more times',
                        ]"
                        label="In the past year, how many times have you used an illegal drug or
                      prescription medication for non-medical reasons?"
                        v-model="item.illegalDrugUse"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        :items="['Yes', 'No']"
                        label="Do you have any hobbies or interests?"
                        v-model="item.hobbiesOrInterests"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="If yes, please list"
                        outlined
                        dense
                        v-model="item.hobbies"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- Emotional Health -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>Emotional Health</v-card-title>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="howOftenEmotionalArray"
                        v-model="item.nervousAnxious"
                        label="Over the past 2 weeks, how often have you felt nervous, anxious, or on edge?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="howOftenEmotionalArray"
                        v-model="item.nonStopWorrying"
                        label="Over the past 2 weeks, how often were you not able to stop worrying or control your worrying?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="howOftenEmotionalArray"
                        v-model="item.downDepressedHopeless"
                        label="Over the past 2 weeks, how often have you felt down, depressed, or hopeless?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="howOftenEmotionalArray"
                        v-model="item.interestOrPleasure"
                        label="Over the past 2 weeks, how often have you felt little interest or pleasure in doing things?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="howOftenArray"
                        v-model="item.socialAndEmotionalSupport"
                        label="How often do you get the social and emotional support you need?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="howOftenArray"
                        v-model="item.stressHandlingLife"
                        label="How often do you get stressed handling your health, finances, relationship, or work?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        :items="['Yes', 'No']"
                        v-model="item.thoughtsOfSuicide"
                        label="Over the past 2 weeks, have you had thoughts of suicide?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        :items="['Yes', 'No', 'N/A']"
                        v-model="item.suicidePlan"
                        label="If yes, do you have a plan?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- Completion Information -->
                <v-card outlined class="mb-3 px-2">
                  <v-card-title>Completion Information</v-card-title>
                  <v-row>
                    <v-col cols="12" lg="3">
                      <v-select
                        :items="roleArray"
                        label="Who completed this HRA form?"
                        v-model="item.whoCompletedForm"
                        outlined
                        dense
                        required
                        :rules="[
                          item.whoCompletedForm != null ||
                            'Please make a selection',
                        ]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="item.nameOfIndividual"
                        label="Full Name Of Individual Filling Out This HRA Form"
                        outlined
                        dense
                        required
                        :rules="[
                          item.nameOfIndividual != null ||
                            'Please enter a name',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="item.completionDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.completionDate"
                            label="Completion Date"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            required
                            :rules="[
                              item.completionDate != null || 'Please enter a date',
                            ]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="item.completionDate"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1900-01-01"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            @click="menu2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="$refs.menu2[0].save(item.completionDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="['Yes', 'No']"
                        v-model="item.memberPresent"
                        label="If not filled out by member, was the Member present while responding to and answering the HRA questions?"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
            </div>
          </v-card>
        </template>
      </v-data-iterator>
    </v-card>

    <v-card v-else>
      <v-row style="height: 100vh">
        <v-col align-self="center" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>

    <v-snackbar v-model="snackError" :timeout="3000" color="error">
      Error saving new record!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="exitNoSave" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to exit?
        </v-card-title>
        <v-card-text> You have unsaved changes that will be lost... </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="ma-3" @click="exitNoSave = false" color="accent">
            Cancel
          </v-btn>
          <v-btn class="ma-3" @click="dialog = false" color="success">
            Yes, Exit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "axios";
import ExportCSV from "vue-json-csv";
import * as htmlToImage from "html-to-image";
import SurgicalHistoryButton from "./hra-components/SurgicalHistoryButton.vue"

export default {
  name: "HRAEnrollmentsModal",
  components: {
    ExportCSV,
    SurgicalHistoryButton
  },
  props: ["memberID"],
  data() {
    return {
      rowsPerPageItems: [],
      page: 1,
      itemsPerPage: 1,
      dialog: false,
      loading: true,
      snackError: false,
      valid: false,
      menu1: false,
      menu2: false,
      enrollee_id: "",
      patientProfile: [],
      sex: ["Male", "Female"],
      countyList: ["Cobb", "Dekalb", "Fulton", "Gwinnett"],
      educationLevels: [
        "8th grade or less",
        "Some high school, but did not graduate",
        "High school or GED",
        "Technical School/Training",
        "Some college",
        "2 year college degree",
        "4 year college degree",
      ],
      races: [
        "African American or Black",
        "Asian",
        "White or Caucasian",
        "Hispanic",
        "Pacific Islander",
        "Native Alaskan",
        "Native American",
        "Native Hawaiian",
        "Indian",
        "Other",
        "Prefer not to answer",
      ],
      rateScaleAnswers: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
      frequencyAnswers: ["0", "1 time", "2 times", "3 or more times"],
      painLevels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      agreeAnswers: [
        "Strongly Disagree",
        "Disagree",
        "Agree",
        "Strongly Agree",
      ],
      conditionArray: [
        "Anxiety",
        "Asthma",
        "Bi-polar Disorder",
        "Blood Disorder",
        "Cancer",
        "COPD/Emphysema",
        "Coronary Artery Disease",
        "Dementia/Alzheimer's",
        "Depression",
        "Diabetes (Type 1)",
        "Diabetes (Type 2)",
        "GERD/Stomach Issues",
        "Hearing Problems",
        "Heart Failure (CHF)",
        "Hypertension",
        "Organ Transplant",
        "Osteoarthritis(OA)",
        "Osteoporosis",
        "Peripheral Vascular Disease (PVD)",
        "Renal/Kiden Failure",
        "Rheumatoid Arthritis (RA)",
        "Seizures",
        "Schizophrenia",
        "Skin Issues/Wound",
        "Stroke",
        "Vision Problems",
        "Other",
      ],
      treamentArray: [
        "Anxiety/Depression",
        "Arthritis",
        "Asthma",
        "Bi-Polar/Schizophrenia",
        "Cancer",
        "COPD/Emphysema",
        "Coronary Artery Disease",
        "Dementia/Alzheimer's",
        "Diabetes",
        "Heart Failure (CHF)",
        "Hypertension",
        "Renal/Kidney Failure",
        "Stroke",
        "Other",
      ],
      yearsDoneArray: [
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "Within The Last 10 Years",
        "Never",
        "N/A",
      ],
      dietArray: ["Daily", "Often", "Less than 3 times a week", "Never"],
      yesNoNaArray: ["Yes", "No", "N/A"],
      medicationItems: [
        "No, never",
        "Yes, daily",
        "Yes, weekly",
        "Yes, as needed",
      ],
      medicationAmountItems: ["0", "1-3", "4-5", "6-7", "8+"],
      exerciseArray: [
        "Daily",
        "3 or more times a week",
        "Less than 3 times a week",
        "Never",
      ],
      smokingArray: [
        "Today",
        "Last week",
        "Last month",
        "3 months ago",
        "Last year",
        "1-5 years ago",
        "Longer than 5 years ago",
        "Never",
      ],
      howOftenEmotionalArray: [
        "Not at all",
        "Several days",
        "More days than not",
        "Nearly every day",
      ],
      howOftenArray: ["Always", "Often", "Sometimes", "Rarely", "Never"],
      roleArray: [
        "Member",
        "PCP",
        "Spouse",
        "Son/Daughter",
        "Caregiver",
        "Other",
      ],
      incompleteForm: false,
      exitNoSave: false,
      originalRecord: [],
      disableSaveButton: true
    };
  },
  methods: {
    addSurgery(surgery) {
      this.patientProfile[0].surgicalHistory.push(surgery)
    },
    getPatientProfile() {
      this.loading = true;

      axios.get(`api/modals/hra/patient/${this.memberID}`).then((res) => {
        this.patientProfile = res.data;
        this.originalRecord = JSON.parse(JSON.stringify(this.patientProfile))
        this.loading = false;
      });
    },
    //this.$refs.form returns an array if inside a v-for, validate function is in the first element of this array
    async updateRecord(id, index) {
      if (this.$refs.form[index].validate()) {
        try {
          await axios.post(`/api/hra/edit/${id}`, this.patientProfile[index]);
          // emit value to reload the patients if changes were made
          this.$emit("recordUpdated", true);
          this.dialog = false;
        } catch (error) {
          this.snackError = true;
          console.log(error);
        }
      } else {
        this.incompleteForm = true;
        this.goToFirstMissingField();
      }
    },
    getPainLevelDescription(value) {
      let description = "";
      switch (value) {
        case 0:
          description = "No Pain";
          break;
        case 1:
        case 2:
        case 3:
          description = "Mild";
          break;
        case 4:
        case 5:
        case 6:
          description = "Moderate";
          break;
        case 7:
        case 8:
        case 9:
        case 10:
          description = "Severe";
          break;
      }
      return description;
    },
    generateJpeg() {
      htmlToImage
        .toJpeg(document.getElementById("capture"), { quality: 0.95, pixelRatio: 1 })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "Credentialing_Snapshot" + ".jpeg";
          link.href = dataUrl;
          link.click();
        });
    },
    close() {
      // if there are unsaved changes fire the exitNoSave modal
      if (!this.disableSaveButton) {
        this.exitNoSave = true;
      } else {
        this.dialog = false
      }
    },
    goToFirstMissingField() {
      if (!this.$refs.form1[0].validate()) {
        document.getElementById("form1").scrollIntoView(true);
      } else if (!this.$refs.form2[0].validate()) {
        document.getElementById("form2").scrollIntoView(true);
      } else if (!this.$refs.form3[0].validate()) {
        document.getElementById("form3").scrollIntoView(true);
      } else if (!this.$refs.form4[0].validate()) {
        document.getElementById("form4").scrollIntoView(true);
      } else if (!this.$refs.form5[0].validate()) {
        document.getElementById("form5").scrollIntoView(true);
      } else if (!this.$refs.form6[0].validate()) {
        document.getElementById("form6").scrollIntoView(true);
      } else if (!this.$refs.form7[0].validate()) {
        document.getElementById("form7").scrollIntoView(true);
      } else if (!this.$refs.form8[0].validate()) {
        document.getElementById("form8").scrollIntoView(true);
      } else if (!this.$refs.form9[0].validate()) {
        document.getElementById("form9").scrollIntoView(true);
      }
    },
  },
  watch: {
    dialog() {
      if (this.memberID != null && this.dialog) {
        this.getPatientProfile();
      }
    },
    patientProfile: {
      handler() {
        if (JSON.stringify(this.patientProfile) === JSON.stringify(this.originalRecord)) {
          this.disableSaveButton = true;
        } else {
          this.disableSaveButton = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
