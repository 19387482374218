<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-row>
            <v-col>
              <v-card-title>
                Enrollments
                <template v-if="user.role === 'fmo'">
                  <v-spacer />
                  <v-switch
                    v-model="showFmoMembers"
                    label="Show FMO Agents Members"
                  />
                </template>
              </v-card-title>
            </v-col>
            <v-col class="px-7" cols="12" lg="5">
              <v-select
                :items="planOptions"
                v-model="planFilter"
                @change="filterEnrolledPatients()"
              />
            </v-col>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col class="px-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="filteredMembers"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.DETAILS="{ item }">
                <enrollment-modal
                  :enrollee="item"
                  :user="user"
                  @documentSaved="getUser"
                ></enrollment-modal>
              </template>
              <template v-slot:item.agentPassDate="{ item }">
                <tr>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-show="checkAgent(item)"
                          medium
                          dense
                          color="red darken-2"
                          class="mr-1"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span>Agent has not passed 2023 exam</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>

              <template v-slot:item.newPayment="{ item }">
                <new-payment-modal
                  @fireSnackbar="snackInfo = true"
                  :agentInfo="item"
                ></new-payment-modal>
              </template>

              <template v-slot:item.agent="{ item }">
                <span v-if="item.agent">{{ item.agent }}</span>
                <span v-else>{{ item.tempAgent }}</span>
              </template>

              <template v-slot:item.enrollmentstatus="{ item }">
                <enrollment-status-modal
                  :view="'Enrollment'"
                  :patient="item"
                  @closeEnrollmentStatusModal="closeEnrollmentStatusModal"
                  @disenrolledPatient="removeDisenrolled"
                ></enrollment-status-modal>
              </template>

              <template slot="footer">
                <ExportCSV :data="this.csvData" :name="'enrollments.csv'">
                  <v-btn color="accent" text class="export-button">
                    Export to CSV
                  </v-btn>
                </ExportCSV>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackInfo" :timeout="3000" color="success">
      Changes saved!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackInfo = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import EnrollmentModal from "../components/EnrollmentModal";
import NewPaymentModal from "../components/NewPaymentModal";
import EnrollmentStatusModal from "../components/EnrollmentStatusModal";
import ExportCSV from "vue-json-csv";
import axios from "axios";

export default {
  name: "Enrollments",
  components: {
    EnrollmentModal,
    NewPaymentModal,
    EnrollmentStatusModal,
    ExportCSV,
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      headers: [],
      enrollmentData: [],
      snackInfo: false,
      paymentDialog: false,
      newPayment: {
        agentID: "",
        patientName: "",
        patientID: "",
        amountPaid: 0,
        paymentType: "",
        disenrollment: "",
        paid: false,
      },
      planOptions: [
        "All",
        "H1748-001 Complete",
        "H1748-003 Diabetes",
        "H1748-004 Heart",
        "H1748-005 Dual",
      ],
      planFilter: "All",
      filteredMembers: [],
      csvData: [],
      limitedAccess: false,
      showFmoMembers: false,
      fmoAgents: [],
    };
  },
  methods: {
    closeEnrollmentStatusModal(value) {
      if (value) {
        this.getEnrolledPatients();
      }
    },
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else if (this.user.role === "member services") {
            this.limitedAccess = true;
            this.getHeaders();
            this.getEnrolledPatients();
          } else {
            this.getHeaders();
            this.getEnrolledPatients();
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    getHeaders() {
      if (
        this.user.role === "agent" ||
        this.user.role === "fmo" ||
        this.user.role === "member services"
      ) {
        this.headers = [
          { text: "Name", value: "Full_Name" },
          { text: "Plan", value: "selectedPlan" },
          { text: "PCP", value: "selectedPCP" },
          { text: "Agent ID", value: "agentID" },
          { text: "Exam Flag", value: "agentPassDate" },
          { text: "Enrollment Details", value: "DETAILS" },
        ];
      } else {
        this.headers = [
          { text: "Name", value: "Full_Name" },
          { text: "Plan", value: "selectedPlan" },
          { text: "PCP", value: "selectedPCP" },
          { text: "Agent ID", value: "agentID" },
          { text: "Agent Name", value: "agent" },
          { text: "Exam Pass Date", value: "agentPassDate" },
          { text: "New Payment", value: "newPayment" },
          { text: "Enrollment Status", value: "enrollmentstatus" },
          { text: "Enrollment Details", value: "DETAILS" },
        ];
      }
    },
    async getEnrolledPatients() {
      this.enrollmentData = [];
      this.load = true;

      if (
        this.user.role === "agent" ||
        (this.user.role === "fmo" && !this.showFmoMembers)
      ) {
        axios.get(`api/enrollments/${this.user.userID}`).then((res) => {
          this.enrollmentData = res.data;
          this.filterEnrolledPatients();
          this.csvData = JSON.parse(JSON.stringify(this.enrollmentData));
          this.csvData.forEach((user) => {
            delete user._id;
          });
          this.load = false;
        });
      } else if (this.user.role === "fmo" && this.showFmoMembers) {
        // get fmo agent id's
        this.fmoAgents = [];
        await axios
          .get(`api/fmo/agents/${this.user.fmoNpn}`)
          .then((res) => {
            this.fmoAgents = res.data;
          })
          .then(() => {
            return axios.get(`api/enrollments/${this.fmoAgents}`);
          })
          .then((res) => {
            this.enrollmentData = res.data;
            this.filterEnrolledPatients();
            this.csvData = JSON.parse(JSON.stringify(this.enrollmentData));
            this.csvData.forEach((user) => {
              delete user._id;
            });
            this.load = false;
          });
      } else {
        axios.get(`api/enrollments/all`).then((res) => {
          this.enrollmentData = res.data;
          this.filterEnrolledPatients();
          this.csvData = JSON.parse(JSON.stringify(this.enrollmentData));
          this.csvData.forEach((user) => {
            delete user._id;
            delete user.Full_Name;
            delete user.selectedPlan;
            delete user.selectedPCP;
            delete user.agentID;
            delete user.agent;
          });
          this.load = false;
        });
      }
    },
    removeDisenrolled(patient) {
      //find the patient
      let indexToUpdate = this.enrollmentData.findIndex(
        (p) => p.medicareNumber === patient
      );
      //remove the disenrolled patient from the report
      this.enrollmentData.splice(indexToUpdate, 1);
      // display snackbar notification
      this.snackInfo = true;
    },
    filterEnrolledPatients() {
      if (this.planFilter === "All") {
        this.filteredMembers = this.enrollmentData;
      } else {
        this.filteredMembers = [];
        this.enrollmentData.forEach((enrollment) => {
          if (
            enrollment.selectedPlan &&
            enrollment.selectedPlan.includes(this.planFilter.substr(0, 9))
          ) {
            this.filteredMembers.push(enrollment);
          }
        });
      }
    },
    checkAgent(item) {
      if (item.agentID !== "Unassigned" && item.agentPassDate.length > 0) {
        return new Date(item.agentPassDate[0]) < new Date("09/28/2022");
      } else if (
        item.agentID !== "Unassigned" &&
        item.agentPassDate.length === 0
      ) {
        return true;
      }
    },
  },
  mounted() {
    this.getUser();
  },
  watch: {
    showFmoMembers() {
      this.getEnrolledPatients();
    },
    user() {
      if (this.user.role === "fmo") {
        this.showFmoMembers = true; // default for fmo
      }
    },
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
