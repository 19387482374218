<template>
  <v-container>
    <transition name="fade" mode="out-in"
      >>
      <v-row v-if="successfullySent" :key="1">
        <v-col>
          <v-card class="pa-5 text-center" flat>
            <v-icon color="success" class="display-2">
              mdi-check-circle-outline
            </v-icon>
            <v-card-text class="headline">
              Correspondence successfully sent!
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else :key="2">
        <v-col>
          <v-card flat>
            <v-card-title>New Correspondence </v-card-title>
            <v-form>
              <v-stepper v-model="stepper" flat class="no-shadow">
                <v-stepper-header class="no-shadow">
                  <v-stepper-step :complete="stepper > 1" step="1">
                    Choose A Template
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="stepper > 2" step="2">
                    Choose Filters
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="stepper > 3" step="3">
                    Choose Recipients
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="4"> Confirm And Send </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-row>
                      <v-col cols="12">
                        <v-dialog v-model="templateDialog" width="900">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              v-bind="attrs"
                              v-on="on"
                              @click="templateHover = ''"
                              color="accent"
                            >
                              Select Template
                            </v-btn>
                          </template>
                          <v-card flat>
                            <v-card-title>Choose A Template</v-card-title>

                            <v-card-text class="px-3">
                              <v-item-group v-model="templateHover">
                                <v-row>
                                  <v-col cols="12" lg="4">
                                    <v-hover>
                                      <template v-slot:default="{ hover }">
                                        <v-item
                                          v-slot="{ active, toggle }"
                                          :value="'DEA Licensing'"
                                        >
                                          <v-card
                                            outlined
                                            :elevation="hover ? 2 : 0"
                                            class="pa-2"
                                          >
                                            <v-img
                                              src="../assets/dea.png"
                                              height="200"
                                              contain
                                              class="text-right"
                                              @click="toggle"
                                            >
                                              <v-btn icon dark>
                                                <v-icon
                                                  v-show="active"
                                                  color="success"
                                                >
                                                  mdi-check-circle-outline
                                                </v-icon>
                                              </v-btn>
                                            </v-img>
                                            <v-card-title
                                              class="justify-center primary--text"
                                            >
                                              DEA Licensing
                                            </v-card-title>
                                          </v-card>
                                        </v-item>
                                      </template>
                                    </v-hover>
                                  </v-col>

                                  <v-col cols="12" lg="4">
                                    <v-hover>
                                      <template v-slot:default="{ hover }">
                                        <v-item
                                          v-slot="{ active, toggle }"
                                          :value="'Medicaid License'"
                                        >
                                          <v-card
                                            outlined
                                            :elevation="hover ? 2 : 0"
                                            class="pa-2"
                                          >
                                            <v-img
                                              src="../assets/medicaid.png"
                                              height="200"
                                              contain
                                              class="text-right"
                                              @click="toggle"
                                            >
                                              <v-btn icon dark>
                                                <v-icon
                                                  v-show="active"
                                                  color="success"
                                                >
                                                  mdi-check-circle-outline
                                                </v-icon>
                                              </v-btn>
                                            </v-img>
                                            <v-card-title
                                              class="justify-center primary--text"
                                            >
                                              Medicaid License
                                            </v-card-title>
                                          </v-card>
                                        </v-item>
                                      </template>
                                    </v-hover>
                                  </v-col>

                                  <v-col cols="12" lg="4">
                                    <v-hover>
                                      <template v-slot:default="{ hover }">
                                        <v-item
                                          v-slot="{ active, toggle }"
                                          :value="'Medicare License'"
                                        >
                                          <v-card
                                            outlined
                                            :elevation="hover ? 2 : 0"
                                            class="pa-2"
                                          >
                                            <v-img
                                              src="../assets/medicare.png"
                                              height="200"
                                              contain
                                              class="text-right"
                                              @click="toggle"
                                            >
                                              <v-btn icon dark>
                                                <v-icon
                                                  v-show="active"
                                                  color="success"
                                                >
                                                  mdi-check-circle-outline
                                                </v-icon>
                                              </v-btn>
                                            </v-img>
                                            <v-card-title
                                              class="justify-center primary--text"
                                            >
                                              Medicare License
                                            </v-card-title>
                                          </v-card>
                                        </v-item>
                                      </template>
                                    </v-hover>
                                  </v-col>

                                  <v-col cols="12" lg="4">
                                    <v-hover>
                                      <template v-slot:default="{ hover }">
                                        <v-item
                                          v-slot="{ active, toggle }"
                                          :value="'Non Delegated'"
                                        >
                                          <v-card
                                            outlined
                                            :elevation="hover ? 2 : 0"
                                            class="pa-2"
                                          >
                                            <v-img
                                              src="../assets/nonDelegated.png"
                                              height="200"
                                              contain
                                              class="text-right"
                                              @click="toggle"
                                            >
                                              <v-btn icon dark>
                                                <v-icon
                                                  v-show="active"
                                                  color="success"
                                                >
                                                  mdi-check-circle-outline
                                                </v-icon>
                                              </v-btn>
                                            </v-img>
                                            <v-card-title
                                              class="justify-center primary--text"
                                            >
                                              Non Delegated
                                            </v-card-title>
                                          </v-card>
                                        </v-item>
                                      </template>
                                    </v-hover>
                                  </v-col>

                                  <v-col cols="12" lg="4">
                                    <v-hover>
                                      <template v-slot:default="{ hover }">
                                        <v-item
                                          v-slot="{ active, toggle }"
                                          :value="'Delegated'"
                                        >
                                          <v-card
                                            outlined
                                            :elevation="hover ? 2 : 0"
                                            class="pa-2"
                                          >
                                            <v-img
                                              src="../assets/delegated.png"
                                              height="200"
                                              contain
                                              class="text-right"
                                              @click="toggle"
                                            >
                                              <v-btn icon dark>
                                                <v-icon
                                                  v-show="active"
                                                  color="success"
                                                >
                                                  mdi-check-circle-outline
                                                </v-icon>
                                              </v-btn>
                                            </v-img>
                                            <v-card-title
                                              class="justify-center primary--text"
                                            >
                                              Delegated
                                            </v-card-title>
                                          </v-card>
                                        </v-item>
                                      </template>
                                    </v-hover>
                                  </v-col>

                                  <v-col cols="12" lg="4">
                                    <v-hover>
                                      <template v-slot:default="{ hover }">
                                        <v-item
                                          v-slot="{ active, toggle }"
                                          :value="'Denial'"
                                        >
                                          <v-card
                                            outlined
                                            :elevation="hover ? 2 : 0"
                                            class="pa-2"
                                          >
                                            <v-img
                                              src="../assets/denial.png"
                                              height="200"
                                              contain
                                              class="text-right"
                                              @click="toggle"
                                            >
                                              <v-btn icon dark>
                                                <v-icon
                                                  v-show="active"
                                                  color="success"
                                                >
                                                  mdi-check-circle-outline
                                                </v-icon>
                                              </v-btn>
                                            </v-img>
                                            <v-card-title
                                              class="justify-center primary--text"
                                            >
                                              Denial
                                            </v-card-title>
                                          </v-card>
                                        </v-item>
                                      </template>
                                    </v-hover>
                                  </v-col>

                                  <v-col cols="12" lg="4">
                                    <v-hover>
                                      <template v-slot:default="{ hover }">
                                        <v-item
                                          v-slot="{ active, toggle }"
                                          :value="'Approval'"
                                        >
                                          <v-card
                                            outlined
                                            :elevation="hover ? 2 : 0"
                                            class="pa-2"
                                          >
                                            <v-img
                                              src="../assets/approval.png"
                                              height="200"
                                              contain
                                              class="text-right"
                                              @click="toggle"
                                            >
                                              <v-btn icon dark>
                                                <v-icon
                                                  v-show="active"
                                                  color="success"
                                                >
                                                  mdi-check-circle-outline
                                                </v-icon>
                                              </v-btn>
                                            </v-img>
                                            <v-card-title
                                              class="justify-center primary--text"
                                            >
                                              Approval
                                            </v-card-title>
                                          </v-card>
                                        </v-item>
                                      </template>
                                    </v-hover>
                                  </v-col>
                                </v-row>
                              </v-item-group>
                            </v-card-text>

                            <v-card-actions class="px-3 pb-3">
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="accent"
                                depressed
                                @click="setTemplate()"
                              >
                                Save Selection
                                <v-icon small class="ml-2">mdi-check</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <span class="text-caption primary--text">
                          {{
                            chosenTemplate
                              ? chosenTemplate
                              : "No Template Selected"
                          }}
                        </span>
                        <v-icon
                          @click="removeTemplate"
                          v-show="chosenTemplate"
                          small
                          class="ml-1 primary--text"
                        >
                          mdi-close-circle-outline
                        </v-icon>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" v-if="chosenTemplate">
                        <v-img
                          :src="templatePreviewLink"
                          max-width="400"
                        ></v-img>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          color="accent"
                          text
                          :disabled="!chosenTemplate"
                          @click="stepper = 2"
                        >
                          Continue
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <!-- auth corespondance -->
                  <template
                    v-if="
                      chosenTemplate === 'Denial' ||
                        chosenTemplate === 'Approval'
                    "
                  >
                    <v-stepper-content step="2">
                      <v-row class="pa-5">
                        <v-tabs
                          fixed-tabs
                          background-color="primary"
                          v-model="tab"
                        >
                          <v-tab v-for="item in tabs" :key="item">{{
                            item
                          }}</v-tab>
                          <v-tabs-items v-model="tab">
                            <!-- Unassigned -->
                            <v-tab-item>
                              <v-text-field
                                class="py-0"
                                v-model="authSearch"
                                label="Search"
                                prepend-inner-icon="mdi-magnify"
                                single-line
                                hide-details
                              >
                              </v-text-field>

                              <v-data-table
                                :loading="load"
                                :headers="authHeaders"
                                :search="authSearch"
                                :items="
                                  completed !== 'All'
                                    ? unassignedFilteredData
                                    : unassignedData
                                "
                                :footer-props="{
                                  options: {
                                    itemsPerPage: 15,
                                  },
                                }"
                                sort-by="CreatedDT"
                                sort-desc
                                multi-sort
                                loading-text="Loading data..."
                              >
                                <template v-slot:item.ACTIONS="{ item }">
                                  <v-btn text @click="getAuthData(item._id)">
                                    <!-- add select actions -->
                                    <v-icon>
                                      mdi-check
                                    </v-icon>
                                  </v-btn>
                                </template>
                              </v-data-table>
                            </v-tab-item>

                            <!-- Nurse -->
                            <v-tab-item>
                              <v-row>
                                <v-col class="pa-6">
                                  <v-text-field
                                    class="py-0"
                                    v-model="authSearch"
                                    label="Search"
                                    prepend-inner-icon="mdi-magnify"
                                    single-line
                                    hide-details
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>

                              <v-data-table
                                :loading="load"
                                :headers="authHeaders"
                                :search="authSearch"
                                :items="
                                  completed !== 'All'
                                    ? nurseFilteredData
                                    : nurseData
                                "
                                :footer-props="{
                                  options: {
                                    itemsPerPage: 15,
                                  },
                                }"
                                sort-by="CreatedDT"
                                sort-desc
                                multi-sort
                                loading-text="Loading data..."
                                class="elevation-0 col-md-12 m-auto"
                              >
                                <template v-slot:item.ACTIONS="{ item }">
                                  <v-btn text @click="getAuthData(item._id)">
                                    <v-icon>
                                      mdi-check
                                    </v-icon>
                                  </v-btn>
                                </template>
                              </v-data-table>
                            </v-tab-item>

                            <!-- MD -->
                            <v-tab-item>
                              <v-row>
                                <v-col class="pa-6">
                                  <v-text-field
                                    class="py-0"
                                    v-model="authSearch"
                                    label="Search"
                                    prepend-inner-icon="mdi-magnify"
                                    single-line
                                    hide-details
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>

                              <v-data-table
                                :loading="load"
                                :headers="authHeaders"
                                :items="
                                  completed !== 'All' ? mdFilteredData : mdData
                                "
                                :search="authSearch"
                                :footer-props="{
                                  options: {
                                    itemsPerPage: 15,
                                  },
                                }"
                                sort-by="CreatedDT"
                                sort-desc
                                multi-sort
                                loading-text="Loading data..."
                                class="elevation-0 col-md-12 m-auto"
                              >
                                <template v-slot:item.ACTIONS="{ item }">
                                  <v-btn text @click="getAuthData(item._id)">
                                    <v-icon>
                                      mdi-check
                                    </v-icon>
                                  </v-btn>
                                </template>

                                <template v-slot:item.Services="{ item }">
                                  <auth-services-modal
                                    :services="item.Services"
                                    v-model="servicesModal"
                                  ></auth-services-modal>
                                </template>
                              </v-data-table>
                            </v-tab-item>
                          </v-tabs-items>
                        </v-tabs>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="text-right">
                          <v-btn
                            color="accent lighten-1"
                            class="mr-2"
                            text
                            @click="stepper = 1"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                            Back
                          </v-btn>

                          <v-btn
                            color="accent"
                            text
                            :disabled="provToggle === '' || daysToggle === ''"
                            @click="getFacilities()"
                          >
                            Continue
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </template>

                  <template v-else>
                    <v-stepper-content step="2">
                      <v-row>
                        <v-col cols="12">
                          <v-switch
                            v-model="switch1"
                            :label="'Specific Date Selection'"
                          ></v-switch>
                        </v-col>
                        <v-col cols="12">
                          <v-item-group
                            v-model="provToggle"
                            active-class="primary secondary--text"
                          >
                            <v-row justify="center">
                              <v-col md="3">
                                <v-item
                                  v-slot="{ active, toggle }"
                                  :value="'Providers'"
                                >
                                  <v-card
                                    outlined
                                    :class="active ? 'accent white--text' : ''"
                                    class="d-flex align-center"
                                    height="125"
                                    @click="toggle"
                                  >
                                    <div
                                      class="text-h5 flex-grow-1 text-center"
                                    >
                                      <v-icon
                                        x-large
                                        class="pa-3"
                                        :color="active ? 'white' : ''"
                                      >
                                        mdi-doctor
                                      </v-icon>
                                      <v-spacer></v-spacer>
                                      Providers
                                    </div>
                                  </v-card>
                                </v-item>
                              </v-col>
                              <v-col md="3">
                                <v-item
                                  v-slot="{ active, toggle }"
                                  :value="'Facilities'"
                                >
                                  <v-card
                                    outlined
                                    :class="active ? 'accent white--text' : ''"
                                    class="d-flex align-center"
                                    height="125"
                                    @click="toggle"
                                  >
                                    <div
                                      class="text-h5 flex-grow-1 text-center"
                                    >
                                      <v-icon
                                        x-large
                                        class="pa-3"
                                        :color="active ? 'white' : ''"
                                      >
                                        mdi-hospital-building
                                      </v-icon>
                                      <v-spacer></v-spacer>
                                      Facilities
                                    </div>
                                  </v-card>
                                </v-item>
                              </v-col>
                            </v-row>
                          </v-item-group>
                        </v-col>
                      </v-row>

                      <v-row justify="center" v-if="switch1 === false">
                        <v-col lg="6" sm="12">
                          <v-card class="mt-0" flat>
                            <v-card-title> Days Out </v-card-title>
                            <v-slider
                              v-model="daysToggle"
                              class="pb-3"
                              :tick-labels="sliderLabels"
                              :max="sliderLabels.length - 1"
                              step="1"
                              tick-size="0"
                              color="black"
                              thumb-color="black"
                              track-color="grey"
                            ></v-slider>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row justify="center" v-if="switch1 === true">
                        <v-col cols="6">
                          <v-date-picker
                            v-model="date"
                            full-width
                            class="mt-4"
                          ></v-date-picker>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text-right">
                          <v-btn
                            color="accent lighten-1"
                            class="mr-2"
                            text
                            @click="stepper = 1"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                            Back
                          </v-btn>

                          <v-btn
                            color="accent"
                            text
                            :disabled="provToggle === '' || daysToggle === ''"
                            @click="getFacilities()"
                          >
                            Continue
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </template>

                  <!-- step 3  -->
                  <template
                    v-if="
                      chosenTemplate === 'Denial' ||
                        chosenTemplate === 'Approval'
                    "
                  >
                    <v-stepper-content step="3">
                      <v-form
                        ref="authForm"
                        v-model="authValid"
                        lazy-validation
                      >
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              label="Recipient Email"
                              v-model="sendToEmail"
                              required
                              :rules="[
                                sendToEmail != '' || 'Email is required',
                                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  sendToEmail
                                ) || 'Please enter a valid email address',
                              ]"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-btn color="primary" block @click="validateAuth"
                              >Send</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-stepper-content>
                  </template>

                  <template v-else>
                    <v-stepper-content step="3">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            class="px-3 py-0"
                            v-model="search"
                            label="Search"
                            prepend-inner-icon="mdi-magnify"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-data-table
                        v-model="chosenRecipients"
                        :headers="headers"
                        :items="recipients"
                        :search="search"
                        item-key="_id"
                        :single-select="false"
                        :loading="load"
                        show-select
                        multi-sort
                        loading-text="Loading data..."
                        class="elevation-0 col-md-12 m-auto"
                      >
                        <template v-slot:item.Expiration="{ item }">
                          {{
                            item.Expiration ? item.Expiration.substr(0, 10) : ""
                          }}
                        </template>
                      </v-data-table>

                      <v-row>
                        <v-col cols="12" class="text-right">
                          <v-btn
                            color="accent lighten-1"
                            class="mr-2"
                            text
                            @click="stepper = 2"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                            Back
                          </v-btn>

                          <v-btn
                            color="accent"
                            text
                            :disabled="!chosenRecipients.length > 0"
                            @click="stepper = 4"
                          >
                            Continue
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </template>

                  <template
                    v-if="
                      chosenTemplate === 'Denial' ||
                        chosenTemplate === 'Approval'
                    "
                  >
                    <v-stepper-content step="4">
                      <div class="pa-4 text-center">
                        <h4>Email Sent!</h4>
                      </div>
                    </v-stepper-content>
                  </template>

                  <template v-else>
                    <v-stepper-content step="4">
                      <v-row>
                        <v-col cols="12" lg="4">
                          <v-card outlined height="100%">
                            <v-card-text
                              >Chosen Template:
                              {{ chosenTemplate }}</v-card-text
                            >
                            <v-img
                              :src="templatePreviewLink"
                              class="ml-4"
                              max-width="400"
                            ></v-img>
                          </v-card>
                        </v-col>

                        <v-col cols="12" lg="4">
                          <v-card outlined height="100%">
                            <v-card-text>Recipients:</v-card-text>
                            <v-list dense style="background: transparent">
                              <v-list-item
                                three-line
                                v-for="item in chosenRecipients"
                                :key="item._id"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Recipient Name:
                                    {{ item.Name }}</v-list-item-title
                                  >
                                  <v-list-item-subtitle>
                                    Recipient Email: {{ item.Email }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    License Expiration:
                                    {{
                                      item.Expiration
                                        ? item.Expiration.substr(0, 10)
                                        : ""
                                    }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>

                        <v-col cols="12" lg="4">
                          <v-card outlined height="100%">
                            <v-card-text>Filters:</v-card-text>
                            <v-list dense style="background: transparent">
                              <v-list-item two-line>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Recipient Group:
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ provToggle }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item two-line>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Days Out:
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ sliderLabels[daysToggle] }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="text-right">
                          <v-btn
                            text
                            color="accent lighten-1"
                            class="mr-2"
                            @click="stepper = 3"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                            Back
                          </v-btn>

                          <v-btn depressed color="accent" @click="saveHandler">
                            Send
                            <v-icon class="ml-2">mdi-email-send</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </template>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import axios from "axios"

export default {
  name: "CorrespondenceNew",
  components: {},
  data() {
    return {
      stepper: 1,
      templateDialog: false,
      templateHover: "",
      chosenTemplate: "",
      provToggle: "",
      daysToggle: "",
      load: false,
      recipients: [],
      switch1: false,
      sliderLabels: ["Expired", "1 Day", "30 Days", "60 Days"],
      headers: [
        { text: "Recipient Name", value: "Name" },
        { text: "Recipient Email", value: "Email" },
        { text: "Expiration Date", value: "Expiration" },
      ],
      search: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      chosenRecipients: [],
      providers: [],
      facilities: [],
      successfullySent: false,
      unassignedData: [],
      nurseData: [],
      mdData: [],
      reportData: [],
      unassignedCompletedData: [],
      mdCompletedData: [],
      nurseCompletedData: [],
      AuthItems: ["All", "Completed", "Uncompleted"],
      completed: "All",
      tab: null,
      tabs: ["Unassigned", "Nurse", "MD"],
      authHeaders: [
        { text: "Date Created", value: "CreatedDT" },
        { text: "Authorization ID", value: "AuthID" },
        { text: "Patient Member ID", value: "SonderID" },
        { text: "Member Name", value: "MemberName" },
        { text: "Determintation", value: "Determination" },
        { text: "Requesting Provider Name", value: "ReqProviderName" },
        { text: "Actions", value: "ACTIONS" },
      ],
      authSearch: "",
      selectedAuth: {},
      sendToEmail: "",
      authValid: false,
    }
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0]
          this.getReportData()
          // check role and reroute if unauthorized
          if (this.user.role === "agent" || this.user.role === "fmo") {
            this.$router.push("/")
          }
        })
        .catch(() => {
          this.$router.push("/login")
        })
    },
    async getReportData() {
      await axios
        .get(`/api/authorizations/getAllAuthorizations`)
        .then((res) => {
          let allData = res.data
          this.unassignedData = []
          this.nurseData = []
          this.mdData = []

          allData.forEach((authObj) => {
            if (authObj.ReviewStatus === "Unassigned") {
              this.unassignedData.push(authObj)
            } else if (authObj.ReviewStatus === "Nurse") {
              this.nurseData.push(authObj)
            } else if (authObj.ReviewStatus === "MD") {
              this.mdData.push(authObj)
            }
          })
        })
    },
    async getAuthData(authID) {
      //padload should be auth id
      // grab data
      await axios
        .get(`/api/authorizations/encounters/${authID}`)
        .then((res) => {
          this.selectedAuth = res.data[0]
          this.stepper++
        })
      // load data into var
      // go to next page
    },
    setTemplate() {
      this.chosenTemplate = this.templateHover
      this.templateDialog = false
    },
    removeTemplate() {
      this.chosenTemplate = false
      this.provToggle = ""
      this.switch1 = false
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    },
    async getFacilities() {
      this.stepper = 3
      this.load = true
      this.recipients = []
      this.chosenRecipients = []

      try {
        if (this.switch1 === true) {
          // if user puts switch to query license expiring on specific date
          let flag = "specificDate"
          await axios
            .get(
              `api/correspondence/recipients/${this.chosenTemplate}/${this.provToggle}/${this.date}/${flag}`
            )
            .then((res) => {
              res.data !== ""
                ? (this.recipients = res.data)
                : (this.recipients = [])
            })
        } else {
          await axios
            .get(
              `api/correspondence/recipients/${this.chosenTemplate}/${
                this.provToggle
              }/${this.sliderLabels[this.daysToggle]}`
            )
            .then((res) => {
              res.data !== ""
                ? (this.recipients = res.data)
                : (this.recipients = [])
            })
        }

        this.load = false
      } catch (error) {
        if (error) {
          console.log(error)
        }
      }
    },
    saveHandler() {
      //send post request with data in chosenRecipients
      let data = JSON.parse(JSON.stringify(this.chosenRecipients))
      data.forEach((recipient) => {
        recipient.userID = this.user.userID
        recipient.pdfType = this.chosenTemplate
      })

      try {
        axios.post(
          `api/correspondence/new/${this.chosenTemplate}/${this.provToggle}`,
          data
        )
      } catch (error) {
        console.log(error)
      }

      this.successfullySent = true
      setTimeout(() => {
        this.removeTemplate()
        this.stepper = 1
        this.successfullySent = false
      }, 3000)
    },
    validateAuth() {
      if (this.$refs.authForm.validate()) {
        this.sendAuthEmail()
      } else {
        console.log("Invalid Email")
      }
    },
    async sendAuthEmail() {
      try {
        axios.post(
          `api/authorizations/email/${this.selectedAuth._id}/${this.chosenTemplate}`,
          {
            recipients: [this.sendToEmail],
            authData: this.selectedAuth,
          }
        )
        this.stepper++
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    this.getUser()
  },
  computed: {
    templatePreviewLink() {
      if (this.chosenTemplate === "DEA Licensing") {
        return require("../assets/dea.png")
      } else if (this.chosenTemplate === "Medicaid License") {
        return require("../assets/medicaid.png")
      } else if (this.chosenTemplate === "Medicare License") {
        return require("../assets/medicare.png")
      } else if (this.chosenTemplate === "Non Delegated") {
        return require("../assets/nonDelegated.png")
      } else if (this.chosenTemplate === "Delegated") {
        return require("../assets/nonDelegated.png")
      } else if (this.chosenTemplate === "Denial") {
        return require("../assets/denial.png")
      } else if (this.chosenTemplate === "Approval") {
        return require("../assets/approval.png")
      }
      return ""
    },
  },
  watch: {
    chosenTemplate() {
      this.toggle = ""
      this.daysToggle = ""
      if (
        this.chosenTemplate === "Delegated" ||
        this.chosenTemplate === "Non Delegated"
      ) {
        this.sliderLabels = ["1 Day", "30 Days", "60 Days"]
      } else {
        this.sliderLabels = ["Expired", "1 Day", "30 Days", "60 Days"]
      }
    },
  },
}
</script>

<style scoped>
.no-shadow {
  box-shadow: 0 0 0 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
