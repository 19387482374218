<template>
    <!-- personal Information -->
  <v-form ref="form" v-model="valid">
    <v-card outlined class="transparent-card pa-2 mx-3 mt-2" height="100%">
      <v-card-title class="text-h4"> Personal Information </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3" v-if="isFacility">
            <v-text-field
              v-model="credData.FacilityName"
              label="Facility Name"
              outlined
              dense
              required
              :rules="[
                credData.FacilityName != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-text-field
              v-model="credData.RenderingProviderFullName"
              label="Provider Full Name"
              outlined
              dense
              required
              :rules="[
                credData.RenderingProviderFullName != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-text-field
              v-model="credData.RenderingProviderLastName"
              label="Provider Last Name"
              outlined
              dense
              required
              :rules="[
                credData.RenderingProviderLastName != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-text-field
              v-model="credData.RenderingProviderFirstName"
              label="Provider First Name"
              outlined
              dense
              required
              :rules="[
                credData.RenderingProviderFirstName != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-text-field
              v-model="credData.RenderingProviderMiddleName"
              label="Provider Middle Name"
              outlined
              dense
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="credData.MedicareID"
              label="Medicare ID"
              outlined
              dense
              class="text-subtitle-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="!isFacility">
            <v-select
              v-model="credData.Gender"
              :items="['Male', 'Female']"
              label="Gender"
              outlined
              dense
              required
              :rules="[
                credData.Gender != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="credData.Company"
              label="Company"
              outlined
              dense
              required
              :rules="[
                credData.Company != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="credData.RenderingNPI"
              label="Provider ID"
              outlined
              dense
              required
              :rules="[
                credData.RenderingNPI != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="credData.ProviderType"
              label="Provider Type"
              outlined
              dense
              required
              :rules="[
                credData.ProviderType != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="!isFacility">
            <v-text-field
              v-model="credData.Degree"
              label="Provider Degree"
              outlined
              dense
              required
              :rules="[
                credData.Degree != '' ||
                  'Please make sure this field is not empty',
              ]"
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-menu
              ref="dobPicker"
              v-model="dobPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
              :readonly="limitedAccess === true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="credData.DOB"
                  label="Birth Date"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  :rules="[
                    credData.DOB != '' ||
                      'Please enter the date of birth',
                  ]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="credData.DOB"
                :disabled="limitedAccess === true"
              >
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  @click="dobPicker = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="$refs.dobPicker.save(credData.DOB)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-text-field
              :type="showSSN ? 'text' : 'password'"
              v-model="credData.SSN"
              label="SSN"
              outlined
              dense
              :rules="[
                (v) =>
                  v && v.length != '' && !/^[^a-zA-Z]+$/.test(v)
                    ? 'This field cannot contain letters'
                    : true,
              ]"
              class="text-subtitle-1"
              :readonly="SSNReadOnly"
            >
              <template
                slot="append"
                v-if="
                  user.role === 'manager' || user.role === 'superuser'
                "
              >
                <v-icon
                  @click="
                    (toggleEyeIcon = !toggleEyeIcon),
                      (showSSN = !showSSN),
                      (SSNReadOnly = !SSNReadOnly)
                  "
                  v-html="toggleEyeIcon ? 'mdi-eye' : 'mdi-eye-off'"
                >
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-text-field
              v-model="credData.Language"
              label="Language"
              outlined
              dense
              class="text-subtitle-1"
              :readonly="limitedAccess === true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!isFacility">
            <v-checkbox
              v-model="credData.isPCP"   
              label="Provider is PCP?"
              dense
              :readonly="limitedAccess === true"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: ["credData", "isFacility", "user", "startValidation", "limitedAccess"],
  data() {
    return {
      dobPicker: false,
      valid: false,
      SSNReadOnly: true,
      showSSN: false,
      toggleEyeIcon: false,
    };
  },
  watch: {
    startValidation() {
      if (this.startValidation && !this.$refs.form.validate()) {
        this.$emit("summaryErrors", true);
      } else if (this.startValidation && this.$refs.form.validate()) {
        this.$emit("summaryErrors", false);
      }
    },
  },
};
</script>