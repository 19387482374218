<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  name: "BarChart",
  props: ["chartData", "chartOptions"],
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  watch: {
    async chartData() {
      this.renderChart(this.chartData, this.chartOptions);
    }
  }
};
</script>
