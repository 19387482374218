<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <span class="font-weight-black justify-start ma-3">
        Agent Needs Assessment (Optional)
      </span>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="name"
            label="Name"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="DOB"
                label="Birth Date"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                :rules="[DOB != '' || 'Please enter your date of birth']"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="DOB"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu1.save(DOB)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="sex"
            label="Sex"
            :items="['Male', 'Female']"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="height.feet"
            label="Height(in feet)"
            type="Number"
            suffix="feet"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="height.inches"
            label="Height(in inches)"
            type="Number"
            suffix="inches"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="weight"
            label="Weight"
            type="Number"
            suffix="lbs"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="language"
            label="Primary Language"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="address"
            label="Address"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="city"
            label="City"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="state"
            label="State"
            :items="stateList"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="zip" label="Zip" outlined dense></v-text-field>
        </v-col>
        <v-col cols="6" md="8">
          <v-text-field
            v-model="email"
            label="Email Address"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-checkbox
            v-model="emailOkay"
            label="Is it okay for us to communicate with you via email?"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="phone"
            label="Phone"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="phoneType"
            label="Cell or Home"
            :items="['Cell', 'Home']"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-checkbox
            v-model="voicemailOkay"
            label="Is it okay for us to text you or leave voicemails?"
          ></v-checkbox>
        </v-col>
      </v-row>
      <h2>General Health Questions</h2>
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            v-model="healthRating"
            label="In general, how would you rate your health?"
            :items="ratingList"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="4" md="2">
          <v-checkbox
            v-model="liveAlone"
            label="Do you live alone?"
            outlined
            dense
          ></v-checkbox>
        </v-col>
        <v-col cols="4" md="2">
          <v-checkbox
            v-model="homebound"
            label="Are you homebound?"
            outlined
            dense
          ></v-checkbox>
        </v-col>
        <v-col cols="4" md="2">
          <v-checkbox
            v-model="haveCaregiver"
            label="Do you have a caregiver?"
            outlined
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-checkbox
            v-model="seePcpRegularly"
            label="Do you have a PCP that you see regularly?"
            outlined
            dense
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="pcpName"
            label="PCP Name"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="poaOrSurrogate"
            label="Do you have a Power of Attorney (POA) or healthcare surrogate?"
            :items="['Yes', 'No', 'Not sure']"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="dentalHealthRating"
            label="How would you rate your dental health?"
            :items="ratingList"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="dentistName"
            label="Dentist"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="lastDentalVisit"
                label="Last Dental Visit"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                :rules="[
                  lastDentalVisit != '' || 'Please enter your date of birth',
                ]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="lastDentalVisit"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu2.save(lastDentalVisit)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="useDmeEquipment"
            label="Do you use any special DME equipment/supplies (walkers, oxygen, C-PAP)?"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="dmeEquipmentUsed"
            label="DME Equipment used"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <h2>Medical History Information</h2>
      <v-row>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="alcoholDrugs"
            label="Use Alcohol/Drugs"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="autoimmuneDisorder"
            label="Auto Immune Disorder"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="cancer"
            label="Cancer"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="cvdDisease"
            label="Has CVD"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="chronicHeartFailure"
            label="Chronic Heart Failure"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="dementia"
            label="Dementia"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="diabetes"
            label="Diabetes"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="esld"
            label="End-stage Liver Disease"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="esrd"
            label="End-stage Renal Disease"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="severeHematologicDisorder"
            label="Severe Hamatologic Disorders"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="hivAids"
            label="HIV/AIDS"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="chronicLungDisorders"
            label="Chronic Lung Disorders"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="chronicDisablingMentalHealth"
            label="Chronic Disabling Mental Health"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="neurologicDisorders"
            label="Neurologic Disorders"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="4">
          <v-checkbox
            v-model="stroke"
            label="Stroke"
            dense
            outlined
          ></v-checkbox>
        </v-col>
      </v-row>
      <h2>Medication Information</h2>
      <v-row>
        <v-col cols="4">
          <p>Medication Name</p>
        </v-col>
        <v-col cols="4">
          <p>Dosage</p>
        </v-col>
        <v-col cols="4">
          <p>Frequency</p>
        </v-col>
      </v-row>
      <v-row v-for="item in medicationInformation" :key="item._id">
        <v-col cols="4">
          <v-text-field
            v-model="item.medicationName"
            label="Medication Name"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="item.dosage"
            label="Dosage"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="item.frequency"
            label="Frequency"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-col cols="3" @click="medicationInformation.push({})">
        <v-btn>Add Another Medication</v-btn>
      </v-col>
      <hr class="my-4" />
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="agentName"
            label="Needs Assessment Completed by Agent"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="completedDate"
            label="Date"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h5>
            If filled out by an individual other than the member:
          </h5>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="personFillingForm"
            label="Name of person filling out form"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="relationship"
            label="Relationship to member"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="memberPresent"
            label="Was the member present"
            dense
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="educatedCmProgram"
            label="If member is enrolled in a C-SNP or D-SNP plan, were they educated on the CM Program?"
            dense
            outlined
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-left pb-0">
          <v-btn text color="primary" @click="previous()">
            <v-icon small> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right pb-0">
          <v-btn text color="warning" @click="skip">
            Skip
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-btn text color="primary" @click="saveData">
            Next
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios"

export default {
  props: ["recordID", "medicareID"],
  data() {
    return {
      valid: true,
      menu1: false,
      menu2: false,
      stateList: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      ratingList: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
      name: "",
      DOB: "",
      sex: "",
      height: {
        feet: 0,
        inches: 0,
      },
      weight: 0,
      language: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      emailOkay: false,
      phone: "",
      phoneType: "",
      voicemailOkay: false,
      healthRating: "",
      liveAlone: false,
      homebound: false,
      haveCaregiver: false,
      seePcpRegularly: false,
      pcpName: "",
      poaOrSurrogate: "",
      dentalHealthRating: "",
      dentistName: "",
      lastDentalVisit: "",
      useDmeEquipment: false,
      dmeEquipmentUsed: "",
      alcoholDrugs: false,
      autoimmuneDisorder: false,
      cancer: false,
      cvdDisease: false,
      chronicHeartFailure: false,
      dementia: false,
      diabetes: false,
      esld: false,
      esrd: false,
      severeHematologicDisorder: false,
      hivAids: false,
      chronicLungDisorders: false,
      chronicDisablingMentalHealth: false,
      neurologicDisorders: false,
      stroke: false,
      agentName: "",
      completedDate: "",
      personFillingForm: "",
      relationship: "",
      memberPresent: false,
      educatedCmProgram: false,
      medicationInformation: [
        {
          medicationName: "",
          dosage: "",
          frequency: "",
        },
      ],
    }
  },
  methods: {
    previous() {
      this.$emit("selectedTab", 9)
    },
    skip() {
      this.$emit("selectedTab", 10)
    },
    generatePDF() {
      // pdf creation route
      axios.post(`/api/enrollment/agentNeeds/${this.recordID}`, {
        needsAssessment: {
            name: this.name,
            DOB: this.DOB,
            sex: this.sex,
            height: this.height,
            weight: this.weight,
            language: this.language,
            address: this.address,
            city: this.city,
            state: this.state,
            zip: this.zip,
            email: this.email,
            emailOkay: this.emailOkay,
            phone: this.phone,
            phoneType: this.phoneType,
            voicemailOkay: this.voicemailOkay,
            healthRating: this.healthRating,
            liveAlone: this.liveAlone,
            homebound: this.homebound,
            haveCaregiver: this.haveCaregiver,
            seePcpRegularly: this.seePcpRegularly,
            pcpName: this.pcpName,
            poaOrSurrogate: this.poaOrSurrogate,
            dentalHealthRating: this.dentalHealthRating,
            dentistName: this.dentistName,
            lastDentalVisit: this.lastDentalVisit,
            useDmeEquipment: this.useDmeEquipment,
            dmeEquipmentUsed: this.dmeEquipmentUsed,
            alcoholDrugs: this.alcoholDrugs,
            autoimmuneDisorder: this.autoimmuneDisorder,
            cancer: this.cancer,
            cvdDisease: this.cvdDisease,
            chronicHeartFailure: this.chronicHeartFailure,
            dementia: this.dementia,
            diabetes: this.diabetes,
            esld: this.esld,
            esrd: this.esrd,
            severeHematologicDisorder: this.severeHematologicDisorder,
            hivAids: this.hivAids,
            chronicLungDisorders: this.chronicLungDisorders,
            chronicDisablingMentalHealth: this.chronicDisablingMentalHealth,
            neurologicDisorders: this.neurologicDisorders,
            stroke: this.stroke,
            agentName: this.agentName,
            completedDate: this.completedDate,
            personFillingForm: this.personFillingForm,
            relationship: this.relationship,
            memberPresent: this.memberPresent,
            educatedCmProgram: this.educatedCmProgram,
            medicationInformation: this.medicineHelper(),
        }
      }).then((response) => {
        this.handleFiles(response.data)
      })
    },
    async handleFiles(file) {
      let formData = new FormData();
      formData.append("pdf", file);

      await axios
        .post(
          `api/enrollment/documents/upload/agentNeeds/${this.medicareID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).catch((err) => {
          console.log(err);
        });
    },
    medicineHelper() {
      const arr = []
      this.medicationInformation.forEach((element) => {
        if (element.medicationName || element.dosage || element.frequency) {
          arr.push(element)
        }
      })
      return arr
    },
    saveData() {
      this.generatePDF()
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          needsAssessment: {
            name: this.name,
            DOB: this.DOB,
            sex: this.sex,
            height: this.height,
            weight: this.weight,
            language: this.language,
            address: this.address,
            city: this.city,
            state: this.state,
            zip: this.zip,
            email: this.email,
            emailOkay: this.emailOkay,
            phone: this.phone,
            phoneType: this.phoneType,
            voicemailOkay: this.voicemailOkay,
            healthRating: this.healthRating,
            liveAlone: this.liveAlone,
            homebound: this.homebound,
            haveCaregiver: this.haveCaregiver,
            seePcpRegularly: this.seePcpRegularly,
            pcpName: this.pcpName,
            poaOrSurrogate: this.poaOrSurrogate,
            dentalHealthRating: this.dentalHealthRating,
            dentistName: this.dentistName,
            lastDentalVisit: this.lastDentalVisit,
            useDmeEquipment: this.useDmeEquipment,
            dmeEquipmentUsed: this.dmeEquipmentUsed,
            alcoholDrugs: this.alcoholDrugs,
            autoimmuneDisorder: this.autoimmuneDisorder,
            cancer: this.cancer,
            cvdDisease: this.cvdDisease,
            chronicHeartFailure: this.chronicHeartFailure,
            dementia: this.dementia,
            diabetes: this.diabetes,
            esld: this.esld,
            esrd: this.esrd,
            severeHematologicDisorder: this.severeHematologicDisorder,
            hivAids: this.hivAids,
            chronicLungDisorders: this.chronicLungDisorders,
            chronicDisablingMentalHealth: this.chronicDisablingMentalHealth,
            neurologicDisorders: this.neurologicDisorders,
            stroke: this.stroke,
            agentName: this.agentName,
            completedDate: this.completedDate,
            personFillingForm: this.personFillingForm,
            relationship: this.relationship,
            memberPresent: this.memberPresent,
            educatedCmProgram: this.educatedCmProgram,
            medicationInformation: this.medicineHelper(),
          },
        })
        .then(() => {
          this.$emit("selectedTab", 10)
        })
        .catch((err) => {
          console.log(err)
          console.log("Error saving form data.")
        })
    },
  },
}
</script>
