<template>
  <div>
    <v-form
      ref="claimDetailsForm"
      v-model="claimDetailsValid"
      lazy-validation
      class="pa-3"
    >
      <v-btn color="primary" depressed class="mb-5" @click="searchAuthModal()"
        >Attach Authorization</v-btn
      >
      <v-row>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.claimID"
            label="Claim ID"
            outlined
            dense
            required
            :rules="[formData.claimID != '' || 'Please enter the claim ID']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-select
            v-model="formData.claimStatus"
            :items="statuses"
            :menu-props="{ maxHeight: '400' }"
            label="Claim Status"
            outlined
            dense
            return-object
            :rules="[formData.claimStatus != '' || 'Please enter claim status']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.patientID"
            label="Patient ID"
            outlined
            dense
            required
            :rules="[formData.patientID != '' || 'Please enter the patient ID']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-select
            v-model="formData.gender"
            :items="genders"
            :menu-props="{ maxHeight: '400' }"
            label="Gender"
            outlined
            dense
            return-object
            :rules="[formData.gender != '' || 'Please enter gender']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.DOB"
                label="Date of Birth"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                :rules="[formData.DOB != '' || 'Please enter the DOB']"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker ref="picker" v-model="formData.DOB" min="1900-01-01">
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="$refs.menu1.save(formData.DOB)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.medicareNumber"
            label="Medicare Number"
            outlined
            dense
            required
            :rules="[
              formData.medicareNumber != '' || 'Please enter Medicare number',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.taxID"
            label="Provider Tin (Tax ID)"
            outlined
            dense
            required
            :rules="[
              formData.taxID != '' || 'Please enter Provider Tin (Tax ID)',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.providerNPI"
            label="Provider NPI"
            outlined
            dense
            required
            :rules="[formData.providerNPI != '' || 'Please enter Provider NPI']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-text-field
            v-model="formData.providerState"
            label="Provider State"
            outlined
            dense
            required
            :rules="[
              formData.providerState != '' || 'Please enter Provider State',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-text-field
            v-model="formData.providerZip"
            label="Provider Zip"
            outlined
            dense
            required
            :rules="[formData.providerZip != '' || 'Please enter Provider Zip']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.taxonomy"
            label="Taxonomy"
            outlined
            dense
            required
            :rules="[formData.taxonomy != '' || 'Please enter Taxonomy']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.ambulancePickUpZip"
            label="Ambulance Pick Up Zip"
            outlined
            dense
            required
            :rules="[
              formData.ambulancePickUpZip != '' ||
                'Please enter Ambulance Pick Up Zip',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.remittanceAddress"
            label="Remittance Address"
            outlined
            dense
            required
            :rules="[
              formData.remittanceAddress != '' ||
                'Please enter Remittance Address',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.remittanceCity"
            label="Remittance City"
            outlined
            dense
            required
            :rules="[
              formData.remittanceCity != '' || 'Please enter Remittance City',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.remittanceState"
            label="Remittance State"
            outlined
            dense
            required
            :rules="[
              formData.remittanceState != '' || 'Please enter Remittance State',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.remittanceZip"
            label="Remittance Zip"
            outlined
            dense
            required
            :rules="[
              formData.remittanceZip != '' || 'Please enter Remittance Zip',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.totalCharge"
            label="Total Charge"
            outlined
            dense
            required
            :rules="[formData.totalCharge != '' || 'Please enter Total Charge']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.customRate"
            label="Custom Rate"
            outlined
            dense
            required
            :rules="[formData.customRate != '' || 'Please enter Custom Rate']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.baseAllowed"
            label="Base Allowed"
            outlined
            dense
            required
            :rules="[formData.baseAllowed != '' || 'Please enter Base Allowed']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.patientZip"
            label="Patient Zip"
            outlined
            dense
            required
            :rules="[formData.patientZip != '' || 'Please enter Patient Zip']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.authorizationID"
            label="Authorization ID"
            outlined
            dense
            required
            :rules="[
              formData.authorizationID != '' || 'Please enter Authorization ID',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.mods"
            label="Mods"
            outlined
            dense
            required
            :rules="[formData.mods != '' || 'Please enter Mods']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.sequesterAdjustment"
            label="Sequester Adjustment"
            outlined
            dense
            required
            :rules="[
              formData.sequesterAdjustment != '' ||
                'Please enter Sequester Adjustment',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.readmissionReductionProgram"
            label="Readmission Reduction Program"
            outlined
            dense
            required
            :rules="[
              formData.readmissionReductionProgram != '' ||
                'Please enter Readmission Reduction Program',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="formData.valueBasedPurchasingProgram"
            label="Value Based Purchasing Program"
            outlined
            dense
            required
            :rules="[
              formData.valueBasedPurchasingProgram != '' ||
                'Please enter Value Based Purchasing Program',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-alert dense outlined type="error" v-model="alert">
            Please sign your name below to continue
          </v-alert>
          <Signature ref="Signature" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.email"
            label="Email"
            outlined
            dense
            required
            :rules="[formData.email != '' || 'Please enter Email']"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn text color="accent lighten-1" class="mr-2" @click="backOneStep">
          <v-icon>mdi-chevron-left</v-icon>
          Back
        </v-btn>
        <v-btn @click="validateClaimDetailsForm" color="accent" depressed>
          Continue
          <v-icon class="ml-2">mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="authModal" max-width="900px" persistent>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Add Authorization(s)</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="authModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-card-title>Something here</v-card-title> -->
        <v-card-text class="subtitle-1 pa-3"
          >Enter the member ID below and select the corresponding
          authorization(s) for this claim</v-card-text
        >
        <v-card-text>
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="memberIDSearch"
                label="Member ID"
                class="pa-3"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn color="accent" class="ma-3" @click="searchAuths()"
                >Search</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="authData.length > 0 && !searchAuthLoad">
            <v-col cols="12">
              <v-data-table
                v-model="selectedAuths"
                :headers="authDataHeaders"
                :items="authData"
                :loading="searchAuthLoad"
                :single-expand="singleExpand"
                show-expand
                multi-select
                show-select
                item-key="_id"
                multi-sort
                class="pa-3"
              >
                <template v-slot:item.Services="{ item }">
                  {{ item.Services.length }}
                </template>
                <template v-slot:item.ReceiveDate="{ item }">
                  {{ item.ReceiveDate.slice(0, 10) }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-3">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Service Description</th>
                            <th class="text-left">CPT/HCPCS</th>
                            <th class="text-left">Diagnosis Code</th>
                            <th class="text-left">Visits</th>
                            <th class="text-left">Units</th>
                            <th class="text-left">Matches Claim Codes</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(service, index) in item.Services"
                            :key="index"
                          >
                            <td>
                              {{ service.ServiceDesc }}
                            </td>
                            <td>
                              {{ service.CPT_HCPCS }}
                            </td>
                            <td>
                              {{ service.DIAG }}
                            </td>
                            <td>
                              {{ service.Visits }}
                            </td>
                            <td>
                              {{ service.Units }}
                            </td>
                            <td
                              v-if="
                                service.CPT_HCPCS === formData.HCPCS &&
                                service.DIAG === formData.diagnosisCode &&
                                service.Visits === formData.visits &&
                                service.Units === formData.units
                              "
                            >
                              <v-icon color="success">mdi-check</v-icon>
                            </td>
                            <td v-else>
                              <v-icon color="error">mdi-close</v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="authModal = false"> Cancel </v-btn>
          <v-btn @click="saveSelectedAuth" color="success" text>
            Save Selected
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Signature from "../new-enrollment-components/Signature.vue";
export default {
  components: {
    Signature,
  },
  data() {
    return {
      formData: {
        claimID: "",
        claimStatus: "",
        patientID: "",
        gender: "",
        DOB: "",
        taxID: "",
        medicareNumber: "",
        providerNPI: "",
        providerState: "",
        providerZip: "",
        remittanceAddress: "",
        remittanceCity: "",
        remittanceState: "",
        remittanceZip: "",
        signature: "",
        email: "",
        taxonomy: "",
        ambulancePickUpZip: "",
        totalCharge: "",
        baseAllowed: "",
        customRate: "",
        patientZip: "",
        authorizationID: "",
        sequesterAdjustment: "",
        readmissionReductionProgram: "",
        valueBasedPurchasingProgram: "",
        mods: "",
        authorizations: [],
      },
      menu1: false,
      claimDetailsValid: false,
      alert: false,
      statuses: [
        "New",
        "Ready To Price",
        "Priced",
        "Waiting on Funding",
        "Need Authorization",
        "Paid",
        "Reprice Needed",
        "Disputed Claim",
      ],
      genders: ["Male", "Female"],
      authModal: false,
      memberIDSearch: "",
      searchAuthLoad: false,
      authData: [],
      selectedAuths: [],
      singleExpand: false,
      authDataHeaders: [
        { text: "Member Name", value: "MemberName" },
        { text: "Authorization ID", value: "AuthID" },
        { text: "Receive Date", value: "ReceiveDate" },
        { text: "Determination", value: "Determination" },
        { text: "Services", value: "Services" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    searchAuthModal() {
      this.authModal = true;
    },
    async searchAuths() {
      this.searchAuthLoad = true;
      axios
        .get(
          `/api/authorizations/getMemberAuthorizations/${this.memberIDSearch}`
        )
        .then((response) => {
          this.authData = response.data;
          this.searchAuthLoad = false;
        });
    },
    saveSelectedAuth() {
      this.formData.authorizations = this.selectedAuths;
      // Checking to see if any of the services in the selected auths match up with the codes input on the claim, if so we auto fill the information we can
      this.selectedAuths.forEach((auth) => {
        auth.Services.forEach((service) => {
          if (
            service.CPT_HCPCS === this.formData.HCPCS &&
            service.DIAG === this.formData.diagnosisCode &&
            service.Visits === this.formData.visits &&
            service.Units === this.formData.units
          ) {
            this.formData.patientID = this.formData.authorizations[0].SonderID;
            this.formData.authorizationID =
              this.formData.authorizations[0].AuthID;
            this.formData.DOB = this.formData.authorizations[0].DOB.slice(
              0,
              10
            );
            this.formData.taxID =
              this.formData.authorizations[0].RendBillingTIN;
            this.formData.providerNPI =
              this.formData.authorizations[0].RendPhysNPI;
            this.formData.remittanceAddress =
              this.formData.authorizations[0].RendPhysAddress;
            this.formData.remittanceCity =
              this.formData.authorizations[0].RendPhysCity;
            this.formData.remittanceState =
              this.formData.authorizations[0].RendPhysState;
            this.formData.remittanceZip =
              this.formData.authorizations[0].RendPhysZip;
          }
        });
      });
      this.authModal = false;
    },
    validateClaimDetailsForm() {
      if (this.$refs.claimDetailsForm.validate()) {
        this.$emit("details", this.formData);
      }
    },
    backOneStep() {
      this.$emit("backOne");
    },
  },
  watch: {},
};
</script>
