<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <span class="font-weight-black justify-start ma-3">Please Select Only One</span>
      </v-row>
      <div>
        <v-row>
          <v-col>
            <v-radio-group
              v-model="selectedPlan"
              :rules="[
                this.selectedPlan != null || 'Please pick one of the plans',
              ]"
            >
              <v-radio
                class="pb-4"
                label="H1748-001 Complete Health Medicare Advantage Part D HMO Plan"
                value="H1748-001 Complete Health Medicare Advantage Part D HMO Plan"
              ></v-radio>
              <v-radio
                class="pb-4"
                label="H1748-003 Diabetes Wellness Chronic Special Needs Plan"
                value="H1748-003 Diabetes Wellness Chronic Special Needs Plan"
              ></v-radio>
              <v-radio
                label="H1748-004 Heart Healthy Chronic Special Needs Plan"
                value="H1748-004 Heart Healthy Chronic Special Needs Plan"
              ></v-radio>
              <v-radio
                label="H1748-005 Dual Complete Dual Eligible Special Needs Plan"
                value="H1748-005 Dual Complete Dual Eligible Special Needs Plan"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
      <v-row justify="end">
        <v-btn text :disabled="!valid" color="primary" @click="validate">
          Next
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
export default {
  props: ["recordID"],
  data: () => ({
    valid: true,
    selectedPlan: null,
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData();
      } 
    },
    saveData() {
      this.$emit("selectedTab", 1);
      this.$emit("selectedPlan", this.selectedPlan);
    },
  },
};
</script>
