<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title> Agents </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="px-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="reportData"
              :search="search"
              sort-by="date"
              :sort-desc="true"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.agentID="{ item }">
                <agent-modal
                  :agent="item"
                  v-if="
                    user.role === 'superuser' ||
                    user.role === 'manager' ||
                    user.role === 'fmo'
                  "
                ></agent-modal>

                <span v-else>{{ item.agentID }}</span>
              </template>

              <template v-slot:item.roster="{ item }">
                {{ item.roster.length }}
              </template>

              <template v-slot:item.trainingComplete="{ item }">
                <v-icon :color="colorIcon(item.trainingComplete)">
                  {{ item.trainingComplete ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>

              <template v-slot:item.result="{ item }">
                <v-icon
                  @click="openResults(item)"
                  :color="colorIcon(item.result)"
                >
                  {{ item.result === "Pass" ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>

              <template v-slot:item.ACTIONS="{ item }">
                <v-icon small class="mr-2" @click="openEditDialog(item)">
                  mdi-pencil
                </v-icon>
              </template>

              <template slot="footer">
                <ExportCSV :data="this.csvData" :name="'agent-testing.csv'">
                  <v-btn color="accent" text class="export-button">
                    Export to CSV
                  </v-btn>
                </ExportCSV>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" max-width="450px">
      <template>
        <v-form ref="editForm" v-model="editValid" lazy-validation>
          <v-card>
            <v-card-title> Edit Agent </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Search FMO Name"
                      v-model="agentToEdit.agentFmo"
                      :items="fmoList"
                      :loading="fmoListLoading"
                      :search-input.sync="fmoSearch"
                      item-text="NAME"
                      return-object
                      clearable
                      outlined
                      dense
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Search for FMO
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.NAME }}</v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            item.NPN
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="closeEditDialog" text>Cancel</v-btn>
              <v-btn
                @click="validateEdits"
                :disabled="!editValid"
                color="accent"
                text
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>

    <v-dialog v-model="examResultsDialog" max-width="50%">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Agent Exam Results</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.stop="examResultsDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card tile>
        <v-card-text class="py-3">
          <v-row v-if="selectedAgentAnswers.length == 0">
            <v-col>
              <v-alert type="info">No exam results to display.</v-alert>
            </v-col>
          </v-row>
          <v-row
            v-else
            v-for="(question, qIndex) in this.quizData.questions"
            :key="question.question"
            justify="center"
          >
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  {{ question.question }}
                </v-card-title>
                <v-card-text>
                  <v-radio-group v-model="selectedAgentAnswers[qIndex]">
                    <v-radio
                      v-for="(answer, index) in question.answers"
                      disabled
                      :key="answer"
                      :label="answer"
                      :value="index"
                      :active-class="
                        selectedAgentAnswers[qIndex] == question.correctAnswer
                          ? 'right-answer'
                          : 'wrong-answer'
                      "
                      :class="
                        index == question.correctAnswer
                          ? 'right-answer pa-3'
                          : 'pa-3'
                      "
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="saveSuccess" color="success" :timeout="4000">
      Changes have successfully been saved
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="saveSuccess = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import AgentModal from "../components/AgentModal";
import ExportCSV from "vue-json-csv";
import quiz from "../assets/quiz.json";

export default {
  name: "Agents",
  components: {
    AgentModal,
    ExportCSV,
  },
  data() {
    return {
      load: false,
      search: "",
      quizData: quiz,
      examResultsDialog: false,
      selectedAgentAnswers: [],
      headers: [
        { text: "Actions", value: "ACTIONS" },
        { text: "First Name", value: "fname" },
        { text: "Last Name", value: "lname" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Email", value: "agentEmail" },
        { text: "Agent ID", value: "agentID" },
        { text: "FMO", value: "fmoName" },
        {
          text: "Training Completed",
          value: "trainingComplete",
          align: "center",
        },
        { text: "Results", value: "result" },
        { text: "Num. Of Attempts", value: "attempts" },
        { text: "Date", value: "date" },
        { text: "Members Enrolled", value: "roster" },
      ],
      editValid: false,
      editDialog: false,
      editedIndex: -1,
      agentToEdit: {},
      fmoList: [],
      fmoListLoading: false,
      fmoSearch: null,
      saveSuccess: false,
      reportData: [],
      csvData: [],
      fmoAgentIds: [],
    };
  },
  methods: {
    getHeaders() {
      if (this.user.role === "fmo") {
        this.headers = [
          { text: "First Name", value: "fname" },
          { text: "Last Name", value: "lname" },
          { text: "Phone Number", value: "phoneNumber" },
          { text: "Email", value: "agentEmail" },
          { text: "Agent ID", value: "agentID" },
          { text: "FMO", value: "fmoName" },
          {
            text: "Training Completed",
            value: "trainingComplete",
            align: "center",
          },
          { text: "Results", value: "result" },
          { text: "Num. Of Attempts", value: "attempts" },
          { text: "Date", value: "date" },
          { text: "Members Enrolled", value: "roster" },
        ];
      } else {
        this.headers = [
          { text: "Actions", value: "ACTIONS" },
          { text: "First Name", value: "fname" },
          { text: "Last Name", value: "lname" },
          { text: "Phone Number", value: "phoneNumber" },
          { text: "Email", value: "agentEmail" },
          { text: "Agent ID", value: "agentID" },
          { text: "FMO", value: "fmoName" },
          {
            text: "Training Completed",
            value: "trainingComplete",
            align: "center",
          },
          { text: "Results", value: "result" },
          { text: "Num. Of Attempts", value: "attempts" },
          { text: "Date", value: "date" },
          { text: "Members Enrolled", value: "roster" },
        ];
      }
    },
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "agent") {
            this.$router.push("/");
          } else if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else {
            this.getHeaders();
            this.getAgents();
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async getAgents() {
      this.reportData = [];
      this.load = true;
      let agentIds;
      if (this.user.role === "fmo") {
        await this.getFmoAgents();
        agentIds = this.fmoAgentIds;
      } else {
        agentIds = "all";
      }
      await axios.get(`api/agents/all/${agentIds}`).then((res) => {
        this.reportData = res.data;
        // Make copy of report data so we can remove the _id field for exporting csv's
        this.csvData = JSON.parse(JSON.stringify(this.reportData));
        this.csvData.forEach((agent) => {
          delete agent._id;
          agent.roster = agent.roster.length;
        });
        this.load = false;
      });
    },
    openResults(agent) {
      if (agent.answers != null) {
        this.selectedAgentAnswers = agent.answers;
      } else {
        this.selectedAgentAnswers = [];
      }
      this.examResultsDialog = true;
    },
    openEditDialog(item) {
      this.editedIndex = this.reportData.indexOf(item);
      this.agentToEdit = Object.assign({}, item);
      this.fmoList = [{ NAME: item.fmoName }];
      this.agentToEdit.agentFmo = {
        NAME: item.fmoName,
      };
      this.editDialog = true;
    },
    validateEdits() {
      if (this.$refs.editForm.validate()) {
        this.saveEdits();
      }
    },
    async saveEdits() {
      let data = {
        fmoNpn: this.agentToEdit.agentFmo ? this.agentToEdit.agentFmo.NPN : "",
        fmoName: this.agentToEdit.agentFmo
          ? this.agentToEdit.agentFmo.NAME
          : "",
        agentID: this.agentToEdit.agentID,
      };
      await axios
        .post("/api/agents/update", data)
        .then((res) => {
          this.reportData[this.editedIndex].fmoName = res.data.fmoName;
          this.saveSuccess = true;
          this.closeEditDialog();
        })
        .catch((err) => {
          console.log(err);
          console.log("Couldn't update user.");
        });
    },
    closeEditDialog() {
      this.editDialog = false;
      this.agentToEdit = {};
      this.fmoList = [];
    },
    async getFmoAgents() {
      this.fmoAgentIds = [];
      await axios.get(`api/fmo/agents/${this.user.fmoNpn}`).then((res) => {
        this.fmoAgentIds = res.data;
      });
    },
    colorIcon(res) {
      if (res === "Pass" || res === true) {
        return "success";
      } else {
        return "red";
      }
    },
  },
  watch: {
    fmoSearch() {
      if (this.fmoSearch !== null) {
        this.fmoListLoading = true;

        // Lazily load input items
        axios.get(`/api/search/fmo/${this.fmoSearch[0]}`).then((response) => {
          this.fmoList = response.data;
          this.fmoListLoading = false;
        });
      }
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}

.v-card__title {
  word-break: break-word !important;
}

.right-answer {
  background-color: rgb(0, 200, 83, 0.5);
  border-radius: 5px;
}
.wrong-answer {
  background-color: rgba(255, 82, 82, 0.5);
  border-radius: 5px;
}
</style>
