<template>
  <v-dialog v-model="memberSearchDialog" max-width="750px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        color="accent"
        class="mb-2"
        block
        small
      >
        <v-icon>mdi-account-search</v-icon>
        Search Members
      </v-btn>
    </template>

    <v-toolbar dark color="primary">
      <v-toolbar-title>Member Search</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="cancelSelectedMember">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card class="pa-5">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              v-model="searchMethod"
              label="Search Method"
              :items="[
                'Member ID',
                'Name',
                'Birthday',
              ]"
              required
              :rules="[searchMethod != '' || 'Method of search is required']"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-if="searchMethod === 'Member ID'"
              :required="searchMethod === 'Member ID'"
              :rules="[memSearchId != '' || 'ID is required']"
              v-model="memSearchId"
              label="ID"
              outlined
              dense
            ></v-text-field>
            
            <v-text-field
              v-if="searchMethod === 'Name'"
              :required="searchMethod === 'Name'"
              :rules="[memSearchName != '' || 'Name is required']"
              v-model="memSearchName"
              label="Full Name"
              outlined
              dense
            ></v-text-field>

            <v-menu
              v-if="searchMethod === 'Birthday'"
              v-model="memSearchDobMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="memSearchDob"
                  :required="searchMethod === 'Birthday'"
                  :rules="[memSearchName != '' || 'Birthday is required']"
                  label="Birthday"
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="memSearchDob"
                @input="memSearchDobMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn 
              @click="validate"
              color="accent" 
              block
            >
              <v-icon class="mr-2">mdi-magnify</v-icon>
              Search Members
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-divider></v-divider>

      <v-row v-if="memSearchLoad" justify="center">
        <v-col align-self="center" class="text-center">
          <v-progress-circular
            :size="20"
            class="mt-2"
            color="accent"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <div v-if="memSearchResults.length >= 1 && !memSearchLoad">
        <v-row>
          <v-col cols="12">
            <v-data-table
              v-model="memSearchSelected"
              :headers="memSearchHeaders"
              :items="memSearchResults"
              item-key="_id"
              :loading="memSearchLoad"
              single-select
              show-select
              multi-sort
              loading-text="Loading data..."
            ></v-data-table>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="cancelSelectedMember"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="saveSelectedMember"
            color="success"
            text
          >
            Save Selected
          </v-btn>
        </v-card-actions>
      </div>

      <div v-if="memSearchResults.length === 0 && !memSearchLoad">
        <v-alert
          class="mt-2"
          border="top"
          colored-border
          type="info"
          color="accent"
          elevation="2"
        >
          Please search to find matching members.
        </v-alert>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "memSearchDialog",
  props: [],
  components: {
  },
  data() {
    return {
      memberSearchDialog: false,
      memSearchLoad: false,
      searchMethod: '',
      memSearchId: '',
      memSearchName: '',
      memSearchDob: '',
      memSearchDobMenu: false,
      memSearchHeaders: [
        { text: "ID", value: "memberID" },
        { text: "Name", value: "name" },
        { text: "Date of Birth", value: "birthDate" },
      ],
      memSearchResults: [],
      memSearchSelected: [],
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.searchMembers();
      }
    },
    searchMembers() {
      this.memSearchLoad = true;
      this.memSearchResults = [];
      
      // set the param to the correct value based on field youare searching for
      let searchParam = '';
      if(this.searchMethod === 'Member ID') {
        searchParam = this.memSearchId;
      } else if (this.searchMethod === 'Name') {
        searchParam = this.memSearchName;
      } else if (this.searchMethod === 'Birthday') {
        searchParam = this.memSearchDob;
      }

      axios
        .get(`/api/search/members/${this.searchMethod}/${searchParam}`)
        .then(response => {
          this.memSearchResults = response.data;
          this.memSearchLoad = false;
        })
    },
    saveSelectedMember() {
      // send member to parent to save
      this.$emit('memberSelected', this.memSearchSelected[0]);
      this.searchMethod = '';
      this.memSearchDob = '';
      this.memSearchName = '';
      this.memSearchId = ''
      this.memSearchResults = [];
      this.memSearchSelected = []
      this.memberSearchDialog = false;
    },
    cancelSelectedMember() {
      this.searchMethod = '';
      this.memSearchDob = '';
      this.memSearchName = '';
      this.memSearchId = ''
      this.memSearchResults = [];
      this.memSearchSelected = []
      this.memberSearchDialog = false;
    }
  },
  computed: {
  },
  watch: {
    searchMethod() {
      this.memSearchId ='';
      this.memSearchName ='';
      this.memSearchDob ='';
    }
  }
};
</script>

<style scoped>

</style>
