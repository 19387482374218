<template>
  <v-dialog v-model="paymentDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        @click="getFmoInfo()"
        color="success"
        :disabled="agentInfo.agentID ? false : true"
        outlined 
        dense 
        small
      >
        Add payment
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Create New Payment</span>
      </v-card-title>
      <v-progress-circular
        v-if="load"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-card-text v-else-if="!load">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Agent ID"
                v-model="newPayment.agentID"
                :rules="[newPayment.agentID != '' || 'Agent ID is required']"
                required
                outlined
                dense
                :readonly="agentInfo.agentID != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Patient Name"
                v-model="newPayment.patientName"
                :rules="[newPayment.patientName != '' || 'Patient Name is required']"
                required
                outlined
                dense
                :readonly="agentInfo.agentID != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Patient ID"
                v-model="newPayment.patientID"
                :rules="[newPayment.patientID != '' || 'Patient ID is required']"
                required
                outlined
                dense
                :readonly="agentInfo.agentID != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Payment Type"
                :items="['New Enrollment', 'Renewal', 'Disenrollment', 'Reversal']"
                v-model="newPayment.paymentType"
                :rules="[newPayment.paymentType != '' || 'Payment Type is required']"
                outlined
                dense
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Amount"
                v-model.number="newPayment.amountPaid"
                :rules="[newPayment.amountPaid > 0 || 'Amount Paid is required']"
                required
                placeholder="0"
                type="number"
                prefix="$"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="newPayment.fmoName != undefined">
              <v-text-field
                label="FMO Name"
                v-model="newPayment.fmoName"
                :rules="[newPayment.fmoName != '' || 'FMO Name is required']"
                outlined
                dense
                required
                :readonly="agentInfo.agentID != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="newPayment.fmoPayment != undefined">
              <v-text-field
                label="FMO Payment"
                v-model="newPayment.fmoPayment"
                :rules="[newPayment.fmoPayment > 0 || 'FMO Payment is required']"
                type="number"
                prefix="$"
                outlined
                dense
                required
                :readonly="agentInfo.agentID != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox
                class="mt-0"
                v-model="newPayment.paid"
                hide-details
                label="Paid"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="closePaymentDialog()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          text
          @click="validate()"
        >
          Add Payment
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: "NewPaymentModal",
  props: ['agentInfo'],
  data() {
    return {
      paymentDialog: false,
      valid: true,
      load: false,
      newPayment: {
        agentID: '',
        patientName: '',
        patientID: '',
        amountPaid: 0,
        paymentType: '',
        fmoName: '',
        fmoPayment: 0,
        paid: false
      }
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.saveNewPayment()
      }
    },
    getFmoInfo() {
      this.load = true;

      axios.get(`/api/payments/fmo/${this.agentInfo.agentID}`)
        .then(res => {
          this.newPayment.fmoName = res.data[0].fmoName;
          this.newPayment.fmoPayment = res.data[0].fmoRate;
        })
        
      this.load = false;
    },
    saveNewPayment() {
      let payment = this.newPayment;

      axios
        .post("/api/payments/new", payment)
        .then(response => {
          // if successfully saved display snackbar notification
          if(response.status === 201) {
            this.$emit("paymentCreated", response.data.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
      this.paymentDialog = false;
      this.newPayment = {
        agentID: '',
        patientName: '',
        patientID: '',
        amountPaid: 0,
        paymentType: '',
        paid: false
      }
    },
    closePaymentDialog() {
      this.newPayment = {
        agentID: '',
        patientName: '',
        patientID: '',
        amountPaid: 0,
        paymentType: '',
        fmoName: '',
        fmoPayment: 0,
        paid: false
      }
      this.paymentDialog = false;
    },
  },
  watch: {
    async paymentDialog() {
      if(this.paymentDialog === true) {
        this.newPayment.agentID = this.agentInfo.agentID;
        this.newPayment.patientName = this.agentInfo.Full_Name;
        this.newPayment.patientID = this.agentInfo.medicareNumber;
      }
    },
  },
};
</script>