<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="9">
          <v-text-field
            class="py-0"
            v-model="search"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-dialog v-model="newUserDialog" max-width="750px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" block dark color="accent" class="mb-16">
                <v-icon>mdi-account-plus</v-icon>
                Add User
              </v-btn>
            </template>
            <template>
              <v-form ref="newUserForm" v-model="newUserValid" lazy-validation>
                <v-card>
                  <v-card-title> Add User </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newUser.firstName"
                          :rules="[(v) => !!v || 'First Name is required']"
                          label="First Name"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newUser.lastName"
                          :rules="[(v) => !!v || 'Last Name is required']"
                          label="Last Name"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="newUser.role === 'Agent'" cols="12" md="6">
                        <v-text-field
                          v-model="newUser.agentNpn"
                          :rules="[(v) => !!v || 'NPN is required']"
                          label="NPN"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="newUser.role"
                          :rules="[(v) => !!v || 'Role is required']"
                          :items="roles"
                          label="Role"
                          required
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newUser.email"
                          autocomplete="new-password"
                          :rules="[
                            (v) => !!v || 'E-mail is required',
                            (v) =>
                              /.+@.+\..+/.test(v) || 'E-mail must be valid',
                            (v) =>
                              !/\s/.test(v) ||
                              'Please remove spaces from email',
                          ]"
                          label="Email"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newUser.password"
                          autocomplete="new-password"
                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPass ? 'text' : 'password'"
                          :rules="[(v) => !!v || 'Password is required']"
                          label="Password"
                          required
                          @click:append="showPass = !showPass"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="newUser.clinicalType"
                          :items="['Nurse', 'MD']"
                          label="Clinical Type"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" v-if="newUser.role === 'Fmo'">
                        <v-autocomplete
                          label="Search FMOs"
                          v-model="newUser.fmo"
                          :disabled="newUser.role !== 'Fmo'"
                          :items="fmoList"
                          :loading="fmoListLoading"
                          item-text="NPN"
                          return-object
                          clearable
                          required
                          :rules="[
                            newUser.fmo != '' || 'Assignment is required',
                          ]"
                          outlined
                          dense
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title>
                                Search for FMO
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.NPN }}</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                item.NAME
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelNewUser" text>Cancel</v-btn>
                    <v-btn
                      @click="validateNewUser"
                      :disabled="!newUserValid"
                      color="accent"
                      text
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </template>
          </v-dialog>
        </v-col>
      </v-row>

      <v-data-table
        :loading="load"
        :headers="headers"
        :items="userData"
        :search="search"
        multi-sort
        loading-text="Loading data..."
        class="elevation-0 col-md-12 m-auto"
      >

        <template v-slot:item.lastLogin="{ item }">
          <span v-if="item.lastLogin">
          {{ item.lastLogin.substr(0, 10) }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-slot:item.created="{ item }">
          {{ item.created.substr(0, 10) }}
        </template>
        
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small tag="hi" class="mr-2" @click="openEditDialog(item)" v-bind="attrs"
                v-on="on">
                  mdi-pencil
                </v-icon>
            </template>
            <span>Edit User</span>
          </v-tooltip>
        </template>
        <template v-slot:item.reset="{ item }">
          <v-btn
            v-if="item.firstLogin"
            x-small
            width="100%"
            outlined
            disabled
            color="green"
          >
            Awaiting Login
          </v-btn>
          <v-btn
            v-else
            x-small
            outlined
            width="100%"
            color="primary"
            @click="openPasswordDialog(item)"
          >
            Reset Password
          </v-btn>
        </template>

        <template slot="footer">
          <ExportCSV :data="csvData" :name="'agent-testing.csv'">
            <v-btn color="accent" text class="export-button">
              Export to CSV
            </v-btn>
          </ExportCSV>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="editUserDialog" max-width="750px">
      <template>
        <v-form ref="editUserForm" v-model="editUserValid" lazy-validation>
          <v-card>
            <v-card-title> Edit User </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedUser.firstname"
                      :rules="[(v) => !!v || 'First Name is required']"
                      label="First Name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedUser.lastname"
                      :rules="[(v) => !!v || 'Last Name is required']"
                      label="Last Name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col v-show="editedUser.role === 'Agent'" cols="12" md="6">
                    <v-text-field
                      v-model="editedUser.userID"
                      :rules="[(v) => !!v || 'NPN is required']"
                      label="NPN"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="editedUser.role"
                      :rules="[(v) => !!v || 'Role is required']"
                      :items="roles"
                      label="Role"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedUser.email"
                      autocomplete="new-password"
                      :rules="[
                        (v) => !!v || 'E-mail is required',
                        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                        (v) =>
                          !/\s/.test(v) || 'Please remove spaces from email',
                      ]"
                      label="Email"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="editedUser.clinicaltype"
                      :items="['Nurse', 'MD']"
                      label="Clinical Type"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-if="editedUser.role !== 'superuser'"
                      v-model="editedUser.status"
                      :items="['Active', 'Pending', 'Suspended']"
                      label="Status"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" v-if="editedUser.role === 'Fmo'">
                    <v-autocomplete
                      label="Search FMOs"
                      v-model="editedUser.fmo"
                      :disabled="editedUser.role !== 'Fmo'"
                      :items="fmoList"
                      item-text="NPN"
                      return-object
                      clearable
                      required
                      :rules="[
                        editedUser.fmo != '' || 'Assignment is required',
                      ]"
                      outlined
                      dense
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Search for FMO
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title> {{ item.NPN }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.NAME
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" v-if="editedUser.role === 'Manager'">
                    <v-checkbox 
                      v-model="editedUser.receiveEmail"
                      label="Email Notification For New Agents"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="closeEditDialog" text>Cancel</v-btn>
              <v-btn
                @click="validateEdits"
                :disabled="!editUserValid"
                color="accent"
                text
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>


    <!-- ❗ Commenting this out for the time being. We don't want users deleting other users, and should instead use the Status field in the edit dialog ❗ -->
    <!-- <v-dialog v-model="deleteDialog" max-width="900px" :retain-focus="false">
      <template>
        <v-card>
          <v-card-title>
            Are you sure you want to delete the account for
            {{ userToDelete.firstname + " " + userToDelete.lastname }}?
          </v-card-title>

          <v-card-text>
            Deleting this will remove this user's account permanently
          </v-card-text>

          <v-card-actions>
            <v-btn @click="deleteUser()" color="primary" text> Yes </v-btn>

            <v-btn @click="deleteDialog = false" text>No</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog> -->

    <v-dialog v-model="passwordResetDialog" max-width="500px">
      <template>
        <v-card>
          <v-card-title> Password Reset </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                Are you sure you want to reset the password for
                {{ userToResetPassword.email }}?
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="resetPassword()" color="primary">Yes</v-btn>
            <v-btn text @click="passwordResetDialog = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-snackbar v-model="emailInUse" color="error" :timeout="3000">
      The email you have entered is already in use, please try another.
      <v-btn text @click="emailInUse = false"> Close </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import ExportCSV from "vue-json-csv";

export default {
  name: "UserAdmin",
  components: {
    ExportCSV,
  },
  data() {
    return {
      search: "",
      load: false,
      headers: [
         { text: "Actions", value: "actions", sortable: false },
        { text: "User ID", value: "userID" },
        { text: "First Name", value: "firstname" },
        { text: "Last Name", value: "lastname" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Last Login", value: "lastLogin" },
        { text: "Date Created", value: "created" },
        { text: "Reset Password", value: "reset" },
      ],
      userData: [],
      csvData: [],
      roles: ["Manager", "Agent", "HRA", "Credentialing", "Fmo", "Member Services"],
      showPass: false,
      newUserDialog: false,
      newUserValid: false,
      newUser: {
        firstName: "",
        lastName: "",
        agentNpn: "",
        role: "",
        email: "",
        password: "",
        clinicalType: "",
        fmo: {
          NPN: "",
        },
      },
      fmoList: [],
      fmoListLoading: false,
      fmoSearch: null,
      emailInUse: false,
      editUserDialog: false,
      editUserValid: false,
      editedIndex: -1,
      editedUser: {
        _id: "",
        userID: "",
        firstname: "",
        lastname: "",
        clinicaltype: "",
        role: "",
        email: "",
        status: "",
        password: "",
        fmo: {
          NPN: "",
        },
        recieveEmail: false
      },
      deleteDialog: false,
      userToDelete: {},
      passwordResetDialog: false,
      userToResetPassword: {},
    };
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "agent") {
            this.$router.push("/");
          } else if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else {
            this.getAllUsers();
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async getAllUsers() {
      this.userData = [];
      this.load = true;
      axios.get("api/users").then((res) => {
        this.userData = res.data;
        this.csvData = JSON.parse(JSON.stringify(this.userData));
        this.csvData.forEach((user) => {
          delete user._id;
          delete user.filledPdf;
        });
        this.userData.forEach((user) => {
          user.role =
            user.role === "hra"
              ? user.role.toUpperCase()
              : user.role.charAt(0).toUpperCase() + user.role.slice(1);
        });

        this.load = false;
      });
    },
    validateNewUser() {
      if (this.$refs.newUserForm.validate()) {
        this.saveNewUser();
      }
    },
    async saveNewUser() {
      const data = {
        firstname: this.newUser.firstName,
        lastname: this.newUser.lastName,
        userID: this.newUser.agentNpn,
        role: this.newUser.role.toLowerCase(),
        email: this.newUser.email.toLowerCase(),
        password: this.newUser.password,
        clinicaltype: this.newUser.clinicalType,
        fmoNpn: this.newUser.fmo.NPN ? this.newUser.fmo.NPN : "",
      };

      let emailCheck = await axios.get(`api/users/emailcheck/${data.email}`);

      if (emailCheck.data) {
        this.emailInUse = true;
      } else {
        await axios.post("/api/users/create", data).catch((err) => {
          console.log(err);
          console.log("Couldn't register user.");
        });

        this.getAllUsers();
        // clear out new user dialog after saving
        this.cancelNewUser();
      }
    },
    cancelNewUser() {
      // close dialog
      this.newUserDialog = false;
      // reset all form fields
      this.newUser = {
        firstName: "",
        lastName: "",
        agentNpn: "",
        role: "",
        email: "",
        password: "",
        clinicalType: "",
        fmo: {
          NPN: "",
        },
      };
      // reset form validation
      this.$refs.newUserForm.reset();
    },
    openEditDialog(item) {
      this.editedIndex = this.userData.indexOf(item);
      this.editedUser = Object.assign({}, item);
      // this.fmoList = [{ NPN: item.fmoNpn }];
      this.getFmoList()
      this.editedUser.fmo = {
        NPN: item.fmoNpn,
      };
      this.editUserDialog = true;
    },
    validateEdits() {
      if (this.$refs.editUserForm.validate()) {
        this.saveEdits();
      }
    },
    async saveEdits() {
      this.editedUser.fmoNpn = this.editedUser.fmo.NPN;

      await axios
        .post("/api/users/update", this.editedUser)
        .then((res) => {
          this.userData.splice(this.editedIndex, 1, res.data);
          this.getAllUsers()
          this.closeEditDialog();
        })
        .catch((err) => {
          console.log(err);
          console.log("Couldn't update user.");
        });
    },
    closeEditDialog() {
      this.editUserDialog = false;

      this.editedUser = {};
    },
    openPasswordDialog(item) {
      this.userToResetPassword = item;
      this.passwordResetDialog = true;
    },
    async resetPassword() {
      this.userToResetPassword.firstLogin = true;

      await axios.post("/api/user/resetpass", this.userToResetPassword);

      this.passwordResetDialog = false;
      this.userToResetPassword = {};
    },
    getFmoList() {
      axios
        .get(`/api/search/fmo/`)
        .then((response) => {
          this.fmoList = response.data;
        });
    },
  },
  mounted() {
    this.getUser();
    this.getFmoList()
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}

.v-card__title {
  word-break: break-word !important;
}
</style>
