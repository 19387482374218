import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#60777F", // sidebar background, headers and main info
        secondary: "#FFFFFF", // sidebar highlighted selection
        accent: "#60777F", // buttons and other accents
        info: "#4F7CAC",
        background: "#fafafa",
        error: "#D32F2F",
        success: "#4FAC7F",
      },
      //this is here incase someones darkmode is enabled in local storage
      dark: {
        primary: "#14A7B8", // sidebar background, headers and main info
        secondary: "#FFFFFF", // sidebar highlighted selection
        accent: "#F86972", // buttons and other accents
        info: "#4F7CAC",
        background: "#fafafa",
        error: "#D32F2F",
        success: "#4FAC7F",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  font: "Poppins",
});
