<template>
  <v-card class="pa-2 mx-3 transparent-card" outlined flat>
    <v-row>
      <v-card-title class="text-h4">Authorization Notes</v-card-title>
      <v-spacer></v-spacer>
      <v-btn class="ma-3" color="success" @click="dialog = !dialog">
        Create New Note
      </v-btn>

      <v-col cols="12">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(note, index) in notes" :key="index">
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no gutters>
                <v-col cols="6">
                  {{ formatDate(note.Date) }}
                </v-col>
                <v-col cols="4" v-if="!open">
                  <p class="grey--text">
                    {{ note.Note.slice(0, 60) }}
                    <span v-if="note.Note.length > 60">...</span>
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>{{ note.Note }}</p>
              <p v-if="note.Author" class="grey--text"
                >Author: {{ note.Author }}</p
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="450px" persistent>
      <v-card>
        <v-toolbar dark flat color="primary">
          <v-card-title color="primary">Add New Note</v-card-title>
        </v-toolbar>

        <v-form ref="newNoteForm" v-model="newNoteValid" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="newNoteText"
                  placeholder="Please enter your note here...."
                  :counter="10000"
                  :rules="[
                    newNoteText != '' || 'Note contents must not be empty',
                    (v) => v.length <= 10000 || 'Max 10000 characters',
                  ]"
                  required
                  outlined
                  auto-grow
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newNoteAuthor"
                  label="Author"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12">
                Is this a clinical note?
                <v-radio-group
                  v-model="newNoteClinicalStatus"
                  required
                  :rules="[
                    newNoteClinicalStatus != '' || 'Please make a selection',
                  ]"
                  row
                >
                  <v-radio value="true" label="Yes" />
                  <v-radio value="false" label="No" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-btn color="accent" text @click="close()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveChangesVerify()">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="saveDialog" max-width="350px">
      <v-card>
        <v-toolbar dark dense flat color="primary" class="mx-auto mb-3">
          <v-card-title color="primary">Are you sure?</v-card-title>
        </v-toolbar>

        <v-card-text class="heading">
          Once created, notes cannot be edited. Please be sure that all
          information is correct before saving.
        </v-card-text>

        <v-card-actions>
          <v-btn color="accent" text @click="saveDialog = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="createNote()">
            Yes, Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  name: "AuthNotes",
  props: ["authID", "user"],
  data() {
    return {
      dialog: false,
      notes: [],
      newNoteValid: false,
      newNoteText: "",
      newNoteAuthor: "",
      newNoteClinicalStatus: "",
      newNoteDate: moment(),
      saveDialog: false,
    }
  },
  computed: {
    clinicalAccess() {
      if (
        this.user.role === "hra" &&
        (this.user.clinicaltype === "" || this.user.clinicaltype === null)
      ) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
    this.getNotes()
  },
  methods: {
    async getNotes() {
      await axios.get(`api/authorizations/notes/${this.authID}`).then((res) => {
        if (!this.clinicalAccess) {
          let filteredNotes = res.data.filter((note) => note.Clinical === false)
          this.notes = filteredNotes
        } else {
          this.notes = res.data
        }
      })
    },
    async createNote() {
      let newNote = {
        AuthID: this.authID,
        Clinical: this.newNoteClinicalStatus,
        Note: this.newNoteText,
        Date: this.newNoteDate,
        Author: this.newNoteAuthor,
      }

      axios.post(`api/authorizations/notes/new`, newNote).then(this.getNotes())

      this.close()
    },
    saveChangesVerify() {
      if (this.$refs.newNoteForm.validate()) {
        this.saveDialog = true
      }
    },
    close() {
      this.newNoteText = ""
      this.newNoteAuthor = ""
      this.newNoteClinicalStatus = ""
      this.dialog = false
      this.saveDialog = false
    },
    formatDate(date) {
      //added if check to remove console error
      if (date) {
        return date.slice(0, 10)
      }
    },
  },
}
</script>
