<template>
    <v-dialog v-model="addCPTDialog" max-width="900px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" class="mb-5"><v-icon>mdi-plus</v-icon>Add CPT Code</v-btn>
        </template>
        <v-card>
            <v-card-title>Add CPT</v-card-title>
            <v-form ref="cptForm" lazy-validation class="pa-3">
                <v-switch v-model="codeIsRange" :label="codeIsRange ? 'Range Input' : 'Single Number Input'" @click="switchCodeInput()"></v-switch>
                <div v-if="codeIsRange">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-text-field
                                v-model="cptMin"
                                label="Code Minimum Number"
                                dense
                                outlined
                                required
                                type="Number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                                v-model="cptMax"
                                label="Code Maximum Number"
                                dense
                                outlined
                                required
                                type="Number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-text-field
                                v-model="cptCode"
                                label="Code Code"
                                dense
                                outlined
                                required
                                type="Number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <v-row>
                    <v-col cols="12" lg="12">
                        <v-text-field
                            v-model="description"
                            label="Description"
                            dense
                            outlined
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-checkbox
                            v-model="authRequired"
                            label="Authorization Required"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-checkbox
                            v-model="referralRequired"
                            label="Referral Required"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="maxBenefitAmount"
                            label="Max Benefit Amount"
                            dense
                            outlined
                            type="Number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="copay"
                            label="Copay"
                            dense
                            outlined
                            type="Number"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-card-title>Days</v-card-title>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="daysAllotmentStart"
                            label="Start"
                            dense
                            outlined
                            type="Number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="daysAllotmentEnd"
                            label="End"
                            dense
                            outlined
                            type="Number"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="addCPTDialog = false" text>Cancel</v-btn>
              <v-btn
                color="accent"
                text
                @click="addCPTCode()"
              >
                Add
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            addCPTDialog: false,
            codeIsRange: true,
            cptMin: null,
            cptMax: null,
            cptCode: null,
            authRequired: false,
            referralRequired: false,
            daysAllotmentStart: null,
            daysAllotmentEnd: null,
            maxBenefitAmount: null,
            description: null,
            copay: null,
        }
    },
    methods: {
        addCPTCode() {
            let enteredMax = null
            let enteredMin = null
            if (this.cptCode) {
                enteredMax = this.cptCode
                enteredMin = this.cptCode
            } else {
                enteredMax = this.cptMax
                enteredMin = this.cptMin
            }
            let newCode = {
                desc: this.description,
                code: {
                    max: enteredMax,
                    min: enteredMin
                },
                refRequired: this.referralRequired,
                authRequired: this.authRequired,
                days: {
                    start: this.daysAllotmentStart,
                    end: this.daysAllotmentEnd
                },
                maxBenefit: this.maxBenefitAmount,
                copay: this.copay,
            }
            this.$emit("newCode", newCode)
            this.addCPTDialog = false
        },
        switchCodeInput() {
            this.cptCode = null
            this.cptMax = null
            this.cptMin = null
            this.authRequired = false
            this.referralRequired = false
            this.daysAllotmentStart = null
            this.daysAllotmentEnd = null
            this.maxBenefitAmount = null
            this.description = null
            this.copay = null
        },
    },
    watch: {
        addCPTDialog() {
            this.cptCode = null
            this.cptMax = null
            this.cptMin = null
            this.authRequired = false
            this.referralRequired = false
            this.daysAllotmentStart = null
            this.daysAllotmentEnd = null
            this.maxBenefitAmount = null
            this.description = null
            this.copay = null
        }
    }
}
</script>

<style scoped>

</style>