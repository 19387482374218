<template>
  <v-container>
    <v-card class="pa-2 mx-3 transparent-card" outlined>
      <v-card-title>
        Notifications
        <v-spacer></v-spacer>
        <new-notification
          @notificationCreated="notificationSuccess"
          view="authorization"
          :auth="auth"
        />
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="9">
            <v-text-field
              class="py-0"
              v-model="search"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <div class="d-flex justify-end">
              <v-switch inset v-model="scheduled">
                <template slot="prepend">
                  <p class="pt-1 mr-2">
                    {{ scheduled ? "Scheduled" : "Sent" }}
                  </p>
                </template>
              </v-switch>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="load"
              :headers="getHeaders"
              :items="scheduled ? scheduledData : sentData"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt.slice(0, 10) }}
              </template>

              <template v-slot:item.sent="{ item }">
                <v-icon v-if="item.sent" color="success">mdi-check</v-icon>
                <v-icon v-if="!item.sent" color="error">mdi-close</v-icon>
              </template>

              <template v-slot:item.ACTIONS="{ item }">
                <v-icon @click="openEditDialog(item)" class="mr-2" small>
                  mdi-pencil
                </v-icon>

                <v-icon @click="deleteNotification(item)" small>
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Edit Notification</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12" sm="6">
                <v-menu transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Date"
                      v-model="editDate"
                      required
                      :rules="[
                        editDate != '' || 'Notification Date is required',
                      ]"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editDate"
                    :min="new Date().toISOString().slice(0, 10)"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <time-selector v-model="editTime" view="notificationModal" />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  class="py-0"
                  v-model="editMessage"
                  label="Message"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn class="ma-3" @click="editDialog = false" text color="accent">
              Cancel
            </v-btn>
            <v-btn
              class="ma-3"
              @click="saveNotificationEdit()"
              text
              color="success"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteNotificationDialog" persistent max-width="600px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Are you sure you want to delete this notification?</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="deleteNotificationDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="mt-3"> 
            The selected notification will be deleted and no email notification will be sent.
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="deleteSelectedNotification()" text color="Error">Delete</v-btn>
              <v-btn @click="deleteNotificationDialog = false" text color="primary">Cancel</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="notificationSuccessMessage"
        color="success"
        :timeout="2000"
      >
        Changes have successfully been saved
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import TimeSelector from "../TimeSelector.vue";
import NewNotification from "../NewNotification.vue";

export default {
  props: ["auth"],
  components: { TimeSelector, NewNotification },
  data() {
    return {
      load: false,
      headers: [
        { text: "Sent", value: "sent" },
        { text: "Created", value: "createdAt" },
        { text: "Type", value: "type" },
        { text: "Message", value: "message" },
        { text: "Delivery Date", value: "deliveryDate" },
        { text: "Delivery Time", value: "deliveryTime" },
        { text: "Assignee", value: "assignee.fullName" },
        { text: "Actions", value: "ACTIONS" },
      ],
      items: [],
      search: "",
      scheduledData: [],
      sentData: [],
      scheduled: true,
      editDialog: false,
      notificationToEdit: "",
      editDate: "",
      editTime: "",
      editMessage: "",
      editMessageText: "",
      messageList: ["New Assignment", "Upcoming Expiration"],
      deleteNotificationDialog: false,
      notificationToDelete: "",
      notificationSuccessMessage: false,
    };
  },
  computed: {
    getHeaders() {
      if(this.scheduled) {
        return[
          { text: "Sent", value: "sent" },
          { text: "Created", value: "createdAt" },
          { text: "Type", value: "type" },
          { text: "Message", value: "message" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Delivery Time", value: "deliveryTime" },
          { text: "Assignee", value: "assignee.fullName" },
          { text: "Actions", value: "ACTIONS" },
        ]
      } else {
        return [
          { text: "Sent", value: "sent" },
          { text: "Created", value: "createdAt" },
          { text: "Type", value: "type" },
          { text: "Message", value: "message" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Delivery Time", value: "deliveryTime" },
          { text: "Assignee", value: "assignee.fullName" },
        ]
      }
    }
  },
  methods: {
    getReportData() {
      this.load = true;

      axios.get(`/api/notifications/auth/${this.auth.AuthID}`).then((response) => {
        this.scheduledData = [];
        this.sentData = [];

        response.data.forEach((notification) => {
          if (notification.sent === true) {
            this.sentData.push(notification);
          } else {
            this.scheduledData.push(notification);
          }
        });

        this.load = false;
      });
    },
    openEditDialog(item) {
      this.editDialog = true;
      this.notificationToEdit = item.notificationReference;
      this.editDate = item.deliveryDate;
      this.editTime = item.deliveryTime;
      this.editMessage = item.message;
    },
    saveNotificationEdit() {
      let notificationEdits = {
        notificationReference: this.notificationToEdit,
        deliveryDate: this.editDate,
        deliveryTime: this.editTime,
        message: this.editMessage,
      }

      axios
        .post(`/api/notifications/update`, notificationEdits)
        .then(() => {
          this.editDialog = false;
          this.getReportData();
        });
    },
    deleteNotification(notification) {
      this.notificationToDelete = notification
      this.deleteNotificationDialog = true
    },
    deleteSelectedNotification() {
      axios 
        .post(`/api/notifications/delete/${this.notificationToDelete.notificationReference}`)
        .then(() => {
          if(this.scheduled) {
            let deletedItem = this.scheduledData.indexOf(this.notificationToDelete);
            
            this.scheduledData.splice(deletedItem, 1);
            this.$nextTick(() => {
              this.deletedItem = -1
            })
          } else {
            let deletedItem = this.sentData.indexOf(this.notificationToDelete);
            
            this.sentData.splice(deletedItem, 1);
            this.$nextTick(() => {
              this.deletedItem = -1
            })
          }
          this.deleteNotificationDialog = false;
          this.notificationSuccessMessage = true;
        })
    },
    notificationSuccess(item) {
      this.scheduledData.push(item);
      this.notificationSuccessMessage = true;
    },
  },
  mounted() {
    this.getReportData();
  },
};
</script>

<style scoped>
</style>