<template>
  <v-dialog v-model="serviceDetailsDialog" persistent max-width="750px">
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        color="success"
        block
      >
        <v-icon>mdi-pencil-plus</v-icon>
        Add Service
      </v-btn>
    </template>

    <v-card class="pa-3">
      <v-card-title class="pl-0">Add Service Details Item</v-card-title>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              label="Diagnosis Code"
              v-model="diagnosisCode"
              :items="diagnosisItems"
              :loading="diagnosisLoading"
              :search-input.sync="diagnosisSearch"
              item-text="CODE"
              clearable
              required
              :rules="[diagnosisCode != '' || 'Diagnosis Code is required']"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for a diagnosis code
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.CODE"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.DESCRIPTION"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              label="CPT4/HCPCS Code"
              v-model="serviceCode"
              :items="serviceCodeItems"
              :loading="serviceCodeLoading"
              :search-input.sync="serviceCodeSearch"
              item-text="CODE"
              return-object
              clearable
              required
              :rules="[serviceCode != '' || 'CPT4/HCPCS Code is required']"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for a CPT4/HCPCS code
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.CODE"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.DESCRIPTION"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Service Description"
              v-model="serviceDescription"
              readonly
              :disabled="!serviceCode"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Number of Visits"
              v-model="numOfVisits"
              type="number"
              min="0"
              required
              :rules="[(v) => !!v || 'Number of Visits is required']"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Number of Units"
              v-model="numOfUnits"
              type="number"
              min="0"
              required
              :rules="[(v) => !!v || 'Number of Units is required']"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="cancelDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="validate"
          color="success"
          text
        >
          Save Selected
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "memSearchDialog",
  props: ["resetService"],
  components: {
  },
  data() {
    return {
      serviceDetailsDialog: false,
      diagnosisCode: '',
      diagnosisItems: [],
      diagnosisLoading: false,
      diagnosisSearch: null,
      serviceCode: '',
      serviceCodeItems: [],
      serviceCodeLoading: false,
      serviceCodeSearch: null,
      serviceDescription: '',
      numOfVisits: 0,
      numOfUnits: 0,
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.saveNewService();
      }
    },
    saveNewService() {
      // create and emit object to fill parent data table
      let newService = {
        DIAG: this.diagnosisCode,
        CPT_HCPCS: this.serviceCode.CODE,
        ServiceDesc: this.serviceDescription,
        Visits: this.numOfVisits,
        Units: this.numOfUnits
      }
      this.$emit('newServiceDetail', newService)
      this.diagnosisCode = '';
      this.serviceCode = '';
      this.serviceDescription = '';
      this.numOfVisits = 0;
      this.numOfUnits = 0;
      this.serviceDetailsDialog = false;
    },
    cancelDialog() {
      this.diagnosisCode = '';
      this.serviceCode = '';
      this.serviceDescription = '';
      this.numOfVisits = 0;
      this.numOfUnits = 0;
      this.serviceDetailsDialog = false;
    }
  },
  computed: {
  },
  watch: {
    diagnosisSearch() {
      this.diagnosisLoading = true;

      // Lazily load input items
      axios
        .get(`/api/search/codes/diagnosis/${this.diagnosisSearch}`)
        .then(response => {
          this.diagnosisItems = response.data;
          this.diagnosisLoading = false;
        })
    },
    serviceCodeSearch() {
      this.serviceCodeLoading = true;

      // Lazily load input items
      axios
        .get(`/api/search/codes/service/${this.serviceCodeSearch}`)
        .then(response => {
          this.serviceCodeItems = response.data;
          this.serviceCodeLoading = false;
        })
    },
    serviceCode() {
      this.serviceCode 
        ? this.serviceDescription = this.serviceCode.DESCRIPTION
        : this.serviceDescription = '';
    },
    resetService(){
      if(this.resetService){
        this.diagnosisCode = '';
        this.diagnosisItems = [];
        this.diagnosisLoading = false;
        this.diagnosisSearch = null;
        this.serviceCode = '';
        this.serviceCodeItems = [];
        this.serviceCodeLoading = false;
        this.serviceCodeSearch = null;
        this.serviceDescription = '';
        this.numOfVisits = 0;
        this.numOfUnits = 0;
      }
    }
  }
};
</script>

<style scoped>

</style>
