<template>    
    <v-dialog v-model="servicesModal" max-width="900px">
        <template v-slot:activator="{ on }">
            <v-chip v-on="on">{{services.length}}</v-chip>
        </template>
        <v-card>
            <v-toolbar dark color="primary" class="mx-auto">
                <v-card-title color="primary">Services</v-card-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="servicesModal = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar> 
            <v-data-table
            :items="services"
            :headers="servicesHeaders"
            ></v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
  name: "AuthServicesModal",
  props: ['services'],
  data() {
    return {
      servicesHeaders: [
        {text: "CPT/HCPCS", value: "CPT_HCPCS"},
        {text: "Diagnosis", value: "DIAG"},
        {text: "Description", value: "ServiceDesc"},
        {text: "Units", value: "Units"},
        {text: "Visits", value: "Visits"},
      ],
      servicesModal: false,
    };
  },
  methods: {
    
  },
  watch: {
    
  },
};
</script>

<style scoped>

</style>