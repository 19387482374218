<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="!formComplete" flat>
          <v-card-title> New HRA Enrollment </v-card-title>
          <v-tabs
            v-model="tab"
            background-color="primary"
            class="rounded-b-0"
            dark
          >
            <v-tab v-for="item in items" :key="item" disabled>{{ item }}</v-tab>
            <v-tab-item style="border-radius-bottom: 20px !important">
              <v-card flat>
                <demographic
                  @unsavedChanges="setUnsavedChanges"
                  @data="saveTabData"
                  @selectedTab="selectedTab"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <general-health
                  @data="saveTabData"
                  @selectedTab="selectedTab"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <medicalHistory
                  @data="saveTabData"
                  @selectedTab="selectedTab"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <activitiesOfDailyLiving
                  @data="saveTabData"
                  @selectedTab="selectedTab"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <medication @data="saveTabData" @selectedTab="selectedTab" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <preventiveServicesInformation
                  @data="saveTabData"
                  @selectedTab="selectedTab"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <lifestyle @data="saveTabData" @selectedTab="selectedTab" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <emotionalHealthInformation
                  @data="saveTabData"
                  @selectedTab="selectedTab"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <formCompletionInformation
                  @data="saveTabData"
                  @formCompleted="formCompleted"
                />
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>

        <v-card v-else class="pa-5 text-center" flat>
          <v-icon color="success" class="display-2"> mdi-check </v-icon>
          <v-card-text class="headline">
            Form submitted successfully!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackError" :timeout="3000" color="error">
      Error saving new record!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import Demographic from "../components/hra-components/Demographic";
import GeneralHealth from "../components/hra-components/GeneralHealth";
import ActivitiesOfDailyLiving from "../components/hra-components/ActivitiesOfDailyLiving";
import Medication from "../components/hra-components/Medication";
import PreventiveServicesInformation from "../components/hra-components/PreventiveServicesInformation";
import Lifestyle from "../components/hra-components/Lifestyle";
import EmotionalHealthInformation from "../components/hra-components/EmotionalHealthInformation";
import FormCompletionInformation from "../components/hra-components/FormCompletionInformation";
import MedicalHistory from "../components/hra-components/MedicalHistory";

export default {
  name: "Enrollments",
  components: {
    Demographic,
    GeneralHealth,
    MedicalHistory,
    ActivitiesOfDailyLiving,
    Medication,
    PreventiveServicesInformation,
    Lifestyle,
    EmotionalHealthInformation,
    FormCompletionInformation,
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      tab: 0,
      items: [
        "Demographic",
        "General Health",
        "Medical History & Treatment",
        "Activities of Daily Living",
        "Medication",
        "Preventive Services",
        "Lifestyle",
        "Emotional Health",
        "Form Completion",
      ],
      snackError: false,
      formComplete: false,
      unsavedChangesDemographic: false,
      enrollmentData: {},
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (
            this.user.role === "agent" ||
            this.user.role === "fmo"
          ) {
            this.$router.push("/");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else {
            this.enrollmentData.userID = this.user.userID;
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    updateRecord() {
      axios
        .post(`/api/hra/edit/${this.enrollmentData._id}`, this.enrollmentData)
        .then(() => {
          console.log("Record Updated");
        })
        .catch((error) => {
          console.log(error);
          // display snackbar notification
          this.snackError = true;
        });
    },
    saveRecord() {
      axios
        .post("/api/hra/new", this.enrollmentData)
        .then((response) => {
          // if created a new record - update record ID
          if (response.status == 201) {
            this.enrollmentData._id = response.data.data._id;
          }
        })
        .catch((error) => {
          console.log(error);
          // display snackbar notification
          this.snackError = true;
        });
    },
    setUnsavedChanges(value) {
      this.unsavedChangesDemographic = value;
    },
    async selectedTab(index) {
      this.tab = index;
    },
    async saveTabData(data) {
      for (const property in data) {
        this.enrollmentData[property] = data[property];
      }
      //create new record after completing demographic section then just update the record for every tab after that
      if (this.tab === 0) {
        this.saveRecord();
      } else {
        this.updateRecord();
      }
    },
    formCompleted() {
      this.updateRecord();
      this.formComplete = true;
    },
  },
  computed: {
    unsavedChanges() {
      if ((this.tab > 0 && this.tab < 8) || this.unsavedChangesDemographic) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm(
        "Are you sure you want to leave the page? All unsaved changes will be lost!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
};
</script>

<style scoped>
.v-tab--disabled {
  color: white !important;
  opacity: 1 !important;
}
</style>