<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="success">New Care Plan</v-btn>
    </template>

    <v-card tile id="capture" flat color="#fafafa">
      <v-toolbar dark color="primary">
        <v-toolbar-title> Create New Care Plan </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closeDialog = true">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card outlined flat class="ma-2 pa-4">
        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-card v-if="selectedMember !== ''" flat transparent>
              <v-card-title class="pt-0">
                Member Name:
                {{ selectedMember.name }}
              </v-card-title>

              <v-card-subtitle>
                <div>
                  Medicare Number: {{ selectedMember.medicareNumber }}
                </div>
                <div>Sonder ID: {{ selectedMember.memberID }}</div>
              </v-card-subtitle>
            </v-card>

            <v-card v-if="!selectedMember" flat transparent>
              <v-card-title class="pt-0"> Member Name: </v-card-title>

              <v-card-subtitle>
                <div>Medicare Number:</div>
                <div>Sonder ID:</div>
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <member-search-dialog
              @memberSelected="saveSelectedMember"
            ></member-search-dialog>
            <div v-if="!selectedMember" class="text-caption accent--text">
              (Please select a member)
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-card outlined flat class="ma-2" min-height="500px">
        <v-card-title>
          Care Plan Details
          <v-spacer></v-spacer>
          <new-diagnosis-dialog
            resetService="resetService"
            @newPlanItem="addPlanItem"
          ></new-diagnosis-dialog>
        </v-card-title>

        <v-card-text>
          <div v-if="planItems.length === 0">
            Please enter at least one diagnosis
          </div>
          <v-card class="mb-3" v-for="item in planItems" :key="item.problem">
            <v-card-text>
              <v-list outlined class="mb-2">
                <v-row>
                  <v-col cols="6">
                    <v-list-item class="font-weight-bold" dense>Problem</v-list-item>
                    <v-subheader class="text-h6">
                      {{ item.problem }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item class="font-weight-bold" dense>Diagnosis</v-list-item>
                    <v-subheader class="text-h6">
                      {{ item.diagnosis }}
                    </v-subheader>
                  </v-col>
                </v-row>
              </v-list>

              <v-row>
                <v-col cols="12">
                  <v-list outlined height="100%">
                    <v-list-item class="font-weight-bold" dense>Goals</v-list-item>
                    <v-list-item dense v-for="goal in item.goals" :key="goal.identifier">
                      {{ goal.identifier }}. {{ goal.goal }}
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pb-3">
              <v-spacer></v-spacer>
              <v-btn
                @click="openEditDialog(item)"
                class="mx-2"
                color="primary"
                x-small
                dark
                fab
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                @click="deleteCode(item)"
                class="mx-2"
                color="primary"
                x-small
                dark
                fab
              >
                <v-icon dark> 
                  mdi-delete 
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>

      <v-row class="pa-2">
        <v-col cols="12" class="text-right">
          <v-btn color="accent" @click="validateCareplan">
            <v-icon class="mr-2">mdi-folder-plus-outline</v-icon>
            Generate Care Plan
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="editDialog" max-width="750px" persistent>
        <v-card>
          <v-toolbar dark flat color="primary">
            <v-card-title color="primary">Edit Plan Item</v-card-title>
          </v-toolbar>

          <v-form ref="newNoteForm" v-model="editValid" lazy-validation>
            <v-card-text>
              <v-row>
                <v-card-text class="pb-0">
                  Problem:
                </v-card-text>
                <v-col cols="12">
                  <v-text-field
                    label="Enter a Problem"
                    v-model="itemToEdit.problem"
                    required
                    :rules="[
                      itemToEdit.problem != '' ||
                        'Please make an entry',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-card-text class="py-0">
                  Diagnosis: 
                </v-card-text>
                <v-col cols="12">
                  <v-text-field
                    label="Enter a Diagnosis"
                    v-model="itemToEdit.diagnosis"
                    required
                    :rules="[
                      itemToEdit.problem != '' ||
                        'Please make an entry',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-card-text class="py-0">
                  Goals:
                </v-card-text>
                <v-col cols="12" md="6" v-for="(item, index) in itemToEdit.goals" :key="index">
                  <v-text-field
                    label="Please enter a goal"
                    v-model="item.goal"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-btn
                    class="mx-2"
                    @click="removeGoal"
                    fab
                    dark
                    x-small
                    color="grey"
                  >
                    <v-icon dark>
                      mdi-minus
                    </v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-2"
                    @click="addGoal"
                    fab
                    dark
                    x-small
                    color="primary"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>

          <v-card-actions>
            <v-btn color="accent" text @click="closeEditDialog()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="saveEdits">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="text-h5"> Confirmation </v-card-title>
          <v-card-text>
            Are you sure you want to generate a new care plan? Please confirm
            that all the information is accurate before saving.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="confirmDialog = false">
              Cancel
            </v-btn>
            <v-btn color="success" text @click="generateCarePlan"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="closeDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to exit?
          </v-card-title>
          <v-card-text> Any unsaved progress will be lost... </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="ma-3" @click="closeDialog = false" text color="accent"
              >Cancel</v-btn
            >
            <v-btn class="ma-3" @click="dialog = false" text color="success"
              >Yes, Exit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="missingInfo"
        color="error"
        :timeout="5000"
      >
        In order to generate a careplan, a member must be selected and at least one diagnosis must be added.
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      
      <v-snackbar
        v-model="saveError"
        color="error"
        :timeout="5000"
      >
        There was an error saving your new care plan, please check information and try again.
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import MemberSearchDialog from "./auth-components/MemberSearchDialog";
import NewDiagnosisDialog from "./careplan-components/NewDiagnosisDialog";

export default {
  name: "NewCareplan",
  props: ["user"],
  components: {
    MemberSearchDialog,
    NewDiagnosisDialog,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selectedMember: "",
      search: "",
      headers: [
        { text: "Problem", value: "problem" },
        { text: "Diagnosis", value: "diagnosis" },
        { text: "Goals", value: "goals" },
        { text: "Actions", value: "ACTIONS" },
      ],
      planItems: [],
      goalsDialog: false,
      confirmDialog: false,
      closeDialog: false,
      missingInfo: false,
      saveError: false,
      editValid: false,
      editDialog: false,
      editedIndex: -1,
      itemToEdit: {},
    };
  },
  mounted() {},
  methods: {
    saveSelectedMember(value) {
      this.selectedMember = value;
    },
    validateCareplan() {
      if (this.selectedMember !== "" && this.planItems.length > 0) {
        this.confirmDialog = true;
      } else {
        this.missingInfo = true;
      }
    },
    async generateCarePlan() {
      let careplan = {
        memberName: this.selectedMember.name,
        memberID: this.selectedMember.memberID,
        planDetails: this.planItems
      }

      let newCareplan = await axios.post('/api/careplans/new', careplan);

      if (newCareplan.data !== 'existingCareplan') {
        this.$emit('newCareplan', newCareplan.data);
        this.dialog = false;
        this.planItems = [];
        this.selectedMember = "";
        this.confirmDialog = false;
      } else {
        this.saveError = true;
        this.confirmDialog = false;
      }
    },
    addPlanItem(item) {
      this.planItems.push(item);
    },
    openEditDialog(item) {
      this.editedIndex = this.planItems.indexOf(item);
      // assign fields to edit so we don't overwrite existing data
      this.itemToEdit = Object.assign({}, item);

      // assign nested arrays/objects
      let editableGoals = [];
      item.goals.forEach(goal => {
        editableGoals.push(Object.assign({}, goal))
      })      
      this.itemToEdit.goals = editableGoals;

      this.editDialog = true;
    },
    addGoal() {
      this.itemToEdit.goals.push({
        identifier: this.itemToEdit.goals.length + 1,
        goal: ''
      });
    },
    removeGoal() {
      if(this.itemToEdit.goals.length > 1) {
        this.itemToEdit.goals.pop();
      }
    },
    async saveEdits() {
      let newPlanInfo = [...this.planItems];
      newPlanInfo[this.editedIndex] = this.itemToEdit;

      this.planItems = newPlanInfo;

      this.closeEditDialog();
    },
    closeEditDialog() {
      this.itemToEdit = {};
      this.editDialog = false;
    },
    deleteCode(item) {
      let deletedItem = this.planItems.indexOf(item);
      this.planItems.splice(deletedItem, 1);
    },
  },
  computed: {},
  watch: {
    assignmentSearch() {
      // Lazily load input items
      if (this.assignmentSearch !== null) {
        this.assignmentLoading = true;
        axios
          .get(
            `/api/search/users/${this.assignmentType}/${this.assignmentSearch}`
          )
          .then((response) => {
            this.assignmentList = response.data;
            this.assignmentLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.switch-label {
  min-width: 100px;
  text-align: right;
}
</style>