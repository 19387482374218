<template>
  <div>
    <v-container fluid>
      <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
        <v-row>
          <v-col>
            <span class="font-weight-black justify-start">
              Paying your plan premium
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            If you have selected a plan with zero monthly premium and we
            determine that you owe late enrollment penalty (or if you currently
            have a late enrollment penalty), we need to know how you would
            prefer to pay it. You can pay by mail or Electronic Funds Transfer
            (EFT) each month. You can also choose to pay your premium by
            automatic deduction from your Social Security or Railroad Retirement
            Board (RRB) benefit check each month.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            If you have selected a plan with a monthly premium, you can pay this
            premium (including any late enrollment penalty that you currently
            have or may owe) by mail or Electronic Funds Transfer (EFT) each
            month. You can also choose to pay your premium by automatic
            deduction from your Social Security or Railroad Retirement Board
            (RRB) benefit check each month.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            If you are assessed a Part D-Income Related Monthly Adjustment
            Amount (IRMAA), you will be notified by the Social Security
            Administration. You will be responsible for paying this extra amount
            in addition to your plan premium. You will either have the amount
            withheld from your Social Security benefit check or be billed
            directly by Medicare or the Railroad Retirement Board (RRB). DO NOT
            pay Sonder Health plans the part D-IRMAA.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            People with limited incomes may qualify for Extra Help to pay for
            their prescription drug costs. If eligible, Medicare could pay for
            75% or more of your drug costs including monthly prescription drug
            premiums, annual deductibles, and coinsurance. Additionally, those
            who qualify will not be subject to the coverage gap or a late
            enrollment penalty. Many people are eligible for these savings and
            don’t even know it. For more information about this Extra Help,
            contact your local Social Security office or call social security at
            1-800-772-1213. TTY users should call 1-800-325-0778. You can also
            apply for extra help online at
            www.socialsecurity.gov/prescriptionhelp.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            If you qualify for Extra Help with your Medicare prescription drug
            coverage costs, Medicare will pay all or part of your plan premium.
            If Medicare pays only a portion of this premium, we will bill you
            for the amount that Medicare doesn’t cover.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            If you don’t select a premium payment option, you will get a bill
            each month.
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col>
            <span class="font-weight-black justify-start">
              Please select a premium payment option
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-checkbox
              class="mt-0"
              v-model="bill"
              label="Get a bill"
              dense
              required
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-checkbox
              class="mt-0"
              v-model="deduction"
              label="Automatic deduction from your monthly Social Security or RBB benefit check."
              type="number"
              dense
              required
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="deduction === true">
          <v-col>
            <v-card outlined>
              <v-card-title class="subtitle-2"
                >I get monthly deductions from:</v-card-title
              >
              <v-card-text>
                <v-checkbox
                  class="mt-0"
                  label="Social Security"
                  v-model="socialSecurity"
                  dense
                ></v-checkbox>
                <v-checkbox
                  class="mt-0"
                  label="RRB"
                  v-model="rrb"
                  value="value"
                  dense
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-checkbox
              class="mt-0"
              label="Electrionic Funds Transfer (EFT) from your bank account each month"
              v-model="eft"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="eft === true">
          <v-col>
            <v-card outlined>
              <v-card-title class="subtitle-2"
                >I hereby authorize Sonder to initiate debit/credit entries to
                my checking/savings account for payment of
                premium.</v-card-title
              >
              <v-card-text>
                <v-checkbox
                  class="mt-0"
                  label="Checking"
                  v-model="checking"
                  value="value"
                  dense
                ></v-checkbox>

                <v-checkbox
                  class="mt-0"
                  label="Savings"
                  v-model="savings"
                  value="value"
                  dense
                ></v-checkbox>

                <div v-if="checking || savings">
                  <v-col cols="12" lg="4">
                    <v-text-field
                      label="Account Holder Name"
                      v-model="acctHolderName"
                      class="pt-0 mt-0"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </div>
                <div v-if="checking || savings">
                  <v-col cols="12" lg="4">
                    <v-text-field
                      label="Depository Bank Name"
                      v-model="depositoryBankName"
                      class="pt-0 mt-0"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </div>
                <div v-if="checking || savings">
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="pt-0 mt-0"
                      outlined
                      dense
                      required
                      type="number"
                      label="Bank Routing Number"
                      v-model="bankRouting"
                    ></v-text-field>
                  </v-col>
                </div>
                <div v-if="checking || savings">
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="pt-0 mt-0"
                      outlined
                      dense
                      required
                      type="number"
                      label="Bank Account Number"
                      v-model="bankAcctNumber"
                    ></v-text-field>
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <v-btn text :disabled="!valid" color="primary" @click="previous()">
                <v-icon small> mdi-chevron-left </v-icon>
                Previous
            </v-btn>
          </v-col> 
          <v-spacer></v-spacer>
          <v-col class="text-right pb-0">
            <v-btn
              text
              :disabled="!valid"
              color="primary"
              @click="validate"
            >
              Next
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["recordID"],
  data: () => ({
    valid: true,
    bill: false,
    deduction: false,
    socialSecurity: false,
    rrb: false,
    eft: false,
    checking: false,
    savings: false,
    acctHolderName: "",
    depositoryBankName: "",
    bankAcctNumber: "",
    bankRouting: "",
  }),

  methods: {
      previous() {
        this.$emit("selectedTab", 3);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData();
      }
    },
    saveData() {
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          bill: this.bill,
          deduction: this.deduction,
          socialSecurity: this.socialSecurity,
          rrb: this.rrb,
          eft: this.eft,
          checking: this.checking,
          savings: this.savings,
          acctHolderName: this.acctHolderName,
          depositoryBankName: this.depositoryBankName,
          bankAcctNumber: this.bankAcctNumber,
          bankRouting: this.bankRouting,
        })
        .then(() => {
          this.$emit("selectedTab", 5);
        })
        .catch((err) => {
          console.log(err);
          console.log("Error saving form data.");
        });
    },
  },
};
</script>
