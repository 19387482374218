<template>
  <div>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
        <v-row>
          <v-col>
            <h4>
              Typically, you may enroll in a Medicare Advantage plan only during
              the annual enrollment period from October 15 through December 7 of
              each year. There are exceptions that may allow you to enroll in a
              Medicare Advantage plan outside of this period.
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5 class="subtitle-2">
              Please read the following statements carefully and mark the bubble
              if the statement(s) applies to you. By marking any of the
              following bubbles, you are certifying that, to the best of your
              knowledge, you are eligible for an enrollment period. If we later
              determine that this information is incorrect, you may be
              disenrolled.
            </h5>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col cols="3">
            <h4>Code</h4>
          </v-col>
          <v-col cols="9">
            <h4>Enrollment Period Statemtents</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="NEW" label="NEW" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I just became eligible for Medicare Part A and/or Part B (ICEP/IEP).
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="LEC" label="LEC" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="4">
            I am leaving employer or union coverage on:
          </v-col>
          <v-col>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="leavingCoverage"
                  label="Date Leaving Coverage"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  class="pt-0 mt-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                type="month"
                v-model="leavingCoverage"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="AEP" label="AEP" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I am enrolling during Annual Enrollment Period.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="CIE" label="CIE" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I was enrolled in a plan by Medicare (or my state) within the last
            three months and I want to choose a different plan.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="DST" label="DST" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I was affected by a Federal Emergency Management Agency (FEMA)
            declared emergency/disaster or a disaster or other emergency
            declaration issued by a federal, state or local governments entity,
            and was unable to use another election period available to me due to
            it.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="EXC" label="EXC" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I had a change in my Extra Help paying for Medicare prescription
            drug coverage (newly got Extra Help, had a change in the level of
            Extra Help, or lost Extra Help) within the last three months.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="EXT" label="EXT" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I get Extra Help paying for my Medicare prescription drug coverage,
            but I haven’t had a change. Note: the SEP is only valid once per
            calendar quarter from January 1 through September 30.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="INC" label="INC" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I was released from incarceration within the last three months.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="LAW" label="LAW" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I obtained lawful presence status in the United States within the
            last three months.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="LOC" label="LOC" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="4">
            I recently involuntarily lost my credible prescription drug coverage
            (coverage as good as Medicare’s). I lost my drug coverage on (insert
            date):
          </v-col>
          <v-col>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="lostPresCoverage"
                  label="Date Coverage Lost"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined 
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                type="month"
                v-model="lostPresCoverage"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="LTC" label="LTC" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="4">
            I am moving into, live in, or recently moved out of a long-term care
            facility (for example, a nursing home). I moved/will move into/out
            of the facility on (insert date):
          </v-col>
          <v-col>
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="longTermCareDate"
                  label="Date Of LTC Move"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined 
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                type="month"
                v-model="longTermCareDate"
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="MCC" label="MCC" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I had a change in my Medicaid (newly got Medicaid, had a change a
            level of Medicaid assistance, or lost Medicaid) within the last
            three months.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="MCD" label="MCD" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I have both Medicare and Medicaid (or my state helps pay for my
            Medicare premiums), but I haven’t had a change. Note: this Special
            Election Period (SEP) is only valid once per calendar quarter from
            January 1 through September 30.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="MOV" label="MOV" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="4">
            I recently moved outside of the service area from my current plan OR
            I recently moved in this plan is a new option for me. I moved on
            (insert date):).
          </v-col>
          <v-col>
            <v-menu
              v-model="menu4"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="serviceMoveOut"
                  label="Date Of Move"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined 
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                type="month"
                v-model="serviceMoveOut"
                @input="menu4 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="NON" label="NON" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            My existing Medicare Advantage (MA) plan is non-renewing for the
            upcoming contract year. Note: this Special Election Period (SEP) is
            only valid from December 8th through the last day of February of the
            following year.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="OEP" label="OEP" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I am enrolled in a Medicare Advantage plan in what to make a change
            during the Medicare Advantage Open Enrollment Period.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="PAC" label="PAC" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I left a PACE (Program of All-Inclusive Care for the Elderly)
            program within the last two months.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="RUS" label="RUS" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I returned to the United States after living permanently outside of
            the U.S. within the last three months.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="SNP" label="SNP" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I am being disenrolled from a Special Needs Plan (SNP) because I no
            longer have special needs status OR I have been disenrolled from an
            SNP plan within the last three months.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="SSEP" label="S-SEP" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I am applying for a SNP Chronic Condition Plan.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="SPA" label="SPA" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            I belong to a pharmacy is systems program provided by my state.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="TER" label="TER" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            My plan is ending its contract with Medicare, or Medicare is ending
            its contract with my plan.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox v-model="OTH" label="OTH" class="ma-0" dense></v-checkbox>
          </v-col>
          <v-col cols="9">
            None of the above statements apply to me; however, I feel I have a
            special circumstance which would allow me an exception to enroll
            (subject to approval). Please explain:
          </v-col>
          <v-col>
            <v-text-field v-model="otherReason" label="Reason" outlined dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
            <v-col class="text-left pb-0">
                <v-btn text :disabled="!valid" color="primary" @click="previous()">
                    <v-icon small> mdi-chevron-left </v-icon>
                    Previous
                </v-btn>
            </v-col> 
          <v-spacer></v-spacer>
          <v-col class="text-right pb-0">
            <v-btn
              text
              :disabled="!valid"
              color="primary"
              @click="validate"
            >
              Next
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["recordID"],
  data: () => ({
    valid: true,
    leavingCoverage: "",
    lostPresCoverage: "",
    longTermCareDate: "",
    serviceMoveOut: "",
    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,
    NEW: false,
    LEC: false,
    AEP: false,
    CIE: false,
    DST: false,
    EXC: false,
    EXT: false,
    INC: false,
    LAW: false,
    LOC: false,
    LTC: false,
    MCC: false,
    MCD: false,
    MOV: false,
    NON: false,
    OEP: false,
    PAC: false,
    RUS: false,
    SNP: false,
    SPA: false,
    TER: false,
    OTH: false,
    SSEP: false,
    otherReason: "",
  }),

  methods: {
    previous() {
        this.$emit("selectedTab", 5);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData();
      }
    },
    saveData() {
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          leavingCoverage: this.leavingCoverage,
          lostPresCoverage: this.lostPresCoverage,
          longTermCareDate: this.longTermCareDate,
          serviceMoveOut: this.serviceMoveOut,
          NEW: this.NEW,
          LEC: this.LEC,
          AEP: this.AEP,
          CIE: this.CIE,
          DST: this.DST,
          EXC: this.EXC,
          EXT: this.EXT,
          INC: this.INC,
          LAW: this.LAW,
          LOC: this.LOC,
          LTC: this.LTC,
          MCC: this.MCC,
          MCD: this.MCD,
          MOV: this.MOV,
          NON: this.NON,
          OEP: this.OEP,
          PAC: this.PAC,
          RUS: this.RUS,
          SNP: this.SNP,
          SPA: this.SPA,
          TER: this.TER,
          OTH: this.OTH,
          SSEP: this.SSEP,
          otherReason: this.otherReason,
        })
        .then(() => {
          this.$emit("selectedTab", 7);
        })
        .catch((err) => {
          console.log(err);
          console.log("Error saving form data.");
        });
    },
  },
};
</script>
