<template>
    <v-card class="transparent-card pa-2 mx-3 mt-2" outlined>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-card-actions>
                <v-select
                  v-model="selectedNote"
                  :items="noteItems"
                  label="Note Type"
                  class="ml-3 mt-1 pl-1"
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-card-actions>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn
                @click="newNoteDialog = true"
                class="mt-4 mr-4"
                color="success"
              >
                Create New Note
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog
        v-model="newNoteDialog"
        persistent
        max-width="500px"
        id="note-dialog"
      >
        <v-card tile>
          <v-toolbar dark flat color="primary">
            <v-card-title color="primary">Add New Note</v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="newNoteForm" v-model="newNoteValid" lazy-validation>
              <v-row class="mt-2 px-1">
                <v-col cols="12">
                  <v-select
                    v-model="newNoteType"
                    :items="noteItems"
                    label="Note Type"
                    outlined
                    required
                    :rules="[newNoteType != '' || 'Please select note type']"
                    dense
                  ></v-select>
                </v-col>
                <template v-if="newNoteType === 'Termination Notes'">
                  <v-col cols="12">
                    <v-menu
                      ref="termedDatePicker"
                      v-model="termedDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Termed As Of"
                          append-icon="mdi-calendar"
                          v-model="newNoteTermDate"
                          clearable
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker ref="picker" v-model="newNoteTermDate">
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="primary"
                          @click="termedDatePicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="$refs.termedDatePicker.save(newNoteTermDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="newNoteTermReason"
                      :items="termReasonList"
                      label="Termination Reason"
                      dense
                      outlined
                    />
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-text-field
                    v-model="newNoteTitle"
                    label="Title"
                    :counter="20"
                    :rules="[(v) => v.length < 20 || 'Max 20 characters']"
                    hide-details
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="newNoteText"
                    placeholder="Please enter your note here..."
                    :counter="300"
                    :rules="[
                      newNoteText != '' || 'Note contents must not be empty',
                      (v) => v.length <= 300 || 'Max 300 characters',
                      newNoteType != '' || 'Please select note type',
                    ]"
                    required
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newNoteAuthor"
                    label="Note Author"
                    class="px-2 my-2"
                    hide-details
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="accent" text @click="closeNewNote()">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="validateNewNote()">
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- edit note dialog -->
      <v-dialog v-model="editNoteDialog" persistent max-width="450px">
        <v-card>
          <v-toolbar dark flat color="primary" class="mb-2">
            <v-card-title color="primary">Edit Note</v-card-title>
          </v-toolbar>
          <v-form ref="newNoteForm" lazy-validation>
            <v-text-field
              v-model="newNoteTitle"
              label="Title"
              :counter="20"
              :rules="[(v) => v.length < 20 || 'Max 20 characters']"
              hide-details
              outlined
              dense
            />
            <v-textarea
              v-model="newNoteText"
              placeholder="Please enter your note here..."
              :counter="300"
              :rules="[
                newNoteText != '' || 'Note contents must not be empty',
                (v) => v.length <= 300 || 'Max 300 characters',
              ]"
              required
              outlined
            />
          </v-form>
          <v-card-actions>
            <v-btn
              color="accent"
              text
              @click="
                editNoteDialog = false
                oldNote = {}
              "
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="addNewNote(null, true)">
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- / edit note dialog -->
      <!-- delete prompt -->
      <v-dialog v-model="deleteDialog" max-width="900px">
        <v-card>
          <v-card-title
            >Are you sure you want to delete this note?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteNote()">Yes</v-btn>
            <v-btn color="blue darken-1" text @click="deleteDialog = false"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- / delete prompt -->
      <v-row min-height="12rem">
        <v-col v-for="note in noteList" :key="note.id" cols="12" md="4">
          <v-card height="100%" width="100%">
            <v-row class="pl- pb-2">
              <v-card-title class="pa-0 ml-5">
                {{ note.title }}
              </v-card-title>
              <v-spacer></v-spacer>
              <div class="my-2">
                <v-chip :color="noteChipColor(note.type)" small outlined>
                  {{ note.type }}
                </v-chip>
                <v-btn
                  @click="
                    editNoteDialog = true
                    openEditDialog({
                      title: note.title,
                      message: note.message,
                      date: note.date,
                      author: note.author,
                      type: note.type,
                    })
                  "
                  icon
                  small
                >
                  <v-icon small>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="promptDelete(note.message, note.date, note.type)"
                  icon
                  small
                  class="mr-3"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-row>
            <v-divider></v-divider>
            <v-card-text style="height: 12rem">
              {{ note.message }}
              <template v-if="note.termDate" class="white grey--text">
                <p class="caption mt-5">
                  Termination Notification Date: {{ note.termDate }}
                </p>
              </template>
              <template v-if="note.termReason" class="white grey--text">
                <p class="caption mt-5">
                  Termination Reason: {{ note.termReason }}
                </p>
              </template>
            </v-card-text>
            <v-card-actions class="grey--text">
              <v-col cols="6" class="caption" v-if="note.author">
                Note Author: {{ note.author }}
              </v-col>
              <v-col cols="6" class="text-right caption">
                Created: {{ note.date.slice(0, 10) }}
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
export default {
  props: ["credData"],
  data() {
    return {
      selectedNote: "",
      newNoteType: "",
      noteItems: ["Notes", "Withdraw Notes", "Termination Notes"],
      newNoteDialog: false,
      editNoteDialog: false,
      termedDatePicker: false,
      editNoteDate: "",
      newNoteTitle: "",
      newNoteText: "",
      newNoteAuthor: "",
      newNoteTermDate: "",
      newNoteTermReason: "",
      newNoteValid: false,
      oldNote: {},
      deleteDialog: false,
      termReasonList: [
        "Provider Left Group",
        "Retired",
        "Expired License",
        "Expired COI",
        "Denied by Credentialing Committee",
        "Expired Attestation for Re-Cred",
        "Appears on OIG or SAM report",
      ],
    }
  },
  methods: {
    openEditDialog(editNote) {
      // note edit dialog
      this.oldNote = editNote
      // setup initial values for note edit dialog
      this.newNoteTitle = editNote.title ? editNote.title : ""
      this.newNoteText = editNote.message
      this.newNoteAuthor = editNote.author ? editNote.author : ""
    },
    addNewNote(payload = null, edit = false) {
      // optional editing param defaults to false, optional payload for custom event from locationtab
      if (!edit) {
        if (payload) {
          // push the payload (note) to its corresponding array
          this.credData[payload.type].push({
            author: "",
            title: "",
            message: payload.message,
            termDate: this.newNoteTermDate,
            termReason: this.newNoteTermReason,
            date: new Date().toISOString(),
            type: payload.formattedType,
          })
        } else {
          this.credData[this.noteTypeParser(this.newNoteType)].push({
            author: this.newNoteAuthor,
            title: this.newNoteTitle,
            message: this.newNoteText,
            termReason: this.newNoteTermReason,
            termDate: this.newNoteTermDate,
            date: new Date().toISOString(),
            type: this.newNoteType,
          })
        }
      } else {
        this.credData[this.noteTypeParser(this.oldNote.type)].every(
          (element) => {
            if (
              element.message == this.oldNote.message &&
              element.date == this.oldNote.date
            ) {
              // like deleteNote we are comparing date and message to the array
              element.message = this.newNoteText
              element.title = this.newNoteTitle
              return false
            } else return true
          }
        )
        this.oldNote = {}
      }
      this.newNoteAuthor = ""
      this.newNoteTitle = ""
      this.newNoteText = ""
      this.newNoteDialog = false
      this.editNoteDialog = false
      this.newNoteType = ""
    },
    promptDelete(message, date, type) {
      const noteObj = {
        message,
        date,
        type,
      }
      this.deleteDialog = true
      this.toDelete = noteObj
    },
    deleteNote() {
      // check note type and then filter through that array comparing message and date
      this.credData[this.noteTypeParser(this.toDelete.type)] = this.credData[
        this.noteTypeParser(this.toDelete.type)
      ].filter(
        (noteArray) =>
          noteArray.message != this.toDelete.message &&
          noteArray.date != this.toDelete.date
      )
      this.deleteDialog = false
    },
    async validateNewNote() {
      if (this.$refs.newNoteForm.validate()) {
        await this.addNewNote()
      }
    },
    closeNewNote() {
      this.newNoteType = ""
      this.newNoteText = ""
      this.newNoteAuthor = ""
      this.newNoteDialog = false
      this.newNoteTermDate = ""
      this.newNoteTermReason = ""
    },
    noteChipColor(type) {
      // for note type chips
      return type === "Notes"
        ? "#4FAC7E"
        : type === "Withdraw Notes"
        ? "#0E8BCD"
        : "red"
    },
    sortDates(arr) {
      // sorts notes by dates
      return arr
        .sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))
        .reverse()
    },
    async setTypes(init) {
      // sets up types for notes on the front end at initialization
      if (init) {
        this.credData["notes"] = this.credData["notes"].map((item) => ({
          ...item,
          type: "Notes",
        }))
        this.credData["withdrawNotes"] = this.credData[
          "withdrawNotes"
        ].map((item) => ({ ...item, type: "Withdraw Notes" }))
        this.credData["terminationNotes"] = this.credData[
          "terminationNotes"
        ].map((item) => ({ ...item, type: "Termination Notes" }))
      } else {
        // clear types, otherwise these would get stored in the db and we don't need that
        this.credData["notes"].forEach((object) => {
          delete object["type"]
        })
        this.credData["withdrawNotes"].forEach((object) => {
          delete object["type"]
        })
        this.credData["terminationNotes"].forEach((object) => {
          delete object["type"]
        })
      }
    },
    noteTypeParser(noteString) {
      // method helps out all the instances where the note type is its fluffy name version, vs db name / goes both ways
      if (noteString === "Notes") return "notes"
      if (noteString === "notes") return "Notes"
      if (noteString === "Termination Notes") return "terminationNotes"
      if (noteString === "terminationNotes") return "Termination Notes"
      if (noteString === "Withdraw Notes") return "withdrawNotes"
      if (noteString === "withdrawNotes") return "Withdraw Notes"
    },
  },
  computed: {
    noteList() {
      if (!this.selectedNote) {
        return this.sortDates([
          ...this.credData["notes"],
          ...this.credData["withdrawNotes"],
          ...this.credData["terminationNotes"],
        ])
      } else {
        return this.sortDates(
          this.credData[this.noteTypeParser(this.selectedNote)]
        )
      }
    },
  },
}
</script>

<style scoped>
#note-dialog {
  overflow-x: hidden !important;
}
</style>
