<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12" lg="3">
          <v-select
            :items="roleArray"
            label="Who completed this HRA form?"
            v-model="whoCompletedForm"
            outlined
            dense
            required
            :rules="[
              this.whoCompletedForm != '' || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            label="Full Name Of Individual Filling Out This HRA Form"
            outlined
            dense
            v-model="nameOfIndividual"
            required
            :rules="[
              this.nameOfIndividual != '' || 'Please type the name of the person who filled out this form',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="completionDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="completionDate"
                label="Completion Date"
                append-icon="mdi-calendar"
                readonly
                required
                outlined
                dense
                :rules="[
                  completionDate != '' || 'Please enter completion date',
                ]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="completionDate"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
            >
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="$refs.menu1.save(completionDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="memberPresent"
            :items="['Yes', 'No']"
            label="If not filled out by member, was the Member present while
            responding to and answering the HRA questions?"
            outlined
            dense
            required
            :rules="[this.memberPresent != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn depressed :disabled="!valid" color="success" @click="validate">
            Save
            <v-icon class="pl-2" small> mdi-content-save-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    menu1: false,
    roleArray: [
      "Member",
      "PCP",
      "Spouse",
      "Son/Daughter",
      "Caregiver",
      "Other"
    ],
    completionDate: new Date().toISOString().substr(0, 10),
    whoCompletedForm: "",
    nameOfIndividual: "",
    memberPresent: ""
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          completionDate: this.completionDate,
          whoCompletedForm: this.whoCompletedForm,
          nameOfIndividual: this.nameOfIndividual,
          memberPresent: this.memberPresent
        });

        this.$emit('formCompleted', true)
      }
    }
  },
  watch: {
    menu1(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  }
};
</script>
