<template>
  <v-app class="login-img">
    <v-navigation-drawer
      color="primary"
      class="login-sidebar pa-6"
      permanent
      floating
    >
      <v-img
        :src="require('@/assets/vernierwhite.png')"
        max-width="150px"
        contain
        class="mx-auto"
      ></v-img>
      <v-alert
        type="warning"
        class="mt-5"
        v-if="errorMessage === true && loginInvalid"
      >
        This account is {{ loginInvalid }}, please contact support.
      </v-alert>
      <v-alert type="error" class="mt-5" v-else-if="errorMessage === true">
        Invalid credentials, please try again.
      </v-alert>

      <v-form class="mt-10">
        <v-text-field
          v-model="user.email"
          outlined
          dark
          color="white"
          prepend-inner-icon="mdi-email"
          label="Email"
          type="email"
          @keydown.enter="submit"
        ></v-text-field>

        <v-text-field
          v-model="user.password"
          outlined
          dark
          color="white"
          prepend-inner-icon="mdi-lock"
          label="Password"
          type="password"
          @keydown.enter="submit"
        ></v-text-field>
      </v-form>

      <row class="d-flex justify-center align-center">
        <v-btn :loading="loading" color="white" class="px-5" @click="submit">
          Sign in
        </v-btn>
      </row>

      <v-list>
        <v-list-item class="sidebar-bottom-text">
          <v-btn
            text
            class="mx-auto"
            color="primary"
            href="https://halossystems.com"
            target="_blank"
          >
            Powered by Halos Systems
          </v-btn>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>
    </v-navigation-drawer>
    <div class="welcome-text d-none d-sm-block">
      <div class="display-3">welcome to Vernier.</div>
      <div class="underline">
        the one stop solution to health plan management.
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      signUpForm: false,
      registrationSuccess: false,
      user: {
        userID: "",
        email: "",
        password: "",
      },
      registrationError: false,
      regErrorMessage: "",
      errorMessage: false,
      loading: false,
      loginInvalid: "",
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.loading = true;
      const email = this.user.email.toLowerCase();
      const { password } = this.user;
      const login = async () => {
        const data = {
          email,
          password,
        };
        this.loginInvalid = "";
        await axios
          .post("/api/login", data)
          .then((res) => {
            console.log(res);
            let role = res.data.user.role;

            if (res.data.user.firstLogin === true) {
              this.$router.push("/pwchange");
            } else if (role === "hra") {
              this.$router.push("/hrapatients");
            } else {
              axios.post("/api/users/lastLogin", res.data.user);
              this.$router.push("/");
            }
            return res;
          })
          .catch((errors) => {
            if (errors.response.status === 403) {
              axios.post("/api/users/lastLogin", {
                _id: errors.response.data.userId,
              });
              this.loginInvalid = errors.response.data.userStatus.toLowerCase();
            }

            this.errorMessage = true;
            this.loading = false;
          });
      };
      login();
    },
    register(e) {
      e.preventDefault();
      this.loading = true;
      const userID = this.user.userID.toLowerCase();
      const email = this.user.email.toLowerCase();
      const { password } = this.user;
      const role = "agent";
      const user = {
        userID,
        email,
        password,
        role,
      };
      axios
        .get(`/api/agents/find/${userID}`)
        .then((response) => {
          if (response.data.length > 0) {
            axios
              .post("/api/users/create", user)
              .then(() => {
                this.registrationSuccess = true;
                this.registrationError = false;
                this.signUpForm = false;
                // reset registration fields
                this.user.userID = "";
                this.user.email = "";
                this.user.password = "";
              })
              .catch((error) => {
                this.regErrorMessage = error.response.data.message;
                this.registrationError = true;
              });
          } else {
            this.regErrorMessage = "Agent ID not found.";
            this.registrationError = true;
            this.registrationSuccess = false;
          }

          this.loading = false;
        })
        .catch(() => {
          this.errorMessage = true;
          console.log("Cannot register");
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.sidebar-bottom-text {
  position: fixed !important;
  bottom: 10px;
  left: 0;
  width: 100%;
}

.login-img {
  background-image: url("../assets/waves.svg");
  background-size: 100vw !important;
}

/* .login-img {
  background: linear-gradient(300deg, #14a7b8, #a3edf5, #f86972);
  background-size: 180% 180%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */
/* .login-img {
  background-image: url(../assets/loginbg.jpg);
  background-size: cover;
} */
.welcome-text {
  position: absolute;
  top: 45%;
  left: 25%;
}
</style>
