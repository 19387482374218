<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        <v-icon color="accent">
          mdi-dots-horizontal
        </v-icon>
      </v-btn>
    </template>

    <v-toolbar dark color="primary" class="mx-auto">
      <v-btn icon dark @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card v-if="loading" tile color="#fafafa">
      <v-row justify="center" style="height: 100vh">
        <v-col align-self="center" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
    
    <v-card v-if="!loading && careplanData.planDetails" tile color="#fafafa">
      <v-card-title class="display-2">
        {{ careplanData.memberName }}
      </v-card-title>

      <v-card-subtitle>
        <div>Member ID: {{ careplanData.memberID }}</div>
        <div>Date Created: {{ careplanData.dateCreated }}</div>
      </v-card-subtitle>

      <v-row class="px-4">
        <v-col cols="12" v-for="item in careplanData.planDetails" :key="item.problem">
          <v-card height="100%">
            <v-card-text>
              <v-list outlined class="mb-2">
                <v-row>
                  <v-col cols="6">
                    <v-list-item class="font-weight-bold" dense>Problem</v-list-item>
                    <v-subheader class="text-h6">
                      {{ item.problem }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item class="font-weight-bold" dense>Diagnosis</v-list-item>
                    <v-subheader class="text-h6">
                      {{ item.diagnosis }}
                    </v-subheader>
                  </v-col>
                </v-row>
              </v-list>

              <v-row>
                <v-col cols="6">
                  <v-list outlined height="100%">
                    <v-list-item class="font-weight-bold" dense>Goals</v-list-item>
                    <v-list-item dense v-for="goal in item.goals" :key="goal.identifier">
                      {{ goal.identifier }}. {{ goal.goal }} <span v-if="goal.completed"><i> - <span class="success--text">Goal Met</span></i></span>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="6">
                  <v-list outlined height="100%">
                    <v-list-item class="font-weight-bold" dense>Evaluations</v-list-item>
                    <v-list-item three-line dense v-for="evaluation in item.evaluations" :key="evaluation.identifier">
                      <v-list-item-content>
                        <v-list-item-title>{{ evaluation.evaluation }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ evaluation.evaluationDate.substr(0, 10) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ evaluation.evaluatedBy }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="openEditDialog(item)"
                class="mx-2"
                color="primary"
                x-small
                dark
                fab
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="editDialog" max-width="900px" persistent>
        <v-card>
          <v-toolbar dark flat color="primary">
            <v-card-title color="primary">Edit Plan Item</v-card-title>
          </v-toolbar>

          <v-form ref="newNoteForm" v-model="editValid" lazy-validation>
            <v-card-text>
              <v-row>
                <v-card-text class="pb-0">
                  Problem:
                </v-card-text>
                <v-col cols="12">
                  <v-text-field
                    label="Enter a Problem"
                    v-model="itemToEdit.problem"
                    required
                    :rules="[
                      itemToEdit.problem != '' ||
                        'Please make an entry',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-card-text class="py-0">
                  Diagnosis: 
                </v-card-text>
                <v-col cols="12">
                  <v-text-field
                    label="Enter a Diagnosis"
                    v-model="itemToEdit.diagnosis"
                    required
                    :rules="[
                      itemToEdit.problem != '' ||
                        'Please make an entry',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-card-text class="py-0">
                  Goals:
                </v-card-text>
                <v-col cols="12" md="6" v-for="(item, index) in itemToEdit.goals" :key="index">
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        label="Please enter a goal"
                        v-model="item.goal"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        v-model="item.completed"
                        class="mt-0"
                        label="Goal Met"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-btn
                    class="mx-2"
                    @click="removeGoal"
                    fab
                    dark
                    x-small
                    color="grey"
                  >
                    <v-icon dark>
                      mdi-minus
                    </v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-2"
                    @click="addGoal"
                    fab
                    dark
                    x-small
                    color="primary"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              
              <v-row>
                <v-card-text class="py-0">
                  Evaluations:
                </v-card-text>
                <v-col cols="12" md="6" v-for="(item, index) in itemToEdit.evaluations" :key="index">
                  <v-text-field
                    label="Please enter a evaluation"
                    v-model="item.evaluation"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-btn
                    class="mx-2"
                    @click="removeEvaluation"
                    fab
                    dark
                    x-small
                    color="grey"
                  >
                    <v-icon dark>
                      mdi-minus
                    </v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-2"
                    @click="addEvaluation"
                    fab
                    dark
                    x-small
                    color="primary"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>

          <v-card-actions>
            <v-btn color="accent" text @click="closeEditDialog()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="saveEdits">
              Save
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="saveSuccess" :timeout="3000" color="success">
      Changes have successfully been saved!
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>

  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment"

export default {
  name: "CareplanModal",
  props: [
    "careplanID",
    "user"
  ],
  components: {
  },
  data() {
    return {
      dialog: false,
      loading: false,
      careplanData: {},
      editValid: false,
      editDialog: false,
      editedIndex: -1,
      itemToEdit: {},
      saveSuccess: false,
    };
  },
  methods: {
    async getCareplanDetails() {
      this.loading = true;

      let response = await axios.get(`/api/careplans/find/${this.careplanID}`);
      
      this.careplanData = response.data;
      this.loading = false;
    },
    openEditDialog(item) {
      this.editedIndex = this.careplanData.planDetails.indexOf(item);
      // assign fields to edit so we don't overwrite existing data
      this.itemToEdit = Object.assign({}, item);

      // assign nested arrays/objects
      let editableGoals = [];
      item.goals.forEach(goal => {
        editableGoals.push(Object.assign({}, goal))
      })      
      this.itemToEdit.goals = editableGoals;
      
      let editableEvals = [];
      item.evaluations.forEach(evaluation => {
        editableEvals.push(Object.assign({}, evaluation))
      })
      this.itemToEdit.evaluations = editableEvals;

      this.editDialog = true;
    },
    async saveEdits() {
      let newPlanInfo = [...this.careplanData.planDetails];
      newPlanInfo[this.editedIndex] = this.itemToEdit;

      for (let i=0; i < this.careplanData.planDetails.length; i++) {
        // Loop through the keys in edited care plan
        Object.keys(newPlanInfo[0]).forEach((key) => {
          // If there is a difference we create a newEdit object and push to the edits array
          if (JSON.stringify(newPlanInfo[i][key]) !== JSON.stringify(this.careplanData.planDetails[i][key])) {
            let newEdit = {
              fieldChanged: key,
              changedFrom: this.careplanData.planDetails[i][key],
              changedTo: newPlanInfo[i][key],
              date: moment(new Date()),
              user: this.user
            }
            this.careplanData.edits.unshift(newEdit)
          }
        })
      }

      this.careplanData.planDetails = newPlanInfo

      await axios.post(`/api/careplans/update/${this.careplanID}`, this.careplanData)
        .then(() => {
          this.saveSuccess = true;
          this.closeEditDialog();
          this.getCareplanDetails();
        })
    },
    closeEditDialog() {
      this.itemToEdit = {};
      this.editDialog = false;
    },
    addGoal() {
      this.itemToEdit.goals.push({
        identifier: this.itemToEdit.goals.length + 1,
        goal: ''
      });
    },
    removeGoal() {
      if(this.itemToEdit.goals.length > 1) {
        this.itemToEdit.goals.pop();
      }
    },
    addEvaluation() {
      this.itemToEdit.evaluations.push({
        evaluation: '',
        evaluatedBy: this.user.userID,
        evaluationDate: new Date().toISOString()
      });
    },
    removeEvaluation() {
      if(this.itemToEdit.evaluations.length > 1) {
        this.itemToEdit.evaluations.pop();
      }
    },
  },
  watch: {
    dialog() {
      if(this.dialog === true) {
        this.getCareplanDetails()
      }
    },
  }, 
};
</script>

<style scoped>
</style>