import { render, staticRenderFns } from "./HRAPatients.vue?vue&type=template&id=1622aeaf&scoped=true&"
import script from "./HRAPatients.vue?vue&type=script&lang=js&"
export * from "./HRAPatients.vue?vue&type=script&lang=js&"
import style0 from "./HRAPatients.vue?vue&type=style&index=0&id=1622aeaf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1622aeaf",
  null
  
)

export default component.exports