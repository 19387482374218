<template>
  <div>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
        <v-row>
          <span class="justify-start ma-3 font-weight-black">
            Please provide your Medicare insurance information
          </span>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            <v-text-field
              v-model="name"
              label="Name"
              hint="As it appears on your Medicare card"
              outlined
              required
              dense
              :rules="[
                this.name != '' ||
                  'Please enter a name',
                /\S/.test(
                  this.name
                ) || 'Please enter a name',
              ]"
            ></v-text-field>
            <!-- regex -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="medicareNumber"
              label="Medicare Number"
              outlined
              required
              dense
              :rules="[
                this.medicareNumber != '' ||
                  'Field cannot be blank',
                /\S/.test(
                  this.medicareNumber
                ) || 'Field cannot be blank',
                /^\w+$/.test(this.medicareNumber) || 'Field must be alphanumberic symbols only'
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="medicaidNumber"
              label="Medicaid Number (optional)"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              dense
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="effectiveDateOfCoverage"
                  label="Sonder Effective Date"
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker 
                v-model="effectiveDateOfCoverage"
                :min="new Date().toISOString().substr(0, 10)"
                no-title 
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(effectiveDateOfCoverage)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" lg="3" align="start">
            <div class="mb-4">Is Entitled To:</div>
          </v-col>
          <v-col cols="6" lg="3" align="start">
            <div class="mb-2">Effective Date:</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3" align="start">
            <div class="pt-2">HOSPITAL (Part A)</div>
          </v-col>
          <v-col lg="3" align="start">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              dense
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="partA"
                  label="Hospital (Part A)"
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="partA" type="month" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu1.save(partA)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3" align="start">
            <div class="pt-2">MEDICAL (Part B)</div>
          </v-col>
          <v-col lg="3" align="start">
            <div>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="partB"
                    label="Medical (Part B)"
                    append-icon="mdi-calendar"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="partB" type="month" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(partB)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>
              You must have Medicare Part A and B to join a Medicare Advantage
              plan.
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <v-btn text :disabled="!valid" color="primary" @click="previous()">
              <v-icon small> mdi-chevron-left </v-icon>
              Previous
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right pb-0">
            <v-btn text :disabled="!valid" color="primary" @click="validate">
              Next
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["selectedPlan", "recordID"],
  data: () => ({
    valid: true,
    name: "",
    medicareNumber: "",
    medicaidNumber: "",
    effectiveDateOfCoverage: "",
    partA: "",
    partB: "",
    menu1: false,
    menu2: false,
    menu: false,
    firstSave: true,
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.removeSpace()
        this.saveData();
      }
    },
    removeSpace() {
      this.medicareNumber = this.medicareNumber.trim()
    },
    previous() {
      this.$emit("selectedTab", 0);
    },
    saveData() {
      if (this.firstSave) {
        axios
          .post("/api/enrollment/new", {
            name: this.name,
            selectedPlan: this.selectedPlan,
            medicareNumber: this.medicareNumber,
            medicaidNumber: this.medicaidNumber,
            effectiveDateOfCoverage: this.effectiveDateOfCoverage,
            partA: this.partA,
            partB: this.partB,
            rxBin: "",
            rxPcn: "",
            rxGroup: "",
            pcpCopay: 0,
            specialistCopay: 0,
            emergencyCopay: 0,
            urgentCopay: 0,
            disenrollmentLetter: null,
            memberIdCreated: null,
            typeOfPractice: "",
            ackOfReciept: null,
          })
          .then((response) => {
            this.firstSave = false;
            this.$emit("selectedTab", 2);
            this.$emit("recordID", response.data.data._id);
            this.$emit("medicareID", response.data.data.medicareNumber);
            this.$emit("effectiveDateOfCoverage", this.effectiveDateOfCoverage) // a surprise tool for later ;)
          })
          .catch((err) => {
            console.log(err);
            console.log("Error saving form data.");
          });
      } else {
        axios
          .post("/api/enrollment/save", {
            _id: this.recordID,
            name: this.name,
            selectedPlan: this.selectedPlan,
            medicareNumber: this.medicareNumber,
            medicaidNumber: this.medicaidNumber,
            partA: this.partA,
            partB: this.partB,
            effectiveDateOfCoverage: this.effectiveDateOfCoverage
          })
          .then(() => {
            this.$emit("selectedTab", 2);
          })
          .catch((err) => {
            console.log(err);
            console.log("Error saving form data.");
          });
      }
    },
  },
};
</script>
