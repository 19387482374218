<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <span class="justify-start ma-3 font-weight-black">
          Please choose the name of a Primary Care Physician (PCP), clinic or
          health center
        </span>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-combobox
            :items="pcpList"
            v-model="selectedPCP"
            label="PCP Name"
            item-text="RenderingProviderFullName"
            hint="Search or select PCP from dropdown"
            @change="setPCP()"
            persistent-hint
            outlined
            dense
          />
        </v-col>
        <v-col cols="2">
          <v-checkbox
            class="mt-0 pt-0"
            label="PCP Not Shown"
            v-model="pcpNotShown"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-combobox
            v-model="pcpID"
            label="PCP ID"
            :items="pcpList"
            item-text="RenderingNPI"
            @change="setPCPbyID()"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row class="text-left">
        <v-col cols="12" lg="4">
          <span>Are you already a patient of the PCP you chose?</span>
        </v-col>
        <v-col cols="6" lg="1">
          <v-checkbox
            class="mt-0 pt-0"
            v-model="pcpYes"
            @change="pcpNo = !pcpYes"
            label="Yes"
            type="number"
            dense
          ></v-checkbox>
        </v-col>
        <v-col cols="6" lg="1">
          <v-checkbox
            class="mt-0 pt-0"
            v-model="pcpNo"
            @change="pcpYes = !pcpNo"
            label="No"
            type="number"
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
      <div>
        <h3>OR</h3>
      </div>
      <v-row class="mt-3">
        <v-col>
          <v-autocomplete v-model="MedicalGroupAffiliation" label="Medical Group Affiliation" :items="medicalAffiliates" dense outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-left pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="previous()">
            <v-icon small> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  props: ["recordID"],
  data: () => ({
    valid: true,
    pcpList: [],
    medicalAffiliates: [],
    MedicalGroupAffiliation: "",
    selectedPCP: null,
    pcpID: "",
    pcpNotShown: false,
    pcpYes: false,
    pcpNo: false,
  }),
  mounted() {
    this.getPCP()
    this.getMedicalAffiliates()
  },
  methods: {
    previous() {
      this.$emit("selectedTab", 2);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData();
      }
    },
    async getMedicalAffiliates() {
      try {
        const data = await axios.get(`/api/affiliates/all/${false}/${true}`)
        this.medicalAffiliates = data.data
      }
      catch(error) {
        console.log(error)
      }
    },
    getPCP() {
      axios.get("/api/enrollment/pcps").then((response) => {
        this.pcpList = response.data[0];
      });
    },
    setPCP() {
      if(!this.pcpNotShown) {
        this.pcpID = this.selectedPCP.RenderingNPI
        this.selectedPCP = this.selectedPCP.RenderingProviderFullName 
      }
    },
    setPCPbyID() {
      if(!this.pcpNotShown) {
        this.selectedPCP = this.pcpID.RenderingProviderFullName
        this.pcpID = this.pcpID.RenderingNPI
      }
    },
    saveData() {
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          selectedPCP: this.selectedPCP,
          pcpID: this.pcpID,
          pcpYes: this.pcpYes,
          pcpNo: this.pcpNo,
          pcpNotShown: this.pcpNotShown,
          MedicalGroupAffiliation: this.MedicalGroupAffiliation
        })
        .then(() => {
          this.$emit("selectedTab", 4);
        })
        .catch((err) => {
          console.log(err);
          console.log("Error saving form data.");
        });
    },
  }
};
</script>
