<template>
  <v-container>
    <v-card class="pa-2 transparent-card" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="9">
            <v-text-field
              class="py-0"
              v-model="search"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="text-right">
            <new-task 
              :auth="auth" 
              :user="user" 
              :section="'authorization'" 
              @addTask="taskSaved"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="tasks"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.Completed="{ item }">
                <v-simple-checkbox
                  v-model="item.Completed"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>

              <template v-slot:item.CompletedDate="{ item }">
                {{ item.CompletedDate ? item.CompletedDate.substring(0, 10) : '-' }}
              </template>

              <template v-slot:item.TaskPriority="{ item }">
                <span :class="getPriorityColor(item.TaskPriority)">
                  {{ item.TaskPriority }}
                </span>
              </template>

              <template v-slot:item.TaskObjective="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>{{ item.TaskObjective }}</span>
                </v-tooltip>
              </template>
              
              <template v-slot:item.Created="{ item }">
                {{ item.Created.substring(0, 10) }}
              </template>

              <template v-slot:item.ACTIONS="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="sendReminder(item)"
                      text
                    >
                      <v-icon color="grey darken-1">mdi-email-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Send Reminder</span>
                </v-tooltip>

                <task-modal 
                  :user="user" 
                  :taskID="item.TaskID"
                  @completionChanged="updateCompleted"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="saveSuccess"
      color="success"
      :timeout="2000"
    >
      Changes have successfully been saved
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="saveSuccess = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="reminderSent"
      color="success"
      :timeout="2000"
    >
      Your reminder has successfully sent
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="reminderSent = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import TaskModal from "../task-components/TaskModal";
import NewTask from "../task-components/NewTask";

export default {
  props: [
    "auth",
    "user",
  ],
  components: {
    TaskModal,
    NewTask,
  },
  data() {
    return {
      loading: false,
      search: "",
      tasks: [],
      headers: [
        { text: "Completed", value: "Completed" },
        { text: "Completed Date", value: "CompletedDate" },
        { text: 'Priority', value: 'TaskPriority' },
        { text: 'Objective', value: 'TaskObjective' },
        { text: "Type", value: "TaskType" },
        { text: "Assigned To", value: "AssignedTo.Name" },
        { text: "Created", value: "Created" },
        { text: "Actions", value: "ACTIONS" },
      ],
      reminderSent: false,
      saveSuccess: false,
    };
  },
  computed: {
  },
  methods: {
    getTasks() {
      this.loading = true;
      this.tasks = [];

      axios.get(`/api/tasks/authorization/${this.auth.AuthID}`).then((response) => {
        this.tasks = response.data;
        this.loading = false;
      });
    },
    updateCompleted(value) {
      let index = this.tasks.findIndex(task => task.TaskID === value.TaskID);
      this.tasks[index].Completed = value.Completed;
      this.tasks[index].CompletedDate = value.CompletedDate;
    },
    sendReminder(task) {
      axios.post('/api/tasks/reminder', task).then(res => {
        if(res.data === 'sent') {
          this.reminderSent = true;
        }
      })
    },
    taskSaved(item) {
      this.tasks.push(item);
      this.saveSuccess = true;
    },
    getPriorityColor(item) {
      if(item === 'High') {
        return 'error--text';
      } else if(item === 'Medium') {
        return 'warning--text';
      } else if(item === 'Low') {
        return 'success--text';
      } else {
        return '';
      }
    },
  },
  mounted() {
    this.getTasks();
  },
};
</script>

<style scoped>
</style>