<template>
  <v-container>
    <span class="display-1">Capitation Reports</span>
    <v-row class="mt-3">
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="startPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="paramPackage.startDate"
              label="Report Month"
              append-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              flat
              outlined
              dense
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="paramPackage.startDate"
            show-current="false"
            type="month"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn outlined color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="$refs.menu.save(paramPackage.startDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="getReport">
          Submit
        </v-btn>
      </v-col>
      <v-col cols="4" v-if="submitted && !patientMode" class="d-flex">
        <v-btn @click="viewAll" color="primary" class="mx-1">
          <v-icon v-if="viewAllMembers">mdi-arrow-left</v-icon>
            {{ viewAllMembers ? 'Back' : 'View All Members' }}
        </v-btn>
        <v-btn color="primary" outlined class="mx-1" @click="generatePDF">
          <v-icon>mdi-file-image-outline</v-icon>
          <span class="pl-3">Export PDF</span>
        </v-btn>
      </v-col>
      <template v-if="patientMode">
        <v-col cols="4" class="d-flex">
          <v-btn class="mx-1" @click="(patientMode = false)" color="primary">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <ExportCSV
            :data="csvData"
            :name="`Capitation Report (${dateString}).csv`"
            :advancedOptions="advancedOptions"
          >
            <v-btn class="mx-1" color="primary" outlined>
              Export to CSV
            </v-btn>
          </ExportCSV>
          <v-btn class="mx-1" outlined @click="generatePDF()" color="primary">
            <v-icon>mdi-file-image-outline</v-icon>
            <span class="pl-3">Export PDF</span>
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <v-container id="capture">
    <template v-if="patientMode">
      <v-card id="report-header" flat class="pb-4 mb-1" tile>
        <v-card-title class="text-h3">
          {{ selectedProvider.RenderingProviderFullName }}
        </v-card-title>
        <v-card-subtitle class="pb-0 text-h5">
          <span>NPI:</span>
          <span class="accent--text">{{ selectedProvider.RenderingNPI }}</span>
        </v-card-subtitle>
        <v-card-text class="pb-0 mb-0">
          <span class="text-h6">Capitation Report {{ dateString }}</span>
          <p class="text-h6"
            >Capitation Total: ${{
              (Math.round(selectedProvider.total * 100) / 100).toFixed(2)
            }}</p
          >
        </v-card-text>
      </v-card>
    </template>
    <template v-if="(!patientMode && submitted)">
      <v-card id="report-header" flat class="pb-4 mb-1" tile>
        <v-card-text class="text-h6 pb-0 mb-0">
          <span class="accent--text">Capitation Report </span>
          <span>{{ dateString }}</span>
        </v-card-text>
        <v-card-text class="text-h6">
          <span class="accent--text">Total All Providers: </span>
          <span>${{ totalAll }}</span>
        </v-card-text>
      </v-card>
    </template>

    <v-data-table
      :items="patientMode ? patients : providers"
      :headers="headers"
      hide-default-footer
      disable-pagination
    >
      <template v-if="!patientMode" v-slot:item.Amount="{ item }">
        ${{ item.Amount }}
      </template>
      <template v-if="!patientMode" v-slot:item.total="{ item }">
        ${{ item.total }}
      </template>
      <template v-if="!patientMode" v-slot:item.view="{ item }">
        <v-btn
          v-model="item.view"
          @click="getPatients(item)"
          color="primary"
          icon
        >
          <v-icon>mdi-table-eye</v-icon>
        </v-btn>
      </template>
      <template v-if="patientMode" v-slot:item.birthDate="{ item }">
        {{ item.birthDate.slice(0, 10) }}
      </template>
      <template v-if="patientMode" v-slot:item.capitation>
        ${{ selectedProvider.Amount }}
      </template>
    </v-data-table>

    <!-- Make something so they can print all the members from all the members -->
    <template v-if="viewAllMembers">
      <template v-for="provider in allProviders">
        <v-card id="report-header" flat class="pb-4 mb-1" tile :key="provider._id">
          <v-card-title class="text-h3">
            {{ provider.RenderingProviderFullName }}
          </v-card-title>
          <v-card-subtitle class="pb-0 text-h5">
            <span>NPI:</span>
            <span class="accent--text">{{ provider.RenderingNPI }}</span>
          </v-card-subtitle>
          <v-card-text class="pb-0 mb-0">
            <span class="text-h6">Capitation Report {{ dateString }}</span>
            <p class="text-h6"
              >Capitation Total: ${{
                (Math.round(provider.total * 100) / 100).toFixed(2)
              }}</p
            >
          </v-card-text>
        </v-card>
        <v-data-table
          :headers="testHeaders"
          :items="provider.patients"
          :key="provider._id"
          hide-default-footer
          class="mb-4"
          v-if="provider.patients"
      >
      <template v-slot:item.birthDate="{ item }">
        {{ item.birthDate.slice(0, 10) }}
      </template>
      <template v-slot:item.capitation>
        ${{ provider.Amount }}
      </template>
      </v-data-table>
      </template>
    </template>
  </v-container>
  </v-container>
</template>

<script>
import axios from "axios"
import ExportCSV from "vue-json-csv"
import html2pdf from "html2pdf.js"

export default {
  name: "CapitationReports",
  components: {
    ExportCSV,
  },
  data() {
    return {
      patientMode: false,
      startPicker: false,
      endPicker: false,
      csvData: [],
      providers: [],
      patients: [],
      selectedProvider: {},
      totalAll: 0,
      submitted: false,
      paramPackage: {
        startDate: new Date().toISOString().substr(0, 7),
        providerName: ""
      },
      testHeaders: [
        { text: "Medicare ID", value: "medicareNumber" },
        { text: "Member ID", value: "memberID" },
        { text: "Member Name", value: "name" },
        { text: "DOB", value: "birthDate" },
        { text: "Age", value: "age" },
        { text: "Capitation", value: "capitation" },
      ],
      viewAllMembers: false,
      advancedOptions: {
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        transformHeader: undefined,
        dynamicTyping: false,
        preview: 0,
        encoding: "",
        worker: false,
        comments: false,
        step: undefined,
        complete: undefined,
        error: undefined,
        download: false,
      },
      allProviders: [],
    }
  },
  computed: {
    headers() {
      if(this.viewAllMembers) {
        return []
      }
      if (this.patientMode) {
        return [
          { text: "Medicare ID", value: "medicareNumber" },
          { text: "Member ID", value: "memberID" },
          { text: "Member Name", value: "name" },
          { text: "DOB", value: "birthDate" },
          { text: "Age", value: "age" },
          { text: "Capitation", value: "capitation" },
        ]
      } else
        return [
          { text: "Provider Name", value: "RenderingProviderFullName" },
          { text: "NPI", value: "RenderingNPI" },
          { text: "Total Members", value: "count" },
          { text: "Capitation", value: "Amount" },
          { text: "Total", value: "total" },
          { text: "View Members", value: "view" },
        ]
    },
    dateString() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
      const dateArr = this.paramPackage.startDate.slice(0, 10).split("-")
      const dateStr = `${months[dateArr[1] - 1]} ${dateArr[0]}`
      return dateStr
    },
  },
  methods: {
    async getReport() {
      try {
        const data = await axios.get(
          `api/credentialing/capitations/${JSON.stringify(this.paramPackage)}`
        )
        this.totalAll = data.data[1].totalAll
        this.providers = data.data[0]
        this.patientMode = false
        this.selectedProvider = {}
        this.csvData = []
        this.submitted = true
      } catch (error) {
        console.log(error)
      }
    },
    async getPatients(provider) {
      this.selectedProvider = provider
      const data = await axios.get(
        `api/credentialing/capPatients/${provider.RenderingNPI}/${JSON.stringify(this.paramPackage)}`
      )
      this.patients = data.data[0]
      let tempCSV = [
        ["Capitation Report"],
        [
          this.selectedProvider.RenderingProviderFullName,
          `Provider NPI: ${this.selectedProvider.RenderingNPI}`,
        ],
      ]
      tempCSV.push(["Medicare Number", "Name", "DOB", "Age", "Capitation"])
      this.patients.forEach((element) => {
        const tempDOB = element.birthDate.slice(0, 10)
        tempCSV.push([
          element.medicareNumber,
          element.name,
          tempDOB,
          element.age,
          `$${this.selectedProvider.Amount}`,
        ])
      })
      tempCSV.push([
        "",
        "",
        "",
        "",
        `Capitation Total: $${this.selectedProvider.total}`,
      ])
      this.csvData = tempCSV
      this.patientMode = true
    },
    async getAllPatients() {
      this.providers.forEach(async (provider) => {
        let tempProvider = {
          RenderingProviderFullName: provider.RenderingProviderFullName,
          RenderingNPI: provider.RenderingNPI,
          Amount: provider.Amount,
          total: provider.total,
          patients: []
        }

        const tempData = await axios.get(
          `api/credentialing/capPatients/${provider.RenderingNPI}/${JSON.stringify(this.paramPackage)}`
        )
        tempProvider.patients = tempData.data[0]
        this.allProviders.push(tempProvider)
      })
    },
    async viewAll() {
      if(!this.viewAllMembers) {
        this.viewAllMembers = true
        await this.getAllPatients()
      } else {
        this.viewAllMembers = false
      }

    },
    generatePDF() {
      let element = document.getElementById("capture")
      html2pdf(element, {
        margin: 1,
        filename: "Capitations.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      })
    },
  },
}
</script>