<template>
  <div>
    <v-container id="capture" style="background: #ffffff">
      <div>
        <v-card width="100%">
          <v-form ref="form" lazy-validation v-model="valid">
            <h3 class="ma-3 fon-weight-black">
              CHRONIC CONDITION VERIFICATION FORM
            </h3>
            <v-row class="mb-2">
              <v-card-text>
                Completion of this document authorizes the disclosure and/or use
                of individually identifiable health information, as set forth
                below, consistent with Federal law concerning the privacy of
                such information.
              </v-card-text>
            </v-row>
            <v-row>
              <v-col>
                <strong>
                  <h3 class="justify-start ma-3 fon-weight-black">
                    RELEASE OF INFORMATION
                  </h3>
                  <p>
                    By joining either the Sonder Diabetes Wellness Plan (C-SNP
                    H1748003) or the Sonder Heart Healthy Plan (C-SNP H1748004),
                    I acknowledge that I have one or more of the following
                    conditions:
                  </p>
                </strong>
              </v-col>
              <v-col>
                <v-checkbox
                  label="Diabetes"
                  v-model="chronicConditionsVerified.diabetesStatus"
                />
                <v-checkbox
                  label="Cardiovascular Disease"
                  v-model="chronicConditionsVerified.cvdStatus"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-card-text>
                I authorize and direct
                <v-text-field
                  dense
                  v-model="chronicConditionsVerified.providerName"
                  label="Care Provider/Specialist"
                  single-line
                />
                to confirm my chronic condition and disclose my medical records
                to Sonder Health Plans. This authorization shall be effective
                until I am no longer enrolled in Sonder Health Plans.
              </v-card-text>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="justify-start ma-3 fon-weight-black">
                  APPLICATION USE AND DISCLOSURE AUTHORIZATION
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <strong> APPLICANT, please complete if applicable. </strong>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="chronicConditionsVerified.applicantName"
                  label="Name of Applicant/Authorized Representative"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="chronicConditionsVerified.dobOrMedicareID"
                  label="Medicare ID Number or Date of Birth"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="chronicConditionsVerified.date"
                      :rules="[
                        !!chronicConditionsVerified.date
                          ? true
                          : 'Please enter a name',
                      ]"
                      label="Date of Signature"
                      readonly
                      class="pt-0 mt-0"
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="chronicConditionsVerified.date"
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col> Signature of Applicate/Authorized Representative</v-col>
              <v-alert dense outlined type="error" v-model="alert">
                Please sign your name below to continue
              </v-alert>
              <v-col cols="12">
                <Signature ref="Signature" :signatureSize="4" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                If you are the authorized representative of the applicant,
                provide the following information:
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="chronicConditionsVerified.representativeRelationship"
                  label="Relationship to Applicant"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="chronicConditionsVerified.representativeNumber"
                  label="Telephone Number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </v-container>
    <v-container class="mt-2">
      <v-row class="d-flex mb-6 justify-end">
        <!-- <v-btn class="mr-5" color="warning" @click="skip">Skip</v-btn> -->
        <v-btn color="success" @click="save"
          >Save<span v-if="!isMobile"> & Upload</span></v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Signature from "./Signature.vue";
import axios from "axios";
// import { log } from "console";

export default {
  components: {
    Signature,
  },
  props: ["recordID", "medicareID"],
  data() {
    return {
      chronicConditionsVerified: {
        cvdStatus: false,
        diabetesStatus: false,
        providerName: "",
        applicantName: "",
        dobOrMedicareID: "",
        signature: "",
        date: "",
        representativeRelationship: "",
        representativeNumber: "",
        isMobile: false,
      },
      valid: false,
      menu: false,
      alert: false,
      csnp: true,
      csnpInfo: "",
      footer: `Sonder Health Plans
      6190 Powers Ferry Road, Suite 320
      Atlanta, GA 30339
      If you have any questions, please call: 1 (888) 428-4400, TTY 711, 7 days a week, 8 am - 8pm.

      Sonder Health Plans, Inc. is an HMO with a Medicare contract.
      Enrollment in Sonder health plans, Inc. depends on contract renewal.
      `,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.csnpInfo = JSON.parse(localStorage.getItem("data"));
    },
    async save() {
      this.chronicConditionsVerified.signature =
        await this.$refs.Signature.submit();
      if (this.$refs.form.validate()) {
        if (this.chronicConditionsVerified.signature !== undefined) {
          this.fillPDF();
        } else {
          this.alert = true;
        }
      }
    },
    // async generateJpeg() {
    //   if(!this.isMobile) {
    //     await htmlToImage
    //       .toJpeg(document.getElementById("capture"), {
    //         quality: 0.95,
    //         backgroundColor: "white",
    //         width: 700,
    //         height: 1600,
    //       })
    //       .then((dataUrl) => {
    //         let link = document.createElement("a")
    //         link.download = "Chronic_Condition_Verification" + ".jpeg"
    //         link.href = dataUrl
    //         link.click()
    //         this.handleFiles(dataUrl)
    //       })
    //   }
    //   else {
    //     await htmlToImage
    //       .toJpeg(document.getElementById("capture"), {
    //         quality: 0.95,
    //         backgroundColor: "white",
    //       })
    //       .then((dataUrl) => {
    //         let link = document.createElement("a")
    //         link.download = "Chronic_Condition_Verification" + ".jpeg"
    //         link.href = dataUrl
    //         this.handleFiles(dataUrl)
    //       })
    //   }
    // },

    async fillPDF() {
      this.chronicConditionsVerified = {
        ...this.chronicConditionsVerified,
        ...this.csnpInfo,
      };

      await axios
        .post(`api/modals/enrollment/csnp`, this.chronicConditionsVerified)
        .then((response) => {
          this.handleFiles(response.data);
        });
    },
    async handleFiles(file) {
      let formData = new FormData();
      formData.append("pdf", file);

      await axios
        .post(
          `api/modals/enrollment/documents/upload/csnp/${this.medicareID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.saveData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveData() {
      axios
        .post("/api/enrollment/save", {
          _id: this.recordID,
          chronicConditionsVerified: this.chronicConditionsVerified,
        })
        .then(() => {
          this.$emit("selectedTab", 9);
        })
        .catch((err) => {
          console.log(err);
          console.log("Error saving form data.");
        });
    },
    skip() {
      this.$emit("selectedTab", 9);
    },
  },
  created() {
    this.isMobile = ["iPad", "iPhone", "iPod"].indexOf(navigator.platform) >= 0;
  },
};
</script>

<style scoped>
#capture {
  font-size: 0.8em;
}
#capture .v-card__text {
  font-size: 1em;
  padding-top: 0;
  padding-bottom: 0;
}
#capture .v-sheet {
  box-shadow: none;
}
</style>
