<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="medications"
            :items="medicationItems"
            outlined
            dense
            label="Do you take medications; and if so, how often do you take
            medications?"
            required
            :rules="[this.medications != '' || 'Please select an answer']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-model="medicationAmount"
            :items="medicationAmountItems"
            outlined
            dense
            label="How many medications do you take?"
            required
            :rules="[this.medicationAmount != '' || 'Please select an answer']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="pharmacyName"
            label="Pharmacy Name"
            outlined
            dense
            required
            :rules="[this.pharmacyName != '' || 'Please enter the pharmacy name']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="pharmacyAddress"
            label="Pharmacy Address"
            outlined
            dense
            required
            :rules="[this.pharmacyAddress != '' || 'Please enter the pharmacy address']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="pharmacyPhoneNumber"
            label="Phone Number"
            outlined
            dense
            @input="acceptNumber()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            v-model="groceriesOrMedications"
            :items="medicationQuestion"
            outlined
            dense
            required
            label="Do you sometimes find you have to choose between buying groceries
            or medications?"
            :rules="[this.groceriesOrMedications != '' || 'Please select an answer']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    medicationItems: [
      "No, never",
      "Yes, daily",
      "Yes, weekly",
      "Yes, as needed"
    ],
    medicationAmountItems: ["0", "1-3", "4-5", "6-7", "8+"],
    medicationQuestion: ["Yes", "No", "N/A"],
    medications: "",
    medicationAmount: "",
    pharmacyName: "",
    pharmacyAddress: "",
    pharmacyPhoneNumber: "",
    groceriesOrMedications: ""
  }),
  methods: {
    acceptNumber() {
      var x = this.pharmacyPhoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.pharmacyPhoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          medications: this.medications,
          medicationAmount: this.medicationAmount,
          pharmacyName: this.pharmacyName,
          pharmacyAddress: this.pharmacyAddress,
          pharmacyPhoneNumber: this.pharmacyPhoneNumber,
          groceriesOrMedications: this.groceriesOrMedications
        });

        this.$emit('selectedTab', 5)
      }
    },
  }
};
</script>
