<template>
    <v-dialog v-model="addPlanDialog" fullscreen transition="dialog-bottom-transition">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" class="mb-5"><v-icon>mdi-plus</v-icon>Add Plan</v-btn>
        </template>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title> Add Plan </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="addPlanDialog = false">
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <span>
                <v-row>
                    <v-col cols="6" align="left">
                        <v-card-title>Add Plan</v-card-title>
                    </v-col>
                  <v-col cols="6" align="right">
                    <v-btn
                        color="accent"
                        outlined
                        @click="addPlan()"
                        class="ma-5"
                    >
                        Create Plan
                    </v-btn>
                  </v-col>
              </v-row>
            </span>
            <v-form class="ma-5">
                <v-row>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.planName"
                            label="Plan Name"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.premium"
                            label="Premium"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.deductible"
                            label="Deductible"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="formData.maxOutOfPocket"
                            label="Max Out Of Pocket"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="formData.emergencyRoomCopay"
                            label="Emergency Room Copay"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="formData.urgentCareCopay"
                            label="Urgent Care Copay"
                            required
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="formData.choiceOfProviders"
                            label="Choice of Providers"
                            :items="['In Network Only', 'In and Out of Network']"
                            required
                            dense
                            outlined
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.optionalSupplementalBenefits"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Optional Supplemental Benefits</v-card-text>
                    </v-col>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.rxDrugCoverage"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Prescription Drug Coverage</v-card-text>
                    </v-col>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.otherPlanDeductibles"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Other Plan Deductibles</v-card-text>
                    </v-col>
                    <v-col cols="1" class="py-0">
                        <v-btn-toggle
                            v-model="formData.additionalBenefits"
                            dense
                            class="mt-2"
                        >
                            <v-btn :value="true">
                                Yes
                            </v-btn>
                            <v-btn :value="false">
                                No
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="2" class="align-left py-0">
                        <v-card-text class="subtitle-2">Additional Benefits</v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="3">
                        <add-c-p-t @newCode="newCode"></add-c-p-t>
                    </v-col>
                </v-row>
            </v-form>
            <div v-if="formData.cptCodes && formData.cptCodes.length > 0">
                <v-data-iterator
                    :items="formData.cptCodes"
                    :items-per-page.sync="itemsPerPage"
                >
                    <template v-slot:default="props">
                        <v-card flat outlined class="ma-3" v-for="(code, index) in props.items" :key="index">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" lg="3" v-if="code.code.min !== code.code.max">
                                        CPT Code Min: <span class="font-weight-bold">{{code.code.min}}</span>
                                    </v-col>
                                    <v-col cols="12" lg="3" v-if="code.code.min !== code.code.max">
                                        CPT Code Max: <span class="font-weight-bold">{{code.code.max}}</span>
                                    </v-col>
                                    <v-col cols="12" lg="3" v-if="code.code.min === code.code.max">
                                        CPT Code: <span class="font-weight-bold">{{code.code.max}}</span>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                        Description: <span class="font-weight-bold">{{code.desc}}</span>
                                    </v-col>
                                    <v-col cols="12" lg="3" v-if="code.days.start && code.days.end">
                                        Days: <span class="font-weight-bold">{{code.days.start}} - {{code.days.end}}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3">
                                        Referral Needed: <span class="font-weight-bold">{{code.refRequired}}</span>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                        Authorization Needed: <span class="font-weight-bold">{{code.authRequired}}</span>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                        Copay: <span class="font-weight-bold">{{code.copay}}</span>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                        Max Benefit: <span class="font-weight-bold">{{code.maxBenefit}}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-data-iterator>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import AddCPT from "./AddCPT.vue"
export default {
    components: {
        AddCPT
    },
    data() {
        return {
            addPlanDialog: false,
            // cptCodes: [],
            itemsPerPage: 5,
            formData: {
                planName: "",
                premium: "",
                deductible: "",
                maxOutOfPocket: "",
                otherPlanDeductibles: null,
                choiceOfProviders: "",
                optionalSupplementalBenefits: null,
                rxDrugCoverage: null,
                additionalBenefits: null,
                cptCodes: [],
                emergencyRoomCopay: "",
                urgentCareCopay: "",
            },
            coverageOptions: [
                { text: "1 exam every 3 months", value: "1 exam every 3 months"},
                { text: "1 exam every 6 months", value: "1 exam every 6 months"},
                { text: "1 exam every year", value: "1 exam every year"},
                { text: "1 exam every 18 months", value: "1 exam every 18 months"}
            ]
        }
    },
    methods: {
        addPlan() {
            console.log("Plan", this.formData)
            axios
                .post('/api/plans/newPlan', this.formData)
                .then(() => {
                    this.addPlanDialog = false
                    this.$emit("planAdded")
                })
                .catch((err) => {
                    console.log(err);
                    console.log("Couldn't add plan.");
                });
        },
        newCode(code) {
            this.formData.cptCodes.unshift(code)
        },
    },
    watch: {
        addPlanDialog() {
            this.formData = {
                planName: "",
                premium: "",
                deductible: "",
                maxOutOfPocket: "",
                otherPlanDeductibles: null,
                choiceOfProviders: "",
                optionalSupplementalBenefits: null,
                rxDrugCoverage: null,
                additionalBenefits: null,
                cptCodes: [],
                emergencyRoomCopay: "",
                urgentCareCopay: "",
            }
        }
    }
}
</script>

<style scoped>

</style>