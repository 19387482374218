<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-row>
            <v-col>
              <v-card-title>
                Pending Enrollments
                <template v-if="user.role === 'fmo'">
                  <v-spacer />
                  <v-switch
                    v-model="showFmoMembers"
                    label="Show FMO Agents Members"
                  />
                </template>
              </v-card-title>
            </v-col>
            <v-col class="px-7" cols="12" lg="5">
              <v-select
                :items="planOptions"
                v-model="planFilter"
                @change="filterPendingPatients()"
              />
            </v-col>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col class="px-6">
                <v-text-field
                  class="py-0"
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="filteredMembers"
              :search="search"
              :sort-by="['created']"
              :sort-desc="true"
              multi-sort
              loading-text="Loading data..."
              class="elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.Full_Name="{ item }">
                {{ item.Full_Name ? item.Full_Name : item.name }}
              </template>
              <template v-slot:item.DETAILS="{ item }">
                <enrollment-modal
                  :enrollee="item"
                  :user="user"
                  @documentSaved="getUser"
                ></enrollment-modal>
              </template>

              <template v-slot:item.agentID="{ item }">
                {{ item.agentID ? item.agentID : "Unassigned" }}
              </template>
              <template v-slot:item.agentPassDate="{ item }">
                <tr>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-show="checkAgent(item)"
                          medium
                          dense
                          color="red darken-2"
                          class="mr-1"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span>Agent has not passed 2023 exam</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>

              <template v-slot:item.enrollmentStatus="{ item }">
                <enrollment-status-modal
                  :view="'Pending'"
                  :patient="item"
                  @closeEnrollmentStatusModal="closeEnrollmentStatusModal"
                ></enrollment-status-modal>
              </template>

              <template v-slot:item.agent="{ item }">
                <span v-if="item.agent">{{ item.agent }}</span>
                <span v-else>{{ item.tempAgent }}</span>
              </template>

              <template v-slot:item.created="{ item }">
                {{ item.created.slice(0, 10) }}
              </template>

              <template slot="footer">
                <ExportCSV :data="this.pendingData" :name="'enrollments.csv'">
                  <v-btn color="accent" text class="export-button">
                    Export to CSV
                  </v-btn>
                </ExportCSV>
              </template>
            </v-data-table>
            <v-snackbar v-model="snackInfo" :timeout="2000" color="success">
              Patient successfully updated.
              <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
              </template>
            </v-snackbar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EnrollmentModal from "../components/EnrollmentModal";
import EnrollmentStatusModal from "../components/EnrollmentStatusModal";
import ExportCSV from "vue-json-csv";
import axios from "axios";

export default {
  name: "Pending",
  components: {
    EnrollmentModal,
    ExportCSV,
    EnrollmentStatusModal,
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      headers: [
        { text: "Name", value: "Full_Name" },
        { text: "Plan", value: "selectedPlan", width: "20%" },
        { text: "PCP", value: "selectedPCP" },
        { text: "Application Submission Date", value: "created", width: "20%" },
        {
          text: "Enrollment Status",
          value: "enrollmentStatus",
          width: "120px",
        },
        { text: "Effective Date", value: "effectiveDateOfCoverage" },
        { text: "Agent ID", value: "agentID" },
        { text: "Agent Name", value: "agent" },
        { text: "Exam Pass Date", value: "agentPassDate" },
        { text: "Enrollment Details", value: "DETAILS" },
      ],
      pendingData: [],
      snackInfo: false,
      rules: {
        memberID: [(val) => (val || "").length > 0 || "This field is required"],
      },
      enrollmentStatus: "",
      patient: "",
      memberID: "",
      planOptions: [
        "All",
        "H1748-001 Complete",
        "H1748-003 Diabetes",
        "H1748-004 Heart",
        "H1748-005 Dual",
      ],
      planFilter: "All",
      filteredMembers: [],
      fmoAgents: [],
      showFmoMembers: false,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    closeEnrollmentStatusModal(value) {
      if (value) {
        this.getPendingPatients();
      }
    },
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          } else {
            if (this.user.role === "agent" || this.user.role === "fmo") {
              this.headers = [
                { text: "Name", value: "Full_Name" },
                { text: "Plan", value: "selectedPlan" },
                { text: "PCP", value: "selectedPCP" },
                { text: "Enrollment Status", value: "enrollmentStatus" },
                { text: "Effective Date", value: "effectiveDateOfCoverage" },
                { text: "Agent ID", value: "agentID" },
                { text: "Exam Flag", value: "agentPassDate" },
                { text: "Enrollment Details", value: "DETAILS" },
              ];
            } else if (this.user.role === "member services") {
              this.headers = [
                { text: "Name", value: "Full_Name" },
                { text: "Plan", value: "selectedPlan" },
                { text: "PCP", value: "selectedPCP" },
                { text: "Agent ID", value: "agentID" },
                { text: "Exam Pass Date", value: "agentPassDate" },
                { text: "Enrollment Details", value: "DETAILS" },
              ];
            }
            this.getPendingPatients();
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async getPendingPatients() {
      this.pendingData = [];
      this.load = true;

      let requestURL;
      if (
        this.user.role === "agent" ||
        (this.user.role === "fmo" && !this.showFmoMembers)
      ) {
        requestURL = `api/pending/${this.user.userID}`;
      } else if (
        this.user.role === "superuser" ||
        this.user.role === "manager" ||
        this.user.role === "member services"
      ) {
        requestURL = `api/pending/all`;
      } else if (this.user.role === "fmo" && this.showFmoMembers) {
        this.fmoAgents = [];
        await axios
          .get(`api/fmo/agents/${this.user.fmoNpn}`)
          .then((res) => {
            this.fmoAgents = res.data;
          })
          .then(() => {
            this.fmoAgents.forEach((agentID) => {
              axios.get(`api/pending/${agentID}`).then((res) => {
                if (res.data[0]) {
                  this.pendingData.push(res.data[0]);
                }
              });
            });
          })
          .finally(() => {
            this.filterPendingPatients();
            this.csvData = JSON.parse(JSON.stringify(this.pendingData));
            this.csvData.forEach((user) => {
              delete user._id;
            });
            this.load = false;
          });
      }

      if (!this.showFmoMembers) {
        axios.get(requestURL).then((res) => {
          this.pendingData = res.data;
          // Filter patients based on plan
          this.filterPendingPatients();
          this.load = false;
        });
      }
    },

    filterPendingPatients() {
      if (this.planFilter === "All") {
        this.filteredMembers = this.pendingData;
      } else {
        this.filteredMembers = [];
        this.pendingData.forEach((pending) => {
          if (
            pending.selectedPlan &&
            pending.selectedPlan.includes(this.planFilter.substr(0, 9))
          ) {
            this.filteredMembers.push(pending);
          }
        });
      }
    },
    checkAgent(item) {
      if (item.agentID !== "Unassigned" && item.agentPassDate.length > 0) {
        return new Date(item.agentPassDate[0]) < new Date("09/28/2022");
      } else if (
        item.agentID !== "Unassigned" &&
        item.agentPassDate.length === 0
      ) {
        return true;
      }
    },
  },
  watch: {
    showFmoMembers() {
      this.getPendingPatients();
    },
    user() {
      if (this.user.role === "fmo") {
        this.showFmoMembers = true;
      }
    },
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
