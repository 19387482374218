<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12" lg="8">
          <v-select
            v-model="conditions"
            :items="conditionArray"
            multiple
            outlined
            dense
            label="What Medical conditions do you have, or have you had in the
            past?"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="conditionOther"
            label="If other, what type?"
            outlined
            dense
            required
            :rules="[(!/[^a-zA-Z]/.test(this.conditionOther)) || 'Invalid entry, no special characters or numbers']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="8">
          <v-select
            v-model="treatments"
            :items="treamentArray"
            multiple
            outlined
            dense
            label="Which of the following conditions are you currently receiving
            treatment for?"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            v-model="treatmentOther"
            label="If other, what type?"
            outlined
            dense
            required
            :rules="[(!/[^a-zA-Z]/.test(this.treatmentOther)) || 'Invalid entry, no special characters or numbers']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="surgicalHistory.length > 0">
        <v-col cols="6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Surgery</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in surgicalHistory" :key="index">
                  <td>{{ item.surgeryName }}</td>
                  <td>{{ item.date }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="float-left">
          <surgical-history-button @surgery="addSurgery"></surgical-history-button>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import SurgicalHistoryButton from "./SurgicalHistoryButton.vue"
export default {
  components: {
    SurgicalHistoryButton,
  },
  data: () => ({
    valid: true,
    conditionArray: [
      "Anxiety",
      "Asthma",
      "Bi-polar Disorder",
      "Blood Disorder",
      "Cancer",
      "COPD/Emphysema",
      "Coronary Artery Disease",
      "Dementia/Alzheimer's",
      "Depression",
      "Diabetes (Type 1)",
      "Diabetes (Type 2)",
      "GERD/Stomach Issues",
      "Hearing Problems",
      "Heart Failure (CHF)",
      "Hypertension",
      "Organ Transplant",
      "Osteoarthritis(OA)",
      "Osteoporosis",
      "Peripheral Vascular Disease (PVD)",
      "Renal/Kiden Failure",
      "Rheumatoid Arthritis (RA)",
      "Seizures",
      "Schizophrenia",
      "Skin Issues/Wound",
      "Stroke",
      "Vision Problems",
      "Other"
    ],
    treamentArray: [
      "Anxiety/Depression",
      "Arthritis",
      "Asthma",
      "Bi-Polar/Schizophrenia",
      "Cancer",
      "COPD/Emphysema",
      "Coronary Artery Disease",
      "Dementia/Alzheimer's",
      "Diabetes",
      "Heart Failure (CHF)",
      "Hypertension",
      "Renal/Kidney Failure",
      "Stroke",
      "Other"
    ],
    conditions: [],
    conditionOther: "",
    treatments: [],
    treatmentOther: "",
    surgicalHistory: []
  }),
  methods: {
    addSurgery(surgery) {
      this.surgicalHistory.push(surgery)
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          conditions: this.conditions,
          conditionOther: this.conditionOther,
          treatments: this.treatments,
          treatmentOther: this.treatmentOther,
          surgicalHistory: this.surgicalHistory
        });

        this.$emit('selectedTab', 3)
      }
    },    
  }
};
</script>
