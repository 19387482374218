<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Enrolled HRA Patients
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-text-field
                class="py-0"
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                single-line
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-data-table
              :loading="load"
              :headers="headers"
              :items="enrollmentData"
              :search="search"
              multi-sort
              loading-text="Loading data..."
              class="glassTable elevation-0 col-md-12 m-auto"
            >
              <template v-slot:item.DETAILS="{ item }">
                <HRAEnrollmentModal
                  :memberID="item._id"
                  @recordUpdated="reloadAfterSaving"
                />
              </template>

              <template slot="footer">
                <ExportCSV
                  :data="this.enrollmentData"
                  :name="'enrollments.csv'"
                >
                  <v-btn color="accent" text class="export-button">
                    Export to CSV
                  </v-btn>
                </ExportCSV>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import HRAEnrollmentModal from "../components/HRAEnrollmentModal";
import ExportCSV from "vue-json-csv";

export default {
  name: "Enrollments",
  components: {
    HRAEnrollmentModal,
    ExportCSV
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Member ID", value: "_id" },
        { text: "Address", value: "address" },
        { text: "Email", value: "email" },
        { text: "Enrollment Details", value: "DETAILS" },
        { value: "fullName", align: ' d-none' } // d-none needs a leading space to function
      ],
      enrollmentData: []
    };
  },
  methods: {
    getUser() {
      axios.get("/api/user").then(response => {
        this.user = response.data[0];
        // check role and reroute if unauthorized
        if (
          this.user.role === "agent" ||
          this.user.role === "fmo"
        ) {
          this.$router.push("/");
        } else if (this.user.role === "credentialing") {
          this.$router.push("/credentialing");
        } else {
          this.getEnrolledPatients()
        }
      }).catch(() => {
        this.$router.push("/login");
      });
    },
    async getEnrolledPatients() {
      this.enrollmentData = [];
      this.load = true;

      let requestURL;
      if (this.user.role === "agent" || this.user.role === "hra") {
        requestURL = `api/hra/enrollments/${this.user.userID}`;
      } else if (
        this.user.role === "superuser" ||
        this.user.role === "manager"
      ) {
        requestURL = `api/hra/enrollments/all`;
      }

      axios.get(requestURL).then(res => {
        this.enrollmentData = res.data;
        this.load = false;
      });
    },
    reloadAfterSaving(pleaseReloadMe) {
      if (pleaseReloadMe) this.getEnrolledPatients();
    },
    formatCurrency(c) {
      if (c != undefined && c != "")
        return "$" + c.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  },
  mounted() {
    this.getUser();
  }
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}
</style>
