<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="!formComplete" flat>
          <v-card-title>
            New Scope of Appointment
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="formData.agentName"
                    label="Agent Name"
                    outlined
                    dense
                    required
                    :rules="[
                      this.formData.agentName != '' || 'Please enter your name',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="formData.agentPhone"
                    label="Agent Phone"
                    outlined
                    dense
                    required
                    @input="formatPhoneNum()"
                    :rules="[
                      this.formData.agentPhone != '' ||
                        'Please enter your phone number',
                      /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/.test(
                        this.formData.agentPhone
                      ) || 'Please enter an actual phone number',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.beneficiaryName"
                    label="Beneficiary Name"
                    outlined
                    dense
                    required
                    :rules="[
                      this.formData.beneficiaryName != '' ||
                        'Please enter your Beneficiaries Name',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.ssn"
                    label="Last 4 Digits of Beneficiary SSN"
                    outlined
                    dense
                    required
                    :rules="[
                      formData.ssn.length == 4 || 'Social needs to be 4 digits',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.beneficiaryPhone"
                    label="Beneficiary Phone (optional)"
                    outlined
                    dense
                    @input="formatMemberPhoneNum()"
                    :rules="[
                      /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/.test(
                        this.formData.agentPhone
                      ) || 'Please enter an actual phone number',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.beneficiaryAddress"
                    label="Beneficiary Address (optional)"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="subtitle-1">
                    Check the product type(s) you want to discuss with the
                    patient (required):
                  </div>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[0]"
                    label="Medicare Advantage MAP D Plan (HMO)"
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[1]"
                    label="Chronic Special Needs Program - Diabetic"
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[2]"
                    label="Chronic Special Needs Program - Cardiovascular"
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="formData.agentPlans[3]"
                    label="Dual Special Needs Plan (D-SNP)"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.dateApptCompleted"
                        label="Date of Appointment"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        @change="fieldChanged()"
                        :rules="[
                          formData.dateApptCompleted != '' ||
                            'Please enter the date',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="formData.dateApptCompleted"
                      min="1900-01-01"
                    >
                      <v-spacer></v-spacer>
                      <v-btn outlined color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary"
                        @click="$refs.menu1.save(formData.dateApptCompleted)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.initialMethodOfContact"
                    label="Initial Method of Contact"
                    outlined
                    dense
                    required
                    :rules="[
                      this.formData.initialMethodOfContact != '' ||
                        'Please enter the initial method of contact',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    v-model="formData.agentNPN"
                    label="Agent NPN"
                    outlined
                    dense
                    required
                    :rules="[
                      this.formData.agentNPN != '' || 'Please enter the NPN',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="subtitle-1">
                    <strong>Agents Only: </strong>Please Sign in the Box Below
                  </div>
                  <v-alert dense outlined type="error" v-model="alert">
                    Please sign your name below to continue
                  </v-alert>
                  <Signature ref="Signature" />
                </v-col>
                <v-col cols="12" :md="showEmail ? 6 : 12">
                  <v-btn
                    @click="showEmail = !showEmail"
                    color="warning"
                    block
                    dense
                    depressed
                    >Member Signing in Person</v-btn
                  >
                </v-col>
                <template v-if="!showEmail">
                  <v-row>
                    <v-col cols="12">
                      <div class="subtitle-1">
                        Members Please Sign in the Box Below
                      </div>
                      <v-alert dense outlined type="error" v-model="alert">
                        Member Signature
                      </v-alert>
                      <Signature ref="memberSignature" />
                    </v-col>
                    <div class="subtitle-1">
                      If you are the authorized representative, please sign
                      above and print clearly and legibly below:
                    </div>
                    <v-col cols="6">
                      <v-text-field
                        label="Name (First and Last)"
                        v-model="formData.repName"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="formData.repRelationship"
                        label="Relationship"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="beneficiaryEmail"
                    label="Beneficiary Email"
                    :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                      (v) => !/\s/.test(v) || 'Please remove spaces from email',
                    ]"
                    outlined
                    dense
                    v-if="showEmail"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-5">
                <v-col cols="12">
                  <v-btn
                    depressed
                    x-large
                    block
                    :disabled="!valid"
                    color="accent"
                    @click="validate"
                    :loading="load"
                    class="py-0"
                    height="40px"
                  >
                    Send Form
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card v-else class="pa-5 text-center" flat>
          <v-icon color="success" class="display-2">
            mdi-check
          </v-icon>
          <v-card-text class="headline">
            Form sent successfully!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackError" :timeout="3000" color="error">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import Signature from "../components/new-enrollment-components/Signature.vue";
export default {
  name: "NewSOA",
  components: {
    Signature,
  },
  data() {
    return {
      load: false,
      search: "",
      user: "",
      snackError: false,
      snackMessage: "",
      valid: false,
      formComplete: false,
      alert: false,
      options: {
        penColor: "#343434",
        minWidth: 2.5,
        maxWidth: 4.5,
      },
      menu1: false,
      beneficiaryEmail: "",
      formData: {
        agentName: "",
        agentPhone: "",
        beneficiaryName: "",
        beneficiaryPhone: "",
        beneficiaryAddress: "",
        agentPlans: [false, false, false, false],
        dateApptCompleted: "",
        initialMethodOfContact: "",
        agentSignature: "",
        memberSignature: "",
        agentNPN: "",
        ssn: "",
        repName: "",
        repRelationship: "",
      },
      showEmail: true,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (!this.showEmail) {
          this.formData.memberSignature = await this.$refs.memberSignature.submit();
        }
        this.formData.agentSignature = await this.$refs.Signature.submit();
        if (this.formData.agentSignature && this.formData.memberSignature) {
          this.processPDF();
        } else {
          this.alert = true;
        }
      }
    },
    processPDF() {
      this.load = true;

      if (this.showEmail) {
        axios
          .post("/api/soa/processPdf", {
            formData: this.formData,
            email: this.beneficiaryEmail,
            userID: this.user.userID,
          })
          .then(() => {
            this.formComplete = true;
          })
          .catch((error) => {
            this.load = false;

            // display snackbar notification
            this.snackError = true;

            if (error.response.status == 409) {
              this.snackMessage = "A form already exists for this patient.";
            } else {
              this.snackMessage = "Error sending the form.";
            }
          });
      } else {
        axios
          .post("/api/soa/processPdf", {
            formData: this.formData,
            userID: this.user.userID,
          })
          .then(() => {
            this.formComplete = true;
          })
          .catch((error) => {
            this.load = false;

            // display snackbar notification
            this.snackError = true;

            if (error.response.status == 409) {
              this.snackMessage = "A form already exists for this patient.";
            } else {
              this.snackMessage = "Error sending the form.";
            }
          });
      }
    },
    formatPhoneNum() {
      let x = this.formData.agentPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.formData.agentPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    formatMemberPhoneNum() {
      let x = this.formData.beneficiaryPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.formData.beneficiaryPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>

<style>
#signature {
  border: 1px solid #888;
  border-radius: 5px;
}
</style>
