<template>
  <div>
    <v-select
      v-model="patient.enrollmentStatus"
      :items="getStatusSelections"
      :label="patient.enrollmentStatus"
      @change="openModal(patient)"
      single-line
      outlined
      dense
      class="mt-5"
      :key="key"
    ></v-select>
    <v-dialog v-model="statusDialog" persistent max-width="900px">
      <v-card>
        <v-toolbar dark color="primary" class="mx-auto"
          >Please Confirm</v-toolbar
        >
        <div>
          <div v-if="enrollmentStatus === 'Enrollment'">
            <v-card-title>
              <span class="text-h5">Enroll</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="enrollmentForm" lazy-validation>
                Please enter a MemberID for the enrolled patient
                <v-col cols="12">
                  <v-text-field
                    v-model="memberID"
                    required
                    label="Member ID"
                    :rules="rules.memberID"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="enrollmentDateMenu"
                    ref="enrollmentDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="effectiveDateOfCoverage"
                        label="Effective Date"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="effectiveDateOfCoverage"
                      show-current="false"
                      :close-on-content-click="true"
                      no-title
                      scrollable
                      @input="enrollmentDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="validate()">Add</v-btn>
              <v-btn color="error" text @click="closeModal()">Cancel</v-btn>
            </v-card-actions>
          </div>
          <div v-if="enrollmentStatus === 'Disenrollment'">
            <v-card-title>
              <span class="text-h5">Disenroll</span>
            </v-card-title>
            <v-card-text>
              <v-alert outlined type="warning"
                >To set a future disenrollment date, please visit member's
                summary page.</v-alert
              >
              <v-form ref="disenrollmentForm">
                <v-col cols="12">
                  <v-select
                    label="Please select a reason for disenrollment"
                    outlined
                    dense
                    required
                    :rules="[
                      reasonChoice.length > 0 ||
                        'Please select a reason for disenrolling this member.',
                    ]"
                    v-model="reasonChoice"
                    :items="disenrollmentReason"
                  />
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="disenrollmentDateMenu"
                    ref="disenrollmentDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="disenrollmentDate"
                        label="Disenrollment Date"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="disenrollmentDate"
                      show-current="false"
                      :close-on-content-click="true"
                      no-title
                      :max="new Date().toISOString().substr(0, 10)"
                      scrollable
                      @input="disenrollmentDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="error" text @click="closeModal()">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="validate()">
                Disenroll
              </v-btn>
            </v-card-actions>
          </div>
          <div v-if="enrollmentStatus === 'Not Eligible'">
            <v-card-text>
              Are you sure you want to move this patient to Not Eligible?
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="changeStatus()">
                Yes
              </v-btn>
              <v-btn color="error" text @click="closeModal()">
                No
              </v-btn>
            </v-card-actions>
          </div>
          <div v-if="enrollmentStatus === 'Pending'">
            <v-card-text>
              Are you sure you want to move this patient to Pending?
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="changeStatus()">
                Yes
              </v-btn>
              <v-btn color="error" text @click="closeModal()">
                No
              </v-btn>
            </v-card-actions>
          </div>
        </div>
        <!-- <div v-if="view === 'Pending'">
                <div v-if="enrollmentStatus === 'Enrollment'">
                    <v-form ref="enrollmentForm" lazy-validation>
                        <v-card-text>
                            Please enter a MemberID for the enrolled
                            patient
                        </v-card-text>
                        <v-text-field
                            v-model="memberID"
                            required
                            label="Member ID"
                            outlined
                            dense
                            class="mx-6"
                        >
                        </v-text-field>
                        <v-card-actions>
                            <v-btn color="primary" text @click="validate()"
                            >Add</v-btn
                            >
                            <v-btn color="error" text @click="closeModal()"
                            >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-form>
                </div>
                <div v-if="enrollmentStatus === 'Not Eligible'">
                    <v-card-text>
                        Are you sure you want to move this patient to Not Eligible?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="changeStatus()"
                        >
                          Yes
                        </v-btn>
                        <v-btn color="error" text @click="closeModal()">
                          No
                        </v-btn>
                    </v-card-actions>
                </div>
            </div>
            <div v-if="view === 'Not Eligible'">
                <div v-if="enrollmentStatus === 'Enrollment'">
                    <v-form ref="enrollmentForm" lazy-validation>
                        <v-card-text>
                            Please enter a MemberID for the enrolled
                            patient
                        </v-card-text>
                        <v-text-field
                            v-model="memberID"
                            required
                            label="Member ID"
                            :rules="rules.memberID"
                            outlined
                            dense
                            class="mx-6"
                        >
                        </v-text-field>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="validate()"
                            >Add</v-btn
                            >
                            <v-btn color="error" text @click="closeModal()"
                            >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-form>
                </div>
                <div v-if="enrollmentStatus === 'Pending'">
                    <v-card-text>
                        Are you sure you want to move this patient to Pending?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="changeStatus()"
                        >
                          Yes
                        </v-btn>
                        <v-btn color="error" text @click="closeModal()">
                          No
                        </v-btn>
                    </v-card-actions>
                </div>
            </div>
            <div v-if="view === 'Disenrollment'">
                <div v-if="enrollmentStatus === 'Enrollment'">
                    <v-form ref="enrollmentForm" lazy-validation>
                        <v-card-text
                            >Please enter a MemberID for the enrolled
                            patient</v-card-text
                        >
                        <v-text-field
                            v-model="memberID"
                            required
                            label="Member ID"
                            :rules="rules.memberID"
                            outlined
                            dense
                            class="mx-6"
                        >
                        </v-text-field>
                        <v-card-actions>
                            <v-btn color="primary" text @click="validate()"
                            >Add</v-btn
                            >
                            <v-btn color="error" text @click="closeModal()"
                            >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-form>
                </div>
                <div v-if="enrollmentStatus === 'Pending'">
                    <v-card-text>
                        Are you sure you want to move this patient to Pending?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="changeStatus()"
                        >
                          Yes
                        </v-btn>
                        <v-btn color="error" text @click="closeModal()">
                          No
                        </v-btn>
                    </v-card-actions>
                </div>
                <div v-if="enrollmentStatus === 'Not Eligible'">
                    <v-card-text>
                        Are you sure you want to move this patient to Not Eligible?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="changeStatus()"
                        >
                          Yes
                        </v-btn>
                        <v-btn color="error" text @click="closeModal()">
                          No
                        </v-btn>
                    </v-card-actions>
                </div>
            </div> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "EnrollmentStatusModal",
  props: ["view", "patient"],
  data() {
    return {
      reasonChoice: "",
      key: 0,
      disenrollmentDateMenu: false,
      enrollmentDateMenu: false,
      effectiveDateOfCoverage: new Date().toISOString().substr(0, 10),
      disenrollmentDate: new Date().toISOString().substr(0, 10),
      disenrollmentReason: [
        "Voluntary Withdrew",
        "Switched to alternate plan",
        "Moved out of service area",
        "No longer qualifies",
        "Deceased",
        "Incomplete Enrollment",
        "Doctor not within network",
        "Prescriptions not within Tier",
        "Going directly with Medicare",
        "Going directly with another carrier",
      ],
      statusDialog: false,
      enrollmentStatus: "",
      memberID: "",
      rules: {
        memberID: [(val) => (val || "").length > 0 || "This field is required"],
      },
      patientID: "",
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeEnrollmentStatusModal", true)
      this.statusDialog = false
      this.updateAfterChange()
    },
    validate() {
      if (this.enrollmentStatus === "Enrollment") {
        if (this.$refs.enrollmentForm.validate()) {
          this.changeStatus()
        }
      }
      if (this.enrollmentStatus === "Disenrollment") {
        if (this.$refs.disenrollmentForm.validate()) {
          this.disenroll()
        }
      }
    },
    updateAfterChange() {
      this.key++
    },
    openModal(patient) {
      this.statusDialog = true
      this.enrollmentStatus = patient.enrollmentStatus
      this.patientID = patient.medicareNumber
    },
    changeStatus() {
      if (this.enrollmentStatus === "Enrollment") {
        this.patient.disenrollmentDate = null
        this.patient.effectiveDateOfCoverage = this.effectiveDateOfCoverage
        this.patient.enrollmentDate = this.effectiveDateOfCoverage
      }
      this.patient.memberID = this.memberID
      axios
        .post(`/api/enrollments/update/${this.patient._id}`, this.patient)
        .then(() => {
          this.snackInfo = true
          this.closeModal()
        })
    },
    getMemberId() {
      axios
        .get(`/api/modals/enrollment/patient/${this.patient._id}`)
        .then((res) => {
          this.memberID = res.data[0].memberID
        })
    },
    async disenroll() {
      let disenrolee = {
        patientID: this.patientID,
        enrollmentStatus: "Disenrollment",
        disenrollmentDate: this.disenrollmentDate,
        disenrollmentReason: this.reasonChoice,
      }
      await axios
        .post("/api/enrollments/type/save", disenrolee)
        .then((response) => {
          if (response.status === 200) {
            this.reasonChoice = ""
            this.$emit("disenrolledPatient", this.patientID)
            this.closeModal()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  computed: {
    getStatusSelections() {
      if (this.view === "Enrollment") {
        return ["Disenrollment", "Not Eligible", "Pending"]
      } else if (this.view === "Pending") {
        return ["Enrollment", "Not Eligible"]
      } else if (this.view === "Not Eligible") {
        return ["Enrollment", "Pending"]
      } else {
        return ["Enrollment", "Pending", "Not Eligible"]
      }
    },
  },
  watch: {
    statusDialog() {
      if (this.statusDialog) {
        this.getMemberId()
      }
    },
  },
}
</script>

<style scoped>
.col {
  padding: 0;
}
</style>
