<template>
  <v-card flat>
    <v-card outlined class="ma-4 pa-2">
        <v-row>
            <v-col cols="12" md="9">
                <v-card-title>Rates For Internal Agents</v-card-title>
            </v-col>
            <v-col cols="12" md="3">
                <v-btn @click="updateRatesDialog()" block dark color="accent" class="mt-3">
                    <v-icon>mdi-pencil</v-icon>
                    Update Internal Rates
                </v-btn>
            </v-col>
        </v-row>
        <v-card-text>
            <v-row>
                <v-col cols="3">New Enrollments</v-col>
                <v-col cols="9">${{ rateData.newEnrollmentRate }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="3">Renewal</v-col>
                <v-col cols="9">${{ rateData.renewalRate }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="3">Plan To Plan</v-col>
                <v-col cols="9">${{ rateData.planToPlanRate }}</v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <v-card outlined class="ma-4">
        <v-card-title>Change History</v-card-title>
        <v-data-table
            :loading="load"
            :headers="headers"
            :items="changeData"
            multi-sort
            loading-text="Loading data..."
            class="elevation-0 col-md-12 m-auto"
        >
            <template v-slot:item.date="{ item }">
                <span>{{ item.date.toString().slice(0, 10) }}</span>
            </template>
            <template v-slot:item.newEnrollment="{ item }">
                <span v-if="item.prevRates.newEnrollmentRate === item.newRates.newEnrollmentRate"><v-icon color="error">mdi-close</v-icon></span>
                <span v-else><v-icon color="success">mdi-check</v-icon></span>
            </template>
            <template v-slot:item.renewal="{ item }">
                <span v-if="item.prevRates.renewalRate === item.newRates.renewalRate"><v-icon color="error">mdi-close</v-icon></span>
                <span v-else><v-icon color="success">mdi-check</v-icon></span>
            </template>
            <template v-slot:item.planToPlan="{ item }">
                <span v-if="item.prevRates.planToPlanRate === item.newRates.planToPlanRate"><v-icon color="error">mdi-close</v-icon></span>
                <span v-else><v-icon color="success">mdi-check</v-icon></span>
            </template>
            <template v-slot:item.details="{ item }">
                <v-icon @click="openDetailsDialog(item)" color="error">mdi-dots-horizontal</v-icon>
            </template>
        </v-data-table>
    </v-card>

    <v-dialog v-model="detailsDialog" max-width="900px">
        <v-card>
            <v-toolbar
                dark
                color="primary"
                class="mx-auto mb-3"
                >
                <v-card-title color="primary">
                    Rate History
                </v-card-title>

                <v-spacer></v-spacer>

                <v-btn icon dark>
                    <v-icon @click="closeDetailsDialog()">
                    mdi-close
                    </v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title v-if="detailsDialogObj">Changes Made On {{ detailsDialogObj.date.slice(0, 10) }}</v-card-title>
            <v-simple-table class="pa-3 pb-8">
                <thead>
                    <tr>
                        <th>Rate Type</th>
                        <th>Previous Rate</th>
                        <th>New Rate</th>
                    </tr>
                </thead>
                <tbody v-if="detailsDialogObj">
                    <tr v-if="detailsDialogObj.prevRates.newEnrollmentRate !== detailsDialogObj.newRates.newEnrollmentRate">
                        <td>New Enrollment Rate</td>
                        <td>{{detailsDialogObj.prevRates.newEnrollmentRate}}</td>
                        <td>{{detailsDialogObj.newRates.newEnrollmentRate}}</td>
                    </tr>
                    <tr v-if="detailsDialogObj.prevRates.renewalRate !== detailsDialogObj.newRates.renewalRate">
                        <td>Renewal Rate</td>
                        <td>{{detailsDialogObj.prevRates.renewalRate}}</td>
                        <td>{{detailsDialogObj.newRates.renewalRate}}</td>
                    </tr>
                    <tr v-if="detailsDialogObj.prevRates.planToPlanRate !== detailsDialogObj.newRates.planToPlanRate">
                        <td>Plan To Pan Rate</td>
                        <td>{{detailsDialogObj.prevRates.planToPlanRate}}</td>
                        <td>{{detailsDialogObj.newRates.planToPlanRate}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </v-dialog>

    <v-dialog v-model="rateDialog" max-width="750px">
        <template>
            <v-form ref="ratesForm" v-model="rateValid" lazy-validation>
                <v-card>
                <v-toolbar
                    dark
                    color="primary"
                    class="mx-auto mb-3"
                    >
                    <v-card-title color="primary">
                        Update Rates
                    </v-card-title>

                    <v-spacer></v-spacer>

                    <v-btn icon dark>
                        <v-icon @click="rateDialog = false">
                        mdi-close
                        </v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                    <v-col cols="12">
                        <v-text-field
                        v-model="updateRates.newEnrollmentRate"
                        :rules="[(v) => !!v || 'Rate is required']"
                        label="New Enrollment Rate"
                        required
                        outlined
                        dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                        v-model="updateRates.renewalRate"
                        :rules="[(v) => !!v || 'Rate is required']"
                        label="Renewal Rate"
                        required
                        outlined
                        dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                        v-model="updateRates.planToPlanRate"
                        :rules="[(v) => !!v || 'Rate is required']"
                        label="Plan To Plan Rate"
                        required
                        outlined
                        dense
                        ></v-text-field>
                    </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                    @click="cancelNewRate"
                    color="error" 
                    text
                    >
                    Cancel
                    </v-btn>
                    <v-btn
                    @click="validateNewRate"
                    :disabled="!rateValid"
                    color="success"
                    text
                    >
                    Save
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-form>
        </template>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "InternalRatesAdmin",
  components: {
  },
  data() {
    return {
      load: false,
      changeData: [],
      headers: [
        { text: "Date", value: "date" },
        { text: "New Enrollment Rate Updated", value: "newEnrollment" },
        { text: "Renewal Rate Updated", value: "renewal" },
        { text: "Plan To Plan Rate Updated", value: "planToPlan" },
        { text: "Details", value: "details" },
      ],
      updateRates: {
          newEnrollmentRate: "",
          renewalRate: "",
          planToPlanRate: ""
      },
      rateDialog: false,
      rateValid: false,
      rateData: [],
      detailsDialog: false,
      detailsDialogObj: null,
    };
  },
  methods: {
    async getInternalRates() {
      this.load = true;

      await axios.get("/api/internalrates/").then(res => {
        this.rateData = res.data[0]
        this.changeData = res.data[0].changes
        this.dataCopy = JSON.parse(JSON.stringify(res.data[0]))
        this.load = false;
      })
    },
    validateNewRate() {
      if(this.$refs.ratesForm.validate()) {
        // Set new rates
        this.rateData.newEnrollmentRate = this.updateRates.newEnrollmentRate
        this.rateData.renewalRate = this.updateRates.renewalRate
        this.rateData.planToPlanRate = this.updateRates.planToPlanRate
        // Make new obj to be added to changes array
        let newChange = {
            date: moment(),
            prevRates: {
                newEnrollmentRate: this.dataCopy.newEnrollmentRate,
                renewalRate: this.dataCopy.renewalRate,
                planToPlanRate: this.dataCopy.planToPlanRate,
            },
            newRates: {
                newEnrollmentRate: this.updateRates.newEnrollmentRate,
                renewalRate: this.updateRates.renewalRate,
                planToPlanRate: this.updateRates.planToPlanRate,
            }
        }
        this.rateData.changes.unshift(newChange)
        this.saveRates();
      }
    },
    async saveRates() {
      await axios.post("/api/internalrates/update", this.rateData).then(() => {
          this.rateDialog = false;
          this.getInternalRates();
      })
    },
    openDetailsDialog(item) {
        if (!this.detailsDialog) {
            this.detailsDialog = true
            this.detailsDialogObj = item
        }
    },
    closeDetailsDialog() {
        if (this.detailsDialog) {
            this.detailsDialog = false
        }
    },
    cancelNewRate() {
        this.rateData.newEnrollmentRate = this.dataCopy.newEnrollmentRate;
        this.rateData.renewalRate = this.dataCopy.renewalRate;
        this.rateData.planToPlanRate = this.dataCopy.planToPlanRate;
        this.rateDialog = false;
    },
    updateRatesDialog() {
        this.rateDialog = true
        this.updateRates.newEnrollmentRate = this.rateData.newEnrollmentRate
        this.updateRates.renewalRate = this.rateData.renewalRate
        this.updateRates.planToPlanRate = this.rateData.planToPlanRate
    }
  },
  mounted() {
    this.getInternalRates();
  },
};
</script>

<style scoped>
.export-button {
  position: absolute;
  left: 15px;
  bottom: 30px;
}

.v-card__title {
  word-break: break-word !important;
}
</style>