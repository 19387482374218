<template>
    <v-card tile id="capture" flat color="#fafafa" class="mx-2">
        <v-row v-if="loading">
            <v-col cols="12" class="text-center mt-6">
                <v-progress-circular
                color="secondary"
                indeterminate
                :size="50"
                :width="3"
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-if="loading === false">
            <v-col cols="6">
                <v-card-title>
                    <span v-if="isFacility">Facilities In Same Group</span>
                    <span v-else>Providers In Same Group</span>
                </v-card-title>
            </v-col>
            <v-col v-if="this.groups.length > 1" cols="6">
                <v-card-text class="error--text pl-0">*This provider belongs to multiple groups, select group NPIs below</v-card-text>
                <v-select v-model="selectedGroups" :items="groups" chips multiple @change="filterData()"></v-select>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    v-if="sharedData !== ''"
                    :loading="loading"
                    :headers="sharedHeaders"
                    :items="tableData"
                    multi-sort
                    loading-text="Loading data..."
                    class="elevation-0 col-md-12 m-auto"
                    :hide-default-footer="true"
                >
                    <template v-slot:item.details="{ item }">
                        <credentialing-modal 
                            :npiNumber="item._id"
                            :isFacility="isFacility"
                        >
                        </credentialing-modal>
                    </template>
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            class="pt-16 justify-end"
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :items-per-page-options="rowsPerPageItems"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import axios from "axios";
export default {
    props: ["groupNPI", "isFacility", "npi"],
    components: {
        CredentialingModal: () => import('../CredentialingModal')
    },
    data() {
        return {
            sharedData: "",
            sharedHeaders: [
                {text: 'Name', value:'name'}, 
                {text: 'Specialty', value:'specialty'}, 
                {text: 'Location', value:'location'}, 
                {text: 'Details', value: 'details'}
            ],
            loading: false,
            selectedGroups: [],
            groups: [],
            tableData: "",
            rowsPerPageItems: [],
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true
            axios.get(`/api/modals/credentialing/sharednpis/${this.groupNPI}/${this.isFacility}`)
            .then((res) => {
                let resData = res.data
                this.sharedData = []
                resData.forEach((obj) => {
                    // remove the current provider you are viewing from the sharedData table and build sharedData array
                    if (obj._id !== this.npi) {
                        this.sharedData.push(obj)
                    }
                    // create groups array for dropdown
                    this.groupNPI.forEach((group) => {
                        if (!this.groups.includes(group) && group !== null && group !== "") {
                            this.groups.push(group)
                            this.selectedGroups.push(group)
                        }
                    })
                });
                this.tableData = this.sharedData
                this.filterData()
                this.loading = false
            });
        },
        filterData() {
            this.tableData = []
            // Table names is here to prevent us from adding the same provider twice to the tableData when they exist in multiple groups with the current provider/facility 
            let tableNames = []
            this.sharedData.forEach((obj) => {
                // groupNPI will be an array longer than 1 when the provider/facility modal we are in is part of multiple groups
                obj.groupNPI.forEach((num) => {
                    // If the objects group numbers match the selected group numbers
                    if (this.selectedGroups.includes(num)) {
                        // If we haven't already added the provider to the table
                        if ((!tableNames.includes(obj.name))) {
                            tableNames.push(obj.name)
                            this.tableData.push(obj)
                        }
                    }
                })
            })
        }
    },
}
</script>

<style scoped>

</style>