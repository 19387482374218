<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col class="mt-4">
        <h1 class="display-2 mb-3">Sonder Health Plans Enrollment</h1>
        <p class="subheading">
          Please fill out the following form in order to complete enrollment
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer></v-spacer>
        <v-tabs v-model="tab" background-color="primary" dark show-arrows class="px-3">
          <v-tab
            v-for="(item, index) in items"
            :key="item"
            :disabled="disableTabs(index)"
            >{{ item }}</v-tab
          >
          <v-tab-item>
            <v-card
              ><Plans @selectedPlan="selectPlan" @selectedTab="selectedTab"
            /></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              ><Medicare
                :selectedPlan="selectedPlan"
                @recordID="setRecordID"
                @selectedTab="selectedTab"
                @medicareID="setMedicareID"
                @effectiveDateOfCoverage="(payload) => effectiveDateOfCoverage = payload"
                :recordID="recordID !== '' ? recordID : null"
            /></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              ><Patientinfo @selectedTab="selectedTab" :recordID="recordID"
            /></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              ><PCP @selectedTab="selectedTab" :recordID="recordID"
            /></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              ><Payment @selectedTab="selectedTab" :recordID="recordID"
            /></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              ><Questionnaire @selectedTab="selectedTab" :recordID="recordID" :effDate="effectiveDateOfCoverage"
            /></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              ><Period @selectedTab="selectedTab" :recordID="recordID"
            /></v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <CSNPForm @selectedTab="selectedTab" :recordID="recordID" />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <chronic-conditions
              @selectedTab="selectedTab"
              :recordID="recordID"
              :medicareID="medicareID"
            />
          </v-tab-item>
          <v-tab-item>
            <agent-needs @selectedTab="selectedTab" :recordID="recordID" :medicareID="medicareID" />
          </v-tab-item>
          <v-tab-item>
            <v-card
              ><Acknowledgement
                @selectedTab="selectedTab"
                :recordID="recordID"
                :medicareID="medicareID"
            /></v-card>
          </v-tab-item>
        </v-tabs>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="4">
        <div class="overline text-center text-lg-left">
          H1748_ENROLLAPP_ONLN22_C
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Plans from "../components/new-enrollment-components/Plans";
import Medicare from "../components/new-enrollment-components/Medicare";
import Patientinfo from "../components/new-enrollment-components/Patientinfo";
import PCP from "../components/new-enrollment-components/Pcp";
import Payment from "../components/new-enrollment-components/Payment";
import Questionnaire from "../components/new-enrollment-components/Questionnaire";
import Period from "../components/new-enrollment-components/Period";
import Acknowledgement from "../components/new-enrollment-components/Acknowledgement";
import CSNPForm from "../components/new-enrollment-components/CSNPForm";
import ChronicConditions from "../components/new-enrollment-components/ChronicConditions.vue";
import AgentNeeds from "../components/new-enrollment-components/AgentNeeds.vue"

export default {
  name: "NewEnrollment",
  components: {
    Plans,
    Medicare,
    Patientinfo,
    PCP,
    Payment,
    Questionnaire,
    Period,
    Acknowledgement,
    CSNPForm,
    ChronicConditions,
    AgentNeeds
  },
  data: () => ({
    user: "",
    tab: null,
    recordID: "",
    medicareID: "",
    selectedPlan: null,
    items: [
      "Plan Selection",
      "Medicare Information",
      "Patient Information",
      "PCP",
      "Payment",
      "Questionnaire",
      "Period Statements",
      "CSNP Pre-Qualification Form(Optional)",
      "Chronic Condition Verification",
      "Agent Needs Assessment(Optional)",
      "Acknowledgement",
    ],
    effectiveDateOfCoverage: "",
    currentIndex: 0,
  }),
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      axios
        .get("/api/user")
        .then((response) => {
          this.user = response.data[0];
          // check role and reroute if unauthorized
          if (this.user.role === "hra") {
            this.$router.push("/hraenrollment");
          } else if (this.user.role === "credentialing") {
            this.$router.push("/credentialing");
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    disableTabs(index) {
      if (this.currentIndex === index) {
        return false;
      } else {
        return true;
      }
    },
    async selectedTab(index) {
      this.tab = index;
      this.currentIndex = index;
    },
    async selectPlan(plan) {
      this.selectedPlan = plan;
      this.medicareDisabled = false;
    },
    async setRecordID(id) {
      this.recordID = id;
    },
    async setMedicareID(id) {
      this.medicareID = id;
    },
  },
};
</script>
<style>
.cms {
  position: fixed;
  left: 20px;
  bottom: 20px;
}

.tab-panel {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
</style>
