<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12">
          <v-select
            :items="exerciseArray"
            v-model="dailyExercise"
            label="How often do you exercise for at least 20 minutes in a day?"
            required
            outlined
            dense
            :rules="[this.dailyExercise != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="['Yes', 'No']"
            v-model="sleepingPatterns"
            label="In the past 2 weeks, have you had a change in your sleeping
            patterns, or are you having trouble obtaining restful
            sleep?"
            required
            outlined
            dense
            :rules="[
              this.sleepingPatterns != '' || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            :items="['Yes', 'No']"
            v-model="specialDiet"
            label="Are you on a special diet recommended by your doctor (low sodium, cholesterol, fat)?"
            required
            outlined
            dense
            :rules="[this.specialDiet != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            label="If yes, what type of diet?"
            v-model="specialDietOther"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="dietArray"
            v-model="fruitVegitableConsumption"
            label="How often do you eat fresh fruits or vegetables?"
            required
            outlined
            dense
            :rules="[
              this.fruitVegitableConsumption != '' ||
                'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="dietArray"
            label="How often do you eat fried or high fat foods (fried fish/checken, bacon, potato chips)?"
            v-model="highFatFoods"
            required
            outlined
            dense
            :rules="[this.highFatFoods !== '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="dietArray"
            v-model="alcohol"
            label="How often do you drink alcohol?"
            required
            outlined
            dense
            :rules="[this.alcohol != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="yesNoNaArray"
            label="Have you ever felt you should reduce your alcohol
            consumption?"
            v-model="reduceAlcohol"
            required
            outlined
            dense
            :rules="[this.reduceAlcohol != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="smokingArray"
            v-model="lastTimeSmoked"
            label="When was the last time you smoked or used any tobacco product (cigarettes, chew, snuff, pipes, cigars, vapor cigarettes)?"
            required
            outlined
            dense
            :rules="[this.lastTimeSmoked != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="yesNoNaArray"
            label="Are you interested in quitting smoking?"
            v-model="interestedInQuittingSmoking"
            required
            outlined
            dense
            :rules="[
              this.interestedInQuittingSmoking != '' ||
                'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="['0', '1 time', '2 times', '3 or more times']"
            label="In the past year, how many times have you used an illegal drug or
            prescription medication for non-medical reasons?"
            v-model="illegalDrugUse"
            required
            outlined
            dense
            :rules="[this.illegalDrugUse != '' || 'Please make a selection']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-select
            :items="['Yes', 'No']"
            label="Do you have any hobbies or interests?"
            v-model="hobbiesOrInterests"
            required
            outlined
            dense
            :rules="[
              this.hobbiesOrInterests != '' || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="hobbies"
            label="If yes, please list"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    dietArray: ["Daily", "Often", "Less than 3 times a week", "Never"],
    yesNoNaArray: ["Yes", "No", "N/A"],
    exerciseArray: [
      "Daily",
      "3 or more times a week",
      "Less than 3 times a week",
      "Never"
    ],
    smokingArray: [
      "Today",
      "Last week",
      "Last month",
      "3 months ago",
      "Last year",
      "1-5 years ago",
      "Longer than 5 years ago",
      "Never"
    ],
    dailyExercise: "",
    sleepingPatterns: "",
    specialDiet: "",
    fruitVegitableConsumption: "",
    highFatFoods: "",
    alcohol: "",
    reduceAlcohol: "",
    lastTimeSmoked: "",
    interestedInQuittingSmoking: "",
    illegalDrugUse: "",
    hobbiesOrInterests: "",
    hobbies: "",
    specialDietOther: ""
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          dailyExercise: this.dailyExercise,
          sleepingPatterns: this.sleepingPatterns,
          specialDiet: this.specialDiet,
          fruitVegitableConsumption: this.fruitVegitableConsumption,
          highFatFoods: this.highFatFoods,
          alcohol: this.alcohol,
          reduceAlcohol: this.reduceAlcohol,
          lastTimeSmoked: this.lastTimeSmoked,
          interestedInQuittingSmoking: this.interestedInQuittingSmoking,
          illegalDrugUse: this.illegalDrugUse,
          hobbiesOrInterests: this.hobbiesOrInterests,
          hobbies: this.hobbies,
          specialDietOther: this.specialDietOther
        });

        this.$emit('selectedTab', 7)
      }
    }
  }
};
</script>
