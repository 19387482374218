<template>
  <div>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        v-if="submitted !== true"
        lazy-validation
        class="pa-3"
      >
        <v-row>
          <v-col>
            <span class="font-weight-black justify-start">
              Please read this important information:
            </span>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            If you currently have health coverage from an employer or union,
            joining Sonder Health Plans could affect your employer or Union
            Health benefits. You can lose your employer or Union Health coverage
            if you join Sonder Health Plans. Read the communications your
            employer or union sends you. If you have questions to visit the
            website, or contact the office listed in their communications. If
            there isn’t any information on whom to contact, your benefits
            administrator or the office that answers questions about your
            coverage can help.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="title">
            Please read and sign the following page:
          </v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col>
            By completing this enrollment form, I do agree to the following:
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            Sonder Health Plans is a Medicare advantage plan and has a contract
            with the Federal government. I will need to keep my Medicare parts A
            and B. I can be in only one Medicare advantage plan at a time, and I
            understand that my enrollment in this plan will automatically end my
            enrollment in another Medicare Health plan or prescription drug
            plan. It is my responsibility to inform you of any prescription drug
            coverage that I have or may get in the future. Enrollment in this
            plan is generally for the entire year. Once I enroll, I may leave
            this plan or make changes only at certain times of the year when an
            enrollment period is available (Example: October 15 through December
            7 of every year), or under certain special circumstances.
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            Sonder Health Plans serves a specific service area. If I move out of
            the area Sonder Health Plans serves, I need to notify the plan so I
            can disenroll and find a new plan in my area. Once I am a member of
            Sonder Health Plans, I have a right to appeal plan decisions about
            payment or services if I disagree. I will read the Evidence of
            Coverage document from Sonder Health Plans when I get it to know
            which rules I must follow to get coverage with this Medicare
            Advantage plan. I understand that people with Medicare aren’t
            usually covered under Medicare while out of the country except for a
            limited coverage near the U.S. border.
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            I understand that beginning on the date Sonder Health Plans coverage
            begins, I must get all my health care from Sonder Health Plans,
            except for emergency or urgently needed services or out-of-area
            dialysis services. Services authorized by Sonder Health Plans and
            other services contained in my Sonder Health Plans. Evidence of
            Coverage document (also known as the member contract or subscriber
            agreement) will be covered. Without authorization, neither Medicare
            nor Sonder Health Plans will pay for the services.
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            I understand that if I am getting assistance from a sales agent,
            broker, or other individual employed by or contracted with Sonder
            Health Plans, he/she may be paid based on my enrollment in Sonder
            Health Plans.
          </v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col>
            Release of information:
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            By joining this Medicare Health plan, I acknowledge that Sonder
            Health Plans will release my information to Medicare and other plans
            as is necessary for treatment, payment in health care operations. I
            also acknowledge that Sonder Health Plans will release my
            information including my prescription drug event data to Medicare,
            whom a release it for research and other purposes which follow all
            applicable Federal statutes and regulations. The information on this
            enrollment form is correct to the best of my knowledge. I understand
            that if I intentionally provide false information on this form, I
            will be disenrolled from the plan.
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            I understand that my signature or the signature (or the signature of
            the person authorized to act on my behalf under the laws of the
            state where I live) on this enrollment form means that I have read
            and understand the contents of this enrollment form. If signed by an
            authorized individual as described above, this signature certifies
            that: 1) this person is authorized under the state law to complete
            this enrollment and 2) documentation of this authority is available
            upon request from Medicare.
          </v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col>
            If you are the authorized representative, you must sign below and
            provide the following information:
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="enrollmentDate"
                  label="Today's Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="enrollmentDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="lastNameAck"
              label="Last Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="firstNameAck"
              label="First Name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="addressAck" label="Address"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col>
            If you're an agent filling out this form, please input your agent
            number below:
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-combobox
              v-model="agentNumberAck"
              :items="agentIDs"
              @change="getAgentName"
              label="NPN"
              outlined
              dense
              :rules="[
                /^[^a-zA-Z]*$/.test(agentNumberAck) ||
                  'This input can only take numbers',
              ]"
            ></v-combobox>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="agentName"
              label="Agent Name"
              outlined
              :key="key"
              disabled
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <div class="text-body-1">Upload paper enrollment</div>
            <div class="text-caption mb-2">(if applicable)</div>
            <v-file-input
              v-model="uploadedFiles"
              accept=".pdf"
              prepend-icon="mdi-file"
              multiple
              counter
              dense
              outlined
              truncate-length="10"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" :timeout="3000" color="error">
          {{ errorMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-row class="my-2">
          <v-col cols="12">
            <p class="font-weight-bold">Please have the member sign here:</p>
            <v-alert dense outlined type="error" v-model="alert">
              Please sign your name below to continue
            </v-alert>
            <Signature ref="Signature" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <v-btn text color="primary" @click="previous()">
              <v-icon small> mdi-chevron-left </v-icon>
              previous
            </v-btn>
          </v-col>
          <v-col class="text-right pb-0">
            <v-btn color="success" @click="validate()">
              <v-icon small class="mr-2">mdi-check</v-icon>
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row v-else class="text-center">
        <v-col cols="12">
          <v-icon x-large color="success">mdi-check</v-icon>
        </v-col>
        <v-col>
          Application has been submitted successfully.
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import Signature from "./Signature.vue";

export default {
  props: ["recordID", "medicareID"],
  components: {
    Signature,
  },
  data: () => ({
    valid: false,
    menu: null,
    options: {
      penColor: "#c0f",
    },
    enrollmentDate: "",
    firstNameAck: "",
    lastNameAck: "",
    addressAck: "",
    signature: "",
    agentNumberAck: "",
    submitted: false,
    uploadedFiles: [],
    snackbar: false,
    errorMessage: "",
    alert: false,
    updated: false,
    agentIDs: [],
    agentName: "",
  }),
  methods: {
    previous() {
      this.$emit("selectedTab", 7);
    },
    async save() {
      this.signature = await this.$refs.Signature.submit();
      if (this.signature !== undefined) {
        this.alert = false;
        let data = new FormData();

        for (let i = 0; i < this.uploadedFiles.length; i++) {
          let file = this.uploadedFiles[i];
          data.append("files[" + i + "]", file);
        }
        // update enrollment
        await axios
          .post("/api/enrollment/save", {
            _id: this.recordID,
            enrollmentDate: this.enrollmentDate,
            firstNameAck: this.firstNameAck,
            lastNameAck: this.lastNameAck,
            addressAck: this.addressAck,
            signature: this.signature,
            agentName: this.agentName,
            agentNumberAck: this.agentNumberAck,
          })
          .then(() => {
            this.updated = true;
            this.sendDocument(data);
          })
          .catch((err) => {
            console.log(err);
            this.errorMessage =
              "Error saving data on acknowledgemnt page, please check you have filled out all required fields";
            this.snackbar = true;
          });
      } else {
        this.alert = true;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      } else {
        this.errorMessage =
          "Error saving data on acknowledgemnt page, please check you have filled out all required fields";
        this.snackbar = true;
      }
    },
    async sendDocument(data) {
      // send new document to API
      await axios
        .post(`/api/enrollment/documents/save/${this.medicareID}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.submitted = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAgentNames() {
      await axios.get(`api/modals/enrollment/agentComboBox`).then((res) => {
        //format fields for card
        let response = res.data;
        response.forEach((obj) => {
          this.agentIDs.push(obj.agentID);
        });
      });
    },
    async getAgentName() {
      await axios
        .get(`api/modals/enrollment/agentNameAutoFill/${this.agentNumberAck}`)
        .then((response) => {
          this.agentName = response.data[0].agentName;
        });
    },
  },
  mounted() {
    this.getAgentNames();
  },
};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
