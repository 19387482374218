<template>
  <v-card>
    <v-card-title v-if="insideDialog">
      <span class="text-h5 mb-2">New Task</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              label="Assign User"
              v-model="assignedUser"
              :items="assignmentList"
              :loading="assignmentLoading"
              :search-input.sync="assignmentSearch"
              :readonly="section === 'authorization'"
              item-text="fullName"
              return-object
              clearable
              required
              :rules="[assignedUser != null || 'Assignment is required']"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title> Search for users </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title> {{ item.fullName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.userID }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            Task Priority
            <v-radio-group
              v-model="taskPriority"
              required
              :rules="[taskPriority != '' || 'Priority is required']"
              row
            >
              <v-radio label="Low" value="Low"></v-radio>
              <v-radio label="Medium" value="Medium"></v-radio>
              <v-radio label="High" value="High"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="taskObjective"
              label="Please enter the task objective"
              :counter="255"
              dense
              outlined
              :rules="[
                taskObjective != '' || 'Objective is required',
                (v) => v.length <= 255 || 'Max 255 characters',
              ]"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn color="accent" text @click="closeNewNote()"> Cancel </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" text @click="validate()"> Create </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "NewTask",
  props: ["user", "section", "credID", "insideDialog"],
  data() {
    return {
      dialog: false,
      valid: false,
      assignedUser: null,
      assignmentList: [],
      assignmentLoading: false,
      assignmentSearch: null,
      taskType: "Follow up",
      taskPriority: "",
      taskObjective: "",
      taskAuthID: "",
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.saveTask();
      }
    },
    async saveTask() {
      /*  
        In enrollments a 'Follow up' task can be created when a new note is created on that enrollment.
        If the user checks to create a followup then this component shows.
        When creating a followup, we validate the task here and emit it to it's parent component
        The parent component then validates its note. Both note and task have to be validated for either to go through
        if one is invalid, neither gets created 
      */
      let newTask = {
        AssignedBy: {
          email: this.user.email,
          fullName: `${this.user.lastname}, ${this.user.firstname}`,
          userID: this.user.userID,
        },
        AssignedTo: this.assignedUser,
        TaskType: this.taskType,
        TaskPriority: this.taskPriority,
        TaskObjective: this.taskObjective,
        TaskAuthID: this.taskAuthID,
        TaskPatientID: this.credID,
      };
      if (!this.insideDialog) {
        this.$emit("addNewNote", newTask);
      } else {
        // create task without note
        this.clearTask();
        await axios
          .post(`/api/tasks/new`, [newTask])
          .then(() => {
            this.$emit("closeDialog", true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    clearTask() {
      this.assignedUser = {};
      this.taskPriority = "";
      this.taskObjective = "";
      this.dialog = false;
    },
    setAuthID(value) {
      this.taskAuthID = value.AuthID;
    },
    closeNewNote() {
      if (!this.insideDialog) {
        this.$emit("closeNewNote");
      } else {
        this.clearTask();
        this.$emit("closeDialog");
      }
    },
  },
  computed: {
    showTaskAuthId() {
      if (
        this.section === "authorization" ||
        this.taskType === "Authorization Alert"
      ) {
        return true;
      } else {
        return false;
      }
    },
    typeList() {
      return ["Follow up"];
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        switch (this.section) {
          case "authorization":
            this.assignmentList.push(this.auth.AssignedUser);
            this.assignedUser = this.auth.AssignedUser;
            this.taskAuthID = this.auth.AuthID;
            break;
          default:
            this.assignedUser = null;
        }
      }
    },
    assignmentSearch() {
      if (this.assignmentSearch !== null) {
        this.assignmentLoading = true;

        // Lazily load input items
        axios
          .get(`/api/search/users/all/${this.assignmentSearch[0]}`)
          .then((response) => {
            this.assignmentList = response.data;
            this.assignmentLoading = false;
          });
      }
    },
  },
};
</script>
