<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on"
        color="success"
      >
        <v-icon class="mr-1">mdi-folder-plus</v-icon>
        Create Task
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5 mb-2">New Task</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-select
                label="Task Type"
                :items="typeList"
                v-model="taskType"
                required
                :rules="[
                  taskType != '' || 'Type is required'
                ]"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="showTaskAuthId" class="pb-0">
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    :readonly="section === 'authorization'"
                    v-model="taskAuthID"
                    label="Task Auth ID"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <auth-search 
                    :buttonDisabled="section == 'authorization'"
                    @authSelected="setAuthID"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Assign User"
                v-model="assignedUser"
                :items="assignmentList"
                :loading="assignmentLoading"
                :search-input.sync="assignmentSearch"
                :readonly="section === 'authorization'"
                item-text="fullName"
                return-object
                clearable
                required
                :rules="[
                  assignedUser != null || 'Assignment is required'
                ]"
                outlined
                dense
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for users
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title> {{ item.fullName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.userID }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              Task Priority
              <v-radio-group
                v-model="taskPriority"
                required
                :rules="[
                  taskPriority != '' ||
                    'Priority is required',
                ]"
                row
              >
                <v-radio 
                  label="Low" 
                  value="Low"
                ></v-radio>
                <v-radio
                  label="Medium"
                  value="Medium"
                ></v-radio>
                <v-radio
                  label="High"
                  value="High"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="taskObjective"
                label="Please enter the task objective"
                :counter="255"
                dense
                outlined
                :rules="[
                  taskObjective != '' || 'Objective is required',
                  v => v.length <= 255|| 'Max 255 characters'
                ]"
              >
              </v-textarea>
            </v-col>
          </v-row>    
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          text
          @click="clearTask()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          text
          @click="validate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import AuthSearch from '../search-components/AuthSearchDialog';

export default {
  name: "NewTask",
  components: {
    AuthSearch
  },
  props: [
    'user',
    'section',
    'auth',
  ],
  data() {
    return {
      dialog: false,
      valid: false,
      assignedUser: null,
      assignmentList: [],
      assignmentLoading: false,
      assignmentSearch: null,
      taskType: '',
      taskPriority: '',
      taskObjective: '',
      taskAuthID: '',
    };
  },
  methods: {
    validate() {
      if(this.$refs.form.validate()) {
        this.saveTask();
      }
    },
    async saveTask() {
      let newTask = {
        AssignedBy: {
          email: this.user.email,
          fullName: `${this.user.lastname}, ${this.user.firstname}`,
          userID: this.user.userID
        },
        AssignedTo: this.assignedUser,
        TaskType: this.taskType,
        TaskPriority: this.taskPriority,
        TaskObjective: this.taskObjective,
        TaskAuthID: this.taskAuthID,
      }

      await axios
        .post(`/api/tasks/new`, [newTask])
        .then((res) => {
          this.$emit('addTask', res.data.savedTask);
          this.clearTask();
        })
        .catch(error => {
          console.log(error);
        });
    },
    clearTask() {
      this.assignedUser = {};
      this.taskType = '';
      this.taskPriority = '';
      this.taskObjective = '';
      this.dialog = false;
    },
    setAuthID(value) {
      this.taskAuthID = value.AuthID;
    },
  },
  computed: {
    showTaskAuthId() {
      if(
        this.section === 'authorization' ||
        this.taskType === 'Authorization Alert'
      ) {
        return true;
      } else {
        return false;
      }
    },
    typeList() {
      if(this.section === 'authorization') {
        return ['Authorization Alert'];
      } else {
        return [
          'General',
          'Authorization Alert',
        ];
      }
    }
    // show authID field
      // opening from auth modal
      // selecting taks type auth related option
    // prefill and disable inputs
      // if opening from auth modal
  },
  watch: {
    dialog() {
       if(this.dialog) {
         switch (this.section) {
           case 'authorization':
            this.assignmentList.push(this.auth.AssignedUser)
            this.assignedUser = this.auth.AssignedUser;
            this.taskAuthID = this.auth.AuthID;
            break;
          default:
            this.assignedUser = null;
         }
       }
    },
    assignmentSearch() {
      if(this.assignmentSearch !== null) {
        this.assignmentLoading = true;

        // Lazily load input items
        axios
          .get(
            `/api/search/users/all/${this.assignmentSearch[0]}`
          )
          .then((response) => {
            this.assignmentList = response.data;
            this.assignmentLoading = false;
          });
      }
    },
    taskType() {
      if(this.taskType !== 'Authorization Alert') {
        this.taskAuthID = ''
      }
    }
  },
};
</script>