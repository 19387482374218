import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login.vue";
import Admin from "./views/Admin.vue";
import Dashboard from "./views/Dashboard.vue";
import Agents from "./views/Agents.vue";
import Enrollments from "./views/Enrollments.vue";
import Disenrollments from "./views/Disenrollments.vue";
import Pending from "./views/Pending.vue";
import NotEligible from "./views/NotEligible.vue";
import NewEnrollment from "./views/NewEnrollment.vue";
import Reports from "./views/Reports.vue";
import ProviderReports from "./views/ProviderReports.vue"
import CapitationReports from "./views/CapitationReports"
import HRAEnrollment from "./views/HRAEnrollment.vue";
import HRAPatients from "./views/HRAPatients.vue";
import Credentialing from "./views/Credentialing.vue";
import CreateCredentialing from "./views/CreateCredentialing.vue";
import CorrespondenceNew from "./views/CorrespondenceNew.vue";
import CorrespondenceSent from "./views/CorrespondenceSent.vue";
import NewSOA from "./views/NewSOA.vue";
import SOA from "./views/SOA.vue";
import SignSOA from "./views/SignSOA.vue";
import Authorizations from "./views/Authorizations.vue";
import Careplans from "./views/Careplans.vue";
import PWChange from "./views/PWChange.vue";
import Notifications from "./views/Notifications.vue";
import Tasks from "./views/Tasks.vue";
import SubmitClaim from "./views/SubmitClaim.vue";
import CreateClaim from "./views/CreateClaim.vue";
import ClaimHistory from "./views/ClaimHistory.vue";
import PlanSettings from "./views/PlanSettings.vue";

import errorPage from "./components/error/404.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/admin",
      name: "Admin",
      component: Admin,
    },
    {
      path: "/agents",
      name: "Agents",
      component: Agents,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/pwchange",
      name: "pwchange",
      component: PWChange,
    },
    {
      path: "/enrollments",
      name: "enrollments",
      component: Enrollments,
    },
    {
      path: "/disenrollments",
      name: "disenrollments",
      component: Disenrollments,
    },
    {
      path: "/pending",
      name: "pending",
      component: Pending,
    },
    {
      path: "/noteligible",
      name: "noteligible",
      component: NotEligible,
    },
    {
      path: "/newenrollment",
      name: "newenrollment",
      component: NewEnrollment,
    },
    {
      path: "/reports",
      name: "reports",
      component: Reports,
    },
    {
      path: "/capitationreports",
      name: "capitationreports",
      component: CapitationReports,
    },
    {
      path: "/providerreports",
      name: "providerreports",
      component: ProviderReports,
    },
    {
      path: "/hraenrollment",
      name: "hraenrollment",
      component: HRAEnrollment,
    },
    {
      path: "/hrapatients",
      name: "hrapatients",
      component: HRAPatients,
    },
    {
      path: "/credentialing",
      name: "credentialing",
      component: Credentialing,
    },
    {
      path: "/newprovider",
      name: "newprovider",
      component: CreateCredentialing,
    },
    {
      path: "/SOA",
      name: "SOA",
      component: SOA,
    },
    {
      path: "/newSOA",
      name: "newSOA",
      component: NewSOA,
    },
    {
      path: "/sign-soa/:id",
      name: "sign-soa-id",
      component: SignSOA,
    },
    {
      path: "/newcorrespondence",
      name: "newcorrespondence",
      component: CorrespondenceNew,
    },
    {
      path: "/sentcorrespondence",
      name: "sentcorrespondence",
      component: CorrespondenceSent,
    },
    {
      path: "/authorizations",
      name: "authorizations",
      component: Authorizations,
    },
    {
      path: "/careplans",
      name: "careplans",
      component: Careplans,
    },
    {
      path: "/404",
      name: "error",
      component: errorPage,
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/notifications",
      name: "notifications",
      component: Notifications,
    },
    {
      path: "/tasks",
      name: "tasks",
      component: Tasks,
    },
    {
      path: "/submitclaim",
      name: "submitclaim",
      component: SubmitClaim,
    },
    {
      path: "/createclaim",
      name: "createclaim",
      component: CreateClaim,
    },
    {
      path: "/claimhistory",
      name: "claimhistory",
      component: ClaimHistory,
    },
    {
      path: "/plansettings",
      name: "plansettings",
      component: PlanSettings,
    },
  ],
});
