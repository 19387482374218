<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col cols="12">
          <div>
            When was the last time you had each of the following?
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            v-model="pneumoniaVaccine"
            outlined
            dense
            required
            label="Pneumonia Vaccine"
            multiple
            :rules="[
              this.pneumoniaVaccine.length !== 0 || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            v-model="fluVaccine"
            outlined
            dense
            required
            label="Flu Vaccine"
            multiple
            :rules="[
              this.fluVaccine.length !== 0 || 'Please make a selection',
            ]"
          ></v-select>
        </v-col> </v-row
      ><v-row>
        <v-col cols="12">
          <v-select
            :items="covidAnswers"
            v-model="covid19Vaccine"
            outlined
            dense
            required
            label="COVID-19 Vaccine"
            multiple
            :rules="[
              this.covid19Vaccine.length !== 0 || 'Please make a selection',
            ]"
          ></v-select>
        </v-col> </v-row
      ><v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            v-model="breastScreening"
            outlined
            dense
            required
            label="Breast Screening (Mammogram)"
            multiple
            :rules="[
              this.breastScreening.length !== 0 || 'Please make a selection',
            ]"
          ></v-select>
        </v-col> </v-row
      ><v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            v-model="colorectalScreening"
            outlined
            dense
            required
            label="Colorectal Screening (Colonoscopy)"
            multiple
            :rules="[
              this.colorectalScreening.length !== 0 ||
                'Please make a selection',
            ]"
          ></v-select>
        </v-col> </v-row
      ><v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            v-model="cervicalScreening"
            outlined
            dense
            required
            label="Cervical Screening (PAP Smear)"
            multiple
            :rules="[
              this.cervicalScreening.length !== 0 ||
                'Please make a selection',
            ]"
          ></v-select>
        </v-col> </v-row
      ><v-row>
        <v-col cols="12">
          <v-select
            :items="answerArray"
            v-model="boneDensity"
            outlined
            dense
            required
            label="Bone Density Screening"
            multiple
            :rules="[
              this.boneDensity.length !== 0 || 'Please make a selection',
            ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    answerArray: [
      "2022",
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "Within The Last 10 Years",
      "Never",
      "N/A"
    ],
    covidAnswers:[
      '2022',
      '2021',
      '2020',
      'Never',
      'N/A'
    ],
    pneumoniaVaccine: [],
    fluVaccine: [],
    covid19Vaccine: [],
    breastScreening: [],
    colorectalScreening: [],
    cervicalScreening: [],
    boneDensity: []
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          pneumoniaVaccine: this.pneumoniaVaccine,
          fluVaccine: this.fluVaccine,
          covid19Vaccine: this.covid19Vaccine,
          breastScreening: this.breastScreening,
          colorectalScreening: this.colorectalScreening,
          cervicalScreening: this.cervicalScreening,
          boneDensity: this.boneDensity
        });

        this.$emit('selectedTab', 6)
      }
    }
  }
};
</script>
