<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>Claim History</v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="desserts" class="elevation-1">
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Member Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Status", value: "status" },
        { text: "Member ID", value: "id" },
        { text: "Charges", value: "charges" },
        { text: "Allowed", value: "allowed" },
        { text: "GroupID", value: "groupdId" },
      ],
      desserts: [
        {
          name: "Gwen Irving",
          status: "Approved",
          id: 3242342432,
          charges: "$120.49",
          allowed: "$73.49",
          groupdId: "SOH",
        },
        {
          name: "Craig Shah",
          status: "Approved",
          id: 3242344432,
          charges: "$320.44",
          allowed: "$150.89",
          groupdId: "SOH",
        },
        {
          name: "Barbara Jeffery",
          status: "Pending",
          id: 4442342432,
          charges: "$310.19",
          allowed: "$203.48",
          groupdId: "SOH",
        },
        {
          name: "Jordan Duran",
          status: "Denied",
          id: 3243562432,
          charges: "$520.23",
          allowed: "$333.96",
          groupdId: "SOH",
        },
        {
          name: "Kairon Easton",
          status: "Approved",
          id: 3242344332,
          charges: "$543.35",
          allowed: "$123.39",
          groupdId: "SOH",
        },
        {
          name: "Francesca Thompson",
          status: "Approved",
          id: 6843342432,
          charges: "$1,455.49",
          allowed: "$739.43",
          groupdId: "SOH",
        },
        {
          name: "Whitney Frank",
          status: "Pending",
          id: 4326342432,
          charges: "$1200.49",
          allowed: "$973.49",
          groupdId: "SOH",
        },
        {
          name: "Melanie Hooper",
          status: "Approved",
          id: 9954342432,
          charges: "$427.49",
          allowed: "$373.20",
          groupdId: "SOH",
        },
        {
          name: "Mikhail Bassett",
          status: "Pending",
          id: 9442222432,
          charges: "$3,207.56",
          allowed: "$2,734.32",
          groupdId: "SOH",
        },
        {
          name: "Alessandra Mcpherson",
          status: "Denied",
          id: 6742342444,
          charges: "$853.21",
          allowed: "$532.06",
          groupdId: "SOH",
        },
      ],
    };
  },
  methods: {
    getColor(status) {
      if (status === "Denied") return "red";
      else if (status === "Pending") return "orange";
      else return "green";
    },
  },
};
</script>

<style scoped></style>
