<template crossorigin="use-credentials">
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        <v-icon color="accent">mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-toolbar dark color="primary" class="mx-auto">
      <v-btn icon dark @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card tile id="capture" flat>
      <v-row v-if="loading" justify="center" style="height: 100vh">
        <v-col align-self="center" class="text-center">
          <v-progress-circular
            :size="100"
            color="accent"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-card transparent flat v-else-if="taskDetails && !loading">
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Task Priority:</v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon v-if="taskDetails.TaskPriority === 'High'" color="error">mdi-alert-octogon</v-icon>
                  <v-icon v-if="taskDetails.TaskPriority === 'Medium'" color="warning">mdi-alert</v-icon>
                  <v-icon v-if="taskDetails.TaskPriority === 'Low'" color="success">mdi-alert-circle</v-icon>
                  {{ taskDetails.TaskPriority }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Task Type:</v-list-item-title>
                <v-list-item-subtitle>{{ taskDetails.TaskType }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Task Objective:</v-list-item-title>
                <v-list-item-subtitle class="text-wrap">{{ taskDetails.TaskObjective }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Created:</v-list-item-title>
                <v-list-item-subtitle>{{ taskDetails.Created.substring(0, 10) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Created By:</v-list-item-title>
                <v-list-item-subtitle>{{ taskDetails.AssignedBy.Name }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ taskDetails.AssignedBy.UserID }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Assigned To:</v-list-item-title>
                <v-list-item-subtitle>{{ taskDetails.AssignedTo.Name }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ taskDetails.AssignedTo.UserID }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item v-if="taskDetails.Completed">
              <v-list-item-content>
                <v-list-item-title>
                  Task Completion:
                  <v-icon color="success">mdi-check</v-icon>
                  <v-btn
                    @click="completeTask(false)"
                    class="ml-2"
                    color="accent"
                    outlined 
                    small 
                  >
                    Reopen Task
                  </v-btn>
                </v-list-item-title>
                <v-list-item-subtitle>Completion Date: {{ taskDetails.CompletedDate.substring(0, 10) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!taskDetails.Completed">
              <v-list-item-content>
                <v-list-item-title>
                  Task Completion:
                  <v-icon color="error">mdi-close</v-icon>
                  <v-btn
                    @click="completeTask(true)"
                    :loading="completingTask"
                    class="ml-2"
                    color="accent"
                    outlined 
                    small 
                  >
                    Complete Task
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        
        <v-divider></v-divider>

        <v-row>
          <v-col cols="12">
            <v-card-title class="text-uppercase">
              Comments

              <v-spacer></v-spacer>

              <v-btn
                @click="commentDialog = true"
                color="success"
                depressed
              >
                <v-icon>mdi-comment-plus</v-icon>
                Add Comment
              </v-btn>
            </v-card-title>
            <v-card-text v-if="taskDetails.Comments.length > 0">
              <v-card v-for="comment in taskDetails.Comments" :key="comment.Date" outlined class="mb-4">
                <v-list-item>
                  <v-list-item-content>
                    <div class="text-overline">
                      {{ comment.Date.substring(0, 10) }} - {{ formatTime(comment.Date.substring(11, 16)) }}
                    </div>
                    <v-list-item-title class="text-wrap">
                      {{ comment.Comment }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ comment.CreatedBy.Name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card-text>
            
            <v-card-text v-if="taskDetails.Comments.length === 0">
              <v-card outlined>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      There are no comments associated with this task
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>

      <v-dialog
        v-model="commentDialog"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-title>
            New Comment
          </v-card-title>
          <v-card-text class="pb-0">
            <v-form
              ref="commentForm"
              v-model="commentValid"
              lazy-validation
            >
              <v-textarea
                label="Please enter a comment"
                v-model="newCommentMessage"
                required
                :rules="[
                  newCommentMessage != '' ||
                    'Comment is required',
                ]"
                outlined
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              text
              @click="cancelComment()"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="saveComment()"
            >
              Save Comment
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="saveSuccess"
        color="success"
        :timeout="2000"
      >
        Changes have successfully been saved
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="saveSuccess = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
// import * as htmlToImage from "html-to-image";
// import ExportCSV from "vue-json-csv";
// import pdf from "vue-pdf";

export default {
  name: "TaskModal",
  props: [
    "user",
    "taskID"
  ],
  components: {
    // ExportCSV,
    // pdf
  },
  data() {
    return {
      dialog: false,
      loading: false,
      taskDetails: null,
      completingTask: false,
      commentDialog: false,
      commentValid: false,
      newCommentMessage: '',
      saveSuccess: false,
    };
  },
  methods: {
    async getTask() {
      this.loading = true;
      this.taskDetails = null;

      await axios.get(`api/tasks/find/${this.taskID}`).then((res) => {
        this.taskDetails = res.data;

        this.loading = false;
      });
    },
    async completeTask(item) {
      this.completingTask = true;

      let updates
      if(item === false) {
        updates = {
          Completed: false,
          CompletedDate: null
        }
      } else {
        updates = {
          Completed: true,
          CompletedDate: Date.now()
        }
      }

      axios.post(`/api/tasks/update/${this.taskDetails.TaskID}`, updates).then(res => {
        this.taskDetails = res.data;
        this.saveSuccess = true;
        this.completingTask = false;

        // send updated info to reflect in table
        this.$emit('completionChanged', this.taskDetails);
      })
    },
    formatTime(time) {
      let timeConvert = moment(time, "HH:mm A").format("h:mm a");
      return timeConvert;
    },
    cancelComment() {
      this.commentDialog = false;
      this.newCommentMessage = '';
    },
    async saveComment() {
      if(this.$refs.commentForm.validate()) {
        let newComment = {
          CreatedBy: {
            UserID: this.user.userID,
            Name: `${this.user.lastname}, ${this.user.firstname}`,
            Email: this.user.email,
          },
          Comment: this.newCommentMessage,
          Date: Date.now(),
        }
        
        await axios.post(`/api/tasks/comments/new/${this.taskDetails.TaskID}`, newComment).then(res => {
          this.taskDetails.Comments = res.data.Comments;
  
          this.saveSuccess = true;
          
          this.newCommentMessage = '';
          this.commentDialog = false;
        })
      }
    },
  },
  watch: {
    async dialog() {
      if (this.dialog === true) {
        this.getTask();
      }
    },
  },

};
</script>

<style scoped>
.v-dialog--fullscreen {
  overflow-x: hidden !important;
}
</style>
