var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_c('v-icon',[_vm._v("mdi-pencil-plus")]),_vm._v(" Add Diagnosis ")],1)]}}]),model:{value:(_vm.diagnosisDialog),callback:function ($$v) {_vm.diagnosisDialog=$$v},expression:"diagnosisDialog"}},[_c('v-toolbar',{staticClass:"mx-auto",attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v("Add Diagnosis")])],1),_c('v-card',{staticClass:"pa-3",attrs:{"tile":""}},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Problem: ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Enter a Problem","required":"","rules":[
              _vm.problem != '' ||
                'Please make an entry',
            ],"outlined":"","dense":""},model:{value:(_vm.problem),callback:function ($$v) {_vm.problem=$$v},expression:"problem"}})],1),_c('v-card-text',{staticClass:"py-0"},[_vm._v(" Diagnosis: ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Enter a Diagnosis","required":"","rules":[
              _vm.diagnosis != '' ||
                'Please make an entry',
            ],"outlined":"","dense":""},model:{value:(_vm.diagnosis),callback:function ($$v) {_vm.diagnosis=$$v},expression:"diagnosis"}})],1),_c('v-card-text',{staticClass:"py-0"},[_vm._v(" Goals: ")]),_vm._l((_vm.goals),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Please enter a goal","outlined":"","dense":""},model:{value:(item.goal),callback:function ($$v) {_vm.$set(item, "goal", $$v)},expression:"item.goal"}})],1)}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"grey"},on:{"click":_vm.removeGoal}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":_vm.addGoal}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.cancelDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.validate}},[_vm._v(" Save Selected ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }