<template>
    <v-dialog v-model="addServiceDialog" max-width="900px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" class="mb-5"><v-icon>mdi-plus</v-icon>Add Service</v-btn>
        </template>
        <v-card>
            <v-card-title>Add Service</v-card-title>
            <v-form ref="serviceForm" lazy-validation class="pa-3">
                <v-row>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.serviceProcedureCode"
                            label="Service Procedure Code"
                            outlined
                            dense
                            required
                            :rules="[formData.serviceProcedureCode != '' || 'Please enter Service Procedure Code']"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-select
                            v-model="formData.POS"
                            :items="posTypes"
                            label="POS (Place of Service)"
                            outlined
                            dense
                            required
                            :rules="[formData.POS != '' || 'Please enter POS (Type of Service)']"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-select
                            v-model="formData.TOS"
                            :items="serviceTypes"
                            label="TOS (Type of Service)"
                            outlined
                            dense
                            required
                            :rules="[formData.TOS != '' || 'Please enter TOS (Type of Service)']"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            label="Diagnosis Code"
                            v-model="formData.diagnosisCode"
                            :items="diagnosisItems"
                            :loading="diagnosisLoading"
                            :search-input.sync="diagnosisSearch"
                            item-text="CODE"
                            clearable
                            required
                            :rules="[formData.diagnosisCode != '' || 'Diagnosis Code is required']"
                            outlined
                            dense
                        >
                        <template v-slot:no-data>
                            <v-list-item>
                            <v-list-item-title>
                                Search for a diagnosis code
                            </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-content>
                            <v-list-item-title v-text="item.CODE"></v-list-item-title>
                            <v-list-item-subtitle v-text="item.DESCRIPTION"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.serviceMods"
                            label="Service Mods"
                            outlined
                            dense
                            required
                            :rules="[formData.serviceMods != '' || 'Please enter Service Mods']"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.units"
                            label="Units"
                            outlined
                            dense
                            required
                            :rules="[formData.units != '' || 'Please enter Units']"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="4">
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formData.serviceStartDate"
                                    label="Service Start Date"
                                    append-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    :rules="[formData.serviceStartDate != '' || 'Please enter the Service Start Date']"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="formData.serviceStartDate"
                                min="1900-01-01"
                            >
                                <v-spacer></v-spacer>
                                <v-btn outlined color="primary" @click="menu1 = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed
                                    color="primary"
                                    @click="$refs.menu1.save(formData.serviceStartDate)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            max-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formData.serviceEndDate"
                                    label="Service End Date"
                                    append-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    :rules="[formData.serviceEndDate != '' || 'Please enter the Service End Date']"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="formData.serviceEndDate"
                                min="1900-01-01"
                            >
                                <v-spacer></v-spacer>
                                <v-btn outlined color="primary" @click="menu2 = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed
                                    color="primary"
                                    @click="$refs.menu2.save(formData.serviceEndDate)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="formData.charges"
                            label="Charges"
                            outlined
                            dense
                            required
                            :rules="[formData.charges != '' || 'Please enter Charges']"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="addServiceDialog = false" text>Cancel</v-btn>
              <v-btn
                @click="validateService"
                color="accent"
                text
              >
                Add
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            addServiceDialog: false,
            formData: {
                serviceProcedureCode: "",
                POS: "",
                TOS: "",
                diagnosisCode: "",
                serviceMods: "",
                units: "",
                charges: "",
                serviceStartDate: "",
                serviceEndDate: "",
            },
            menu1: false,
            menu2: false,
            serviceValid: false,
            posTypes: [
                { text: "00 - Not Supplied", value: "00"},
                { text: "01 - Pharmacy", value: "01"},
                { text: "02 - Telehealth Provided Other than in Patient’s Home", value: "02"},
                { text: "03 - School", value: "03"},
                { text: "04 - Homeless Shelter", value: "04"},
                { text: "05 - Indian Health Services Free-Standing Facility", value: "05"},
                { text: "06 - Indian Health Service Provider-based Facility", value: "06"},
                { text: "07 - Tribal 638 Free-standing Facility", value: "07"},
                { text: "08 - Tribal 638 Provider-based Facility", value: "08"},
                { text: "09 - Prison/Correctional Facility", value: "09"},
                { text: "10 - Telehealth Provided in Patient’s Home", value: "10"},
                { text: "11 - Office", value: "11"},
                { text: "12 - Home", value: "21"},
                { text: "13 - Assisted Living Facility", value: "13"},
                { text: "14 - Group Home", value: "14"},
                { text: "15 - Mobile Unit", value: "15"},
                { text: "16 - Temporary Lodging", value: "16"},
                { text: "17 - Walk-in Retail Health Clinic", value: "17"},
                { text: "18 - Place of Employment Worksite", value: "18"},
                { text: "19 - Off Campus Outpatient Hospital", value: "19"},
                { text: "20 - Urgent Care Facility", value: "20"},
                { text: "21 - Inpatient Hospital", value: "21"},
                { text: "22 - On Campus Outpatient Hospital", value: "22"},
                { text: "23 - Emergency Room Hospital", value: "23"},
                { text: "24 - Abulatory Surgical Center", value: "24"},
                { text: "25 - Birthing Center", value: "25"},
                { text: "26 - Military Treatment Facility", value: "26"},
                { text: "31 - Skilled Nursing Facility", value: "31"},
                { text: "32 - Nursing Facility", value: "32"},
                { text: "33 - Custodial Care Facility", value: "33"},
                { text: "34 - Hospice", value: "34"},
                { text: "41 - Ambulance - Land", value: "41"},
                { text: "42 - Ambulance - Air or Water", value: "42"},
                { text: "49 - Independent Clinic", value: "49"},
                { text: "50 - Federally Qualified Health Center", value: "50"},
                { text: "51 - Inpatient Psychiatric Facility", value: "51"},
                { text: "52 - Psychiatric Facility - Partial Hospitalization", value: "52"},
                { text: "53 - Community Mental Health Center", value: "53"},
                { text: "54 - Intermediate Care Facility/Individuals with Intellectual Disabilities", value: "54"},
                { text: "55 - Residential Substance Abuse Treatment Facility", value: "55"},
                { text: "56 - Psychiatric Residential Treatment Center", value: "56"},
                { text: "57 - Non-residential Substance Abuse Treatment Facility", value: "57"},
                { text: "58 - Non-residential Opiod Treatment Facility", value: "58"},
                { text: "60 - Mass Immunization Center", value: "60"},
                { text: "61 - Comprehensive Inpatient Rehabilitation Facility", value: "61"},
                { text: "62 - Comprehensive Outpatient Rehabilitation Facility", value: "62"},
                { text: "65 - End Stage Renal Disease Treatment Facility", value: "65"},
                { text: "71 - Public Health Clinic", value: "71"},
                { text: "72 - Rural Health Clinic", value: "72"},
                { text: "81 - Independent Laboratory", value: "81"},
                { text: "99 - Other Place of Service", value: "99"},
            ],
            serviceTypes: [
                "Office/Diag Ctr",
                "DME",
                "Home Health",
                "OP Hospital",
                "IP Hospital",
                "Ambulatory Surgery Center",
                "Physical Therapy",
                "Occupational Therapy",
                "Speech Therapy",
                "Part B Drug Request",
            ],
            diagnosisItems: [],
            diagnosisLoading: false,
            diagnosisSearch: null
        }
    },
    methods: {
        validateService() {
            if (this.$refs.serviceForm.validate()) {
                this.$emit('service', this.formData)
                this.formData = {
                    serviceProcedureCode: "",
                    POS: "",
                    TOS: "",
                    diagnosisCode: "",
                    serviceMods: "",
                    units: "",
                    charges: "",
                    serviceStartDate: "",
                    serviceEndDate: "",
                }
                this.addServiceDialog = false
            }
        },
    },
    watch: {
        diagnosisSearch() {
            this.diagnosisLoading = true;
            axios
                .get(`/api/search/codes/diagnosis/${this.diagnosisSearch}`)
                .then(response => {
                this.diagnosisItems = response.data;
                this.diagnosisLoading = false;
                })
        },
    }
}
</script>

<style scoped>

</style>