<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
      <v-row>
        <v-col class="pb-0">
          <div>
            Do you need help with doing the following daily activities?
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-checkbox
            v-model="standingUpFromSittingPosition"
            hide-details
            label="Standing up from a sitting position"
          ></v-checkbox>
          <v-checkbox
            v-model="walkingInTheHouse"
            hide-details
            label="Walking in the house"
          ></v-checkbox>
          <v-checkbox
            v-model="walkingOutsideOfTheHouse"
            hide-details
            label="Walking outside of the house"
          ></v-checkbox>
          <v-checkbox
            v-model="preparingAMeal"
            hide-details
            label="Preparing a meal"
          ></v-checkbox>
          <v-checkbox
            v-model="eatingAMeal"
            hide-details
            label="Eating a meal"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="6">
          <v-checkbox
            v-model="usingTheToilet"
            hide-details
            label="Using the toilet"
          ></v-checkbox>
          <v-checkbox
            v-model="bathing"
            hide-details
            label="Bathing"
          ></v-checkbox>
          <v-checkbox
            v-model="gettingDressed"
            hide-details
            label="Getting dressed"
          ></v-checkbox>
          <v-checkbox
            v-model="drivingOrGettingPlaces"
            hide-details
            label="Driving or getting to places"
          ></v-checkbox>
        </v-col>
        
        <v-col class="pb-0">
          <div>
            If you need help with any of these activities, do you have someone who can help you?          </div>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="someoneCanHelp"
            label="Yes"
          ></v-checkbox>
          <v-checkbox
            v-model="nobodyCanHelp"
            label="No"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right pb-0">
          <v-btn text :disabled="!valid" color="primary" @click="validate">
            Next
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    standingUpFromSittingPosition: false,
    walkingInTheHouse: false,
    walkingOutsideOfTheHouse: false,
    preparingAMeal: false,
    eatingAMeal: false,
    usingTheToilet: false,
    bathing: false,
    gettingDressed: false,
    drivingOrGettingPlaces: false,
    someoneWhoCanHelp: false,
    someoneCanHelp: false,
    nobodyCanHelp:false
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('data', {
          standingUpFromSittingPosition: this.standingUpFromSittingPosition,
          walkingInTheHouse: this.walkingInTheHouse,
          walkingOutsideOfTheHouse: this.walkingOutsideOfTheHouse,
          preparingAMeal: this.preparingAMeal,
          eatingAMeal: this.eatingAMeal,
          usingTheToilet: this.usingTheToilet,
          bathing: this.bathing,
          gettingDressed: this.gettingDressed,
          drivingOrGettingPlaces: this.drivingOrGettingPlaces,
          someoneWhoCanHelp: this.someoneWhoCanHelp
        });

        this.$emit('selectedTab', 4)
      }
    },
  },
  watch:{
    nobodyCanHelp() {
      if(this.nobodyCanHelp){
        this.someoneWhoCanHelp = false;
        this.someoneCanHelp = false;
      }
    },
    someoneCanHelp(){
      if(this.someoneCanHelp) {
        this.someoneWhoCanHelp = true;
        this.nobodyCanHelp = false;
      }
    }
  }
};
</script>
